import styled from 'styled-components';

import { device } from '../../../lib/media-query';
import { SpotHeader } from '../../shared/typography/spot-header/spot-header.component';

export const FindCarWrapper = styled.div<{ columnSpan: number; backgroundColor?: string; index: number }>`
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: ${(props) => (props.backgroundColor ? '#' + props.backgroundColor : '#688488')};
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);

    padding: 30px 15px;
    grid-column: span ${(props) => props.columnSpan};

    height: 100%;

    @media ${device.tablet} {
        min-width: 395px;
        width: auto;
    }

    @media ${device.laptopS} {
        flex-grow: 1;
    }
`;

export const FindCarHeader = styled.h2`
    color: #fafafa;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
    font-family: 'Corporate E';
    margin-bottom: 10px;
    z-index: 1;
`;

export const StyledSpotHeader = styled(SpotHeader)`
    color: #fafafa;
    text-align: center;
    margin-bottom: 10px;
    z-index: 1;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
`;
