import styled from 'styled-components';

export const StyledSecondaryLinkSectionHeader = styled.p`
    font-family: 'Corporate E';
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 25px;
`;

export const StyledSecondaryLink = styled.a`
    display: block;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    text-decoration: none;
    color: #0b0b0b;
    opacity: 0.9;
    margin-bottom: 20px;

    &:hover {
        text-decoration: none;
    }
`;

export const StyledCtaWrapper = styled.div`
    margin-top: 30px;
    margin-bottom: 20px;
`;

export const StyledSecondaryLinkSectionWrapper = styled.div`
    :not(:first-of-type) {
        margin-top: 40px;
    }
`;
