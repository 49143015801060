import { FC, useEffect, useMemo, useState } from 'react';
import { TextInput } from '../../../forms/inputs';
import { Step } from '../../../hire/hire-lead-checkout/step';
import { StyledCityZipWrapper, StyledFormWrapper } from './shop-customer-info.styled';
import { useZipcodeFinder } from '../../../../hooks/use-zipcode-finder';
import { CartCustomer } from '../../../../lib/api/models/shop/cart';
import { validateEmail, validateName, validateNotEmpty } from '../../../../utils/helpers';
import { CartStoreContext } from '../../../../lib/state/shop/cart/cart.store';
import { ShopCustomerInfoSummary } from './shop-customer-info-summary.component';
import { ShopStepProps } from '../shop-checkout-page.component';
import { useGtmTracking } from '../../../../gtm-tracking/hooks/use-gtm-tracking';

type ValidationState = {
    email: boolean;
    name: boolean;
    address: boolean;
    zip: boolean;
    city: boolean;
    phone: boolean;
};

export const ShopCustomerInfo: FC<ShopStepProps> = ({ currentStep, setActiveStep }) => {
    const { cart, shopCheckoutComplete } = CartStoreContext.useStoreState((state) => state);
    const { addCustomerToCart } = CartStoreContext.useStoreActions((actions) => actions);
    const [customerInfoLoaded, setCustomerInfoLoaded] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const { trackShopEvents } = useGtmTracking();
    const tracker = trackShopEvents();

    const [customerForm, setCustomerForm] = useState<CartCustomer>({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        customerAddress: {
            addressLine1: '',
            city: '',
            countryCode: 'DK',
            countryName: 'Danmark',
            zipCode: '',
        },
    });

    const [canValidateState, setCanValidateState] = useState<ValidationState>({
        email: false,
        name: false,
        address: false,
        zip: false,
        city: false,
        phone: false,
    });

    const [formValidState, setFormValidState] = useState<ValidationState>({
        email: false,
        name: false,
        address: false,
        zip: false,
        city: false,
        phone: false,
    });

    const { city: cityByZipCode } = useZipcodeFinder(customerForm.customerAddress.zipCode);

    useEffect(() => {
        if (cart && currentStep === 1 && !formSubmitted) tracker.startCheckout(cart);
    }, [cart, currentStep, formSubmitted, tracker]);

    useEffect(() => {
        if (cityByZipCode && cityByZipCode.length > 0) {
            setCustomerForm({ ...customerForm, customerAddress: { ...customerForm.customerAddress, city: cityByZipCode } });
            setCanValidateState({ ...canValidateState, city: true });
            setFormValidState({ ...formValidState, city: validateNotEmpty(cityByZipCode) });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityByZipCode]);

    useEffect(() => {
        if (cart && cart.customer && !customerInfoLoaded) {
            setCustomerInfoLoaded(true);
            setCustomerForm(cart.customer);
        }
    }, [cart, customerInfoLoaded]);

    useEffect(() => {
        setFormValidState({
            email: validateEmail(customerForm.email),
            name: validateName(customerForm.firstName),
            address: validateNotEmpty(customerForm.customerAddress.addressLine1),
            zip: !Number.isNaN(customerForm.customerAddress.zipCode) && customerForm.customerAddress.zipCode.length === 4,
            city: validateNotEmpty(customerForm.customerAddress.city),
            phone: validateNotEmpty(customerForm.phone),
        });
    }, [customerForm]);

    const isStepValid = useMemo(() => {
        return (
            formValidState.address && formValidState.city && formValidState.email && formValidState.name && formValidState.phone && formValidState.zip
        );
    }, [formValidState]);

    const submitStep = () => {
        if (!cart) return null;
        setFormSubmitted(true);
        addCustomerToCart({ customerInfo: customerForm, cart });
    };

    const stepComplete = useMemo(() => {
        return formSubmitted && ((cart !== null && cart.customer !== null) || shopCheckoutComplete);
    }, [cart, formSubmitted, shopCheckoutComplete]);

    useEffect(() => {
        if (stepComplete && currentStep === 1) setActiveStep(2);
    }, [currentStep, setActiveStep, stepComplete]);

    return (
        <Step
            edit={() => {
                setFormSubmitted(false);
                setActiveStep(1);
            }}
            goNext={() => submitStep()}
            isCompleted={stepComplete}
            isCurrentStep={currentStep === 1}
            isStepLoading={false}
            isStepValid={isStepValid}
            setCanValidateStep={() => console.log('set can validate step')}
            stepNumber={1}
            stepType={'Unknown'}
            title="Dine oplysninger"
            totalSteps={4}
            isCheckoutComplete={shopCheckoutComplete}
            nextStep="Fortsæt"
        >
            {stepComplete ? (
                <ShopCustomerInfoSummary />
            ) : (
                <StyledFormWrapper>
                    <TextInput
                        canValidateInputField={canValidateState.email}
                        id="customer-email"
                        isValid={formValidState.email}
                        label="E-MAIL"
                        onChange={(e) => {
                            setCustomerForm({ ...customerForm, email: e.target.value });
                        }}
                        type="email"
                        placeholder="Indtast din e-mail adresse"
                        onInputBlur={() => setCanValidateState({ ...canValidateState, email: true })}
                        value={customerForm.email}
                    />
                    <TextInput
                        canValidateInputField={canValidateState.name}
                        id="customer-name"
                        isValid={formValidState.name}
                        label="NAVN"
                        onChange={(e) => {
                            setCustomerForm({ ...customerForm, firstName: e.target.value });
                        }}
                        type="name"
                        placeholder="Indtast dit fulde navn"
                        onInputBlur={() => setCanValidateState({ ...canValidateState, name: true })}
                        value={customerForm.firstName}
                    />
                    <TextInput
                        canValidateInputField={canValidateState.address}
                        id="customer-address"
                        isValid={formValidState.address}
                        label="ADRESSE"
                        onChange={(e) => {
                            setCustomerForm({ ...customerForm, customerAddress: { ...customerForm.customerAddress, addressLine1: e.target.value } });
                        }}
                        type="text"
                        placeholder="Indtast din adresse"
                        onInputBlur={() => setCanValidateState({ ...canValidateState, address: true })}
                        value={customerForm.customerAddress.addressLine1}
                    />
                    <StyledCityZipWrapper>
                        <TextInput
                            canValidateInputField={canValidateState.zip}
                            id="customer-zip"
                            isValid={formValidState.zip}
                            label="POST NR."
                            onChange={(e) => {
                                setCustomerForm({ ...customerForm, customerAddress: { ...customerForm.customerAddress, zipCode: e.target.value } });
                            }}
                            type="text"
                            placeholder=""
                            value={customerForm.customerAddress.zipCode}
                            onInputBlur={() => setCanValidateState({ ...canValidateState, zip: true })}
                        />
                        <TextInput
                            canValidateInputField={canValidateState.city}
                            id="customer-city"
                            isValid={formValidState.city}
                            label="BY"
                            onChange={(e) => {
                                setCustomerForm({ ...customerForm, customerAddress: { ...customerForm.customerAddress, city: e.target.value } });
                            }}
                            type="text"
                            placeholder=""
                            value={customerForm.customerAddress.city}
                            onInputBlur={() => setCanValidateState({ ...canValidateState, city: true })}
                        />
                    </StyledCityZipWrapper>
                    <TextInput
                        canValidateInputField={canValidateState.phone}
                        id="customer-phone"
                        isValid={formValidState.phone}
                        label="TLF."
                        onChange={(e) => {
                            setCustomerForm({ ...customerForm, phone: e.target.value });
                        }}
                        type="text"
                        placeholder="Indtast dit tlf. nr."
                        onInputBlur={() => setCanValidateState({ ...canValidateState, phone: true })}
                        value={customerForm.phone}
                    />
                </StyledFormWrapper>
            )}
        </Step>
    );
};
