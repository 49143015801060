import styled from 'styled-components';
import { device } from '../../../lib/media-query';
import { Button } from '../../shared';

const wrapperPadding = 'padding: 0 45px;';
const wrapperPaddingMobile = 'padding: 0 20px;';

export const BorderBox = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    padding: 20px 0;
    background-color: #ffffff;
    /* max-width: 485px; */

    align-self: center;

    @media ${device.tablet} {
        padding: 38px 0;
        align-self: revert;
    }
`;

export const Header = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    letter-spacing: 0;
    line-height: 30px;
    font-weight: 700;

    margin-bottom: 1.2rem;

    ${wrapperPaddingMobile}
    @media ${device.tablet} {
        ${wrapperPadding}
    }
`;

export const PriceList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    ${wrapperPaddingMobile}
    @media ${device.tablet} {
        flex-direction: row;
        gap: 40px;

        ${wrapperPadding}
    }
`;

export const PriceWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Price = styled.p`
    color: ${({ theme }) => theme.palette.primary.main};
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
`;

export const PriceDescription = styled.p`
    color: #0b0b0b;
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    font-size: 15px;
    letter-spacing: 0;
    line-height: 23px;
`;

export const ButtonList = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.2rem;

    ${wrapperPaddingMobile}
    @media ${device.tablet} {
        flex-direction: row;
        ${wrapperPadding}
        gap: revert;
    }
`;

export const StyledCarSaverBox = styled.div`
    box-shadow: 0px 5px 20px 0px #00000029;
    padding: 24px;
    background-color: white;
    border-radius: 16px;
`;

export const StyledCarSaverHeader = styled.p`
    font-size: 26px;
    font-weight: 900;
    line-height: 29px;
    margin-bottom: 16px;
`;

export const StyledSavingsSummaryGrid = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 30px;
`;

export const StyledSavingsAmount = styled.p`
    font-size: 26px;
    font-weight: 900;
    line-height: 29px;
    color: ${({ theme }) => theme.palette.primary.main};
`;

export const StyledCalculationCta = styled(Button)`
    font-size: 12px;
    text-decoration: none;
    border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
    border-radius: 0;
    line-height: 17px;
    font-weight: 400;
`;

export const StyledCarSaverMembershipCtaWrapper = styled.div`
    margin: 24px -24px 0;
    padding: 24px 24px 0;
    border-top: 1px solid #e6e6e6;
`;
