import { useEffect, useState, VFC } from 'react';
import { umbraco } from '../../../../../lib/api';
import { HireStepReceipt } from '../../../../../lib/api/models/umbraco/hire-site/lead-checkout';
import { LeadCheckoutFormsStore } from '../../../../../lib/state/hessel-site/lead-checkout/forms';
import { generateHireFormId } from '../../../../../lib/state/hessel-site/lead-checkout/forms/hire-forms.helpers';
import { ProductDetailsStore } from '../../../../../lib/state/hessel-site/product-details';
import { formInputValue, getFormattedValue, getUniqueId, sanitizeMarkup } from '../../../../../utils/helpers';
import { NoteWithIcon } from '../../../../shared';
import { OrderSummaryCard } from '../../../_shared';
import { NotesWrapper, ReceiptWrapper, StyledHeader, StyledText } from './receipt.styled';
import { Abbreviations } from '../../../../../constants/units-and-abbreviations';

type Props = {
    content: HireStepReceipt;
};

const getContentHtml = (text: string, template: umbraco.ReceiptPlaceholder, value: string, appendix = '') => {
    return text.replace(template, `<strong>${value}</strong>${appendix}`);
};

export const Receipt: VFC<Props> = ({ content }) => {
    // User information store
    const { inputs } = LeadCheckoutFormsStore.useStoreState((state) => ({
        inputs: state.inputs.filter(({ step }) => step === 'checkoutStepUserInformation'),
    }));

    const [customerInfo, setCustomerInfo] = useState({
        email: '',
        name: '',
    });

    useEffect(() => {
        const emailInput = inputs.find((x) => x.id === generateHireFormId('checkoutStepUserInformation', 'Email'));
        const nameInput = inputs.find((x) => x.id === generateHireFormId('checkoutStepUserInformation', 'Name'));

        if (emailInput && nameInput) {
            setCustomerInfo({
                email: formInputValue(emailInput),
                name: formInputValue(nameInput),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { items: notes, thanksForOrderHeader, thanksForOrderDescription, whatHappensNextHeader, whatHappensDescription } = content;

    // Product Details Store
    const {
        selectedProduct,
        selectedColor,
        extraEquipmentPriceSum,
        optionalEquipmentPriceSum,
        extraEquipmentPackagePriceSum,
        selectedLeasingPeriod,
        selectedStartupDate,
    } = ProductDetailsStore.useStoreState((state) => state);

    const imageUrl =
        selectedProduct?.resources.find((x) => x.type === 'image' && x.colorCode === selectedColor?.code) ??
        selectedProduct?.resources.find((x) => x.type === 'image');

    const totalPriceWithMoms =
        (selectedProduct?.purchaseTypes.hire?.baseMonthlyPrice ?? 0) +
        extraEquipmentPriceSum +
        extraEquipmentPackagePriceSum +
        optionalEquipmentPriceSum +
        (selectedColor?.monthlyHirePrice ?? 0);

    // Hire Lead Forms Store
    const { checkoutMode } = LeadCheckoutFormsStore.useStoreState((state) => state);

    return (
        <ReceiptWrapper>
            <StyledHeader>{thanksForOrderHeader.replace(umbraco.ReceiptPlaceholder.Name, customerInfo.name)}</StyledHeader>

            <StyledText {...sanitizeMarkup(getContentHtml(thanksForOrderDescription, umbraco.ReceiptPlaceholder.Email, customerInfo.email))} />

            {selectedProduct ? (
                <OrderSummaryCard
                    colorName={selectedColor?.name ?? ''}
                    colorImageUrl={selectedColor?.resource.data ?? ''}
                    selectedLeasingPeriod={selectedLeasingPeriod}
                    imageUrl={imageUrl && imageUrl.type === 'image' ? imageUrl?.url : '/'}
                    carPrice={`${getFormattedValue(selectedProduct.purchaseTypes.hire.baseMonthlyPrice)} ${Abbreviations.KR_SLASH_MD}`}
                    extraEquipmentPrice={`${getFormattedValue(extraEquipmentPriceSum + extraEquipmentPackagePriceSum)} ${Abbreviations.KR_SLASH_MD}`}
                    optionalEquipmentPrice={`${getFormattedValue(optionalEquipmentPriceSum)} ${Abbreviations.KR_SLASH_MD}`}
                    totalPriceIncludingMoms={`${getFormattedValue(totalPriceWithMoms)} ${Abbreviations.KR_SLASH_MD}`}
                    totalPriceExcludingMoms={`${getFormattedValue(totalPriceWithMoms * 0.8)} ${Abbreviations.KR_SLASH_MD}`}
                    startupDate={selectedStartupDate}
                    brandTitle={selectedProduct.brandTitle}
                    variantTitle={selectedProduct.variantTitle}
                    checkoutMode={checkoutMode}
                />
            ) : null}

            <NotesWrapper>
                <StyledHeader>{whatHappensNextHeader}</StyledHeader>
                {notes?.map((note, index) => {
                    return <NoteWithIcon key={getUniqueId()} header={note.header} text={note.text} icon={note.icon} index={index} />;
                })}
            </NotesWrapper>

            <p
                {...sanitizeMarkup(
                    whatHappensDescription
                        .replace(
                            umbraco.ReceiptPlaceholder.SupportTel,
                            `<Link><a href=${`tel:${content.supportTel?.split(' ').join('')}`}>${content.supportTel}</a></Link>`
                        )
                        .replace(
                            umbraco.ReceiptPlaceholder.SupportEmail,
                            `<Link><a href=${`mailto:${content.supportEmail}`}>${content.supportEmail}</a></Link>`
                        )
                )}
            />
        </ReceiptWrapper>
    );
};
