import styled from 'styled-components';
import { device } from '../../../lib/media-query';
import { SortingDropdown } from '../../shared/filter-dropdown/filter-dropdown.component';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';

export const StyledCenteredBlock = styled(CenteredBlock)`
    background-color: #f7f7f8;
`;

export const StyledEmployeesInDepartment = styled.section`
    padding: 40px 10px;
    @media ${device.tablet} {
        padding: 85px 0;
    }
`;

export const Header = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 35px;
    margin-bottom: 23px;
`;

export const ContentWrapper = styled.section`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 19px;
`;

export const ToggleUiWrapper = styled.div`
    margin-top: 23px;
    margin-bottom: 30px;
`;

export const DepartmentsDropdown = styled(SortingDropdown)`
    height: 30px;
    width: 50%;
`;
