import { useEffect, VFC } from 'react';
import { LeadCheckoutFormsStore, HireCheckoutMode } from '../../../../../lib/state/hessel-site/lead-checkout/forms';
import { LeadCheckoutStepsStore } from '../../../../../lib/state/hessel-site/lead-checkout/steps';
import { FormInput } from '../../../../../lib/state/_shared/input-types';
import { HireInputs } from '../../hire-inputs';
import { StyledSubHeader } from '../../typography';
import { BorderBox, Content, StyledToggleUi } from './user-information.styled';

type Props = {
    contentHeader?: string;
    canValidateStep: boolean;
};

export const UserInformation: VFC<Props> = ({ contentHeader, canValidateStep }) => {
    const { inputs, checkoutMode } = LeadCheckoutFormsStore.useStoreState((state) => ({
        inputs: state.inputs.filter(({ step }) => step === 'checkoutStepUserInformation'),
        checkoutMode: state.checkoutMode,
    }));

    const { setCheckoutMode } = LeadCheckoutFormsStore.useStoreActions((actions) => ({
        setCheckoutMode: actions.setCheckoutMode,
    }));

    const { setStepValidity } = LeadCheckoutStepsStore.useStoreActions((actions) => ({
        setStepValidity: actions.setStepValidity,
    }));

    useEffect(() => {
        function getStepValidity(inputs: FormInput<string>[], checkoutMode: HireCheckoutMode): boolean {
            if (checkoutMode === HireCheckoutMode.Business) {
                return inputs.every((x) => x.isValid);
            }

            const filtered = inputs.filter((x) => x.type !== 'company (business)' && x.type !== 'cvr (business)');

            return filtered.every((x) => x.isValid);
        }

        setStepValidity({
            stepNumber: 0,
            isValid: getStepValidity(inputs, checkoutMode),
        });
    }, [checkoutMode, inputs, setStepValidity]);

    return (
        <BorderBox>
            <Content>
                {contentHeader && contentHeader !== '' ? <StyledSubHeader>{contentHeader}</StyledSubHeader> : null}
                <StyledToggleUi
                    options={[
                        { label: 'Privat', value: `${HireCheckoutMode.Private}` },
                        { label: 'Firma', value: `${HireCheckoutMode.Business}` },
                    ]}
                    selectedOption={`${checkoutMode}`}
                    onChange={(selection) => {
                        setCheckoutMode({
                            mode: +selection as HireCheckoutMode,
                        });
                    }}
                />
                <HireInputs inputs={inputs} canValidate={canValidateStep} />
            </Content>
        </BorderBox>
    );
};
