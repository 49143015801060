import { FC, useMemo } from 'react';
import { DropdownLabel, DropdownWrapper, OptionsWrapper, ValueAndArrowWrapper, Option, OptionsBackground } from './dropdown-filter.styled';
import Image from 'next/image';
import { useSelect } from 'downshift';
import { FacetOption } from '../../../../../lib/view-models/vehicle';
import { formatPrice } from '../../../../../utils/helpers';

type IProps = {
    options: FacetOption[];
    onChange: (newValue?: FacetOption) => void;
    selectedValue: string;
    label: string;
    valueValidator: (value: string) => boolean;
    labelUnit: string;
    formatNumericalValues?: boolean;
};

export const DropdownFilter: FC<IProps> = ({ options, onChange, selectedValue, label, valueValidator, labelUnit, formatNumericalValues = true }) => {
    const { isOpen, getItemProps, getLabelProps, getMenuProps, getToggleButtonProps, toggleMenu } = useSelect({
        items: options,
        itemToString: (item) => item?.key ?? '',
        onSelectedItemChange: ({ selectedItem }) => {
            toggleMenu();
            onChange(selectedItem ?? undefined);
        },
        initialSelectedItem: options.find((x) => x.selected),
    });

    const formattedSelectedValue = useMemo(() => {
        const converted = Number(selectedValue);
        if (isNaN(converted) || !formatNumericalValues) return selectedValue;
        return formatPrice(converted);
    }, [formatNumericalValues, selectedValue]);

    return (
        <>
            <DropdownWrapper {...getToggleButtonProps()}>
                <DropdownLabel {...getLabelProps()} open={isOpen}>
                    {label}
                </DropdownLabel>
                <ValueAndArrowWrapper open={isOpen}>
                    <span>{selectedValue !== '0' && selectedValue !== '9999999' ? `${formattedSelectedValue} ${labelUnit}` : 'Alle'}</span>
                    <Image src="/icons/chevron/down.svg" height="20px" width="20px" />
                </ValueAndArrowWrapper>
                <OptionsWrapper open={isOpen} {...getMenuProps()}>
                    <OptionsBackground>
                        {isOpen &&
                            options.map((item, index) => {
                                return (
                                    <Option key={index} {...getItemProps({ item, index })} disabled={!valueValidator(item.key)}>
                                        {item.key === '0' ? 'Alle' : `${item.key} ${labelUnit}`}
                                    </Option>
                                );
                            })}
                    </OptionsBackground>
                </OptionsWrapper>
            </DropdownWrapper>
        </>
    );
};

export default DropdownFilter;
