import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
`;

export const Label = styled.p`
    margin-right: 5px;
`;

export const StyledLink = styled.a`
    text-decoration: underline;
`;
