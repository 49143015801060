import { useState } from 'react';
import { DispatchMethod } from '../gtm-tracking.types';
import { COMMON_GTM } from '../gtm-tracking-utils';
import { HireFormSubmitData } from '../../lib/api/models/umbraco';

export type ContactHesselFormEvents = {
    completeContactHesselForm: (prams: HireFormSubmitData) => void;
};

const useContactHesselFromEvents = (dispatch: DispatchMethod): ContactHesselFormEvents => {
    return useState({
        completeContactHesselForm: (action: HireFormSubmitData) =>
            dispatch({
                event: 'kontakt_hessel',
                type: COMMON_GTM.COMPLETED,
                ...action,
            }),
    })[0];
};

export const trackContactHesselForm = (dispatch: DispatchMethod): (() => ContactHesselFormEvents) => {
    return () => useContactHesselFromEvents(dispatch);
};
