import { FC, useMemo, useState } from 'react';
import { SidePanel } from '../../../../shared/modals/side-panel';
import { SidePanelLayout } from '../../../../shared/modals/side-panel-layout/side-panel-layout.component';
import { StyledButton, StyledModalHeader, StyledProductDescription } from './product-description.styled';
import { useMediaQuery } from '../../../../../hooks/use-media-query';

type IProps = {
    description: string;
    readMoreLimit?: number;
};

export const ShopProductDescription: FC<IProps> = ({ description, readMoreLimit = 300 }) => {
    const [fullDescriptionOpen, setFullDescriptionOpen] = useState(false);

    const isDevice = useMediaQuery({
        target: 'tablet',
    });

    const readMoreDeviceCalculated = useMemo(() => {
        if (isDevice) return readMoreLimit * 0.75;
        return readMoreLimit;
    }, [isDevice, readMoreLimit]);

    const readMoreRelevant = useMemo(() => {
        return description.length > readMoreDeviceCalculated;
    }, [description.length, readMoreDeviceCalculated]);

    return (
        <>
            <StyledProductDescription>
                <span style={{ marginRight: '5px' }}>
                    {readMoreRelevant ? `${description.substring(0, readMoreDeviceCalculated).trim()}...` : description}
                </span>
                {readMoreRelevant && (
                    <StyledButton variant="link" onClick={() => setFullDescriptionOpen(true)}>
                        Læs mere
                    </StyledButton>
                )}
            </StyledProductDescription>
            {readMoreRelevant && (
                <>
                    <SidePanel isVisible={fullDescriptionOpen} cancelAction={() => setFullDescriptionOpen(false)}>
                        <SidePanelLayout closeSidePanel={() => setFullDescriptionOpen(false)}>
                            <StyledModalHeader>Beskrivelse</StyledModalHeader>
                            <p>{description}</p>
                        </SidePanelLayout>
                    </SidePanel>
                </>
            )}
        </>
    );
};
