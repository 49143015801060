import { VFC } from 'react';
import { SvgIcon } from '../../../../shared/svg-icon';
import { Image, ImageWrapper, TogglerWrapper, Column, ColumnHeader, ColumnContent, DetailsColumn, StyledToggler } from './summary-toggler.styled';

type Props = {
    imageUrl: string;
    price: string;
    brand: string;
    model: string;
    showDetails: () => void;
};

export const SummaryToggler: VFC<Props> = ({ imageUrl, price, brand, model, showDetails }) => {
    return (
        <TogglerWrapper>
            <ImageWrapper>
                <Image src={imageUrl} />
            </ImageWrapper>

            <Column>
                <ColumnHeader>{brand}</ColumnHeader>
                <ColumnContent title={model}>{model}</ColumnContent>
            </Column>

            <DetailsColumn>
                <ColumnContent>{price}</ColumnContent>
                <StyledToggler onClick={showDetails}>
                    Se detaljer <SvgIcon iconName="chevron/right" color="primary" />
                </StyledToggler>
            </DetailsColumn>
        </TogglerWrapper>
    );
};
