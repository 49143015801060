import { VFC } from 'react';
import { FormsManagerStore } from '../../../../lib/state/plus-sites/forms-display-manager';
import { SidePanel } from '../../../shared/modals/side-panel';
import { SidePanelLayout } from '../../../shared/modals/side-panel-layout/side-panel-layout.component';
import { UmbracoForms } from '../../../spots/umbraco-forms';
import { useUI } from '../../../ui';

type Props = {
    context: {
        pageId: string;
    };
};

export const FormsContentHandler: VFC<Props> = ({ context }) => {
    const ui = useUI();

    const { formList } = FormsManagerStore.useStoreState((state) => state);
    const { setFormVisibility } = FormsManagerStore.useStoreActions((actions) => actions);

    if (formList && formList.length > 0) {
        return (
            <>
                {formList.map((x, index) => {
                    return (
                        <SidePanel
                            key={`${x.form.id}-${index}`}
                            isVisible={x.isVisible}
                            cancelAction={() => {
                                setFormVisibility({ id: x.form.id, value: false });
                                ui.applyScroll();
                            }}
                            variant="md"
                        >
                            <SidePanelLayout
                                closeSidePanel={() => {
                                    setFormVisibility({ id: x.form.id, value: false });
                                    ui.applyScroll();
                                }}
                            >
                                <UmbracoForms
                                    form={x.form}
                                    pageId={context.pageId}
                                    header={x.formHeader}
                                    subtext={x.formSubtext}
                                    trackingStrategy={x.trackingStrategy ? x.trackingStrategy[0] : undefined}
                                />
                            </SidePanelLayout>
                        </SidePanel>
                    );
                })}
            </>
        );
    } else {
        return null;
    }
};
