import useSWR from 'swr';
import { generateQueryString } from '../../../lib/api/helpers';
import { hesselApiTypes } from '../../../lib/api/models/hessel-api';

type CarFinancingParams = {
    ObjectPrice: string;
    DownPayment: number;
    PaymentTerms: number;
    PartnerExternalDealerId?: string;
    /**
     * 0 for Order Cars.
     * BE Provides value for In Stock Cars.
     */
    Mileage: number;
    /**
     * As of now, send todays date in YYYY-MM-dd format
     */
    FirstRegistrationDate: string;
    /**
     * As of now, send current year
     */
    ModelYear: number;
    IsFixedInterest?: boolean;
    ObjectType?: 'WhitePlate' | 'YellowPlate' | 'Camping';
    Brand: string;
};

type UseSantanderReturnType = {
    santanderFinancing: hesselApiTypes.CarFinancing | undefined;
    error: unknown;
    isLoading: boolean;
    isValidating: boolean;
};

/**
 *
 * @param params
 * @param active true for requests to be sent
 * @returns
 */
export const useSantander = (params: CarFinancingParams, active?: boolean): UseSantanderReturnType => {
    const updatedParams = {
        IsFixedInterest: false,
        ObjectType: 'WhitePlate',
        ...params,
    };

    const { data, error, isLoading, isValidating } = useSWR<hesselApiTypes.CarFinancing>(
        active ? `/api/v1/Financing?${generateQueryString(updatedParams)}` : null,
        {
            revalidateOnFocus: false,
            revalidateIfStale: false,
        }
    );

    return { santanderFinancing: data, error, isLoading, isValidating };
};
