import { FC, useEffect, useState } from 'react';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { umbraco } from '../../../lib/api';
import { StyledHeaderContent, StyledHeaderLogoLink, StyledImageLogo, StyledMinimalHeader } from './minimal-site-header.styled';
import Link from 'next/link';
import { getCmsImageUrl } from '../../../utils/helpers';

type Props = {
    content?: umbraco.MinimalHeaderSettings;
};

export const MinimalSiteHeader: FC<Props> = ({ content }) => {
    const isMobile = useMediaQuery({ target: 'laptop_tablet_landscape' });

    const [scrolled, setScrolled] = useState(false);

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 25) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    if (!content) {
        return null;
    }

    return (
        <>
            <StyledMinimalHeader scrolled={scrolled}>
                <StyledHeaderContent>
                    <Link href="/">
                        <StyledHeaderLogoLink href="/">
                            {isMobile ? (
                                scrolled ? (
                                    <StyledImageLogo
                                        src={getCmsImageUrl(content.headerLogoAlternative.src)}
                                        alt={content?.headerLogoAlternative?.name}
                                    />
                                ) : (
                                    <StyledImageLogo src={getCmsImageUrl(content.headerLogo.src)} alt={content?.headerLogo?.name} />
                                )
                            ) : (
                                <StyledImageLogo src={getCmsImageUrl(content.headerLogoAlternative.src)} alt={content?.headerLogoAlternative?.name} />
                            )}
                        </StyledHeaderLogoLink>
                    </Link>

                    {/* <Link href="/">Log ind</Link> */}
                </StyledHeaderContent>
            </StyledMinimalHeader>
        </>
    );
};
