import { FC, useEffect, useState } from 'react';
import {
    StyledAlternativeDeliveryArea,
    StyledAlternativeDeliveryForm,
    StyledAlternativeDeliveryFormArea,
    StyledCustomerInfo,
    StyledDeliveryAddressHeader,
    StyledZipCityWrapper,
} from './home-delivery.styled';
import { CartStoreContext } from '../../../../../lib/state/shop/cart/cart.store';
import { CheckBox } from '../../../../shared';
import { TextInput } from '../../../../forms/inputs';
import { validateName, validateNotEmpty, validateZip } from '../../../../../utils/helpers';
import { useZipcodeFinder } from '../../../../../hooks/use-zipcode-finder';
import { AlternativeDelivery } from '../shop-delivery-step.component';

type FormField = {
    value: string;
    canValidate: boolean;
    isValid: boolean;
};

export type AlternativeDeliveryAddressForm = {
    name: FormField;
    address: FormField;
    city: FormField;
    zip: FormField;
};

type IProps = {
    updateAlternativeDeliveryRequest: (request: AlternativeDelivery) => void;
    submitDelivery: () => void;
};

export const HomeDelivery: FC<IProps> = ({ updateAlternativeDeliveryRequest, submitDelivery }) => {
    const [useAlternativeDeliveryAddress, setUseAlternativeDeliveryAddres] = useState(false);
    const { cart } = CartStoreContext.useStoreState((state) => state);

    const [alternativeAddressForm, setAlternativeAddressForm] = useState<AlternativeDeliveryAddressForm>({
        name: {
            value: '',
            canValidate: false,
            isValid: false,
        },
        address: {
            value: '',
            canValidate: false,
            isValid: false,
        },
        city: {
            value: '',
            canValidate: false,
            isValid: false,
        },
        zip: {
            value: '',
            canValidate: false,
            isValid: false,
        },
    });

    const { city: cityByZipCode } = useZipcodeFinder(alternativeAddressForm.zip.value);

    useEffect(() => {
        if (cityByZipCode && cityByZipCode.length > 0) {
            setAlternativeAddressForm({
                ...alternativeAddressForm,
                city: {
                    value: cityByZipCode,
                    isValid: validateNotEmpty(cityByZipCode),
                    canValidate: true,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityByZipCode]);

    useEffect(() => {
        updateAlternativeDeliveryRequest({
            alternativeDeliveryRequested: useAlternativeDeliveryAddress,
            alternativeAddress: alternativeAddressForm,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alternativeAddressForm, useAlternativeDeliveryAddress]);

    useEffect(() => {
        submitDelivery();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (cart === null || cart.customer === null) return null;
    return (
        <>
            <StyledDeliveryAddressHeader>Faktureringsadresse</StyledDeliveryAddressHeader>
            <StyledCustomerInfo>{cart.customer.firstName}</StyledCustomerInfo>
            <StyledCustomerInfo>{cart.customer.customerAddress.addressLine1}</StyledCustomerInfo>
            <StyledCustomerInfo>
                {cart.customer.customerAddress.zipCode} {cart.customer.customerAddress.city}
            </StyledCustomerInfo>
            <StyledCustomerInfo>{cart.customer.customerAddress.countryName}</StyledCustomerInfo>
            <StyledAlternativeDeliveryArea>
                <CheckBox
                    checked={!useAlternativeDeliveryAddress}
                    id="alternative-delivery-address"
                    onChange={() => setUseAlternativeDeliveryAddres(!useAlternativeDeliveryAddress)}
                    value="Alternative delivery address"
                >
                    Send til faktureringsadressen
                </CheckBox>
                {useAlternativeDeliveryAddress ? (
                    <StyledAlternativeDeliveryFormArea>
                        <StyledDeliveryAddressHeader>Leveringsadresse</StyledDeliveryAddressHeader>
                        <StyledAlternativeDeliveryForm>
                            <TextInput
                                id={'alternative-name'}
                                label={'NAVN'}
                                placeholder={`Navn på modtager`}
                                type={'text'}
                                onChange={(change) => {
                                    setAlternativeAddressForm({
                                        ...alternativeAddressForm,
                                        name: {
                                            value: change.target.value,
                                            canValidate: alternativeAddressForm.name.canValidate,
                                            isValid: validateName(change.target.value),
                                        },
                                    });
                                }}
                                onInputBlur={() =>
                                    setAlternativeAddressForm({
                                        ...alternativeAddressForm,
                                        name: {
                                            ...alternativeAddressForm.name,
                                            canValidate: true,
                                        },
                                    })
                                }
                                value={alternativeAddressForm.name.value}
                                isValid={alternativeAddressForm.name.isValid}
                                canValidateInputField={alternativeAddressForm.name.canValidate}
                            />
                            <TextInput
                                id={'alternative-address'}
                                label={'ADRESSE'}
                                placeholder={`Modtagers adresse`}
                                type={'text'}
                                onChange={(change) => {
                                    setAlternativeAddressForm({
                                        ...alternativeAddressForm,
                                        address: {
                                            value: change.target.value,
                                            canValidate: alternativeAddressForm.address.canValidate,
                                            isValid: validateNotEmpty(change.target.value),
                                        },
                                    });
                                }}
                                onInputBlur={() =>
                                    setAlternativeAddressForm({
                                        ...alternativeAddressForm,
                                        address: {
                                            ...alternativeAddressForm.address,
                                            canValidate: true,
                                        },
                                    })
                                }
                                value={alternativeAddressForm.address.value}
                                isValid={alternativeAddressForm.address.isValid}
                                canValidateInputField={alternativeAddressForm.address.canValidate}
                            />
                            <StyledZipCityWrapper>
                                <TextInput
                                    id={'alternative-zip'}
                                    label={'POST NR.'}
                                    placeholder={``}
                                    type={'text'}
                                    onChange={(change) => {
                                        setAlternativeAddressForm({
                                            ...alternativeAddressForm,
                                            zip: {
                                                value: change.target.value,
                                                canValidate: alternativeAddressForm.zip.canValidate,
                                                isValid: validateZip(change.target.value),
                                            },
                                        });
                                    }}
                                    onInputBlur={() =>
                                        setAlternativeAddressForm({
                                            ...alternativeAddressForm,
                                            zip: {
                                                ...alternativeAddressForm.zip,
                                                canValidate: true,
                                            },
                                        })
                                    }
                                    value={alternativeAddressForm.zip.value}
                                    isValid={alternativeAddressForm.zip.isValid}
                                    canValidateInputField={alternativeAddressForm.zip.canValidate}
                                />
                                <TextInput
                                    id={'alternative-city'}
                                    label={'BY'}
                                    placeholder={``}
                                    type={'text'}
                                    onChange={(change) => {
                                        setAlternativeAddressForm({
                                            ...alternativeAddressForm,
                                            city: {
                                                value: change.target.value,
                                                canValidate: alternativeAddressForm.city.canValidate,
                                                isValid: validateNotEmpty(change.target.value),
                                            },
                                        });
                                    }}
                                    onInputBlur={() =>
                                        setAlternativeAddressForm({
                                            ...alternativeAddressForm,
                                            city: {
                                                ...alternativeAddressForm.city,
                                                canValidate: true,
                                            },
                                        })
                                    }
                                    value={alternativeAddressForm.city.value}
                                    isValid={alternativeAddressForm.city.isValid}
                                    canValidateInputField={alternativeAddressForm.city.canValidate}
                                />
                            </StyledZipCityWrapper>
                        </StyledAlternativeDeliveryForm>
                    </StyledAlternativeDeliveryFormArea>
                ) : null}
            </StyledAlternativeDeliveryArea>
        </>
    );
};
