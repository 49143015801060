import { createContextStoreWithRuntimeModel } from '../../helpers/context-store.helpers';
import { productDetailsActions } from './product-details.actions';
import { productDetailsState } from './product-details.state';
import { ProductDetailsInjections, ProductDetailsState, ProductDetailsStoreModel } from './product-details.types';

export const ProductDetailsStore = createContextStoreWithRuntimeModel<
    ProductDetailsStoreModel,
    Partial<ProductDetailsState>,
    ProductDetailsInjections
>(
    (runtimeModel) => ({
        ...productDetailsState(runtimeModel),
        ...productDetailsActions(),
    }),
    { name: 'productDetailsStore' }
);
