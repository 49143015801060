import styled from 'styled-components';
import { device } from '../../../lib/media-query';
import { DropdownInput } from '../../forms/inputs';
import { Button } from '../../shared';

export const Filters = styled.div<{ colSpan: number }>`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    width: 100%;

    // if spot is set to be 2x1, show filters in a row. Otherwise, show them in a column
    @media ${device.desktop} {
        flex-direction: ${(props) => (props.colSpan > 1 ? 'row' : 'column')};
    }

    & > div {
        width: 100%;
    }

    & button {
        height: 100%;
        min-height: 50px;
        width: 100%;
        max-width: revert;

        @media ${device.desktop} {
            max-width: ${(props) => (props.colSpan > 1 ? '150px' : '100%')};
        }
    }
    margin-top: 15px;
`;

export const StyledCarFinderButton = styled(Button)`
    min-width: 200px;
`;

export const StyledPriceFilterDropdown = styled(DropdownInput)`
    min-width: revert;
    width: 100%;
    @media ${device.tablet} {
        min-width: 250px;
    }
`;
