import { useMemo, VFC } from 'react';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { contentSpotTypes } from '../../../lib/api';
import { ContentSpotRichText } from '../../shared/content-spot-rich-text/content-spot-rich-text.component';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';
import {
    MediaContainer,
    MediaImage,
    MediaVideo,
    SplitMediaListWrapper,
    SplitTextContent,
    SplitTextHeader,
    SplitTextWrapper,
} from './split-media-list.styled';
import { SpotHeader } from '../../shared/typography/spot-header/spot-header.component';
import { SplitMedia, SplitText } from '../../../lib/api/models/umbraco/content-spot';

export type SplitMediaListProps = {
    content: contentSpotTypes.SplitMediaList;
};

const MEDIA_URL = process.env.NEXT_PUBLIC_MEDIA_URL;

export const SplitMediaList: VFC<SplitMediaListProps> = ({ content }) => {
    const isMobile = useMediaQuery({ target: 'tablet' });

    const itemsHandler = useMemo(() => {
        const itemsToRender: Array<SplitMedia | SplitText> = [];
        const itemsToForward: Array<SplitMedia | SplitText> = [];
        if (content.items.length > 2) {
            itemsToRender.push(content.items[0], content.items[1]);
            itemsToForward.push(...content.items.slice(2));
        } else {
            itemsToRender.push(...content.items);
        }
        return {
            itemsToRender,
            itemsToForward,
        };
    }, [content.items]);

    const reverseOnDevice = useMemo(() => {
        if (itemsHandler.itemsToRender.length === 2) {
            const [first, second] = itemsHandler.itemsToRender;
            return first.alias === 'splitText' && second.alias === 'splitMedia';
        }
        return false;
    }, [itemsHandler.itemsToRender]);

    if (!content.items || content.items.length === 0) {
        return null;
    }

    const getMediaSrc = (media: contentSpotTypes.SplitMedia) => {
        if (isMobile && media.mediaMobile?.src) {
            return `${MEDIA_URL}/${media.mediaMobile.src}`;
        }
        return `${MEDIA_URL}/${media.media.src}`;
    };

    const getRenderedMedia = (media: contentSpotTypes.SplitMedia, isAboveFold: boolean) => {
        const isWebp = media.media.extension?.toLowerCase() === 'webp';

        if (
            (isMobile && media.mediaMobile?.type === 'file' && !isWebp) ||
            ((!media.mediaMobile || !isMobile) && media.media.type === 'file' && !isWebp)
        ) {
            const autoPlay = media.autoplayVideo;
            const loop = media.loopVideo;
            return (
                <MediaVideo useSquaredCorners={media.useSquaredCorners} playsInline={true} autoPlay={autoPlay} muted={true} loop={loop} controls>
                    <source src={`${getMediaSrc(media)}#t=0.001`} type="video/mp4"></source>
                    Your browser does not support HTML5 video.
                </MediaVideo>
            );
        }
        return (
            <MediaImage
                useSquaredCorners={media.useSquaredCorners}
                src={`${getMediaSrc(media)}`}
                alt={media?.media?.name ?? ''}
                loading={isAboveFold ? 'eager' : 'lazy'}
            />
        );
    };

    return (
        <>
            <CenteredBlock maxWidth={1110}>
                <SplitMediaListWrapper id={content.scrollAnchorId} reverse={reverseOnDevice}>
                    {itemsHandler.itemsToRender.map((x, index) => {
                        if (x.alias === 'splitText') {
                            return (
                                <SplitTextWrapper key={`${x.alias}-${index}`} index={index}>
                                    {x.headerSize && x.headerSize.length > 0 ? (
                                        <SpotHeader headerType={x.headerType} headerSize={x.headerSize}>
                                            {x.headerText}
                                        </SpotHeader>
                                    ) : (
                                        <SplitTextHeader as={x.headerType}>{x.headerText}</SplitTextHeader>
                                    )}
                                    <SplitTextContent>
                                        <ContentSpotRichText text={x.text.replace('/media/', `${MEDIA_URL}/media/`)} />
                                    </SplitTextContent>
                                </SplitTextWrapper>
                            );
                        }

                        if (x.alias === 'splitMedia') {
                            return <MediaContainer key={`${x.alias}-${index}`}>{getRenderedMedia(x, !!content.isAboveFold)}</MediaContainer>;
                        }

                        return null;
                    })}
                </SplitMediaListWrapper>
            </CenteredBlock>
            {itemsHandler.itemsToForward.length > 0 ? (
                <SplitMediaList content={{ ...content, scrollAnchorId: '', items: itemsHandler.itemsToForward }} />
            ) : null}
        </>
    );
};
