import { VFC, useState } from 'react';
import { ShopPdpUspBar, PdpUspBar } from '../../../lib/api/models/umbraco/product-details';
import { UspTemplate } from '../../shared/usp-template/usp-template.component';
import { StyledPdpUspBar, StyledCenteredBlock } from './pdp-usp-template-bar.styled';
import { uiTypes } from '../../../lib';
import { SidePanel } from '../../shared/modals/side-panel';
import { SidePanelLayout } from '../../shared/modals/side-panel-layout/side-panel-layout.component';
import { sanitizeMarkup } from '../../../utils/helpers';
import { StyledCloseButton, StyledDescription, StyledHeader } from '../../usp-bar-list/usp-bar-list.styled';
import { useUI } from '../../ui';
type PdpUspBarProps = {
    className?: string;
    uspBar?: PdpUspBar | ShopPdpUspBar;
};

export const PdpUspTemplateBar: VFC<PdpUspBarProps> = ({ className, uspBar }) => {
    const [sidePanelInfo, setSidePanelInfo] = useState<{
        visible: boolean;
        header?: string;
        headerType?: uiTypes.HeaderType;
        description?: string;
        closeButtonLabel?: string;
    }>({
        visible: false,
    });

    const uiHandler = useUI();

    const closeSidePanel = () => {
        uiHandler.applyScroll();
        setSidePanelInfo({ visible: false, header: undefined, description: undefined, closeButtonLabel: undefined });
    };

    return uspBar ? (
        <>
            <StyledCenteredBlock className={className}>
                <StyledPdpUspBar>
                    {uspBar.uspList.map((x, index) => {
                        return (
                            <UspTemplate
                                key={`usp-template-${index}`}
                                header={x.header}
                                description={x.description}
                                topSideIcon={x.topSideIcon}
                                bottomSideIcon={x.bottomSideIcon}
                                isAboveFold={false}
                                readMore={x.alias === 'richPdpUspTemplate'}
                                onUspClick={
                                    x.alias === 'richPdpUspTemplate' && x.text && x.text.length > 0
                                        ? () => {
                                              setSidePanelInfo({
                                                  ...x,
                                                  visible: true,
                                              });
                                          }
                                        : undefined
                                }
                            />
                        );
                    })}
                </StyledPdpUspBar>
            </StyledCenteredBlock>
            {uspBar.uspList.some((x) => x.alias === 'richPdpUspTemplate') ? (
                <SidePanel isVisible={sidePanelInfo.visible} cancelAction={() => closeSidePanel()}>
                    <SidePanelLayout closeSidePanel={() => closeSidePanel()}>
                        <StyledHeader as={sidePanelInfo.headerType}>{sidePanelInfo.header}</StyledHeader>
                        <StyledDescription {...sanitizeMarkup(sidePanelInfo.description)} />
                        <StyledCloseButton variant="primary" onClick={() => closeSidePanel()}>
                            <p>{sidePanelInfo.closeButtonLabel}</p>
                        </StyledCloseButton>
                    </SidePanelLayout>
                </SidePanel>
            ) : null}
        </>
    ) : null;
};
