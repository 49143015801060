import styled from 'styled-components';

export const StyledStarRatingContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 25px;
`;

export const StyledButtonContainer = styled.div`
    padding: 5px 5px 10px 5px;
`;
