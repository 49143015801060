import { animated } from 'react-spring';
import styled from 'styled-components';
import { device } from '../../lib/media-query';

export const StyledSearchOverlayContainer = styled(animated.div)`
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
`;

export const StyledFadeWrapper = styled(animated.div)``;

export const StyledSearchWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;

    @media ${device.laptop} {
        gap: 40px;
        grid-template-columns: minmax(0, 350fr) minmax(0, 995fr);
    }
`;

export const StyledRecommendedLinksWrapper = styled.div`
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(2, 1fr);

    @media ${device.mobile_tablet} {
        grid-template-columns: repeat(3, 1fr);
    }
`;

export const StyledSearchResultsBackground = styled.div`
    background-color: #f7f7f8;
    padding: 40px 0;
`;

export const StyledLogoText = styled.p`
    font-family: 'Corporate E';
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.1em;
    color: #002134;
    line-height: 1;
    user-select: none;
    cursor: default;
`;

export const StyledSearchHeaderArea = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 20px 0 25px;

    @media ${device.laptop} {
        padding: 60px 0 25px;
    }
`;

export const StyledSearchHeaderFlexContainer = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;

    @media ${device.laptop} {
        column-gap: 100px;
    }

    svg {
        cursor: pointer;
    }
`;

export const StyledSearchInputContainer = styled.div`
    order: 1;
    flex: 0 0 100%;
    position: relative;

    @media ${device.laptopS} {
        padding-left: 140px;
    }

    @media ${device.laptop} {
        order: 0;
        flex: 1 0 auto;
    }
`;

export const StyledClearSearch = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding-right: 8px;
    display: flex;
    align-items: center;
    background-color: transparent;
`;

export const StyledSearchInput = styled.input`
    width: 100%;
    padding: 15px 0;
    border: none;
    border-bottom: 1px solid #0b0b0b;
    font-weight: 600;
    font-size: 16px;

    &:focus-visible {
        outline: none;
    }

    @media ${device.laptop} {
        padding: 20px 32px;
    }
`;

export const StyledCloseText = styled.div`
    font-weight: 600;
    font-size: 16px;
    color: #767676;

    span {
        cursor: pointer;
    }
`;
