import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const ReceiptContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media ${device.tablet} {
        padding: 1rem;
    }
`;

export const Header = styled.h1`
    font-size: 30px;
    font-family: 'Corporate E';
    font-weight: bold;
    margin-bottom: 1rem;
`;

export const TextWrapper = styled.div`
    margin-top: 1rem;
    text-align: center;
    max-width: revert;

    @media ${device.tablet} {
        margin-top: 4rem;
        max-width: 765px;
    }
`;

export const Text = styled.div`
    p,
    a {
        font-size: 17px;
    }

    overflow: hidden;
    & img {
        width: auto;
        max-width: 320px;
        object-fit: cover;

        @media ${device.tablet} {
            max-width: 500px;
        }
    }

    & ul,
    & ol {
        list-style: revert;
        padding: revert;
        margin: revert;

        & li {
            text-align: left;
        }
    }
`;

export const FormSummary = styled.div`
    margin: 3rem 0;
`;

export const FormSummaryHeader = styled.h4`
    font-weight: bold;
    font-size: 14px;
`;

export const FormSummarySection = styled.div`
    margin-top: 1.5rem;
`;

export const CtaWrapper = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    @media ${device.tablet} {
        flex-direction: row;
        justify-content: center;
        max-width: 500px;
        gap: 2rem;
    }

    margin-bottom: 3rem;
`;
