import { FC, useMemo } from 'react';
import { SortingLabel } from '../../../../lib/api/models/umbraco';
import { useSelect } from 'downshift';

import {
    ArrowContainer,
    SortingDropdownOptions,
    SortingDropdownWrapper,
    SortingOption,
    SortingValue,
    StyledSortingLabel,
} from './vehicle-product-list-sorting-dropdown.styled';

import Image from 'next/image';

type VehicleProductListSortingDropdownProps = {
    options: SortingLabel[];
    onChangeSorting: (selection: string) => void;
    anyResults: boolean;
    showSorting?: boolean;
    selected: string;
};

export const VehicleProductListSortingDropdown: FC<VehicleProductListSortingDropdownProps> = ({
    options,
    onChangeSorting,
    anyResults,
    selected,
    showSorting = true,
}) => {
    const { isOpen, getItemProps, getLabelProps, getMenuProps, getToggleButtonProps, toggleMenu } = useSelect({
        items: options,
        itemToString: (item) => (item ? item.labelText : ''),
        onSelectedItemChange: ({ selectedItem }) => {
            onChangeSorting(selectedItem ? selectedItem.sortingKey : '');
            toggleMenu();
        },
    });

    const selectedSorting = useMemo(() => {
        return options.find((x) => x.sortingKey === selected);
    }, [options, selected]);

    return (
        <>
            {anyResults && showSorting && options.length > 0 ? (
                <SortingDropdownWrapper {...getToggleButtonProps()}>
                    <StyledSortingLabel {...getLabelProps()}>Sortér efter</StyledSortingLabel>
                    <SortingValue>{selectedSorting ? selectedSorting.labelText : 'Vælg sortering'}</SortingValue>
                    <ArrowContainer open={isOpen}>
                        <Image src="/icons/chevron/down.svg" width="20px" height="20px" />
                    </ArrowContainer>
                    <SortingDropdownOptions open={isOpen} {...getMenuProps()}>
                        {isOpen &&
                            options.map((item, index) => {
                                return (
                                    <SortingOption selected={item.sortingKey === selected} key={index} {...getItemProps({ item, index })}>
                                        {item.labelText}
                                    </SortingOption>
                                );
                            })}
                    </SortingDropdownOptions>
                </SortingDropdownWrapper>
            ) : null}
        </>
    );
};
