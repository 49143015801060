import { VFC } from 'react';
import { StyledCenteredBlock, StyledCustomerPortalBackground } from './custimer-portal.styled';
import { CustomerPortal as CustomerPortalType } from '../../../lib/api/models/umbraco/content-spot';

type IProps = {
    spot: CustomerPortalType;
};

export const CustomerPortal: VFC<IProps> = ({ spot }) => {
    if (typeof window === 'undefined') return null;

    const refUrl = encodeURIComponent(window.location.href);
    return (
        <StyledCustomerPortalBackground id={spot.scrollAnchorId}>
            <StyledCenteredBlock>
                <iframe
                    frameBorder="0"
                    src={`https://hessel-preference.flowstack.com/?referer_url=${refUrl}`}
                    title="Hessel Kunde"
                    width="100%"
                    height={820}
                    id="customerPortalFrame"
                ></iframe>
            </StyledCenteredBlock>
        </StyledCustomerPortalBackground>
    );
};
