import { useEffect, useState } from 'react';
import { getAPI } from '../lib/api/helpers';

type DawaResponse = {
    navn: string;
};

type ZipCodeReturn = {
    city: string;
};

export const useZipcodeFinder = (zipcode: string): ZipCodeReturn => {
    const [city, setCity] = useState('');
    const [latestZipParams, setLatestZipParams] = useState<string>();

    useEffect(() => {
        const getZipInfo = async () => {
            const [response, error] = await getAPI<DawaResponse>(`https://api.dataforsyningen.dk/postnumre/${zipcode}`);
            if (response && !error) {
                setCity(response.navn);
            } else if (error) {
                setCity('');
            }
        };

        if (zipcode.length === 4 && zipcode !== latestZipParams && !Number.isNaN(zipcode)) {
            getZipInfo();
            setLatestZipParams(zipcode);
        } else if (zipcode.length !== 4) {
            setLatestZipParams('');
        }
    }, [latestZipParams, zipcode]);

    return {
        city,
    };
};
