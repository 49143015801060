import { action } from 'easy-peasy';
import { createContextStoreWithRuntimeModel } from '../../lib/state/helpers/context-store.helpers';
import { GtmTrackingMode } from '../hooks/use-gtm-tracking';
import { GtmTrackingState, GtmTrackingActions, GtmTrackingStore as GtmTrackingStoreType } from './gtm-tracking.store-types';

const gtmTrackingDefaultState = (runtimeModel: Partial<GtmTrackingState> | undefined): GtmTrackingState => ({
    trackingMode: runtimeModel?.trackingMode ?? GtmTrackingMode.Disabled,
});

const gtmTrackingActions = (): GtmTrackingActions => ({
    setTrackingMode: action((state, payload) => {
        state.trackingMode = payload;
    }),
});

export const GtmTrackingStore = createContextStoreWithRuntimeModel<GtmTrackingStoreType, Partial<GtmTrackingState>>(
    (runtimeModel) => ({
        ...gtmTrackingDefaultState(runtimeModel),
        ...gtmTrackingActions(),
    }),
    { name: 'gtmTrackingStore' }
);
