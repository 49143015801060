export function hexToRGBA(hex: string, opacity: number): string {
    if (!hex || hex.length === 0 || opacity === null || opacity === undefined || isNaN(opacity)) {
        return '';
    }
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, '');

    // Parse the hex color
    let r: number, g: number, b: number;
    if (hex.length === 3) {
        // If it's a shorthand hex color
        r = parseInt(hex.charAt(0) + hex.charAt(0), 16);
        g = parseInt(hex.charAt(1) + hex.charAt(1), 16);
        b = parseInt(hex.charAt(2) + hex.charAt(2), 16);
    } else if (hex.length === 6) {
        // If it's a full hex color
        r = parseInt(hex.substring(0, 2), 16);
        g = parseInt(hex.substring(2, 4), 16);
        b = parseInt(hex.substring(4, 6), 16);
    } else {
        throw new Error('Invalid hex color: ' + hex);
    }

    // Return the RGBA color
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
