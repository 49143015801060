import { FC, Fragment } from 'react';
import { StyledFacetOptionIcon, StyledReadMoreToggle, StyledYourCarFacetOption, StyledYourCarSubFacetsWrapper } from './your-car-filter.styled';
import { FilterUpdateNotification } from '../../../../../lib/api/models/hessel-api/hire';
import { ShopFacetGroup, ShopFacetOption } from '../../../../../lib/api/models/shop/product-list';
import { MEDIA_URL } from '../../../../../utils/environment-constants';
import { ReadMoreWrapper } from '../../../../shared/read-more/read-more-wrapper.component';

type IProps = {
    group: ShopFacetGroup;
    onChange: (filterUpdate: FilterUpdateNotification[]) => void;
    notificationToSelectParent?: FilterUpdateNotification[];
    currentLevel?: number;
};

export const YourCarFilter: FC<IProps> = ({ group, onChange, notificationToSelectParent = [], currentLevel = 0 }) => {
    const facetClick = (facet: ShopFacetOption) => {
        const unselectNotifications: FilterUpdateNotification[] =
            notificationToSelectParent && notificationToSelectParent.length > 0
                ? [...notificationToSelectParent]
                : [{ action: 'Remove all in key', query: facet.filterQueryValue }];
        onChange(facet.selected ? [...unselectNotifications] : [{ action: 'Replace', query: facet.filterQueryValue }]);
    };

    const renderFacetUi = (facet: ShopFacetOption, idx: number) => {
        return (
            <Fragment key={`${facet.filterQueryValue}-${idx}`}>
                <StyledYourCarFacetOption selected={facet.selected} onClick={() => facetClick(facet)}>
                    <span>{facet.value} </span>
                    {facet.icon && facet.icon.length > 0 ? <StyledFacetOptionIcon src={`${MEDIA_URL}${facet.icon}`} /> : null}
                </StyledYourCarFacetOption>
                {(facet.selected && facet.subFacets) || (currentLevel === 0 && facet.subFacets) ? (
                    <StyledYourCarSubFacetsWrapper>
                        {facet.subFacets.map((subFacet, idx) => {
                            return (
                                <YourCarFilter
                                    key={`${subFacet.key}-${idx}`}
                                    group={subFacet}
                                    onChange={(filterUpdate) => onChange(filterUpdate)}
                                    notificationToSelectParent={[{ action: 'Replace', query: facet.filterQueryValue }]}
                                    currentLevel={currentLevel + 1}
                                />
                            );
                        })}
                    </StyledYourCarSubFacetsWrapper>
                ) : null}
            </Fragment>
        );
    };

    const subfacetsAboveReadMore = 6;

    return (
        <div>
            {group.facetOptions
                .filter((_, idx) => idx < subfacetsAboveReadMore)
                .map((facet, idx) => {
                    return renderFacetUi(facet, idx);
                })}
            {group.facetOptions.length > subfacetsAboveReadMore + 1 ? (
                <ReadMoreWrapper labelContent={<StyledReadMoreToggle>Vis alle modeller</StyledReadMoreToggle>} hideReadMoreAfterOpen={true}>
                    {group.facetOptions
                        .filter((_, idx) => idx >= subfacetsAboveReadMore)
                        .map((facet, idx) => {
                            return renderFacetUi(facet, idx);
                        })}
                </ReadMoreWrapper>
            ) : null}
        </div>
    );
};
