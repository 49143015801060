import styled from 'styled-components';

export const StyledAddVoucherWrapper = styled.div`
    margin: 20px 0;
`;

export const StyledVoucherToggleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
`;

export const StyledVoucherToggleText = styled.p`
    font-weight: 500;
    text-decoration: underline;
`;

export const StyledInputAndCtaWrapper = styled.div`
    padding-top: 10px;
    display: flex;
    gap: 10px;
`;

export const StyledErrorMessage = styled.p`
    margin-top: 10px;
    font-size: 12px;
    font-weight: 500;
    color: #aeaeaf;
`;
