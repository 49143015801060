import styled from 'styled-components';
import { VideoContainerProps } from './youtube-video-iframe.props';

export const VideoContainer = styled.div<VideoContainerProps>`
    position: relative;
    width: ${(props) => props.width};
    height: 0;
    padding-bottom: ${(props) => (props.height ? props.height : '56.25%')};
`;
export const YoutubeIframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
`;
