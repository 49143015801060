import { VFC } from 'react';
import { useSpring, config } from 'react-spring';
import {
    ButtonText,
    CarInfoWrapper,
    FinancialInfoWrapper,
    BrandTitle,
    VariantTitle,
    Prices,
    StyledButton,
    StyledStickySummary,
    TextWrapper,
    Title,
    Value,
    DownPaymentText,
} from './desktop.styled';
import { formatPrice } from '../../../../utils/helpers';
import { StickySummarySubComponentProps } from '../sticky-summary.component';
import { Abbreviations } from '../../../../constants/units-and-abbreviations';

export const StickySummaryDesktop: VFC<StickySummarySubComponentProps> = ({
    visible,
    variantTitle,
    brandTitle,
    period,
    primaryCta,
    selectedOwnershipTab,
    priceInfo,
    isCompanyCar,
}) => {
    const slideIn = useSpring({
        config: { ...config.slow, friction: 35 },
        top: visible ? '0px' : '-110px',
        delay: 500,
    });

    const { price, downPayment, monthly, vatIncluded, overrideText, priceTextOverride, isAFromPrice } = priceInfo;

    return (
        <StyledStickySummary style={slideIn}>
            <CarInfoWrapper>
                <BrandTitle>{brandTitle}</BrandTitle>
                <VariantTitle>{variantTitle}</VariantTitle>
            </CarInfoWrapper>
            <FinancialInfoWrapper>
                <Prices>
                    {selectedOwnershipTab === 'Car HiRE' ? (
                        <TextWrapper>
                            <Title>HesselHire</Title>
                            <Value>{period}</Value>
                        </TextWrapper>
                    ) : null}

                    <TextWrapper>
                        {overrideText ? (
                            <Value>{overrideText}</Value>
                        ) : (
                            <>
                                {' '}
                                <Title>
                                    {isAFromPrice ? 'Fra ' : null}
                                    {formatPrice(price)} {monthly ? Abbreviations.KR_SLASH_MD : Abbreviations.KR}
                                </Title>
                                {downPayment !== undefined && downPayment !== null ? (
                                    <DownPaymentText
                                        id="down-payment-sticky-summary-desktop"
                                        style={{
                                            color: '#636363',
                                            fontSize: '12px',
                                            letterSpacing: '0',
                                            fontWeight: 'normal',
                                            lineHeight: '18px',
                                        }}
                                    >
                                        Udbetaling {formatPrice(downPayment)}&nbsp;kr.
                                    </DownPaymentText>
                                ) : null}
                                {priceTextOverride ? (
                                    <Value>{priceTextOverride}</Value>
                                ) : (
                                    <Value>
                                        {!isCompanyCar ? `Priser er ${vatIncluded ? 'ekskl.' : 'inkl.'} moms.` : `Pris inkl. moms og afgifter`}
                                    </Value>
                                )}
                            </>
                        )}
                    </TextWrapper>
                </Prices>
                <StyledButton onClick={() => primaryCta.action?.()} variant="primary" unsetMinWidth={true}>
                    <ButtonText>{primaryCta.label}</ButtonText>
                </StyledButton>
            </FinancialInfoWrapper>
        </StyledStickySummary>
    );
};
