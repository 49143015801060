import { VFC } from 'react';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import { uiTypes } from '../../../../lib';
import { contentSpotTypes } from '../../../../lib/api';
import { CoverImage } from '../../../shared/cover-image/cover-image.component';
import { HeroVideo } from '../../../shared/hero-video';
import { CenteredBlock } from '../../../structural-blocks/centered-block.component';
import { MediaContent, MediaWrapper, MediaHeader, TextWrapper, StylesMediaHeader } from './media-item.styled';
import { isNullOrEmpty } from '../../../../utils/helpers';

type Props = {
    imageUrl?: string;
    imageMobileUrl?: string;
    videoUrl?: string;
    videoMobileUrl?: string;
    text?: string;
    headerText?: string;
    headerType?: uiTypes.HeaderType;
    headerSize?: uiTypes.HeaderSize;
    textPosition?: contentSpotTypes.Position;
    textColor?: string;
    isAboveFold: boolean;
    carouselIndex: number;
};

const MEDIA_URL = process.env.NEXT_PUBLIC_MEDIA_URL;

export const MediaItem: VFC<Props> = ({
    imageUrl,
    imageMobileUrl,
    videoUrl,
    videoMobileUrl,
    text,
    headerText,
    headerType,
    headerSize,
    textColor,
    textPosition,
    carouselIndex,
    isAboveFold,
}) => {
    const isMobile = useMediaQuery({ target: 'tablet' });

    return (
        <MediaWrapper showOverlay={!!text}>
            {!isMobile ? videoUrl ? <HeroVideo videoUrl={videoUrl} /> : null : null}
            {isMobile ? videoMobileUrl ? <HeroVideo videoUrl={videoMobileUrl} /> : null : null}
            {isMobile && !videoMobileUrl ? videoUrl ? <HeroVideo videoUrl={videoUrl} /> : null : null}
            {!videoUrl && !videoMobileUrl ? (
                <CoverImage
                    src={isMobile && !!imageMobileUrl ? imageMobileUrl : imageUrl}
                    loading={isAboveFold && carouselIndex === 0 ? 'eager' : 'lazy'}
                />
            ) : null}

            {text ? (
                <CenteredBlock>
                    <MediaContent textPosition={textPosition || 'Left'}>
                        {isNullOrEmpty(headerSize) ? (
                            <MediaHeader as={headerType} textColor={textColor || 'fff'}>
                                {headerText}
                            </MediaHeader>
                        ) : (
                            <StylesMediaHeader headerType={headerType} headerSize={headerSize} textColor={textColor || 'fff'}>
                                {headerText}
                            </StylesMediaHeader>
                        )}
                        <TextWrapper
                            textColor={textColor || 'fff'}
                            dangerouslySetInnerHTML={{ __html: text.replace('/media/', `${MEDIA_URL}/media/`) }}
                        />
                    </MediaContent>
                </CenteredBlock>
            ) : null}
        </MediaWrapper>
    );
};
