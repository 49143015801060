import { VFC } from 'react';
import { GoogleMap } from '../../google/_map-wrapper/map-wrapper.component';
import { DealershipInfoBox } from '../dealership-info-box/dealership-info-box.component';
import styled from 'styled-components';
import { FindDealershipsStore } from '../../../../lib/state/find-dealership';
import { DEFAULT_MAP_POSITION } from '../../../../constants/organization-consts';
import { StyleDealershipsMapImage } from './find-dealerships-desktop.styled';

export const FindDealershipsDesktop: VFC = () => {
    const { locations, userCurrentLocation, renderGoogleMaps, staticImageUrl } = FindDealershipsStore.useStoreState((state) => state);

    return (
        <StyledFindStores>
            <DealershipInfoBox />
            {renderGoogleMaps ? (
                <GoogleMap
                    center={{
                        lat: userCurrentLocation ? locations[0]?.lat ?? DEFAULT_MAP_POSITION.lat : DEFAULT_MAP_POSITION.lat,
                        lng: userCurrentLocation ? locations[0]?.lng ?? DEFAULT_MAP_POSITION.lng : DEFAULT_MAP_POSITION.lng,
                    }}
                />
            ) : (
                <StyleDealershipsMapImage src={staticImageUrl} />
            )}
        </StyledFindStores>
    );
};

export const StyledFindStores = styled.section`
    position: relative;
`;
