import { FC } from 'react';
import { StyledCircle } from './generic-circle.styled';

export interface CircleProps {
    className?: string;
    onClick?: () => void;
}

export const GenericCircle: FC<CircleProps> = ({ className, children, onClick }) => {
    return (
        <StyledCircle className={className} onClick={() => onClick?.()} isClickable={!!onClick}>
            {children}
        </StyledCircle>
    );
};
