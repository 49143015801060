import { useCombobox, useMultipleSelection } from 'downshift';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { useClickOutsideObserver } from '../../../../../hooks/use-click-outside-observer';
import { getUniqueId } from '../../../../../utils/helpers';
import { CheckBox } from '../../../../shared';
import { ScrollIndicator } from '../../../../shared/scroll-cue';
import { SvgIcon } from '../../../../shared/svg-icon';
import { BaseInput } from '../../base';
import { ComboBoxItem, Props } from './combo-box.props';
import {
    Placeholder,
    StyledList,
    StyledListFilters,
    StyledListItem,
    StyledListWrapper,
    StyledPlaceholder,
    StyledValue,
    ComboBoxDropdownContainer,
    ComboListContainer,
    StyledOption,
} from './combo-box.styled';

const valueIsDefined = (value?: ComboBoxItem): value is ComboBoxItem => (value?.value ? true : false);

const ComboBox: FC<Props> = ({
    value,
    onComboBoxItemSelected,
    items: options,
    isValid,
    disabled,
    showResetButton,
    placeholder,
    columns,
    ...props
}) => {
    const [showScrollCue, setShowScrollCue] = useState(false);
    const dropdownMenuRef = useRef<HTMLElement>(null);

    const { getDropdownProps } = useMultipleSelection<ComboBoxItem>();

    const itemToString = (item: ComboBoxItem) => (item ? item.value : '');

    const { getToggleButtonProps, getLabelProps, getMenuProps, getInputProps, getComboboxProps, getItemProps, toggleMenu } =
        useCombobox<ComboBoxItem>({
            items: options,
            stateReducer: (_, actionAndChanges) => {
                const { changes, type } = actionAndChanges;
                switch (type) {
                    case useCombobox.stateChangeTypes.InputKeyDownEnter:
                    case useCombobox.stateChangeTypes.ItemClick:
                        return {
                            ...changes,
                            isOpen: true, // keep the menu open after selection.
                        };
                }
                return changes;
            },
        });

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    useClickOutsideObserver(dropdownRef, () => setIsOpen(false), isOpen);
    const handleDocumentKeyDown = useCallback((e) => {
        if (e.key === 'Escape') {
            setIsOpen(false);
        }
    }, []);

    useEffect(() => {
        if (!disabled) {
            document.addEventListener('keydown', handleDocumentKeyDown, { passive: true });
        } else {
            document.removeEventListener('keydown', handleDocumentKeyDown);
        }

        return () => document.removeEventListener('keydown', handleDocumentKeyDown);
    }, [disabled, handleDocumentKeyDown]);

    useEffect(() => {
        const scrollHeight = dropdownMenuRef?.current?.scrollHeight ?? 0;
        const clientHeight = dropdownMenuRef?.current?.clientHeight ?? 0;

        if (isOpen && scrollHeight > clientHeight) {
            setShowScrollCue(true);
        }
    }, [isOpen]);

    const spin = useSpring({
        transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    });

    const anySelectedOptions = options.some((x) => x.isSelected === true);

    return (
        <ComboBoxDropdownContainer
            isOpen={isOpen}
            ref={dropdownRef}
            onClick={() => {
                if (!disabled) {
                    setIsOpen(!isOpen);
                    toggleMenu();
                }
            }}
        >
            <BaseInput
                {...props}
                isOpen={isOpen}
                value={value}
                isValid={anySelectedOptions}
                disabled={disabled}
                adornment={
                    <animated.div style={spin}>
                        <SvgIcon iconName={'chevron/down'} onClick={disabled ? undefined : toggleMenu} />
                    </animated.div>
                }
                labelProps={getLabelProps({ disabled })}
                valueIsDefined={valueIsDefined}
                comboBoxProps={getComboboxProps()}
                inputProps={getInputProps(getDropdownProps({ preventKeyAction: isOpen }))}
                placeholder={anySelectedOptions ? `${options.filter((x) => x.isSelected === true).length ?? 0} Valgt` : placeholder}
                canValidateInputField={props.canValidateInputField ?? false}
            >
                {({ placeholder, value }) => (
                    <>
                        <Placeholder {...getToggleButtonProps({ disabled })} onClick={disabled ? undefined : toggleMenu}>
                            {valueIsDefined(value) ? <StyledValue>{value.value}</StyledValue> : <StyledPlaceholder>{placeholder}</StyledPlaceholder>}
                        </Placeholder>
                    </>
                )}
            </BaseInput>

            <ComboListContainer isOpen={isOpen}>
                <StyledList
                    {...getMenuProps({ disabled, ref: dropdownMenuRef })}
                    onScroll={(e) => {
                        const scrollHasEnded = e.currentTarget.offsetHeight + e.currentTarget.scrollTop >= e.currentTarget.scrollHeight;

                        if (scrollHasEnded) {
                            setShowScrollCue(false);
                        } else if (!showScrollCue) {
                            setShowScrollCue(true);
                        }
                    }}
                >
                    {isOpen && (
                        <StyledListWrapper>
                            <StyledListFilters columns={columns}>
                                {options.map((opt, idx) => {
                                    return (
                                        <StyledListItem key={idx} disabled={!!opt.disabled}>
                                            <div
                                                {...getItemProps({
                                                    item: opt,
                                                    index: idx,
                                                    style: {},
                                                    disabled,
                                                })}
                                            >
                                                <CheckBox
                                                    id={getUniqueId()}
                                                    checked={opt.isSelected === true}
                                                    onChange={(checked) => {
                                                        onComboBoxItemSelected(checked, opt);
                                                    }}
                                                    canValidate={false}
                                                    value={itemToString(opt)}
                                                    checkboxSize={16}
                                                >
                                                    <StyledOption>{itemToString(opt)}</StyledOption>
                                                </CheckBox>
                                            </div>
                                        </StyledListItem>
                                    );
                                })}
                            </StyledListFilters>
                        </StyledListWrapper>
                    )}
                </StyledList>
                {showScrollCue && isOpen && <ScrollIndicator icon="/icons/chevron/down.svg" />}
            </ComboListContainer>
        </ComboBoxDropdownContainer>
    );
};

export default ComboBox;
