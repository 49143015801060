import styled from 'styled-components';
import { device } from '../../../lib/media-query';
import { mixins } from '../../../themes';

export const AllEquipmentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding-right: 10px;
    padding-left: 10px;

    @media ${device.tablet} {
        padding-right: 25px;
        padding-left: 20px;
    }
`;
export const EquipmentInfoWrapper = styled.div``;

export const Header = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;

    margin-bottom: 15px;
`;

export const AccordionListWrapper = styled.div``;

export const AccordionHeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    scroll-margin-top: 90px;
    @media ${device.tablet} {
        scroll-margin-top: revert;
    }
`;

export const StyledHeader = styled.p`
    color: #0b0b0b;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 26px;
    @media ${device.tablet} {
        font-size: 14px;
    }
`;

export const StyledEquipmentPrice = styled.p`
    color: #807f80;
    font-size: 12px;
    letter-spacing: 0;
    text-align: left;
    font-weight: 600;
    @media ${device.tablet} {
        font-size: 13px;
    }
`;

export const StyledAccordionContent = styled.div`
    padding: 0px 0;

    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const StyledText = styled.p`
    color: #0b0b0b;
    font-size: 14px;
    letter-spacing: 0;
    font-weight: normal;
`;

export const StyledUl = styled.ul`
    list-style-type: disc;
    padding-inline-start: 1.2em;
`;

export const StyledLi = styled.li``;

export const ImageWrapper = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 1rem;
`;

export const StyledImage = styled.img<{ useSquaredCorners?: boolean }>`
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center center;
    ${({ useSquaredCorners }) => mixins.useSquaredCorners(useSquaredCorners)}
`;

export const StyledPackageContent = styled.div`
    margin-bottom: 1rem;
`;

export const PackageHeaderAndResources = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
