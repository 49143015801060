import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const StyledContactSectionWrapper = styled.div`
    background-color: #f7f7f8;
    padding: 30px 35px;
`;

export const StyledContactSectionHeader = styled.p`
    font-family: 'Corporate E';
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 20px;
`;

export const StyledCompanyInfoWrapper = styled.section`
    margin-top: 30px;
`;

export const StyledCompanyInfo = styled.p`
    font-weight: 500;
    font-size: 12px;
`;

export const StyledCompanyCVR = styled.p`
    margin-top: 5px;
    font-size: 12px;
`;

export const StyledCtaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;

    @media ${device.laptopS} {
        flex-direction: row;
    }
`;

export const StyledFormWrapper = styled.div`
    padding: 25px 50px 50px;
`;

export const StyledCloseActions = styled.div`
    display: flex;
    justify-content: space-between;
`;
