import {
    ConditionBuilder,
    ContentSearchBuilder,
    ContentSearchResponse,
    PopularSearchTermsRecommendationBuilder,
    ProductSearchBuilder,
    ProductSearchResponse,
    Recommender,
    SearchCollectionBuilder,
    SearchTermPredictionBuilder,
    SearchTermPredictionResponse,
    SearchTermRecommendationResponse,
    Searcher,
    Settings,
} from '@relewise/client';
import { getRelewiseUser, relewiseApiSetup } from '../../../utils/helpers/relewise.helpers';

export type RelewiseSearchResponse = {
    searchTermsResults: SearchTermPredictionResponse;
    productSearchResults: ProductSearchResponse;
    contentSearchResults: ContentSearchResponse;
};

export const vehicleDataKeys = [
    'Url',
    'ImageUrl',
    'Name',
    'Family',
    'ParentColors',
    'DownPayment',
    'BaseMonthlyPrice',
    'ModelTitle',
    'SantanderComplianceText',
    'AvailabilityCode',
    'MixedDriving',
    'MixedDrivingValue',
    'CategoryTypeCode',
    'OdometerReadingInKm',
    'FuelType',
    'ModelYear',
    'HorsePower',
    'TowingWeight',
];

export function getSettings(segment: string): Settings {
    return {
        language: 'da-DK',
        currency: 'DKK',
        displayedAtLocation: 'search overlay',
        user: getRelewiseUser(segment),
    };
}

export const doRelewiseSearch = async (searchTerm: string, segment: string): Promise<RelewiseSearchResponse | undefined> => {
    if (relewiseApiSetup.apiKey && relewiseApiSetup.dataset && relewiseApiSetup.serverUrl) {
        const searcher = new Searcher(relewiseApiSetup.dataset, relewiseApiSetup.apiKey, {
            serverUrl: relewiseApiSetup.serverUrl,
        });

        const settings = getSettings(segment);

        const contentSearchBuilder = new ContentSearchBuilder(settings)
            .setTerm(searchTerm)
            .pagination((p) => p.setPageSize(9).setPage(1))
            .setContentProperties({
                displayName: true,
                dataKeys: ['url', 'metaDescription', 'metaTitle'],
            });

        const productSearchBuilder = new ProductSearchBuilder(settings)
            .setTerm(searchTerm)
            .pagination((p) => p.setPageSize(9).setPage(1))
            .setSelectedProductProperties({
                displayName: true,
                brand: true,
                pricing: true,
                dataKeys: vehicleDataKeys,
            })
            .filters((f) =>
                f.addProductDataFilter('ProductType', (cb: ConditionBuilder) =>
                    cb.addEqualsCondition({ type: 'String', isCollection: false, value: 'vehicle' })
                )
            );

        const searchTermPredicter = new SearchTermPredictionBuilder(settings).setTerm(searchTerm).take(5);

        const searchCollectionBuilder = new SearchCollectionBuilder(settings)
            .addRequest(productSearchBuilder.build())
            .addRequest(searchTermPredicter.build())
            .addRequest(contentSearchBuilder.build());

        const result = await searcher.batch(searchCollectionBuilder.build());

        if (result && result.responses && result.responses.length === 3) {
            const productSearchResults = result.responses[0] as ProductSearchResponse;
            const searchTermsResults = result.responses[1] as SearchTermPredictionResponse;
            const contentSearchResults = result.responses[2] as ContentSearchResponse;
            return {
                productSearchResults,
                searchTermsResults,
                contentSearchResults,
            };
        }
    }
};

export const getPopularSearchTerms = async (segment: string): Promise<SearchTermRecommendationResponse | undefined> => {
    if (relewiseApiSetup.apiKey && relewiseApiSetup.dataset && relewiseApiSetup.serverUrl) {
        const searcher = new Recommender(relewiseApiSetup.dataset, relewiseApiSetup.apiKey, {
            serverUrl: relewiseApiSetup.serverUrl,
        });

        const settings = getSettings(segment);

        const popularSearchTermBuilder = new PopularSearchTermsRecommendationBuilder(settings).addEntityType('Product');

        return await searcher.recommendPopularSearchTerms(popularSearchTermBuilder.build());
    }
};

export const getNextPageOfVehicles = async (searchTerm: string, page: number, segment: string): Promise<ProductSearchResponse | undefined> => {
    if (relewiseApiSetup.apiKey && relewiseApiSetup.dataset && relewiseApiSetup.serverUrl) {
        const settings = getSettings(segment);
        const searcher = new Searcher(relewiseApiSetup.dataset, relewiseApiSetup.apiKey, {
            serverUrl: relewiseApiSetup.serverUrl,
        });
        const productSearchBuilder = new ProductSearchBuilder(settings)
            .setTerm(searchTerm)
            .pagination((p) => p.setPageSize(9).setPage(page))
            .setSelectedProductProperties({
                displayName: true,
                brand: true,
                pricing: true,
                dataKeys: vehicleDataKeys,
            });

        return await searcher.searchProducts(productSearchBuilder.build());
    }
};
