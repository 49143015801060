import styled, { DefaultTheme } from 'styled-components';
import { Size } from '../../../lib/ui-types';
import { SITE_NAME, isSiteName } from '../../../utils/helpers/site-id.helper';

function getFontSize(theme: DefaultTheme, fontSize?: Size) {
    if (isSiteName(SITE_NAME.CARSAVER)) {
        switch (fontSize) {
            case 'small':
                return '12px';
            case 'medium':
                return '16px';
            case 'large':
                return '18px';

            default:
                return `${theme.buttonDefault.fontSize}px`;
        }
    } else {
        switch (fontSize) {
            case 'small':
                return '10px';
            case 'medium':
                return '12px';
            case 'large':
                return '16px';

            default:
                return `${theme.buttonDefault.fontSize}px`;
        }
    }
}

export const LargeLink = styled.a`
    color: ${isSiteName(SITE_NAME.CARSAVER) ? '#000' : '#fcfcfc'};
    display: block;
    font-size: ${({ theme }) => theme.typography.bodyDefault.fontSize};
    letter-spacing: 0;
    /* line-height: 24px; */
    margin-bottom: 6px;
    cursor: pointer;
    text-decoration: ${isSiteName(SITE_NAME.CARSAVER) ? 'underline' : 'none'};

    &:hover {
        text-decoration: underline;
    }
`;

export const StyledCtaText = styled.span<{ fontSize?: Size }>`
    font-size: ${(props) => getFontSize(props.theme, props.fontSize)};
    font-weight: ${(props) => props.theme.buttonDefault.fontWeight};
`;

export const StyledCarSaverLink = styled.a`
    line-height: 18px;
    margin-bottom: 6px;
`;

export const StyledCarSaverUnderline = styled.span`
    border-bottom: 1px solid #00000033;
`;
