import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { DealershipInformationPage } from '../../../../../lib/api/models/umbraco';
import { BlackPinUrl, BluePinUrl } from '../../../../organization/helpers/organization-consts';

type ShopPickupMapsProps = {
    pickupDestinations: Array<DealershipInformationPage>;
    selectedPickupDestination: string;
    setSelectedPickupDestination: (destination: string) => void;
};

type PickupDestination = {
    destination: DealershipInformationPage;
    marker: google.maps.Marker;
};

export const ShopPickupMap: FC<ShopPickupMapsProps> = ({ pickupDestinations, selectedPickupDestination, setSelectedPickupDestination }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map>();
    const [allPickupDestinationsWithMarkers, setAllPickupDestinationsWithMarkers] = useState<Array<PickupDestination>>([]);

    const mapPinPosition = useMemo(() => new google.maps.Point(14.5, 17), []);
    const mapPinSizeScale = useMemo(() => new google.maps.Size(30, 45), []);

    useEffect(() => {
        if (ref.current && !map) {
            setMap(
                new window.google.maps.Map(ref.current, {
                    center: { lat: pickupDestinations[0].latitude, lng: pickupDestinations[0].longitude },
                    zoom: 10,
                    mapTypeControl: false,
                    streetViewControl: false,
                    fullscreenControl: false,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.TOP_RIGHT,
                    },
                    gestureHandling: 'cooperates',
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, map]);

    const getPickcupDestinationsByProximity = (zipCode: number) => {
        return allPickupDestinationsWithMarkers.sort(
            (a, b) => Math.abs(Number(a.destination.zipcode) - zipCode) - Math.abs(Number(b.destination.zipcode) - zipCode)
        );
    };

    useEffect(() => {
        const selection = allPickupDestinationsWithMarkers.find((x) => x.destination.hovedafdelingId === selectedPickupDestination);
        allPickupDestinationsWithMarkers.forEach((x) => {
            x.marker.setIcon({
                url: `/${x === selection ? BluePinUrl : BlackPinUrl}`,
                labelOrigin: mapPinPosition,
                scaledSize: mapPinSizeScale,
            });
        });
        if (map && selection) {
            const newBounds = new google.maps.LatLngBounds();
            const sortedDealerships = getPickcupDestinationsByProximity(Number(selection.destination.zipcode));
            sortedDealerships
                .filter((_x, idx) => idx < 2)
                .forEach((y) => {
                    const markerPosition = y.marker.getPosition();
                    if (markerPosition) newBounds.extend(markerPosition);
                });
            map.fitBounds(newBounds);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPickupDestination]);

    useEffect(() => {
        if (allPickupDestinationsWithMarkers.length > 0) {
            allPickupDestinationsWithMarkers.forEach((shop) => {
                shop.marker.addListener('click', () => {
                    setSelectedPickupDestination(shop.destination.hovedafdelingId);
                    allPickupDestinationsWithMarkers.forEach((x) => {
                        x.marker.setIcon({
                            url: `/${x === shop ? BluePinUrl : BlackPinUrl}`,
                            labelOrigin: mapPinPosition,
                            scaledSize: mapPinSizeScale,
                        });
                    });
                });
            });
        }
    }, [allPickupDestinationsWithMarkers, mapPinPosition, mapPinSizeScale, setSelectedPickupDestination]);

    useEffect(() => {
        if (map && pickupDestinations) {
            const acc: Array<PickupDestination> = [];
            pickupDestinations.forEach((x) => {
                const selected = selectedPickupDestination === x.hovedafdelingId;
                const marker = new google.maps.Marker({
                    map,
                    position: {
                        lat: x.latitude,
                        lng: x.longitude,
                    },
                    label: {
                        text: 'EH',
                        color: 'white',
                        fontFamily: 'Corporate E',
                        fontSize: '15px',
                        fontWeight: '500',
                    },
                    icon: {
                        url: `/${selected ? BluePinUrl : BlackPinUrl}`,
                        labelOrigin: mapPinPosition,
                        scaledSize: mapPinSizeScale,
                    },
                });
                acc.push({ destination: x, marker });
            });
            setAllPickupDestinationsWithMarkers(acc);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, pickupDestinations]);

    return <div style={{ width: '100%', height: '350px', marginTop: '20px' }} ref={ref}></div>;
};
