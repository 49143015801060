import { FC } from 'react';
import { StyledCard, StyledContainer, StyledSubTitle, StyledEmployeeImage, StyledContent } from './employee-contact-info-card.styled';
import { DealershipInformationPage } from '../../../lib/api/models/umbraco';
import { getCmsImageUrl } from '../../../utils/helpers';
import { Mail, MapPin, Phone } from '../../icons';
import BulletPointList from '../bullet-point-list/bullet-point-list.component';
import { Employee } from '../../../lib/api/models/hessel-api/sales-video';

type Props = {
    title: string;
    subTitle: string;
    employee?: Employee;
    department?: DealershipInformationPage;
};

const EmployeeContactInfoCard: FC<Props> = ({ title, subTitle, employee, department }) => {
    return (
        <StyledContainer>
            <h1>{title}</h1>
            <StyledSubTitle>{subTitle}</StyledSubTitle>
            <StyledCard>
                <StyledEmployeeImage src={employee?.webPicture ?? getCmsImageUrl(department?.employeesPlaceholderImage.src ?? '')} />
                <StyledContent>
                    <h2>{employee?.name}</h2>
                    <BulletPointList
                        points={[
                            {
                                name: 'Telefon',
                                value: employee?.phone ?? '',
                                icon: <Phone />,
                                link: `tel:${employee?.phone}`,
                            },
                            {
                                name: 'E-mail',
                                value: employee?.email ?? '',
                                icon: <Mail />,
                                link: `mailto:${employee?.email}`,
                            },
                        ]}
                    ></BulletPointList>
                </StyledContent>
                <StyledContent>
                    <h2>{department?.displayName}</h2>
                    <BulletPointList
                        points={[
                            {
                                name: 'Adresse',
                                value: department?.address ?? '',
                                icon: <MapPin />,
                            },
                            {
                                name: 'Post nr. & by',
                                value: department?.zipcode && department?.city ? `${department?.zipcode}, ${department?.city}` : '',
                                icon: <MapPin />,
                            },
                        ]}
                    ></BulletPointList>
                </StyledContent>
            </StyledCard>
        </StyledContainer>
    );
};

export default EmployeeContactInfoCard;
