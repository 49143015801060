import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { BlackPinUrl, BluePinUrl } from '../../../../organization/helpers/organization-consts';
import { ParcelShop } from '../../../../../lib/api/models/shop/parcel-shops';

type ShopPickupMapsProps = {
    parcelShops: Array<ParcelShop>;
    selectedParcelShop: ParcelShop;
    setSelectedParcelShop: (parcelShop: ParcelShop) => void;
};

type PickupDestination = {
    destination: ParcelShop;
    marker: google.maps.Marker;
};

export const ParcelShopMap: FC<ShopPickupMapsProps> = ({ parcelShops, selectedParcelShop, setSelectedParcelShop: setSelectedPickupDestination }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map>();
    const [allParcelShopsWithMarkers, setAllParcelShopsWithMarkers] = useState<Array<PickupDestination>>([]);

    const mapPinPosition = useMemo(() => new google.maps.Point(14.5, 17), []);
    const mapPinSizeScale = useMemo(() => new google.maps.Size(30, 45), []);

    useEffect(() => {
        if (ref.current && !map) {
            setMap(
                new window.google.maps.Map(ref.current, {
                    center: { lat: parcelShops[0].latitude, lng: parcelShops[0].longitude },
                    zoom: 10,
                    mapTypeControl: false,
                    streetViewControl: false,
                    fullscreenControl: false,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.TOP_RIGHT,
                    },
                    gestureHandling: 'cooperates',
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, map]);

    useEffect(() => {
        const selection = allParcelShopsWithMarkers.find((x) => x.destination.id === selectedParcelShop.id);
        allParcelShopsWithMarkers.forEach((x) => {
            x.marker.setIcon({
                url: `/${x === selection ? BluePinUrl : BlackPinUrl}`,
                labelOrigin: mapPinPosition,
                scaledSize: mapPinSizeScale,
            });
        });
        if (map) {
            const newBounds = new google.maps.LatLngBounds();
            allParcelShopsWithMarkers.forEach((y) => {
                const markerPosition = y.marker.getPosition();
                if (markerPosition) newBounds.extend(markerPosition);
            });
            map.fitBounds(newBounds);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allParcelShopsWithMarkers, selectedParcelShop]);

    useEffect(() => {
        if (allParcelShopsWithMarkers.length > 0) {
            allParcelShopsWithMarkers.forEach((shop) => {
                shop.marker.addListener('click', () => {
                    setSelectedPickupDestination(shop.destination);
                    allParcelShopsWithMarkers.forEach((x) => {
                        x.marker.setIcon({
                            url: `/${x === shop ? BluePinUrl : BlackPinUrl}`,
                            labelOrigin: mapPinPosition,
                            scaledSize: mapPinSizeScale,
                        });
                    });
                });
            });
        }
    }, [allParcelShopsWithMarkers, mapPinPosition, mapPinSizeScale, setSelectedPickupDestination]);

    useEffect(() => {
        if (map && allParcelShopsWithMarkers) {
            const acc: Array<PickupDestination> = [];
            parcelShops.forEach((x) => {
                const selected = selectedParcelShop === x;
                const marker = new google.maps.Marker({
                    map,
                    position: {
                        lat: x.latitude,
                        lng: x.longitude,
                    },
                    label: {
                        text: 'GLS',
                        color: 'white',
                        fontFamily: 'Corporate E',
                        fontSize: '15px',
                        fontWeight: '500',
                    },
                    icon: {
                        url: `/${selected ? BluePinUrl : BlackPinUrl}`,
                        labelOrigin: mapPinPosition,
                        scaledSize: mapPinSizeScale,
                    },
                });
                acc.push({ destination: x, marker });
            });
            setAllParcelShopsWithMarkers(acc);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, parcelShops]);

    return <div style={{ width: '100%', height: '350px', marginTop: '20px' }} ref={ref}></div>;
};
