import { VFC } from 'react';
import { BorderBox, StyledLabel, ValuesWrapper, PriceLabel, DownPayment } from './total-price.styled';

type Props = {
    totalPrice: string;
};

export const TotalPrice: VFC<Props> = ({ totalPrice }) => {
    return (
        <BorderBox>
            <StyledLabel>Pris i alt inkl. moms</StyledLabel>

            <ValuesWrapper>
                <PriceLabel>{totalPrice}</PriceLabel>
                <DownPayment>Udbetaling 0 kr.</DownPayment>
            </ValuesWrapper>
        </BorderBox>
    );
};
