import { AriaRole, FC, useState } from 'react';
import { ToolTipContent } from '../tool-tip-content/tool-tip-content.component';
import { StyledLabelAndTooltip, StyledSellingPoint, StyledSellingPointIcon, StyledSellingPointLabel } from './selling-point.styled';
import { useMediaQuery } from '../../../hooks/use-media-query';

export type SellingPointProps = {
    className?: string;
    iconUrl: string;
    isEmphasized?: boolean;
    tooltip?: string;
    label: string;
    role?: AriaRole;
    onlyForHireAmgPackages?: boolean;
};

export const SellingPoint: FC<SellingPointProps> = (props) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const isDevice = useMediaQuery({
        target: 'tablet',
    });

    const LabelUi = <StyledSellingPointLabel isEmphasized={!!props.isEmphasized}>{props.label}</StyledSellingPointLabel>;

    return (
        <StyledSellingPoint className={props.className} role={props.role}>
            <StyledSellingPointIcon alt="" src={props.iconUrl} />

            {props.tooltip ? (
                <StyledLabelAndTooltip
                    onMouseEnter={(e) => {
                        e.stopPropagation();
                        setTooltipOpen(true);
                    }}
                    onMouseLeave={(e) => {
                        e.stopPropagation();
                        setTooltipOpen(false);
                    }}
                >
                    {LabelUi}
                    {props.tooltip ? (
                        <ToolTipContent isOpen={tooltipOpen} openDirection={isDevice ? 'right' : 'left'}>
                            {props.tooltip}
                        </ToolTipContent>
                    ) : null}
                </StyledLabelAndTooltip>
            ) : (
                LabelUi
            )}
        </StyledSellingPoint>
    );
};
