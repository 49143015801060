import useSWR from 'swr';
import { DepartmentFilterResponse, getDepartmentsForFilter } from '../lib/api/organization/hessel-dealership-api';

type UseCmsDealershipsReturnType = {
    departmentOptions: Array<DepartmentFilterResponse> | undefined;
    isLoading: boolean;
    error: unknown;
};

const departmentsUrl = 'api/v1/dealership';
const starMarkDepartmentsUrl = 'content/api/v1/content/alias/starmarkDealershipInformation';

const fetcher = async (url: string) => {
    const [departments, error] = await getDepartmentsForFilter(url);
    if (error) throw error;
    return departments;
};

export const useCmsDealerships = (shouldFetch = false): UseCmsDealershipsReturnType => {
    const { data: departmentOptions, error } = useSWR(shouldFetch ? departmentsUrl : null, fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        dedupingInterval: Infinity,
    });

    const { data: starmarkDepartmentOptions, error: starmarkError } = useSWR(shouldFetch ? starMarkDepartmentsUrl : null, fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        dedupingInterval: Infinity,
    });

    const isLoading = !error && !departmentOptions && !starmarkDepartmentOptions && !starmarkError;

    return {
        departmentOptions: [...(departmentOptions ?? []), ...(starmarkDepartmentOptions ?? [])],
        isLoading,
        error,
    };
};
