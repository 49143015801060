import styled from 'styled-components';
import { Close } from '../../../icons';
import { IconButtonWithLabel } from '../../icon-button-with-label/icon-button-with-label.component';
import { TabbedCarousel } from './tabbed-carousel.component';

export const StyledTabbedCarousel = styled(TabbedCarousel)`
    height: 100%;
`;

export const StyledClosedButton = styled(IconButtonWithLabel).attrs({
    children: Close({}),
})`
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10;
`;
