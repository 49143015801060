import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import { ContactSection, SecondaryLink } from '../../../lib/api/models/umbraco/mega-menu.types';
import { SvgIcon } from '../../shared/svg-icon';
import { ToggleUi } from '../../shared/toggle-ui';
import { ContactSection as ContactSectionComponent } from '../contact-section/contact-section.component';
import { MenuContext } from '../mega-menu.component';
import { StyledTextLogo } from '../mega-menu.styled';
import { useMegaMenu } from '../use-mega-menu';
import { StyledActionWrapper, StyledBurgerLink, StyledCloseIcon, StyledLinkWrapper, StyledWrapper } from './burger-menu-desktop.styled';

type IProps = {
    closeAction: () => void;
    menuContext: MenuContext;
    links: SecondaryLink[];
    erhvervLinks: SecondaryLink[];
    contactSection: ContactSection;
    pageId: string;
};

export const BurgerMenuDesktop: FC<IProps> = ({ closeAction, menuContext, links, erhvervLinks, contactSection, pageId }) => {
    const [toggleState, setToggleState] = useState(menuContext);
    const router = useRouter();

    useEffect(() => {
        if (menuContext !== toggleState) {
            setToggleState(menuContext);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuContext]);

    const closeBurgerMenu = () => {
        setToggleState(menuContext);
        closeAction();
    };

    const updateToggleState = (newState: string) => {
        if (newState === 'privat') setToggleState('privat');
        if (newState === 'erhverv') setToggleState('erhverv');
    };

    const { homeUrl } = useMegaMenu(toggleState);

    return (
        <StyledWrapper>
            <StyledActionWrapper>
                <ToggleUi
                    options={[
                        {
                            label: 'Privat',
                            value: 'privat',
                        },
                        {
                            label: 'Erhverv',
                            value: 'erhverv',
                        },
                    ]}
                    selectedOption={menuContext.toString()}
                    onChange={(newState) => updateToggleState(newState)}
                />
                <StyledCloseIcon onClick={() => closeBurgerMenu()}>
                    <SvgIcon iconName="close" />
                </StyledCloseIcon>
            </StyledActionWrapper>
            <div style={{ marginTop: '40px' }}>
                <StyledTextLogo
                    onClick={() => {
                        closeBurgerMenu();
                        router.push(homeUrl);
                    }}
                >
                    EJNER HESSEL
                </StyledTextLogo>
            </div>
            <StyledLinkWrapper>
                {toggleState === 'privat'
                    ? links.map((x, idx) => {
                          return (
                              <Link key={`privat-link-${idx}`} href={x.linkDestination.url} passHref>
                                  <StyledBurgerLink onClick={() => closeBurgerMenu()}>{x.linkText}</StyledBurgerLink>
                              </Link>
                          );
                      })
                    : erhvervLinks.map((x, idx) => {
                          return (
                              <Link key={`erhverv-link-${idx}`} href={x.linkDestination.url} passHref>
                                  <StyledBurgerLink onClick={() => closeBurgerMenu()}>{x.linkText}</StyledBurgerLink>
                              </Link>
                          );
                      })}
            </StyledLinkWrapper>
            <ContactSectionComponent contactSection={contactSection} pageId={pageId} closeAction={() => closeBurgerMenu()} />
        </StyledWrapper>
    );
};
