import { Action, Thunk } from 'easy-peasy';
import { APIError } from '../../../../api';
import { hesselApiTypes } from '../../../../api/models/hessel-api';
import { ErrorInjections } from '../../../helpers/context-store.types';
import { storeInputTypes } from '../../../_shared';

export enum HireCheckoutMode {
    Private = 1,
    Business = 0,
}

export type HireCheckoutFormState = {
    inputs: storeInputTypes.FormInput<string>[];
    isCheckoutComplete: boolean;
    checkoutMode: HireCheckoutMode;
};

export type HireCheckoutFormActions = {
    onChange: Action<HireCheckoutFormStore, { id: string; value: string }>;
    onChangeMultiple: Action<HireCheckoutFormStore, { rootId: string; id: string; value: string }>;
    disableInput: Action<HireCheckoutFormStore, { id: string }>;
    setCheckoutMode: Action<HireCheckoutFormStore, { mode: HireCheckoutMode }>;
    setCheckoutComplete: Action<HireCheckoutFormStore, boolean>;
    setCanValidate: Action<HireCheckoutFormStore, { id: string; value: boolean }>;
    setCanValidateAddress: Action<HireCheckoutFormStore, { rootId: string; id: string; value: boolean }>;
};

export type HireCheckoutFormInjections = ErrorInjections;

export type HireCheckoutFormThunks = {
    confirmHireLeadCheckout: Thunk<
        HireCheckoutFormStore,
        hesselApiTypes.HireLeadCheckoutSubmit,
        HireCheckoutFormInjections,
        Record<string, unknown>,
        Promise<APIError | undefined>
    >;
};

export type HireCheckoutFormStore = HireCheckoutFormState & HireCheckoutFormActions & HireCheckoutFormThunks;
