import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const StyledEmployeeImage = styled.img`
    width: 100%;

    @media ${device.tablet} {
        width: 400px;
    }
`;

export const StyledCard = styled.div`
    display: flex;
    background-color: white;
    box-shadow: ${(props) => props.theme.shadows.large};

    flex-direction: column;
    margin-top: 15px;
    gap: 50px;
    padding: 5px;
    padding-bottom: 20px;

    @media ${device.tablet} {
        flex-direction: row;
        padding: 20px 30px;
    }
`;

export const StyledContent = styled.div`
    margin-left: 20px;
    @media ${device.tablet} {
        margin-left: 0;
    }
`;

export const StyledContainer = styled.div`
    margin: auto;
    max-width: max-content;
    width: 95%;
    padding: 60px 0;
`;

export const StyledSubTitle = styled.p`
    margin-top: 15px;
`;
