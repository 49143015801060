import { FC } from 'react';
import { StyledSeparator } from './separator.styled';
import { CSSProperties } from 'styled-components';

type Props = {
    marginTop?: string;
    marginBottom?: string;
    stylings?: CSSProperties;
};

export const Separator: FC<Props> = ({ stylings, marginTop = '10px', marginBottom = '10px' }) => {
    return <StyledSeparator marginTop={marginTop} marginBottom={marginBottom} style={stylings} />;
};
