import { action, thunk } from 'easy-peasy';
import {
    HireCheckoutFormActions,
    HireCheckoutFormInjections,
    HireCheckoutFormState,
    HireCheckoutFormStore,
    HireCheckoutFormThunks,
    HireCheckoutMode,
} from '.';
import { submitHireLeadCheckout } from '../../../../api/hire/hessel-hire-lead-checkout-api';
import { createContextStoreWithRuntimeModel } from '../../../helpers/context-store.helpers';
import { storeInputTypes } from '../../../_shared';
import { validateHireInput } from './hire-forms.helpers';

const hireCheckoutDefaultState = (runtimeModel: Partial<HireCheckoutFormState> | undefined): HireCheckoutFormState => ({
    inputs: runtimeModel?.inputs ?? [],
    isCheckoutComplete: false,
    checkoutMode: runtimeModel?.checkoutMode ?? HireCheckoutMode.Private,
});

const updateHireSingleFormInput = <T extends storeInputTypes.SingleFormInput<string>>(input: T, payload: { id: string; value: string }): T =>
    input.id === payload.id ? { ...input, value: payload.value, isValid: validateHireInput({ ...input, ...payload }) } : { ...input };

const hireCheckoutFormActions = (): HireCheckoutFormActions => ({
    onChange: action((state, payload) => {
        state.inputs = state.inputs.map((input) => (input.type !== 'address' ? updateHireSingleFormInput(input, payload) : input));
    }),
    onChangeMultiple: action((state, payload) => {
        state.inputs = state.inputs.map((input) =>
            input.id === payload.rootId && input.type === 'address'
                ? {
                      ...input,
                      isValid: validateHireInput({
                          ...input,
                          inputs: input.inputs.map((input) => updateHireSingleFormInput(input, payload)),
                      }),
                      inputs: input.inputs.map((input) => updateHireSingleFormInput(input, payload)),
                  }
                : input
        );
    }),
    disableInput: action((state, payload) => {
        const input = state.inputs.find((x) => x.id === payload.id);

        if (input) {
            input.disabled = true;
        }
    }),
    setCheckoutComplete: action((state, payload) => {
        state.isCheckoutComplete = payload;
    }),
    setCanValidate: action((state, payload) => {
        const inputToUpdate = state.inputs.find((x) => x.id === payload.id);

        if (inputToUpdate && !inputToUpdate.canValidate) {
            inputToUpdate.canValidate = payload.value;
        }
    }),
    setCanValidateAddress: action((state, payload) => {
        const addressInputs = state.inputs.find((x) => x.id === payload.rootId);

        if (!addressInputs || addressInputs?.type !== 'address') {
            return;
        }

        const input = addressInputs.inputs.find((x) => x.id === payload.id);

        if (input) {
            input.canValidate = true;
        }
    }),
    setCheckoutMode: action((state, payload) => {
        state.checkoutMode = payload.mode;
    }),
});

const hireCheckoutFormThunks = (): HireCheckoutFormThunks => ({
    confirmHireLeadCheckout: thunk(async (actions, payload, { injections }) => {
        const { pushError } = injections;
        const [response, error] = await submitHireLeadCheckout(payload);
        if (error) {
            pushError(error.errorType);
            return error;
        }
        console.log(response);
        actions.setCheckoutComplete(true);
    }),
});

export const LeadCheckoutFormsStore = createContextStoreWithRuntimeModel<
    HireCheckoutFormStore,
    Partial<HireCheckoutFormState>,
    HireCheckoutFormInjections
>(
    (runtimeModel) => ({
        ...hireCheckoutDefaultState(runtimeModel),
        ...hireCheckoutFormActions(),
        ...hireCheckoutFormThunks(),
    }),
    { name: 'hireCheckoutForm', injections: { pushError: (e) => console.log('test', e) } }
);
