import styled from 'styled-components';

export const StyledSubMenuWrapper = styled.div`
    padding: 20px;
    background-color: white;
`;

export const StyledSubMenuHeaderActions = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const StyledSubMenuNameAndLinkWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 40px;
    margin-bottom: 20px;
`;

export const StyledBackButton = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    font-weight: 500;
    font-size: 13px;
    cursor: pointer;
`;

export const StyledPageName = styled.p`
    font-family: 'Corporate E';
    font-weight: bold;
    font-size: 17px;
`;

export const StyledPageLink = styled.a`
    font-size: 12px;
`;

export const StyledInspirationalLinkWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px 10px;
`;

export const StyledPrimaryLinkWrapper = styled.div`
    margin-top: 30px;
    border-top: 1px solid rgba(151, 151, 151, 0.2);
`;
