import { ChangeEventHandler, FC, InputHTMLAttributes } from 'react';
import { BaseInput, BaseInputChildrenProps } from '../../forms/inputs/base';
import { StyledGeoFilterInput, StyledGeoInputWrapper, StyledGeoSearch } from './geo-filter-input.styled';

export type GeoFilterInputProps = BaseInputChildrenProps<string> &
    Pick<InputHTMLAttributes<HTMLInputElement>, 'min' | 'max'> & {
        type: 'text';
        onChange: ChangeEventHandler<HTMLInputElement>;
        readonly?: boolean;
        isValid: boolean;
        validationMessage?: string;
        canValidateInputField: boolean;
        pattern?: string;
        onInputBlur?: () => void;
        requestForLocation: () => void;
    };

export const GeoFilterInput: FC<GeoFilterInputProps> = ({
    type,
    onChange,
    readonly = false,
    max,
    min,
    pattern,
    onInputBlur,
    requestForLocation,
    ...props
}) => {
    return (
        <StyledGeoInputWrapper>
            <BaseInput
                {...props}
                onInputBlur={onInputBlur}
                adornment={<StyledGeoSearch onClick={() => requestForLocation()} viewBox="-4.7 -4.8 26 26" width={34} height={34} />}
            >
                {({ ...childProps }) => (
                    <StyledGeoFilterInput {...childProps} type={type} min={min} max={max} onChange={onChange} readOnly={readonly} pattern={pattern} />
                )}
            </BaseInput>
        </StyledGeoInputWrapper>
    );
};
