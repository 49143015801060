import styled from 'styled-components';
import { Close } from '../../icons';

export const MetaMenuWrapper = styled.section<{ backgroundColor?: string; textColor?: string }>`
    position: relative;
    background-color: black;
    color: ${(props) => (props.textColor ? `#${props.textColor}` : 'white')};
    background-color: ${(props) => (props.backgroundColor ? `#${props.backgroundColor}` : 'black')};
    padding: 3px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 15;
`;

export const StyledText = styled.div`
    & > p {
        font-size: 12px;
        font-weight: normal;
        text-align: center;

        & a {
            color: inherit;
            font-weight: inherit;
            font-size: inherit;
        }
    }
`;

export const StyledClose = styled(Close)<{ textcolor?: string }>`
    justify-self: end;
    & path {
        stroke: ${(props) => (props.textcolor ? `#${props.textcolor}` : 'white')};
        stroke-width: 1px;
    }
`;
