import { FC } from 'react';
import { umbraco } from '../../../lib/api';
import ReepayReceipt from '../../plus-sites-shared/reepay/receipt/reepay-receipt.component';

type PlusSubscriptionPageReceiptProps = {
    page: umbraco.ReceiptPage;
};

export const PlusSubscriptionPageReceipt: FC<PlusSubscriptionPageReceiptProps> = ({ page }) => {
    return (
        <ReepayReceipt
            pageContent={{
                header: page.header,
                receiptText: page.receiptText,
                formSummaryHeader: page.formSummaryHeader,
                emailLabel: page.emailLabel,
                phoneLabel: page.phoneLabel,
                ctas: page.ctas,
            }}
        />
    );
};
