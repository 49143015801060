import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const EmployeeCardWrapper = styled.article`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 4px;
    width: 100%;

    @media ${device.tablet} {
        width: revert;
        max-width: 370px;
        min-width: 319px;
    }

    :focus-visible {
        outline: 2px dotted ${(props) => props.theme.palette.primary.main};
        outline-offset: 0.1em;
    }
`;

export const ImageWrapper = styled.div`
    width: 100%;
    height: 206px;
    overflow: hidden;
`;

export const EmployeeImage = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
`;

export const EmployeeInfoWrapper = styled.div`
    padding: 23px 21px;
    padding-bottom: 0;
    padding-bottom: 0;
`;

export const Header = styled.p`
    font-family: 'Corporate E';
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 9px;
`;

export const Role = styled.p`
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 23px;
`;

export const ContactInfoList = styled.ul``;

export const Contact = styled.li``;

export const StyledAnchor = styled.a``;

export const StyledBrandAndDealershipsWrapper = styled.div`
    padding: 15px 21px;
    margin-top: auto;
    display: flex;
    flex-direction: column;
`;

export const CorrespondingBrands = styled.p`
    opacity: 0.5;
    color: #0b0b0b;
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    font-size: 12px;
    letter-spacing: 0;
    line-height: 23px;
`;
