import { useEffect, useState, VFC } from 'react';
import { umbraco } from '../../../../lib/api';
import {
    ContentWrapper,
    DescriptionButton,
    StyledDescription,
    StyledIcon,
    StyledTitle,
    TitleAndDescription,
    ButtonText,
} from './collapsible-cell.styled';

import { useSpring, config, animated } from 'react-spring';
import { useMeasure } from 'react-use';
import { Button } from '../..';
import { formatPrice, replaceLineBreaks } from '../../../../utils/helpers';

type Props = {
    saving: umbraco.SavingsWithId;
};

const MEDIA_URL = process.env.NEXT_PUBLIC_MEDIA_URL;

export const CollapsibleCell: VFC<Props> = ({ saving }) => {
    const defaultHeight: string | undefined = '0px';

    // Gets the height of the element (ref)
    const [heightRef, { height }] = useMeasure<HTMLParagraphElement>();

    const [contentHeight, setContentHeight] = useState(defaultHeight || height);

    useEffect(() => {
        //Sets initial height
        setContentHeight(height);
    }, [height]);

    const [isOpen, setIsOpen] = useState(false);

    // Animations
    const expand = useSpring({
        config: { ...config.slow, friction: 50 },
        height: isOpen ? `${contentHeight}px` : defaultHeight,
        overflow: !isOpen ? 'hidden' : '',
    });

    return (
        <ContentWrapper>
            {saving.icon ? <StyledIcon src={`${MEDIA_URL}${saving.icon.src}`} alt={saving.icon?.name} /> : <div></div>}

            <TitleAndDescription>
                <StyledTitle>
                    <strong>{saving.detailsDisplayText}</strong> (
                    {saving.alias === 'multipleChoicesSaving'
                        ? saving.choices.find((x) => x.value === saving.value)?.label
                        : `${formatPrice(saving.value)}${saving.unit ? ` ${saving.unit}` : ''}`}
                    )
                </StyledTitle>
                {saving.detailsDescription ? (
                    <DescriptionButton>
                        <Button variant="link" onClick={() => setIsOpen(!isOpen)}>
                            <ButtonText>{isOpen ? 'Læs mindre' : 'Læs mere'}</ButtonText>
                        </Button>

                        <animated.div style={expand}>
                            <StyledDescription ref={heightRef} dangerouslySetInnerHTML={{ __html: replaceLineBreaks(saving.detailsDescription) }} />
                        </animated.div>
                    </DescriptionButton>
                ) : null}
            </TitleAndDescription>
        </ContentWrapper>
    );
};
