import React, { VFC } from 'react';
import styled from 'styled-components';
import { device } from '../../../lib/media-query';

const StyledShape = styled.div`
    display: none;

    @media ${device.laptop} {
        color: ${({ theme }) => theme.palette.footer.background};
        display: block;
        position: absolute;
        top: 0;
        right: -100px;
        width: 100px;
        height: 100%;
    }
`;

export const HeroSplitSpotShape: VFC<{ shapeColor?: string }> = ({ shapeColor = 'transparent' }) => {
    return (
        <StyledShape>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" preserveAspectRatio="none" viewBox="0 0 100 300">
                <polygon fill={shapeColor} height="100%" width="100%" points="0 0 100 0 0 300 0 0" />
            </svg>
        </StyledShape>
    );
};
