import { FC } from 'react';
import { BookingStepWorkshop } from '../../../lib/api/models/umbraco';

type Props = {
    workshopContent: BookingStepWorkshop;
};

export const VendorDelivery: FC<Props> = ({ workshopContent }) => {
    return (
        <>
            <p>{workshopContent.pickupByVendorText}</p>
            <p>
                <small>{workshopContent.pickupByVendorSubText}</small>
            </p>
        </>
    );
};
