import styled from 'styled-components';

export const InputWrapper = styled.section`
    position: relative;
`;

export const DateInput = styled.input.attrs({ type: 'text' })`
    font-size: 1rem;
    font-family: inherit;
    color: inherit;
    padding: 0 1rem;
    border-radius: ${({ theme }) => theme.shape.rounded.borderRadius}px;
    border: ${({ theme }) => theme.palette.grey.medium};
    background-color: #f1f1f2;
    border-bottom: 3px solid transparent;
    width: 100%;
    height: 45px;
    display: block;
    transition: all 0.3s;
    text-transform: uppercase;

    margin-bottom: 1rem;

    &:focus {
        outline: none;
        box-shadow: ${({ theme }) => theme.shadows.large};
    }

    &::placeholder {
        color: ${({ theme }) => theme.palette.common.black};
    }
`;

export const InputIcon = styled.div`
    position: absolute;
    top: 10px;
    right: 30px;
    width: 10px;
    height: 10px;
`;

export const CalendarWrapper = styled.div<{ isLoading: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    max-height: 400px;
    pointer-events: ${(props) => (props.isLoading ? 'none' : 'revert')};
    cursor: ${(props) => (props.isLoading ? 'not-allowed' : 'revert')};
    filter: ${(props) => (props.isLoading ? 'grayscale(0.5) blur(2px)' : 'revert')};
`;

export const CalendarArea = styled.div`
    position: relative;
`;

export const SpinnerContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
