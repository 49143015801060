import { CSSProperties, FC, useMemo, useRef } from 'react';
import reactStringReplace from 'react-string-replace';
import { useOnScreen } from '../../../hooks/use-on-screen';
import {} from '../../../lib/api/vehicle/hessel-vehicle-plp-api';
import { ProductListApiResponse } from '../../../lib/api/models/hessel-api/hire';
import { PaymentType, ProductListConfig } from '../../../lib/api/models/umbraco/content-spot';
import {} from '../../../lib/mappers/vehicle/product-card.mapper';
import { FacetGroup, ProductCard } from '../../../lib/view-models/vehicle';
import { LoadMore } from '../../shared/load-more';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';
import { VehicleProductListNoResults } from './vehicle-product-list-no-results';
import { VehicleProductListResults } from './vehicle-product-list-results/vehicle-product-list-results.component';
import { VehicleProductListSorting } from './vehicle-product-list-sorting';
import { HeaderAndDropdownWrapper, LoadingBlocker, PageHeader, ProductListMain, ResultsText, StyledDescription } from './vehicle-product-list.styled';

import { useHireProductList } from '../../../hooks/productlist/use-vehicle-productlist';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { isNullOrEmpty, removeStoredRequestFrom, toggleChatBot } from '../../../utils/helpers';
import { handleSortingQuery } from '../../../utils/helpers/productlist.helpers';
import { SpotHeader } from '../../shared/typography/spot-header/spot-header.component';
import { ProductListFilterTiles } from './product-list-filter-tiles/product-list-filter-tiles';
import { VehicleProductListSortingDropdown } from './vehicle-product-list-sorting-dropdown/vehicle-product-list-sorting-dropdown.component';
import { VehicleProductListFilter } from './vehicle-product-list-filter';

export type VehicleProductListProps = {
    plpConfig: ProductListConfig;
    pageId: string;
    scrollAnchorId: string;
    initialProducts?: ProductCard[];
    initialProductListData?: ProductListApiResponse;
    initialFacetsArray?: FacetGroup[];
    initialActiveFilters?: string | string[];
    isAboveFold?: boolean;
    showFacets?: boolean;
};

export const VehicleProductList: FC<VehicleProductListProps> = ({
    plpConfig,
    pageId,
    scrollAnchorId,
    initialProductListData,
    initialProducts,
    initialFacetsArray,
    initialActiveFilters,
    isAboveFold,
    showFacets = true,
}) => {
    const {
        resultsText,
        paymentType,
        selectedSortingValue,
        validAvailabilities,
        anyResults,
        filterUpdate,
        productListData,
        isLoading,
        allProductCards,
        maximumSubfacetDepth,
        facets,
        productListItems,
        filterTiles,
        clearFiltersText,
        resetFilters,
        sortingOptions,
        departmentOptions,
        productListRequest,
        noResultsContactForm,
        activeFiltersLength,
        baseFilterSetup,
        updateProductListRequest,
        loadMore,
    } = useHireProductList({
        plpConfig: plpConfig,
        productListData: initialProductListData ?? null,
        initialFacetsArray,
        initialActiveFilters: initialActiveFilters ?? [],
        initialProducts: initialProducts ?? null,
    });

    const resultsTextFormatted = useMemo(() => {
        if (!productListData) return resultsText;
        return reactStringReplace(resultsText, '{{amount}}', () => <strong key="1">{productListData.total}</strong>);
    }, [productListData, resultsText]);

    const isDevice = useMediaQuery({ target: 'smallDesktop' });

    const pageHeader = useRef<HTMLHeadingElement>(null);

    const visibilityChecker = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(visibilityChecker);

    toggleChatBot(isDevice && isVisible);

    const containerStyles: CSSProperties = {
        backgroundColor: '#f7f7f8',
        padding: isDevice ? '20px 0' : '40px 0',
        position: 'relative',
        overflow: isLoading ? 'hidden' : 'auto',
        filter: isLoading && !isDevice ? 'blur(2px)' : 'none',
    };

    function scrollToSpotHeader() {
        if (isDevice && pageHeader.current && productListRequest.from === 0) {
            pageHeader.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (
        <div style={containerStyles}>
            {isLoading ? <LoadingBlocker></LoadingBlocker> : null}
            <CenteredBlock>
                <ProductListMain id={scrollAnchorId} showFacets={showFacets}>
                    {showFacets ? (
                        <VehicleProductListFilter
                            inViewport={isVisible}
                            clearFiltersText={clearFiltersText}
                            closeMobileFilterOverlay={
                                plpConfig.closeMobileFilterOverlay.length > 0
                                    ? plpConfig.closeMobileFilterOverlay
                                    : plpConfig.globalPlpSettings.closeMobileFilterOverlay
                            }
                            applyMobileFiltersText={
                                plpConfig.applyMobileFiltersText.length > 0
                                    ? plpConfig.applyMobileFiltersText
                                    : plpConfig.globalPlpSettings.applyMobileFiltersText
                            }
                            filters={facets}
                            filterLabels={plpConfig.globalPlpSettings.filterGroupLabels}
                            updateFilter={(filterUpdateNotifications) => {
                                filterUpdate(filterUpdateNotifications);
                                scrollToSpotHeader();
                            }}
                            resetFilters={() => {
                                resetFilters();
                                scrollToSpotHeader();
                            }}
                            filterSectionHeader={
                                plpConfig.filterSectionHeader.length > 0
                                    ? plpConfig.filterSectionHeader
                                    : plpConfig.globalPlpSettings.filterSectionHeader
                            }
                            activeFiltersCount={activeFiltersLength}
                            sortOptions={sortingOptions}
                            selectedSortOptionValue={selectedSortingValue}
                            updateSorting={(selection: string) => {
                                handleSortingQuery(selection, initialProductListData?.selectedSorting[0] ?? selection);
                                updateProductListRequest({ ...productListRequest, sort: selection, from: 0 });
                                scrollToSpotHeader();
                            }}
                            maximumSubfacetDepth={maximumSubfacetDepth}
                            departments={departmentOptions}
                            paymentType={paymentType as PaymentType}
                        />
                    ) : null}
                    <section>
                        {isNullOrEmpty(plpConfig.headerSize) ? (
                            <PageHeader ref={pageHeader} as={plpConfig.headerType}>
                                {plpConfig.header.length > 0 ? plpConfig.header : plpConfig.globalPlpSettings.header}
                            </PageHeader>
                        ) : (
                            <SpotHeader ref={pageHeader} headerType={plpConfig.headerType} headerSize={plpConfig.headerSize}>
                                {plpConfig.header.length > 0 ? plpConfig.header : plpConfig.globalPlpSettings.header}
                            </SpotHeader>
                        )}

                        {!isNullOrEmpty(plpConfig.description) && anyResults ? <StyledDescription>{plpConfig.description}</StyledDescription> : null}

                        <div
                            style={{
                                marginTop: validAvailabilities && validAvailabilities.facetOptions.length > 1 ? '30px' : 0,
                            }}
                        >
                            <VehicleProductListSorting
                                onChangeCarAvailabilityType={(filterUpdateNotification) => filterUpdate([filterUpdateNotification])}
                                carAvailabilityTypes={validAvailabilities}
                                availabilityLabels={plpConfig.globalPlpSettings.availabilityLabels}
                                baseFilterSetup={baseFilterSetup}
                            />
                            <div ref={visibilityChecker}>
                                {anyResults && productListItems ? (
                                    <>
                                        <HeaderAndDropdownWrapper>
                                            <ResultsText>{resultsTextFormatted}</ResultsText>
                                            <VehicleProductListSortingDropdown
                                                options={sortingOptions}
                                                selected={selectedSortingValue}
                                                anyResults={anyResults}
                                                showSorting={showFacets}
                                                onChangeSorting={(selection: string) => {
                                                    removeStoredRequestFrom();
                                                    handleSortingQuery(selection, initialProductListData?.selectedSorting[0] ?? selection);
                                                    updateProductListRequest({ ...productListRequest, sort: selection, from: 0 });
                                                }}
                                            />
                                        </HeaderAndDropdownWrapper>
                                        <ProductListFilterTiles
                                            filterTiles={filterTiles}
                                            resetFilters={() => resetFilters()}
                                            filterUpdate={(filterUpdateNotification) => filterUpdate(filterUpdateNotification)}
                                        />
                                        <VehicleProductListResults
                                            results={allProductCards}
                                            demoCarsBookTestDriveForm={plpConfig.globalPlpSettings.demoCarsBookTestDriveForm}
                                            pageId={pageId}
                                            isAboveFold={isAboveFold}
                                            departments={departmentOptions}
                                            paymentType={paymentType}
                                            configurationId={plpConfig.filterConfiguration.key}
                                            noFilters={!showFacets}
                                            demoConfigurationId={plpConfig.globalPlpSettings.filterConfigurationForDemoCars?.key}
                                        />
                                    </>
                                ) : (
                                    <VehicleProductListNoResults
                                        image={plpConfig.image ?? plpConfig.globalPlpSettings.image}
                                        noResultsText={
                                            plpConfig.noResultsText.length > 0 ? plpConfig.noResultsText : plpConfig.globalPlpSettings.noResultsText
                                        }
                                        clearFiltersText={clearFiltersText}
                                        contactForm={noResultsContactForm}
                                        contactCTAText={
                                            plpConfig.contactCTAText.length > 0
                                                ? plpConfig.contactCTAText
                                                : plpConfig.globalPlpSettings.contactCTAText
                                        }
                                        pageId={pageId}
                                        resetFilters={() => resetFilters()}
                                        noResultHeader={
                                            !isNullOrEmpty(plpConfig.noResultsHeaderText)
                                                ? plpConfig.noResultsHeaderText
                                                : plpConfig.globalPlpSettings.noResultHeader
                                        }
                                        filterTiles={
                                            <div style={{ marginTop: '25px' }}>
                                                <ProductListFilterTiles
                                                    filterTiles={filterTiles}
                                                    resetFilters={() => resetFilters()}
                                                    filterUpdate={(filterUpdateNotification) => filterUpdate(filterUpdateNotification)}
                                                />
                                            </div>
                                        }
                                    />
                                )}
                            </div>
                        </div>

                        <LoadMore
                            onClick={() => {
                                loadMore(productListItems?.length ?? 0, plpConfig.globalPlpSettings.paginationSize);
                            }}
                            total={productListData?.total ?? 0}
                            take={plpConfig.globalPlpSettings.paginationSize}
                            current={productListItems?.length ?? 0}
                            loadMoreText={plpConfig.globalPlpSettings.loadMoreText}
                        />
                    </section>
                </ProductListMain>
            </CenteredBlock>
        </div>
    );
};

export default VehicleProductList;
