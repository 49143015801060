import { useEffect, useState, VFC } from 'react';
import { SidePanel } from '../../modals/side-panel/side-panel.component';
import { StyledClosedButton, StyledTabbedCarousel } from './tabbed-carousel-dialog.styled';
import { TabbedCarouselProps } from './tabbed-carousel.component';

export type TabbedCarouselDialogProps = TabbedCarouselProps & {
    isVisible: boolean;
    onClose: () => void;
};

export const TabbedCarouselDialog: VFC<TabbedCarouselDialogProps> = ({ isVisible, onClose, ...carouselProps }) => {
    const [timesOpened, setTimesOpened] = useState(0);
    useEffect(() => {
        if (isVisible) {
            setTimesOpened(timesOpened + 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible]);

    return (
        <SidePanel cancelAction={onClose} isVisible={isVisible} variant="full">
            <StyledClosedButton label="Luk dialog" onClick={onClose} />
            {isVisible ? <StyledTabbedCarousel key={timesOpened} {...carouselProps} /> : null}
        </SidePanel>
    );
};
