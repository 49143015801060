import { FC, useMemo } from 'react';
import { hesselViewModels } from '../../../lib/view-models';
import { CampaignListItem } from './campaign-list.styled';

type CampaignListProps = {
    campaigns: Array<hesselViewModels.ProductCampaign>;
    visibleAmount?: number;
    className?: string;
};

export const CampaignList: FC<CampaignListProps> = ({ campaigns, visibleAmount = 3, className }) => {
    const mappedCampaigns = useMemo(() => {
        return campaigns.slice(0, visibleAmount).map((x) => {
            let style = {
                backgroundColor: '',
                color: '',
            };

            switch (x.style) {
                case 'BlackWhite':
                    style = {
                        backgroundColor: '#000',
                        color: '#fff',
                    };
                    break;
                case 'BlueWhite':
                    style = {
                        backgroundColor: '#0089D1',
                        color: '#fff',
                    };
                    break;
                case 'GreenWhite':
                    style = {
                        backgroundColor: '#75C553',
                        color: '#fff',
                    };
                    break;
                case 'GreyWhite':
                    style = {
                        backgroundColor: '#688488',
                        color: '#F9F9F9',
                    };
                    break;
                case 'RedWhite':
                    style = {
                        backgroundColor: '#E43A3A',
                        color: '#fff',
                    };
                    break;
                default: {
                    const exhaustiveCheck: never = x.style;
                    return exhaustiveCheck;
                }
            }

            return { ...x, ...style };
        });
    }, [campaigns, visibleAmount]);

    return (
        <>
            {mappedCampaigns.map((x, index) => (
                <CampaignListItem key={index} bgColor={x?.backgroundColor} color={x?.color} className={className}>
                    {x?.text}
                </CampaignListItem>
            ))}
        </>
    );
};
