import { useState } from 'react';
import { GTMHirePdpData } from '../../hooks/lead-checkout/use-hire-gtm-tracking-data';
import { DispatchMethod } from '../gtm-tracking.types';

const PDP_Actions = {
    pdpPageEvents: 'Pdp Events',
    viewItem: 'view_item',
    addToCart: 'add_to_cart',
};

export type HirePdpEvents = {
    viewItem: (item: GTMHirePdpData) => void;
    addToCart: (item: GTMHirePdpData) => void;
};

const usePdpEvents = (dispatch: DispatchMethod): HirePdpEvents => {
    const eventType = PDP_Actions.pdpPageEvents;

    return useState({
        viewItem: (item: GTMHirePdpData) =>
            dispatch({
                event: PDP_Actions.viewItem,
                type: eventType,
                ...item,
            }),
        addToCart: (item: GTMHirePdpData) =>
            dispatch({
                event: PDP_Actions.addToCart,
                type: eventType,
                ...item,
            }),
    })[0];
};

export const trackHirePdp = (dispatch: DispatchMethod): (() => HirePdpEvents) => {
    return () => usePdpEvents(dispatch);
};
