import styled from 'styled-components';
import { KeyValueTable } from '../../../shared';
import { KeyValueTableRow } from '../../../shared/tables/key-value-table/row';

export const Tables = styled.div`
    display: flex;
    flex-direction: column;
    gap: 26px;
    margin-top: 25px;
`;

export const StyledTableWrapper = styled.div``;

export const StyledTableHeader = styled.p`
    color: #0b0b0b;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    margin-bottom: 15px;
    line-height: 26px;
`;

export const StyledPaymentPlanTable = styled(KeyValueTable)`
    font-size: 12px;
    font-weight: 500;
`;

export const PaymentPlanTableTotalRow = styled(KeyValueTableRow)`
    font-weight: 600;
`;
