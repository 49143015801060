export const isNullOrUndefined = (obj: unknown): obj is null | undefined => {
    return obj === null || obj === undefined;
};

export function scrollToElementWithOffset(element: HTMLElement, offset: number): void {
    const elementRect = element.getBoundingClientRect();
    const absoluteElementTop = elementRect.top + window.scrollY;
    const offsetPosition = absoluteElementTop - offset;

    window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
    });
}
