import styled from 'styled-components';

export const StyledLink = styled.a`
    font-size: inherit;
    font-weight: inherit;
    text-decoration: none;

    &:hover {
        text-decoration: revert;
    }
`;
