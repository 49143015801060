import React, { useCallback, useEffect, useMemo, useState, VFC } from 'react';
import { useSpring, config, animated } from 'react-spring';
import { useMeasure } from 'react-use';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import { CompareVansLeasingTypesModal, LeasingComparisonRow } from '../../../../lib/api/models/umbraco/product-details';
import { ProductDetailsStore } from '../../../../lib/state/hessel-site/product-details';
import { calculateIsAFromPrice, getAllIncludedPrice } from '../../../../lib/state/hessel-site/product-details/price.helper';
import { PdpResource } from '../../../../lib/view-models/vehicle';
import { MEDIA_URL } from '../../../../utils/environment-constants';
import { formatPrice, isNullOrEmpty, sanitizeMarkup } from '../../../../utils/helpers';
import { Button } from '../../../shared';
import { CompactCarousel } from '../../../shared/carousels/compact-carousel/compact-carousel.component';
import { SimpleCarousel } from '../../../shared/carousels/simple-carousel/simple-carousel.component';
import { SidePanel } from '../../../shared/modals/side-panel';
import { SidePanelLayout } from '../../../shared/modals/side-panel-layout/side-panel-layout.component';
import { StickyTable } from '../../../shared/tables/sticky-table/sticky-table.component';
import {
    DescriptionAndMedia,
    DisclaimersWrapper,
    Header,
    HighlightedText,
    MediaWrapper,
    ReadMoreContent,
    StyledDescription,
    StyledDisclaimer,
    StyledText,
} from './vans-leasing-types-modal.styled';
import { Abbreviations, Units } from '../../../../constants/units-and-abbreviations';

type VansLeasingTypesModalProps = {
    visible: boolean;
    onClose: () => void;
    modalSettings: CompareVansLeasingTypesModal;
};

const VansLeasingTypesModalMemo: VFC<VansLeasingTypesModalProps> = ({ visible, onClose, modalSettings }) => {
    const isMobile = useMediaQuery({ target: 'tablet' });
    const defaultHeight: string | undefined = isMobile ? '70px' : '50px';
    // Gets the height of the element (ref)
    const [heightRef, { height }] = useMeasure<HTMLDivElement>();
    const [contentHeight, setContentHeight] = useState(defaultHeight || height);
    const { selectedProduct, selectedColor } = ProductDetailsStore.useStoreState((state) => state);

    const { financialLeasingData, financialLeasingPrices, operationalLeasingData, operationalLeasingPrices } = useMemo(() => {
        return {
            operationalLeasingData: selectedProduct?.purchaseTypes.operationalLeasing,
            operationalLeasingPrices: getAllIncludedPrice('Van Operationel Leasing', selectedProduct, selectedColor),
            financialLeasingData: selectedProduct?.purchaseTypes.financialLeasing,
            financialLeasingPrices: getAllIncludedPrice('Van Finansiel Leasing', selectedProduct, selectedColor),
        };
    }, [selectedProduct, selectedColor]);

    const isFinancialLeasingPriceFrom = useMemo(() => {
        return calculateIsAFromPrice('Van Finansiel Leasing', [
            ...(selectedProduct?.extraEquipment['Van Finansiel Leasing'] ?? []),
            ...(selectedProduct?.extraEquipmentPackages['Van Finansiel Leasing'] ?? []),
        ]);
    }, [selectedProduct?.extraEquipment, selectedProduct?.extraEquipmentPackages]);

    const isOperationalLeasingPriceFrom = useMemo(() => {
        return calculateIsAFromPrice('Van Operationel Leasing', [
            ...(selectedProduct?.extraEquipment['Van Operationel Leasing'] ?? []),
            ...(selectedProduct?.extraEquipmentPackages['Van Operationel Leasing'] ?? []),
        ]);
    }, [selectedProduct?.extraEquipment, selectedProduct?.extraEquipmentPackages]);

    const carouselItems = useMemo(() => {
        return (
            modalSettings?.descriptionMedia?.map((x) => {
                if (x.media.type === 'image' || x.media.extension?.toLowerCase() === 'webp') {
                    return {
                        type: 'image',
                        url: MEDIA_URL + x.media.src,
                        caption: x.caption,
                    } as PdpResource;
                }

                if (x.media.type === 'file') {
                    return {
                        type: 'video',
                        url: MEDIA_URL + x.media.src,
                        caption: x.caption,
                    } as PdpResource;
                }

                throw new Error('Unknown media type');
            }) || []
        );
    }, [modalSettings]);

    useEffect(() => {
        //Sets initial height
        setContentHeight(height);
    }, [height]);

    const [showEntireContent, setShowEntireContent] = useState(false);

    // Animations
    const expand = useSpring({
        config: { ...config.slow, friction: 50 },
        height: showEntireContent ? `${contentHeight}px` : defaultHeight,
        overflow: 'hidden',
    });

    const textToDisplay = useMemo(
        () => (showEntireContent ? modalSettings.description : modalSettings.description.substring(0, isMobile ? 125 : 250) + '...'),
        [showEntireContent, modalSettings.description, isMobile]
    );

    const generateRow = useCallback(
        (rowData: LeasingComparisonRow) => ({
            title: rowData.label,
            description: rowData.details,
            cells: [
                {
                    icon: rowData.operationalLeasing ? modalSettings.availableIcon : modalSettings.unavailableIcon,
                },
                {
                    icon: rowData.financialLeasing ? modalSettings.availableIcon : modalSettings.unavailableIcon,
                },
            ],
        }),
        [modalSettings]
    );

    return (
        <SidePanel cancelAction={onClose} isVisible={visible} variant="70%">
            <SidePanelLayout closeSidePanel={onClose}>
                <Header>{modalSettings.header}</Header>

                <ReadMoreContent>
                    <animated.div style={expand}>
                        <DescriptionAndMedia ref={heightRef}>
                            <StyledDescription {...sanitizeMarkup(textToDisplay)} />
                            {carouselItems && carouselItems.length > 0 ? (
                                <MediaWrapper>
                                    {isMobile ? (
                                        <CompactCarousel slides={carouselItems} isAboveFold={false} />
                                    ) : (
                                        <SimpleCarousel slides={carouselItems} isAboveFold={false}></SimpleCarousel>
                                    )}
                                </MediaWrapper>
                            ) : null}
                        </DescriptionAndMedia>
                    </animated.div>

                    <Button variant="link" onClick={() => setShowEntireContent(!showEntireContent)}>
                        {showEntireContent ? 'Læs mindre' : 'Læs mere'}
                    </Button>
                </ReadMoreContent>

                <StickyTable
                    rowHeaderSize="medium"
                    rowPadding="15px 20px"
                    tableData={{
                        columns: [
                            {
                                headerText: !isNullOrEmpty(modalSettings.operationelLeasingColumnHeader)
                                    ? modalSettings.operationelLeasingColumnHeader
                                    : 'Operationel leasing',
                                cta: [],
                            },
                            {
                                headerText: !isNullOrEmpty(modalSettings.finansielLeasingColumnHeader)
                                    ? modalSettings.finansielLeasingColumnHeader
                                    : 'Finansiel leasing',
                                cta: [],
                            },
                        ],
                        rows: [
                            {
                                title: 'Leasingydelse pr. md. (1)',
                                cells: [
                                    {
                                        text: (
                                            <HighlightedText>{`${isOperationalLeasingPriceFrom ? 'Fra ' : ''}${formatPrice(
                                                operationalLeasingPrices.price
                                            )} ${Abbreviations.KR_SLASH_MD}`}</HighlightedText>
                                        ),
                                    },
                                    {
                                        text: (
                                            <HighlightedText>{`${isFinancialLeasingPriceFrom ? 'Fra ' : ''}${formatPrice(
                                                financialLeasingPrices.price
                                            )} ${Abbreviations.KR_SLASH_MD}`}</HighlightedText>
                                        ),
                                    },
                                ],
                            },
                            {
                                title: 'Udbetaling',
                                cells: [
                                    {
                                        text: <StyledText>{`${formatPrice(operationalLeasingPrices.downPayment)} kr.`}</StyledText>,
                                    },
                                    {
                                        text: <StyledText>{`${formatPrice(financialLeasingPrices.downPayment)} kr.`}</StyledText>,
                                    },
                                ],
                            },
                            {
                                title: 'Dokumentgebyr',
                                cells: [
                                    {
                                        text: <StyledText>{`${formatPrice(operationalLeasingData?.establishmentFee ?? 0)} kr.`}</StyledText>,
                                    },
                                    {
                                        text: <StyledText>{`${formatPrice(financialLeasingData?.establishmentFee ?? 0)} kr.`}</StyledText>,
                                    },
                                ],
                            },

                            {
                                title: 'Leasingperiode',
                                description: '',
                                cells: [
                                    {
                                        text: <StyledText>{operationalLeasingData?.durationMonths} mdr.</StyledText>,
                                    },
                                    {
                                        text: <StyledText>{financialLeasingData?.durationMonths} mdr.</StyledText>,
                                    },
                                ],
                            },
                            {
                                title: 'Tilgængelige kilometer i perioden (2)',
                                description: '',
                                cells: [
                                    {
                                        text: (
                                            <StyledText>
                                                {formatPrice(operationalLeasingData?.kilometersPerYear ?? 0)} {Units.km}
                                            </StyledText>
                                        ),
                                    },
                                    {
                                        text: (
                                            <StyledText>
                                                {formatPrice(financialLeasingData?.kilometersPerYear ?? 0)} {Units.km}
                                            </StyledText>
                                        ),
                                    },
                                ],
                            },

                            ...(modalSettings.leasingComparisonRows?.map((x) => generateRow(x)) || []),
                            {
                                title: 'Bilens restværdi efter endt leasingperiode (3)',
                                description:
                                    'En af de store forskelle på operationel og finansiel leasing er, at du ifm. finansiel leasing forpligter dig til at købe bilen fri til en aftalt restværdi efter leasingaftalens udløb - hvor meget restværdien er på, afhænger af, hvor meget du har afbetalt på bilen (leasingydelse pr. md.) i leasingperioden. Nogle ønsker ikke at stå tilbage med en brugt varebil, hvorfor de bør kigge i retning af operationel leasing, hvorimod andre gerne ser frem til at kunne købe en i forvejen nedskrevet varebil til en forudgående aftalt pris. Da varebilen ifm. finansiel leasing er i dit ejerskab efter endt leasingperiode og betalt restværdi, så indestår du heller ikke for eventuelle meromkostninger ifm. skader o.l. - det gør du derimod ved operationel leasing, da du ved denne leasingform har lejet bilen af et leasingselskab igennem Ejner Hessel.',
                                cells: [
                                    {
                                        text: <StyledText>Ikke aktuel</StyledText>,
                                    },
                                    {
                                        text: (
                                            <StyledText>
                                                {financialLeasingData?.residualValue
                                                    ? `${formatPrice(financialLeasingData?.residualValue)} kr.*`
                                                    : 'Ikke aktuel'}
                                            </StyledText>
                                        ),
                                    },
                                ],
                            },
                        ],
                    }}
                />

                <DisclaimersWrapper>
                    {modalSettings.disclaimers.map((disclaimer, index) => {
                        return <StyledDisclaimer key={`van-disclaimer-${index}`}>{disclaimer.text}</StyledDisclaimer>;
                    })}
                </DisclaimersWrapper>
            </SidePanelLayout>
        </SidePanel>
    );
};

export const VansLeasingTypesModal = React.memo(VansLeasingTypesModalMemo);
