import { User, UserFactory } from '@relewise/client';

export const relewiseApiSetup = {
    apiKey: process.env.NEXT_PUBLIC_RELEWISE_APIKEY,
    dataset: process.env.NEXT_PUBLIC_RELEWISE_DATASET,
    serverUrl: process.env.NEXT_PUBLIC_RELEWISE_SERVERURL,
};

export const getRelewiseUser = (segment: string): User => {
    const user =
        window.Cookiebot.consent && window.Cookiebot.consent.statistics
            ? UserFactory.byTemporaryId(window.Cookiebot.consent.stamp)
            : UserFactory.anonymous();

    user.classifications = { segment };

    return user;
};
