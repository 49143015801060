import { useSelect } from 'downshift';
import { FC } from 'react';
import { DropdownLabel, DropdownWrapper, OptionsWrapper, ValueAndArrowWrapper, Option, OptionsBackground } from './dropdown.styled';
import Image from 'next/image';

type OptionType = {
    label: string;
    value: string;
};

type IProps = {
    options: OptionType[];
    onChange: (selection: string) => void;
    selected: string;
    label: string;
};

export const Dropdown: FC<IProps> = ({ options, onChange, selected, label }) => {
    const { isOpen, getItemProps, getLabelProps, getMenuProps, getToggleButtonProps, toggleMenu, selectedItem } = useSelect({
        items: [...options],
        itemToString: (item) => item?.value ?? '',
        onSelectedItemChange: ({ selectedItem }) => {
            toggleMenu();
            if (selectedItem) {
                onChange(selectedItem.value);
            }
        },
        initialSelectedItem: options.find((x) => x.value === selected),
    });

    return (
        <DropdownWrapper {...getToggleButtonProps()}>
            <DropdownLabel {...getLabelProps()}>{label}</DropdownLabel>
            <ValueAndArrowWrapper open={isOpen}>
                <span style={{ position: 'static', zIndex: 3 }}>{selectedItem?.label}</span>
                <Image src="/icons/chevron/down.svg" height="20px" width="20px" />
            </ValueAndArrowWrapper>
            <OptionsWrapper open={isOpen} {...getMenuProps()}>
                <OptionsBackground>
                    {isOpen &&
                        options.map((item, index) => {
                            return (
                                <Option key={index} {...getItemProps({ item, index })} disabled={false}>
                                    {item.label}
                                </Option>
                            );
                        })}
                </OptionsBackground>
            </OptionsWrapper>
        </DropdownWrapper>
    );
};
