import { animated } from 'react-spring';
import styled from 'styled-components';
import { mixins } from '../../../../themes';

export const DropdownContainer = styled.div<{ isOpen?: boolean }>`
    position: relative;
    box-shadow: ${({ isOpen, theme: { shadows } }) => (isOpen ? shadows.large : '0px')};
    border-radius: ${({ theme: { shape } }) => `${shape.rounded.borderRadius}px`};
    cursor: pointer;
`;

export const ListContainer = styled.div<{ isOpen?: boolean }>`
    width: 100%;
    position: absolute;
    z-index: 10;
    top: 50px;
    background-color: ${({ theme: { palette } }) => palette.common.white};
    border-bottom-left-radius: ${({ theme: { shape } }) => `${shape.rounded.borderRadius}px`};
    border-bottom-right-radius: ${({ theme: { shape } }) => `${shape.rounded.borderRadius}px`};
    border-color: ${({ theme: { palette } }) => palette.grey.main};
    border-style: solid;
    border-width: ${({ isOpen }) => (isOpen ? '0px 1px 1px' : '0px')};
    padding-bottom: ${({ isOpen }) => (isOpen ? '25px' : '0px')};
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
`;
/* box-shadow: ${({ isOpen, theme: { shadows } }) => (isOpen ? shadows.large : '0px')}; */

export const StyledList = styled.ul`
    background-color: ${({ theme: { palette } }) => palette.common.white};
    ${mixins.typography('inputValue')}
    width: 100%;
    max-height: 250px;
    overflow-y: auto;
    :focus-visible {
        outline: none;
    }

    :focus {
        outline: none;
    }
`;

export const StyledListItem = styled.li`
    ${mixins.typography('inputPlaceholder')}
    padding: 4px 35px;
    position: relative;
    cursor: pointer;
`;

export const StyledPlaceholder = styled(animated.span)`
    background-color: rgba(0, 0, 0, 0);
    ${mixins.typography('inputPlaceholder')};
    opacity: 0.6;
    padding: 1px 2px;
    white-space: nowrap;
`;

export const StyledValue = styled(animated.span)<{ isdesktopdevice: string }>`
    background-color: rgba(0, 0, 0, 0);
    ${mixins.typography('inputValue')};
    padding: 1px ${(props) => (props.isdesktopdevice === 'true' ? '2px' : '5px')};
    width: 100%;
    white-space: nowrap;
    cursor: default;
`;

export const DisplayWrapper = styled.div`
    display: flex;
    justify-items: center;
    min-height: 22px;
`;
