import React, { FC } from 'react';
import { SvgIcon } from '../../svg-icon';
import { StyledStarContainer } from './star.styled';

type Props = {
    width: number;
    isActive: boolean;
    spaceBetween?: number;
    onClick: () => void;
    onMouseOver: () => void;
};

const Star: FC<Props> = ({ isActive, width, spaceBetween = 0, onClick, onMouseOver }) => {
    return (
        <StyledStarContainer width={width} spaceBetween={spaceBetween} onMouseOver={onMouseOver}>
            <SvgIcon onClick={() => onClick()} iconName={isActive ? 'star-rating-active' : 'star-rating-inactive'}></SvgIcon>
        </StyledStarContainer>
    );
};

export default Star;
