import { hesselApi } from '../models';
import { getAPI, postAPI } from '../helpers';
import { APIResponse } from '../types';
import { ServiceAgreementRequest, ServiceAgreementResponse } from '../models/hessel-api/service-agreement-calculator.types';

export const getVehicleAndServiceAgreements = async (licensePlate: string): APIResponse<hesselApi.VehicleAndServiceAgreementsResponse> => {
    return getAPI(`api/v1/ServiceAgreementCalculator/serviceagreements/${licensePlate}`);
};

export const submitServiceAgreement = async (serviceAgreementRequest: ServiceAgreementRequest): APIResponse<ServiceAgreementResponse> => {
    try {
        return await postAPI(`/api/v3/Lead/service-agreement-calculator`, serviceAgreementRequest);
    } catch {
        return [undefined, undefined];
    }
};
