import React from 'react';
import styled from 'styled-components';

const StyledFooterShape = styled.svg`
    color: ${({ theme }) => theme.palette.footer.background};
`;

export const FooterShape = (): JSX.Element => {
    return (
        <StyledFooterShape>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="40px" preserveAspectRatio="none" viewBox="0 0 300 40">
                <polygon fill="currentColor" height="100%" width="100%" points="0 0 300 40 0 40 0 0" />
            </svg>
        </StyledFooterShape>
    );
};
