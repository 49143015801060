import { device } from '../../../../lib/media-query';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const ReceiptHeader = styled.p`
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 27px;
    margin-bottom: 1rem;
`;

export const ReceiptText = styled.p`
    margin-bottom: 1rem;
`;

export const DownloadLink = styled.a`
    color: white;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
`;

export const ActionWrapper = styled.div`
    display: grid;
    justify-content: center;
    grid-row-gap: 1rem;
    margin-top: 2rem;

    @media ${device.tablet} {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        justify-content: center;

        & > button:not(:last-child) {
            margin-right: 10px;
        }
    }
`;
