import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const StyledOpeningHours = styled.div``;

export const AccordionItems = styled.section`
    padding-top: 20px;
    padding-right: 50px;

    @media ${device.tablet} {
        padding-right: 70px;
    }
`;

export const OpeningHoursWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const DayAndDate = styled.div`
    display: flex;
    justify-content: space-between;
    width: 177px;
`;

export const StyledText = styled.p<{ showAsBold: boolean }>`
    color: #0b0b0b;
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    font-size: 14px;
    letter-spacing: 0;
    line-height: 30px;
    font-weight: ${(props) => (props.showAsBold ? '600' : 'normal')};
`;

export const FromAndTo = styled.div`
    display: flex;
    justify-content: space-between;
`;
