import { VFC } from 'react';
import Image from 'next/image';
import {
    ArrowContainer,
    SortingDropdownOptions,
    SortingDropdownWrapper,
    SortingOption,
    SortingValue,
    StyledSortingLabel,
} from './filter-dropdown.styled';
import { useSelect } from 'downshift';
import { SortingLabel } from '../../../lib/api/models/umbraco';

type SortingDropdownProps = {
    className?: string;
    label?: string;
    selectedValue: string;
    options: SortingLabel[];
    onChangeSorting: (selection: string) => void;
};

export const SortingDropdown: VFC<SortingDropdownProps> = ({ className, selectedValue, label, options, onChangeSorting }) => {
    const { isOpen, getItemProps, getLabelProps, getMenuProps, getToggleButtonProps, toggleMenu } = useSelect({
        items: options,
        itemToString: (item) => (item ? item.labelText : ''),
        onSelectedItemChange: ({ selectedItem }) => {
            onChangeSorting(selectedItem ? selectedItem.sortingKey : '');
            toggleMenu();
        },
    });

    return (
        <SortingDropdownWrapper className={className} {...getToggleButtonProps()}>
            <StyledSortingLabel {...getLabelProps()}>{label ?? 'Sortér efter'}</StyledSortingLabel>
            <SortingValue>{selectedValue}</SortingValue>
            <ArrowContainer open={isOpen}>
                <Image src="/icons/chevron/down.svg" width="20px" height="20px" />
            </ArrowContainer>
            <SortingDropdownOptions open={isOpen} {...getMenuProps()}>
                {isOpen &&
                    options.map((item, index) => {
                        return (
                            <SortingOption
                                selected={item.sortingKey === selectedValue}
                                key={index}
                                {...getItemProps({ item, index })}
                                disabled={item.disabled}
                            >
                                {item.labelText}
                            </SortingOption>
                        );
                    })}
            </SortingDropdownOptions>
        </SortingDropdownWrapper>
    );
};
