import Link from 'next/link';
import { FC, useMemo } from 'react';
import { SearchStoreContext } from '../../../lib/state/search/search.store';
import {
    StyledContentResult,
    StyledContentResultHeader,
    StyledContentResultText,
    StyledDesktopCarouselWrapper,
    StyledDragSliderWrapper,
    StyledMobileCarouselWrapper,
    StyledTabletCarouselWrapper,
} from './search-results.styled';
import { HorizontalDragSlider } from '../../shared/horizontal-drag-slider/horizontal-drag-slider.component';

export const ContentSearchResults: FC = () => {
    const { searchContentResults } = SearchStoreContext.useStoreState((state) => state);

    const searchResultsContent = useMemo(() => {
        return searchContentResults.map((content, index: number) => {
            if (!content.data || !content.data.url || !content.data.url.value) return null;
            const title =
                content.data.metaTitle && content.data.metaTitle.value && (content.data.metaTitle.value as string).length > 0
                    ? content.data.metaTitle.value
                    : content.displayName;
            const subText =
                content.data && content.data.metaDescription && content.data.metaDescription.value
                    ? (content.data.metaDescription.value as string)
                    : '';

            return (
                <Link key={index} href={content.data.url.value as string}>
                    <StyledContentResult href={content.data.url.value as string} className="keen-slider__slide">
                        <StyledContentResultHeader>{title}</StyledContentResultHeader>
                        {subText.length > 0 && <StyledContentResultText>{subText}</StyledContentResultText>}
                    </StyledContentResult>
                </Link>
            );
        });
    }, [searchContentResults]);

    return (
        <>
            <StyledDragSliderWrapper>
                {searchContentResults.length > 0 ? (
                    <>
                        <StyledMobileCarouselWrapper>
                            <HorizontalDragSlider perView={1.5} key={`${searchContentResults.map((con) => `${con.contentId}`)}`} controls={true}>
                                {searchResultsContent}
                            </HorizontalDragSlider>
                        </StyledMobileCarouselWrapper>
                        <StyledTabletCarouselWrapper>
                            <HorizontalDragSlider perView={2.5} key={`${searchContentResults.map((con) => `${con.contentId}`)}`} controls={true}>
                                {searchResultsContent}
                            </HorizontalDragSlider>
                        </StyledTabletCarouselWrapper>
                        <StyledDesktopCarouselWrapper>
                            <HorizontalDragSlider perView={3.5} key={`${searchContentResults.map((con) => `${con.contentId}`)}`} controls={true}>
                                {searchResultsContent}
                            </HorizontalDragSlider>
                        </StyledDesktopCarouselWrapper>
                    </>
                ) : null}
            </StyledDragSliderWrapper>
        </>
    );
};
