import { useEffect, VFC } from 'react';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { hesselViewModels } from '../../../lib/view-models';
import { formatPrice, isNullOrEmpty } from '../../../utils/helpers';
import { CompactCarousel } from '../../shared/carousels/compact-carousel/compact-carousel.component';
import { SimpleCarousel } from '../../shared/carousels/simple-carousel/simple-carousel.component';
import { SidePanel } from '../../shared/modals/side-panel';
import { SidePanelLayout } from '../../shared/modals/side-panel-layout/side-panel-layout.component';
import { SimpleAccordion } from '../../shared/simple-accordion';
import { CollapsibleItem } from './collapsible-item';
import {
    Header,
    AccordionListWrapper,
    AccordionHeaderWrapper,
    StyledHeader,
    StyledEquipmentPrice,
    StyledAccordionContent,
    StyledText,
    StyledUl,
    StyledLi,
    ImageWrapper,
    StyledImage,
    StyledPackageContent,
    AllEquipmentWrapper,
    EquipmentInfoWrapper,
    PackageHeaderAndResources,
} from './equipment-dialog.styled';
import { useEquipmentAccordionController } from './hooks/use-equipment-accordion';
import { useEquipmentData } from '../hooks/use-equipment-data';
import { useScrollLock } from '../../../hooks/use-scroll-lock';
import { Abbreviations } from '../../../constants/units-and-abbreviations';
import { InStockAvailabilityKey } from '../../../constants/site-consts';

export type EquipmentDialogProps = {
    visible: boolean;
    hideModal: () => void;
    giveImagesSquaredCorners?: boolean;

    selectedEquipment?: hesselViewModels.Equipment;
    selectedOptionalEquipment?: hesselViewModels.Equipment;
    selectedEquipmentPackage?: hesselViewModels.EquipmentPackage;

    standardEquipmentList?: Array<hesselViewModels.Equipment>;
    extraEquipmentList?: Array<hesselViewModels.Equipment>;
    optionalEquipmentList?: Array<hesselViewModels.Equipment>;

    standardEquipmentPackage?: Array<hesselViewModels.EquipmentPackage>;
    extraEquipmentPackage?: Array<hesselViewModels.EquipmentPackage>;
    availability?: hesselViewModels.VehicleAvailability;
    includedEquipmentText: string;
    showAllEquipment?: boolean;
    selectedOwnershipTab?: hesselViewModels.OwnershipTab;

    equipmentNames?: Array<string>;

    storeSelectedTabOverride?: hesselViewModels.OwnershipTab;
};

const EQUIPMENT_PREFIX = 'equipment-';
const OPTIONAL_EQUIPMENT_PREFIX = 'optional-equipment-';
const EQUIPMENT_PACKAGE_PREFIX = 'equipment-package-';

const getOpenAccordionPackageIndex = (
    selectedPackage?: hesselViewModels.EquipmentPackage,
    packageList?: Array<hesselViewModels.EquipmentPackage>
) => {
    if (selectedPackage && packageList) {
        return packageList.findIndex((x) => x.id === selectedPackage.id);
    }

    return undefined;
};

const getOpenAccordionEquipmentIndex = (selectedEquipment?: hesselViewModels.Equipment, equipmentList?: Array<hesselViewModels.Equipment>) => {
    if (!selectedEquipment || !equipmentList) {
        return undefined;
    }

    if (!selectedEquipment.description || selectedEquipment.description.length === 0) {
        return undefined;
    }

    return equipmentList.findIndex((x) => x.id === selectedEquipment.id);
};

export const EquipmentDialog: VFC<EquipmentDialogProps> = ({
    visible,
    hideModal,
    selectedEquipment,
    selectedOptionalEquipment,
    selectedEquipmentPackage,
    standardEquipmentList,
    extraEquipmentList,
    optionalEquipmentList,
    extraEquipmentPackage,
    standardEquipmentPackage,
    availability,
    includedEquipmentText,
    showAllEquipment,
    selectedOwnershipTab,
    equipmentNames = [],
    storeSelectedTabOverride,
    giveImagesSquaredCorners,
}) => {
    const { registerAccordion: extraEquipmentAccordion } = useEquipmentAccordionController(
        getOpenAccordionEquipmentIndex(selectedEquipment, extraEquipmentList)
    );

    const { registerAccordion: standardEquipmentAccordion } = useEquipmentAccordionController(
        getOpenAccordionEquipmentIndex(selectedEquipment, standardEquipmentList)
    );

    const { registerAccordion: optionalEquipmentAccordion } = useEquipmentAccordionController(
        getOpenAccordionEquipmentIndex(selectedOptionalEquipment, optionalEquipmentList)
    );

    const { registerAccordion: standardEquipmentPackageAccordion } = useEquipmentAccordionController(
        getOpenAccordionPackageIndex(selectedEquipmentPackage, standardEquipmentPackage)
    );

    const { registerAccordion: extraEquipmentPackageAccordion } = useEquipmentAccordionController(
        getOpenAccordionPackageIndex(selectedEquipmentPackage, extraEquipmentPackage)
    );

    useEffect(() => {
        setTimeout(() => {
            let element: HTMLElement | null = null;

            if (selectedEquipment) {
                element = document.getElementById(`equipment-${selectedEquipment.id}`);
            } else if (selectedOptionalEquipment) {
                element = document.getElementById(`optional-equipment-${selectedOptionalEquipment.id}`);
            } else if (selectedEquipmentPackage) {
                element = document.getElementById(`equipment-package-${selectedEquipmentPackage.id}`);
            }

            element?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }, 500);
    }, [selectedEquipment, selectedEquipmentPackage, selectedOptionalEquipment]);

    const { getEquipmentPrice } = useEquipmentData(storeSelectedTabOverride);

    const { applyScrollBars } = useScrollLock();

    const isMobile = useMediaQuery({ target: 'tablet' });

    return (
        <SidePanel
            cancelAction={() => {
                applyScrollBars();
                hideModal();
            }}
            isVisible={visible}
            scrollToTop={showAllEquipment}
        >
            <SidePanelLayout
                closeSidePanel={() => {
                    applyScrollBars();
                    hideModal();
                }}
            >
                {selectedOptionalEquipment && optionalEquipmentList && optionalEquipmentList.length > 0 ? (
                    <AllEquipmentWrapper>
                        {/* Optional equipment */}
                        <EquipmentInfoWrapper>
                            <Header>Tilvalg</Header>
                            <AccordionListWrapper>
                                {optionalEquipmentList?.map((optionalEq, index) => {
                                    return (
                                        <SimpleAccordion
                                            key={`optional-equipment:${optionalEq.id}`}
                                            title={''}
                                            border={'bottom'}
                                            headerSize="0px"
                                            textColor={'0B0B0B'}
                                            {...optionalEquipmentAccordion(index)}
                                            contentPadding={'15px 0px'}
                                            toggleIndicator="plus"
                                            customTitle={
                                                <AccordionHeaderWrapper id={`${OPTIONAL_EQUIPMENT_PREFIX}${optionalEq.id}`}>
                                                    <StyledHeader>{optionalEq.name}</StyledHeader>
                                                    <StyledEquipmentPrice>
                                                        {selectedOwnershipTab === 'Car HiRE'
                                                            ? availability !== InStockAvailabilityKey && optionalEq.monthlyHirePrice
                                                                ? `${formatPrice(optionalEq.monthlyHirePrice)} ${Abbreviations.KR_SLASH_MD}`
                                                                : `Værdi af ${formatPrice(optionalEq.monthlyHirePrice ?? -1)} ${
                                                                      Abbreviations.KR_SLASH_MD
                                                                  }`
                                                            : null}

                                                        {selectedOwnershipTab !== 'Car HiRE' ? getEquipmentPrice(optionalEq)?.formatted : null}
                                                    </StyledEquipmentPrice>
                                                </AccordionHeaderWrapper>
                                            }
                                            disableAccordion={
                                                isNullOrEmpty(optionalEq.description) && (!optionalEq.resources || optionalEq.resources.length === 0)
                                            }
                                        >
                                            <StyledAccordionContent>
                                                {optionalEq.description ? <StyledText>{optionalEq.description}</StyledText> : null}

                                                {optionalEq.resources && optionalEq.resources.length === 1 ? (
                                                    <ImageWrapper>
                                                        <StyledImage
                                                            alt={optionalEq.resources[0].name}
                                                            src={optionalEq.resources[0].data}
                                                            useSquaredCorners={giveImagesSquaredCorners}
                                                        />
                                                    </ImageWrapper>
                                                ) : null}

                                                {optionalEq.resources && optionalEq.resources.length > 1 ? (
                                                    !isMobile ? (
                                                        <SimpleCarousel
                                                            slides={optionalEq.resources?.map((r) => {
                                                                return {
                                                                    id: r.id,
                                                                    type: 'image',
                                                                    url: r.data,
                                                                    caption: r.name,
                                                                };
                                                            })}
                                                            isAboveFold={false}
                                                        />
                                                    ) : (
                                                        <CompactCarousel
                                                            slides={optionalEq.resources?.map((r) => {
                                                                return {
                                                                    id: r.id,
                                                                    type: 'image',
                                                                    url: r.data,
                                                                    caption: r.name,
                                                                };
                                                            })}
                                                            isAboveFold={false}
                                                        />
                                                    )
                                                ) : null}
                                            </StyledAccordionContent>
                                        </SimpleAccordion>
                                    );
                                })}
                            </AccordionListWrapper>
                        </EquipmentInfoWrapper>
                    </AllEquipmentWrapper>
                ) : (
                    <AllEquipmentWrapper>
                        {/* Extra equipment and extra equipment package */}
                        {extraEquipmentList?.length || extraEquipmentPackage?.length ? (
                            <EquipmentInfoWrapper>
                                <Header>{availability === 'Order' ? 'Muligt ekstraudstyr' : 'Ekstraudstyr inkluderet i bilen'}</Header>
                                <AccordionListWrapper>
                                    {extraEquipmentList?.map((extraEq, index) => {
                                        return (
                                            <SimpleAccordion
                                                key={`extra-equipment:${extraEq.id}`}
                                                title={''}
                                                border={'bottom'}
                                                headerSize="0px"
                                                textColor={'0B0B0B'}
                                                {...extraEquipmentAccordion(index)}
                                                contentPadding={'15px 0px'}
                                                toggleIndicator="plus"
                                                customTitle={
                                                    <AccordionHeaderWrapper id={`${EQUIPMENT_PREFIX}${extraEq.id}`}>
                                                        <StyledHeader>{extraEq.name}</StyledHeader>
                                                        <StyledEquipmentPrice>
                                                            {selectedOwnershipTab === 'Car HiRE' &&
                                                            availability === 'Order' &&
                                                            extraEq.monthlyHirePrice
                                                                ? `Værdi af ${formatPrice(extraEq.monthlyHirePrice)} ${Abbreviations.KR_SLASH_MD}`
                                                                : null}

                                                            {selectedOwnershipTab === 'Car HiRE' && availability === 'InStock'
                                                                ? includedEquipmentText
                                                                : null}

                                                            {selectedOwnershipTab !== 'Car HiRE' ? getEquipmentPrice(extraEq)?.formatted : null}
                                                        </StyledEquipmentPrice>
                                                    </AccordionHeaderWrapper>
                                                }
                                                disableAccordion={
                                                    isNullOrEmpty(extraEq.description) && (!extraEq.resources || extraEq.resources.length === 0)
                                                }
                                            >
                                                <StyledAccordionContent>
                                                    {extraEq.description ? <StyledText>{extraEq.description}</StyledText> : null}

                                                    {extraEq.resources && extraEq.resources.length === 1 ? (
                                                        <ImageWrapper>
                                                            <StyledImage alt={extraEq.resources[0].name} src={extraEq.resources[0].data} />
                                                        </ImageWrapper>
                                                    ) : null}

                                                    {extraEq.resources && extraEq.resources.length > 1 ? (
                                                        !isMobile ? (
                                                            <SimpleCarousel
                                                                slides={extraEq.resources?.map((r) => {
                                                                    return {
                                                                        id: r.id,
                                                                        type: 'image',
                                                                        url: r.data,
                                                                        caption: r.name,
                                                                    };
                                                                })}
                                                                isAboveFold={false}
                                                            />
                                                        ) : (
                                                            <CompactCarousel
                                                                slides={extraEq.resources?.map((r) => {
                                                                    return {
                                                                        id: r.id,
                                                                        type: 'image',
                                                                        url: r.data,
                                                                        caption: r.name,
                                                                    };
                                                                })}
                                                                isAboveFold={false}
                                                            />
                                                        )
                                                    ) : null}
                                                </StyledAccordionContent>
                                            </SimpleAccordion>
                                        );
                                    })}

                                    {extraEquipmentPackage?.map((extraEqPackage, index) => {
                                        return (
                                            <SimpleAccordion
                                                key={`extra-equipment-package:${extraEqPackage.id}`}
                                                title={''}
                                                border={'bottom'}
                                                headerSize="0px"
                                                textColor={'0B0B0B'}
                                                {...extraEquipmentPackageAccordion(index)}
                                                contentPadding={'15px 0px'}
                                                toggleIndicator="plus"
                                                customTitle={
                                                    <AccordionHeaderWrapper id={`${EQUIPMENT_PACKAGE_PREFIX}${extraEqPackage.id}`}>
                                                        <StyledHeader>{extraEqPackage.name}</StyledHeader>
                                                        <StyledEquipmentPrice>
                                                            {selectedOwnershipTab === 'Car HiRE' &&
                                                            availability === 'Order' &&
                                                            extraEqPackage.monthlyHirePrice
                                                                ? `Værdi af ${formatPrice(extraEqPackage.monthlyHirePrice)} ${
                                                                      Abbreviations.KR_SLASH_MD
                                                                  }`
                                                                : null}

                                                            {selectedOwnershipTab === 'Car HiRE' && availability === 'InStock'
                                                                ? includedEquipmentText
                                                                : null}

                                                            {selectedOwnershipTab !== 'Car HiRE'
                                                                ? getEquipmentPrice(extraEqPackage)?.formatted
                                                                : null}
                                                        </StyledEquipmentPrice>
                                                    </AccordionHeaderWrapper>
                                                }
                                                disableAccordion={
                                                    isNullOrEmpty(extraEqPackage.description) &&
                                                    (!extraEqPackage.equipment || extraEqPackage.equipment.length === 0)
                                                }
                                            >
                                                <StyledAccordionContent>
                                                    <PackageHeaderAndResources
                                                        style={{ paddingTop: isNullOrEmpty(extraEqPackage.description) ? '5px' : '0' }}
                                                    >
                                                        {!isNullOrEmpty(extraEqPackage.description) ? (
                                                            <StyledText>{extraEqPackage.description}</StyledText>
                                                        ) : null}

                                                        {extraEqPackage.resources && extraEqPackage.resources.length === 1 ? (
                                                            <ImageWrapper>
                                                                <StyledImage
                                                                    alt={extraEqPackage.resources[0].name}
                                                                    src={extraEqPackage.resources[0].data}
                                                                />
                                                            </ImageWrapper>
                                                        ) : null}
                                                    </PackageHeaderAndResources>

                                                    {extraEqPackage.resources && extraEqPackage.resources.length > 1 ? (
                                                        !isMobile ? (
                                                            <SimpleCarousel
                                                                slides={extraEqPackage.resources?.map((r) => {
                                                                    return {
                                                                        id: r.id,
                                                                        type: 'image',
                                                                        url: r.data,
                                                                        caption: r.name,
                                                                    };
                                                                })}
                                                                isAboveFold={false}
                                                            />
                                                        ) : (
                                                            <CompactCarousel
                                                                slides={extraEqPackage.resources?.map((r) => {
                                                                    return {
                                                                        id: r.id,
                                                                        type: 'image',
                                                                        url: r.data,
                                                                        caption: r.name,
                                                                    };
                                                                })}
                                                                isAboveFold={false}
                                                            />
                                                        )
                                                    ) : null}

                                                    {extraEqPackage.equipment.length > 0 ? (
                                                        <StyledPackageContent>
                                                            <StyledHeader>Udstyrspakken indeholder</StyledHeader>
                                                            <StyledUl>
                                                                {extraEqPackage.equipment.map((extraEqPackage, index) => {
                                                                    return (
                                                                        <StyledLi key={`extra-equipment-package-item-${extraEqPackage.id}:${index}`}>
                                                                            {!extraEqPackage.description ? (
                                                                                <StyledText>{extraEqPackage.name}</StyledText>
                                                                            ) : (
                                                                                <CollapsibleItem
                                                                                    name={extraEqPackage.name}
                                                                                    description={extraEqPackage.description}
                                                                                    resources={extraEqPackage.resources}
                                                                                />
                                                                            )}
                                                                        </StyledLi>
                                                                    );
                                                                })}
                                                            </StyledUl>
                                                        </StyledPackageContent>
                                                    ) : null}
                                                </StyledAccordionContent>
                                            </SimpleAccordion>
                                        );
                                    })}
                                </AccordionListWrapper>
                            </EquipmentInfoWrapper>
                        ) : null}

                        {/* Standard equipment and standard equipment package */}
                        {standardEquipmentList?.length || standardEquipmentPackage?.length ? (
                            <EquipmentInfoWrapper>
                                <Header>Standardudstyr inkluderet i bilen</Header>
                                <AccordionListWrapper>
                                    {standardEquipmentList?.map((standardEq, index) => {
                                        return (
                                            <SimpleAccordion
                                                key={`standard-equipment:${standardEq.id}`}
                                                title={''}
                                                border={'bottom'}
                                                headerSize="0px"
                                                textColor={'0B0B0B'}
                                                {...standardEquipmentAccordion(index)}
                                                contentPadding={'15px 0px'}
                                                toggleIndicator="plus"
                                                customTitle={
                                                    <AccordionHeaderWrapper id={`${EQUIPMENT_PREFIX}${standardEq.id}`}>
                                                        <StyledHeader>{standardEq.name}</StyledHeader>
                                                    </AccordionHeaderWrapper>
                                                }
                                                disableAccordion={
                                                    isNullOrEmpty(standardEq.description) &&
                                                    (!standardEq.resources || standardEq.resources.length === 0)
                                                }
                                            >
                                                <StyledAccordionContent>
                                                    {standardEq.description ? <StyledText>{standardEq.description}</StyledText> : null}

                                                    {standardEq.resources && standardEq.resources.length === 1 ? (
                                                        <ImageWrapper>
                                                            <StyledImage alt={standardEq.resources[0].name} src={standardEq.resources[0].data} />
                                                        </ImageWrapper>
                                                    ) : null}

                                                    {standardEq.resources && standardEq.resources.length > 1 ? (
                                                        !isMobile ? (
                                                            <SimpleCarousel
                                                                slides={standardEq.resources?.map((r) => {
                                                                    return {
                                                                        id: r.id,
                                                                        type: 'image',
                                                                        url: r.data,
                                                                        caption: r.name,
                                                                    };
                                                                })}
                                                                isAboveFold={false}
                                                            />
                                                        ) : (
                                                            <CompactCarousel
                                                                slides={standardEq.resources?.map((r) => {
                                                                    return {
                                                                        id: r.id,
                                                                        type: 'image',
                                                                        url: r.data,
                                                                        caption: r.name,
                                                                    };
                                                                })}
                                                                isAboveFold={false}
                                                            />
                                                        )
                                                    ) : null}
                                                </StyledAccordionContent>
                                            </SimpleAccordion>
                                        );
                                    })}

                                    {standardEquipmentPackage?.filter(Boolean).map((standardEqPackage, index) => {
                                        return (
                                            <SimpleAccordion
                                                key={`standard-equipment-package:${standardEqPackage.id}`}
                                                title={''}
                                                border={'bottom'}
                                                headerSize="0px"
                                                textColor={'0B0B0B'}
                                                {...standardEquipmentPackageAccordion(index)}
                                                contentPadding={'15px 0px'}
                                                toggleIndicator="plus"
                                                customTitle={
                                                    <AccordionHeaderWrapper id={`${EQUIPMENT_PACKAGE_PREFIX}${standardEqPackage.id}`}>
                                                        <StyledHeader>{standardEqPackage.name}</StyledHeader>
                                                        <StyledEquipmentPrice>
                                                            {availability === 'Order' && standardEqPackage.monthlyHirePrice
                                                                ? `Værdi af ${formatPrice(standardEqPackage.monthlyHirePrice)} ${
                                                                      Abbreviations.KR_SLASH_MD
                                                                  }`
                                                                : null}

                                                            {availability === 'InStock' ? includedEquipmentText : null}
                                                        </StyledEquipmentPrice>
                                                    </AccordionHeaderWrapper>
                                                }
                                                disableAccordion={
                                                    isNullOrEmpty(standardEqPackage.description) &&
                                                    (!standardEqPackage.equipment || standardEqPackage.equipment.length === 0)
                                                }
                                            >
                                                <StyledAccordionContent>
                                                    <PackageHeaderAndResources
                                                        style={{ paddingTop: isNullOrEmpty(standardEqPackage.description) ? '5px' : '0' }}
                                                    >
                                                        {!isNullOrEmpty(standardEqPackage.description) ? (
                                                            <StyledText>{standardEqPackage.description}</StyledText>
                                                        ) : null}

                                                        {standardEqPackage.resources && standardEqPackage.resources.length === 1 ? (
                                                            <ImageWrapper>
                                                                <StyledImage
                                                                    alt={standardEqPackage.resources[0].name}
                                                                    src={standardEqPackage.resources[0].data}
                                                                />
                                                            </ImageWrapper>
                                                        ) : null}

                                                        {standardEqPackage.resources && standardEqPackage.resources.length > 1 ? (
                                                            !isMobile ? (
                                                                <SimpleCarousel
                                                                    slides={standardEqPackage.resources?.map((r) => {
                                                                        return {
                                                                            id: r.id,
                                                                            type: 'image',
                                                                            url: r.data,
                                                                            caption: r.name,
                                                                        };
                                                                    })}
                                                                    isAboveFold={false}
                                                                />
                                                            ) : (
                                                                <CompactCarousel
                                                                    slides={standardEqPackage.resources?.map((r) => {
                                                                        return {
                                                                            id: r.id,
                                                                            type: 'image',
                                                                            url: r.data,
                                                                            caption: r.name,
                                                                        };
                                                                    })}
                                                                />
                                                            )
                                                        ) : null}
                                                    </PackageHeaderAndResources>

                                                    {standardEqPackage.equipment && standardEqPackage.equipment.length > 0 ? (
                                                        <StyledPackageContent>
                                                            <StyledHeader>Udstyrspakken indeholder</StyledHeader>
                                                            <StyledUl>
                                                                {standardEqPackage.equipment.map((stdEqpPackage, index) => {
                                                                    return (
                                                                        <StyledLi
                                                                            key={`standard-equipment-package-item-${stdEqpPackage.id}:${index}`}
                                                                        >
                                                                            {!stdEqpPackage.description ? (
                                                                                <StyledText>{stdEqpPackage.name}</StyledText>
                                                                            ) : (
                                                                                <CollapsibleItem
                                                                                    name={stdEqpPackage.name}
                                                                                    description={stdEqpPackage.description}
                                                                                    resources={stdEqpPackage.resources}
                                                                                />
                                                                            )}
                                                                        </StyledLi>
                                                                    );
                                                                })}
                                                            </StyledUl>
                                                        </StyledPackageContent>
                                                    ) : null}
                                                </StyledAccordionContent>
                                            </SimpleAccordion>
                                        );
                                    })}
                                </AccordionListWrapper>
                            </EquipmentInfoWrapper>
                        ) : null}
                    </AllEquipmentWrapper>
                )}

                {availability === 'Used' || availability === 'Engros' ? (
                    <AllEquipmentWrapper>
                        {/* Used Cars Equipment List */}
                        {equipmentNames?.length > 0 ? (
                            <EquipmentInfoWrapper>
                                <Header>Udstyr som bilen står med </Header>
                                <AccordionListWrapper>
                                    {equipmentNames?.map((eqName, index) => {
                                        return (
                                            <SimpleAccordion
                                                key={`used-car-equipment-name:${eqName}`}
                                                title={''}
                                                border={'bottom'}
                                                headerSize="0px"
                                                textColor={'0B0B0B'}
                                                {...standardEquipmentAccordion(index)}
                                                contentPadding={'15px 0px'}
                                                toggleIndicator="plus"
                                                customTitle={
                                                    <AccordionHeaderWrapper id={`${EQUIPMENT_PREFIX}${eqName}`}>
                                                        <StyledHeader>{eqName}</StyledHeader>
                                                    </AccordionHeaderWrapper>
                                                }
                                                disableAccordion={true}
                                            >
                                                {null}
                                            </SimpleAccordion>
                                        );
                                    })}
                                </AccordionListWrapper>
                            </EquipmentInfoWrapper>
                        ) : null}
                    </AllEquipmentWrapper>
                ) : null}
            </SidePanelLayout>
        </SidePanel>
    );
};
