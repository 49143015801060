import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const ImageAndIframe = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media ${device.laptopS} {
        display: grid;
        grid-template-columns: 45% 1fr;
        gap: 60px;
    }
    min-height: 80vh;
`;

export const ImageWrapper = styled.div``;

export const IframeWrapper = styled.div``;

export const StyledHeader = styled.p`
    font-size: 25px;
    letter-spacing: 0;
    font-family: 'Corporate E';
    margin-bottom: 20px;
`;

export const StyledImage = styled.img`
    display: block;
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: center;
    display: none;

    @media ${device.laptopS} {
        height: 100%;
        display: revert;
    }
`;

export const StyledIFrame = styled.iframe`
    display: block;
    border: none;
    overflow: hidden;
    width: 100%;
    height: 100%;
    min-height: 80vh;
`;
