import useSWR from 'swr';
import { getPageByDataTypeAlias } from '../../../../lib/api';
import { DealershipInformationPage, StarmarkDealershipPage } from '../../../../lib/api/models/umbraco';
import { ProductDetailsStore } from '../../../../lib/state/hessel-site/product-details';

type PdpDealershipsReturnType = {
    dealerships: Array<DealershipInformationPage> | undefined;
    starmarkDealerships: Array<StarmarkDealershipPage> | undefined;
    error: Error | undefined;
};

export const usePdpDealerships = (): PdpDealershipsReturnType => {
    const { selectedProduct } = ProductDetailsStore.useStoreState((state) => state);

    const fetcher = async () => {
        const [listOfDealerships, listOfDealershipsError] = await getPageByDataTypeAlias('dealershipInformation');
        const [starmarkDealerships, starmarkDealershipsError] = await getPageByDataTypeAlias('starmarkDealershipInformation');

        if (!listOfDealerships || listOfDealerships.length === 0 || listOfDealershipsError || !starmarkDealerships || starmarkDealershipsError) {
            throw new Error('No dealerships for dealershipInformation data type alias.');
        }

        return {
            dealerships: listOfDealerships as Array<DealershipInformationPage>,
            starmarkDealerships: starmarkDealerships as Array<StarmarkDealershipPage>,
        };
    };

    const { data, error } = useSWR(['dealershipInformation', selectedProduct?.id], fetcher, { dedupingInterval: Infinity });

    return {
        dealerships: data?.dealerships,
        starmarkDealerships: data?.starmarkDealerships,
        error,
    };
};
