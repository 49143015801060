import React, { VFC } from 'react';
import { ContentSpotRichText } from '../content-spot-rich-text/content-spot-rich-text.component';
import { StyledHeader, StyledSubmitButton, SubmitResult } from './general-form-submit-receipt.styled';

type GeneralFormSubmitReceiptProps = {
    title: string;
    description: string;
    buttonText: string;
    onCtaClick: () => void;
};

const GeneralFormSubmitReceiptMemo: VFC<GeneralFormSubmitReceiptProps> = ({ title, description, buttonText, onCtaClick }) => {
    return (
        <SubmitResult>
            <StyledHeader>{title}</StyledHeader>
            <ContentSpotRichText text={description} />
            <StyledSubmitButton
                variant="primary"
                onClick={() => {
                    onCtaClick();
                }}
            >
                <p>{buttonText}</p>
            </StyledSubmitButton>
        </SubmitResult>
    );
};

export const GeneralFormSubmitReceipt = React.memo(GeneralFormSubmitReceiptMemo);
