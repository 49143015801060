import { FC, useState } from 'react';
import { umbraco } from '../../lib/api';
import { ContactInfo } from '../faq/contact-info';
import { FaqList } from '../faq/faq-list';
import { UspList } from '../faq/usp-list';
import { Button, SimpleModal } from '../shared';
import { useUI } from '../ui';
import { ButtonWrapper, StyledHelp, ContentWrapper } from './help-center.styled';

type Props = {
    helpSettings?: umbraco.CustomerHelpCenter[];
};

export const HelpCenter: FC<Props> = ({ helpSettings }) => {
    const [showHelpCenterModal, setShowHelpCenterModal] = useState(false);

    const ui = useUI();

    if (!helpSettings) {
        return <h3>Ingen data fundet</h3>;
    }

    const helpSettingsHtml = (
        <StyledHelp>
            {helpSettings.map((setting) => {
                switch (setting.type) {
                    case 'Contact Details':
                        return (
                            <ContactInfo
                                key={setting.alias}
                                email={setting.customerEmail}
                                telephone={setting.customerTel}
                                title={setting.headerText}
                            />
                        );
                    case 'FAQ':
                        return <FaqList key={setting.alias} title={setting.headerText} questions={setting.items} />;
                    case 'USP':
                        return <UspList key={setting.alias} title={setting.headerText} items={setting.items} />;

                    default:
                        return <h3>Unknown</h3>;
                }
            })}
        </StyledHelp>
    );

    const customerDetails = helpSettings.find((x) => x.type === 'Contact Details');

    const getHelpText = customerDetails && customerDetails.type === 'Contact Details' ? customerDetails.getHelpButtonText : 'Få hjælp';

    return (
        <>
            <ContentWrapper>{helpSettingsHtml}</ContentWrapper>

            <SimpleModal
                headerText={getHelpText}
                isVisible={showHelpCenterModal}
                closeAction={() => {
                    setShowHelpCenterModal(false);
                    ui.applyScroll();
                }}
            >
                {helpSettingsHtml}
            </SimpleModal>

            <ButtonWrapper>
                <Button
                    onClick={() => {
                        setShowHelpCenterModal(true);
                        ui.removeScrollAndLock();
                    }}
                    variant="light"
                >
                    {getHelpText}
                </Button>
            </ButtonWrapper>
        </>
    );
};
