import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useSpring } from 'react-spring';
import { useDebounce, useMeasure } from 'react-use';
import {
    StyledClearSearch,
    StyledCloseText,
    StyledFadeWrapper,
    StyledLogoText,
    StyledRecommendedLinksWrapper,
    StyledSearchHeaderArea,
    StyledSearchHeaderFlexContainer,
    StyledSearchInput,
    StyledSearchInputContainer,
    StyledSearchOverlayContainer,
    StyledSearchResultsBackground,
    StyledSearchWrapper,
} from './search-overlay.styled';
import { CenteredBlock } from '../structural-blocks/centered-block.component';
import { InspirationalLink } from '../mega-menu/inspirational-link/inspirational-link.compontent';
import { SvgIcon } from '../shared/svg-icon';
import { useSearchOverlay } from '../../hooks/use-search-overlay';
import { SearchStoreContext } from '../../lib/state/search/search.store';
import { SearchResults } from './search-results/search-results.component';
import { RecommendedSearchWords } from './recommended-search-words/recommended-search-words.component';
import { SearchNoResults } from './search-no-results/search-no-results.component';
import { SearchSettings } from '../../lib/api/models/umbraco';
import { MenuContext } from '../mega-menu/mega-menu.component';
import { toggleChatBot } from '../../utils/helpers';
import { useMediaQuery } from '../../hooks/use-media-query';
import { useSegmentContext } from '../../hooks/use-segment-context';

type IProps = {
    isOpen: boolean;
    searchSettings: SearchSettings;
    menuContext: MenuContext;
};

export const SearchOverlay: FC<IProps> = ({ isOpen, searchSettings, menuContext }) => {
    const { searchTerm, searchVehicleResults, searchReturnedNoResults, popularSearchTerms, searchContentResults } = SearchStoreContext.useStoreState(
        (state) => state
    );
    const {
        setSearchTerm,
        doSearch,
        setSearchVehicleResults,
        setSearchReturnedNoResults,
        setSearchPredictionResults,
        getPopularSearchTerms,
        setContentResults,
    } = SearchStoreContext.useStoreActions((actions) => actions);
    const { closeSearch } = useSearchOverlay();

    const defaultHeight: string | undefined = '0px';
    const searchInputRef = useRef<HTMLInputElement>(null);

    const [heightRef, { height }] = useMeasure<HTMLDivElement>();

    const [contentHeight, setContentHeight] = useState(defaultHeight || height);

    const minimumSearchTermLength = 2;

    const segment = useSegmentContext();

    const searchIfEnoughCharacters = () => {
        if (searchTerm.length >= minimumSearchTermLength && isOpen) {
            doSearch({ searchTerm, segment });
        } else {
            cancel();
            if (searchTerm.length < minimumSearchTermLength) {
                setSearchVehicleResults([]);
                setSearchReturnedNoResults(false);
                setSearchPredictionResults([]);
                setContentResults([]);
            }
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_searchDebouncer, cancel] = useDebounce(searchIfEnoughCharacters, 200, [searchTerm]);

    useEffect(() => {
        //Sets initial height
        setContentHeight(height - 20);
    }, [height]);

    const expand = useSpring({
        height: isOpen ? `${Number(contentHeight) + 20}px` : defaultHeight,
        overflow: !isOpen ? 'hidden' : '',
        delay: 0,
    });

    const fadeContent = useSpring({
        opacity: isOpen ? 1 : 0,
        delay: isOpen ? 300 : 0,
    });

    useEffect(() => {
        if (isOpen) {
            searchInputRef.current?.focus();
            if (popularSearchTerms.length === 0) {
                getPopularSearchTerms({ segment });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const isDevice = useMediaQuery({ target: 'laptop_tablet_landscape' });

    useEffect(() => {
        if (isDevice && isOpen) {
            toggleChatBot(true);
        } else {
            toggleChatBot(false);
        }
    }, [isDevice, isOpen]);

    const inspirationalLinks = useMemo(() => {
        if (menuContext === 'privat') {
            return searchSettings.inspirationalLinks;
        }
        return searchSettings.inspirationalLinksBusiness;
    }, [menuContext, searchSettings]);

    const clearSearch = () => {
        setSearchTerm('');
        searchInputRef.current?.focus();
    };

    return (
        <StyledSearchOverlayContainer style={expand}>
            <div ref={heightRef}>
                <StyledFadeWrapper style={fadeContent}>
                    <CenteredBlock>
                        <StyledSearchHeaderArea>
                            <StyledSearchHeaderFlexContainer>
                                <StyledLogoText>EJNER HESSEL</StyledLogoText>
                                <StyledSearchInputContainer>
                                    <StyledSearchInput
                                        type="text"
                                        placeholder="Søg her"
                                        ref={searchInputRef}
                                        value={searchTerm}
                                        onChange={(e) => {
                                            setSearchTerm(e.target.value);
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Escape') {
                                                closeSearch();
                                            }
                                        }}
                                    />
                                    {searchTerm.length > 0 && (
                                        <StyledClearSearch>
                                            <SvgIcon iconName="close-in-circle" onClick={clearSearch} customColor="#767676" />
                                        </StyledClearSearch>
                                    )}
                                </StyledSearchInputContainer>
                                <StyledCloseText>
                                    <span onClick={closeSearch}>Luk</span>
                                </StyledCloseText>
                            </StyledSearchHeaderFlexContainer>
                        </StyledSearchHeaderArea>
                    </CenteredBlock>
                    <StyledSearchResultsBackground>
                        <CenteredBlock>
                            {isOpen ? (
                                <StyledSearchWrapper>
                                    <RecommendedSearchWords />
                                    <div
                                        style={{
                                            display: searchVehicleResults.length > 0 || searchContentResults.length > 0 ? 'block' : 'none',
                                            maxWidth: 'calc(100vw - 40px)',
                                        }}
                                    >
                                        <SearchResults />
                                    </div>
                                    <div style={{ display: searchReturnedNoResults ? 'block' : 'none' }}>
                                        <SearchNoResults noResultsText={searchSettings.noResultsText} />
                                    </div>
                                    <div
                                        style={{
                                            display:
                                                searchVehicleResults.length === 0 && searchContentResults.length === 0 && !searchReturnedNoResults
                                                    ? 'block'
                                                    : 'none',
                                        }}
                                    >
                                        <StyledRecommendedLinksWrapper>
                                            {inspirationalLinks.map((link, index: number) => (
                                                <InspirationalLink link={link} key={index}></InspirationalLink>
                                            ))}
                                        </StyledRecommendedLinksWrapper>
                                    </div>
                                </StyledSearchWrapper>
                            ) : null}
                        </CenteredBlock>
                    </StyledSearchResultsBackground>
                </StyledFadeWrapper>
            </div>
        </StyledSearchOverlayContainer>
    );
};
