import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const StyledCheckoutWrapper = styled.main`
    background-color: rgb(247, 247, 248);
    padding: 20px 0;

    @media ${device.laptopS} {
        padding: 40px 0;
    }
`;

export const StyledBackLinkWrapper = styled.div`
    display: inline-flex;
    gap: 20px;
    font-size: 17px;
    font-weight: 500;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;
`;

export const StyledCheckoutGrid = styled.div`
    gap: 15px;
    position: relative;
    display: flex;
    flex-direction: column;
    @media ${device.laptopS} {
        gap: 40px;
        display: grid;
        grid-template-columns: 880fr 420fr;
        align-items: flex-start;
    }
`;

export const StyledStepsList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    order: 1;
    @media ${device.laptopS} {
        order: 0;
        gap: 30px;
    }
`;
