import styled, { css } from 'styled-components';
import { mixins } from '../../../themes';
import { isSiteName, SITE_NAME } from '../../../utils/helpers/site-id.helper';

export const Wrapper = styled.div<{ alignment: string }>`
    /* display: grid;
    row-gap: 5px;
    column-gap: 10px;
    grid-template-columns: calc(${({ theme }) => `${theme.typography.inputRadio.size}px + 10px`}) auto;
    align-items: ${(props) => (props.alignment === 'top' ? 'flex-start' : 'center')}; */
    display: flex;
    align-items: ${(props) => (props.alignment === 'top' ? 'flex-start' : 'center')};
    justify-content: flex-start;
    gap: 10px;

    & + & {
        margin-top: 8px;
    }
`;

/* The container */
export const StyledRadioButton = styled.input.attrs({ type: 'radio' })<{ isValid: boolean }>`
    aspect-ratio: 1 / 1;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid ${(props) => (!props.isValid ? props.theme.palette.error.main : props.theme.forms.borderColorDefault)};
    cursor: pointer;
    display: inline-block;
    width: ${({ theme }) => `${theme.typography.inputRadio.size}px`};
    outline: none;
    position: relative;
    transition: background-color 0.3s, border-color 0.3s, box-shadow 0.2s;
    -moz-appearance: none;
    -webkit-appearance: none;

    &::after {
        background-color: ${({ theme }) => theme.palette.primary.main};
        border-radius: 50%;
        content: '';
        display: block;
        height: calc(${({ theme }) => `${theme.typography.inputRadio.size}px / 2`});
        width: calc(${({ theme }) => `${theme.typography.inputRadio.size}px / 2`});
        opacity: 0;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: opacity 0.3s;

        ${isSiteName(SITE_NAME.CARSAVER) &&
        css`
            background-color: #000;
        `}
    }

    &:checked {
        &::after {
            opacity: 1;
            ${isSiteName(SITE_NAME.CARSAVER) &&
            css`
                background-color: #000;
            `}
        }
    }

    &:hover:not(:checked):not(:disabled) {
        border-color: ${({ theme }) => theme.forms.borderColorHover};
    }

    &:focus {
        box-shadow: 0 0 0 2px rgba(39, 94, 254, 0.3);
    }

    &:disabled {
        background-color: #efefef;
        cursor: not-allowed;

        &:checked {
            background-color: ${({ theme }) => theme.palette.primary.main};
            border-color: ${(props) => props.theme.forms.borderColorDefault};
        }

        & + label {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }
`;

export const StyledLabel = styled.label`
    grid-column: 2 / span 1;
    display: inline-block;
    cursor: pointer;
    font-size: ${({ theme: { forms } }) => forms.fontSize}px;
    font-weight: ${({ theme: { forms } }) => forms.fontWeight};
`;

export const StyledSubLabel = styled.span`
    grid-column: 2 / span 1;
    ${mixins.typography('inputSubLabel')}/* font-size: ${({ theme: { typography } }) => typography.inputSubLabel.fontSize}px; */
`;

export const StyledError = styled.p`
    display: flex;
    flex-direction: column;
    color: #d64469;
    font-size: ${({ theme: { typography } }) => typography.small.fontSize}px;
    margin-left: 10px;
`;
