import styled from 'styled-components';

export const StyledHeader = styled.p`
    font-family: 'Corporate E';
    font-size: 30px;
    line-height: 35px;
    font-weight: bold;
`;

export const StyledSpotWrapper = styled.div<{ bgColor: string }>`
    background-color: ${(props) => props.bgColor};
    padding: 60px 0;
`;

export const StyledEmployeeGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 20px;
    margin-top: 20px;
`;

export const StyledCtaWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 40px;
`;
