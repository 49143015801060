import { useState } from "react";
import { DispatchMethod } from "../gtm-tracking.types";

const CALCULATE_SAVINGS = {
    savingsEvent: 'Se din besparelse',
    opened: 'Påbegyndt',
    click: 'Klik',
    viewDetails: 'Se udregning',
}

export type CalculateSavingsEvents = {
    opened: () => void;
    viewDetails: () => void;
}

const useCalculateSavingsEvents = (dispatch: DispatchMethod): CalculateSavingsEvents => {
    const event = CALCULATE_SAVINGS.savingsEvent;

    return useState({
        opened: () => dispatch({
            event,
            type: CALCULATE_SAVINGS.opened,
        }),
        viewDetails: () => dispatch({
            event,
            type: CALCULATE_SAVINGS.click,
            action: CALCULATE_SAVINGS.viewDetails,
        }),
    })[0];
}

export const trackCalculateSavings = (dispatch: DispatchMethod): () => CalculateSavingsEvents => {
    return () => useCalculateSavingsEvents(dispatch);
}
