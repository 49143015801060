import styled from 'styled-components';

export const Container = styled.article`
    display: grid;
    grid-template-columns: 157px 1fr;
    align-items: center;
    gap: 0.5rem;
`;

export const ImageWrapper = styled.div`
    height: 100%;
`;

export const StyledImage = styled.img`
    display: block;
    max-inline-size: 100%;
    block-size: auto;
    object-fit: contain;
    object-position: center;
`;

export const DetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0px;
`;

export const StyledBadge = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.palette.common.black};
    padding: 2px 10px;
    margin-bottom: 10px;
`;

export const StyledBadgeText = styled.div`
    color: #fcfcfc;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0;
`;

export const StyledModel = styled.p`
    color: #0b0b0b;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
`;

export const StyledPrice = styled.p`
    color: #0b0b0b;
    font-size: 15px;
    letter-spacing: 0;
    font-weight: normal;
`;

export const StyledText = styled.p`
    font-weight: normal;
    text-align: left;
`;
