import styled from 'styled-components';

export const StyledColorOptionWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    column-gap: 20px;
`;

export const StyledColorOption = styled.div<{ disabled: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: center;
    ${(props) => (props.disabled ? 'pointer-events: none;' : '')}
    ${(props) => (props.disabled ? 'opacity: .5;filter:blur(1px);' : '')}
    ${(props) => (props.disabled ? 'cursor: not-allowed' : 'cursor: pointer')};
`;

export const StyledColorImage = styled.div<{ selected: boolean }>`
    border-radius: 50%;
    width: 33px;
    height: 33px;
    display: block;
    flex-grow: 1;
    border: 3px solid ${(props) => (props.selected ? '#0B0B0B' : 'white')};
    background-size: cover;
    background-position: center center;
`;

export const StyledColorOptionName = styled.p`
    color: #807f80;
    font-size: 10px;
`;
