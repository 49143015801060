import { VFC } from 'react';
import { formatPrice } from '../../../utils/helpers';
import {
    BorderBox,
    InputSliderWrapper,
    MinMaxWrapper,
    RangeValue,
    StyledMinMaxText,
    StyledRange,
    StyledSlider,
    StyledThumb,
    StyledTrack,
    StyledValue,
} from './input-slider.styled';

const getLeftPosition = (min: number, max: number, value: number) => {
    const newValue = Number(((value - min) * 100) / (max - min));
    const newPosition = 10 - newValue * 0.2;
    return `calc(${newValue}% + (${newPosition}px))`;
};

type Props = {
    min: number;
    max: number;
    defaultValue: number;
    interval: number;
    value: number;
    unit?: string;
    onValueChange: (value: number) => void;
};

export const InputSlider: VFC<Props> = ({ min, max, defaultValue, interval, value, unit, onValueChange }) => {
    return (
        <BorderBox>
            <InputSliderWrapper>
                <RangeValue leftValue={getLeftPosition(min, max, value)}>
                    <StyledValue>
                        {formatPrice(value)}
                        {unit ?? <>&nbsp;{unit}</>}
                    </StyledValue>
                </RangeValue>
                <StyledSlider
                    defaultValue={[defaultValue]}
                    min={+min}
                    max={+max}
                    step={+interval}
                    aria-label="Price"
                    value={[value]}
                    onValueChange={(e) => {
                        onValueChange(e[0]);
                    }}
                >
                    <StyledTrack>
                        <StyledRange />
                    </StyledTrack>
                    <StyledThumb />
                </StyledSlider>
            </InputSliderWrapper>
            <MinMaxWrapper>
                <StyledMinMaxText>
                    {formatPrice(min)}
                    {unit ? ` ${unit}` : ''}
                </StyledMinMaxText>
                <StyledMinMaxText>
                    {formatPrice(max)}
                    {unit ? ` ${unit}` : ''}
                </StyledMinMaxText>
            </MinMaxWrapper>
        </BorderBox>
    );
};
