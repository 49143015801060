import { useState } from 'react';
import { DispatchMethod } from '../gtm-tracking.types';
import { COMMON_GTM } from '../gtm-tracking-utils';
import { HireFormSubmitData } from '../../lib/api/models/umbraco';

export type BookTestDriveFormEvents = {
    completeBookTestDrive: (prams: HireFormSubmitData) => void;
};

const useBookTestDriveEvents = (dispatch: DispatchMethod): BookTestDriveFormEvents => {
    return useState({
        completeBookTestDrive: (action: HireFormSubmitData) =>
            dispatch({
                event: 'book_prøvetur',
                type: COMMON_GTM.COMPLETED,
                ...action,
            }),
    })[0];
};

export const trackBookTestDriveForm = (dispatch: DispatchMethod): (() => BookTestDriveFormEvents) => {
    return () => useBookTestDriveEvents(dispatch);
};
