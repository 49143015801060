import styled from 'styled-components';

export const StyledDisclaimerWrapper = styled.div`
    padding: 8px 15px;
    background-color: #c2d1d3;
    display: flex;
    gap: 10px;
    p {
        font-size: 12px;
    }
`;
