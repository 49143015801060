import React, { useState, VFC } from 'react';
import { orgTypes } from '../../../lib/api/models/umbraco';
import { MEDIA_URL } from '../../../utils/environment-constants';
import { BrandAndServiceTable } from '../brand-and-service-table/brand-and-service-table.component';
import {
    StyledBrandAndServiceModule,
    Header,
    Description,
    BookTimeButton,
    ButtonText,
    CertificateImageWrapper,
    CertificateImage,
    CertificateText,
    StyledCenteredBlock,
    CertificateListWrapper,
    CertificateContent,
} from './brand-and-service-module.styled';
import { OrganizationConsts } from '../../../constants';
import { umbraco } from '../../../lib/api';
import { BookWorkshopModal } from '../../organization/book-workshop-modal/book-workshop-modal.component';

export type BrandAndServiceModuleProps = {
    supportedBrands: Array<orgTypes.SupportedBrand>;
    header: string;
    description: string;
    certificates: Array<orgTypes.OrgCertificate>;
    dealership: umbraco.orgTypes.DealershipWithGeoInfo | null;
};

export const BrandAndServiceModule: VFC<BrandAndServiceModuleProps> = ({ supportedBrands, header, description, certificates, dealership }) => {
    const [showBookWorkshopModal, setBookWorkshopModal] = useState(false);

    const workshopIsAvailable = supportedBrands.some((x) => x.vehiclesInWorkshop.length > 0);
    return (
        <>
            <StyledCenteredBlock>
                <StyledBrandAndServiceModule id={OrganizationConsts.SalesAndServiceSpot}>
                    <Header>{header}</Header>
                    <Description>{description}</Description>

                    <BrandAndServiceTable
                        supportedBrands={supportedBrands}
                        onCtaClick={workshopIsAvailable ? () => setBookWorkshopModal(true) : undefined}
                    />

                    {workshopIsAvailable ? (
                        <BookTimeButton variant="primary" onClick={() => setBookWorkshopModal(true)}>
                            <ButtonText>Book tid på værksted</ButtonText>
                        </BookTimeButton>
                    ) : null}

                    <CertificateListWrapper>
                        {certificates.map((x, index) => {
                            return (
                                <CertificateContent key={`certificate-${x.title}-${index}`}>
                                    <CertificateImageWrapper>
                                        <CertificateImage src={MEDIA_URL + x.image.src} alt={x.title} />
                                    </CertificateImageWrapper>
                                    <CertificateText>{x.description}</CertificateText>
                                </CertificateContent>
                            );
                        })}
                    </CertificateListWrapper>
                </StyledBrandAndServiceModule>
            </StyledCenteredBlock>

            {dealership ? (
                <BookWorkshopModal
                    visible={showBookWorkshopModal}
                    header={dealership.bookWorkshopModalTexts?.bookWorkshopHeader ?? 'Book tid på værksted'}
                    description={dealership.bookWorkshopModalTexts?.bookWorkshopDescription ?? 'Følgende mærker serviceres hos'}
                    onClose={() => setBookWorkshopModal(false)}
                    dealership={dealership}
                    bookWorkshopModalButton={dealership.bookWorkshopModalTexts?.bookWorkshopModalButtonText ?? 'Book tid'}
                />
            ) : null}
        </>
    );
};
