import { FC } from 'react';
import { ContactSection, TopLevelLink } from '../../../lib/api/models/umbraco/mega-menu.types';
import { SvgIcon } from '../../shared/svg-icon';
import { ToggleUi } from '../../shared/toggle-ui';
import {
    StyledActionWrapper,
    StyledCloseIcon,
    StyledCloseText,
    StyledLinkWrapper,
    StyledWrapper,
} from '../burger-menu-desktop/burger-menu-desktop.styled';
import { ContactSection as ContactSectionComponent } from '../contact-section/contact-section.component';
import { MenuContext } from '../mega-menu.component';
import { BurgerMenuMobileLink } from './burger-menu-mobile-link/burger-menu-mobile-link.component';

type IProps = {
    closeAction: () => void;
    menuContext: MenuContext;
    updateMenuContext: (newContext: MenuContext) => void;
    links: TopLevelLink[];
    contactSection: ContactSection;
    pageId: string;
    showPrivateErhvervToggle: boolean;
};

export const BurgerMenuMobile: FC<IProps> = ({
    closeAction,
    menuContext,
    updateMenuContext,
    links,
    contactSection,
    pageId,
    showPrivateErhvervToggle,
}) => {
    const contextToggle = (newContext: string) => {
        if (newContext === 'privat') updateMenuContext('privat');
        if (newContext === 'erhverv') updateMenuContext('erhverv');
        closeAction();
    };
    return (
        <StyledWrapper>
            <StyledActionWrapper>
                {showPrivateErhvervToggle ? (
                    <ToggleUi
                        options={[
                            {
                                label: 'Privat',
                                value: 'privat',
                            },
                            {
                                label: 'Erhverv',
                                value: 'erhverv',
                            },
                        ]}
                        selectedOption={menuContext.toString()}
                        onChange={(newState) => contextToggle(newState)}
                    />
                ) : (
                    <div />
                )}
                <StyledCloseIcon onClick={() => closeAction()}>
                    <StyledCloseText>Luk</StyledCloseText>
                    <SvgIcon iconName="close" />
                </StyledCloseIcon>
            </StyledActionWrapper>
            <StyledLinkWrapper>
                {links.map((x, idx) => {
                    return <BurgerMenuMobileLink key={idx} link={x} closeAction={() => closeAction()} />;
                })}
            </StyledLinkWrapper>
            <ContactSectionComponent contactSection={contactSection} pageId={pageId} closeAction={() => closeAction()} />
        </StyledWrapper>
    );
};
