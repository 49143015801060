import { useMemo, VFC } from 'react';
import { contentSpotTypes, umbraco } from '../../../lib/api';
import { Filters, StyledCarFinderButton, StyledPriceFilterDropdown } from './car-finder-filters.styled';
import { ComboBox } from '../../forms/inputs/combos/combo-box';
import { FilterUpdateNotification } from '../../../lib/api/models/hessel-api/hire';

import { ToggleUi } from '../../shared/toggle-ui';
import {
    getActiveFiltersQueryParams,
    getActiveFiltersUrl,
    getFacetInputTexts,
    mapAvailabilityFacetToToggleOptions,
} from '../../../utils/helpers/productlist.helpers';
import { useCarFinderFilter } from '../hooks/use-car-finder-filter';
import { ToggleOptionType } from '../../shared/toggle-ui/toggle-ui.component';
import { useRouter } from 'next/router';
import { cmsUrlWithoutSiteId } from '../../../utils/helpers';
import { Abbreviations } from '../../../constants/units-and-abbreviations';
import {
    DefaultBaseFilterSetup,
    Applicable_Facet_Keys,
    Car_Finder_Spots_Filter_Limit,
    OrderAvailabilityKey,
    InStockAvailabilityKey,
    HirePriceFilterKey,
} from '../../../constants/site-consts';

type CarFinderFiltersProps = {
    hero: contentSpotTypes.HeroCarFinder;
    columnSpan: number;
    globalPlpSettings: umbraco.GlobalProductListSettings;
};

export const CarFinderFilters: VFC<CarFinderFiltersProps> = ({ hero, columnSpan, globalPlpSettings }) => {
    const baseFilter = useMemo(
        () => (hero.baseFilterSetup && hero.baseFilterSetup.length > 0 ? hero.baseFilterSetup[0] : DefaultBaseFilterSetup),
        [hero.baseFilterSetup]
    );
    const { availabilityFacet, filterUpdate, facets, activeFilters, productListData } = useCarFinderFilter(
        globalPlpSettings,
        hero.filterConfiguration.key,
        true,
        baseFilter
    );

    const restrictedFacets = useMemo(() => {
        return facets?.filter((x) => Applicable_Facet_Keys.includes(x.key)).filter((_, index) => index < Car_Finder_Spots_Filter_Limit);
    }, [facets]);

    const router = useRouter();

    const toggleOptions: ToggleOptionType[] = useMemo(() => {
        if (availabilityFacet) {
            const mappedAvailabilityLabels = mapAvailabilityFacetToToggleOptions(availabilityFacet, globalPlpSettings.availabilityLabels);

            return [
                ...availabilityFacet?.facetOptions.map((x) => {
                    let label = x.key;
                    const match = mappedAvailabilityLabels.find((y) => y.value.toLowerCase().includes(x.key.toLowerCase()));
                    if (x.key === OrderAvailabilityKey) {
                        label = match?.label ?? 'Bestillingsbiler';
                    } else if (x.key === InStockAvailabilityKey) {
                        label = match?.label ?? 'Nye biler på lager';
                    }
                    return {
                        label,
                        value: x.filterQueryValue,
                    };
                }),
            ];
        }
        return [];
    }, [availabilityFacet, globalPlpSettings.availabilityLabels]);

    const selectedAvailability = useMemo(() => {
        const selected = availabilityFacet?.facetOptions.find((x) => x);
        if (selected) return selected.filterQueryValue;
        return '';
    }, [availabilityFacet?.facetOptions]);

    return (
        <>
            {availabilityFacet ? (
                <ToggleUi
                    options={toggleOptions}
                    onChange={(filterUpdateNotification) => filterUpdate([{ query: filterUpdateNotification, action: 'Replace' }])}
                    selectedOption={selectedAvailability}
                />
            ) : null}

            <Filters colSpan={columnSpan}>
                {restrictedFacets?.map((currentFacet) => {
                    const { facetLabel, facetPlaceholder } = getFacetInputTexts(hero.filterGroupTextConfiguration, currentFacet);

                    if (currentFacet.key === HirePriceFilterKey) {
                        const options = currentFacet.facetOptions
                            .filter((x) => x.count > 0)
                            .map((x) => ({ displayValue: `${x.key} ${Abbreviations.KR_SLASH_MD}`, value: x.key }));

                        const selectedPrice = currentFacet.facetOptions.find((x) => x.selected);

                        const selected = selectedPrice
                            ? {
                                  displayValue: `${selectedPrice.key} ${Abbreviations.KR_SLASH_MD}`,
                                  value: selectedPrice.key,
                              }
                            : undefined;

                        return (
                            <StyledPriceFilterDropdown
                                options={options.filter((_, idx) => idx !== 0)}
                                value={selected}
                                id={currentFacet.key}
                                label={facetLabel.toUpperCase()}
                                placeholder={facetPlaceholder}
                                disabled={options.length === 0}
                                onChange={(option) => {
                                    const selectedOp = currentFacet.facetOptions.find((x) => x.key === option?.value);
                                    if (selectedOp) {
                                        const maxPrice = selectedOp.filterQueryValue;
                                        if (maxPrice) {
                                            filterUpdate([{ query: `${currentFacet.key}__0-${maxPrice}`, action: 'Replace' }]);
                                        }
                                    }
                                }}
                                isValid={true}
                                canValidateInputField={false}
                                onInputBlur={() => null}
                                key={currentFacet.key}
                            />
                        );
                    }

                    const currentCheckboxes: Array<{
                        id: string;
                        type: 'Item';
                        value: string;
                        isSelected: boolean;
                    }> =
                        currentFacet?.facetOptions.map((x) => {
                            return {
                                id: x.key,
                                type: 'Item',
                                value: `${x.key} (${x.count})`,
                                isSelected: x.selected,
                                disabled: x.count === 0,
                            };
                        }) ?? [];
                    return (
                        <ComboBox
                            id={currentFacet.key}
                            key={currentFacet.key}
                            isValid={true}
                            items={currentCheckboxes}
                            label={facetLabel.toUpperCase()}
                            placeholder={facetPlaceholder}
                            onComboBoxItemSelected={(checked, item) => {
                                const option = currentFacet.facetOptions.find((x) => x.key === item?.id);

                                if (!option) {
                                    return;
                                }

                                if (checked) {
                                    //When checking a facet that is a subfacet we must unselect the parent facet, since we only send the "deepest" facet to the BE
                                    filterUpdate([{ query: option.filterQueryValue, action: 'Add' }]);
                                } else {
                                    //When we uncheck a facet, if it is a subfacet, we have to reselect it's parent facet
                                    filterUpdate([{ query: option.filterQueryValue, action: 'Remove' }, {} as FilterUpdateNotification]);
                                }
                            }}
                            hideValidation={true}
                        />
                    );
                })}

                <StyledCarFinderButton
                    onClick={() => {
                        if (hero.linkToPlp) {
                            let finishedUrl = '/';
                            const destinationUrl = cmsUrlWithoutSiteId(hero.linkToPlp.url);
                            if (destinationUrl.indexOf('#') > -1) {
                                const [url, anchor] = destinationUrl.split('#');
                                finishedUrl = `${url}${getActiveFiltersQueryParams(activeFilters)}#${anchor}`;
                            } else {
                                finishedUrl = `${finishedUrl}${destinationUrl}${getActiveFiltersQueryParams(activeFilters)}`;
                            }
                            router.push(finishedUrl);
                        } else {
                            router.push(getActiveFiltersUrl(activeFilters));
                        }
                    }}
                    variant="primary"
                    type="button"
                >
                    <span style={{ whiteSpace: 'nowrap' }}>
                        {hero.submitButtonText} (+{productListData?.total})
                    </span>
                </StyledCarFinderButton>
            </Filters>
        </>
    );
};
