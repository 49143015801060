import styled from 'styled-components';

export const FilterHeader = styled.p`
    font-size: 12px;
    font-weight: 500;
`;

export const InputWrapper = styled.div`
    padding-top: 20px;
`;

export const OptionWrapper = styled.div`
    &:not(:last-of-type) {
        margin-bottom: 20px;
    }
`;

export const FilterWrapper = styled.div`
    padding: 15px 0 10px;
    border-bottom: 1px solid ${({ theme: { palette } }) => palette.grey.medium};
`;

export const DropdownWrapper = styled.div``;
