import styled from 'styled-components';
import { contentSpotTypes } from '../../../lib/api';
import { device } from '../../../lib/media-query';
import { SpotHeader } from '../../shared/typography/spot-header/spot-header.component';

const getContentPosition = (position: contentSpotTypes.Position): 'flex-start' | 'center' | 'flex-end' | 'unset' => {
    switch (position) {
        case 'Left':
            return 'flex-start';

        case 'Center':
            return 'center';

        case 'Right':
            return 'flex-end';

        case '':
            return 'unset';

        default:
            return 'unset';
    }
};

const getHeaderAlignment = (position: contentSpotTypes.Position): 'left' | 'center' | 'right' | 'unset' => {
    switch (position) {
        case 'Left':
            return 'left';

        case 'Center':
            return 'center';

        case 'Right':
            return 'right';

        default:
            return 'left';
    }
};

const getVerticalAlignment = (position: contentSpotTypes.VerticalAlignment): string => {
    switch (position) {
        case 'Top':
            return 'flex-start';
        case 'Center':
            return 'center';
        case 'Bottom':
            return 'flex-end';
        case '':
        default:
            return 'center';
    }
};

export const CampaignContent = styled.div<{ position: contentSpotTypes.Position }>`
    display: flex;
    flex-direction: column;
    width: clamp(300px, 70%, 500px);
    align-items: center;
    justify-content: center;

    @media ${device.tablet} {
        padding-left: 2rem;
        padding-right: 2rem;
        align-self: ${(props) => getContentPosition(props.position)};
    }
    align-items: ${(props) => getContentPosition(props.position)};
`;

export const CampaignHeader = styled.h2<{ columnSpan?: number; position: contentSpotTypes.Position; textColor: string }>`
    color: ${(props) => props.textColor};
    font-size: 24px;
    margin-bottom: 2rem;
    font-family: 'Corporate E';

    text-align: ${(props) => getHeaderAlignment(props.position)};
`;

export const StyledSpotHeader = styled(SpotHeader)<{ columnSpan?: number; position: contentSpotTypes.Position; textColor: string }>`
    color: ${(props) => props.textColor};
    margin-bottom: 2rem;
    text-align: ${(props) => getHeaderAlignment(props.position)};
`;

export const CtaListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media ${device.tablet} {
        flex-direction: row;
        flex-wrap: wrap;
    }
`;

export const CampaignContentWrapper = styled.div<{
    backgroundColor?: string;
    verticalAlignment: contentSpotTypes.VerticalAlignment;
    horizontalAlignment: contentSpotTypes.Position;
    showCursor: boolean;
}>`
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: ${(props) => (props.verticalAlignment === 'Center' && props.horizontalAlignment === 'Center' ? 'center' : 'flex-start')};

    height: 100%;
    width: 100%;

    background-color: ${(props) => (props.backgroundColor ? '#' + props.backgroundColor : 'rgba(0, 0, 0, 0.7)')};

    cursor: ${(props) => (props.showCursor ? 'pointer' : 'default')};

    & * {
        z-index: 20;
    }

    padding: 40px 20px;

    @media ${device.tablet} {
        justify-content: ${(props) => getVerticalAlignment(props.verticalAlignment)};
        padding: 80px 40px;
    }
`;
