import { useMemo } from 'react';

type UseMegaMenuReturnType = {
    homeUrl: string;
};

export const useMegaMenu = (menuContext: string): UseMegaMenuReturnType => {
    const homeUrl = useMemo(() => {
        if (menuContext === 'erhverv') return '/erhverv';
        return '/';
    }, [menuContext]);

    return {
        homeUrl,
    };
};
