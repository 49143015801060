import { action } from 'easy-peasy';
import { createContextStoreWithRuntimeModel } from '../../helpers/context-store.helpers';
import {
    EquipmentDialogManagerActions,
    EquipmentDialogManagerInjections,
    EquipmentDialogManagerState,
    EquipmentDialogManagerStoreModel,
} from './equipment-dialog-manager.types';

const equipmentDialogManagerDefaultState = (): EquipmentDialogManagerState => ({
    showAllEquipment: false,
    selectedEquipment: undefined,
    selectedOptionalEquipment: undefined,
    selectedEquipmentPackage: undefined,
});

const equipmentDialogManagerActions = (): EquipmentDialogManagerActions => ({
    setShowAllEquipment: action((state, payload) => {
        state.showAllEquipment = payload;
    }),
    setSelectedEquipment: action((state, payload) => {
        state.selectedEquipment = payload;
    }),
    setSelectedOptionalEquipment: action((state, payload) => {
        state.selectedOptionalEquipment = payload;
    }),
    setSelectedEquipmentPackage: action((state, payload) => {
        state.selectedEquipmentPackage = payload;
    }),
    resetStore: action((state) => {
        state.showAllEquipment = false;
        state.selectedEquipment = undefined;
        state.selectedOptionalEquipment = undefined;
        state.selectedEquipmentPackage = undefined;
    }),
});

export const EquipmentDialogManagerStore = createContextStoreWithRuntimeModel<
    EquipmentDialogManagerStoreModel,
    Partial<EquipmentDialogManagerState>,
    EquipmentDialogManagerInjections
>(
    () => ({
        ...equipmentDialogManagerDefaultState(),
        ...equipmentDialogManagerActions(),
    }),
    { name: 'equipmentDialogManagerStore' }
);
