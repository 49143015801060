import { action, computed } from 'easy-peasy';
import { SavingsForm } from '.';
import { formatPrice } from '../../../../utils/helpers';
import { createContextStoreWithRuntimeModel } from '../../helpers/context-store.helpers';
import { SavingsActions, SavingsInjections, SavingsState, SavingsStore } from './savings.types';

function getNonMemberSum(savings: Array<SavingsForm>) {
    let sum = 0;
    savings.forEach((saving) => (sum += saving.nonMemberPrice));
    return sum;
}

function getMembershipSum(savings: Array<SavingsForm>) {
    let sum = 0;
    savings.forEach((saving) => (sum += saving.memberPrice));
    return sum;
}

function getCompleteMemberSum(savings: Array<SavingsForm>) {
    let sum = 0;
    savings.forEach((saving) => (sum += saving.memberCompletePrice));
    return sum;
}

const savingsDefaultState = (runtimeModel: Partial<SavingsState> | undefined): SavingsState => ({
    savings: runtimeModel?.savings ?? [],
    yearlyMembership: runtimeModel?.yearlyMembership,
    yearlyCompleteMembership: runtimeModel?.yearlyCompleteMembership,
    savingsResult: computed((state) => {
        const savings = state.savings;

        savings.forEach((saving) => {
            const usersInput = saving.value;
            const factors = saving.factors?.map((x) => x.value);

            let factorsSum = 0;
            if (factors && factors.length > 0) {
                factorsSum = factors.reduce((acc, val) => acc * val);
            }

            if (saving.alias === 'multipleChoicesSaving') {
                saving.nonMemberPrice = usersInput;
                saving.memberPrice = saving.gratisAsMember ? 0 : usersInput;
                saving.memberCompletePrice = saving.gratisAsCompleteMember ? 0 : usersInput;
            } else if (saving.alias === 'transportAndGasSaving') {
                saving.nonMemberPrice = 0;
                saving.memberPrice = saving.gratisAsMember ? 0 : -(usersInput / saving.kilometerPrLiter) * saving.savingPrLiter;
                saving.memberCompletePrice = saving.gratisAsCompleteMember ? 0 : -(usersInput / saving.kilometerPrLiter) * saving.savingPrLiter;
            } else if (saving.alias === 'carwashSaving') {
                if (factorsSum !== 0) {
                    const baseCalculation = usersInput * factorsSum;

                    saving.nonMemberPrice = saving.applyCalculationsToPrice ? baseCalculation : usersInput;

                    const memberHasToPay = saving.gratisAsMember ? 0 : saving.applyCalculationToMemberPrice ? usersInput * factorsSum : usersInput;
                    saving.memberPrice = memberHasToPay < saving.freeWashedAsMember * factorsSum ? 0 : memberHasToPay - usersInput * factorsSum;

                    const completeMemberHasToPay = saving.gratisAsCompleteMember
                        ? 0
                        : saving.applyCalculationToCompletePrice
                        ? usersInput * factorsSum
                        : usersInput;
                    saving.memberCompletePrice =
                        completeMemberHasToPay < saving.freeWashedAsCompleteMember * factorsSum
                            ? 0
                            : completeMemberHasToPay - saving.freeWashedAsCompleteMember * factorsSum;
                }
            } else {
                switch (saving.operation) {
                    case 'Addition': {
                        const sumValue = usersInput + factorsSum;
                        saving.nonMemberPrice = sumValue;
                        saving.memberPrice = saving.gratisAsMember ? 0 : usersInput;
                        saving.memberCompletePrice = saving.gratisAsCompleteMember ? 0 : usersInput;
                        break;
                    }
                    case 'Division': {
                        if (factorsSum !== 0) {
                            const baseCalculation = usersInput / factorsSum;
                            saving.nonMemberPrice = saving.applyCalculationsToPrice ? baseCalculation : usersInput;
                            saving.memberPrice = saving.gratisAsMember ? 0 : usersInput;
                        }
                        break;
                    }
                    case 'Multiplication': {
                        if (factorsSum !== 0) {
                            const baseCalculation = usersInput * factorsSum;

                            saving.nonMemberPrice = saving.applyCalculationsToPrice ? baseCalculation : usersInput;

                            saving.memberPrice = saving.gratisAsMember
                                ? 0
                                : saving.applyCalculationToMemberPrice
                                ? usersInput * (1 - factorsSum)
                                : usersInput;

                            saving.memberCompletePrice = saving.gratisAsCompleteMember
                                ? 0
                                : saving.applyCalculationToCompletePrice
                                ? usersInput * (1 - factorsSum)
                                : usersInput;
                        }
                        break;
                    }
                    case 'Subtraction': {
                        const baseCalculation = usersInput - factorsSum;

                        saving.nonMemberPrice = saving.applyCalculationsToPrice ? baseCalculation : usersInput;
                        saving.memberPrice = saving.gratisAsMember ? 0 : usersInput;
                        break;
                    }
                    default:
                        throw new Error(`Invalid Saving Operation`);
                }
            }
        });

        const memberSum = getMembershipSum(savings);
        const nonMemberSum = getNonMemberSum(savings);
        const completeMemberSum = getCompleteMemberSum(savings);

        const normalSaving = nonMemberSum - (memberSum + (state.yearlyMembership ?? 0));
        const completeSaving = state.yearlyCompleteMembership ? nonMemberSum - (completeMemberSum + state.yearlyCompleteMembership) : undefined;

        return {
            normalPackage: formatPrice(Math.round(normalSaving)),
            completePackage: completeSaving ? formatPrice(Math.round(completeSaving)) : undefined,
            membershipSum: memberSum,
            noneMembershipSum: nonMemberSum,
            completeMembershipSum: completeMemberSum,
        };
    }),
});

const savingsActions = (): SavingsActions => ({
    onChange: action((state, payload) => {
        const saving = state.savings.find((x) => x.id === payload.id);

        if (saving) {
            saving.value = payload.value;
        }
    }),
});

export const CustomerSavingsStore = createContextStoreWithRuntimeModel<SavingsStore, Partial<SavingsState>, SavingsInjections>(
    (runtimeModel) => ({
        ...savingsDefaultState(runtimeModel),
        ...savingsActions(),
    }),
    { name: 'customerSavingsStore' }
);
