import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '../button';
import { StyledCollapsibleButtonText, StyledCollapsibleContainer } from './collapsible.styled';

type CollapsibleProps = {
    /** collapsedHeigh is height in px */
    collapsedHeight?: number;
    readMoreBtnText?: string;
    readLessBtnText?: string;
};

const Collapsible: FC<React.PropsWithChildren<CollapsibleProps>> = memo(
    ({ collapsedHeight = 250, children, readLessBtnText = 'Læs mindre', readMoreBtnText = 'Læs mere' }) => {
        const contentRef = useRef<HTMLDivElement>(null);
        const [fullHeight, setFullHeight] = useState(collapsedHeight);
        const [isExpanded, setIsExpanded] = useState(false);
        const [isCollapsible, setIsCollapsible] = useState(false);

        useEffect(() => {
            if (contentRef.current && fullHeight < contentRef.current?.clientHeight) {
                setIsCollapsible(true);
                setFullHeight(contentRef.current?.clientHeight);
            } else if (contentRef.current && fullHeight !== contentRef.current?.clientHeight) {
                setIsCollapsible(false);
                setFullHeight(collapsedHeight);
                setIsExpanded(false);
            }
        }, [children, collapsedHeight, fullHeight]);

        const btnText = useMemo(() => {
            return isExpanded ? readLessBtnText : readMoreBtnText;
        }, [isExpanded, readLessBtnText, readMoreBtnText]);

        return (
            <>
                <StyledCollapsibleContainer
                    collapsedHeight={collapsedHeight}
                    isCollapsible={isCollapsible}
                    isExpanded={isExpanded}
                    contentHeight={fullHeight}
                >
                    <div ref={contentRef}>{children}</div>
                </StyledCollapsibleContainer>
                {isCollapsible && (
                    <Button variant="link" onClick={() => setIsExpanded(!isExpanded)}>
                        <StyledCollapsibleButtonText>{btnText}</StyledCollapsibleButtonText>
                    </Button>
                )}
            </>
        );
    }
);

export default Collapsible;
