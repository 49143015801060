import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const Wrapper = styled.div<{ overridePadding: boolean }>`
    background-color: transparent;
    padding: ${(props) => (props.overridePadding ? '0' : '10px')};
`;

export const StyledLabel = styled.label`
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
    overflow-wrap: break-word;
    max-width: 300px;

    @media ${device.tablet} {
        max-width: 300px;
    }
`;

export const StyledText = styled.p<{ overrideMaxWidth: boolean }>`
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;
    width: max-content;
    overflow-wrap: break-word;
    max-width: ${(props) => (props.overrideMaxWidth ? 'none' : '100px')};

    @media ${device.tablet} {
        max-width: 300px;
    }
`;
