import styled from 'styled-components';
import { device } from '../../../../../lib/media-query';

export const StyledDeliveryAddressHeader = styled.p`
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
`;

export const StyledCustomerInfo = styled.p`
    font-size: 12px;
    line-height: 17px;
`;

export const StyledAlternativeDeliveryArea = styled.div`
    margin-top: 20px;
`;

export const StyledAlternativeDeliveryFormArea = styled.div`
    max-width: 340px;
    margin-top: 20px;
`;

export const StyledAlternativeDeliveryForm = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const StyledZipCityWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media ${device.tablet} {
        display: grid;
        grid-template-columns: 134fr 191fr;
        gap: 20px;
    }
`;
