import styled from 'styled-components';
import { GenericCircle } from '../../../../shared/shapes/generic-circle';

export const BorderBox = styled.div`
    display: grid;
    grid-template-columns: minmax(90px, 0.35fr) 1fr;
    grid-column-gap: 1rem;
    align-items: center;
    padding: 15px 0;
`;

export const ImageWrapper = styled.div``;

export const StyledImage = styled.img`
    display: block;
    max-inline-size: 100%;
    block-size: auto;
    object-fit: cover;
    object-position: center;
`;

export const TextsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const CarName = styled.p`
    color: #0b0b0b;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
`;

export const CarModel = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
`;

export const CarColor = styled.p`
    color: #807f80;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
`;

export const ColorTextAndImageMobile = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

export const StyledSummaryColorCircleMobile = styled(GenericCircle)<{ imageUrl?: string; backgroundColor?: string }>`
    width: 12px;
    height: 12px;
    background-image: ${(props) => (props.imageUrl ? `url('${props.imageUrl}')` : 'none')};
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    flex-shrink: 0;
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'unset')};
`;
