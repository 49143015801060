import { ComboBoxItem } from '../../components/forms/inputs/combos/combo-box/combo-box.props';
import { CarColor } from '../../lib/api/models/hessel-api/hire';
import { Condition, FormsInput, InputTypes, sharedTypes, SpecialFormProps, UmbracoForm } from '../../lib/api/models/umbraco';
import { hesselViewModels } from '../../lib/view-models';
import { ProductDetails } from '../../lib/view-models/vehicle';

export const getFieldTypeAlias = (fieldTypeId: string): InputTypes | null => {
    switch (fieldTypeId) {
        case '3f92e01b-29e2-4a30-bf33-9df5580ed52c':
            return 'shortAnswer';
        case '023f09ac-1445-4bcb-b8fa-ab49f33bd046':
            return 'longAnswer';
        case 'f8b4c3b8-af28-11de-9dd8-ef5956d89593':
            return 'date';
        case 'd5c0c390-ae9a-11de-a69e-666455d89593':
            return 'checkbox';
        case '84a17cf8-b711-46a6-9840-0e4a072ad000':
            return 'fileUpload';
        case 'fb37bc60-d41e-11de-aeae-37c155d89593':
            return 'password';
        case 'fab43f20-a6bf-11de-a28f-9b5755d89593':
            return 'multipleChoice';
        case 'a72c9df9-3847-47cf-afb8-b86773fd12cd':
            return 'dataConsent';
        case '0dd29d42-a6a5-11de-a2f2-222256d89593':
            return 'dropdown';
        case '903df9b0-a78c-11de-9fc1-db7a56d89593':
            return 'singleChoice';
        case 'e3fbf6c4-f46c-495e-aff8-4b3c227b4a98':
            return 'titleAndDescription';
        case 'da206cae-1c52-434e-b21a-4a7c198af877':
            return 'hidden';
        case '7cc6ef35-0533-4ba7-8708-62e173ee87ed':
            return 'advancedDatepicker';
        default:
            return null;
    }
};

export const evaluateFormsCondition = (condition: Condition, allInputs: FormsInput[]): boolean => {
    let anyFailedTests = false;
    let anyCompletedTests = false;
    if (condition === null || !condition.enabled || condition.rules.length === 0) {
        return true;
    }
    condition.rules.forEach((r) => {
        const inputToMatch = allInputs.find((x) => x.id === r.field);
        if (!inputToMatch) {
            return true;
        }
        let nullCheckedStringValue = inputToMatch?.value === undefined ? '' : inputToMatch.value;
        switch (r.operator) {
            case 'Is':
                if (getFieldTypeAlias(inputToMatch.fieldTypeId) === 'checkbox' && nullCheckedStringValue.length === 0) {
                    nullCheckedStringValue = 'false';
                }
                if (r.value === nullCheckedStringValue) {
                    anyCompletedTests = true;
                } else {
                    anyFailedTests = true;
                }
                break;
            case 'IsNot':
                if (getFieldTypeAlias(inputToMatch.fieldTypeId) === 'checkbox' && nullCheckedStringValue.length === 0) {
                    nullCheckedStringValue = 'false';
                }
                if (r.value !== nullCheckedStringValue) {
                    anyCompletedTests = true;
                } else {
                    anyFailedTests = true;
                }
                break;
            case 'LessThen':
                if (isNaN(Number(r.value)) || isNaN(Number(inputToMatch.value))) {
                    anyCompletedTests = true;
                } else {
                    if (Number(r.value) < Number(inputToMatch.value)) {
                        anyCompletedTests = true;
                    } else {
                        anyFailedTests = true;
                    }
                }
                break;
            case 'GreaterThen':
                if (isNaN(Number(r.value)) || isNaN(Number(inputToMatch.value))) {
                    anyCompletedTests = true;
                } else {
                    if (Number(r.value) > Number(inputToMatch.value)) {
                        anyCompletedTests = true;
                    } else {
                        anyFailedTests = true;
                    }
                }
                break;
            case 'StartsWith':
                if (inputToMatch.value?.startsWith(r.value)) {
                    anyCompletedTests = true;
                } else {
                    anyFailedTests = true;
                }
                break;
            case 'EndsWith':
                if (inputToMatch.value?.endsWith(r.value)) {
                    anyCompletedTests = true;
                } else {
                    anyFailedTests = true;
                }
                break;
            case 'Contains':
                if (inputToMatch.value) {
                    if (inputToMatch.value?.indexOf(r.value) > -1) {
                        anyCompletedTests = true;
                    } else {
                        anyFailedTests = true;
                    }
                } else {
                    anyFailedTests = true;
                }
                break;
        }
    });
    let inputShouldShow = false;
    if (condition.logicType === 'All') {
        inputShouldShow = anyCompletedTests && !anyFailedTests;
    } else {
        inputShouldShow = anyCompletedTests;
    }
    if (condition.actionType === 'Hide') {
        inputShouldShow = !inputShouldShow;
    }
    return inputShouldShow;
};

export const validateInput = (input: FormsInput): boolean => {
    if (getFieldTypeAlias(input.fieldTypeId) === 'date' || getFieldTypeAlias(input.fieldTypeId) === 'advancedDatepicker') {
        return true;
    }
    if (input.mandatory) {
        const notEmpty = !!input.value && input.value !== '';
        const patternMatches =
            input.regex === undefined ||
            input.regex === null ||
            input.regex === '' ||
            (notEmpty && !!input.regex && new RegExp(input.regex).test(input.value));
        return notEmpty && patternMatches;
    }

    if (input.regex) {
        return new RegExp(input.regex).test(input.value);
    }

    return true;
};

export const isUmbracoFormType = (form: sharedTypes.CtaWithAction): boolean => {
    if ((form.formToOpen as UmbracoForm).id) {
        return true;
    } else {
        return false;
    }
};

export const getCustomHiddenInputFieldValue = (input: FormsInput, specialProps?: SpecialFormProps): string => {
    if (specialProps) {
        if (input.alias === 'bundleId') {
            return specialProps.bundleId ?? '';
        }
        if (input.alias === 'dealershipId') {
            return specialProps.dealershipId ?? '';
        }
        if (input.alias === 'enquiryCarId') {
            return specialProps.enquiryCarId ?? '';
        }
        if (input.alias === 'configurations') {
            return specialProps.configurations ?? '';
        }
    }
    if (input.alias === 'url') {
        return window.location.href;
    }
    return input.value;
};

export const getDefaultValue = (input: FormsInput): string => {
    if (getFieldTypeAlias(input.fieldTypeId) === 'checkbox') {
        if (input.value) return input.value;
        if (!input.settings.defaultValue || input.settings.defaultValue.length === 0) return 'false';
        return 'true';
    } else {
        return input.value ? input.value : '';
    }
};

export const getFormConfigurations = (selectedTab: hesselViewModels.OwnershipTab, product?: ProductDetails, color?: CarColor): string => {
    if (!product) {
        return '';
    }

    const entries: string[] = (color ? [color.name] : [])
        .concat(product.extraEquipment[selectedTab].filter((ee) => ee.isSelected).map((ee) => ee.name) || [])
        .concat(product.extraEquipmentPackages[selectedTab].filter((ee) => ee.isSelected).map((ee) => ee.name || '') || [])
        .concat(product.optionalEquipment[selectedTab].filter((ee) => ee.isSelected).map((ee) => ee.name) || []);

    return entries.join(', ');
};

export const loadComboBoxItems = (input: FormsInput): Array<ComboBoxItem> => {
    const result: ComboBoxItem[] =
        input.preValues.map((option) => {
            return {
                id: option.value,
                type: 'Item',
                value: option.value,
                isSelected: option.isSelected,
                disabled: false,
            };
        }) ?? [];

    return result;
};
