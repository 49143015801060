import { VFC } from 'react';
import { BlackPinUrl, BluePinUrl } from '../../helpers/organization-consts';
import { PinWithLetterWrapper, StyledDealershipPin, StyledLetter } from './pin-with-letter.styled';

type PinWithLetterProps = {
    selected: boolean;
    letter?: string;
};

export const PinWithLetter: VFC<PinWithLetterProps> = ({ selected, letter }) => {
    return (
        <PinWithLetterWrapper>
            <StyledDealershipPin src={selected ? `/${BluePinUrl}` : `/${BlackPinUrl}`}></StyledDealershipPin>
            {letter ? <StyledLetter>{letter}</StyledLetter> : null}
        </PinWithLetterWrapper>
    );
};
