import styled from 'styled-components';

export const StyledUsp = styled.div`
    display: flex;
    align-items: center;

    svg path {
        transform: translate(0.5px, 0.5px);
    }
`;

export const StyledText = styled.p`
    margin-left: 20px;
    line-height: 15px;
`;
