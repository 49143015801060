import styled from 'styled-components';

export const StyledCollapsibleContainer = styled.section<{
    isExpanded: boolean;
    collapsedHeight: number;
    contentHeight: number;
    isCollapsible: boolean;
}>(({ isExpanded, contentHeight, collapsedHeight, isCollapsible }) => ({
    overflow: 'hidden',
    height: isCollapsible ? (isExpanded ? `${contentHeight}px` : `${collapsedHeight}px`) : 'auto',
    transition: 'height 300ms ease-in-out',
    position: 'relative',

    ':after': {
        content: isCollapsible && !isExpanded ? '""' : 'none',
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: '50%',
        backgroundImage: 'linear-gradient( rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 100% )',
        pointerEvents: 'none',
    },
}));

export const StyledCollapsibleButtonText = styled.span`
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: 14px;
    letter-spacing: 0;
    white-space: nowrap;
`;
