import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const Wrapper = styled.div`
    @media ${device.tablet} {
        width: 50%;
    }
`;

export const TermsModalContent = styled.p`
    padding: 0 30px;
    margin-top: 20px;
`;
