import { useEffect, useState, VFC } from 'react';
import { config, useSpring } from 'react-spring';
import { useMeasure } from 'react-use';
import { Borders, ToggleIndicator } from '../../../lib/ui-types';
import { SvgIcon } from '../svg-icon';
import {
    StyledAccordion,
    StyledHeader,
    AnimatedWrapper,
    Header,
    PlusIndicator,
    PlusWrapper,
    StyledContent,
    StyledIcon,
    StyledWrapper,
} from './simple-accordion.styled';

export type SimpleAccordionProps = {
    className?: string;
    title: string;
    children: React.ReactNode;
    onClick: (args: number) => void;
    isOpen: boolean;
    index: number;
    textColor?: string;
    backgroundColor?: string;
    headerSize?: `${number}px` | `${number}rem`;
    border?: Borders;
    contentPadding?: `${number}px` | `${number}rem` | `${number}px ${number}px` | `${number}rem ${number}rem`;
    customTitle?: React.ReactNode;
    toggleIndicator?: ToggleIndicator;
    borderColor?: string;
    iconColor?: 'primary' | 'secondary';
    disableAccordion?: boolean;
};

export const SimpleAccordion: VFC<SimpleAccordionProps> = ({
    className,
    title,
    children,
    onClick: handleClick,
    isOpen,
    index,
    textColor,
    backgroundColor,
    headerSize,
    border = 'top and bottom',
    contentPadding = '0',
    customTitle,
    toggleIndicator = 'chevron',
    borderColor = 'E2E2E4',
    iconColor = 'primary',
    disableAccordion = false,
}) => {
    const defaultHeight: string | undefined = '0px';

    // Gets the height of the element (ref)
    const [heightRef, { height }] = useMeasure();

    const [contentHeight, setContentHeight] = useState(defaultHeight || height);

    useEffect(() => {
        //Sets initial height
        setContentHeight(height);
    }, [height]);

    // Animations
    const expand = useSpring({
        config: { ...config.slow, friction: 50 },
        height: isOpen ? `${contentHeight}px` : defaultHeight,
        overflow: !isOpen ? 'hidden' : '',
    });

    const fadeContent = useSpring({
        config: { ...config.slow, friction: 50 },
        opacity: isOpen ? 1 : 0,
        delay: 300,
    });

    const spin = useSpring({
        transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    });

    const turn = useSpring({
        config: {
            ...config.stiff,
            duration: 20,
        },
        transform: isOpen ? 'rotate(0deg)' : 'rotate(90deg)',
    });

    return (
        <StyledAccordion
            className={className}
            border={border}
            background={backgroundColor ?? ''}
            borderColor={borderColor}
            contentPadding={contentPadding}
        >
            <StyledHeader isDisabled={disableAccordion} onClick={() => handleClick(index)}>
                {customTitle ? (
                    customTitle
                ) : (
                    <Header size={headerSize ?? '0.875rem'} textColor={textColor ?? ''}>
                        {title}
                    </Header>
                )}

                {!disableAccordion && toggleIndicator === 'chevron' ? (
                    <StyledIcon style={spin}>
                        <SvgIcon iconName="chevron/down" color={iconColor === 'primary' ? 'primary' : 'light'} />
                    </StyledIcon>
                ) : null}

                {!disableAccordion && toggleIndicator === 'plus' ? (
                    <PlusWrapper>
                        <PlusIndicator style={turn}></PlusIndicator>
                        <PlusIndicator></PlusIndicator>
                    </PlusWrapper>
                ) : null}
            </StyledHeader>
            <AnimatedWrapper style={expand}>
                <StyledContent ref={heightRef as any} textColor={textColor ?? ''}>
                    <StyledWrapper style={fadeContent}>{children}</StyledWrapper>
                </StyledContent>
            </AnimatedWrapper>
        </StyledAccordion>
    );
};
