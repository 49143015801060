import { VFC } from 'react';
import { getCmsImageUrl } from '../../../../../utils/helpers';
import { StyledKeyValueTableCell, StyledKeyValueTableIcon, StyledKeyValueTableRow } from './key-value-table-row.styled';

export type KeyValueTableRowProps = {
    className?: string;
    label: string;
    iconUrl?: string;
    value?: string;
};

export const KeyValueTableRow: VFC<KeyValueTableRowProps> = (props) => {
    return (
        <StyledKeyValueTableRow className={props.className}>
            <StyledKeyValueTableCell>{props.label}</StyledKeyValueTableCell>
            <StyledKeyValueTableCell style={{ display: 'flex', alignItems: 'center' }}>
                {props.iconUrl && <StyledKeyValueTableIcon src={getCmsImageUrl(props.iconUrl, ['width=20'])} />}
            </StyledKeyValueTableCell>
            <StyledKeyValueTableCell>{props.value}</StyledKeyValueTableCell>
        </StyledKeyValueTableRow>
    );
};
