import styled, { css } from 'styled-components';
import { IconButton } from '../../icon-button/icon-button.component';
import { ArrowLeft, ArrowRight } from '../../../icons';
import { InnerCarousel } from '../inner-carousel/inner-carousel.component';
import { StyledCarouselAspectRatioProps } from '../carousel.types';
import { mixins } from '../../../../themes';

export const StyledTabbedCarousel = styled.div`
    position: relative;
`;

export const StyledTabbedCarouselControl = styled.div<{ isPreviousButton: boolean }>`
    ${({ isPreviousButton }) =>
        isPreviousButton
            ? css`
                  left: 40px;
              `
            : css`
                  right: 40px;
              `}
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
`;

export const StyledTabbedCarouselPreviousButton = styled(IconButton).attrs({
    children: ArrowLeft({}),
})``;

export const StyledTabbedCarouselNextButton = styled(IconButton).attrs({
    children: ArrowRight({}),
})``;

export const StyledTabbedInnerCarousel = styled(InnerCarousel).attrs<StyledCarouselAspectRatioProps>((attrs) => ({
    style: {
        aspectRatio: attrs.aspectRatio || '16 / 9',
    },
}))`
    height: 100%;
    width: 100%;
`;

export const StyledTabbedCarouselTabList = styled.div`
    align-items: flex-end;
    bottom: 60px;
    display: flex;
    gap: 30px;
    justify-content: center;
    left: 0px;
    position: absolute;
    right: 0px;
    white-space: nowrap;
    z-index: 2;
`;

export const StyledTabbedCarouselTab = styled.button<{ isActive: boolean }>`
    appearance: none;
    background: ${(props) => props.theme.palette.common.white};
    border: none;
    ${(props) =>
        props.isActive &&
        css`
            border-bottom: 0.8px solid ${props.theme.palette.common.black};
        `}
    cursor: pointer;
    display: inline-block;
    flex: 0 1 78px;
    padding: 0;
    transition: 200ms padding;
    border-radius: 20px;
    &:hover {
        padding: 0 0 5px;
    }

    &:focus-visible {
        outline: 2px dotted ${(props) => props.theme.palette.primary.main};
    }

    position: relative;
`;

export const StyledTabbedCarouselTabImage = styled.img.attrs<StyledCarouselAspectRatioProps>((attrs) => ({
    style: {
        aspectRatio: attrs.aspectRatio || '16 / 9',
    },
}))`
    display: inline-block;
    object-fit: contain;
    vertical-align: top;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    ${mixins.useSquaredCorners(false)}
`;

export const StyledTabbedCarouselTabVideo = styled.video.attrs<StyledCarouselAspectRatioProps>((attrs) => ({
    style: {
        aspectRatio: attrs.aspectRatio || '16 / 9',
    },
}))`
    display: inline-block;
    object-fit: contain;
    vertical-align: top;
    width: 100%;
`;

export const StyledPlayButton = styled.img`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid black;
    border-radius: 50%;
`;

export const StyledTabbedCarouselTabYouTube = styled.img.attrs<StyledCarouselAspectRatioProps>((attrs) => ({
    style: {
        aspectRatio: attrs.aspectRatio || '16 / 9',
    },
}))`
    display: inline-block;
    object-fit: cover;
    vertical-align: top;
    width: 100%;
`;

export const StyledYouTubeImageAndIcon = styled.div`
    position: relative;
`;

export const StyledYouTubeImageAndIconOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
`;
