import { orgTypes } from '../../lib/api/models/umbraco';
import { getLetter } from './array.helpers';
import { isNullOrEmpty } from './text-convert.helpers';

/**
 * Modified version of the solution below
 * https://stackoverflow.com/questions/26836146/how-to-sort-array-items-by-longitude-latitude-distance-in-javascripts
 * @param lat1
 * @param lon1
 * @param lat2
 * @param lon2
 * @returns
 */
export function calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const lat1InRadians = (Math.PI * lat1) / 180;
    const lat2InRadians = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radiusAsTheta = (Math.PI * theta) / 180;

    let dist = Math.sin(lat1InRadians) * Math.sin(lat2InRadians) + Math.cos(lat1InRadians) * Math.cos(lat2InRadians) * Math.cos(radiusAsTheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344;

    return dist;
}

export function sortGeoLocations(
    locations: Array<orgTypes.DealershipWithGeoInfo>,
    userLocation?: { lat: number; long: number }
): Array<orgTypes.DealershipWithGeoInfo> {
    if (!userLocation) {
        locations.sort((a, b) => {
            if (a.header < b.header) {
                return -1;
            }
            if (a.header > b.header) {
                return 1;
            }
            return 0;
        });

        locations.forEach((x, idx) => (x.letter = getLetter(idx)));

        return locations;
    }

    for (let i = 0; i < locations.length; i++) {
        locations[i].distanceFromUser = calculateDistance(locations[i].lat, locations[i].lng, userLocation.lat, userLocation.long);
    }

    locations.sort((a, b) => {
        return (a.distanceFromUser ?? 0) - (b.distanceFromUser ?? 0);
    });

    locations.forEach((x, idx) => (x.letter = getLetter(idx)));

    return locations;
}

export const getGoogleMapsUrl = (address: string, zipcode?: string): URL => {
    return new URL(`https://www.google.com/maps/dir/?api=1&destination=${address}${!isNullOrEmpty(zipcode) ? `+${zipcode}` : ''}`);
};
