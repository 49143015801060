import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { BookingEvents, trackBooking } from './booking-events';
import { CalculateSavingsEvents, trackCalculateSavings } from './calculate-savings-events';
import { DataLayerObject, DispatchMethod } from '../gtm-tracking.types';
import { MembershipEvents, trackMembership } from './membership-events';
import { ReepayFormEvents, trackReepayForm } from './reepay-form-events';
import { HirePdpEvents, trackHirePdp } from './pdp-events';
import { HireLeadCheckoutEvents, trackHireLeadCheckout } from './hire-lead-checkout-events';
import { BookTestDriveFormEvents, trackBookTestDriveForm } from './book-test-drive-form-events';
import { ContactHesselFormEvents, trackContactHesselForm } from './contact-hessel-form-events';
import { GtmTrackingStore } from '../state/gtm-tracking.store';
import { ContactSixPlusSixFormEvents, trackContactSixPlusSixFormEvents } from './contact-sixplussix-form-events';
import { ShopTrackingEvents, trackShop } from './shop-events';

type GtmEvents = {
    trackBooking: () => BookingEvents;
    trackCalculateSavings: () => CalculateSavingsEvents;
    trackMembership: () => MembershipEvents;
    trackReepayForm: (type: string) => ReepayFormEvents;
    trackHirePdp: () => HirePdpEvents;
    trackHireLeadCheckout: () => HireLeadCheckoutEvents;
    trackBookTestDriveForm: () => BookTestDriveFormEvents;
    trackContactHesselForm: () => ContactHesselFormEvents;
    trackSixPlusSixContactForm: () => ContactSixPlusSixFormEvents;
    trackShopEvents: () => ShopTrackingEvents;
};

export enum GtmTrackingMode {
    Disabled,
    LogAndTrack,
    LogOnly,
    TrackOnly,
}

/**
 * Exposes methods for dispatching events to GTM.
 *
 * @param mode
 * Sets the tracking method. Available options:
 * - `Disabled`: Do nothing.
 * - `LogAndTrack`: Logs event to console and dispatches to GTM.
 * - `LogOnly`: Does not dispatch any event, but logs it to console.
 * - `TrackOnly`: Dispatces the events, without logging.
 */
export const useGtmTracking = (): GtmEvents => {
    const storedTrackingMode = GtmTrackingStore.useStoreState((state) => state.trackingMode);

    const sendDataToGTM = useGTMDispatch();

    const dispatch: DispatchMethod = (data: DataLayerObject) => {
        const trackMode = window.Cookiebot.consent?.marketing ? storedTrackingMode : GtmTrackingMode.Disabled;

        switch (trackMode) {
            case GtmTrackingMode.Disabled:
                return;

            case GtmTrackingMode.LogOnly:
                console.log(`[useGtmEvents] Logging GTM event '${data.event}' (no data dispatched).`, data);
                break;

            case GtmTrackingMode.TrackOnly:
                sendDataToGTM(data);
                break;

            default:
                console.log(`[useGtmEvents] Dispatching '${data.event}'.`, data);
                sendDataToGTM(data);
                break;
        }
    };

    return {
        trackBooking: trackBooking(dispatch),
        trackCalculateSavings: trackCalculateSavings(dispatch),
        trackMembership: trackMembership(dispatch),
        trackReepayForm: trackReepayForm(dispatch),
        trackHirePdp: trackHirePdp(dispatch),
        trackHireLeadCheckout: trackHireLeadCheckout(dispatch),
        trackBookTestDriveForm: trackBookTestDriveForm(dispatch),
        trackContactHesselForm: trackContactHesselForm(dispatch),
        trackSixPlusSixContactForm: trackContactSixPlusSixFormEvents(dispatch),
        trackShopEvents: trackShop(dispatch),
    };
};
