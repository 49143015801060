import styled from 'styled-components';

export const AllContent = styled.div`
    display: flex;
    flex-direction: column;

    padding: 50px;
    padding-right: 30px;
`;

export const CloseButton = styled.div`
    display: flex;
    align-content: center;
    width: auto;
    align-self: flex-end;
    cursor: pointer;
    margin-bottom: 30px;
`;

export const StyledHeaderAndDescription = styled.div`
    padding-right: 50px;

    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const SidebarHeader = styled.h2`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
`;

export const StyledDescription = styled.p`
    & ul,
    & ol {
        font-size: clamp(14px, 3.3vw - 0.75rem, 1rem);
        font-weight: 500;
        list-style: revert;
        padding: revert;
        margin: revert;
    }
`;
