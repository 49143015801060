import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { FC, useMemo } from 'react';
import { SiteSettings } from '../../lib/api/models/umbraco';
import { IS_PRODUCTION } from '../../utils/environment-constants';
import { resolveTrackingMode } from '../gtm-tracking-utils';
import { GtmTrackingStore } from '../state/gtm-tracking.store';

type GtmTrackingProviderProps = {
    gtmContainerId: string | undefined;
    isEnabled: boolean;
};

export function propsFromSettings(siteSettings: SiteSettings): GtmTrackingProviderProps {
    const { trackingSetup } = siteSettings.marketSettings[0];
    if (!trackingSetup) throw new Error('[propsFromSettings] Settings contains no markets.');

    return {
        gtmContainerId: trackingSetup.gTMId,
        isEnabled: !!trackingSetup.gTMEnabled,
    };
}

export const GtmTrackingProvider: FC<GtmTrackingProviderProps> = ({ children, gtmContainerId, isEnabled }) => {
    const gtmParams = useMemo(() => ({ id: gtmContainerId }), [gtmContainerId]);
    const gtmTrackingMode = useMemo(() => resolveTrackingMode(isEnabled, !IS_PRODUCTION), [isEnabled]);

    return (
        <GtmTrackingStore.Provider runtimeModel={{ trackingMode: gtmTrackingMode }}>
            <GTMProvider state={gtmParams}>{children}</GTMProvider>
        </GtmTrackingStore.Provider>
    );
};
