import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

import { ComplianceText } from '../car-leasing-tabs/components/compliance-text.component';

export const IncludingDeliveryAndComplianceTextWrapper = styled.div`
    padding: 0 20px;
    grid-area: 3 / 1 / 4 / 3;
    margin-bottom: 20px;
    @media ${device.laptopS} {
        margin-bottom: revert;
    }
`;

export const PositionedComplianceText = styled(ComplianceText)``;

export const StyledIncludingDeliveryText = styled.p`
    color: #807f80;
    font-size: 11px;
    font-style: italic;
    margin-bottom: 5px;
`;
