import { FC, useEffect, useState } from 'react';
import { ToggleWrapper, ToggleGrid, ToggleOption } from './toggle-ui.styled';

export type ToggleOptionType = {
    label: string;
    value: string;
};

type IProps = {
    className?: string;
    options: ToggleOptionType[];
    selectedOption: string;
    onChange?: (selection: string) => void;
};

export const ToggleUi: FC<IProps> = ({ className, options, onChange, selectedOption }) => {
    const [selected, setSelected] = useState(selectedOption);
    const currentlySelected = options.find((x) => x.value === selected);
    const updateValue = (value: string) => {
        setSelected(value);
        if (onChange) {
            onChange(value);
        }
    };
    useEffect(() => {
        if (selectedOption !== selected) setSelected(selectedOption);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOption]);

    return (
        <ToggleWrapper className={className}>
            <ToggleGrid toggleState={options.indexOf(currentlySelected ?? options[0])} totalToggles={options.length}>
                {options.map((x, index) => {
                    return (
                        <ToggleOption key={index} onClick={() => updateValue(x.value)} selected={x.value === selected}>
                            {x.label}
                        </ToggleOption>
                    );
                })}
            </ToggleGrid>
        </ToggleWrapper>
    );
};

export default ToggleUi;
