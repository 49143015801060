import { umbraco } from '../../../../lib/api';
import { ProductDetailsStore } from '../../../../lib/state/hessel-site/product-details';
import { Button } from '../../../shared';
import { OpenDialog, usePdpModals } from '../product-details-page.context';
import { StyledProductDetailGrid } from '../shared/product-detail-page-shared.styled';
import {
    StyledProductSpec,
    StyledProductSpecAllSpecsWrapper,
    StyledProductSpecsHeader,
    StyledProductSpecsWrapper,
} from './product-details-page-specs.styled';

type ProductDetailsPageSpecsProps = {
    sizeGuide?: umbraco.SizeGuideForModel;
};
const ProductDetailsPageSpecs = ({ sizeGuide }: ProductDetailsPageSpecsProps) => {
    const { selectedProduct } = ProductDetailsStore.useStoreState((state) => state);
    const { openDialog } = usePdpModals();

    const navigateToStyleGuide = () => {
        if (!selectedProduct) return;

        if (sizeGuide) {
            const element = document.querySelectorAll(`[id="${selectedProduct.variantModelCode}" i]`);

            if (element && element.length > 0) {
                element[0].scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }
        }
    };

    return (
        <StyledProductSpecsWrapper>
            <StyledProductSpecsHeader>Specifikationer</StyledProductSpecsHeader>
            <StyledProductDetailGrid>
                {selectedProduct?.highlightedSpecs.map((spec) => (
                    <StyledProductSpec key={spec.label}>
                        <span>{spec.label}</span>
                        <span>
                            {spec.value} {spec.unit}
                        </span>
                    </StyledProductSpec>
                ))}
            </StyledProductDetailGrid>
            <StyledProductSpecAllSpecsWrapper>
                <Button onClick={() => openDialog({ dialog: OpenDialog.Specifications })}>Se alle specifikationer</Button>
                {selectedProduct && sizeGuide && (
                    <Button variant="link" onClick={navigateToStyleGuide}>
                        Hvor stor er bilen? Læs vores størrelsesguide
                    </Button>
                )}
            </StyledProductSpecAllSpecsWrapper>
        </StyledProductSpecsWrapper>
    );
};

export default ProductDetailsPageSpecs;
