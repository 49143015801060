import { useEffect, useMemo, useState, VFC } from 'react';
import { hireCheckoutTypes } from '../../../../../lib/api/models/umbraco';
import { EquipmentDialogManagerStore } from '../../../../../lib/state/hessel-site/equipment-dialog-manager';
import { LeadCheckoutStepsStore } from '../../../../../lib/state/hessel-site/lead-checkout/steps';
import { ProductDetailsStore } from '../../../../../lib/state/hessel-site/product-details';
import { hesselViewModels } from '../../../../../lib/view-models';
import { getFormattedValue, getUniqueId, isNullOrEmpty } from '../../../../../utils/helpers';
import { CheckboxBar } from '../../../../shared';
import { useUI } from '../../../../ui';
import { AddonsAndEquipmentWrapper, Content, StyledHeader, StyledList } from './addons-and-equipment.styled';
import { GenericBenefitsDialog } from './generic-benefits-dialog/generic-benefits-dialog.component';
import { Abbreviations } from '../../../../../constants/units-and-abbreviations';

function getIncludedEquipmentPackages(
    selectedTab: hesselViewModels.OwnershipTab,
    selectedProduct?: hesselViewModels.ProductDetails
): Array<hesselViewModels.EquipmentPackage> {
    if (!selectedProduct) {
        return [];
    }
    const equipmentPackages = selectedProduct?.extraEquipmentPackages[selectedTab] ?? [];
    return equipmentPackages.filter((eqPackage) => eqPackage.ownershipStatus === 'ExtraIncluded');
}

function getIncludedEquipment(
    selectedTab: hesselViewModels.OwnershipTab,
    selectedProduct?: hesselViewModels.ProductDetails
): Array<hesselViewModels.Equipment> {
    if (!selectedProduct) {
        return [];
    }
    const extraEquipment = selectedProduct?.extraEquipment[selectedTab] ?? [];
    return extraEquipment.filter((eq) => eq.ownershipStatus === 'ExtraIncluded');
}

type AddonsAndEquipmentProps = {
    genericBenefits: Array<hireCheckoutTypes.HireGenericBenefit>;
    stepNumber: number;
};

export const AddonsAndEquipment: VFC<AddonsAndEquipmentProps> = ({ genericBenefits, stepNumber }) => {
    // Product Details Store
    const { selectedProduct, includedEquipmentText, selectedTab } = ProductDetailsStore.useStoreState((state) => state);
    const { updateExtraEquipment, updateOptionalEquipment, updateExtraEquipmentPackage } = ProductDetailsStore.useStoreActions((actions) => actions);

    // Steps Store
    const { setStepValidity } = LeadCheckoutStepsStore.useStoreActions((actions) => actions);

    const [genericBenefitToView, setGenericBenefitToView] = useState<hireCheckoutTypes.HireGenericBenefit | undefined>();

    const uiHandler = useUI();

    // Equipment Dialog Manager Store
    const { setSelectedEquipment, setSelectedOptionalEquipment, setSelectedEquipmentPackage } = EquipmentDialogManagerStore.useStoreActions(
        (actions) => actions
    );

    useEffect(() => {
        setStepValidity({ isValid: !!selectedProduct, stepNumber });
    }, [selectedProduct, setStepValidity, stepNumber]);

    const selectableExtraEquipment = useMemo(() => {
        const equipmentList = selectedProduct?.extraEquipment[selectedTab] ?? [];
        return equipmentList.filter((eq) => eq.ownershipStatus === 'Extra');
    }, [selectedProduct?.extraEquipment, selectedTab]);

    const selectableExtraEquipmentPackage = useMemo(() => {
        const equipmentPackages = selectedProduct?.extraEquipmentPackages[selectedTab] ?? [];
        return equipmentPackages.filter((eqPackage) => eqPackage.ownershipStatus === 'Extra');
    }, [selectedProduct?.extraEquipmentPackages, selectedTab]);

    if (!selectedProduct) {
        return null;
    }

    return (
        <>
            <AddonsAndEquipmentWrapper>
                <Content>
                    <StyledHeader>Dette er allerede inkluderet i din aftale</StyledHeader>

                    <StyledList>
                        {genericBenefits.map((gb, index) => {
                            return (
                                <CheckboxBar
                                    key={`generic-benefit-${getUniqueId()}-${index}`}
                                    id={`generic-benefit-${getUniqueId()}-${index}`}
                                    title={gb.title ?? ''}
                                    isSelected={true}
                                    disabled={true}
                                    onReadMoreClick={
                                        !isNullOrEmpty(gb.description)
                                            ? () => {
                                                  uiHandler.removeScroll();
                                                  setGenericBenefitToView(gb);
                                              }
                                            : undefined
                                    }
                                    priceText={gb.label && gb.label.length > 0 ? gb.label : 'Inkluderet'}
                                />
                            );
                        })}

                        {/* Included Kilometers */}
                        <CheckboxBar
                            id={`included-kilometers-${getUniqueId()}`}
                            title={`${getFormattedValue(selectedProduct.purchaseTypes.hire.kilometersPerMonth)} ${Abbreviations.KM_SLASH_MD}`}
                            isSelected={true}
                            disabled={true}
                            priceText={includedEquipmentText && includedEquipmentText.length > 0 ? includedEquipmentText : 'Inkluderet'}
                        />

                        {getIncludedEquipment(selectedTab, selectedProduct).map((extraEq, index) => {
                            return (
                                <CheckboxBar
                                    key={`included-extra-equipment-id:${extraEq.id}-${index}`}
                                    id={extraEq.id}
                                    title={extraEq.name}
                                    isSelected={true}
                                    disabled={true}
                                    onReadMoreClick={
                                        !isNullOrEmpty(extraEq.description)
                                            ? () => {
                                                  uiHandler.removeScrollAndLock();
                                                  setSelectedEquipment(extraEq);
                                              }
                                            : undefined
                                    }
                                    priceText={includedEquipmentText}
                                />
                            );
                        })}

                        {getIncludedEquipmentPackages(selectedTab, selectedProduct).map((extraEqPackage, index) => {
                            return (
                                <CheckboxBar
                                    key={`equipment-package-id:${extraEqPackage.id}-${index}`}
                                    id={extraEqPackage.id}
                                    title={extraEqPackage.name ?? ''}
                                    isSelected={true}
                                    disabled={true}
                                    onReadMoreClick={
                                        !isNullOrEmpty(extraEqPackage.description) || extraEqPackage.equipment?.length > 0
                                            ? () => {
                                                  uiHandler.removeScrollAndLock();
                                                  setSelectedEquipmentPackage(extraEqPackage);
                                              }
                                            : undefined
                                    }
                                    priceText={includedEquipmentText}
                                />
                            );
                        })}
                    </StyledList>
                </Content>

                {/* For Order Cars show: extra equipment and equipment packages */}
                {selectedProduct.availability === 'Order' && (selectableExtraEquipment?.length || selectableExtraEquipmentPackage?.length) ? (
                    <Content>
                        <StyledHeader>Ekstraudstyr du burde overveje</StyledHeader>

                        <StyledList>
                            {selectableExtraEquipment?.map((extraEq, index) => {
                                return (
                                    <CheckboxBar
                                        key={`extra-equipment-id:${extraEq.id}-${index}`}
                                        id={extraEq.id}
                                        title={extraEq.name}
                                        isSelected={!!extraEq.isSelected}
                                        onChange={(id, isSelected) => updateExtraEquipment({ id, isSelected })}
                                        onReadMoreClick={
                                            !isNullOrEmpty(extraEq.description)
                                                ? () => {
                                                      uiHandler.removeScrollAndLock();
                                                      setSelectedEquipment(extraEq);
                                                  }
                                                : undefined
                                        }
                                        priceText={
                                            extraEq.monthlyHirePrice
                                                ? `+ ${getFormattedValue(extraEq.monthlyHirePrice)} ${Abbreviations.KR_SLASH_MD}`
                                                : '-'
                                        }
                                    />
                                );
                            })}

                            {selectableExtraEquipmentPackage?.map((extraEqPackage, index) => {
                                return (
                                    <CheckboxBar
                                        key={`extra-equipment-package-id:${extraEqPackage.id}-${index}`}
                                        id={extraEqPackage.id}
                                        title={extraEqPackage.name ?? ''}
                                        isSelected={!!extraEqPackage.isSelected}
                                        disabled={false}
                                        onReadMoreClick={
                                            !isNullOrEmpty(extraEqPackage.description) || extraEqPackage.equipment?.length > 0
                                                ? () => {
                                                      uiHandler.removeScrollAndLock();
                                                      setSelectedEquipmentPackage(extraEqPackage);
                                                  }
                                                : undefined
                                        }
                                        onChange={(id, isSelected) => updateExtraEquipmentPackage({ id, isSelected })}
                                        priceText={
                                            extraEqPackage.monthlyHirePrice
                                                ? `+ ${getFormattedValue(extraEqPackage.monthlyHirePrice)} ${Abbreviations.KR_SLASH_MD}`
                                                : '-'
                                        }
                                    />
                                );
                            })}
                        </StyledList>
                    </Content>
                ) : null}

                {selectedProduct.optionalEquipment?.[selectedTab]?.length ? (
                    <Content>
                        <StyledHeader>Attraktive tilvalg</StyledHeader>

                        <StyledList>
                            {selectedProduct?.optionalEquipment[selectedTab].map((optionalEq, index) => {
                                return (
                                    <CheckboxBar
                                        key={`optional-equipment-id:${optionalEq.id}-${index}`}
                                        id={optionalEq.id}
                                        title={optionalEq.name}
                                        isSelected={!!optionalEq.isSelected}
                                        onChange={(id, isSelected) => updateOptionalEquipment({ id, isSelected })}
                                        onReadMoreClick={
                                            !isNullOrEmpty(optionalEq.description)
                                                ? () => {
                                                      uiHandler.removeScrollAndLock();
                                                      setSelectedOptionalEquipment(optionalEq);
                                                  }
                                                : undefined
                                        }
                                        priceText={
                                            optionalEq.monthlyHirePrice
                                                ? `+ ${getFormattedValue(optionalEq.monthlyHirePrice)} ${Abbreviations.KR_SLASH_MD}`
                                                : '-'
                                        }
                                    />
                                );
                            })}
                        </StyledList>
                    </Content>
                ) : null}
            </AddonsAndEquipmentWrapper>

            <GenericBenefitsDialog
                visible={!!genericBenefitToView}
                header={genericBenefitToView?.title ?? ''}
                description={genericBenefitToView?.description ?? ''}
                hideSidebar={() => {
                    uiHandler.applyScroll();
                    setGenericBenefitToView(undefined);
                }}
            />
        </>
    );
};
