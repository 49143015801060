import styled from 'styled-components';
import { animated } from 'react-spring';
import { device } from '../../../../lib/media-query';

export const StyledErrorLabel = styled.p`
    color: red;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 12px;
`;

export const StyledLicensePlateContainer = styled.div`
    width: 80%;
`;

export const StyledBrandInfo = styled(animated.div)`
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid ${({ theme }) => theme.palette.grey.medium};
    padding: 0.8rem;
    max-width: 80%;
    width: max-content;

    @media ${device.tablet} {
        align-self: flex-start;
    }
`;

export const StyledBrandInfoItem = styled.div<{ hasAgreements: boolean }>`
    display: grid;
    grid-template-columns: 0.5fr 1fr 4fr;
    align-items: center;
    margin-bottom: ${(props) => (props.hasAgreements ? '1rem' : '0')};
    grid-column-gap: 10px;
`;

export const StyledBrandInfoItemHeader = styled.p`
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 14px;
    font-weight: 500;
`;

export const StyledBrandInfoItemDescription = styled.p`
    letter-spacing: 0;
    line-height: 20px;
    font-size: 12px;
`;
