import { VFC, useMemo } from 'react';
import { PdpCarFinanceSetup } from '../../../../lib/api/models/umbraco/product-details';
import { hesselViewModels } from '../../../../lib/view-models';
import { TabbedCard } from '../../../shared/tabbed-card/tabbed-card.component';
import { OwnershipCard } from '../../ownership-card/ownership-card.component';
import { OpenDialog, usePdpModals } from '../../product-details-page/product-details-page.context';
import { isNullOrEmpty } from '../../../../utils/helpers';
import { ProductDetailsStore } from '../../../../lib/state/hessel-site/product-details';

export type CompanyCarTabsProps = {
    ownershipMode: hesselViewModels.OwnershipMode;
    companyCarSetup: Array<PdpCarFinanceSetup>;
    priceVisibilityChanged: (visible: boolean) => void;
};

export const CompanyCarTabs: VFC<CompanyCarTabsProps> = ({ companyCarSetup, priceVisibilityChanged }) => {
    const { openDialog } = usePdpModals();

    const { selectedProduct, selectedColor, extraEquipmentPackagePriceSum, extraEquipmentPriceSum, optionalEquipmentPriceSum } =
        ProductDetailsStore.useStoreState((state) => state);

    const totalPrice = useMemo(() => {
        return (
            (selectedProduct?.purchaseTypes?.taxation?.basePrice ?? 0) +
            (selectedColor?.cashPrice ?? 0) +
            extraEquipmentPackagePriceSum +
            extraEquipmentPriceSum +
            optionalEquipmentPriceSum
        );
    }, [
        extraEquipmentPackagePriceSum,
        extraEquipmentPriceSum,
        optionalEquipmentPriceSum,
        selectedColor?.cashPrice,
        selectedProduct?.purchaseTypes?.taxation?.basePrice,
    ]);

    const companyCarCMS = companyCarSetup.find((x) => x.alias === 'companyCar');
    const cashTab =
        companyCarCMS?.alias === 'companyCar'
            ? {
                  label: companyCarCMS?.tabName ?? '',
                  description: '',
                  onClick: () => () => {},
                  content: (
                      <OwnershipCard
                          primaryCta={{
                              label: !isNullOrEmpty(companyCarCMS.ctaText) ? companyCarCMS.ctaText : 'Book rådgiver',
                              action: () => openDialog({ dialog: OpenDialog.BookCompanyCarAdvisor }),
                          }}
                          secondaryCta={{
                              label: 'Book prøvetur',
                              action: () => openDialog({ dialog: OpenDialog.BookTrial, sidebarOpened: 'Book Prøvetur' }),
                          }}
                          footerCta={{
                              label: 'Kontakt Hessel',
                              action: () => openDialog({ dialog: OpenDialog.ContactHessel }),
                          }}
                          usps={companyCarCMS.usps}
                          price={{
                              price: totalPrice,
                              monthly: false,
                              isCompanyCar: true,
                          }}
                          disclaimer={''}
                          priceVisibilityChanged={priceVisibilityChanged}
                      />
                  ),
              }
            : undefined;

    return <TabbedCard tabs={cashTab ? [cashTab] : []} />;
};
