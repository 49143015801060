import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const OrderSummaryCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    padding: 5px 10px;
    padding-bottom: 15px;

    @media ${device.mobileL} {
        padding-right: 25px;
        padding-left: 25px;
    }
`;

export const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px 0;
`;

export const TextGroup = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Label = styled.p`
    color: #807f80;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
`;

export const StyledValue = styled.p`
    color: #0b0b0b;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
`;
