import React, { useState, VFC } from 'react';
import { uiTypes } from '../../../../lib';
import { contentSpotTypes } from '../../../../lib/api';
import { MEDIA_URL } from '../../../../utils/environment-constants';
import { ScrollIndicator } from '../../../shared/scroll-cue';
import { CollapsibleContent } from './collapsible-content/collapsible-content.component';
import {
    BorderBox,
    ColumnHeader,
    ColumnSubHeader,
    ColumnTextWrapper,
    StickyTh,
    StickyThForRow,
    StyledCellText,
    StyledIcon,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from './sticky-table.styled';

type StickyTableProps = {
    className?: string;
    tableData: {
        firstColumnHeader?: string;
        firstColumnSubheader?: string;
        rows: Array<contentSpotTypes.ComparisonTableRow>;
        columns: Array<contentSpotTypes.ComparisonTableColumn>;
    };
    rowPadding?: uiTypes.Spacing;
    rowHeaderSize?: uiTypes.Size;
};

export const StickyTable: VFC<StickyTableProps> = ({ className, tableData, rowPadding, rowHeaderSize }) => {
    const [showScrollCue, setShowScrollCue] = useState(true);

    return (
        <BorderBox
            className={className}
            onScroll={() => {
                if (showScrollCue) {
                    setShowScrollCue(false);
                }
            }}
        >
            <Table>
                <Thead>
                    <Tr>
                        <StickyTh>
                            {tableData.firstColumnHeader && tableData.firstColumnHeader.length > 0 ? (
                                <ColumnTextWrapper>
                                    <ColumnHeader>{tableData.firstColumnHeader}</ColumnHeader>
                                    <ColumnSubHeader>{tableData.firstColumnSubheader}</ColumnSubHeader>
                                </ColumnTextWrapper>
                            ) : null}
                        </StickyTh>
                        {tableData?.columns.map((col, index) => {
                            return (
                                <Th key={`column-${index}`}>
                                    <ColumnTextWrapper>
                                        <ColumnHeader>{col.headerText}</ColumnHeader>
                                        <ColumnSubHeader>{col.subheaderText}</ColumnSubHeader>
                                    </ColumnTextWrapper>
                                </Th>
                            );
                        })}
                    </Tr>
                </Thead>
                <Tbody>
                    {tableData.rows?.map((row, index) => {
                        return (
                            <Tr key={`row-${index}`}>
                                <StickyThForRow rowPadding={rowPadding ?? '8px 20px'}>
                                    <CollapsibleContent row={row} rowTitleSize={rowHeaderSize} />
                                </StickyThForRow>

                                {row.cells?.map((cell, cellIndex) => {
                                    return (
                                        <Td key={`cell-${cellIndex}`}>
                                            {cell.icon ? <StyledIcon src={`${MEDIA_URL}${cell.icon?.src}`} alt={row.icon?.name} /> : null}
                                            {typeof cell.text === 'string' && !cell.icon ? (
                                                <StyledCellText>
                                                    <strong>{cell.text}</strong>
                                                </StyledCellText>
                                            ) : null}
                                            {React.isValidElement(cell.text) && !cell.icon ? cell.text : null}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
            {showScrollCue && (
                <ScrollIndicator icon="/icons/chevron/right.svg" width={50} animationDirection="Horizontal" top="26%" right="-4%" fullWidth={false} />
            )}
        </BorderBox>
    );
};
