import { FC, useEffect, useMemo, useState } from 'react';
import { IDatePicker } from './date-picker.props';
import { CalendarArea, CalendarWrapper, DateInput, InputIcon, InputWrapper, SpinnerContainer } from './date-picker.styled';
import { SvgIcon } from '../svg-icon';
import { CustomDatePicker } from './custom-date-picker/custom-date-picker.styled';
import { ConfirmModal } from '../modals/confirm';
import { DateStyle, formatDate, isDateDisabled } from '../../../utils/helpers';
import da from 'date-fns/locale/da';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { useUI } from '../../ui';
import { Spinner } from '../spinner';
import { Matcher } from 'react-day-picker';

export const DatePicker: FC<IDatePicker> = ({
    selectedDate = new Date(),
    onSelect,
    availableDays = [],
    modalHeaderText,
    modalConfirmText,
    modalCancelText,
    onMonthChange,
    toggleModalNotification,
    loadingDays,
    mobileRenderMethod = 'Modal',
    includeWeekends = false,
    showWeekNumber = false,
}) => {
    const [showModal, setShowModal] = useState(false);
    const isMobile = useMediaQuery({ target: 'tablet' });
    const [currentSelection, setCurrentSelection] = useState(selectedDate);

    const ui = useUI();

    const disabledDates: Matcher[] = useMemo(
        () => [(day) => isDateDisabled(day, availableDays), { dayOfWeek: includeWeekends ? [] : [0, 6] }, { before: new Date() }],
        [availableDays, includeWeekends]
    );

    useEffect(() => {
        setCurrentSelection(selectedDate);
    }, [selectedDate]);

    useEffect(() => {
        onSelect(selectedDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Currently react-day picker has issues with updating the UI when month is not today's month.
    // Future updates might fix this issue. If so, remove this useEffect.
    const [month, setMonth] = useState<Date>();

    useEffect(() => {
        setMonth(selectedDate);
    }, [selectedDate]);

    return !isMobile || mobileRenderMethod === 'Inline' ? (
        <CustomDatePicker
            locale={da}
            selected={selectedDate}
            disabled={disabledDates}
            mode="default"
            month={month}
            fromMonth={new Date()}
            defaultMonth={selectedDate}
            onDayClick={(date: Date | undefined) => {
                if (date) {
                    setCurrentSelection(date);
                    onSelect(date);
                }
            }}
            onMonthChange={(date: Date) => {
                if (onMonthChange) {
                    onMonthChange(date);
                }

                setMonth(date);
            }}
            showWeekNumber={showWeekNumber}
        />
    ) : (
        <>
            <InputWrapper
                onClick={() => {
                    setShowModal(true);
                    ui.removeScroll();
                    if (toggleModalNotification) {
                        toggleModalNotification(true);
                    }
                }}
            >
                <DateInput placeholder="Vælg dato" value={formatDate(selectedDate, DateStyle.dk_text)} readOnly />
                <InputIcon>
                    <SvgIcon iconName="chevron/down" color="primary" />
                </InputIcon>
            </InputWrapper>

            <ConfirmModal
                confirmAction={() => {
                    onSelect(currentSelection);
                    setShowModal(false);
                    ui.applyScroll();
                    if (toggleModalNotification) {
                        toggleModalNotification(false);
                    }
                }}
                cancelAction={() => {
                    setCurrentSelection(selectedDate);
                    setShowModal(false);
                    ui.applyScroll();
                    if (toggleModalNotification) {
                        toggleModalNotification(false);
                    }
                }}
                isVisible={showModal}
                headerText={modalHeaderText}
                confirmText={modalConfirmText}
                cancelText={modalCancelText}
            >
                <CalendarArea>
                    {loadingDays && (
                        <SpinnerContainer>
                            <Spinner variant="light" size="medium" />
                        </SpinnerContainer>
                    )}
                    <CalendarWrapper isLoading={loadingDays}>
                        <CustomDatePicker
                            locale={da}
                            selected={currentSelection}
                            disabled={disabledDates}
                            mode="default"
                            month={month}
                            fromMonth={new Date()}
                            defaultMonth={selectedDate}
                            onDayClick={(date: Date | undefined) => {
                                if (date) {
                                    setCurrentSelection(date);
                                    // onSelect(date);
                                }
                            }}
                            onMonthChange={(date: Date) => {
                                if (onMonthChange) {
                                    onMonthChange(date);
                                }

                                setMonth(date);
                            }}
                            showWeekNumber={showWeekNumber}
                        />
                    </CalendarWrapper>
                </CalendarArea>
            </ConfirmModal>
        </>
    );
};
