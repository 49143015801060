import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const StyledPrimaryLinkBlock = styled.a<{ border: boolean }>`
    display: flex;
    flex-grow: 1;
    align-items: flex-start;
    gap: 18px;
    padding: 20px 0;
    ${({ border }) => (border ? 'border-bottom: 1px solid rgba(151,151,151,.2);' : '')}
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
    flex-direction: row-reverse;
    justify-content: space-between;
    @media ${device.laptopS} {
        flex-direction: row;
        justify-content: flex-start;
    }
`;

export const StyledLinkTextBox = styled.span`
    display: block;
`;

export const StyledIcon = styled.div`
    background-color: ${({ theme }) => theme.iconography.primary.stroke};
    height: 32px;
    width: 32px;
    border-radius: 50%;
    position: relative;
    flex-shrink: 0;

    span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

export const StyledLinkTextWrapper = styled.span`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const StyledPrimaryLinkText = styled.p`
    font-family: 'Corporate E';
    font-weight: 700;
    font-size: 18px;
    color: #0b0b0b;
    line-height: 1;
`;

export const StyledPrimaryLinkDescription = styled.p`
    font-size: 12px;
    color: #0b0b0b;
    opacity: 0.5;
    line-height: 1;
    font-weight: 500;
`;
