import styled from 'styled-components';

export const BorderBox = styled.div`
    margin-bottom: 10px;
`;

export const ChildWrapper = styled.div<{ extraMargin?: boolean }>`
    margin: ${(props) => (props.extraMargin ? '2.5rem 0 0 0' : '1rem 0 0 0')};
`;

export const StyledRadioButtonText = styled.p`
    color: #0b0b0b;
    font-weight: 400;
`;
