import { FC, useMemo, useState } from 'react';
import { FilterUpdateNotification } from '../../../../../lib/api/models/hessel-api/hire';
import { FacetGroup } from '../../../../../lib/view-models/vehicle';
import { ToggleUi } from '../../../../shared/toggle-ui';
import { DropdownFilterWrapper } from '../dropdown-filter/dropdown-filter-wrapper.component';
import { FilterHeader, FilterWrapper } from '../filter-group/filter-group.styled';
import { Accordion } from '../../../../shared/accordion';
import styled from 'styled-components';

type IProps = {
    onChange: (filterUpdate: FilterUpdateNotification[]) => void;
    priceOptions: FacetGroup[];
};

export const PriceFilter: FC<IProps> = ({ priceOptions, onChange }) => {
    const [selectedPriceOption, setSelectedPriceOption] = useState(priceOptions[0].key);
    const currentlySelectedPriceOption = useMemo(() => priceOptions.find((x) => x.key === selectedPriceOption), [priceOptions, selectedPriceOption]);
    const [isDirty, setIsDirty] = useState(false);
    const [open, setOpen] = useState(false);
    const togglePrices = (selection: string) => {
        if (currentlySelectedPriceOption && isDirty) {
            setIsDirty(false);
            onChange([
                {
                    action: 'Remove all in key',
                    query: `${currentlySelectedPriceOption.key}__`,
                },
            ]);
        }
        setSelectedPriceOption(selection);
    };
    const updateFilter = (update: FilterUpdateNotification[]) => {
        setIsDirty(true);
        onChange(update);
    };
    return (
        <FilterWrapper>
            <Accordion
                title=""
                variant="primary"
                border="none"
                headerSize="sm"
                isOpen={open}
                index={0}
                onClick={() => setOpen(!open)}
                backgroundColor="fff"
                customTitle={<FilterHeader>Pris</FilterHeader>}
                toggleIndicator="plus"
            >
                <StyledToggleUi
                    options={[
                        ...priceOptions.map((x) => {
                            return {
                                label: x.label,
                                value: x.key,
                            };
                        }),
                    ]}
                    selectedOption={selectedPriceOption}
                    onChange={(selection) => togglePrices(selection)}
                />
                {currentlySelectedPriceOption ? (
                    <DropdownFilterWrapper
                        key={currentlySelectedPriceOption.key}
                        facets={currentlySelectedPriceOption.facetOptions}
                        onChange={(update) => updateFilter(update)}
                        groupKey={currentlySelectedPriceOption.key}
                    />
                ) : null}
            </Accordion>
        </FilterWrapper>
    );
};

const StyledToggleUi = styled(ToggleUi)`
    margin: 10px 0;
`;
