import { useEffect, useMemo, useState } from 'react';
import { umbraco } from '../../../lib/api';
import { getProductList } from '../../../lib/api/vehicle/hessel-vehicle-plp-api';
import { hesselApiTypes } from '../../../lib/api/models/hessel-api';
import { FilterUpdateNotification, ProductListApiResponse } from '../../../lib/api/models/hessel-api/hire';
import { createFacetArray } from '../../../lib/mappers/vehicle/product-list.mapper';
import { FacetGroup } from '../../../lib/view-models/vehicle';
import { BaseFilterSetup } from '../../../lib/api/models/umbraco/content-spot';
import { AvailabilityFilterKey, EquipmentFilterKey } from '../../../constants/site-consts';

type CarFinderFilterModule = {
    activeFilters: Array<string>;
    productListData?: ProductListApiResponse | null;
    facetsArray: Array<FacetGroup>;
    filterUpdate: (updates: FilterUpdateNotification[]) => void;
    availabilityFacet: FacetGroup | undefined;
    facets: Array<FacetGroup>;
};

const availabilityKey = AvailabilityFilterKey;
const equipmentKey = EquipmentFilterKey;

export const useCarFinderFilter = (
    globalPlpSettings: umbraco.GlobalProductListSettings,
    configurationId: string,
    allowModelOption = false,
    baseFilterSetup: BaseFilterSetup
): CarFinderFilterModule => {
    const [activeFilters, setActiveFilters] = useState<string[]>([`${AvailabilityFilterKey}__${baseFilterSetup.defaultAvailability}`]);
    const [productListData, setProductListData] = useState<ProductListApiResponse | null>(null);
    const [facetsArray, setFacetsArray] = useState<FacetGroup[]>([]);

    const [productListRequest, setProductListRequest] = useState<hesselApiTypes.ProductListRequest>({
        from: 0,
        take: 0, // Since we only need facets, take should be 0
        configurationId,
        filters: activeFilters,
    });

    //When we select a new filter we push it to the url. The 'blocking' part is to prevent us from calling the filterservice
    //with the same parameters as the list was prepopulated with.
    useEffect(() => {
        setProductListRequest({ ...productListRequest, filters: activeFilters, from: 0 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFilters]);

    useEffect(() => {
        const getProductListConfig = async () => {
            const [results, error] = await getProductList(productListRequest, 'Car');

            if (results && !error) {
                setProductListData(results);
                setFacetsArray(createFacetArray(results, globalPlpSettings, baseFilterSetup, allowModelOption));
            }
        };

        getProductListConfig();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productListRequest]);

    const filterUpdate = (updates: FilterUpdateNotification[]) => {
        let accumulator = activeFilters;
        updates.forEach((update) => {
            if (update.action === 'Add') {
                accumulator = [...accumulator, update.query];
            } else if (update.action === 'Remove') {
                accumulator = [...accumulator.filter((x) => x !== update.query)];
            } else if (update.action === 'Replace') {
                const baseQuery = update.query.split('__')[0];
                accumulator = [...accumulator.filter((x) => !x.startsWith(baseQuery)), update.query];
            }
        });
        //If we are switching to 'CarsToOrder' availability we remove any equipment filters
        if (accumulator.find((x) => x === `${availabilityKey}__1`)) {
            accumulator = accumulator.filter((x) => !x.startsWith(equipmentKey));
        }
        setActiveFilters(accumulator);
    };

    const availabilityFacet = useMemo(() => facetsArray.find((x) => x.key === availabilityKey), [facetsArray]);

    const facets = useMemo(
        () =>
            facetsArray.filter((x) => {
                if (x.key.startsWith(equipmentKey) && activeFilters.find((y) => y === `${availabilityKey}__1`)) {
                    return false;
                } else if (x.key !== availabilityKey && x.facetOptions.length > 0) {
                    return true;
                }
                return false;
            }),
        [activeFilters, facetsArray]
    );

    return {
        activeFilters,
        productListData,
        facetsArray,
        filterUpdate,
        availabilityFacet,
        facets,
    };
};
