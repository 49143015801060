import styled from 'styled-components';

export const StyledCircle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #f7f7f8;
    border-radius: 50%;
    width: 39px;
    height: 39px;
    font-size: 14px;
    font-weight: 500;
    color: rgba(11, 11, 11, 0.5);
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
`;
