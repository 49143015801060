import styled from 'styled-components';
import { device } from '../../../../../lib/media-query';

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    @media ${device.tablet} {
        display: flex;
        flex-direction: column;
    }
`;

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media ${device.tablet} {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        width: 100%;
    }
`;

export const CarInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & > div {
        margin-bottom: 10px;
    }

    @media ${device.tablet} {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 1rem;
        width: 100%;
        align-items: stretch;

        & > div {
            margin-bottom: 0;
        }
    }
`;
