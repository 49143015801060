import { FC, useEffect, useMemo, useState } from 'react';
import { SidePanel } from '../../../shared/modals/side-panel';
import { Button, CheckBox } from '../../../shared';
import { FilterGroup } from './filter-group';
import { MobileFilterWrapper, MobileFiltersHeader, MobileFiltersFooter } from './vehicle-product-list-mobile-filter.styled';
import { SvgIcon } from '../../../shared/svg-icon';
import { FacetGroup } from '../../../../lib/view-models/vehicle';
import { getFilterRenderType } from './filter.helpers';
import { FilterUpdateNotification } from '../../../../lib/api/models/hessel-api/hire';
import { SortingLabel } from '../../../../lib/api/models/umbraco';
import { Accordion } from '../../../shared/accordion';
import { FilterHeader, FilterWrapper, InputWrapper } from './filter-group/filter-group.styled';
import { OptionText } from './checkbox-filter/checkox-filter.styled';
import { DepartmentFilterResponse } from '../../../../lib/api/organization/hessel-dealership-api';
import { PaymentType } from '../../../../lib/api/models/umbraco/content-spot';
import { PriceFilter } from './price-filter/price-filter.component';
import {
    HirePriceFilterKey,
    FinancingPriceFilterKey,
    VanLeaseingPriceFilterKey,
    CashPriceFilterKey,
    PrivateLeasingPriceFilterKey,
} from '../../../../constants/site-consts';

type IProps = {
    openState: boolean;
    setOpenState: (state: boolean) => void;
    applyMobileFiltersText: string;
    closeMobileFilterOverlay: string;
    clearFiltersText: string;
    facets: FacetGroup[];
    resetFilters: () => void;
    updateFilters: (updates: FilterUpdateNotification[]) => void;
    updateSorting: (sorting: string) => void;
    sortingOptions: SortingLabel[];
    selectedSortOption: string;
    sortingOpenExternal: boolean;
    paymentType: PaymentType;
    maximumSubfacetDepth?: number;
    departments?: DepartmentFilterResponse[];
};

export const VehicleProductListMobileFilters: FC<IProps> = ({
    openState,
    setOpenState,
    applyMobileFiltersText,
    closeMobileFilterOverlay,
    clearFiltersText,
    facets,
    resetFilters,
    updateFilters,
    sortingOpenExternal,
    sortingOptions,
    selectedSortOption,
    updateSorting,
    maximumSubfacetDepth,
    departments,
    paymentType,
}) => {
    const [sortingOpen, setSortingOpen] = useState(sortingOpenExternal);
    const [selectedSorting, setSelectedSorting] = useState([selectedSortOption, '']);
    const resetFiltersAndClose = () => {
        setOpenState(false);
        resetFilters();
    };

    useEffect(() => {
        if (sortingOpenExternal !== sortingOpen) setSortingOpen(sortingOpenExternal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortingOpenExternal]);

    useEffect(() => {
        if (selectedSortOption !== selectedSorting[0]) setSelectedSorting([selectedSortOption, '']);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSortOption]);

    const filtersWithCorrectPriceFilters = useMemo(() => {
        if (paymentType === 'Buy') {
            return facets?.filter((x) => x.key !== HirePriceFilterKey && x.key !== FinancingPriceFilterKey && x.key !== VanLeaseingPriceFilterKey);
        }
        if (paymentType === 'Leasing') {
            return facets?.filter((x) => x.key !== CashPriceFilterKey && x.key !== FinancingPriceFilterKey);
        }
        return facets;
    }, [facets, paymentType]);

    const cashPriceFacet = useMemo(() => facets?.find((x) => x.key === CashPriceFilterKey), [facets]);
    const finanincingPriceFacet = useMemo(() => facets?.find((x) => x.key === FinancingPriceFilterKey), [facets]);
    const hirePriceFacet = useMemo(() => facets?.find((x) => x.key === HirePriceFilterKey), [facets]);
    const privateLeasingFacet = useMemo(() => facets?.find((x) => x.key === PrivateLeasingPriceFilterKey), [facets]);

    return (
        <SidePanel
            isVisible={openState}
            cancelAction={() => {
                setOpenState(false);
            }}
            variant="md"
        >
            <MobileFilterWrapper>
                <MobileFiltersHeader onClick={() => setOpenState(false)}>
                    {closeMobileFilterOverlay} <SvgIcon iconName="close" />
                </MobileFiltersHeader>
                {sortingOptions.length > 0 ? (
                    <FilterWrapper>
                        <Accordion
                            title=""
                            variant="primary"
                            border="none"
                            headerSize="sm"
                            isOpen={sortingOpen}
                            index={0}
                            onClick={() => setSortingOpen(!sortingOpen)}
                            backgroundColor="fff"
                            customTitle={<FilterHeader>Sortering</FilterHeader>}
                            toggleIndicator="plus"
                        >
                            {sortingOptions.map((x) => {
                                return (
                                    <InputWrapper key={x.sortingKey}>
                                        <CheckBox
                                            checked={x.labelText === selectedSorting[0]}
                                            id={x.sortingKey}
                                            value={x.sortingKey}
                                            onChange={() => updateSorting(x.sortingKey)}
                                        >
                                            <OptionText>{x.labelText}</OptionText>
                                        </CheckBox>
                                    </InputWrapper>
                                );
                            })}
                        </Accordion>
                    </FilterWrapper>
                ) : null}
                {filtersWithCorrectPriceFilters.map((x, idx) => {
                    if (x === cashPriceFacet && finanincingPriceFacet) {
                        return (
                            <PriceFilter
                                key={paymentType}
                                onChange={(filterUpdate) => updateFilters(filterUpdate)}
                                priceOptions={[cashPriceFacet, finanincingPriceFacet]}
                            />
                        );
                    }
                    if (x === finanincingPriceFacet && cashPriceFacet) return null;
                    if (x === hirePriceFacet && privateLeasingFacet) {
                        return (
                            <PriceFilter
                                key={paymentType}
                                onChange={(filterUpdate) => updateFilters(filterUpdate)}
                                priceOptions={[privateLeasingFacet, hirePriceFacet]}
                            />
                        );
                    }
                    if (x === privateLeasingFacet && hirePriceFacet) return null;
                    return (
                        <FilterGroup
                            key={`${x.key}-${idx}`}
                            header={x.label}
                            filterRenderType={getFilterRenderType(x)}
                            facet={x.facetOptions}
                            onChange={(filterUpdate) => updateFilters(filterUpdate)}
                            group={x}
                            maximumSubfacetDepth={maximumSubfacetDepth}
                            departments={departments}
                        />
                    );
                })}
                <MobileFiltersFooter>
                    <Button onClick={() => resetFiltersAndClose()}>{clearFiltersText}</Button>
                    <Button variant="primary" onClick={() => setOpenState(false)}>
                        {applyMobileFiltersText}
                    </Button>
                </MobileFiltersFooter>
            </MobileFilterWrapper>
        </SidePanel>
    );
};
