import React, { FC, ReactElement } from 'react';
import {
    StyledBulletPoint,
    StyledBulletPointImageContainer,
    StyledBulletPointLabel,
    StyledBulletPointList,
    StyledBulletPointValue,
} from './bullet-point-list.styled';

type Props = {
    points: BulletPoint[];
};

type BulletPoint = {
    name: string;
    value: string;
    icon: ReactElement;
    link?: string;
};

const BulletPointList: FC<Props> = ({ points }) => {
    return (
        <StyledBulletPointList>
            {points.map((point) => (
                <StyledBulletPoint key={point.name}>
                    <StyledBulletPointImageContainer>{point.icon}</StyledBulletPointImageContainer>
                    <div>
                        <StyledBulletPointLabel>{point.name}:</StyledBulletPointLabel>
                        <StyledBulletPointValue>{point.link ? <a href={point.link}>{point.value}</a> : point.value}</StyledBulletPointValue>
                    </div>
                </StyledBulletPoint>
            ))}
        </StyledBulletPointList>
    );
};

export default BulletPointList;
