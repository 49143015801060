import { useEffect, useState, VFC } from 'react';

import { ContentWrapper, StyledIcon, StyledDescription, StyledTitle, TitleAndDescription, ReadMore } from './collapsible-content.styled';

import { useSpring, config, animated } from 'react-spring';
import { useMeasure } from 'react-use';
import { replaceLineBreaks } from '../../../../../utils/helpers';
import { contentSpotTypes } from '../../../../../lib/api';
import { uiTypes } from '../../../../../lib';

const MEDIA_URL = process.env.NEXT_PUBLIC_MEDIA_URL;

type CollapsibleContentProps = {
    row: contentSpotTypes.ComparisonTableRow;
    rowTitleSize?: uiTypes.Size;
};

export const CollapsibleContent: VFC<CollapsibleContentProps> = ({ row, rowTitleSize }) => {
    const defaultHeight: string | undefined = '0px';

    // Gets the height of the element (ref)
    const [heightRef, { height }] = useMeasure();

    const [contentHeight, setContentHeight] = useState(defaultHeight || height);

    useEffect(() => {
        //Sets initial height
        setContentHeight(height);
    }, [height]);

    const [isOpen, setIsOpen] = useState(false);

    // Animations
    const expand = useSpring({
        config: { ...config.slow, friction: 50 },
        height: isOpen ? `${contentHeight}px` : defaultHeight,
        overflow: !isOpen ? 'hidden' : '',
    });

    return (
        <ContentWrapper hasIcon={!!row.icon}>
            {row.icon ? <StyledIcon src={`${MEDIA_URL}${row.icon.src}`} alt={row.icon?.name} /> : <div></div>}

            <TitleAndDescription>
                <StyledTitle size={rowTitleSize ?? 'small'}>
                    {row.title}{' '}
                    {row.description && row.description.length > 0 ? (
                        <>
                            &nbsp;
                            <ReadMore size={rowTitleSize ?? 'small'} onClick={() => setIsOpen(!isOpen)}>
                                {isOpen ? 'Læs mindre' : 'Læs mere'}
                            </ReadMore>
                        </>
                    ) : null}
                </StyledTitle>

                <animated.div style={expand}>
                    <StyledDescription ref={heightRef as any} dangerouslySetInnerHTML={{ __html: replaceLineBreaks(row.description) }} />
                </animated.div>
            </TitleAndDescription>
        </ContentWrapper>
    );
};
