import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const MangerWrapper = styled.div`
    & > section {
        margin-bottom: 0px;
    }

    @media ${device.tablet} {
        & > section {
            margin-bottom: 20px;
        }
    }
`;
