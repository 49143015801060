import { animated } from 'react-spring';
import styled from 'styled-components';
import { mixins } from '../../../../themes';

export const StyledTextInput = styled(animated.input)`
    ${mixins.typography('inputValue')}
    border: none;
    background-color: rgba(0, 0, 0, 0);
    letter-spacing: ${({ theme }) => theme.forms.letterSpacing}px;
    width: 100%;

    &:focus {
        outline: none;
    }

    &::placeholder {
        ${mixins.typography('inputPlaceholder')}
    }
`;
