import styled from 'styled-components';

export const NavListWrapper = styled.div`
    z-index: 15;
    padding: 0 20px;
    margin-bottom: 2.5rem;

    & section {
        border-bottom: 1px solid #d8d8d8;
    }
`;
