type CityGeoInfo = {
    name: string;
    zipcode: string;
    coordinates: {
        latitude: number;
        longitude: number;
    };
};

export const cities_and_geo_location: Array<CityGeoInfo> = [
    {
        name: 'København K',
        zipcode: '1050',
        coordinates: {
            latitude: 55.68065246,
            longitude: 12.58600133,
        },
    },
    {
        name: 'København K',
        zipcode: '1051',
        coordinates: {
            latitude: 55.6798377,
            longitude: 12.59022696,
        },
    },
    {
        name: 'København K',
        zipcode: '1052',
        coordinates: {
            latitude: 55.67906706,
            longitude: 12.58954953,
        },
    },
    {
        name: 'København K',
        zipcode: '1053',
        coordinates: {
            latitude: 55.67798554,
            longitude: 12.59073024,
        },
    },
    {
        name: 'København K',
        zipcode: '1054',
        coordinates: {
            latitude: 55.67763459,
            longitude: 12.58906639,
        },
    },
    {
        name: 'København K',
        zipcode: '1055',
        coordinates: {
            latitude: 55.67723256,
            longitude: 12.58807383,
        },
    },
    {
        name: 'København K',
        zipcode: '1056',
        coordinates: {
            latitude: 55.67946993,
            longitude: 12.58793902,
        },
    },
    {
        name: 'København K',
        zipcode: '1057',
        coordinates: {
            latitude: 55.67833309,
            longitude: 12.58959143,
        },
    },
    {
        name: 'København K',
        zipcode: '1058',
        coordinates: {
            latitude: 55.67595336,
            longitude: 12.58792693,
        },
    },
    {
        name: 'København K',
        zipcode: '1059',
        coordinates: {
            latitude: 55.67675239,
            longitude: 12.58672365,
        },
    },
    {
        name: 'København K',
        zipcode: '1060',
        coordinates: {
            latitude: 55.67651434,
            longitude: 12.5837984,
        },
    },
    {
        name: 'København K',
        zipcode: '1061',
        coordinates: {
            latitude: 55.67749602,
            longitude: 12.5810871,
        },
    },
    {
        name: 'København K',
        zipcode: '1062',
        coordinates: {
            latitude: 55.67745504,
            longitude: 12.5820684,
        },
    },
    {
        name: 'København K',
        zipcode: '1063',
        coordinates: {
            latitude: 55.67812648,
            longitude: 12.58481601,
        },
    },
    {
        name: 'København K',
        zipcode: '1064',
        coordinates: {
            latitude: 55.67855701,
            longitude: 12.58421619,
        },
    },
    {
        name: 'København K',
        zipcode: '1065',
        coordinates: {
            latitude: 55.67792419,
            longitude: 12.58120927,
        },
    },
    {
        name: 'København K',
        zipcode: '1066',
        coordinates: {
            latitude: 55.67738024,
            longitude: 12.58283658,
        },
    },
    {
        name: 'København K',
        zipcode: '1067',
        coordinates: {
            latitude: 55.67872776,
            longitude: 12.58180816,
        },
    },
    {
        name: 'København K',
        zipcode: '1068',
        coordinates: {
            latitude: 55.67746813,
            longitude: 12.58355191,
        },
    },
    {
        name: 'København K',
        zipcode: '1069',
        coordinates: {
            latitude: 55.67777633,
            longitude: 12.58417532,
        },
    },
    {
        name: 'København K',
        zipcode: '1070',
        coordinates: {
            latitude: 55.67881568,
            longitude: 12.58352498,
        },
    },
    {
        name: 'København K',
        zipcode: '1071',
        coordinates: {
            latitude: 55.67826694,
            longitude: 12.58375053,
        },
    },
    {
        name: 'København K',
        zipcode: '1072',
        coordinates: {
            latitude: 55.67845615,
            longitude: 12.58065996,
        },
    },
    {
        name: 'København K',
        zipcode: '1073',
        coordinates: {
            latitude: 55.67878677,
            longitude: 12.58060115,
        },
    },
    {
        name: 'København K',
        zipcode: '1074',
        coordinates: {
            latitude: 55.67941235,
            longitude: 12.5835962,
        },
    },
    {
        name: 'København K',
        zipcode: '1100',
        coordinates: {
            latitude: 55.67989336,
            longitude: 12.58283276,
        },
    },
    {
        name: 'København K',
        zipcode: '1101',
        coordinates: {
            latitude: 55.68044771,
            longitude: 12.5831775,
        },
    },
    {
        name: 'København K',
        zipcode: '1102',
        coordinates: {
            latitude: 55.68084435,
            longitude: 12.58279789,
        },
    },
    {
        name: 'København K',
        zipcode: '1103',
        coordinates: {
            latitude: 55.68076455,
            longitude: 12.58412972,
        },
    },
    {
        name: 'København K',
        zipcode: '1104',
        coordinates: {
            latitude: 55.68124172,
            longitude: 12.58393567,
        },
    },
    {
        name: 'København K',
        zipcode: '1105',
        coordinates: {
            latitude: 55.68003329,
            longitude: 12.58212355,
        },
    },
    {
        name: 'København K',
        zipcode: '1106',
        coordinates: {
            latitude: 55.68045276,
            longitude: 12.58120094,
        },
    },
    {
        name: 'København K',
        zipcode: '1107',
        coordinates: {
            latitude: 55.68161934,
            longitude: 12.58319268,
        },
    },
    {
        name: 'København K',
        zipcode: '1110',
        coordinates: {
            latitude: 55.68146481,
            longitude: 12.5818153,
        },
    },
    {
        name: 'København K',
        zipcode: '1111',
        coordinates: {
            latitude: 55.68219996,
            longitude: 12.58080608,
        },
    },
    {
        name: 'København K',
        zipcode: '1112',
        coordinates: {
            latitude: 55.68123981,
            longitude: 12.57974966,
        },
    },
    {
        name: 'København K',
        zipcode: '1113',
        coordinates: {
            latitude: 55.68001592,
            longitude: 12.58017369,
        },
    },
    {
        name: 'København K',
        zipcode: '1114',
        coordinates: {
            latitude: 55.68069802,
            longitude: 12.57891556,
        },
    },
    {
        name: 'København K',
        zipcode: '1115',
        coordinates: {
            latitude: 55.68103123,
            longitude: 12.5782846,
        },
    },
    {
        name: 'København K',
        zipcode: '1116',
        coordinates: {
            latitude: 55.68222456,
            longitude: 12.58005882,
        },
    },
    {
        name: 'København K',
        zipcode: '1117',
        coordinates: {
            latitude: 55.6810324,
            longitude: 12.58157142,
        },
    },
    {
        name: 'København K',
        zipcode: '1118',
        coordinates: {
            latitude: 55.68104247,
            longitude: 12.58080964,
        },
    },
    {
        name: 'København K',
        zipcode: '1119',
        coordinates: {
            latitude: 55.6823894,
            longitude: 12.57714609,
        },
    },
    {
        name: 'København K',
        zipcode: '1120',
        coordinates: {
            latitude: 55.68236562,
            longitude: 12.57870875,
        },
    },
    {
        name: 'København K',
        zipcode: '1121',
        coordinates: {
            latitude: 55.68277836,
            longitude: 12.57891573,
        },
    },
    {
        name: 'København K',
        zipcode: '1122',
        coordinates: {
            latitude: 55.68253368,
            longitude: 12.57978841,
        },
    },
    {
        name: 'København K',
        zipcode: '1123',
        coordinates: {
            latitude: 55.68426232,
            longitude: 12.57745579,
        },
    },
    {
        name: 'København K',
        zipcode: '1124',
        coordinates: {
            latitude: 55.68303024,
            longitude: 12.57689183,
        },
    },
    {
        name: 'København K',
        zipcode: '1125',
        coordinates: {
            latitude: 55.6824351,
            longitude: 12.5763264,
        },
    },
    {
        name: 'København K',
        zipcode: '1126',
        coordinates: {
            latitude: 55.68215407,
            longitude: 12.57533822,
        },
    },
    {
        name: 'København K',
        zipcode: '1127',
        coordinates: {
            latitude: 55.68291443,
            longitude: 12.57557089,
        },
    },
    {
        name: 'København K',
        zipcode: '1128',
        coordinates: {
            latitude: 55.68282201,
            longitude: 12.57473787,
        },
    },
    {
        name: 'København K',
        zipcode: '1129',
        coordinates: {
            latitude: 55.68317382,
            longitude: 12.57433901,
        },
    },
    {
        name: 'København K',
        zipcode: '1130',
        coordinates: {
            latitude: 55.68337542,
            longitude: 12.57364349,
        },
    },
    {
        name: 'København K',
        zipcode: '1131',
        coordinates: {
            latitude: 55.68385549,
            longitude: 12.5738097,
        },
    },
    {
        name: 'København K',
        zipcode: '1150',
        coordinates: {
            latitude: 55.68116195,
            longitude: 12.57713782,
        },
    },
    {
        name: 'København K',
        zipcode: '1151',
        coordinates: {
            latitude: 55.67925834,
            longitude: 12.57640167,
        },
    },
    {
        name: 'København K',
        zipcode: '1152',
        coordinates: {
            latitude: 55.68037643,
            longitude: 12.57705358,
        },
    },
    {
        name: 'København K',
        zipcode: '1153',
        coordinates: {
            latitude: 55.67913736,
            longitude: 12.57741313,
        },
    },
    {
        name: 'København K',
        zipcode: '1154',
        coordinates: {
            latitude: 55.67961793,
            longitude: 12.5758435,
        },
    },
    {
        name: 'København K',
        zipcode: '1155',
        coordinates: {
            latitude: 55.68007367,
            longitude: 12.57544104,
        },
    },
    {
        name: 'København K',
        zipcode: '1156',
        coordinates: {
            latitude: 55.67957221,
            longitude: 12.57530761,
        },
    },
    {
        name: 'København K',
        zipcode: '1157',
        coordinates: {
            latitude: 55.67907168,
            longitude: 12.57544281,
        },
    },
    {
        name: 'København K',
        zipcode: '1158',
        coordinates: {
            latitude: 55.67861951,
            longitude: 12.57361214,
        },
    },
    {
        name: 'København K',
        zipcode: '1159',
        coordinates: {
            latitude: 55.68077513,
            longitude: 12.57608635,
        },
    },
    {
        name: 'København K',
        zipcode: '1160',
        coordinates: {
            latitude: 55.67891577,
            longitude: 12.57872573,
        },
    },
    {
        name: 'København K',
        zipcode: '1161',
        coordinates: {
            latitude: 55.67850064,
            longitude: 12.5756279,
        },
    },
    {
        name: 'København K',
        zipcode: '1162',
        coordinates: {
            latitude: 55.67892784,
            longitude: 12.57424006,
        },
    },
    {
        name: 'København K',
        zipcode: '1164',
        coordinates: {
            latitude: 55.67826381,
            longitude: 12.57338344,
        },
    },
    {
        name: 'København K',
        zipcode: '1165',
        coordinates: {
            latitude: 55.68076308,
            longitude: 12.57068192,
        },
    },
    {
        name: 'København K',
        zipcode: '1166',
        coordinates: {
            latitude: 55.67899335,
            longitude: 12.57281215,
        },
    },
    {
        name: 'København K',
        zipcode: '1167',
        coordinates: {
            latitude: 55.6791299,
            longitude: 12.57155172,
        },
    },
    {
        name: 'København K',
        zipcode: '1168',
        coordinates: {
            latitude: 55.67990118,
            longitude: 12.57235905,
        },
    },
    {
        name: 'København K',
        zipcode: '1169',
        coordinates: {
            latitude: 55.680565,
            longitude: 12.57454621,
        },
    },
    {
        name: 'København K',
        zipcode: '1170',
        coordinates: {
            latitude: 55.68008905,
            longitude: 12.57446263,
        },
    },
    {
        name: 'København K',
        zipcode: '1171',
        coordinates: {
            latitude: 55.68177555,
            longitude: 12.57230959,
        },
    },
    {
        name: 'København K',
        zipcode: '1172',
        coordinates: {
            latitude: 55.68095745,
            longitude: 12.5736165,
        },
    },
    {
        name: 'København K',
        zipcode: '1173',
        coordinates: {
            latitude: 55.68174413,
            longitude: 12.57365135,
        },
    },
    {
        name: 'København K',
        zipcode: '1174',
        coordinates: {
            latitude: 55.68241134,
            longitude: 12.5724811,
        },
    },
    {
        name: 'København K',
        zipcode: '1175',
        coordinates: {
            latitude: 55.68255536,
            longitude: 12.57414508,
        },
    },
    {
        name: 'København K',
        zipcode: '1200',
        coordinates: {
            latitude: 55.67820341,
            longitude: 12.5798222,
        },
    },
    {
        name: 'København K',
        zipcode: '1201',
        coordinates: {
            latitude: 55.67813397,
            longitude: 12.57787668,
        },
    },
    {
        name: 'København K',
        zipcode: '1202',
        coordinates: {
            latitude: 55.6777881,
            longitude: 12.57894282,
        },
    },
    {
        name: 'København K',
        zipcode: '1203',
        coordinates: {
            latitude: 55.6765462,
            longitude: 12.57680004,
        },
    },
    {
        name: 'København K',
        zipcode: '1204',
        coordinates: {
            latitude: 55.67653214,
            longitude: 12.57548042,
        },
    },
    {
        name: 'København K',
        zipcode: '1205',
        coordinates: {
            latitude: 55.67723642,
            longitude: 12.57678089,
        },
    },
    {
        name: 'København K',
        zipcode: '1206',
        coordinates: {
            latitude: 55.67754793,
            longitude: 12.57706457,
        },
    },
    {
        name: 'København K',
        zipcode: '1207',
        coordinates: {
            latitude: 55.67810969,
            longitude: 12.57661838,
        },
    },
    {
        name: 'København K',
        zipcode: '1208',
        coordinates: {
            latitude: 55.6769724,
            longitude: 12.57500757,
        },
    },
    {
        name: 'København K',
        zipcode: '1209',
        coordinates: {
            latitude: 55.67789078,
            longitude: 12.57572903,
        },
    },
    {
        name: 'København K',
        zipcode: '1210',
        coordinates: {
            latitude: 55.67809804,
            longitude: 12.57425967,
        },
    },
    {
        name: 'København K',
        zipcode: '1211',
        coordinates: {
            latitude: 55.67746346,
            longitude: 12.5742407,
        },
    },
    {
        name: 'København K',
        zipcode: '1212',
        coordinates: {
            latitude: 55.6770308,
            longitude: 12.57874486,
        },
    },
    {
        name: 'København K',
        zipcode: '1213',
        coordinates: {
            latitude: 55.67665117,
            longitude: 12.57779783,
        },
    },
    {
        name: 'København K',
        zipcode: '1214',
        coordinates: {
            latitude: 55.67457161,
            longitude: 12.57975543,
        },
    },
    {
        name: 'København K',
        zipcode: '1215',
        coordinates: {
            latitude: 55.67544188,
            longitude: 12.58576691,
        },
    },
    {
        name: 'København K',
        zipcode: '1216',
        coordinates: {
            latitude: 55.67524053,
            longitude: 12.58304021,
        },
    },
    {
        name: 'København K',
        zipcode: '1218',
        coordinates: {
            latitude: 55.67606465,
            longitude: 12.58039426,
        },
    },
    {
        name: 'København K',
        zipcode: '1219',
        coordinates: {
            latitude: 55.67370675,
            longitude: 12.58340336,
        },
    },
    {
        name: 'København K',
        zipcode: '1220',
        coordinates: {
            latitude: 55.67358331,
            longitude: 12.57862049,
        },
    },
    {
        name: 'København K',
        zipcode: '1221',
        coordinates: {
            latitude: 55.67283897,
            longitude: 12.58192337,
        },
    },
    {
        name: 'København K',
        zipcode: '1250',
        coordinates: {
            latitude: 55.68202801,
            longitude: 12.58975823,
        },
    },
    {
        name: 'København K',
        zipcode: '1251',
        coordinates: {
            latitude: 55.67960602,
            longitude: 12.59498748,
        },
    },
    {
        name: 'København K',
        zipcode: '1252',
        coordinates: {
            latitude: 55.68145466,
            longitude: 12.59617117,
        },
    },
    {
        name: 'København K',
        zipcode: '1253',
        coordinates: {
            latitude: 55.6847672,
            longitude: 12.59699341,
        },
    },
    {
        name: 'København K',
        zipcode: '1254',
        coordinates: {
            latitude: 55.68100155,
            longitude: 12.59034599,
        },
    },
    {
        name: 'København K',
        zipcode: '1255',
        coordinates: {
            latitude: 55.68116853,
            longitude: 12.58863161,
        },
    },
    {
        name: 'København K',
        zipcode: '1256',
        coordinates: {
            latitude: 55.68579757,
            longitude: 12.59417151,
        },
    },
    {
        name: 'København K',
        zipcode: '1257',
        coordinates: {
            latitude: 55.68446372,
            longitude: 12.59278982,
        },
    },
    {
        name: 'København K',
        zipcode: '1259',
        coordinates: {
            latitude: 55.70489621,
            longitude: 12.6154165,
        },
    },
    {
        name: 'København K',
        zipcode: '1260',
        coordinates: {
            latitude: 55.68378904,
            longitude: 12.59008573,
        },
    },
    {
        name: 'København K',
        zipcode: '1261',
        coordinates: {
            latitude: 55.68191836,
            longitude: 12.58694628,
        },
    },
    {
        name: 'København K',
        zipcode: '1263',
        coordinates: {
            latitude: 55.68876737,
            longitude: 12.59622407,
        },
    },
    {
        name: 'København K',
        zipcode: '1264',
        coordinates: {
            latitude: 55.68424184,
            longitude: 12.58756966,
        },
    },
    {
        name: 'København K',
        zipcode: '1265',
        coordinates: {
            latitude: 55.68485743,
            longitude: 12.58943983,
        },
    },
    {
        name: 'København K',
        zipcode: '1266',
        coordinates: {
            latitude: 55.68789729,
            longitude: 12.59098837,
        },
    },
    {
        name: 'København K',
        zipcode: '1267',
        coordinates: {
            latitude: 55.68828183,
            longitude: 12.5903806,
        },
    },
    {
        name: 'København K',
        zipcode: '1268',
        coordinates: {
            latitude: 55.68881006,
            longitude: 12.59030867,
        },
    },
    {
        name: 'København K',
        zipcode: '1270',
        coordinates: {
            latitude: 55.68984032,
            longitude: 12.5908799,
        },
    },
    {
        name: 'København K',
        zipcode: '1271',
        coordinates: {
            latitude: 55.68924322,
            longitude: 12.58981807,
        },
    },
    {
        name: 'København K',
        zipcode: '1300',
        coordinates: {
            latitude: 55.68267225,
            longitude: 12.58427504,
        },
    },
    {
        name: 'København K',
        zipcode: '1301',
        coordinates: {
            latitude: 55.68334448,
            longitude: 12.58587955,
        },
    },
    {
        name: 'København K',
        zipcode: '1302',
        coordinates: {
            latitude: 55.68448319,
            longitude: 12.58409674,
        },
    },
    {
        name: 'København K',
        zipcode: '1303',
        coordinates: {
            latitude: 55.68594749,
            longitude: 12.58837636,
        },
    },
    {
        name: 'København K',
        zipcode: '1304',
        coordinates: {
            latitude: 55.68333481,
            longitude: 12.58306965,
        },
    },
    {
        name: 'København K',
        zipcode: '1306',
        coordinates: {
            latitude: 55.68430724,
            longitude: 12.58135321,
        },
    },
    {
        name: 'København K',
        zipcode: '1307',
        coordinates: {
            latitude: 55.68643425,
            longitude: 12.57998797,
        },
    },
    {
        name: 'København K',
        zipcode: '1308',
        coordinates: {
            latitude: 55.6865329,
            longitude: 12.58479357,
        },
    },
    {
        name: 'København K',
        zipcode: '1309',
        coordinates: {
            latitude: 55.68721135,
            longitude: 12.58342163,
        },
    },
    {
        name: 'København K',
        zipcode: '1310',
        coordinates: {
            latitude: 55.68593955,
            longitude: 12.59029867,
        },
    },
    {
        name: 'København K',
        zipcode: '1311',
        coordinates: {
            latitude: 55.68811515,
            longitude: 12.58380631,
        },
    },
    {
        name: 'København K',
        zipcode: '1312',
        coordinates: {
            latitude: 55.68763774,
            longitude: 12.58583966,
        },
    },
    {
        name: 'København K',
        zipcode: '1313',
        coordinates: {
            latitude: 55.68703948,
            longitude: 12.58894598,
        },
    },
    {
        name: 'København K',
        zipcode: '1314',
        coordinates: {
            latitude: 55.68750826,
            longitude: 12.58633595,
        },
    },
    {
        name: 'København K',
        zipcode: '1315',
        coordinates: {
            latitude: 55.68763127,
            longitude: 12.58791833,
        },
    },
    {
        name: 'København K',
        zipcode: '1316',
        coordinates: {
            latitude: 55.68830711,
            longitude: 12.58282835,
        },
    },
    {
        name: 'København K',
        zipcode: '1317',
        coordinates: {
            latitude: 55.68896717,
            longitude: 12.58191343,
        },
    },
    {
        name: 'København K',
        zipcode: '1318',
        coordinates: {
            latitude: 55.68846582,
            longitude: 12.58478262,
        },
    },
    {
        name: 'København K',
        zipcode: '1319',
        coordinates: {
            latitude: 55.68802871,
            longitude: 12.58712218,
        },
    },
    {
        name: 'København K',
        zipcode: '1320',
        coordinates: {
            latitude: 55.68846691,
            longitude: 12.58748743,
        },
    },
    {
        name: 'København K',
        zipcode: '1321',
        coordinates: {
            latitude: 55.68791672,
            longitude: 12.5895451,
        },
    },
    {
        name: 'København K',
        zipcode: '1322',
        coordinates: {
            latitude: 55.68835925,
            longitude: 12.58942362,
        },
    },
    {
        name: 'København K',
        zipcode: '1323',
        coordinates: {
            latitude: 55.68966625,
            longitude: 12.58573703,
        },
    },
    {
        name: 'København K',
        zipcode: '1324',
        coordinates: {
            latitude: 55.68930756,
            longitude: 12.58712585,
        },
    },
    {
        name: 'København K',
        zipcode: '1325',
        coordinates: {
            latitude: 55.68932439,
            longitude: 12.58847527,
        },
    },
    {
        name: 'København K',
        zipcode: '1326',
        coordinates: {
            latitude: 55.68962824,
            longitude: 12.58839238,
        },
    },
    {
        name: 'København K',
        zipcode: '1327',
        coordinates: {
            latitude: 55.69037471,
            longitude: 12.58723576,
        },
    },
    {
        name: 'København K',
        zipcode: '1328',
        coordinates: {
            latitude: 55.68991619,
            longitude: 12.58836924,
        },
    },
    {
        name: 'København K',
        zipcode: '1329',
        coordinates: {
            latitude: 55.68914774,
            longitude: 12.58464108,
        },
    },
    {
        name: 'København K',
        zipcode: '1350',
        coordinates: {
            latitude: 55.68659617,
            longitude: 12.57590522,
        },
    },
    {
        name: 'København K',
        zipcode: '1352',
        coordinates: {
            latitude: 55.68939296,
            longitude: 12.57310725,
        },
    },
    {
        name: 'København K',
        zipcode: '1353',
        coordinates: {
            latitude: 55.68704697,
            longitude: 12.57119575,
        },
    },
    {
        name: 'København K',
        zipcode: '1354',
        coordinates: {
            latitude: 55.68980117,
            longitude: 12.57096165,
        },
    },
    {
        name: 'København K',
        zipcode: '1355',
        coordinates: {
            latitude: 55.68871643,
            longitude: 12.5712291,
        },
    },
    {
        name: 'København K',
        zipcode: '1356',
        coordinates: {
            latitude: 55.6871711,
            longitude: 12.56804206,
        },
    },
    {
        name: 'København K',
        zipcode: '1357',
        coordinates: {
            latitude: 55.68858347,
            longitude: 12.56795624,
        },
    },
    {
        name: 'København K',
        zipcode: '1358',
        coordinates: {
            latitude: 55.68056747,
            longitude: 12.56695637,
        },
    },
    {
        name: 'København K',
        zipcode: '1359',
        coordinates: {
            latitude: 55.68365009,
            longitude: 12.56421074,
        },
    },
    {
        name: 'København K',
        zipcode: '1360',
        coordinates: {
            latitude: 55.6856827,
            longitude: 12.56656367,
        },
    },
    {
        name: 'København K',
        zipcode: '1361',
        coordinates: {
            latitude: 55.68296065,
            longitude: 12.56876693,
        },
    },
    {
        name: 'København K',
        zipcode: '1362',
        coordinates: {
            latitude: 55.68488443,
            longitude: 12.56993139,
        },
    },
    {
        name: 'København K',
        zipcode: '1363',
        coordinates: {
            latitude: 55.68513437,
            longitude: 12.56557099,
        },
    },
    {
        name: 'København K',
        zipcode: '1364',
        coordinates: {
            latitude: 55.68207564,
            longitude: 12.56643374,
        },
    },
    {
        name: 'København K',
        zipcode: '1365',
        coordinates: {
            latitude: 55.68208169,
            longitude: 12.56444325,
        },
    },
    {
        name: 'København K',
        zipcode: '1366',
        coordinates: {
            latitude: 55.68207959,
            longitude: 12.56325243,
        },
    },
    {
        name: 'København K',
        zipcode: '1367',
        coordinates: {
            latitude: 55.68310746,
            longitude: 12.56359571,
        },
    },
    {
        name: 'København K',
        zipcode: '1368',
        coordinates: {
            latitude: 55.68138249,
            longitude: 12.56378826,
        },
    },
    {
        name: 'København K',
        zipcode: '1369',
        coordinates: {
            latitude: 55.68214443,
            longitude: 12.55953017,
        },
    },
    {
        name: 'København K',
        zipcode: '1370',
        coordinates: {
            latitude: 55.68484818,
            longitude: 12.56346092,
        },
    },
    {
        name: 'København K',
        zipcode: '1371',
        coordinates: {
            latitude: 55.68594651,
            longitude: 12.56404534,
        },
    },
    {
        name: 'København K',
        zipcode: '1400',
        coordinates: {
            latitude: 55.67038519,
            longitude: 12.59681168,
        },
    },
    {
        name: 'København K',
        zipcode: '1401',
        coordinates: {
            latitude: 55.67729107,
            longitude: 12.59566443,
        },
    },
    {
        name: 'København K',
        zipcode: '1402',
        coordinates: {
            latitude: 55.67280147,
            longitude: 12.58542262,
        },
    },
    {
        name: 'København K',
        zipcode: '1403',
        coordinates: {
            latitude: 55.67539344,
            longitude: 12.5941033,
        },
    },
    {
        name: 'København K',
        zipcode: '1406',
        coordinates: {
            latitude: 55.67493602,
            longitude: 12.59344137,
        },
    },
    {
        name: 'København K',
        zipcode: '1407',
        coordinates: {
            latitude: 55.67360101,
            longitude: 12.59549054,
        },
    },
    {
        name: 'København K',
        zipcode: '1408',
        coordinates: {
            latitude: 55.67363421,
            longitude: 12.59088619,
        },
    },
    {
        name: 'København K',
        zipcode: '1409',
        coordinates: {
            latitude: 55.67355505,
            longitude: 12.58767981,
        },
    },
    {
        name: 'København K',
        zipcode: '1410',
        coordinates: {
            latitude: 55.67196294,
            longitude: 12.59114295,
        },
    },
    {
        name: 'København K',
        zipcode: '1411',
        coordinates: {
            latitude: 55.6706881,
            longitude: 12.58304583,
        },
    },
    {
        name: 'København K',
        zipcode: '1412',
        coordinates: {
            latitude: 55.67059202,
            longitude: 12.58609487,
        },
    },
    {
        name: 'København K',
        zipcode: '1413',
        coordinates: {
            latitude: 55.67064899,
            longitude: 12.58669684,
        },
    },
    {
        name: 'København K',
        zipcode: '1414',
        coordinates: {
            latitude: 55.67225731,
            longitude: 12.58974986,
        },
    },
    {
        name: 'København K',
        zipcode: '1415',
        coordinates: {
            latitude: 55.67444226,
            longitude: 12.59499761,
        },
    },
    {
        name: 'København K',
        zipcode: '1416',
        coordinates: {
            latitude: 55.67286214,
            longitude: 12.59424032,
        },
    },
    {
        name: 'København K',
        zipcode: '1417',
        coordinates: {
            latitude: 55.67274948,
            longitude: 12.59236256,
        },
    },
    {
        name: 'København K',
        zipcode: '1418',
        coordinates: {
            latitude: 55.67133117,
            longitude: 12.59016855,
        },
    },
    {
        name: 'København K',
        zipcode: '1419',
        coordinates: {
            latitude: 55.67053449,
            longitude: 12.58895097,
        },
    },
    {
        name: 'København K',
        zipcode: '1420',
        coordinates: {
            latitude: 55.67073872,
            longitude: 12.59000159,
        },
    },
    {
        name: 'København K',
        zipcode: '1421',
        coordinates: {
            latitude: 55.67028252,
            longitude: 12.58811181,
        },
    },
    {
        name: 'København K',
        zipcode: '1422',
        coordinates: {
            latitude: 55.67583778,
            longitude: 12.60083,
        },
    },
    {
        name: 'København K',
        zipcode: '1423',
        coordinates: {
            latitude: 55.67073581,
            longitude: 12.59282667,
        },
    },
    {
        name: 'København K',
        zipcode: '1424',
        coordinates: {
            latitude: 55.66931315,
            longitude: 12.59220558,
        },
    },
    {
        name: 'København K',
        zipcode: '1425',
        coordinates: {
            latitude: 55.67184012,
            longitude: 12.59425877,
        },
    },
    {
        name: 'København K',
        zipcode: '1426',
        coordinates: {
            latitude: 55.67223173,
            longitude: 12.59670167,
        },
    },
    {
        name: 'København K',
        zipcode: '1427',
        coordinates: {
            latitude: 55.67501129,
            longitude: 12.59740604,
        },
    },
    {
        name: 'København K',
        zipcode: '1428',
        coordinates: {
            latitude: 55.67551144,
            longitude: 12.59745699,
        },
    },
    {
        name: 'København K',
        zipcode: '1429',
        coordinates: {
            latitude: 55.67527963,
            longitude: 12.59825398,
        },
    },
    {
        name: 'København K',
        zipcode: '1430',
        coordinates: {
            latitude: 55.67701184,
            longitude: 12.59864182,
        },
    },
    {
        name: 'København K',
        zipcode: '1432',
        coordinates: {
            latitude: 55.69425974,
            longitude: 12.61655877,
        },
    },
    {
        name: 'København K',
        zipcode: '1433',
        coordinates: {
            latitude: 55.72946145,
            longitude: 12.72430087,
        },
    },
    {
        name: 'København K',
        zipcode: '1434',
        coordinates: {
            latitude: 55.68032633,
            longitude: 12.60345664,
        },
    },
    {
        name: 'København K',
        zipcode: '1435',
        coordinates: {
            latitude: 55.6803494,
            longitude: 12.60607236,
        },
    },
    {
        name: 'København K',
        zipcode: '1436',
        coordinates: {
            latitude: 55.6760139,
            longitude: 12.6035624,
        },
    },
    {
        name: 'København K',
        zipcode: '1437',
        coordinates: {
            latitude: 55.6822005,
            longitude: 12.60755523,
        },
    },
    {
        name: 'København K',
        zipcode: '1438',
        coordinates: {
            latitude: 55.68254869,
            longitude: 12.60018111,
        },
    },
    {
        name: 'København K',
        zipcode: '1439',
        coordinates: {
            latitude: 55.68671916,
            longitude: 12.60639558,
        },
    },
    {
        name: 'København K',
        zipcode: '1440',
        coordinates: {
            latitude: 55.67308696,
            longitude: 12.60137342,
        },
    },
    {
        name: 'København K',
        zipcode: '1441',
        coordinates: {
            latitude: 55.67992992,
            longitude: 12.6128732,
        },
    },
    {
        name: 'København K',
        zipcode: '1450',
        coordinates: {
            latitude: 55.67728132,
            longitude: 12.57305928,
        },
    },
    {
        name: 'København K',
        zipcode: '1451',
        coordinates: {
            latitude: 55.6800785,
            longitude: 12.56919359,
        },
    },
    {
        name: 'København K',
        zipcode: '1452',
        coordinates: {
            latitude: 55.67954104,
            longitude: 12.56829492,
        },
    },
    {
        name: 'København K',
        zipcode: '1453',
        coordinates: {
            latitude: 55.67990502,
            longitude: 12.57080331,
        },
    },
    {
        name: 'København K',
        zipcode: '1454',
        coordinates: {
            latitude: 55.67806442,
            longitude: 12.56955821,
        },
    },
    {
        name: 'København K',
        zipcode: '1455',
        coordinates: {
            latitude: 55.67883409,
            longitude: 12.57032224,
        },
    },
    {
        name: 'København K',
        zipcode: '1456',
        coordinates: {
            latitude: 55.67731979,
            longitude: 12.56925402,
        },
    },
    {
        name: 'København K',
        zipcode: '1457',
        coordinates: {
            latitude: 55.67821919,
            longitude: 12.57191269,
        },
    },
    {
        name: 'København K',
        zipcode: '1458',
        coordinates: {
            latitude: 55.67697626,
            longitude: 12.57201105,
        },
    },
    {
        name: 'København K',
        zipcode: '1459',
        coordinates: {
            latitude: 55.67753312,
            longitude: 12.57180355,
        },
    },
    {
        name: 'København K',
        zipcode: '1460',
        coordinates: {
            latitude: 55.67662509,
            longitude: 12.57083548,
        },
    },
    {
        name: 'København K',
        zipcode: '1461',
        coordinates: {
            latitude: 55.67681948,
            longitude: 12.57298975,
        },
    },
    {
        name: 'København K',
        zipcode: '1462',
        coordinates: {
            latitude: 55.6762414,
            longitude: 12.57177767,
        },
    },
    {
        name: 'København K',
        zipcode: '1463',
        coordinates: {
            latitude: 55.67572208,
            longitude: 12.57219443,
        },
    },
    {
        name: 'København K',
        zipcode: '1464',
        coordinates: {
            latitude: 55.67644962,
            longitude: 12.5726221,
        },
    },
    {
        name: 'København K',
        zipcode: '1465',
        coordinates: {
            latitude: 55.67606578,
            longitude: 12.57342745,
        },
    },
    {
        name: 'København K',
        zipcode: '1466',
        coordinates: {
            latitude: 55.67686347,
            longitude: 12.57412316,
        },
    },
    {
        name: 'København K',
        zipcode: '1467',
        coordinates: {
            latitude: 55.67607304,
            longitude: 12.57413846,
        },
    },
    {
        name: 'København K',
        zipcode: '1468',
        coordinates: {
            latitude: 55.67552423,
            longitude: 12.57336739,
        },
    },
    {
        name: 'København K',
        zipcode: '1470',
        coordinates: {
            latitude: 55.67506238,
            longitude: 12.57386014,
        },
    },
    {
        name: 'København K',
        zipcode: '1471',
        coordinates: {
            latitude: 55.67431551,
            longitude: 12.57499967,
        },
    },
    {
        name: 'København K',
        zipcode: '1472',
        coordinates: {
            latitude: 55.67368537,
            longitude: 12.57632308,
        },
    },
    {
        name: 'København K',
        zipcode: '1473',
        coordinates: {
            latitude: 55.67232734,
            longitude: 12.57834447,
        },
    },
    {
        name: 'København V',
        zipcode: '1550',
        coordinates: {
            latitude: 55.67630637,
            longitude: 12.56794358,
        },
    },
    {
        name: 'København V',
        zipcode: '1551',
        coordinates: {
            latitude: 55.67922359,
            longitude: 12.56484383,
        },
    },
    {
        name: 'København V',
        zipcode: '1552',
        coordinates: {
            latitude: 55.67433051,
            longitude: 12.57367758,
        },
    },
    {
        name: 'København V',
        zipcode: '1553',
        coordinates: {
            latitude: 55.67455675,
            longitude: 12.56908785,
        },
    },
    {
        name: 'København V',
        zipcode: '1554',
        coordinates: {
            latitude: 55.67705334,
            longitude: 12.56487951,
        },
    },
    {
        name: 'København V',
        zipcode: '1555',
        coordinates: {
            latitude: 55.674964,
            longitude: 12.57512169,
        },
    },
    {
        name: 'København V',
        zipcode: '1556',
        coordinates: {
            latitude: 55.67354201,
            longitude: 12.5735107,
        },
    },
    {
        name: 'København V',
        zipcode: '1557',
        coordinates: {
            latitude: 55.6731643,
            longitude: 12.57487219,
        },
    },
    {
        name: 'København V',
        zipcode: '1558',
        coordinates: {
            latitude: 55.67189966,
            longitude: 12.57714319,
        },
    },
    {
        name: 'København V',
        zipcode: '1559',
        coordinates: {
            latitude: 55.67150035,
            longitude: 12.57764517,
        },
    },
    {
        name: 'København V',
        zipcode: '1560',
        coordinates: {
            latitude: 55.66668985,
            longitude: 12.5708171,
        },
    },
    {
        name: 'København V',
        zipcode: '1561',
        coordinates: {
            latitude: 55.6608846,
            longitude: 12.56237124,
        },
    },
    {
        name: 'København V',
        zipcode: '1562',
        coordinates: {
            latitude: 55.67042671,
            longitude: 12.57484744,
        },
    },
    {
        name: 'København V',
        zipcode: '1563',
        coordinates: {
            latitude: 55.67081742,
            longitude: 12.57359465,
        },
    },
    {
        name: 'København V',
        zipcode: '1564',
        coordinates: {
            latitude: 55.67242943,
            longitude: 12.57621894,
        },
    },
    {
        name: 'København V',
        zipcode: '1567',
        coordinates: {
            latitude: 55.67019634,
            longitude: 12.57132645,
        },
    },
    {
        name: 'København V',
        zipcode: '1568',
        coordinates: {
            latitude: 55.66908712,
            longitude: 12.57277693,
        },
    },
    {
        name: 'København V',
        zipcode: '1569',
        coordinates: {
            latitude: 55.67128988,
            longitude: 12.57067539,
        },
    },
    {
        name: 'København V',
        zipcode: '1570',
        coordinates: {
            latitude: 55.67358392,
            longitude: 12.56449889,
        },
    },
    {
        name: 'København V',
        zipcode: '1571',
        coordinates: {
            latitude: 55.67075323,
            longitude: 12.57232129,
        },
    },
    {
        name: 'København V',
        zipcode: '1572',
        coordinates: {
            latitude: 55.671606,
            longitude: 12.5730003,
        },
    },
    {
        name: 'København V',
        zipcode: '1573',
        coordinates: {
            latitude: 55.67173669,
            longitude: 12.57409664,
        },
    },
    {
        name: 'København V',
        zipcode: '1574',
        coordinates: {
            latitude: 55.67158961,
            longitude: 12.57217128,
        },
    },
    {
        name: 'København V',
        zipcode: '1575',
        coordinates: {
            latitude: 55.67200881,
            longitude: 12.57057649,
        },
    },
    {
        name: 'København V',
        zipcode: '1576',
        coordinates: {
            latitude: 55.67113694,
            longitude: 12.56944432,
        },
    },
    {
        name: 'København V',
        zipcode: '1577',
        coordinates: {
            latitude: 55.66901439,
            longitude: 12.56800721,
        },
    },
    {
        name: 'København V',
        zipcode: '1600',
        coordinates: {
            latitude: 55.67988681,
            longitude: 12.56258051,
        },
    },
    {
        name: 'København V',
        zipcode: '1601',
        coordinates: {
            latitude: 55.67877817,
            longitude: 12.55937917,
        },
    },
    {
        name: 'København V',
        zipcode: '1602',
        coordinates: {
            latitude: 55.67873082,
            longitude: 12.5612736,
        },
    },
    {
        name: 'København V',
        zipcode: '1603',
        coordinates: {
            latitude: 55.67925094,
            longitude: 12.56222843,
        },
    },
    {
        name: 'København V',
        zipcode: '1604',
        coordinates: {
            latitude: 55.67743294,
            longitude: 12.5613943,
        },
    },
    {
        name: 'København V',
        zipcode: '1605',
        coordinates: {
            latitude: 55.67602364,
            longitude: 12.56106245,
        },
    },
    {
        name: 'København V',
        zipcode: '1606',
        coordinates: {
            latitude: 55.67806697,
            longitude: 12.56232778,
        },
    },
    {
        name: 'København V',
        zipcode: '1607',
        coordinates: {
            latitude: 55.67858553,
            longitude: 12.56377805,
        },
    },
    {
        name: 'København V',
        zipcode: '1608',
        coordinates: {
            latitude: 55.67611495,
            longitude: 12.56532316,
        },
    },
    {
        name: 'København V',
        zipcode: '1609',
        coordinates: {
            latitude: 55.67579789,
            longitude: 12.56377996,
        },
    },
    {
        name: 'København V',
        zipcode: '1610',
        coordinates: {
            latitude: 55.67456576,
            longitude: 12.5577223,
        },
    },
    {
        name: 'København V',
        zipcode: '1611',
        coordinates: {
            latitude: 55.67518232,
            longitude: 12.56362751,
        },
    },
    {
        name: 'København V',
        zipcode: '1612',
        coordinates: {
            latitude: 55.67560841,
            longitude: 12.56267131,
        },
    },
    {
        name: 'København V',
        zipcode: '1613',
        coordinates: {
            latitude: 55.67452629,
            longitude: 12.56162653,
        },
    },
    {
        name: 'København V',
        zipcode: '1614',
        coordinates: {
            latitude: 55.67414128,
            longitude: 12.56065848,
        },
    },
    {
        name: 'København V',
        zipcode: '1615',
        coordinates: {
            latitude: 55.67390932,
            longitude: 12.55909001,
        },
    },
    {
        name: 'København V',
        zipcode: '1616',
        coordinates: {
            latitude: 55.67309834,
            longitude: 12.55710699,
        },
    },
    {
        name: 'København V',
        zipcode: '1617',
        coordinates: {
            latitude: 55.67294594,
            longitude: 12.55457903,
        },
    },
    {
        name: 'København V',
        zipcode: '1618',
        coordinates: {
            latitude: 55.67382242,
            longitude: 12.55126144,
        },
    },
    {
        name: 'København V',
        zipcode: '1619',
        coordinates: {
            latitude: 55.67376086,
            longitude: 12.55017806,
        },
    },
    {
        name: 'København V',
        zipcode: '1620',
        coordinates: {
            latitude: 55.67264298,
            longitude: 12.55913327,
        },
    },
    {
        name: 'København V',
        zipcode: '1621',
        coordinates: {
            latitude: 55.67293333,
            longitude: 12.54769433,
        },
    },
    {
        name: 'København V',
        zipcode: '1622',
        coordinates: {
            latitude: 55.67245693,
            longitude: 12.54658832,
        },
    },
    {
        name: 'København V',
        zipcode: '1623',
        coordinates: {
            latitude: 55.67193979,
            longitude: 12.54526736,
        },
    },
    {
        name: 'København V',
        zipcode: '1624',
        coordinates: {
            latitude: 55.67212888,
            longitude: 12.54598034,
        },
    },
    {
        name: 'København V',
        zipcode: '1631',
        coordinates: {
            latitude: 55.6825235,
            longitude: 12.55548923,
        },
    },
    {
        name: 'København V',
        zipcode: '1632',
        coordinates: {
            latitude: 55.68343153,
            longitude: 12.55545314,
        },
    },
    {
        name: 'København V',
        zipcode: '1633',
        coordinates: {
            latitude: 55.68231545,
            longitude: 12.5564173,
        },
    },
    {
        name: 'København V',
        zipcode: '1634',
        coordinates: {
            latitude: 55.68187637,
            longitude: 12.55549264,
        },
    },
    {
        name: 'København V',
        zipcode: '1635',
        coordinates: {
            latitude: 55.68284156,
            longitude: 12.55627711,
        },
    },
    {
        name: 'København V',
        zipcode: '1650',
        coordinates: {
            latitude: 55.66872787,
            longitude: 12.55197362,
        },
    },
    {
        name: 'København V',
        zipcode: '1651',
        coordinates: {
            latitude: 55.67129215,
            longitude: 12.56525239,
        },
    },
    {
        name: 'København V',
        zipcode: '1652',
        coordinates: {
            latitude: 55.67242389,
            longitude: 12.56197922,
        },
    },
    {
        name: 'København V',
        zipcode: '1653',
        coordinates: {
            latitude: 55.67234568,
            longitude: 12.5609699,
        },
    },
    {
        name: 'København V',
        zipcode: '1654',
        coordinates: {
            latitude: 55.67158989,
            longitude: 12.55933466,
        },
    },
    {
        name: 'København V',
        zipcode: '1655',
        coordinates: {
            latitude: 55.67121581,
            longitude: 12.55816022,
        },
    },
    {
        name: 'København V',
        zipcode: '1656',
        coordinates: {
            latitude: 55.67137912,
            longitude: 12.55631521,
        },
    },
    {
        name: 'København V',
        zipcode: '1657',
        coordinates: {
            latitude: 55.67050611,
            longitude: 12.55581577,
        },
    },
    {
        name: 'København V',
        zipcode: '1658',
        coordinates: {
            latitude: 55.67049852,
            longitude: 12.55413977,
        },
    },
    {
        name: 'København V',
        zipcode: '1659',
        coordinates: {
            latitude: 55.67182348,
            longitude: 12.55451216,
        },
    },
    {
        name: 'København V',
        zipcode: '1660',
        coordinates: {
            latitude: 55.67089792,
            longitude: 12.55264927,
        },
    },
    {
        name: 'København V',
        zipcode: '1661',
        coordinates: {
            latitude: 55.67164674,
            longitude: 12.55188166,
        },
    },
    {
        name: 'København V',
        zipcode: '1662',
        coordinates: {
            latitude: 55.67152277,
            longitude: 12.55084057,
        },
    },
    {
        name: 'København V',
        zipcode: '1663',
        coordinates: {
            latitude: 55.67061559,
            longitude: 12.54995271,
        },
    },
    {
        name: 'København V',
        zipcode: '1664',
        coordinates: {
            latitude: 55.67191845,
            longitude: 12.54919832,
        },
    },
    {
        name: 'København V',
        zipcode: '1665',
        coordinates: {
            latitude: 55.67082135,
            longitude: 12.54858405,
        },
    },
    {
        name: 'København V',
        zipcode: '1666',
        coordinates: {
            latitude: 55.66979331,
            longitude: 12.54734503,
        },
    },
    {
        name: 'København V',
        zipcode: '1667',
        coordinates: {
            latitude: 55.66906572,
            longitude: 12.54621289,
        },
    },
    {
        name: 'København V',
        zipcode: '1668',
        coordinates: {
            latitude: 55.66882907,
            longitude: 12.54794234,
        },
    },
    {
        name: 'København V',
        zipcode: '1669',
        coordinates: {
            latitude: 55.66573477,
            longitude: 12.54769264,
        },
    },
    {
        name: 'København V',
        zipcode: '1670',
        coordinates: {
            latitude: 55.66709135,
            longitude: 12.54504302,
        },
    },
    {
        name: 'København V',
        zipcode: '1671',
        coordinates: {
            latitude: 55.66836707,
            longitude: 12.54530588,
        },
    },
    {
        name: 'København V',
        zipcode: '1672',
        coordinates: {
            latitude: 55.6685544,
            longitude: 12.54639085,
        },
    },
    {
        name: 'København V',
        zipcode: '1673',
        coordinates: {
            latitude: 55.66799575,
            longitude: 12.5465375,
        },
    },
    {
        name: 'København V',
        zipcode: '1674',
        coordinates: {
            latitude: 55.66560066,
            longitude: 12.54309456,
        },
    },
    {
        name: 'København V',
        zipcode: '1675',
        coordinates: {
            latitude: 55.66647464,
            longitude: 12.54685256,
        },
    },
    {
        name: 'København V',
        zipcode: '1676',
        coordinates: {
            latitude: 55.66619152,
            longitude: 12.54842401,
        },
    },
    {
        name: 'København V',
        zipcode: '1677',
        coordinates: {
            latitude: 55.66602386,
            longitude: 12.54700046,
        },
    },
    {
        name: 'København V',
        zipcode: '1699',
        coordinates: {
            latitude: 55.66810542,
            longitude: 12.56294693,
        },
    },
    {
        name: 'København V',
        zipcode: '1700',
        coordinates: {
            latitude: 55.66964694,
            longitude: 12.56228976,
        },
    },
    {
        name: 'København V',
        zipcode: '1701',
        coordinates: {
            latitude: 55.67179515,
            longitude: 12.56331688,
        },
    },
    {
        name: 'København V',
        zipcode: '1702',
        coordinates: {
            latitude: 55.67134085,
            longitude: 12.5637674,
        },
    },
    {
        name: 'København V',
        zipcode: '1703',
        coordinates: {
            latitude: 55.67056976,
            longitude: 12.56292145,
        },
    },
    {
        name: 'København V',
        zipcode: '1704',
        coordinates: {
            latitude: 55.67305739,
            longitude: 12.5699623,
        },
    },
    {
        name: 'København V',
        zipcode: '1705',
        coordinates: {
            latitude: 55.66349423,
            longitude: 12.54517056,
        },
    },
    {
        name: 'København V',
        zipcode: '1706',
        coordinates: {
            latitude: 55.67074811,
            longitude: 12.55994889,
        },
    },
    {
        name: 'København V',
        zipcode: '1707',
        coordinates: {
            latitude: 55.6718825,
            longitude: 12.56062207,
        },
    },
    {
        name: 'København V',
        zipcode: '1708',
        coordinates: {
            latitude: 55.6696213,
            longitude: 12.55745219,
        },
    },
    {
        name: 'København V',
        zipcode: '1709',
        coordinates: {
            latitude: 55.66829956,
            longitude: 12.55436089,
        },
    },
    {
        name: 'København V',
        zipcode: '1710',
        coordinates: {
            latitude: 55.67015852,
            longitude: 12.56287023,
        },
    },
    {
        name: 'København V',
        zipcode: '1711',
        coordinates: {
            latitude: 55.66770326,
            longitude: 12.5594416,
        },
    },
    {
        name: 'København V',
        zipcode: '1712',
        coordinates: {
            latitude: 55.66837077,
            longitude: 12.55783183,
        },
    },
    {
        name: 'København V',
        zipcode: '1714',
        coordinates: {
            latitude: 55.66603571,
            longitude: 12.5584376,
        },
    },
    {
        name: 'København V',
        zipcode: '1715',
        coordinates: {
            latitude: 55.66870393,
            longitude: 12.56063662,
        },
    },
    {
        name: 'København V',
        zipcode: '1716',
        coordinates: {
            latitude: 55.66884414,
            longitude: 12.55886941,
        },
    },
    {
        name: 'København V',
        zipcode: '1717',
        coordinates: {
            latitude: 55.66706859,
            longitude: 12.55732037,
        },
    },
    {
        name: 'København V',
        zipcode: '1718',
        coordinates: {
            latitude: 55.66711053,
            longitude: 12.55560361,
        },
    },
    {
        name: 'København V',
        zipcode: '1719',
        coordinates: {
            latitude: 55.66698025,
            longitude: 12.55476615,
        },
    },
    {
        name: 'København V',
        zipcode: '1720',
        coordinates: {
            latitude: 55.66359129,
            longitude: 12.54064325,
        },
    },
    {
        name: 'København V',
        zipcode: '1721',
        coordinates: {
            latitude: 55.66624074,
            longitude: 12.55326244,
        },
    },
    {
        name: 'København V',
        zipcode: '1722',
        coordinates: {
            latitude: 55.66657955,
            longitude: 12.55418453,
        },
    },
    {
        name: 'København V',
        zipcode: '1723',
        coordinates: {
            latitude: 55.66726948,
            longitude: 12.5522664,
        },
    },
    {
        name: 'København V',
        zipcode: '1724',
        coordinates: {
            latitude: 55.66809061,
            longitude: 12.55200311,
        },
    },
    {
        name: 'København V',
        zipcode: '1725',
        coordinates: {
            latitude: 55.66565101,
            longitude: 12.55379172,
        },
    },
    {
        name: 'København V',
        zipcode: '1726',
        coordinates: {
            latitude: 55.66564743,
            longitude: 12.55256569,
        },
    },
    {
        name: 'København V',
        zipcode: '1727',
        coordinates: {
            latitude: 55.66529918,
            longitude: 12.55157104,
        },
    },
    {
        name: 'København V',
        zipcode: '1728',
        coordinates: {
            latitude: 55.66481971,
            longitude: 12.55064723,
        },
    },
    {
        name: 'København V',
        zipcode: '1729',
        coordinates: {
            latitude: 55.66458741,
            longitude: 12.54915072,
        },
    },
    {
        name: 'København V',
        zipcode: '1730',
        coordinates: {
            latitude: 55.66439812,
            longitude: 12.54822289,
        },
    },
    {
        name: 'København V',
        zipcode: '1731',
        coordinates: {
            latitude: 55.66411741,
            longitude: 12.54688486,
        },
    },
    {
        name: 'København V',
        zipcode: '1732',
        coordinates: {
            latitude: 55.66393593,
            longitude: 12.54573859,
        },
    },
    {
        name: 'København V',
        zipcode: '1733',
        coordinates: {
            latitude: 55.66368886,
            longitude: 12.54431734,
        },
    },
    {
        name: 'København V',
        zipcode: '1734',
        coordinates: {
            latitude: 55.66523209,
            longitude: 12.54568262,
        },
    },
    {
        name: 'København V',
        zipcode: '1735',
        coordinates: {
            latitude: 55.66479718,
            longitude: 12.54537668,
        },
    },
    {
        name: 'København V',
        zipcode: '1736',
        coordinates: {
            latitude: 55.66524968,
            longitude: 12.54364271,
        },
    },
    {
        name: 'København V',
        zipcode: '1737',
        coordinates: {
            latitude: 55.66484287,
            longitude: 12.54447071,
        },
    },
    {
        name: 'København V',
        zipcode: '1738',
        coordinates: {
            latitude: 55.66449069,
            longitude: 12.54375074,
        },
    },
    {
        name: 'København V',
        zipcode: '1739',
        coordinates: {
            latitude: 55.664438,
            longitude: 12.54299209,
        },
    },
    {
        name: 'København V',
        zipcode: '1749',
        coordinates: {
            latitude: 55.66952603,
            longitude: 12.53704743,
        },
    },
    {
        name: 'København V',
        zipcode: '1750',
        coordinates: {
            latitude: 55.66769158,
            longitude: 12.5398063,
        },
    },
    {
        name: 'København V',
        zipcode: '1751',
        coordinates: {
            latitude: 55.67008603,
            longitude: 12.54477144,
        },
    },
    {
        name: 'København V',
        zipcode: '1752',
        coordinates: {
            latitude: 55.67003659,
            longitude: 12.54294527,
        },
    },
    {
        name: 'København V',
        zipcode: '1753',
        coordinates: {
            latitude: 55.66961867,
            longitude: 12.54335814,
        },
    },
    {
        name: 'København V',
        zipcode: '1754',
        coordinates: {
            latitude: 55.66925047,
            longitude: 12.54411696,
        },
    },
    {
        name: 'København V',
        zipcode: '1755',
        coordinates: {
            latitude: 55.66967755,
            longitude: 12.54272296,
        },
    },
    {
        name: 'København V',
        zipcode: '1756',
        coordinates: {
            latitude: 55.66900959,
            longitude: 12.54203009,
        },
    },
    {
        name: 'København V',
        zipcode: '1757',
        coordinates: {
            latitude: 55.66915035,
            longitude: 12.54082339,
        },
    },
    {
        name: 'København V',
        zipcode: '1758',
        coordinates: {
            latitude: 55.66758517,
            longitude: 12.54297343,
        },
    },
    {
        name: 'København V',
        zipcode: '1759',
        coordinates: {
            latitude: 55.66851692,
            longitude: 12.5432819,
        },
    },
    {
        name: 'København V',
        zipcode: '1760',
        coordinates: {
            latitude: 55.66571623,
            longitude: 12.54546668,
        },
    },
    {
        name: 'København V',
        zipcode: '1761',
        coordinates: {
            latitude: 55.66772397,
            longitude: 12.54124611,
        },
    },
    {
        name: 'København V',
        zipcode: '1762',
        coordinates: {
            latitude: 55.66555484,
            longitude: 12.54086863,
        },
    },
    {
        name: 'København V',
        zipcode: '1763',
        coordinates: {
            latitude: 55.6656842,
            longitude: 12.54207733,
        },
    },
    {
        name: 'København V',
        zipcode: '1764',
        coordinates: {
            latitude: 55.66497095,
            longitude: 12.53940436,
        },
    },
    {
        name: 'København V',
        zipcode: '1765',
        coordinates: {
            latitude: 55.66440364,
            longitude: 12.53905783,
        },
    },
    {
        name: 'København V',
        zipcode: '1766',
        coordinates: {
            latitude: 55.66414411,
            longitude: 12.54067769,
        },
    },
    {
        name: 'København V',
        zipcode: '1770',
        coordinates: {
            latitude: 55.66822916,
            longitude: 12.5365578,
        },
    },
    {
        name: 'København V',
        zipcode: '1771',
        coordinates: {
            latitude: 55.66852133,
            longitude: 12.53917356,
        },
    },
    {
        name: 'København V',
        zipcode: '1772',
        coordinates: {
            latitude: 55.6682938,
            longitude: 12.53864545,
        },
    },
    {
        name: 'København V',
        zipcode: '1773',
        coordinates: {
            latitude: 55.66818798,
            longitude: 12.53801681,
        },
    },
    {
        name: 'København V',
        zipcode: '1774',
        coordinates: {
            latitude: 55.66797162,
            longitude: 12.53904722,
        },
    },
    {
        name: 'København V',
        zipcode: '1775',
        coordinates: {
            latitude: 55.66788031,
            longitude: 12.53683801,
        },
    },
    {
        name: 'København V',
        zipcode: '1777',
        coordinates: {
            latitude: 55.66702322,
            longitude: 12.53764622,
        },
    },
    {
        name: 'København V',
        zipcode: '1799',
        coordinates: {
            latitude: 55.66585361,
            longitude: 12.53344813,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1800',
        coordinates: {
            latitude: 55.67047098,
            longitude: 12.53600765,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1801',
        coordinates: {
            latitude: 55.6690663,
            longitude: 12.53414281,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1802',
        coordinates: {
            latitude: 55.67007767,
            longitude: 12.53457921,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1803',
        coordinates: {
            latitude: 55.66997053,
            longitude: 12.53340416,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1804',
        coordinates: {
            latitude: 55.67003692,
            longitude: 12.53225891,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1805',
        coordinates: {
            latitude: 55.66804842,
            longitude: 12.5311529,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1806',
        coordinates: {
            latitude: 55.6721564,
            longitude: 12.53424914,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1807',
        coordinates: {
            latitude: 55.67115759,
            longitude: 12.53331808,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1808',
        coordinates: {
            latitude: 55.67159046,
            longitude: 12.53426314,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1809',
        coordinates: {
            latitude: 55.6716243,
            longitude: 12.53554461,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1810',
        coordinates: {
            latitude: 55.67146453,
            longitude: 12.53960125,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1811',
        coordinates: {
            latitude: 55.6725422,
            longitude: 12.53677431,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1812',
        coordinates: {
            latitude: 55.67120846,
            longitude: 12.53698945,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1813',
        coordinates: {
            latitude: 55.67238106,
            longitude: 12.53865345,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1814',
        coordinates: {
            latitude: 55.67153382,
            longitude: 12.54130724,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1815',
        coordinates: {
            latitude: 55.6727428,
            longitude: 12.54237549,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1816',
        coordinates: {
            latitude: 55.67280184,
            longitude: 12.54338951,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1817',
        coordinates: {
            latitude: 55.6720535,
            longitude: 12.54298543,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1818',
        coordinates: {
            latitude: 55.67247144,
            longitude: 12.54488147,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1819',
        coordinates: {
            latitude: 55.67398318,
            longitude: 12.54936765,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1820',
        coordinates: {
            latitude: 55.67407429,
            longitude: 12.53462279,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1822',
        coordinates: {
            latitude: 55.67268282,
            longitude: 12.54608419,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1823',
        coordinates: {
            latitude: 55.67285485,
            longitude: 12.54538991,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1824',
        coordinates: {
            latitude: 55.67414951,
            longitude: 12.54712394,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1825',
        coordinates: {
            latitude: 55.67422815,
            longitude: 12.5454441,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1826',
        coordinates: {
            latitude: 55.67479317,
            longitude: 12.54450577,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1827',
        coordinates: {
            latitude: 55.67472699,
            longitude: 12.54312856,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1828',
        coordinates: {
            latitude: 55.67482887,
            longitude: 12.5419349,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1829',
        coordinates: {
            latitude: 55.67594343,
            longitude: 12.54143187,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1850',
        coordinates: {
            latitude: 55.67774692,
            longitude: 12.5362512,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1851',
        coordinates: {
            latitude: 55.67572373,
            longitude: 12.53965438,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1852',
        coordinates: {
            latitude: 55.67648934,
            longitude: 12.53849568,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1853',
        coordinates: {
            latitude: 55.67529526,
            longitude: 12.53701022,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1854',
        coordinates: {
            latitude: 55.67515529,
            longitude: 12.53452691,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1855',
        coordinates: {
            latitude: 55.67620071,
            longitude: 12.53573489,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1856',
        coordinates: {
            latitude: 55.67661415,
            longitude: 12.53738999,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1857',
        coordinates: {
            latitude: 55.67693751,
            longitude: 12.53561489,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1860',
        coordinates: {
            latitude: 55.68109331,
            longitude: 12.53719762,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1861',
        coordinates: {
            latitude: 55.67979174,
            longitude: 12.53508153,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1862',
        coordinates: {
            latitude: 55.68004417,
            longitude: 12.53618519,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1863',
        coordinates: {
            latitude: 55.67918107,
            longitude: 12.53725843,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1864',
        coordinates: {
            latitude: 55.6783961,
            longitude: 12.53709225,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1865',
        coordinates: {
            latitude: 55.67906052,
            longitude: 12.53840772,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1866',
        coordinates: {
            latitude: 55.67777926,
            longitude: 12.53802154,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1867',
        coordinates: {
            latitude: 55.67856694,
            longitude: 12.53980769,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1868',
        coordinates: {
            latitude: 55.67769198,
            longitude: 12.54048726,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1870',
        coordinates: {
            latitude: 55.68052551,
            longitude: 12.54099176,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1871',
        coordinates: {
            latitude: 55.68087799,
            longitude: 12.54543129,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1872',
        coordinates: {
            latitude: 55.68027144,
            longitude: 12.54486513,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1873',
        coordinates: {
            latitude: 55.67981424,
            longitude: 12.54531212,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1874',
        coordinates: {
            latitude: 55.6794665,
            longitude: 12.546312,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1875',
        coordinates: {
            latitude: 55.67914852,
            longitude: 12.54439633,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1876',
        coordinates: {
            latitude: 55.67796084,
            longitude: 12.54513765,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1877',
        coordinates: {
            latitude: 55.676822,
            longitude: 12.54314122,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1878',
        coordinates: {
            latitude: 55.67659707,
            longitude: 12.54447671,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1879',
        coordinates: {
            latitude: 55.67944885,
            longitude: 12.54791386,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1900',
        coordinates: {
            latitude: 55.67568449,
            longitude: 12.55571599,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1901',
        coordinates: {
            latitude: 55.67596832,
            longitude: 12.54638197,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1902',
        coordinates: {
            latitude: 55.67688481,
            longitude: 12.54764912,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1903',
        coordinates: {
            latitude: 55.67760453,
            longitude: 12.54962625,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1904',
        coordinates: {
            latitude: 55.67662945,
            longitude: 12.5503761,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1905',
        coordinates: {
            latitude: 55.67515882,
            longitude: 12.55050195,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1906',
        coordinates: {
            latitude: 55.67534886,
            longitude: 12.55138892,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1908',
        coordinates: {
            latitude: 55.67446845,
            longitude: 12.55353922,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1909',
        coordinates: {
            latitude: 55.67558015,
            longitude: 12.55272852,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1910',
        coordinates: {
            latitude: 55.67957423,
            longitude: 12.55012682,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1911',
        coordinates: {
            latitude: 55.67759267,
            longitude: 12.55287463,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1912',
        coordinates: {
            latitude: 55.67947352,
            longitude: 12.55170183,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1913',
        coordinates: {
            latitude: 55.67825536,
            longitude: 12.55215583,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1914',
        coordinates: {
            latitude: 55.67839105,
            longitude: 12.55360844,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1915',
        coordinates: {
            latitude: 55.67886014,
            longitude: 12.55191481,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1916',
        coordinates: {
            latitude: 55.67755331,
            longitude: 12.55696888,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1917',
        coordinates: {
            latitude: 55.67684523,
            longitude: 12.55519671,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1920',
        coordinates: {
            latitude: 55.68036477,
            longitude: 12.54975215,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1921',
        coordinates: {
            latitude: 55.67989699,
            longitude: 12.55335595,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1922',
        coordinates: {
            latitude: 55.68060854,
            longitude: 12.55207833,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1923',
        coordinates: {
            latitude: 55.6811586,
            longitude: 12.55021079,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1924',
        coordinates: {
            latitude: 55.68134585,
            longitude: 12.55169309,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1925',
        coordinates: {
            latitude: 55.68101368,
            longitude: 12.55346186,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1926',
        coordinates: {
            latitude: 55.68001631,
            longitude: 12.5563497,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1927',
        coordinates: {
            latitude: 55.67936955,
            longitude: 12.55644595,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1928',
        coordinates: {
            latitude: 55.67873506,
            longitude: 12.55637458,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1950',
        coordinates: {
            latitude: 55.68169351,
            longitude: 12.53569241,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1951',
        coordinates: {
            latitude: 55.68207494,
            longitude: 12.53659621,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1952',
        coordinates: {
            latitude: 55.68244847,
            longitude: 12.53583526,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1953',
        coordinates: {
            latitude: 55.68293731,
            longitude: 12.53719507,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1954',
        coordinates: {
            latitude: 55.68414812,
            longitude: 12.5391162,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1955',
        coordinates: {
            latitude: 55.6836199,
            longitude: 12.54040474,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1956',
        coordinates: {
            latitude: 55.6833691,
            longitude: 12.54129064,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1957',
        coordinates: {
            latitude: 55.68329671,
            longitude: 12.54256238,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1958',
        coordinates: {
            latitude: 55.6850193,
            longitude: 12.54339869,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1959',
        coordinates: {
            latitude: 55.68571394,
            longitude: 12.54131491,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1960',
        coordinates: {
            latitude: 55.68491069,
            longitude: 12.54945121,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1961',
        coordinates: {
            latitude: 55.6819061,
            longitude: 12.54710267,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1962',
        coordinates: {
            latitude: 55.68262187,
            longitude: 12.54653218,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1963',
        coordinates: {
            latitude: 55.68129366,
            longitude: 12.54809396,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1964',
        coordinates: {
            latitude: 55.68439097,
            longitude: 12.54841915,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1965',
        coordinates: {
            latitude: 55.68343992,
            longitude: 12.54828201,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1966',
        coordinates: {
            latitude: 55.68336984,
            longitude: 12.54945981,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1967',
        coordinates: {
            latitude: 55.68419509,
            longitude: 12.54978855,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1970',
        coordinates: {
            latitude: 55.68275244,
            longitude: 12.54880394,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1971',
        coordinates: {
            latitude: 55.68421574,
            longitude: 12.55227545,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1972',
        coordinates: {
            latitude: 55.68366621,
            longitude: 12.55268155,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1973',
        coordinates: {
            latitude: 55.68367036,
            longitude: 12.55429159,
        },
    },
    {
        name: 'Frederiksberg C',
        zipcode: '1974',
        coordinates: {
            latitude: 55.6822106,
            longitude: 12.55381232,
        },
    },
    {
        name: 'Frederiksberg',
        zipcode: '2000',
        coordinates: {
            latitude: 55.67945641,
            longitude: 12.51562935,
        },
    },
    {
        name: 'København Ø',
        zipcode: '2100',
        coordinates: {
            latitude: 55.7109795,
            longitude: 12.57364633,
        },
    },
    {
        name: 'Nordhavn',
        zipcode: '2150',
        coordinates: {
            latitude: 55.72293927,
            longitude: 12.61612961,
        },
    },
    {
        name: 'København N',
        zipcode: '2200',
        coordinates: {
            latitude: 55.6940569,
            longitude: 12.54914572,
        },
    },
    {
        name: 'København S',
        zipcode: '2300',
        coordinates: {
            latitude: 55.67362511,
            longitude: 12.6554181,
        },
    },
    {
        name: 'København NV',
        zipcode: '2400',
        coordinates: {
            latitude: 55.71256101,
            longitude: 12.52876704,
        },
    },
    {
        name: 'København SV',
        zipcode: '2450',
        coordinates: {
            latitude: 55.64003572,
            longitude: 12.52293879,
        },
    },
    {
        name: 'Valby',
        zipcode: '2500',
        coordinates: {
            latitude: 55.65941452,
            longitude: 12.49869399,
        },
    },
    {
        name: 'Glostrup',
        zipcode: '2600',
        coordinates: {
            latitude: 55.6741838,
            longitude: 12.39697754,
        },
    },
    {
        name: 'Brøndby',
        zipcode: '2605',
        coordinates: {
            latitude: 55.64503648,
            longitude: 12.40439537,
        },
    },
    {
        name: 'Rødovre',
        zipcode: '2610',
        coordinates: {
            latitude: 55.68182922,
            longitude: 12.44863533,
        },
    },
    {
        name: 'Albertslund',
        zipcode: '2620',
        coordinates: {
            latitude: 55.6869516,
            longitude: 12.34477556,
        },
    },
    {
        name: 'Vallensbæk',
        zipcode: '2625',
        coordinates: {
            latitude: 55.63922355,
            longitude: 12.36481979,
        },
    },
    {
        name: 'Taastrup',
        zipcode: '2630',
        coordinates: {
            latitude: 55.6579409,
            longitude: 12.28083382,
        },
    },
    {
        name: 'Ishøj',
        zipcode: '2635',
        coordinates: {
            latitude: 55.58776321,
            longitude: 12.39483896,
        },
    },
    {
        name: 'Hedehusene',
        zipcode: '2640',
        coordinates: {
            latitude: 55.66357137,
            longitude: 12.19945854,
        },
    },
    {
        name: 'Hvidovre',
        zipcode: '2650',
        coordinates: {
            latitude: 55.58934868,
            longitude: 12.46097002,
        },
    },
    {
        name: 'Brøndby Strand',
        zipcode: '2660',
        coordinates: {
            latitude: 55.61998785,
            longitude: 12.42233883,
        },
    },
    {
        name: 'Vallensbæk Strand',
        zipcode: '2665',
        coordinates: {
            latitude: 55.61964832,
            longitude: 12.38841202,
        },
    },
    {
        name: 'Greve',
        zipcode: '2670',
        coordinates: {
            latitude: 55.54103195,
            longitude: 12.3529229,
        },
    },
    {
        name: 'Solrød Strand',
        zipcode: '2680',
        coordinates: {
            latitude: 55.5150734,
            longitude: 12.26072581,
        },
    },
    {
        name: 'Karlslunde',
        zipcode: '2690',
        coordinates: {
            latitude: 55.5692769,
            longitude: 12.21374503,
        },
    },
    {
        name: 'Brønshøj',
        zipcode: '2700',
        coordinates: {
            latitude: 55.70964347,
            longitude: 12.48463422,
        },
    },
    {
        name: 'Vanløse',
        zipcode: '2720',
        coordinates: {
            latitude: 55.6883311,
            longitude: 12.48194421,
        },
    },
    {
        name: 'Herlev',
        zipcode: '2730',
        coordinates: {
            latitude: 55.74057031,
            longitude: 12.42581802,
        },
    },
    {
        name: 'Skovlunde',
        zipcode: '2740',
        coordinates: {
            latitude: 55.71528471,
            longitude: 12.39724634,
        },
    },
    {
        name: 'Ballerup',
        zipcode: '2750',
        coordinates: {
            latitude: 55.73668449,
            longitude: 12.3638449,
        },
    },
    {
        name: 'Måløv',
        zipcode: '2760',
        coordinates: {
            latitude: 55.75323652,
            longitude: 12.32084626,
        },
    },
    {
        name: 'Smørum',
        zipcode: '2765',
        coordinates: {
            latitude: 55.72556424,
            longitude: 12.28121197,
        },
    },
    {
        name: 'Kastrup',
        zipcode: '2770',
        coordinates: {
            latitude: 55.63352245,
            longitude: 12.78698239,
        },
    },
    {
        name: 'Dragør',
        zipcode: '2791',
        coordinates: {
            latitude: 55.5081121,
            longitude: 12.59121712,
        },
    },
    {
        name: 'Kongens Lyngby',
        zipcode: '2800',
        coordinates: {
            latitude: 55.78572204,
            longitude: 12.52083673,
        },
    },
    {
        name: 'Gentofte',
        zipcode: '2820',
        coordinates: {
            latitude: 55.75145806,
            longitude: 12.53343057,
        },
    },
    {
        name: 'Virum',
        zipcode: '2830',
        coordinates: {
            latitude: 55.78962271,
            longitude: 12.46836985,
        },
    },
    {
        name: 'Holte',
        zipcode: '2840',
        coordinates: {
            latitude: 55.81684549,
            longitude: 12.48640452,
        },
    },
    {
        name: 'Nærum',
        zipcode: '2850',
        coordinates: {
            latitude: 55.81751707,
            longitude: 12.54388723,
        },
    },
    {
        name: 'Søborg',
        zipcode: '2860',
        coordinates: {
            latitude: 55.73800813,
            longitude: 12.48425242,
        },
    },
    {
        name: 'Dyssegård',
        zipcode: '2870',
        coordinates: {
            latitude: 55.73206862,
            longitude: 12.52794393,
        },
    },
    {
        name: 'Bagsværd',
        zipcode: '2880',
        coordinates: {
            latitude: 55.76191781,
            longitude: 12.44587672,
        },
    },
    {
        name: 'Hellerup',
        zipcode: '2900',
        coordinates: {
            latitude: 55.73442051,
            longitude: 12.56946744,
        },
    },
    {
        name: 'Charlottenlund',
        zipcode: '2920',
        coordinates: {
            latitude: 55.75754187,
            longitude: 12.6282041,
        },
    },
    {
        name: 'Klampenborg',
        zipcode: '2930',
        coordinates: {
            latitude: 55.79609961,
            longitude: 12.62132919,
        },
    },
    {
        name: 'Skodsborg',
        zipcode: '2942',
        coordinates: {
            latitude: 55.82842884,
            longitude: 12.59260158,
        },
    },
    {
        name: 'Vedbæk',
        zipcode: '2950',
        coordinates: {
            latitude: 55.86532817,
            longitude: 12.64375317,
        },
    },
    {
        name: 'Rungsted Kyst',
        zipcode: '2960',
        coordinates: {
            latitude: 55.88693173,
            longitude: 12.54954886,
        },
    },
    {
        name: 'Hørsholm',
        zipcode: '2970',
        coordinates: {
            latitude: 55.88126308,
            longitude: 12.43890506,
        },
    },
    {
        name: 'Kokkedal',
        zipcode: '2980',
        coordinates: {
            latitude: 55.91992432,
            longitude: 12.45605071,
        },
    },
    {
        name: 'Nivå',
        zipcode: '2990',
        coordinates: {
            latitude: 55.92959715,
            longitude: 12.51321363,
        },
    },
    {
        name: 'Helsingør',
        zipcode: '3000',
        coordinates: {
            latitude: 56.03943161,
            longitude: 12.61245096,
        },
    },
    {
        name: 'Humlebæk',
        zipcode: '3050',
        coordinates: {
            latitude: 55.9604599,
            longitude: 12.53687938,
        },
    },
    {
        name: 'Espergærde',
        zipcode: '3060',
        coordinates: {
            latitude: 55.99425687,
            longitude: 12.54283631,
        },
    },
    {
        name: 'Snekkersten',
        zipcode: '3070',
        coordinates: {
            latitude: 56.00467892,
            longitude: 12.59499744,
        },
    },
    {
        name: 'Tikøb',
        zipcode: '3080',
        coordinates: {
            latitude: 56.02560338,
            longitude: 12.45560033,
        },
    },
    {
        name: 'Hornbæk',
        zipcode: '3100',
        coordinates: {
            latitude: 56.10118532,
            longitude: 12.45420474,
        },
    },
    {
        name: 'Dronningmølle',
        zipcode: '3120',
        coordinates: {
            latitude: 56.10714196,
            longitude: 12.38687452,
        },
    },
    {
        name: 'Ålsgårde',
        zipcode: '3140',
        coordinates: {
            latitude: 56.09086037,
            longitude: 12.5430865,
        },
    },
    {
        name: 'Hellebæk',
        zipcode: '3150',
        coordinates: {
            latitude: 56.06020462,
            longitude: 12.5478008,
        },
    },
    {
        name: 'Helsinge',
        zipcode: '3200',
        coordinates: {
            latitude: 56.01099572,
            longitude: 12.1715219,
        },
    },
    {
        name: 'Vejby',
        zipcode: '3210',
        coordinates: {
            latitude: 56.09457775,
            longitude: 12.13066388,
        },
    },
    {
        name: 'Tisvildeleje',
        zipcode: '3220',
        coordinates: {
            latitude: 56.12775807,
            longitude: 11.95917304,
        },
    },
    {
        name: 'Græsted',
        zipcode: '3230',
        coordinates: {
            latitude: 56.22745639,
            longitude: 12.06980339,
        },
    },
    {
        name: 'Gilleleje',
        zipcode: '3250',
        coordinates: {
            latitude: 56.16514414,
            longitude: 12.29353244,
        },
    },
    {
        name: 'Frederiksværk',
        zipcode: '3300',
        coordinates: {
            latitude: 55.97774198,
            longitude: 12.05634805,
        },
    },
    {
        name: 'Ølsted',
        zipcode: '3310',
        coordinates: {
            latitude: 55.91156813,
            longitude: 12.06925064,
        },
    },
    {
        name: 'Skævinge',
        zipcode: '3320',
        coordinates: {
            latitude: 55.93559156,
            longitude: 12.15008815,
        },
    },
    {
        name: 'Gørløse',
        zipcode: '3330',
        coordinates: {
            latitude: 55.8837888,
            longitude: 12.2099944,
        },
    },
    {
        name: 'Liseleje',
        zipcode: '3360',
        coordinates: {
            latitude: 56.09213437,
            longitude: 11.87609952,
        },
    },
    {
        name: 'Melby',
        zipcode: '3370',
        coordinates: {
            latitude: 55.99124483,
            longitude: 11.97025413,
        },
    },
    {
        name: 'Hundested',
        zipcode: '3390',
        coordinates: {
            latitude: 56.26445585,
            longitude: 11.6705916,
        },
    },
    {
        name: 'Hillerød',
        zipcode: '3400',
        coordinates: {
            latitude: 55.92123871,
            longitude: 12.28696917,
        },
    },
    {
        name: 'Allerød',
        zipcode: '3450',
        coordinates: {
            latitude: 55.86605512,
            longitude: 12.33661891,
        },
    },
    {
        name: 'Birkerød',
        zipcode: '3460',
        coordinates: {
            latitude: 55.84270608,
            longitude: 12.41757019,
        },
    },
    {
        name: 'Fredensborg',
        zipcode: '3480',
        coordinates: {
            latitude: 55.97086354,
            longitude: 12.40200836,
        },
    },
    {
        name: 'Kvistgård',
        zipcode: '3490',
        coordinates: {
            latitude: 55.99928886,
            longitude: 12.49542003,
        },
    },
    {
        name: 'Værløse',
        zipcode: '3500',
        coordinates: {
            latitude: 55.77912974,
            longitude: 12.35591788,
        },
    },
    {
        name: 'Farum',
        zipcode: '3520',
        coordinates: {
            latitude: 55.81981968,
            longitude: 12.35067108,
        },
    },
    {
        name: 'Lynge',
        zipcode: '3540',
        coordinates: {
            latitude: 55.8397364,
            longitude: 12.28059858,
        },
    },
    {
        name: 'Slangerup',
        zipcode: '3550',
        coordinates: {
            latitude: 55.85027208,
            longitude: 12.17447416,
        },
    },
    {
        name: 'Frederikssund',
        zipcode: '3600',
        coordinates: {
            latitude: 55.85479562,
            longitude: 12.07923445,
        },
    },
    {
        name: 'Jægerspris',
        zipcode: '3630',
        coordinates: {
            latitude: 55.86932225,
            longitude: 11.91680034,
        },
    },
    {
        name: 'Ølstykke',
        zipcode: '3650',
        coordinates: {
            latitude: 55.78991195,
            longitude: 12.1609202,
        },
    },
    {
        name: 'Stenløse',
        zipcode: '3660',
        coordinates: {
            latitude: 55.80077298,
            longitude: 12.27836793,
        },
    },
    {
        name: 'Veksø Sjælland',
        zipcode: '3670',
        coordinates: {
            latitude: 55.75497247,
            longitude: 12.23729507,
        },
    },
    {
        name: 'Rønne',
        zipcode: '3700',
        coordinates: {
            latitude: 54.98495945,
            longitude: 14.40163467,
        },
    },
    {
        name: 'Aakirkeby',
        zipcode: '3720',
        coordinates: {
            latitude: 54.6920138,
            longitude: 14.7409871,
        },
    },
    {
        name: 'Nexø',
        zipcode: '3730',
        coordinates: {
            latitude: 54.87126103,
            longitude: 15.39301087,
        },
    },
    {
        name: 'Svaneke',
        zipcode: '3740',
        coordinates: {
            latitude: 55.30340399,
            longitude: 15.67679007,
        },
    },
    {
        name: 'Østermarie',
        zipcode: '3751',
        coordinates: {
            latitude: 55.1396331,
            longitude: 15.02005943,
        },
    },
    {
        name: 'Gudhjem',
        zipcode: '3760',
        coordinates: {
            latitude: 55.51191781,
            longitude: 15.21574747,
        },
    },
    {
        name: 'Allinge',
        zipcode: '3770',
        coordinates: {
            latitude: 55.36353136,
            longitude: 14.76377199,
        },
    },
    {
        name: 'Klemensker',
        zipcode: '3782',
        coordinates: {
            latitude: 55.19264146,
            longitude: 14.81018781,
        },
    },
    {
        name: 'Hasle',
        zipcode: '3790',
        coordinates: {
            latitude: 55.2284316,
            longitude: 14.52165638,
        },
    },
    {
        name: 'Roskilde',
        zipcode: '4000',
        coordinates: {
            latitude: 55.65647451,
            longitude: 12.06373356,
        },
    },
    {
        name: 'Tune',
        zipcode: '4030',
        coordinates: {
            latitude: 55.59602755,
            longitude: 12.16990416,
        },
    },
    {
        name: 'Jyllinge',
        zipcode: '4040',
        coordinates: {
            latitude: 55.75603087,
            longitude: 12.10404063,
        },
    },
    {
        name: 'Skibby',
        zipcode: '4050',
        coordinates: {
            latitude: 55.75873813,
            longitude: 12.01351904,
        },
    },
    {
        name: 'Kirke Såby',
        zipcode: '4060',
        coordinates: {
            latitude: 55.65437818,
            longitude: 11.87375746,
        },
    },
    {
        name: 'Kirke Hyllinge',
        zipcode: '4070',
        coordinates: {
            latitude: 55.70417096,
            longitude: 11.92418149,
        },
    },
    {
        name: 'Ringsted',
        zipcode: '4100',
        coordinates: {
            latitude: 55.43053197,
            longitude: 11.82805925,
        },
    },
    {
        name: 'Viby Sjælland',
        zipcode: '4130',
        coordinates: {
            latitude: 55.55011926,
            longitude: 12.00904287,
        },
    },
    {
        name: 'Borup',
        zipcode: '4140',
        coordinates: {
            latitude: 55.49411646,
            longitude: 11.97092667,
        },
    },
    {
        name: 'Herlufmagle',
        zipcode: '4160',
        coordinates: {
            latitude: 55.32910688,
            longitude: 11.77284465,
        },
    },
    {
        name: 'Glumsø',
        zipcode: '4171',
        coordinates: {
            latitude: 55.36237671,
            longitude: 11.67300466,
        },
    },
    {
        name: 'Fjenneslev',
        zipcode: '4173',
        coordinates: {
            latitude: 55.43261731,
            longitude: 11.66419067,
        },
    },
    {
        name: 'Jystrup Midtsj',
        zipcode: '4174',
        coordinates: {
            latitude: 55.52537395,
            longitude: 11.85552289,
        },
    },
    {
        name: 'Sorø',
        zipcode: '4180',
        coordinates: {
            latitude: 55.40880423,
            longitude: 11.55727004,
        },
    },
    {
        name: 'Munke Bjergby',
        zipcode: '4190',
        coordinates: {
            latitude: 55.50160113,
            longitude: 11.52243487,
        },
    },
    {
        name: 'Slagelse',
        zipcode: '4200',
        coordinates: {
            latitude: 55.40887345,
            longitude: 11.37134678,
        },
    },
    {
        name: 'Korsør',
        zipcode: '4220',
        coordinates: {
            latitude: 55.34799675,
            longitude: 11.02962796,
        },
    },
    {
        name: 'Skælskør',
        zipcode: '4230',
        coordinates: {
            latitude: 55.17717085,
            longitude: 11.34912168,
        },
    },
    {
        name: 'Vemmelev',
        zipcode: '4241',
        coordinates: {
            latitude: 55.36414208,
            longitude: 11.26373834,
        },
    },
    {
        name: 'Boeslunde',
        zipcode: '4242',
        coordinates: {
            latitude: 55.3061459,
            longitude: 11.26828566,
        },
    },
    {
        name: 'Rude',
        zipcode: '4243',
        coordinates: {
            latitude: 55.22423719,
            longitude: 11.46287897,
        },
    },
    {
        name: 'Agersø',
        zipcode: '4244',
        coordinates: {
            latitude: 55.22799697,
            longitude: 11.12804101,
        },
    },
    {
        name: 'Omø',
        zipcode: '4245',
        coordinates: {
            latitude: 55.1182759,
            longitude: 11.15794643,
        },
    },
    {
        name: 'Fuglebjerg',
        zipcode: '4250',
        coordinates: {
            latitude: 55.31864081,
            longitude: 11.55229442,
        },
    },
    {
        name: 'Dalmose',
        zipcode: '4261',
        coordinates: {
            latitude: 55.28221993,
            longitude: 11.42947642,
        },
    },
    {
        name: 'Sandved',
        zipcode: '4262',
        coordinates: {
            latitude: 55.25079677,
            longitude: 11.528121,
        },
    },
    {
        name: 'Høng',
        zipcode: '4270',
        coordinates: {
            latitude: 55.50308551,
            longitude: 11.30548124,
        },
    },
    {
        name: 'Gørlev',
        zipcode: '4281',
        coordinates: {
            latitude: 55.51887316,
            longitude: 11.04711131,
        },
    },
    {
        name: 'Ruds Vedby',
        zipcode: '4291',
        coordinates: {
            latitude: 55.55253436,
            longitude: 11.38504044,
        },
    },
    {
        name: 'Dianalund',
        zipcode: '4293',
        coordinates: {
            latitude: 55.53579978,
            longitude: 11.48228398,
        },
    },
    {
        name: 'Stenlille',
        zipcode: '4295',
        coordinates: {
            latitude: 55.55246779,
            longitude: 11.57749201,
        },
    },
    {
        name: 'Nyrup',
        zipcode: '4296',
        coordinates: {
            latitude: 55.54056312,
            longitude: 11.6485466,
        },
    },
    {
        name: 'Holbæk',
        zipcode: '4300',
        coordinates: {
            latitude: 55.73825064,
            longitude: 11.69985606,
        },
    },
    {
        name: 'Orø',
        zipcode: '4305',
        coordinates: {
            latitude: 55.78240201,
            longitude: 11.80968567,
        },
    },
    {
        name: 'Lejre',
        zipcode: '4320',
        coordinates: {
            latitude: 55.59456942,
            longitude: 11.95696429,
        },
    },
    {
        name: 'Hvalsø',
        zipcode: '4330',
        coordinates: {
            latitude: 55.58583604,
            longitude: 11.86940589,
        },
    },
    {
        name: 'Tølløse',
        zipcode: '4340',
        coordinates: {
            latitude: 55.61818163,
            longitude: 11.74532267,
        },
    },
    {
        name: 'Ugerløse',
        zipcode: '4350',
        coordinates: {
            latitude: 55.59017034,
            longitude: 11.64996808,
        },
    },
    {
        name: 'Kirke Eskilstrup',
        zipcode: '4360',
        coordinates: {
            latitude: 55.56442046,
            longitude: 11.78695601,
        },
    },
    {
        name: 'Store Merløse',
        zipcode: '4370',
        coordinates: {
            latitude: 55.54054576,
            longitude: 11.7241173,
        },
    },
    {
        name: 'Vipperød',
        zipcode: '4390',
        coordinates: {
            latitude: 55.66270448,
            longitude: 11.74969494,
        },
    },
    {
        name: 'Kalundborg',
        zipcode: '4400',
        coordinates: {
            latitude: 55.70601705,
            longitude: 10.97431629,
        },
    },
    {
        name: 'Regstrup',
        zipcode: '4420',
        coordinates: {
            latitude: 55.66048124,
            longitude: 11.60711139,
        },
    },
    {
        name: 'Mørkøv',
        zipcode: '4440',
        coordinates: {
            latitude: 55.62211279,
            longitude: 11.49619782,
        },
    },
    {
        name: 'Jyderup',
        zipcode: '4450',
        coordinates: {
            latitude: 55.61622153,
            longitude: 11.38733175,
        },
    },
    {
        name: 'Snertinge',
        zipcode: '4460',
        coordinates: {
            latitude: 55.6969919,
            longitude: 11.37072627,
        },
    },
    {
        name: 'Svebølle',
        zipcode: '4470',
        coordinates: {
            latitude: 55.65722471,
            longitude: 11.29429706,
        },
    },
    {
        name: 'Store Fuglede',
        zipcode: '4480',
        coordinates: {
            latitude: 55.5741086,
            longitude: 11.15168308,
        },
    },
    {
        name: 'Jerslev Sjælland',
        zipcode: '4490',
        coordinates: {
            latitude: 55.61730235,
            longitude: 11.23183281,
        },
    },
    {
        name: 'Svinninge',
        zipcode: '4520',
        coordinates: {
            latitude: 55.71737311,
            longitude: 11.48951817,
        },
    },
    {
        name: 'Gislinge',
        zipcode: '4532',
        coordinates: {
            latitude: 55.73610011,
            longitude: 11.56410432,
        },
    },
    {
        name: 'Hørve',
        zipcode: '4534',
        coordinates: {
            latitude: 55.76162863,
            longitude: 11.40509,
        },
    },
    {
        name: 'Fårevejle',
        zipcode: '4540',
        coordinates: {
            latitude: 55.85310811,
            longitude: 11.37403038,
        },
    },
    {
        name: 'Asnæs',
        zipcode: '4550',
        coordinates: {
            latitude: 55.81190532,
            longitude: 11.49734325,
        },
    },
    {
        name: 'Vig',
        zipcode: '4560',
        coordinates: {
            latitude: 55.84646728,
            longitude: 11.56036901,
        },
    },
    {
        name: 'Grevinge',
        zipcode: '4571',
        coordinates: {
            latitude: 55.79587455,
            longitude: 11.58697448,
        },
    },
    {
        name: 'Nørre Asmindrup',
        zipcode: '4572',
        coordinates: {
            latitude: 55.88038731,
            longitude: 11.62326767,
        },
    },
    {
        name: 'Højby',
        zipcode: '4573',
        coordinates: {
            latitude: 55.90779403,
            longitude: 11.55659445,
        },
    },
    {
        name: 'Rørvig',
        zipcode: '4581',
        coordinates: {
            latitude: 56.01043371,
            longitude: 11.71725292,
        },
    },
    {
        name: 'Sjællands Odde',
        zipcode: '4583',
        coordinates: {
            latitude: 56.08627395,
            longitude: 11.26204837,
        },
    },
    {
        name: 'Føllenslev',
        zipcode: '4591',
        coordinates: {
            latitude: 55.79702076,
            longitude: 11.27498796,
        },
    },
    {
        name: 'Sejerø',
        zipcode: '4592',
        coordinates: {
            latitude: 55.92069678,
            longitude: 11.03916639,
        },
    },
    {
        name: 'Eskebjerg',
        zipcode: '4593',
        coordinates: {
            latitude: 55.7277303,
            longitude: 11.23692126,
        },
    },
    {
        name: 'Køge',
        zipcode: '4600',
        coordinates: {
            latitude: 55.45527126,
            longitude: 12.22015308,
        },
    },
    {
        name: 'Gadstrup',
        zipcode: '4621',
        coordinates: {
            latitude: 55.5745913,
            longitude: 12.08017443,
        },
    },
    {
        name: 'Havdrup',
        zipcode: '4622',
        coordinates: {
            latitude: 55.54041131,
            longitude: 12.11265454,
        },
    },
    {
        name: 'Lille Skensved',
        zipcode: '4623',
        coordinates: {
            latitude: 55.50779016,
            longitude: 12.08511883,
        },
    },
    {
        name: 'Bjæverskov',
        zipcode: '4632',
        coordinates: {
            latitude: 55.44838852,
            longitude: 12.02691725,
        },
    },
    {
        name: 'Faxe',
        zipcode: '4640',
        coordinates: {
            latitude: 55.2685839,
            longitude: 12.12411652,
        },
    },
    {
        name: 'Hårlev',
        zipcode: '4652',
        coordinates: {
            latitude: 55.36245892,
            longitude: 12.22317915,
        },
    },
    {
        name: 'Karise',
        zipcode: '4653',
        coordinates: {
            latitude: 55.29633252,
            longitude: 12.21696415,
        },
    },
    {
        name: 'Faxe Ladeplads',
        zipcode: '4654',
        coordinates: {
            latitude: 55.19490482,
            longitude: 12.19373813,
        },
    },
    {
        name: 'Store Heddinge',
        zipcode: '4660',
        coordinates: {
            latitude: 55.33554618,
            longitude: 12.51110642,
        },
    },
    {
        name: 'Strøby',
        zipcode: '4671',
        coordinates: {
            latitude: 55.42088958,
            longitude: 12.31112895,
        },
    },
    {
        name: 'Klippinge',
        zipcode: '4672',
        coordinates: {
            latitude: 55.44595315,
            longitude: 12.4342892,
        },
    },
    {
        name: 'Rødvig Stevns',
        zipcode: '4673',
        coordinates: {
            latitude: 55.20085779,
            longitude: 12.38750748,
        },
    },
    {
        name: 'Herfølge',
        zipcode: '4681',
        coordinates: {
            latitude: 55.41715543,
            longitude: 12.14194267,
        },
    },
    {
        name: 'Tureby',
        zipcode: '4682',
        coordinates: {
            latitude: 55.38830546,
            longitude: 12.08719823,
        },
    },
    {
        name: 'Rønnede',
        zipcode: '4683',
        coordinates: {
            latitude: 55.23758668,
            longitude: 12.02277028,
        },
    },
    {
        name: 'Holmegaard',
        zipcode: '4684',
        coordinates: {
            latitude: 55.25979308,
            longitude: 11.86408266,
        },
    },
    {
        name: 'Haslev',
        zipcode: '4690',
        coordinates: {
            latitude: 55.32031014,
            longitude: 11.95519764,
        },
    },
    {
        name: 'Næstved',
        zipcode: '4700',
        coordinates: {
            latitude: 55.19504272,
            longitude: 11.77357353,
        },
    },
    {
        name: 'Præstø',
        zipcode: '4720',
        coordinates: {
            latitude: 55.12208613,
            longitude: 12.19166049,
        },
    },
    {
        name: 'Tappernøje',
        zipcode: '4733',
        coordinates: {
            latitude: 55.18127292,
            longitude: 11.98569733,
        },
    },
    {
        name: 'Mern',
        zipcode: '4735',
        coordinates: {
            latitude: 55.04277496,
            longitude: 12.06283657,
        },
    },
    {
        name: 'Karrebæksminde',
        zipcode: '4736',
        coordinates: {
            latitude: 55.15521424,
            longitude: 11.6361743,
        },
    },
    {
        name: 'Lundby',
        zipcode: '4750',
        coordinates: {
            latitude: 55.10685595,
            longitude: 11.78064612,
        },
    },
    {
        name: 'Vordingborg',
        zipcode: '4760',
        coordinates: {
            latitude: 55.02464842,
            longitude: 11.8960716,
        },
    },
    {
        name: 'Kalvehave',
        zipcode: '4771',
        coordinates: {
            latitude: 55.00889353,
            longitude: 12.15645358,
        },
    },
    {
        name: 'Langebæk',
        zipcode: '4772',
        coordinates: {
            latitude: 54.99961116,
            longitude: 12.08817698,
        },
    },
    {
        name: 'Stensved',
        zipcode: '4773',
        coordinates: {
            latitude: 54.97060588,
            longitude: 12.03439585,
        },
    },
    {
        name: 'Stege',
        zipcode: '4780',
        coordinates: {
            latitude: 54.9362949,
            longitude: 12.31627561,
        },
    },
    {
        name: 'Borre',
        zipcode: '4791',
        coordinates: {
            latitude: 54.97429881,
            longitude: 12.7284169,
        },
    },
    {
        name: 'Askeby',
        zipcode: '4792',
        coordinates: {
            latitude: 54.7375487,
            longitude: 12.52545007,
        },
    },
    {
        name: 'Bogø By',
        zipcode: '4793',
        coordinates: {
            latitude: 54.92382082,
            longitude: 12.05993905,
        },
    },
    {
        name: 'Nykøbing F',
        zipcode: '4800',
        coordinates: {
            latitude: 54.79083949,
            longitude: 11.90585409,
        },
    },
    {
        name: 'Nørre Alslev',
        zipcode: '4840',
        coordinates: {
            latitude: 54.93527293,
            longitude: 11.78722678,
        },
    },
    {
        name: 'Stubbekøbing',
        zipcode: '4850',
        coordinates: {
            latitude: 54.71290684,
            longitude: 12.41175242,
        },
    },
    {
        name: 'Guldborg',
        zipcode: '4862',
        coordinates: {
            latitude: 54.90483893,
            longitude: 11.62528305,
        },
    },
    {
        name: 'Eskilstrup',
        zipcode: '4863',
        coordinates: {
            latitude: 54.86008913,
            longitude: 11.90575722,
        },
    },
    {
        name: 'Horbelev',
        zipcode: '4871',
        coordinates: {
            latitude: 54.69990623,
            longitude: 12.29995982,
        },
    },
    {
        name: 'Idestrup',
        zipcode: '4872',
        coordinates: {
            latitude: 54.70210055,
            longitude: 12.11249782,
        },
    },
    {
        name: 'Væggerløse',
        zipcode: '4873',
        coordinates: {
            latitude: 54.66359732,
            longitude: 11.98479974,
        },
    },
    {
        name: 'Gedser',
        zipcode: '4874',
        coordinates: {
            latitude: 54.49608541,
            longitude: 11.9502463,
        },
    },
    {
        name: 'Nysted',
        zipcode: '4880',
        coordinates: {
            latitude: 54.62941506,
            longitude: 11.74490279,
        },
    },
    {
        name: 'Toreby L',
        zipcode: '4891',
        coordinates: {
            latitude: 54.7578349,
            longitude: 11.77556621,
        },
    },
    {
        name: 'Kettinge',
        zipcode: '4892',
        coordinates: {
            latitude: 54.70725618,
            longitude: 11.74606046,
        },
    },
    {
        name: 'Øster Ulslev',
        zipcode: '4894',
        coordinates: {
            latitude: 54.68554952,
            longitude: 11.60726017,
        },
    },
    {
        name: 'Errindlev',
        zipcode: '4895',
        coordinates: {
            latitude: 54.63787516,
            longitude: 11.53487222,
        },
    },
    {
        name: 'Nakskov',
        zipcode: '4900',
        coordinates: {
            latitude: 54.75748092,
            longitude: 10.98835691,
        },
    },
    {
        name: 'Harpelunde',
        zipcode: '4912',
        coordinates: {
            latitude: 54.90510445,
            longitude: 10.98695965,
        },
    },
    {
        name: 'Horslunde',
        zipcode: '4913',
        coordinates: {
            latitude: 54.96838447,
            longitude: 11.20918569,
        },
    },
    {
        name: 'Søllested',
        zipcode: '4920',
        coordinates: {
            latitude: 54.79935911,
            longitude: 11.29197531,
        },
    },
    {
        name: 'Maribo',
        zipcode: '4930',
        coordinates: {
            latitude: 54.76659931,
            longitude: 11.49815546,
        },
    },
    {
        name: 'Bandholm',
        zipcode: '4941',
        coordinates: {
            latitude: 54.83767284,
            longitude: 11.49059609,
        },
    },
    {
        name: 'Askø',
        zipcode: '4942',
        coordinates: {
            latitude: 54.89908661,
            longitude: 11.5111678,
        },
    },
    {
        name: 'Torrig L',
        zipcode: '4943',
        coordinates: {
            latitude: 55.05662783,
            longitude: 11.36808859,
        },
    },
    {
        name: 'Fejø',
        zipcode: '4944',
        coordinates: {
            latitude: 54.95857211,
            longitude: 11.4069268,
        },
    },
    {
        name: 'Femø',
        zipcode: '4945',
        coordinates: {
            latitude: 54.9844243,
            longitude: 11.56847203,
        },
    },
    {
        name: 'Nørreballe',
        zipcode: '4951',
        coordinates: {
            latitude: 54.81506841,
            longitude: 11.4281128,
        },
    },
    {
        name: 'Stokkemarke',
        zipcode: '4952',
        coordinates: {
            latitude: 54.84533107,
            longitude: 11.37909951,
        },
    },
    {
        name: 'Vesterborg',
        zipcode: '4953',
        coordinates: {
            latitude: 54.863378,
            longitude: 11.28637518,
        },
    },
    {
        name: 'Holeby',
        zipcode: '4960',
        coordinates: {
            latitude: 54.70172856,
            longitude: 11.53543477,
        },
    },
    {
        name: 'Rødby',
        zipcode: '4970',
        coordinates: {
            latitude: 54.53635729,
            longitude: 11.57693074,
        },
    },
    {
        name: 'Dannemare',
        zipcode: '4983',
        coordinates: {
            latitude: 54.71726169,
            longitude: 11.17254811,
        },
    },
    {
        name: 'Sakskøbing',
        zipcode: '4990',
        coordinates: {
            latitude: 54.8143415,
            longitude: 11.63568865,
        },
    },
    {
        name: 'Odense C',
        zipcode: '5000',
        coordinates: {
            latitude: 55.403021,
            longitude: 10.39174411,
        },
    },
    {
        name: 'Odense V',
        zipcode: '5200',
        coordinates: {
            latitude: 55.39401435,
            longitude: 10.32843174,
        },
    },
    {
        name: 'Odense NV',
        zipcode: '5210',
        coordinates: {
            latitude: 55.41754658,
            longitude: 10.30695242,
        },
    },
    {
        name: 'Odense SØ',
        zipcode: '5220',
        coordinates: {
            latitude: 55.34929575,
            longitude: 10.49825565,
        },
    },
    {
        name: 'Odense M',
        zipcode: '5230',
        coordinates: {
            latitude: 55.37859541,
            longitude: 10.4114235,
        },
    },
    {
        name: 'Odense NØ',
        zipcode: '5240',
        coordinates: {
            latitude: 55.42123207,
            longitude: 10.44332209,
        },
    },
    {
        name: 'Odense SV',
        zipcode: '5250',
        coordinates: {
            latitude: 55.35251254,
            longitude: 10.29791607,
        },
    },
    {
        name: 'Odense S',
        zipcode: '5260',
        coordinates: {
            latitude: 55.34939755,
            longitude: 10.39917484,
        },
    },
    {
        name: 'Odense N',
        zipcode: '5270',
        coordinates: {
            latitude: 55.44966319,
            longitude: 10.35714765,
        },
    },
    {
        name: 'Marslev',
        zipcode: '5290',
        coordinates: {
            latitude: 55.396433,
            longitude: 10.53251585,
        },
    },
    {
        name: 'Kerteminde',
        zipcode: '5300',
        coordinates: {
            latitude: 55.49807432,
            longitude: 10.8508032,
        },
    },
    {
        name: 'Agedrup',
        zipcode: '5320',
        coordinates: {
            latitude: 55.43442698,
            longitude: 10.48909454,
        },
    },
    {
        name: 'Munkebo',
        zipcode: '5330',
        coordinates: {
            latitude: 55.46833207,
            longitude: 10.53070411,
        },
    },
    {
        name: 'Rynkeby',
        zipcode: '5350',
        coordinates: {
            latitude: 55.40654304,
            longitude: 10.62525952,
        },
    },
    {
        name: 'Mesinge',
        zipcode: '5370',
        coordinates: {
            latitude: 55.48986232,
            longitude: 10.64470255,
        },
    },
    {
        name: 'Dalby',
        zipcode: '5380',
        coordinates: {
            latitude: 55.51715531,
            longitude: 10.7017613,
        },
    },
    {
        name: 'Martofte',
        zipcode: '5390',
        coordinates: {
            latitude: 55.61575822,
            longitude: 10.65770823,
        },
    },
    {
        name: 'Bogense',
        zipcode: '5400',
        coordinates: {
            latitude: 55.6129967,
            longitude: 10.15483531,
        },
    },
    {
        name: 'Otterup',
        zipcode: '5450',
        coordinates: {
            latitude: 55.56662852,
            longitude: 10.41038685,
        },
    },
    {
        name: 'Morud',
        zipcode: '5462',
        coordinates: {
            latitude: 55.43415758,
            longitude: 10.19234031,
        },
    },
    {
        name: 'Harndrup',
        zipcode: '5463',
        coordinates: {
            latitude: 55.45477943,
            longitude: 10.02916475,
        },
    },
    {
        name: 'Brenderup Fyn',
        zipcode: '5464',
        coordinates: {
            latitude: 55.49127877,
            longitude: 9.98162512,
        },
    },
    {
        name: 'Asperup',
        zipcode: '5466',
        coordinates: {
            latitude: 55.50180178,
            longitude: 9.90219075,
        },
    },
    {
        name: 'Søndersø',
        zipcode: '5471',
        coordinates: {
            latitude: 55.48691259,
            longitude: 10.08371535,
        },
    },
    {
        name: 'Veflinge',
        zipcode: '5474',
        coordinates: {
            latitude: 55.46255575,
            longitude: 10.14425747,
        },
    },
    {
        name: 'Skamby',
        zipcode: '5485',
        coordinates: {
            latitude: 55.51969426,
            longitude: 10.26859858,
        },
    },
    {
        name: 'Blommenslyst',
        zipcode: '5491',
        coordinates: {
            latitude: 55.38883518,
            longitude: 10.22531693,
        },
    },
    {
        name: 'Vissenbjerg',
        zipcode: '5492',
        coordinates: {
            latitude: 55.39228422,
            longitude: 10.13229762,
        },
    },
    {
        name: 'Middelfart',
        zipcode: '5500',
        coordinates: {
            latitude: 55.48934885,
            longitude: 9.78489314,
        },
    },
    {
        name: 'Ullerslev',
        zipcode: '5540',
        coordinates: {
            latitude: 55.38540272,
            longitude: 10.67264084,
        },
    },
    {
        name: 'Langeskov',
        zipcode: '5550',
        coordinates: {
            latitude: 55.36673317,
            longitude: 10.58947313,
        },
    },
    {
        name: 'Aarup',
        zipcode: '5560',
        coordinates: {
            latitude: 55.37369367,
            longitude: 10.03851749,
        },
    },
    {
        name: 'Nørre Aaby',
        zipcode: '5580',
        coordinates: {
            latitude: 55.41549178,
            longitude: 9.77417672,
        },
    },
    {
        name: 'Gelsted',
        zipcode: '5591',
        coordinates: {
            latitude: 55.39954114,
            longitude: 9.96614107,
        },
    },
    {
        name: 'Ejby',
        zipcode: '5592',
        coordinates: {
            latitude: 55.36518649,
            longitude: 9.82415972,
        },
    },
    {
        name: 'Faaborg',
        zipcode: '5600',
        coordinates: {
            latitude: 55.11203154,
            longitude: 10.30952407,
        },
    },
    {
        name: 'Lyø',
        zipcode: '5601',
        coordinates: {
            latitude: 55.02895047,
            longitude: 10.1289297,
        },
    },
    {
        name: 'Avernakø',
        zipcode: '5602',
        coordinates: {
            latitude: 55.00930386,
            longitude: 10.2827681,
        },
    },
    {
        name: 'Bjørnø',
        zipcode: '5603',
        coordinates: {
            latitude: 55.06201633,
            longitude: 10.25360285,
        },
    },
    {
        name: 'Assens',
        zipcode: '5610',
        coordinates: {
            latitude: 55.29165859,
            longitude: 9.8932785,
        },
    },
    {
        name: 'Glamsbjerg',
        zipcode: '5620',
        coordinates: {
            latitude: 55.27213799,
            longitude: 10.10343663,
        },
    },
    {
        name: 'Ebberup',
        zipcode: '5631',
        coordinates: {
            latitude: 55.13177189,
            longitude: 9.96382534,
        },
    },
    {
        name: 'Millinge',
        zipcode: '5642',
        coordinates: {
            latitude: 55.15062316,
            longitude: 10.12933606,
        },
    },
    {
        name: 'Broby',
        zipcode: '5672',
        coordinates: {
            latitude: 55.24506199,
            longitude: 10.25211529,
        },
    },
    {
        name: 'Haarby',
        zipcode: '5683',
        coordinates: {
            latitude: 55.18924968,
            longitude: 10.07032627,
        },
    },
    {
        name: 'Tommerup',
        zipcode: '5690',
        coordinates: {
            latitude: 55.3195544,
            longitude: 10.19834407,
        },
    },
    {
        name: 'Svendborg',
        zipcode: '5700',
        coordinates: {
            latitude: 55.01443666,
            longitude: 10.59802878,
        },
    },
    {
        name: 'Ringe',
        zipcode: '5750',
        coordinates: {
            latitude: 55.2209888,
            longitude: 10.42313811,
        },
    },
    {
        name: 'Vester Skerninge',
        zipcode: '5762',
        coordinates: {
            latitude: 55.06342876,
            longitude: 10.45011017,
        },
    },
    {
        name: 'Stenstrup',
        zipcode: '5771',
        coordinates: {
            latitude: 55.12556284,
            longitude: 10.52352287,
        },
    },
    {
        name: 'Kværndrup',
        zipcode: '5772',
        coordinates: {
            latitude: 55.18424637,
            longitude: 10.54250568,
        },
    },
    {
        name: 'Årslev',
        zipcode: '5792',
        coordinates: {
            latitude: 55.2906707,
            longitude: 10.3936597,
        },
    },
    {
        name: 'Nyborg',
        zipcode: '5800',
        coordinates: {
            latitude: 55.35301317,
            longitude: 10.79790443,
        },
    },
    {
        name: 'Ørbæk',
        zipcode: '5853',
        coordinates: {
            latitude: 55.2661035,
            longitude: 10.63473931,
        },
    },
    {
        name: 'Gislev',
        zipcode: '5854',
        coordinates: {
            latitude: 55.2122797,
            longitude: 10.60886979,
        },
    },
    {
        name: 'Ryslinge',
        zipcode: '5856',
        coordinates: {
            latitude: 55.23725238,
            longitude: 10.54368086,
        },
    },
    {
        name: 'Ferritslev Fyn',
        zipcode: '5863',
        coordinates: {
            latitude: 55.31045491,
            longitude: 10.58601195,
        },
    },
    {
        name: 'Frørup',
        zipcode: '5871',
        coordinates: {
            latitude: 55.23864739,
            longitude: 10.81307692,
        },
    },
    {
        name: 'Hesselager',
        zipcode: '5874',
        coordinates: {
            latitude: 55.17539817,
            longitude: 10.79995852,
        },
    },
    {
        name: 'Skårup Fyn',
        zipcode: '5881',
        coordinates: {
            latitude: 55.06735111,
            longitude: 10.76263715,
        },
    },
    {
        name: 'Vejstrup',
        zipcode: '5882',
        coordinates: {
            latitude: 55.09510217,
            longitude: 10.79980106,
        },
    },
    {
        name: 'Oure',
        zipcode: '5883',
        coordinates: {
            latitude: 55.11784585,
            longitude: 10.77337328,
        },
    },
    {
        name: 'Gudme',
        zipcode: '5884',
        coordinates: {
            latitude: 55.1496317,
            longitude: 10.7120614,
        },
    },
    {
        name: 'Gudbjerg Sydfyn',
        zipcode: '5892',
        coordinates: {
            latitude: 55.15512414,
            longitude: 10.63638217,
        },
    },
    {
        name: 'Rudkøbing',
        zipcode: '5900',
        coordinates: {
            latitude: 54.88518842,
            longitude: 10.80968538,
        },
    },
    {
        name: 'Humble',
        zipcode: '5932',
        coordinates: {
            latitude: 54.81821541,
            longitude: 10.63436277,
        },
    },
    {
        name: 'Bagenkop',
        zipcode: '5935',
        coordinates: {
            latitude: 54.65753168,
            longitude: 10.66497262,
        },
    },
    {
        name: 'Strynø',
        zipcode: '5943',
        coordinates: {
            latitude: 54.90089651,
            longitude: 10.58807706,
        },
    },
    {
        name: 'Tranekær',
        zipcode: '5953',
        coordinates: {
            latitude: 55.06361655,
            longitude: 10.94268354,
        },
    },
    {
        name: 'Marstal',
        zipcode: '5960',
        coordinates: {
            latitude: 54.85672022,
            longitude: 10.51672958,
        },
    },
    {
        name: 'Birkholm',
        zipcode: '5965',
        coordinates: {
            latitude: 54.9345412,
            longitude: 10.50923834,
        },
    },
    {
        name: 'Ærøskøbing',
        zipcode: '5970',
        coordinates: {
            latitude: 54.79716403,
            longitude: 10.33601694,
        },
    },
    {
        name: 'Søby Ærø',
        zipcode: '5985',
        coordinates: {
            latitude: 54.94375791,
            longitude: 10.21809442,
        },
    },
    {
        name: 'Kolding',
        zipcode: '6000',
        coordinates: {
            latitude: 55.50221297,
            longitude: 9.46847631,
        },
    },
    {
        name: 'Egtved',
        zipcode: '6040',
        coordinates: {
            latitude: 55.61664144,
            longitude: 9.29089234,
        },
    },
    {
        name: 'Almind',
        zipcode: '6051',
        coordinates: {
            latitude: 55.55907816,
            longitude: 9.46401838,
        },
    },
    {
        name: 'Viuf',
        zipcode: '6052',
        coordinates: {
            latitude: 55.58677181,
            longitude: 9.49835852,
        },
    },
    {
        name: 'Jordrup',
        zipcode: '6064',
        coordinates: {
            latitude: 55.55380577,
            longitude: 9.3183142,
        },
    },
    {
        name: 'Christiansfeld',
        zipcode: '6070',
        coordinates: {
            latitude: 55.35487274,
            longitude: 9.44750863,
        },
    },
    {
        name: 'Bjert',
        zipcode: '6091',
        coordinates: {
            latitude: 55.44548525,
            longitude: 9.55990342,
        },
    },
    {
        name: 'Sønder Stenderup',
        zipcode: '6092',
        coordinates: {
            latitude: 55.44711912,
            longitude: 9.65720072,
        },
    },
    {
        name: 'Sjølund',
        zipcode: '6093',
        coordinates: {
            latitude: 55.4068472,
            longitude: 9.53828204,
        },
    },
    {
        name: 'Hejls',
        zipcode: '6094',
        coordinates: {
            latitude: 55.38893478,
            longitude: 9.62026417,
        },
    },
    {
        name: 'Haderslev',
        zipcode: '6100',
        coordinates: {
            latitude: 55.24192755,
            longitude: 9.61996216,
        },
    },
    {
        name: 'Aabenraa',
        zipcode: '6200',
        coordinates: {
            latitude: 55.02899493,
            longitude: 9.49975045,
        },
    },
    {
        name: 'Barsø',
        zipcode: '6210',
        coordinates: {
            latitude: 55.1226369,
            longitude: 9.58941416,
        },
    },
    {
        name: 'Rødekro',
        zipcode: '6230',
        coordinates: {
            latitude: 55.07437317,
            longitude: 9.28037618,
        },
    },
    {
        name: 'Løgumkloster',
        zipcode: '6240',
        coordinates: {
            latitude: 55.06237954,
            longitude: 8.96415413,
        },
    },
    {
        name: 'Bredebro',
        zipcode: '6261',
        coordinates: {
            latitude: 55.07311357,
            longitude: 8.68091354,
        },
    },
    {
        name: 'Tønder',
        zipcode: '6270',
        coordinates: {
            latitude: 54.95248429,
            longitude: 8.89453488,
        },
    },
    {
        name: 'Højer',
        zipcode: '6280',
        coordinates: {
            latitude: 54.97473424,
            longitude: 8.65269961,
        },
    },
    {
        name: 'Gråsten',
        zipcode: '6300',
        coordinates: {
            latitude: 54.91730187,
            longitude: 9.55791076,
        },
    },
    {
        name: 'Broager',
        zipcode: '6310',
        coordinates: {
            latitude: 54.8713804,
            longitude: 9.68079394,
        },
    },
    {
        name: 'Egernsund',
        zipcode: '6320',
        coordinates: {
            latitude: 54.90003298,
            longitude: 9.61059297,
        },
    },
    {
        name: 'Padborg',
        zipcode: '6330',
        coordinates: {
            latitude: 54.84549059,
            longitude: 9.30983473,
        },
    },
    {
        name: 'Kruså',
        zipcode: '6340',
        coordinates: {
            latitude: 54.8687306,
            longitude: 9.46328593,
        },
    },
    {
        name: 'Tinglev',
        zipcode: '6360',
        coordinates: {
            latitude: 54.90310801,
            longitude: 9.20354055,
        },
    },
    {
        name: 'Bylderup-Bov',
        zipcode: '6372',
        coordinates: {
            latitude: 55.01708464,
            longitude: 9.14165594,
        },
    },
    {
        name: 'Bolderslev',
        zipcode: '6392',
        coordinates: {
            latitude: 54.9843364,
            longitude: 9.20811159,
        },
    },
    {
        name: 'Sønderborg',
        zipcode: '6400',
        coordinates: {
            latitude: 54.9528525,
            longitude: 9.71079556,
        },
    },
    {
        name: 'Nordborg',
        zipcode: '6430',
        coordinates: {
            latitude: 55.06940698,
            longitude: 9.77014411,
        },
    },
    {
        name: 'Augustenborg',
        zipcode: '6440',
        coordinates: {
            latitude: 54.98937381,
            longitude: 10.00776445,
        },
    },
    {
        name: 'Sydals',
        zipcode: '6470',
        coordinates: {
            latitude: 54.87308589,
            longitude: 10.03587811,
        },
    },
    {
        name: 'Vojens',
        zipcode: '6500',
        coordinates: {
            latitude: 55.2436827,
            longitude: 9.31835235,
        },
    },
    {
        name: 'Gram',
        zipcode: '6510',
        coordinates: {
            latitude: 55.27645397,
            longitude: 9.01601909,
        },
    },
    {
        name: 'Toftlund',
        zipcode: '6520',
        coordinates: {
            latitude: 55.18306206,
            longitude: 9.0451996,
        },
    },
    {
        name: 'Agerskov',
        zipcode: '6534',
        coordinates: {
            latitude: 55.12832485,
            longitude: 9.15854853,
        },
    },
    {
        name: 'Branderup J',
        zipcode: '6535',
        coordinates: {
            latitude: 55.12085206,
            longitude: 9.05916508,
        },
    },
    {
        name: 'Bevtoft',
        zipcode: '6541',
        coordinates: {
            latitude: 55.19588025,
            longitude: 9.19750089,
        },
    },
    {
        name: 'Sommersted',
        zipcode: '6560',
        coordinates: {
            latitude: 55.32447339,
            longitude: 9.26072776,
        },
    },
    {
        name: 'Vamdrup',
        zipcode: '6580',
        coordinates: {
            latitude: 55.41366579,
            longitude: 9.34398711,
        },
    },
    {
        name: 'Vejen',
        zipcode: '6600',
        coordinates: {
            latitude: 55.4784284,
            longitude: 9.11204215,
        },
    },
    {
        name: 'Gesten',
        zipcode: '6621',
        coordinates: {
            latitude: 55.53607493,
            longitude: 9.1837296,
        },
    },
    {
        name: 'Bække',
        zipcode: '6622',
        coordinates: {
            latitude: 55.58270689,
            longitude: 9.15666611,
        },
    },
    {
        name: 'Vorbasse',
        zipcode: '6623',
        coordinates: {
            latitude: 55.63499139,
            longitude: 9.09958348,
        },
    },
    {
        name: 'Rødding',
        zipcode: '6630',
        coordinates: {
            latitude: 55.38472069,
            longitude: 9.14057169,
        },
    },
    {
        name: 'Lunderskov',
        zipcode: '6640',
        coordinates: {
            latitude: 55.4763505,
            longitude: 9.32882435,
        },
    },
    {
        name: 'Brørup',
        zipcode: '6650',
        coordinates: {
            latitude: 55.49880374,
            longitude: 8.99731666,
        },
    },
    {
        name: 'Lintrup',
        zipcode: '6660',
        coordinates: {
            latitude: 55.41777362,
            longitude: 8.99575864,
        },
    },
    {
        name: 'Holsted',
        zipcode: '6670',
        coordinates: {
            latitude: 55.51123398,
            longitude: 8.90149179,
        },
    },
    {
        name: 'Hovborg',
        zipcode: '6682',
        coordinates: {
            latitude: 55.60367953,
            longitude: 8.94671137,
        },
    },
    {
        name: 'Føvling',
        zipcode: '6683',
        coordinates: {
            latitude: 55.43530414,
            longitude: 8.90966334,
        },
    },
    {
        name: 'Gørding',
        zipcode: '6690',
        coordinates: {
            latitude: 55.4813413,
            longitude: 8.82201058,
        },
    },
    {
        name: 'Esbjerg',
        zipcode: '6700',
        coordinates: {
            latitude: 55.45935717,
            longitude: 8.46174114,
        },
    },
    {
        name: 'Esbjerg Ø',
        zipcode: '6705',
        coordinates: {
            latitude: 55.49119455,
            longitude: 8.50670361,
        },
    },
    {
        name: 'Esbjerg V',
        zipcode: '6710',
        coordinates: {
            latitude: 55.55262453,
            longitude: 8.34866677,
        },
    },
    {
        name: 'Esbjerg N',
        zipcode: '6715',
        coordinates: {
            latitude: 55.55332885,
            longitude: 8.5093547,
        },
    },
    {
        name: 'Fanø',
        zipcode: '6720',
        coordinates: {
            latitude: 55.28286185,
            longitude: 7.56593986,
        },
    },
    {
        name: 'Tjæreborg',
        zipcode: '6731',
        coordinates: {
            latitude: 55.46029975,
            longitude: 8.59046906,
        },
    },
    {
        name: 'Bramming',
        zipcode: '6740',
        coordinates: {
            latitude: 55.46065157,
            longitude: 8.70168486,
        },
    },
    {
        name: 'Glejbjerg',
        zipcode: '6752',
        coordinates: {
            latitude: 55.55973335,
            longitude: 8.83181793,
        },
    },
    {
        name: 'Agerbæk',
        zipcode: '6753',
        coordinates: {
            latitude: 55.60852142,
            longitude: 8.82421529,
        },
    },
    {
        name: 'Ribe',
        zipcode: '6760',
        coordinates: {
            latitude: 55.31137673,
            longitude: 8.76358629,
        },
    },
    {
        name: 'Gredstedbro',
        zipcode: '6771',
        coordinates: {
            latitude: 55.37115323,
            longitude: 8.60307494,
        },
    },
    {
        name: 'Skærbæk',
        zipcode: '6780',
        coordinates: {
            latitude: 55.17738587,
            longitude: 8.79091021,
        },
    },
    {
        name: 'Rømø',
        zipcode: '6792',
        coordinates: {
            latitude: 55.14881868,
            longitude: 8.29013955,
        },
    },
    {
        name: 'Varde',
        zipcode: '6800',
        coordinates: {
            latitude: 55.65607154,
            longitude: 8.52854473,
        },
    },
    {
        name: 'Årre',
        zipcode: '6818',
        coordinates: {
            latitude: 55.57688969,
            longitude: 8.71514349,
        },
    },
    {
        name: 'Ansager',
        zipcode: '6823',
        coordinates: {
            latitude: 55.72123589,
            longitude: 8.75126746,
        },
    },
    {
        name: 'Nørre Nebel',
        zipcode: '6830',
        coordinates: {
            latitude: 55.7873441,
            longitude: 8.22547945,
        },
    },
    {
        name: 'Oksbøl',
        zipcode: '6840',
        coordinates: {
            latitude: 55.63843566,
            longitude: 8.2413214,
        },
    },
    {
        name: 'Janderup Vestj',
        zipcode: '6851',
        coordinates: {
            latitude: 55.63374455,
            longitude: 8.38971082,
        },
    },
    {
        name: 'Billum',
        zipcode: '6852',
        coordinates: {
            latitude: 55.59542013,
            longitude: 8.306516,
        },
    },
    {
        name: 'Vejers Strand',
        zipcode: '6853',
        coordinates: {
            latitude: 55.43289751,
            longitude: 5.24215492,
        },
    },
    {
        name: 'Henne',
        zipcode: '6854',
        coordinates: {
            latitude: 55.71982269,
            longitude: 4.43594447,
        },
    },
    {
        name: 'Outrup',
        zipcode: '6855',
        coordinates: {
            latitude: 55.70803366,
            longitude: 8.37405373,
        },
    },
    {
        name: 'Blåvand',
        zipcode: '6857',
        coordinates: {
            latitude: 55.45206547,
            longitude: 6.6069035,
        },
    },
    {
        name: 'Tistrup',
        zipcode: '6862',
        coordinates: {
            latitude: 55.71896752,
            longitude: 8.63263378,
        },
    },
    {
        name: 'Ølgod',
        zipcode: '6870',
        coordinates: {
            latitude: 55.79347704,
            longitude: 8.61815877,
        },
    },
    {
        name: 'Tarm',
        zipcode: '6880',
        coordinates: {
            latitude: 55.89016003,
            longitude: 8.54104706,
        },
    },
    {
        name: 'Hemmet',
        zipcode: '6893',
        coordinates: {
            latitude: 55.85259869,
            longitude: 8.33561789,
        },
    },
    {
        name: 'Skjern',
        zipcode: '6900',
        coordinates: {
            latitude: 55.99089664,
            longitude: 8.53885308,
        },
    },
    {
        name: 'Videbæk',
        zipcode: '6920',
        coordinates: {
            latitude: 56.08052418,
            longitude: 8.66992988,
        },
    },
    {
        name: 'Kibæk',
        zipcode: '6933',
        coordinates: {
            latitude: 55.94883412,
            longitude: 8.92947696,
        },
    },
    {
        name: 'Lem St',
        zipcode: '6940',
        coordinates: {
            latitude: 56.05110041,
            longitude: 8.42316735,
        },
    },
    {
        name: 'Ringkøbing',
        zipcode: '6950',
        coordinates: {
            latitude: 56.20098497,
            longitude: 4.07461311,
        },
    },
    {
        name: 'Hvide Sande',
        zipcode: '6960',
        coordinates: {
            latitude: 55.93212987,
            longitude: 4.28511819,
        },
    },
    {
        name: 'Spjald',
        zipcode: '6971',
        coordinates: {
            latitude: 56.15361133,
            longitude: 8.50600358,
        },
    },
    {
        name: 'Ørnhøj',
        zipcode: '6973',
        coordinates: {
            latitude: 56.21518023,
            longitude: 8.52448598,
        },
    },
    {
        name: 'Tim',
        zipcode: '6980',
        coordinates: {
            latitude: 56.18956069,
            longitude: 8.19684723,
        },
    },
    {
        name: 'Ulfborg',
        zipcode: '6990',
        coordinates: {
            latitude: 56.3042395,
            longitude: 8.0070526,
        },
    },
    {
        name: 'Fredericia',
        zipcode: '7000',
        coordinates: {
            latitude: 55.57103757,
            longitude: 9.66918005,
        },
    },
    {
        name: 'Børkop',
        zipcode: '7080',
        coordinates: {
            latitude: 55.65719788,
            longitude: 9.67048857,
        },
    },
    {
        name: 'Vejle',
        zipcode: '7100',
        coordinates: {
            latitude: 55.69052913,
            longitude: 9.5066267,
        },
    },
    {
        name: 'Vejle Øst',
        zipcode: '7120',
        coordinates: {
            latitude: 55.7220274,
            longitude: 9.63873738,
        },
    },
    {
        name: 'Juelsminde',
        zipcode: '7130',
        coordinates: {
            latitude: 55.76046689,
            longitude: 10.02918372,
        },
    },
    {
        name: 'Stouby',
        zipcode: '7140',
        coordinates: {
            latitude: 55.69215915,
            longitude: 9.78417201,
        },
    },
    {
        name: 'Barrit',
        zipcode: '7150',
        coordinates: {
            latitude: 55.68695015,
            longitude: 9.8978292,
        },
    },
    {
        name: 'Tørring',
        zipcode: '7160',
        coordinates: {
            latitude: 55.87483844,
            longitude: 9.50220604,
        },
    },
    {
        name: 'Uldum',
        zipcode: '7171',
        coordinates: {
            latitude: 55.8358153,
            longitude: 9.59518771,
        },
    },
    {
        name: 'Vonge',
        zipcode: '7173',
        coordinates: {
            latitude: 55.86467774,
            longitude: 9.42328648,
        },
    },
    {
        name: 'Bredsten',
        zipcode: '7182',
        coordinates: {
            latitude: 55.70610256,
            longitude: 9.36512374,
        },
    },
    {
        name: 'Randbøl',
        zipcode: '7183',
        coordinates: {
            latitude: 55.67810764,
            longitude: 9.26919785,
        },
    },
    {
        name: 'Vandel',
        zipcode: '7184',
        coordinates: {
            latitude: 55.71507833,
            longitude: 9.19531768,
        },
    },
    {
        name: 'Billund',
        zipcode: '7190',
        coordinates: {
            latitude: 55.73792043,
            longitude: 9.10071489,
        },
    },
    {
        name: 'Grindsted',
        zipcode: '7200',
        coordinates: {
            latitude: 55.75986358,
            longitude: 8.89887879,
        },
    },
    {
        name: 'Hejnsvig',
        zipcode: '7250',
        coordinates: {
            latitude: 55.69122218,
            longitude: 8.96462269,
        },
    },
    {
        name: 'Sønder Omme',
        zipcode: '7260',
        coordinates: {
            latitude: 55.85051521,
            longitude: 8.89341393,
        },
    },
    {
        name: 'Stakroge',
        zipcode: '7270',
        coordinates: {
            latitude: 55.89268974,
            longitude: 8.82814289,
        },
    },
    {
        name: 'Sønder Felding',
        zipcode: '7280',
        coordinates: {
            latitude: 55.94233735,
            longitude: 8.80469755,
        },
    },
    {
        name: 'Jelling',
        zipcode: '7300',
        coordinates: {
            latitude: 55.76853547,
            longitude: 9.43589642,
        },
    },
    {
        name: 'Gadbjerg',
        zipcode: '7321',
        coordinates: {
            latitude: 55.77031641,
            longitude: 9.31066038,
        },
    },
    {
        name: 'Give',
        zipcode: '7323',
        coordinates: {
            latitude: 55.85744681,
            longitude: 9.2710166,
        },
    },
    {
        name: 'Brande',
        zipcode: '7330',
        coordinates: {
            latitude: 55.93341595,
            longitude: 9.0912202,
        },
    },
    {
        name: 'Ejstrupholm',
        zipcode: '7361',
        coordinates: {
            latitude: 56.02375436,
            longitude: 9.24826265,
        },
    },
    {
        name: 'Hampen',
        zipcode: '7362',
        coordinates: {
            latitude: 56.06515415,
            longitude: 9.38166979,
        },
    },
    {
        name: 'Herning',
        zipcode: '7400',
        coordinates: {
            latitude: 56.11911505,
            longitude: 8.98775376,
        },
    },
    {
        name: 'Ikast',
        zipcode: '7430',
        coordinates: {
            latitude: 56.09377094,
            longitude: 9.1925469,
        },
    },
    {
        name: 'Bording',
        zipcode: '7441',
        coordinates: {
            latitude: 56.15034765,
            longitude: 9.25470923,
        },
    },
    {
        name: 'Engesvang',
        zipcode: '7442',
        coordinates: {
            latitude: 56.21005036,
            longitude: 9.30391703,
        },
    },
    {
        name: 'Sunds',
        zipcode: '7451',
        coordinates: {
            latitude: 56.22960774,
            longitude: 9.02585964,
        },
    },
    {
        name: 'Karup J',
        zipcode: '7470',
        coordinates: {
            latitude: 56.32799021,
            longitude: 9.17419358,
        },
    },
    {
        name: 'Vildbjerg',
        zipcode: '7480',
        coordinates: {
            latitude: 56.18215286,
            longitude: 8.74507312,
        },
    },
    {
        name: 'Aulum',
        zipcode: '7490',
        coordinates: {
            latitude: 56.2699576,
            longitude: 8.79948064,
        },
    },
    {
        name: 'Holstebro',
        zipcode: '7500',
        coordinates: {
            latitude: 56.34794414,
            longitude: 8.58519299,
        },
    },
    {
        name: 'Haderup',
        zipcode: '7540',
        coordinates: {
            latitude: 56.32444075,
            longitude: 8.9740962,
        },
    },
    {
        name: 'Sørvad',
        zipcode: '7550',
        coordinates: {
            latitude: 56.25194401,
            longitude: 8.66525292,
        },
    },
    {
        name: 'Hjerm',
        zipcode: '7560',
        coordinates: {
            latitude: 56.43288826,
            longitude: 8.64900967,
        },
    },
    {
        name: 'Vemb',
        zipcode: '7570',
        coordinates: {
            latitude: 56.35236537,
            longitude: 8.27244636,
        },
    },
    {
        name: 'Struer',
        zipcode: '7600',
        coordinates: {
            latitude: 56.52775554,
            longitude: 8.60339354,
        },
    },
    {
        name: 'Lemvig',
        zipcode: '7620',
        coordinates: {
            latitude: 56.54580784,
            longitude: 8.33453584,
        },
    },
    {
        name: 'Bøvlingbjerg',
        zipcode: '7650',
        coordinates: {
            latitude: 56.54689645,
            longitude: 5.04556549,
        },
    },
    {
        name: 'Bækmarksbro',
        zipcode: '7660',
        coordinates: {
            latitude: 56.41551889,
            longitude: 8.33184097,
        },
    },
    {
        name: 'Harboøre',
        zipcode: '7673',
        coordinates: {
            latitude: 56.62870473,
            longitude: 8.06400269,
        },
    },
    {
        name: 'Thyborøn',
        zipcode: '7680',
        coordinates: {
            latitude: 56.86776362,
            longitude: 6.26580665,
        },
    },
    {
        name: 'Thisted',
        zipcode: '7700',
        coordinates: {
            latitude: 57.32370377,
            longitude: 7.90681299,
        },
    },
    {
        name: 'Hanstholm',
        zipcode: '7730',
        coordinates: {
            latitude: 57.44347291,
            longitude: 8.33928238,
        },
    },
    {
        name: 'Frøstrup',
        zipcode: '7741',
        coordinates: {
            latitude: 57.39912576,
            longitude: 8.9170332,
        },
    },
    {
        name: 'Vesløs',
        zipcode: '7742',
        coordinates: {
            latitude: 57.00570708,
            longitude: 9.03058677,
        },
    },
    {
        name: 'Snedsted',
        zipcode: '7752',
        coordinates: {
            latitude: 57.2597249,
            longitude: 7.47858654,
        },
    },
    {
        name: 'Bedsted Thy',
        zipcode: '7755',
        coordinates: {
            latitude: 57.17740891,
            longitude: 7.21613935,
        },
    },
    {
        name: 'Hurup Thy',
        zipcode: '7760',
        coordinates: {
            latitude: 56.72425361,
            longitude: 8.42909599,
        },
    },
    {
        name: 'Vestervig',
        zipcode: '7770',
        coordinates: {
            latitude: 57.02202911,
            longitude: 6.93927002,
        },
    },
    {
        name: 'Thyholm',
        zipcode: '7790',
        coordinates: {
            latitude: 56.62765212,
            longitude: 8.51363052,
        },
    },
    {
        name: 'Skive',
        zipcode: '7800',
        coordinates: {
            latitude: 56.46317549,
            longitude: 8.99505562,
        },
    },
    {
        name: 'Vinderup',
        zipcode: '7830',
        coordinates: {
            latitude: 56.47769058,
            longitude: 8.80020865,
        },
    },
    {
        name: 'Højslev',
        zipcode: '7840',
        coordinates: {
            latitude: 56.59677993,
            longitude: 9.16509632,
        },
    },
    {
        name: 'Stoholm Jyll',
        zipcode: '7850',
        coordinates: {
            latitude: 56.48444375,
            longitude: 9.12847686,
        },
    },
    {
        name: 'Spøttrup',
        zipcode: '7860',
        coordinates: {
            latitude: 56.62419195,
            longitude: 8.82098992,
        },
    },
    {
        name: 'Roslev',
        zipcode: '7870',
        coordinates: {
            latitude: 56.72437033,
            longitude: 9.04159313,
        },
    },
    {
        name: 'Fur',
        zipcode: '7884',
        coordinates: {
            latitude: 56.84193567,
            longitude: 9.00553893,
        },
    },
    {
        name: 'Erslev',
        zipcode: '7950',
        coordinates: {
            latitude: 56.8517685,
            longitude: 8.69843044,
        },
    },
    {
        name: 'Karby',
        zipcode: '7960',
        coordinates: {
            latitude: 56.76132134,
            longitude: 8.56443606,
        },
    },
    {
        name: 'Redsted M',
        zipcode: '7970',
        coordinates: {
            latitude: 56.73993747,
            longitude: 8.63946767,
        },
    },
    {
        name: 'Vils',
        zipcode: '7980',
        coordinates: {
            latitude: 56.74842812,
            longitude: 8.72666914,
        },
    },
    {
        name: 'Øster Assels',
        zipcode: '7990',
        coordinates: {
            latitude: 56.68763915,
            longitude: 8.70032858,
        },
    },
    {
        name: 'Aarhus C',
        zipcode: '8000',
        coordinates: {
            latitude: 56.15051496,
            longitude: 10.27802097,
        },
    },
    {
        name: 'Aarhus N',
        zipcode: '8200',
        coordinates: {
            latitude: 56.20167527,
            longitude: 10.15922351,
        },
    },
    {
        name: 'Aarhus V',
        zipcode: '8210',
        coordinates: {
            latitude: 56.17162148,
            longitude: 10.15819911,
        },
    },
    {
        name: 'Brabrand',
        zipcode: '8220',
        coordinates: {
            latitude: 56.15788265,
            longitude: 10.06788413,
        },
    },
    {
        name: 'Åbyhøj',
        zipcode: '8230',
        coordinates: {
            latitude: 56.15196052,
            longitude: 10.1605019,
        },
    },
    {
        name: 'Risskov',
        zipcode: '8240',
        coordinates: {
            latitude: 56.18719668,
            longitude: 10.24680782,
        },
    },
    {
        name: 'Egå',
        zipcode: '8250',
        coordinates: {
            latitude: 56.22214938,
            longitude: 10.29989608,
        },
    },
    {
        name: 'Viby J',
        zipcode: '8260',
        coordinates: {
            latitude: 56.12635244,
            longitude: 10.13778473,
        },
    },
    {
        name: 'Højbjerg',
        zipcode: '8270',
        coordinates: {
            latitude: 56.09903701,
            longitude: 10.28961018,
        },
    },
    {
        name: 'Odder',
        zipcode: '8300',
        coordinates: {
            latitude: 55.92457284,
            longitude: 10.24464778,
        },
    },
    {
        name: 'Samsø',
        zipcode: '8305',
        coordinates: {
            latitude: 55.91180366,
            longitude: 10.68434089,
        },
    },
    {
        name: 'Tranbjerg J',
        zipcode: '8310',
        coordinates: {
            latitude: 56.09423322,
            longitude: 10.13924601,
        },
    },
    {
        name: 'Mårslet',
        zipcode: '8320',
        coordinates: {
            latitude: 56.0658713,
            longitude: 10.15312251,
        },
    },
    {
        name: 'Beder',
        zipcode: '8330',
        coordinates: {
            latitude: 56.06302044,
            longitude: 10.24584275,
        },
    },
    {
        name: 'Malling',
        zipcode: '8340',
        coordinates: {
            latitude: 56.02634501,
            longitude: 10.2151148,
        },
    },
    {
        name: 'Hundslund',
        zipcode: '8350',
        coordinates: {
            latitude: 55.90552571,
            longitude: 10.05739341,
        },
    },
    {
        name: 'Solbjerg',
        zipcode: '8355',
        coordinates: {
            latitude: 56.03661436,
            longitude: 10.0866212,
        },
    },
    {
        name: 'Hasselager',
        zipcode: '8361',
        coordinates: {
            latitude: 56.10123046,
            longitude: 10.08245179,
        },
    },
    {
        name: 'Hørning',
        zipcode: '8362',
        coordinates: {
            latitude: 56.09369625,
            longitude: 10.0135704,
        },
    },
    {
        name: 'Hadsten',
        zipcode: '8370',
        coordinates: {
            latitude: 56.33866468,
            longitude: 10.0289852,
        },
    },
    {
        name: 'Trige',
        zipcode: '8380',
        coordinates: {
            latitude: 56.27022295,
            longitude: 10.16086107,
        },
    },
    {
        name: 'Tilst',
        zipcode: '8381',
        coordinates: {
            latitude: 56.18928869,
            longitude: 10.09750556,
        },
    },
    {
        name: 'Hinnerup',
        zipcode: '8382',
        coordinates: {
            latitude: 56.25949156,
            longitude: 10.06719333,
        },
    },
    {
        name: 'Ebeltoft',
        zipcode: '8400',
        coordinates: {
            latitude: 56.14931257,
            longitude: 10.82632626,
        },
    },
    {
        name: 'Rønde',
        zipcode: '8410',
        coordinates: {
            latitude: 56.30328793,
            longitude: 10.48173371,
        },
    },
    {
        name: 'Knebel',
        zipcode: '8420',
        coordinates: {
            latitude: 56.15222183,
            longitude: 10.45208963,
        },
    },
    {
        name: 'Balle',
        zipcode: '8444',
        coordinates: {
            latitude: 56.20496905,
            longitude: 11.11035786,
        },
    },
    {
        name: 'Hammel',
        zipcode: '8450',
        coordinates: {
            latitude: 56.26356721,
            longitude: 9.8817943,
        },
    },
    {
        name: 'Harlev J',
        zipcode: '8462',
        coordinates: {
            latitude: 56.14059283,
            longitude: 9.99991491,
        },
    },
    {
        name: 'Galten',
        zipcode: '8464',
        coordinates: {
            latitude: 56.17800104,
            longitude: 9.91179535,
        },
    },
    {
        name: 'Sabro',
        zipcode: '8471',
        coordinates: {
            latitude: 56.21127538,
            longitude: 10.01547147,
        },
    },
    {
        name: 'Sporup',
        zipcode: '8472',
        coordinates: {
            latitude: 56.20907595,
            longitude: 9.83610781,
        },
    },
    {
        name: 'Grenaa',
        zipcode: '8500',
        coordinates: {
            latitude: 56.43069147,
            longitude: 11.08217337,
        },
    },
    {
        name: 'Lystrup',
        zipcode: '8520',
        coordinates: {
            latitude: 56.24438884,
            longitude: 10.22716475,
        },
    },
    {
        name: 'Hjortshøj',
        zipcode: '8530',
        coordinates: {
            latitude: 56.29464717,
            longitude: 10.20502083,
        },
    },
    {
        name: 'Skødstrup',
        zipcode: '8541',
        coordinates: {
            latitude: 56.26151082,
            longitude: 10.36793908,
        },
    },
    {
        name: 'Hornslet',
        zipcode: '8543',
        coordinates: {
            latitude: 56.33260255,
            longitude: 10.29717184,
        },
    },
    {
        name: 'Mørke',
        zipcode: '8544',
        coordinates: {
            latitude: 56.35508218,
            longitude: 10.38246065,
        },
    },
    {
        name: 'Ryomgård',
        zipcode: '8550',
        coordinates: {
            latitude: 56.38481233,
            longitude: 10.50027849,
        },
    },
    {
        name: 'Kolind',
        zipcode: '8560',
        coordinates: {
            latitude: 56.34682936,
            longitude: 10.61106988,
        },
    },
    {
        name: 'Trustrup',
        zipcode: '8570',
        coordinates: {
            latitude: 56.36287517,
            longitude: 10.74217288,
        },
    },
    {
        name: 'Nimtofte',
        zipcode: '8581',
        coordinates: {
            latitude: 56.42793946,
            longitude: 10.53988094,
        },
    },
    {
        name: 'Glesborg',
        zipcode: '8585',
        coordinates: {
            latitude: 56.58757292,
            longitude: 10.64766235,
        },
    },
    {
        name: 'Ørum Djurs',
        zipcode: '8586',
        coordinates: {
            latitude: 56.44956016,
            longitude: 10.62703709,
        },
    },
    {
        name: 'Anholt',
        zipcode: '8592',
        coordinates: {
            latitude: 56.77566641,
            longitude: 11.49010919,
        },
    },
    {
        name: 'Silkeborg',
        zipcode: '8600',
        coordinates: {
            latitude: 56.17147071,
            longitude: 9.4900253,
        },
    },
    {
        name: 'Kjellerup',
        zipcode: '8620',
        coordinates: {
            latitude: 56.2882506,
            longitude: 9.39095532,
        },
    },
    {
        name: 'Lemming',
        zipcode: '8632',
        coordinates: {
            latitude: 56.24319222,
            longitude: 9.55711799,
        },
    },
    {
        name: 'Sorring',
        zipcode: '8641',
        coordinates: {
            latitude: 56.17906349,
            longitude: 9.77449496,
        },
    },
    {
        name: 'Ans By',
        zipcode: '8643',
        coordinates: {
            latitude: 56.28777753,
            longitude: 9.6247327,
        },
    },
    {
        name: 'Them',
        zipcode: '8653',
        coordinates: {
            latitude: 56.08668443,
            longitude: 9.55044283,
        },
    },
    {
        name: 'Bryrup',
        zipcode: '8654',
        coordinates: {
            latitude: 56.02488921,
            longitude: 9.47060993,
        },
    },
    {
        name: 'Skanderborg',
        zipcode: '8660',
        coordinates: {
            latitude: 56.02388208,
            longitude: 9.89235588,
        },
    },
    {
        name: 'Låsby',
        zipcode: '8670',
        coordinates: {
            latitude: 56.14838747,
            longitude: 9.79899795,
        },
    },
    {
        name: 'Ry',
        zipcode: '8680',
        coordinates: {
            latitude: 56.10415601,
            longitude: 9.76890364,
        },
    },
    {
        name: 'Horsens',
        zipcode: '8700',
        coordinates: {
            latitude: 55.86597915,
            longitude: 9.89301425,
        },
    },
    {
        name: 'Daugård',
        zipcode: '8721',
        coordinates: {
            latitude: 55.72499013,
            longitude: 9.70789065,
        },
    },
    {
        name: 'Hedensted',
        zipcode: '8722',
        coordinates: {
            latitude: 55.76508387,
            longitude: 9.71130088,
        },
    },
    {
        name: 'Løsning',
        zipcode: '8723',
        coordinates: {
            latitude: 55.81385497,
            longitude: 9.69416947,
        },
    },
    {
        name: 'Hovedgård',
        zipcode: '8732',
        coordinates: {
            latitude: 55.95626604,
            longitude: 9.98616441,
        },
    },
    {
        name: 'Brædstrup',
        zipcode: '8740',
        coordinates: {
            latitude: 55.96052136,
            longitude: 9.59236493,
        },
    },
    {
        name: 'Gedved',
        zipcode: '8751',
        coordinates: {
            latitude: 55.93439431,
            longitude: 9.86197889,
        },
    },
    {
        name: 'Østbirk',
        zipcode: '8752',
        coordinates: {
            latitude: 55.96891982,
            longitude: 9.75737543,
        },
    },
    {
        name: 'Flemming',
        zipcode: '8762',
        coordinates: {
            latitude: 55.89442946,
            longitude: 9.66642959,
        },
    },
    {
        name: 'Rask Mølle',
        zipcode: '8763',
        coordinates: {
            latitude: 55.87835123,
            longitude: 9.6137501,
        },
    },
    {
        name: 'Klovborg',
        zipcode: '8765',
        coordinates: {
            latitude: 55.92463273,
            longitude: 9.48555987,
        },
    },
    {
        name: 'Nørre Snede',
        zipcode: '8766',
        coordinates: {
            latitude: 55.96381394,
            longitude: 9.41496508,
        },
    },
    {
        name: 'Stenderup',
        zipcode: '8781',
        coordinates: {
            latitude: 55.79039351,
            longitude: 9.80232769,
        },
    },
    {
        name: 'Hornsyld',
        zipcode: '8783',
        coordinates: {
            latitude: 55.76234096,
            longitude: 9.85180061,
        },
    },
    {
        name: 'Endelave',
        zipcode: '8789',
        coordinates: {
            latitude: 55.75329623,
            longitude: 10.30820541,
        },
    },
    {
        name: 'Tunø',
        zipcode: '8799',
        coordinates: {
            latitude: 55.9486075,
            longitude: 10.44454187,
        },
    },
    {
        name: 'Viborg',
        zipcode: '8800',
        coordinates: {
            latitude: 56.41681148,
            longitude: 9.36897659,
        },
    },
    {
        name: 'Tjele',
        zipcode: '8830',
        coordinates: {
            latitude: 56.49762699,
            longitude: 9.60208409,
        },
    },
    {
        name: 'Løgstrup',
        zipcode: '8831',
        coordinates: {
            latitude: 56.51759246,
            longitude: 9.32656972,
        },
    },
    {
        name: 'Skals',
        zipcode: '8832',
        coordinates: {
            latitude: 56.59071095,
            longitude: 9.35963562,
        },
    },
    {
        name: 'Rødkærsbro',
        zipcode: '8840',
        coordinates: {
            latitude: 56.35360504,
            longitude: 9.50444327,
        },
    },
    {
        name: 'Bjerringbro',
        zipcode: '8850',
        coordinates: {
            latitude: 56.36051392,
            longitude: 9.64257403,
        },
    },
    {
        name: 'Ulstrup',
        zipcode: '8860',
        coordinates: {
            latitude: 56.3800683,
            longitude: 9.78710533,
        },
    },
    {
        name: 'Langå',
        zipcode: '8870',
        coordinates: {
            latitude: 56.3806496,
            longitude: 9.90941177,
        },
    },
    {
        name: 'Thorsø',
        zipcode: '8881',
        coordinates: {
            latitude: 56.3103006,
            longitude: 9.79439741,
        },
    },
    {
        name: 'Fårvang',
        zipcode: '8882',
        coordinates: {
            latitude: 56.27196318,
            longitude: 9.70492587,
        },
    },
    {
        name: 'Gjern',
        zipcode: '8883',
        coordinates: {
            latitude: 56.22223999,
            longitude: 9.73984728,
        },
    },
    {
        name: 'Randers C',
        zipcode: '8900',
        coordinates: {
            latitude: 56.46401254,
            longitude: 10.0331817,
        },
    },
    {
        name: 'Randers NV',
        zipcode: '8920',
        coordinates: {
            latitude: 56.48050883,
            longitude: 9.88044731,
        },
    },
    {
        name: 'Randers NØ',
        zipcode: '8930',
        coordinates: {
            latitude: 56.50265825,
            longitude: 10.1724293,
        },
    },
    {
        name: 'Randers SV',
        zipcode: '8940',
        coordinates: {
            latitude: 56.41476356,
            longitude: 10.01231946,
        },
    },
    {
        name: 'Ørsted',
        zipcode: '8950',
        coordinates: {
            latitude: 56.53413791,
            longitude: 10.32882868,
        },
    },
    {
        name: 'Randers SØ',
        zipcode: '8960',
        coordinates: {
            latitude: 56.42912946,
            longitude: 10.17671406,
        },
    },
    {
        name: 'Allingåbro',
        zipcode: '8961',
        coordinates: {
            latitude: 56.49705855,
            longitude: 10.49037138,
        },
    },
    {
        name: 'Auning',
        zipcode: '8963',
        coordinates: {
            latitude: 56.42921057,
            longitude: 10.38424574,
        },
    },
    {
        name: 'Havndal',
        zipcode: '8970',
        coordinates: {
            latitude: 56.6517573,
            longitude: 10.26167957,
        },
    },
    {
        name: 'Spentrup',
        zipcode: '8981',
        coordinates: {
            latitude: 56.54082727,
            longitude: 10.0192493,
        },
    },
    {
        name: 'Gjerlev J',
        zipcode: '8983',
        coordinates: {
            latitude: 56.58233605,
            longitude: 10.10145059,
        },
    },
    {
        name: 'Fårup',
        zipcode: '8990',
        coordinates: {
            latitude: 56.55632319,
            longitude: 9.84538694,
        },
    },
    {
        name: 'Aalborg',
        zipcode: '9000',
        coordinates: {
            latitude: 57.05171431,
            longitude: 9.8350492,
        },
    },
    {
        name: 'Aalborg SV',
        zipcode: '9200',
        coordinates: {
            latitude: 57.00928951,
            longitude: 9.86220561,
        },
    },
    {
        name: 'Aalborg SØ',
        zipcode: '9210',
        coordinates: {
            latitude: 56.9991663,
            longitude: 9.93295578,
        },
    },
    {
        name: 'Aalborg Øst',
        zipcode: '9220',
        coordinates: {
            latitude: 57.05425152,
            longitude: 10.00949733,
        },
    },
    {
        name: 'Svenstrup J',
        zipcode: '9230',
        coordinates: {
            latitude: 56.9647583,
            longitude: 9.86815189,
        },
    },
    {
        name: 'Nibe',
        zipcode: '9240',
        coordinates: {
            latitude: 56.94881207,
            longitude: 9.57985536,
        },
    },
    {
        name: 'Gistrup',
        zipcode: '9260',
        coordinates: {
            latitude: 56.93195612,
            longitude: 10.01379029,
        },
    },
    {
        name: 'Klarup',
        zipcode: '9270',
        coordinates: {
            latitude: 57.01911272,
            longitude: 10.05559807,
        },
    },
    {
        name: 'Storvorde',
        zipcode: '9280',
        coordinates: {
            latitude: 56.91678716,
            longitude: 10.27569023,
        },
    },
    {
        name: 'Kongerslev',
        zipcode: '9293',
        coordinates: {
            latitude: 56.89578273,
            longitude: 10.1135906,
        },
    },
    {
        name: 'Sæby',
        zipcode: '9300',
        coordinates: {
            latitude: 57.2843629,
            longitude: 10.57327556,
        },
    },
    {
        name: 'Vodskov',
        zipcode: '9310',
        coordinates: {
            latitude: 57.09538719,
            longitude: 10.09257652,
        },
    },
    {
        name: 'Hjallerup',
        zipcode: '9320',
        coordinates: {
            latitude: 57.17870119,
            longitude: 10.13790859,
        },
    },
    {
        name: 'Dronninglund',
        zipcode: '9330',
        coordinates: {
            latitude: 57.21127809,
            longitude: 10.31020366,
        },
    },
    {
        name: 'Asaa',
        zipcode: '9340',
        coordinates: {
            latitude: 57.13326579,
            longitude: 10.44405468,
        },
    },
    {
        name: 'Dybvad',
        zipcode: '9352',
        coordinates: {
            latitude: 57.28497909,
            longitude: 10.32334518,
        },
    },
    {
        name: 'Gandrup',
        zipcode: '9362',
        coordinates: {
            latitude: 57.05355839,
            longitude: 10.1977671,
        },
    },
    {
        name: 'Hals',
        zipcode: '9370',
        coordinates: {
            latitude: 56.96384115,
            longitude: 10.65368838,
        },
    },
    {
        name: 'Vestbjerg',
        zipcode: '9380',
        coordinates: {
            latitude: 57.12732919,
            longitude: 9.97550463,
        },
    },
    {
        name: 'Sulsted',
        zipcode: '9381',
        coordinates: {
            latitude: 57.16265215,
            longitude: 9.99174315,
        },
    },
    {
        name: 'Tylstrup',
        zipcode: '9382',
        coordinates: {
            latitude: 57.20308117,
            longitude: 9.9350316,
        },
    },
    {
        name: 'Nørresundby',
        zipcode: '9400',
        coordinates: {
            latitude: 57.08119416,
            longitude: 9.92809079,
        },
    },
    {
        name: 'Vadum',
        zipcode: '9430',
        coordinates: {
            latitude: 57.11542161,
            longitude: 9.8563612,
        },
    },
    {
        name: 'Aabybro',
        zipcode: '9440',
        coordinates: {
            latitude: 57.16282327,
            longitude: 9.76743314,
        },
    },
    {
        name: 'Brovst',
        zipcode: '9460',
        coordinates: {
            latitude: 57.10716869,
            longitude: 9.5353613,
        },
    },
    {
        name: 'Løkken',
        zipcode: '9480',
        coordinates: {
            latitude: 57.38233116,
            longitude: 9.65510765,
        },
    },
    {
        name: 'Pandrup',
        zipcode: '9490',
        coordinates: {
            latitude: 57.20322545,
            longitude: 9.65450533,
        },
    },
    {
        name: 'Blokhus',
        zipcode: '9492',
        coordinates: {
            latitude: 57.25739854,
            longitude: 9.57722094,
        },
    },
    {
        name: 'Saltum',
        zipcode: '9493',
        coordinates: {
            latitude: 57.26602114,
            longitude: 9.69608315,
        },
    },
    {
        name: 'Hobro',
        zipcode: '9500',
        coordinates: {
            latitude: 56.6528756,
            longitude: 9.77366901,
        },
    },
    {
        name: 'Arden',
        zipcode: '9510',
        coordinates: {
            latitude: 56.76264148,
            longitude: 9.94260419,
        },
    },
    {
        name: 'Skørping',
        zipcode: '9520',
        coordinates: {
            latitude: 56.84944104,
            longitude: 9.92696601,
        },
    },
    {
        name: 'Støvring',
        zipcode: '9530',
        coordinates: {
            latitude: 56.89267972,
            longitude: 9.80945918,
        },
    },
    {
        name: 'Suldrup',
        zipcode: '9541',
        coordinates: {
            latitude: 56.84292865,
            longitude: 9.68119202,
        },
    },
    {
        name: 'Mariager',
        zipcode: '9550',
        coordinates: {
            latitude: 56.63765951,
            longitude: 10.0220988,
        },
    },
    {
        name: 'Hadsund',
        zipcode: '9560',
        coordinates: {
            latitude: 56.77688954,
            longitude: 10.41645939,
        },
    },
    {
        name: 'Bælum',
        zipcode: '9574',
        coordinates: {
            latitude: 56.8298458,
            longitude: 10.12654857,
        },
    },
    {
        name: 'Terndrup',
        zipcode: '9575',
        coordinates: {
            latitude: 56.81450196,
            longitude: 10.04422607,
        },
    },
    {
        name: 'Aars',
        zipcode: '9600',
        coordinates: {
            latitude: 56.80003403,
            longitude: 9.49610809,
        },
    },
    {
        name: 'Nørager',
        zipcode: '9610',
        coordinates: {
            latitude: 56.74794316,
            longitude: 9.63042311,
        },
    },
    {
        name: 'Aalestrup',
        zipcode: '9620',
        coordinates: {
            latitude: 56.69297871,
            longitude: 9.4967184,
        },
    },
    {
        name: 'Gedsted',
        zipcode: '9631',
        coordinates: {
            latitude: 56.69090841,
            longitude: 9.36542938,
        },
    },
    {
        name: 'Møldrup',
        zipcode: '9632',
        coordinates: {
            latitude: 56.6088695,
            longitude: 9.50805968,
        },
    },
    {
        name: 'Farsø',
        zipcode: '9640',
        coordinates: {
            latitude: 56.77310507,
            longitude: 9.27748567,
        },
    },
    {
        name: 'Løgstør',
        zipcode: '9670',
        coordinates: {
            latitude: 56.95819912,
            longitude: 9.30949603,
        },
    },
    {
        name: 'Ranum',
        zipcode: '9681',
        coordinates: {
            latitude: 56.89861349,
            longitude: 9.12839085,
        },
    },
    {
        name: 'Fjerritslev',
        zipcode: '9690',
        coordinates: {
            latitude: 57.12742277,
            longitude: 9.20878724,
        },
    },
    {
        name: 'Brønderslev',
        zipcode: '9700',
        coordinates: {
            latitude: 57.28432949,
            longitude: 9.88277134,
        },
    },
    {
        name: 'Jerslev J',
        zipcode: '9740',
        coordinates: {
            latitude: 57.29699349,
            longitude: 10.11084423,
        },
    },
    {
        name: 'Østervrå',
        zipcode: '9750',
        coordinates: {
            latitude: 57.34034984,
            longitude: 10.27081316,
        },
    },
    {
        name: 'Vrå',
        zipcode: '9760',
        coordinates: {
            latitude: 57.37190853,
            longitude: 9.87742317,
        },
    },
    {
        name: 'Hjørring',
        zipcode: '9800',
        coordinates: {
            latitude: 57.70230652,
            longitude: 9.2956345,
        },
    },
    {
        name: 'Tårs',
        zipcode: '9830',
        coordinates: {
            latitude: 57.37825187,
            longitude: 10.14317436,
        },
    },
    {
        name: 'Hirtshals',
        zipcode: '9850',
        coordinates: {
            latitude: 57.94281241,
            longitude: 9.71496643,
        },
    },
    {
        name: 'Sindal',
        zipcode: '9870',
        coordinates: {
            latitude: 57.4674892,
            longitude: 10.24075136,
        },
    },
    {
        name: 'Bindslev',
        zipcode: '9881',
        coordinates: {
            latitude: 57.57139797,
            longitude: 10.23668023,
        },
    },
    {
        name: 'Frederikshavn',
        zipcode: '9900',
        coordinates: {
            latitude: 57.52270103,
            longitude: 10.72500277,
        },
    },
    {
        name: 'Læsø',
        zipcode: '9940',
        coordinates: {
            latitude: 57.18887848,
            longitude: 11.14575743,
        },
    },
    {
        name: 'Strandby',
        zipcode: '9970',
        coordinates: {
            latitude: 57.51074154,
            longitude: 10.52915912,
        },
    },
    {
        name: 'Jerup',
        zipcode: '9981',
        coordinates: {
            latitude: 57.53549926,
            longitude: 10.43874206,
        },
    },
    {
        name: 'Ålbæk',
        zipcode: '9982',
        coordinates: {
            latitude: 57.60572774,
            longitude: 10.40473272,
        },
    },
    {
        name: 'Skagen',
        zipcode: '9990',
        coordinates: {
            latitude: 57.85540126,
            longitude: 10.48570715,
        },
    },
];
