import { useGtmTracking } from '../../../gtm-tracking/hooks/use-gtm-tracking';
import { sharedTypes, UmbracoForm } from '../../../lib/api/models/umbraco';
import { DisplayManagerStore } from '../../../lib/state/display-manager';
import { FormsManagerStore } from '../../../lib/state/plus-sites/forms-display-manager';
import { ReepayStore } from '../../../lib/state/reepay-form/reepay-form.store';
import { ServiceAgreementCalculatorModalStore } from '../../../lib/state/service-agreement-calculator/service-agreement-calculator.store';
import { handleUrlClick } from '../../../utils/helpers';
import { isSiteName, SITE_NAME } from '../../../utils/helpers/site-id.helper';
import { isUmbracoFormType } from '../../../utils/helpers/umbraco-forms.helper';
import { scrollToElementWithOffset } from '../../../utils/helpers/utils.helper';
import { useUI } from '../../ui';

export type CtaHandlerUtils = {
    handleCtaClick: (cta: sharedTypes.MixedCta, extraActions?: () => void) => void;
};

export const useCtaHandler = (): CtaHandlerUtils => {
    // UI Handler
    const uiHandler = useUI();

    // Savings, mit-hessel-star-plus display store
    const { showSavingsModal } = DisplayManagerStore.useStoreState((state) => state);
    const { setShowSavingsModal, setShowMyHesselStarPlus } = DisplayManagerStore.useStoreActions((actions) => actions);

    // Reepay store
    const { modalOpen } = ReepayStore.useStoreState((state) => state);
    const { setModalOpen, setSelectedAgreement } = ReepayStore.useStoreActions((actions) => actions);

    const goToSavingsView = (): void => {
        if (!showSavingsModal) {
            calculateSavingsTracker.opened();
            setShowSavingsModal(true);
            uiHandler.removeScroll();
        }
    };

    //  Form Manager store
    const { setFormVisibility } = FormsManagerStore.useStoreActions((actions) => actions);

    // Service agreement calculator store
    const { setModalOpen: setServiceAgreementCalculatorModalOpen } = ServiceAgreementCalculatorModalStore.useStoreActions((actions) => actions);
    const { modalOpen: serviceAgreementCalculatorModalOpen } = ServiceAgreementCalculatorModalStore.useStoreState((state) => state);

    // Google Tag Manager
    const { trackCalculateSavings, trackMembership } = useGtmTracking();
    const calculateSavingsTracker = trackCalculateSavings();
    const membershipTracker = trackMembership();

    function handleCtaClick(cta: sharedTypes.MixedCta, extraActions?: () => void) {
        switch (cta.alias) {
            case 'cTAWithLink': {
                handleUrlClick(cta.linkUrl);
                break;
            }
            case 'cTAWithAction': {
                if (cta.action === 'Display savings calculation') {
                    goToSavingsView();
                } else if (cta.action === 'Become a member') {
                    membershipTracker.openSignUp();
                    setModalOpen(true);
                    uiHandler.removeScroll();
                } else if (cta.action === 'Mit Hessel Star Plus Dialog') {
                    setShowMyHesselStarPlus(true);
                } else if (cta.action === 'Open form') {
                    uiHandler.removeScrollAndLock();
                    setFormVisibility({
                        id: isUmbracoFormType(cta) ? (cta.formToOpen as UmbracoForm).id : (cta.formToOpen as string),
                        value: true,
                    });
                } else if (cta.action === 'Select benefit agreement') {
                    setSelectedAgreement(cta.benefitAgreementToSelect);
                    if (!modalOpen) {
                        membershipTracker.openSignUp();
                        setModalOpen(true);
                        uiHandler.removeScroll();
                    }
                } else if (cta.action === 'Scroll to anchor' && cta.scrollToAnchorId.length > 0) {
                    const scrollTarget = document.getElementById(cta.scrollToAnchorId);
                    let scrollOffsetTop;

                    if (scrollTarget) {
                        if (isSiteName(SITE_NAME.CARSAVER)) {
                            scrollOffsetTop = 150;
                            scrollToElementWithOffset(scrollTarget, scrollOffsetTop);
                        } else {
                            scrollTarget.scrollIntoView({ behavior: 'smooth', block: 'start' });
                        }
                    }
                } else if (cta.action === 'Open service agreement calculator right panel') {
                    if (!serviceAgreementCalculatorModalOpen) {
                        setServiceAgreementCalculatorModalOpen(true);
                        uiHandler.removeScroll();
                    }
                }

                break;
            }
            default:
                throw new Error('Invalid Cta Type');
        }
        if (extraActions) extraActions();
    }

    return {
        handleCtaClick,
    };
};
