import { FC, useCallback, useEffect, useRef } from 'react';
import { toggleChatBot } from '../../../../utils/helpers';
import { Backdrop } from '../../backdrop';
import { StyledSidePanelWrapper } from './side-panel.styled';

export type SidePanelProps = {
    isVisible: boolean;
    cancelAction: () => void;
    children: React.ReactNode;
    variant?: 'sm' | 'md' | 'lg' | 'full' | '70%';
    scrollToTop?: boolean;
};

export const SidePanel: FC<SidePanelProps> = ({ isVisible, cancelAction, children, variant = 'md' }) => {
    useEffect(() => {
        toggleChatBot(isVisible);
    }, [isVisible]);

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        containerRef.current?.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [isVisible]);

    const handleDocumentKeyDown = useCallback(
        (e) => {
            if (e.key === 'Escape') {
                cancelAction();
            }
        },
        [cancelAction]
    );

    useEffect(() => {
        if (isVisible) {
            document.addEventListener('keydown', handleDocumentKeyDown, { passive: true });
        } else {
            document.removeEventListener('keydown', handleDocumentKeyDown);
        }

        return () => document.removeEventListener('keydown', handleDocumentKeyDown);
    }, [handleDocumentKeyDown, isVisible]);

    return (
        <>
            {isVisible && <Backdrop hide={cancelAction}></Backdrop>}
            <StyledSidePanelWrapper ref={containerRef} variant={variant} isVisible={isVisible}>
                {children}
            </StyledSidePanelWrapper>
        </>
    );
};
