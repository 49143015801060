import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const FullScreenCampaignWrapper = styled.section<{ isOnlyImages: boolean }>`
    @media ${device.tablet} {
        ${(props) => (props.isOnlyImages ? '' : 'height: 525px;')}

        & > div {
            ${(props) => (props.isOnlyImages ? '' : 'height: 525px;')}
        }
    }
`;
