import { VFC } from 'react';
import {
    BorderBox,
    ImageWrapper,
    StyledImage,
    TextsWrapper,
    CarName,
    CarModel,
    ColorWrapper,
    CarColorName,
    StyledColorCircle,
} from './car-summary.styled';

type CarSummaryProps = {
    imageUrl: string;
    brandTitle: string;
    model: string;
    colorName: string;
    colorImageUrl: string;
};

export const CarSummary: VFC<CarSummaryProps> = ({ imageUrl, brandTitle, model, colorName, colorImageUrl }) => {
    return (
        <BorderBox>
            <TextsWrapper>
                <CarName>{brandTitle}</CarName>
                <CarModel>{model}</CarModel>
                <ColorWrapper>
                    <StyledColorCircle imageUrl={colorImageUrl} />
                    <CarColorName>{colorName}</CarColorName>
                </ColorWrapper>
            </TextsWrapper>
            <ImageWrapper>
                <StyledImage src={imageUrl} />
            </ImageWrapper>
        </BorderBox>
    );
};
