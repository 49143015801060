import { FC } from 'react';
import { Usp } from '../../shared/usp';
import { IUspList } from './usp-list.props';
import { StyledHeader } from '../_shared';
import styled from 'styled-components';

const StyledWrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & > div:not(:first-child) {
        margin-bottom: 1rem;
    }
`;

export const UspList: FC<IUspList> = ({ items, title }) => {
    return (
        <StyledWrapper>
            <StyledHeader>{title}</StyledHeader>
            {items.map((x, index) => {
                return <Usp key={`usp:${index}`} text={x.text} />;
            })}
        </StyledWrapper>
    );
};
