import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const BorderBox = styled.div`
    position: sticky;
    top: 0;
    z-index: 100;
    isolation: isolate;

    display: flex;
    flex-direction: column;

    background-color: #ffffff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);

    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);

    overscroll-behavior-y: contain;
    overflow-y: auto;

    @media ${device.mobileL} {
        padding-right: 25px;
        padding-left: 25px;
    }

    @media (min-width: 1025px) {
        display: none;
    }

    will-change: height;
`;

export const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px 0;
`;

export const TextGroup = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Label = styled.p`
    color: #807f80;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
`;

export const StyledValue = styled.p`
    color: #0b0b0b;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
`;

export const PreviewLinks = styled.div`
    display: flex;
    flex-direction: Column;
    gap: 10px;
    margin-top: 1rem;
    margin-bottom: 25px;

    @media ${device.mobileS} {
        flex-direction: row;
    }
`;

export const StyledToggler = styled.p`
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;

    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: max-content;
`;
