import { VFC } from 'react';
import { contentSpotTypes } from '../../../lib/api';
import { sharedTypes } from '../../../lib/api/models/umbraco';
import { Slider } from '../../shared';
import { FullscreenMediaWrapper } from './fullscreen-media.styled';
import { MediaItem } from './media-item';

const MEDIA_URL = process.env.NEXT_PUBLIC_MEDIA_URL;

export type FullscreenMediaProps = {
    content: contentSpotTypes.FullscreenMediaList;
};

export const FullscreenMedia: VFC<FullscreenMediaProps> = ({ content }) => {
    if (content.items.length === 0) {
        return null;
    }

    return (
        <FullscreenMediaWrapper id={content.scrollAnchorId}>
            <Slider key={`slider:${content.alias}`} showPagination={content.items.length > 1} slideDelayInSeconds={content.slideDelayInSeconds}>
                {content.items.map((item, index) => {
                    const img = item.media as sharedTypes.Image;
                    const imgMobile = item.mediaMobile as sharedTypes.Image;

                    const video = item.media as sharedTypes.Video;
                    const videoMobile = item.mediaMobile as sharedTypes.Video;

                    return (
                        <MediaItem
                            key={`${item.alias}-${item.media.type}-${index}`}
                            imageUrl={img ? `${MEDIA_URL}/${img?.src}` : undefined}
                            imageMobileUrl={imgMobile ? `${MEDIA_URL}/${imgMobile?.src}` : undefined}
                            videoUrl={
                                video && item.media.type === 'file' && item.media.extension?.toLowerCase() !== 'webp'
                                    ? `${MEDIA_URL}${video?.src}`
                                    : undefined
                            }
                            videoMobileUrl={
                                videoMobile && item.mediaMobile.type === 'file' && item.mediaMobile.extension?.toLowerCase() !== 'webp'
                                    ? `${MEDIA_URL}${videoMobile?.src}`
                                    : undefined
                            }
                            text={item.alias === 'fullscreenMediaWithText' ? item.text : undefined}
                            headerText={item.alias === 'fullscreenMediaWithText' ? item.headerText : undefined}
                            headerType={item.alias === 'fullscreenMediaWithText' ? item.headerType : undefined}
                            headerSize={item.alias === 'fullscreenMediaWithText' ? item.headerSize : undefined}
                            textPosition={item.alias === 'fullscreenMediaWithText' ? item.textPosition : undefined}
                            textColor={item.alias === 'fullscreenMediaWithText' ? item.textColor : undefined}
                            isAboveFold={!!content.isAboveFold}
                            carouselIndex={index}
                        />
                    );
                })}
            </Slider>
        </FullscreenMediaWrapper>
    );
};
