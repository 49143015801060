import { FC } from 'react';
import { ProductCardPrice } from '../../../../../lib/api/models/hessel-api/hire';
import { ProductListCardPriceType } from './product-list-card-price-type.component';
import { StyledPriceContainer } from './product-list-card-price.styled';

type IProps = {
    prices: ProductCardPrice[];
};

export const ProductListCardPrice: FC<IProps> = ({ prices }) => {
    return (
        <StyledPriceContainer>
            {prices.map((x, idx) => {
                return <ProductListCardPriceType key={idx} {...x} />;
            })}
        </StyledPriceContainer>
    );
};
