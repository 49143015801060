import { FC, useEffect, useMemo } from 'react';

type IProps = {
    scriptString: string;
};

export const DynamicContentBoxesScriptLoader: FC<IProps> = ({ scriptString }) => {
    const cookieBotScripts = useMemo(() => {
        return scriptString.indexOf('consent.cookiebot.com') > -1;
    }, [scriptString]);

    useEffect(() => {
        const loadCookieBotScript = () => {
            const script = document.createElement('script');
            script.src = scriptString;
            script.id = 'CookieDeclaration';
            document.querySelector('#CookieBotScriptContainer')?.appendChild(script);
        };
        if (cookieBotScripts) {
            loadCookieBotScript();
        }
    }, [cookieBotScripts, scriptString]);

    return <div>{cookieBotScripts ? <div id="CookieBotScriptContainer"></div> : <span dangerouslySetInnerHTML={{ __html: scriptString }} />}</div>;
};
