import { FC, useCallback } from 'react';
import { TextInput } from '../../../forms/inputs';
import { AddressInput } from '../../../forms/inputs/address';
import { InputList } from './hire-inputs.styled';
import { LeadCheckoutFormsStore, HireCheckoutMode } from '../../../../lib/state/hessel-site/lead-checkout/forms';
import { storeInputTypes } from '../../../../lib/state/_shared';
import { config, useTransition } from '@react-spring/core';
import { animated } from '@react-spring/web';

type Props = {
    inputs: Array<storeInputTypes.FormInput<string>>;
    canValidate: boolean;
};

export const HireInputs: FC<Props> = ({ inputs, canValidate }) => {
    const { checkoutMode } = LeadCheckoutFormsStore.useStoreState((state) => ({
        checkoutMode: state.checkoutMode,
    }));
    const { onChange, onChangeMultiple, setCanValidate, setCanValidateAddress } = LeadCheckoutFormsStore.useStoreActions((actions) => actions);

    const companyTransition = useTransition(checkoutMode === HireCheckoutMode.Business, {
        from: { opacity: 0, display: 'none' },
        enter: { opacity: 1 },
        leave: { opacity: 0, height: 0, display: 'none' },
        reverse: checkoutMode === HireCheckoutMode.Business,
        config: config.gentle,
        delay: 0,
    });

    const renderFormInput = useCallback(
        (input: storeInputTypes.FormInput<string>, idx: number) => {
            switch (input.type) {
                case 'email':
                case 'tel':
                case 'text':
                case 'name':
                    return (
                        <TextInput
                            key={`${idx}:${input.id}:input`}
                            id={input.id}
                            label={input.label}
                            onChange={(e) => {
                                onChange({ id: input.id, value: e.target.value });
                            }}
                            type={input.type}
                            placeholder={input.placeholder}
                            value={input.value}
                            disabled={input.disabled}
                            isValid={input.isValid}
                            validationMessage={input.validationMessage}
                            canValidateInputField={input.canValidate || canValidate}
                            onInputBlur={() => setCanValidate({ id: input.id, value: true })}
                        />
                    );
                case 'number':
                    return (
                        <TextInput
                            key={`${idx}:${input.id}:input`}
                            id={input.id}
                            label={input.label}
                            onChange={(e) => {
                                onChange({ id: input.id, value: e.target.value });
                            }}
                            type={input.type}
                            placeholder={input.placeholder}
                            value={input.value}
                            min={0}
                            isValid={input.isValid}
                            canValidateInputField={input.canValidate || canValidate}
                            validationMessage={input.validationMessage}
                            onInputBlur={() => setCanValidate({ id: input.id, value: true })}
                            pattern="[0-9]*"
                        />
                    );

                case 'address':
                    return (
                        <AddressInput
                            key={`${idx}:${input.id}:input`}
                            id={input.id}
                            onChange={({ id, value }) => {
                                onChangeMultiple({ rootId: input.id, id, value });
                            }}
                            inputs={input.inputs}
                            canValidateInputField={canValidate}
                            setCanValidate={({ rootId, id, value }) => setCanValidateAddress({ rootId, id, value })}
                        />
                    );

                case 'cvr (business)':
                    if (checkoutMode === HireCheckoutMode.Private) {
                        return null;
                    }

                    return companyTransition(({ opacity }, item) =>
                        item ? (
                            <animated.div
                                style={{
                                    opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }),
                                }}
                            >
                                <TextInput
                                    key={`${idx}:${input.id}:input`}
                                    id={input.id}
                                    label={input.label}
                                    onChange={(e) => {
                                        onChange({ id: input.id, value: e.target.value });
                                    }}
                                    type="number"
                                    placeholder={input.placeholder}
                                    value={input.value}
                                    min={0}
                                    isValid={input.isValid}
                                    canValidateInputField={input.canValidate || canValidate}
                                    validationMessage={input.validationMessage}
                                    onInputBlur={() => setCanValidate({ id: input.id, value: true })}
                                    pattern="[0-9]*"
                                />
                            </animated.div>
                        ) : null
                    );

                case 'company (business)':
                    if (checkoutMode === HireCheckoutMode.Private) {
                        return null;
                    }

                    return companyTransition(({ opacity }, item) =>
                        item ? (
                            <animated.div
                                style={{
                                    opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }),
                                }}
                            >
                                <TextInput
                                    key={`${idx}:${input.id}:input`}
                                    id={input.id}
                                    label={input.label}
                                    onChange={(e) => {
                                        onChange({ id: input.id, value: e.target.value });
                                    }}
                                    type="text"
                                    placeholder={input.placeholder}
                                    value={input.value}
                                    disabled={input.disabled}
                                    isValid={input.isValid}
                                    validationMessage={input.validationMessage}
                                    canValidateInputField={input.canValidate || canValidate}
                                    onInputBlur={() => setCanValidate({ id: input.id, value: true })}
                                />
                            </animated.div>
                        ) : null
                    );

                default:
                    <p>unknown</p>;
            }
        },
        [canValidate, checkoutMode, companyTransition, onChange, onChangeMultiple, setCanValidate, setCanValidateAddress]
    );

    return <InputList>{inputs.map(renderFormInput)}</InputList>;
};
