import styled from 'styled-components';
import { device } from '../../../lib/media-query';
import { Mail, MapPin, Phone } from '../../icons';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';

export const StyledCenteredBlock = styled(CenteredBlock)`
    background-color: #f7f7f8;
`;

export const StyledDealershipContactInfo = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 26px 0;
    gap: 27px;

    @media ${device.laptop} {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: space-between;
        align-items: baseline;
        padding: 50px 0;
        padding-bottom: 101px;
        gap: revert;
    }
`;

export const InformationColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    order: 2;

    @media ${device.laptop} {
        align-self: end;
        order: revert;
        gap: 15px;
    }
`;

export const Header = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    max-width: 390px;
    line-height: 35px;

    @media ${device.laptop} {
        font-size: 30px;
    }
`;

export const Description = styled.p`
    color: #0b0b0b;
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    font-size: 13px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 23px;
    max-width: 420px;

    @media ${device.laptop} {
        font-size: 14px;
    }
`;

export const InfoWrapper = styled.div``;

export const IconAndTextWrapper = styled.div`
    display: flex;
    gap: 5px;
    margin-bottom: 5px;
`;

export const StyledAnchor = styled.a``;

export const StyledMapPin = styled(MapPin)`
    margin-left: -7px;
`;

export const StyledPhone = styled(Phone)`
    margin-left: -6px;
`;

export const StyledMail = styled(Mail)`
    margin-left: -4px;
`;

export const AddressWrapper = styled.div``;

export const StyledAddress = styled.p`
    font-weight: normal;
    font-size: 13px;
`;

export const StyledMapOrImageWrapper = styled.div`
    aspect-ratio: 4 / 3;
    width: 100%;
    order: 1;

    @media ${device.mobileL} {
        aspect-ratio: 4 / 2;
    }

    @media ${device.laptop} {
        width: 550px;
        height: 480px;
        order: revert;
        margin-left: auto;
    }
`;

export const CtasWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 14px;
    margin-top: 13px;

    @media ${device.laptop} {
        margin-top: 27px;
    }
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    @media ${device.laptop} {
        flex-direction: row;
        align-items: revert;
    }
`;

export const StyledDealershipImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
`;
