import React, { VFC, useEffect, useMemo } from 'react';
import { BookTestDriveModalSettings, DealershipInformationPage } from '../../../lib/api/models/umbraco';
import { DateStyle, formatDate, isNullOrEmpty, validateEmail, validateName, validateTel } from '../../../utils/helpers';
import { DropdownInput, TextareaInput, TextInput } from '../../forms/inputs';
import { CheckBox, Separator } from '../../shared';
import { DatePickerDropdown } from '../../shared/date-picker-dropdown/date-picker-dropdown.component';
import { SidePanel } from '../../shared/modals/side-panel';
import { SidePanelLayout } from '../../shared/modals/side-panel-layout/side-panel-layout.component';
import {
    StyledText,
    StyledHeader,
    SectionHeader,
    TermsAndConditions,
    InputsWrapper,
    StyledSubmitButton,
    DataPolicyLink,
} from './book-test-drive-modal.styled';
import reactStringReplace from 'react-string-replace';

import { GeneralFormSubmitReceipt } from '../../shared/general-form-submit-receipt/general-form-submit-receipt.component';
import { useBookTestDriveModalData } from './use-book-test-drive-modal-data';
import { submitLeadData } from '../../../lib/api/lead/lead-api';
import { hesselViewModels } from '../../../lib/view-models';
import { addDays } from 'date-fns';
import { umbraco } from '../../../lib/api';
import { TestDriveAvailability } from '../../../lib/view-models/vehicle';
import { useRelewiseTracking } from '../../../hooks/relewise/use-relewise-tracking';

type BookTestDriveModalProps = {
    visible: boolean;
    onClose: () => void;
    currentDealership?: DealershipInformationPage;
    allDealerships: Array<DealershipInformationPage>;
    bookTestDriveModalSettings?: BookTestDriveModalSettings;
    configurationId?: string;
    vehicleItemNumber?: string;
    vehicleEntityId: string;
    vehicleTrackingPrice?: number;
    vehicleType?: string;
    brand?: string;
    brandModel?: string;
    name?: string;
    vehicleLocationId?: string;
    isUsedCar?: boolean;
    vehicleUrl: string;
    vehicleAvailability?: hesselViewModels.VehicleAvailability;
    specialDays?: umbraco.orgTypes.SpecialDay[];
    testDriveAvailability: TestDriveAvailability;
    referenceNumber?: string;
};

export const BookTestDriveModal: VFC<BookTestDriveModalProps> = ({
    visible,
    onClose,
    allDealerships,
    currentDealership,
    bookTestDriveModalSettings,
    configurationId,
    vehicleItemNumber,
    brand,
    brandModel,
    name,
    vehicleType,
    vehicleLocationId,
    isUsedCar = false,
    vehicleUrl,
    vehicleAvailability,
    specialDays,
    testDriveAvailability,
    referenceNumber,
    vehicleEntityId,
    vehicleTrackingPrice,
}) => {
    const {
        formValues,
        setFormValues,
        dealershipOptions,
        timeSlotOptions,
        availableDealerships,
        disabledDays,
        fallBackDealerships,
        filteredSpecialDays,
        getCalculatedWaitingDays,
    } = useBookTestDriveModalData(
        allDealerships,
        isUsedCar,
        currentDealership,
        isUsedCar || vehicleLocationId ? allDealerships.find((x) => Number(x.hovedafdelingId) === Number(vehicleLocationId)) : undefined,
        configurationId,
        vehicleItemNumber,
        vehicleType,
        brand as hesselViewModels.Brands,
        vehicleLocationId,
        vehicleAvailability,
        specialDays
    );

    const initialDealership = useMemo(() => {
        if (isUsedCar || vehicleLocationId) {
            return allDealerships.find((x) => Number(x.hovedafdelingId) === Number(vehicleLocationId));
        }

        if (currentDealership) {
            return currentDealership;
        }

        return availableDealerships.length > 0 ? availableDealerships[0] : undefined;
    }, [allDealerships, availableDealerships, currentDealership, isUsedCar, vehicleLocationId]);

    const defaultLocationId = useMemo(() => {
        if (vehicleLocationId && vehicleLocationId !== '') return vehicleLocationId;
        return '999';
    }, [vehicleLocationId]);

    const timeSlotValidationMessage = useMemo(() => {
        const cmsValueIsEmpty = isNullOrEmpty(bookTestDriveModalSettings?.whenSectionTimeslotValidation);

        if (!formValues) {
            return !cmsValueIsEmpty
                ? bookTestDriveModalSettings?.whenSectionTimeslotValidation
                : 'Det er ikke muligt at vælge tidspunkt, før du har valgt en afdeling';
        }
        return !cmsValueIsEmpty ? bookTestDriveModalSettings?.whenSectionTimeslotValidation : 'Vælg et tidspunkt';
    }, [bookTestDriveModalSettings?.whenSectionTimeslotValidation, formValues]);

    const desiredLocationText = useMemo(() => {
        if (availableDealerships.length > 0 && testDriveAvailability !== 'AvailablePreBooking') {
            return !isNullOrEmpty(bookTestDriveModalSettings?.whereSectionHeader)
                ? bookTestDriveModalSettings?.whereSectionHeader?.replace('{{dealershipsNo}}', availableDealerships.length.toString())
                : `Bilen står i ${availableDealerships.length} afdelinger, hvilken afdeling ønsker du at prøve bilen?`;
        }
        return 'Hvor ønsker du at foretage din prøvekørsel?';
    }, [availableDealerships.length, bookTestDriveModalSettings?.whereSectionHeader, testDriveAvailability]);

    const bookTestDriveWaitingDays = useMemo(() => {
        if (!formValues.dealership?.value) {
            return -1;
        }

        return getCalculatedWaitingDays(formValues.dealership?.value);
    }, [formValues.dealership?.value, getCalculatedWaitingDays]);

    const onlyOneLocation = useMemo(() => {
        if (isUsedCar || (formValues.dealership && availableDealerships.length === 1)) return true;
        return false;
    }, [availableDealerships.length, formValues.dealership, isUsedCar]);

    useEffect(() => {
        if (availableDealerships.length === 1) {
            setFormValues({
                ...formValues,
                dealership: {
                    displayValue: availableDealerships[0].displayName,
                    value: availableDealerships[0].hovedafdelingId,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableDealerships]);

    const { trackVehicleConversion } = useRelewiseTracking();

    return (
        <SidePanel
            isVisible={visible}
            cancelAction={() => {
                onClose();
            }}
            variant="sm"
        >
            <SidePanelLayout
                closeSidePanel={() => {
                    onClose();
                }}
            >
                {formValues.submitted ? (
                    <GeneralFormSubmitReceipt
                        title={bookTestDriveModalSettings?.receiptSettings?.[0]?.header ?? 'Tak for din forespørsel'}
                        description={
                            bookTestDriveModalSettings?.receiptSettings?.[0]?.description ??
                            'Vi har modtaget din forespørgsel og kontakter dig hurtigst mulig i forhold til om vi kan honrerer den. Aftalen er ikke godkendt, før du får en godkendelsesmail.'
                        }
                        buttonText={
                            !isNullOrEmpty(bookTestDriveModalSettings?.receiptSettings?.[0]?.closeButtonText)
                                ? bookTestDriveModalSettings?.receiptSettings?.[0]?.closeButtonText ?? ''
                                : 'Luk'
                        }
                        onCtaClick={() => onClose()}
                    />
                ) : (
                    <>
                        {testDriveAvailability === 'AvailablePreBooking' ? (
                            <StyledHeader>Forudbestil prøvetur</StyledHeader>
                        ) : (
                            <StyledHeader>
                                {!isNullOrEmpty(bookTestDriveModalSettings?.header) ? bookTestDriveModalSettings?.header : 'Book prøvetur'}
                            </StyledHeader>
                        )}

                        <StyledText>
                            {reactStringReplace(
                                !isNullOrEmpty(bookTestDriveModalSettings?.description)
                                    ? bookTestDriveModalSettings?.description
                                    : 'Du har valgt {{vehicle}}',
                                '{{vehicle}}',
                                (_, idx) => {
                                    return (
                                        <strong key={idx}>
                                            {brand}, {name}.
                                        </strong>
                                    );
                                }
                            )}
                        </StyledText>

                        <Separator marginTop="30px" marginBottom="30px" />
                        {testDriveAvailability === 'AvailablePreBooking' && (
                            <StyledText spacing="0 0 32px 0">
                                Vær en af de første til at prøvekøre bilen, når de første står klar. Udfyld formularen herunder og så kontakter vi
                                dig, straks at der bliver åbnet op for prøvekørsler, så du kan få en af de første pladser.
                            </StyledText>
                        )}
                        {availableDealerships?.length === 0 && !isUsedCar ? (
                            <StyledText spacing="0 0 32px 0">
                                {!isNullOrEmpty(bookTestDriveModalSettings?.vehicleNotAvailableText)
                                    ? bookTestDriveModalSettings?.vehicleNotAvailableText
                                    : 'Vi har desværre ikke den bil du har valgt som demo bil, men udfyld gerne formularen, så kontakter vi dig hurtigst muligt, og finder ud af hvordan vi kan hjælpe dig.'}
                            </StyledText>
                        ) : null}

                        {onlyOneLocation ? (
                            <SectionHeader>Bilen står i {initialDealership?.displayName}</SectionHeader>
                        ) : (
                            <SectionHeader>{desiredLocationText}</SectionHeader>
                        )}

                        {onlyOneLocation ? null : (
                            <DropdownInput
                                options={
                                    dealershipOptions.length > 0
                                        ? dealershipOptions
                                        : fallBackDealerships.map((x) => {
                                              return {
                                                  displayValue: x.displayName,
                                                  value: x.hovedafdelingId,
                                              };
                                          })
                                }
                                value={
                                    isUsedCar
                                        ? { displayValue: initialDealership?.displayName ?? '', value: initialDealership?.hovedafdelingId ?? '' }
                                        : formValues?.dealership
                                }
                                id="dealerships-dropdown"
                                label={
                                    bookTestDriveModalSettings?.whereSectionLabel && !isNullOrEmpty(bookTestDriveModalSettings.whereSectionLabel)
                                        ? bookTestDriveModalSettings.whereSectionLabel
                                        : 'Afdeling'
                                }
                                placeholder={
                                    !isNullOrEmpty(bookTestDriveModalSettings?.whereSectionPlaceholder)
                                        ? bookTestDriveModalSettings?.whereSectionPlaceholder
                                        : 'Vælg en forhandler'
                                }
                                onChange={(option) => {
                                    if (!option) {
                                        return;
                                    }

                                    if (
                                        vehicleAvailability === 'Used' ||
                                        vehicleAvailability === 'Engros' ||
                                        testDriveAvailability === 'AvailablePreBooking'
                                    ) {
                                        setFormValues({
                                            ...formValues,
                                            dealership: option,
                                        });
                                        return;
                                    }

                                    const waitingDays = getCalculatedWaitingDays(option.value);

                                    const newFormDate = addDays(new Date(), waitingDays);
                                    setFormValues({
                                        ...formValues,
                                        dealership: option,
                                        testDriveDate: newFormDate,
                                    });
                                }}
                                isValid={!!formValues?.dealership}
                                canValidateInputField={!!formValues?.canValidate || !!formValues?.dealership}
                                validationMessage={'Vælg en forhandler'}
                                onInputBlur={() => null}
                            />
                        )}

                        <StyledText spacing="10px 0 32px 0">
                            {bookTestDriveModalSettings?.whereSectionDescription &&
                            !isNullOrEmpty(bookTestDriveModalSettings.whereSectionDescription) &&
                            bookTestDriveModalSettings.whereSectionLink
                                ? reactStringReplace(bookTestDriveModalSettings.whereSectionDescription, '{{link}}', (_, idx) => {
                                      return (
                                          <a href={bookTestDriveModalSettings.whereSectionLink.url} target="_blank" rel="noreferrer" key={idx}>
                                              {bookTestDriveModalSettings.whereSectionLink.name}
                                          </a>
                                      );
                                  })
                                : null}
                        </StyledText>

                        {availableDealerships && availableDealerships.length > 0 && testDriveAvailability !== 'AvailablePreBooking' ? (
                            <>
                                <SectionHeader>
                                    {!isNullOrEmpty(bookTestDriveModalSettings?.whenSectionHeader)
                                        ? bookTestDriveModalSettings?.whenSectionHeader
                                        : 'Vælg ønsket dato og tidspunkt'}
                                </SectionHeader>

                                <InputsWrapper>
                                    <DatePickerDropdown
                                        id="test-drive-date-dropdown"
                                        placeholder={
                                            !isNullOrEmpty(bookTestDriveModalSettings?.whenSectionDatePlaceholder)
                                                ? bookTestDriveModalSettings?.whenSectionDatePlaceholder
                                                : 'f.eks. 27.05.2022'
                                        }
                                        value={
                                            formValues?.testDriveDate
                                                ? {
                                                      displayValue: formatDate(formValues?.testDriveDate, DateStyle.dk_full_text),
                                                      value: formatDate(formValues?.testDriveDate, DateStyle.dk_full_text),
                                                  }
                                                : undefined
                                        }
                                        isValid={!!formValues?.testDriveDate && formValues.dealership !== undefined}
                                        canValidateInputField={!!formValues?.canValidate}
                                        label={
                                            bookTestDriveModalSettings?.whenSectionDateLabel &&
                                            !isNullOrEmpty(bookTestDriveModalSettings?.whenSectionDateLabel)
                                                ? bookTestDriveModalSettings?.whenSectionDateLabel
                                                : 'Dato'
                                        }
                                        onChange={() => null}
                                        selectedDay={formValues?.testDriveDate}
                                        onDayChange={(date: Date) =>
                                            setFormValues({
                                                ...formValues,
                                                testDriveDate: date,
                                            })
                                        }
                                        validationMessage={'Vælg en dato'}
                                        waitingDays={bookTestDriveWaitingDays - 1}
                                        disableDays={disabledDays}
                                        disabled={formValues.dealership === undefined}
                                        specialDays={filteredSpecialDays?.filter((x) => x.closed)?.map((x) => new Date(x.date))}
                                    />

                                    <DropdownInput
                                        options={timeSlotOptions}
                                        value={formValues?.timeSlot}
                                        id="time-slot-dropdown"
                                        label={
                                            bookTestDriveModalSettings?.whenSectionTimeslotLabel &&
                                            !isNullOrEmpty(bookTestDriveModalSettings?.whenSectionTimeslotLabel)
                                                ? bookTestDriveModalSettings?.whenSectionTimeslotLabel
                                                : 'Tidspunkt'
                                        }
                                        placeholder={
                                            !isNullOrEmpty(bookTestDriveModalSettings?.whenSectionTimeslotPlaceholder)
                                                ? bookTestDriveModalSettings?.whenSectionTimeslotPlaceholder
                                                : 'Vælg et tidspunkt'
                                        }
                                        disabled={timeSlotOptions.length === 0 || !formValues?.testDriveDate}
                                        onChange={(option) => {
                                            if (option) {
                                                setFormValues({
                                                    ...formValues,
                                                    timeSlot: option,
                                                });
                                            }
                                        }}
                                        isValid={!!formValues?.timeSlot}
                                        canValidateInputField={!!formValues?.canValidate || !!formValues?.timeSlot}
                                        validationMessage={timeSlotValidationMessage}
                                        onInputBlur={() => null}
                                    />
                                </InputsWrapper>
                            </>
                        ) : null}

                        <SectionHeader>
                            {!isNullOrEmpty(bookTestDriveModalSettings?.whoSectionHeader)
                                ? bookTestDriveModalSettings?.whoSectionHeader
                                : 'Dine oplysninger'}
                        </SectionHeader>
                        <InputsWrapper>
                            <TextInput
                                id="full-name-input"
                                label={
                                    bookTestDriveModalSettings?.whoSectionNameLabel && !isNullOrEmpty(bookTestDriveModalSettings?.whoSectionNameLabel)
                                        ? bookTestDriveModalSettings?.whoSectionNameLabel
                                        : 'Fulde navn'
                                }
                                onChange={(e) => {
                                    setFormValues({
                                        ...formValues,
                                        fullName: e.target.value,
                                    });
                                }}
                                type="text"
                                placeholder={
                                    !isNullOrEmpty(bookTestDriveModalSettings?.whoSectionNamePlaceholder)
                                        ? bookTestDriveModalSettings?.whoSectionNamePlaceholder
                                        : 'Indtast dit fulde navn'
                                }
                                value={formValues?.fullName}
                                disabled={false}
                                isValid={validateName(formValues?.fullName ?? '')}
                                canValidateInputField={!!formValues?.canValidate}
                                validationMessage={
                                    !isNullOrEmpty(bookTestDriveModalSettings?.whoSectionNameValidation)
                                        ? bookTestDriveModalSettings?.whoSectionNameValidation
                                        : "Obligatorisk felt. Indtast dit fulde navn, f.eks. 'Hans Hansen'"
                                }
                            />

                            <TextInput
                                id="phone-input"
                                label={
                                    bookTestDriveModalSettings?.whoSectionTlfLabel && !isNullOrEmpty(bookTestDriveModalSettings?.whoSectionTlfLabel)
                                        ? bookTestDriveModalSettings.whoSectionTlfLabel
                                        : 'TLF.'
                                }
                                onChange={(e) => {
                                    setFormValues({
                                        ...formValues,
                                        phone: e.target.value,
                                    });
                                }}
                                type="tel"
                                placeholder={
                                    !isNullOrEmpty(bookTestDriveModalSettings?.whoSectionTlfPlaceholder)
                                        ? bookTestDriveModalSettings?.whoSectionTlfPlaceholder
                                        : 'Indtast dit telefonnummer'
                                }
                                value={formValues?.phone}
                                disabled={false}
                                isValid={validateTel(formValues?.phone ?? '')}
                                canValidateInputField={!!formValues?.canValidate}
                                validationMessage={
                                    !isNullOrEmpty(bookTestDriveModalSettings?.whoSectionTlfValidation)
                                        ? bookTestDriveModalSettings?.whoSectionTlfValidation
                                        : 'Obligatorisk felt. Indtast dit telefonnummer'
                                }
                            />

                            <TextInput
                                id="email-input"
                                label={
                                    bookTestDriveModalSettings?.whoSectionEmailLabel && bookTestDriveModalSettings.whoSectionEmailLabel
                                        ? bookTestDriveModalSettings.whoSectionEmailLabel
                                        : 'E-mail'
                                }
                                onChange={(e) => {
                                    setFormValues({
                                        ...formValues,
                                        email: e.target.value,
                                    });
                                }}
                                type="email"
                                placeholder={
                                    !isNullOrEmpty(bookTestDriveModalSettings?.whoSectionEmailPlaceholder)
                                        ? bookTestDriveModalSettings?.whoSectionEmailPlaceholder
                                        : 'Indtast dit e-mail adresse'
                                }
                                value={formValues?.email}
                                disabled={false}
                                isValid={validateEmail(formValues?.email ?? '')}
                                canValidateInputField={!!formValues?.canValidate}
                                validationMessage={
                                    !isNullOrEmpty(bookTestDriveModalSettings?.whoSectionEmailValidation)
                                        ? bookTestDriveModalSettings?.whoSectionEmailValidation
                                        : 'Obligatorisk felt. Indtast din e-mail adresse'
                                }
                            />

                            <TextareaInput
                                hideValidation={true}
                                id="user-message"
                                isValid={true}
                                label={
                                    bookTestDriveModalSettings?.whoSectionMessageLabel &&
                                    !isNullOrEmpty(bookTestDriveModalSettings?.whoSectionMessageLabel)
                                        ? bookTestDriveModalSettings?.whoSectionMessageLabel
                                        : 'Besked'
                                }
                                placeholder={
                                    !isNullOrEmpty(bookTestDriveModalSettings?.whoSectionMessagePlaceholder)
                                        ? bookTestDriveModalSettings?.whoSectionMessagePlaceholder
                                        : 'Skriv til os her'
                                }
                                value={formValues?.message}
                                onChange={(e) => {
                                    setFormValues({
                                        ...formValues,
                                        message: e.target.value,
                                    });
                                }}
                            />
                        </InputsWrapper>

                        <Separator marginTop="30px" marginBottom="30px" />

                        <TermsAndConditions>
                            <CheckBox
                                id="personal-data-policy"
                                value="0"
                                checked={!!formValues?.termsAccepted}
                                onChange={(checked) =>
                                    setFormValues({
                                        ...formValues,
                                        termsAccepted: checked,
                                    })
                                }
                                textAlign="top"
                                isValid={formValues?.termsAccepted}
                                required={true}
                                validationMessage={
                                    !isNullOrEmpty(bookTestDriveModalSettings?.dataPolicyValidation)
                                        ? bookTestDriveModalSettings?.dataPolicyValidation
                                        : 'Du skal acceptere vores persondatapolitik for at fortsætte'
                                }
                                canValidate={!!formValues?.canValidate}
                            >
                                <p>
                                    {!isNullOrEmpty(bookTestDriveModalSettings?.dataPolicyLabel)
                                        ? bookTestDriveModalSettings?.dataPolicyLabel
                                        : "Jeg godkender Ejner Hessel's"}{' '}
                                    <DataPolicyLink href={bookTestDriveModalSettings?.dataPolicyLink?.url ?? '/'} target="_blank" rel="noreferrer">
                                        {!isNullOrEmpty(bookTestDriveModalSettings?.dataPolicyLink?.name)
                                            ? bookTestDriveModalSettings?.dataPolicyLink?.name
                                            : 'persondatapolitik'}
                                    </DataPolicyLink>
                                </p>
                            </CheckBox>
                        </TermsAndConditions>

                        <StyledSubmitButton
                            variant="primary"
                            onClick={async () => {
                                // Set can validate so the inputs know they can show validation messages
                                if (!formValues?.canValidate) {
                                    setFormValues({
                                        ...formValues,
                                        canValidate: true,
                                    });
                                }

                                if (
                                    validateEmail(formValues?.email ?? '') &&
                                    validateTel(formValues?.phone ?? '') &&
                                    validateName(formValues?.fullName ?? '') &&
                                    (availableDealerships && availableDealerships.length > 0 && testDriveAvailability !== 'AvailablePreBooking'
                                        ? !!formValues?.testDriveDate && !!formValues?.timeSlot
                                        : true) &&
                                    formValues?.termsAccepted &&
                                    !!formValues?.dealership
                                ) {
                                    const fullDate = formValues?.testDriveDate ?? new Date();
                                    const [hours, minutes] = formValues.timeSlot?.value.split(':') ?? [];
                                    if (!Number.isNaN(hours) && !Number.isNaN(minutes)) {
                                        fullDate.setHours(Number(hours));
                                        fullDate.setMinutes(Number(minutes));
                                        fullDate.setSeconds(0);
                                        fullDate.setMilliseconds(0);
                                    }

                                    const requestObject = {
                                        vehicle: {
                                            brand: brand,
                                            model: brandModel,
                                            variant: name,
                                            url: vehicleUrl,
                                            type: vehicleType,
                                            availability: vehicleAvailability,
                                            referenceNumber: referenceNumber,
                                        },
                                        departmentId: formValues.dealership?.value ?? defaultLocationId,
                                        departmentName: formValues.dealership?.displayValue ?? '',
                                        name: formValues?.fullName,
                                        phone: formValues?.phone,
                                        email: formValues?.email,
                                        comment: formValues?.message,
                                        consent: formValues?.termsAccepted,
                                        consentText: bookTestDriveModalSettings?.consentCode,
                                    };
                                    await submitLeadData(
                                        testDriveAvailability === 'AvailablePreBooking'
                                            ? 'pre-book-test-drive'
                                            : availableDealerships?.length === 0 && !isUsedCar
                                            ? 'book-test-drive-of-none-available'
                                            : 'book-test-drive',
                                        testDriveAvailability === 'AvailablePreBooking' || availableDealerships.length === 0
                                            ? requestObject
                                            : {
                                                  ...requestObject,
                                                  requestedDriveTime: formatDate(fullDate, DateStyle.booking_format),
                                              }
                                    );

                                    trackVehicleConversion(vehicleEntityId, vehicleTrackingPrice ?? 0);

                                    setFormValues({
                                        ...formValues,
                                        submitted: true,
                                    });
                                }
                            }}
                        >
                            {testDriveAvailability === 'AvailablePreBooking' ? (
                                <span>Forudbestil din prøvetur</span>
                            ) : (
                                <span>
                                    {!isNullOrEmpty(bookTestDriveModalSettings?.submitButtonText)
                                        ? bookTestDriveModalSettings?.submitButtonText
                                        : 'Send forespørgsel'}
                                </span>
                            )}
                        </StyledSubmitButton>
                    </>
                )}
            </SidePanelLayout>
        </SidePanel>
    );
};
