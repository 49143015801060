import styled from 'styled-components';
import { device } from '../../../../lib/media-query';
import { StyledProductDetailGridItem } from '../shared/product-detail-page-shared.styled';

export const StyledProductSpecsWrapper = styled.div``;
export const StyledProductSpecsHeader = styled.h2`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
`;

export const StyledProductSpec = styled(StyledProductDetailGridItem)`
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;

    & span:first-child {
        opacity: 1;
        color: rgba(125, 125, 125, 1);
        font-size: 14px;
        font-weight: 500;
    }

    & span:last-child {
        opacity: 1;
        color: rgba(11, 11, 11, 1);
        font-size: 14px;
        font-weight: 600;
    }
`;

export const StyledProductSpecAllSpecsWrapper = styled.div`
    display: grid;
    gap: 10px;
    margin-top: 25px;
    align-items: center;
    text-align: left;
    justify-content: left;

    @media ${device.tablet} {
        display: flex;
        justify-content: space-between;
    }
`;
