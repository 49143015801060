import { FC, useMemo } from 'react';
import { CheckBox, Slot } from '../../shared';
import { CheckboxText, Header, TimeSlot, TimeSlotPickupHeader } from '../time-slot-picker.styled';

import { BookingWorkshopsStore } from '../../../lib/state/booking/workshops';
import { isEqual, differenceInHours, compareAsc } from 'date-fns';
import { BookingStepsStore } from '../../../lib/state/booking/steps';
import { hesselApi, umbraco } from '../../../lib/api';
import { BookingStepWorkshop } from '../../../lib/api/models/umbraco';
import { BenefitAgreementName, DateStyle, formatDate, getEndTime } from '../../../utils/helpers';
import { Workshop } from '../../../lib/state/booking/workshops/booking-workshops.types';
import { useAgreements } from '../../../hooks/use-agreements';
import { AnimatedOWnRisk, CheckboxWrapper } from './customer-delivery-and-pickup.styled';
import { useSpring, config } from 'react-spring';
import CustomerAgreementText from '../../booking/booking-steps/services/customer-agreement-text/customer-agreement-text.component';
import { useBenefitAgreementInfo } from '../../../hooks/booking/booking/use-benefit-agreement-info';
import { BookingServiceProductsStore } from '../../../lib/state/booking/service-products';
import { filterStepType } from '../../../lib/state/booking/steps/booking-steps.helpers';

type Props = {
    workshopContent: BookingStepWorkshop;
};

export const CustomerDeliveryAndPickup: FC<Props> = ({ workshopContent }) => {
    const { selectedDate, selectedTimeSlot, rentalCar, ownRisk, selectedWorkshop, workshops } = BookingWorkshopsStore.useStoreState((state) => state);
    const { setTimeSlot, setRentalCar, setOwnRisk } = BookingWorkshopsStore.useStoreActions((actions) => actions);
    const { selectedServiceProductsIds } = BookingServiceProductsStore.useStoreState((state) => state);
    const servicesStep = BookingStepsStore.useStoreState((state) => state.steps.find(filterStepType('Services'))?.content);
    const yourCarStep = BookingStepsStore.useStoreState(
        (state) => state.steps.find((x) => (x.type = 'YourCar'))?.content as umbraco.BookingStepYourCar | undefined
    );

    const hasAgreements = useAgreements();
    const agreement = useBenefitAgreementInfo();

    const workshop: Workshop | undefined = useMemo(() => workshops.find(({ sabId }) => sabId === selectedWorkshop), [workshops, selectedWorkshop]);

    const isAvailable = (startTime: Date): boolean => {
        const now = new Date();
        return compareAsc(startTime, now) === 1;
    };

    const dropOffTimes = useMemo(() => {
        if (!workshop) {
            return [];
        }

        const availableDay = workshop.availableDays.find(
            (x) => formatDate(x.date, DateStyle.yyyy_mm_dd) === formatDate(selectedDate, DateStyle.yyyy_mm_dd)
        );

        if (!availableDay) {
            return [];
        }

        return availableDay.timeSlots;
    }, [selectedDate, workshop]);

    const deliveryDates = useMemo<hesselApi.AppointmentAvailability<Date>>(
        () => ({
            date: selectedDate,
            timeSlots: dropOffTimes.map((ts) => {
                return {
                    startDateTime: ts.startDateTime,
                    available: ts.available && isAvailable(ts.startDateTime),
                    endDateTime: getEndTime(ts.endDateTime),
                    estimatedWorkHours: differenceInHours(ts.endDateTime, ts.startDateTime),
                    estimatedWorkMinutes: ts.estimatedWorkMinutes,
                };
            }),
        }),
        [dropOffTimes, selectedDate]
    );

    const eligibleForFreeRentalCar = useMemo(() => {
        const agreementName = agreement?.agreementID as BenefitAgreementName;
        const hasMercedesBenefitAgreement = agreementName === 'HesselStarPlusBusiness' || agreementName === 'HesselStarPlusComplete';
        if (!hasMercedesBenefitAgreement) return false;

        const anyServiceInspectionSelected =
            servicesStep?.serviceInspectionLinks?.some((serviceInspectionProduct) =>
                selectedServiceProductsIds.includes(serviceInspectionProduct.serviceId)
            ) ?? false;

        return anyServiceInspectionSelected;
    }, [agreement?.agreementID, selectedServiceProductsIds, servicesStep?.serviceInspectionLinks]);

    const fadeContent = useSpring({
        config: { ...config.wobbly, friction: 50 },
        opacity: rentalCar ? 1 : 0,
        display: rentalCar ? 'inline-block' : 'none',
    });

    return (
        <>
            <Header>{workshopContent.timeSlotDeliveryTimeHeader}</Header>

            <TimeSlot>
                {deliveryDates?.timeSlots?.map((timeSlot, idx) => (
                    <Slot
                        key={`${idx}:${timeSlot.startDateTime}`}
                        isAvailable={timeSlot.available}
                        isSelected={isEqual(timeSlot.startDateTime, selectedTimeSlot?.startDateTime ?? 0)}
                        time={timeSlot.startDateTime}
                        onClick={() => setTimeSlot(timeSlot)}
                    />
                ))}
            </TimeSlot>

            <TimeSlotPickupHeader>
                {selectedTimeSlot?.endDateTime ? (
                    <>
                        {workshopContent.timeSlotPickupHeader}
                        {` `}
                        <b>{formatDate(getEndTime(selectedTimeSlot?.endDateTime), DateStyle.HH_mm)}</b>
                    </>
                ) : null}
            </TimeSlotPickupHeader>

            <CheckboxWrapper>
                <CheckBox id="cbRentalCar" value="1" checked={rentalCar ?? false} onChange={(checked) => setRentalCar(checked)} textAlign="top">
                    <CheckboxText>
                        <p>{eligibleForFreeRentalCar ? workshopContent.freeRentalCarLabel : workshopContent.rentalCarLabel}</p>
                        {hasAgreements &&
                            yourCarStep &&
                            workshopContent.eligibleForFreeRentalCar &&
                            workshopContent.mightBeEligibleForFreeRentalCar && (
                                <CustomerAgreementText
                                    hasAgreements={true}
                                    preLinkText={
                                        eligibleForFreeRentalCar
                                            ? workshopContent.eligibleForFreeRentalCar
                                            : workshopContent.mightBeEligibleForFreeRentalCar
                                    }
                                    linkText={yourCarStep.registeredSectionActionText}
                                ></CustomerAgreementText>
                            )}
                    </CheckboxText>
                </CheckBox>
                <AnimatedOWnRisk style={fadeContent}>
                    <CheckBox id="cbOwnRisk" value="2" checked={ownRisk ?? false} onChange={(checked) => setOwnRisk(checked)} textAlign="top">
                        <p>{workshopContent.ownRiskLabel}</p>
                    </CheckBox>
                </AnimatedOWnRisk>
            </CheckboxWrapper>
        </>
    );
};
