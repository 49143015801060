import styled from 'styled-components';

export const AccordionWrapper = styled.div`
    width: 100%;
    margin-bottom: 5px;
`;

export const AccordionText = styled.p`
    font-weight: 400;
`;
