import { DataLayerObject, GtmItem, GtmTransaction } from './gtm-tracking.types';
import { GtmTrackingMode } from './hooks/use-gtm-tracking';

export const COMMON_GTM = {
    CHECKOUT: 'checkout',
    CHECKOUTOPTION: 'checkoutOption',
    CLICK: 'Klik',
    COMPLETED: 'Fuldendt',
    DKK: 'DKK',
    PURCHASE: 'purchase',
    STARTED: 'Påbegyndt',
};

export function defaultCheckoutDataLayer(type: string, step: number, products: GtmItem[]): DataLayerObject {
    return {
        event: COMMON_GTM.CHECKOUT,
        type,
        step,
        currencyCode: COMMON_GTM.DKK,
        ecommerce: {
            checkout: {
                actionField: { step },
                products,
            },
        },
    };
}

export function defaultCheckoutOptionDataLayer(type: string, step: number, option?: string): DataLayerObject {
    return {
        event: COMMON_GTM.CHECKOUTOPTION,
        type,
        ecommerce: {
            checkout_option: {
                actionField: { step, option },
            },
        },
    };
}

export function defaultPurchaseDataLayer(type: string, actionField: GtmTransaction, products: GtmItem[]): DataLayerObject {
    return {
        event: COMMON_GTM.PURCHASE,
        type,
        currencyCode: COMMON_GTM.DKK,
        ecommerce: {
            purchase: {
                actionField,
                products,
            },
        },
    };
}

export function resolveTrackingMode(isEnabled: boolean, isDevelopmentEnvironment: boolean): GtmTrackingMode {
    if (!isEnabled) {
        return GtmTrackingMode.Disabled;
    }

    return isDevelopmentEnvironment ? GtmTrackingMode.LogAndTrack : GtmTrackingMode.TrackOnly;
}
