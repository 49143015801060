import React, { VFC } from 'react';
import { sharedTypes } from '../../../lib/api/models/umbraco';
import { MEDIA_URL } from '../../../utils/environment-constants';
import { StyledUspTemplate, ImageWrapper, TopSideIcon, Header, Description, BottomSideIcon } from './usp-template.styled';
import { Button } from '../button';

export type UspTemplateProps = sharedTypes.UspTemplate & {
    onUspClick?: () => void;
    isAboveFold: boolean;
    readMore?: boolean;
};

const UspTemplateMemo: VFC<UspTemplateProps> = ({ header, description, topSideIcon, bottomSideIcon, onUspClick, isAboveFold, readMore }) => {
    return (
        <StyledUspTemplate clickable={!!onUspClick} onClick={() => onUspClick?.()}>
            {topSideIcon ? (
                <ImageWrapper>
                    <TopSideIcon src={MEDIA_URL + topSideIcon.src} alt={topSideIcon.name} loading={isAboveFold ? 'eager' : 'lazy'} />
                </ImageWrapper>
            ) : (
                <div />
            )}

            <Header>{header}</Header>

            <Description>
                {description}{' '}
                {readMore && (
                    <Button onClick={() => {}} variant="link">
                        Læs mere
                    </Button>
                )}
            </Description>

            {bottomSideIcon ? (
                <ImageWrapper>
                    <BottomSideIcon src={MEDIA_URL + bottomSideIcon.src} alt={bottomSideIcon.name} loading={isAboveFold ? 'eager' : 'lazy'} />
                </ImageWrapper>
            ) : (
                <div />
            )}
        </StyledUspTemplate>
    );
};

export const UspTemplate = React.memo(UspTemplateMemo);
