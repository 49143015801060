import styled from 'styled-components';
import { UmbracoForms } from '../../spots/umbraco-forms';

export const HeaderSection = styled.header``;

export const Header = styled.p`
    font: 700 25px/30px 'Corporate E', Serif;
    margin: 0 0 14px;
`;

export const SubHeader = styled.p`
    font-weight: 400;
`;

export const BookAdvisorModalBody = styled(UmbracoForms)``;

export const StyledDescription = styled.div`
    & > p {
        color: #0b0b0b;
        font-size: 14px;
        letter-spacing: 0;
        font-weight: normal;
        margin-bottom: 30px;
    }

    & ul,
    & ol {
        font-weight: normal;
        list-style: revert;
        padding: revert;
        margin: revert;
    }

    & ul li,
    & ol li {
        font-weight: normal;
    }
`;

export const CtaWrapper = styled.div`
    margin-top: 30px;
`;
