import { action, persist } from 'easy-peasy';
import { createContextStoreWithRuntimeModel } from '../../helpers/context-store.helpers';
import { MiniBasketActions, MiniBasketInjections, MiniBasketState, MiniBasketStoreModel } from './mini-basket.types';

const miniBasketDefaultState = (): MiniBasketState => persist({}, { storage: 'localStorage' });

const miniBasketActions = (): MiniBasketActions => ({
    setProductData: action((state, payload) => {
        state.productData = payload;
    }),
});

export const MiniBasketStore = createContextStoreWithRuntimeModel<MiniBasketStoreModel, Partial<MiniBasketState>, MiniBasketInjections>(
    () => ({
        ...miniBasketDefaultState(),
        ...miniBasketActions(),
    }),
    { name: 'miniBasketStore' }
);
