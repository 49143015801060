import Image from 'next/image';
import { FC, memo } from 'react';
import { Button } from '../../../shared';
import { StyledCard, StyledProductDetailGrid } from '../shared/product-detail-page-shared.styled';
import { StyledEquipmentDescription, StyledEquipmentGridItem, StyledEquipmentHeader } from './equipment.card.styled';

type EquipmentCardProps = {
    items?: string[];
    onShowSpecsClick: () => void;
    header?: string;
    description?: string;
    showMoreRelevant: boolean;
};
const EquipmentCard: FC<EquipmentCardProps> = memo(({ items, onShowSpecsClick, header, description, showMoreRelevant }) => {
    return items && items.length > 0 ? (
        <StyledCard paddingStyle="large">
            <StyledEquipmentHeader>{header ?? 'Udstyr og tilbehør'}</StyledEquipmentHeader>
            <StyledEquipmentDescription>{description ?? 'Bemærk at bilen godt kan stå med mere udstyr end nedenstående'}</StyledEquipmentDescription>
            <StyledProductDetailGrid>
                {items.slice(0, 10).map((eq) => {
                    return (
                        <StyledEquipmentGridItem key={eq}>
                            <Image src={'/icons/checkmark.svg'} alt="checkmark ikon" width={25} height={25} />
                            <span>{eq}</span>
                        </StyledEquipmentGridItem>
                    );
                })}
            </StyledProductDetailGrid>
            {showMoreRelevant && (
                <div style={{ marginTop: 25 }}>
                    <Button onClick={onShowSpecsClick}>Se alt udstyr og tilbehør</Button>
                </div>
            )}
        </StyledCard>
    ) : null;
});

export default EquipmentCard;
