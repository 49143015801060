import styled from 'styled-components';
import { device } from '../../../lib/media-query';
import { DropdownInput } from '../../forms/inputs';
import { Button } from '../../shared';

export const FilterWrapper = styled.div`
    width: 100%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;

    padding: 0 20px;

    @media ${device.laptopS} {
        padding: revert;
        justify-content: space-evenly;
        flex-direction: revert;
        max-width: revert;

        & > * {
            width: 100%;
        }
    }

    & button {
        height: 100%;
        width: 100%;
        min-height: 50px;
        z-index: 0;
    }
`;

export const StyledSubmitButton = styled(Button)`
    min-width: 200px;
`;

export const StyledButtonText = styled.p`
    white-space: nowrap;
`;

export const StyledPriceFilterDropdown = styled(DropdownInput)`
    min-width: revert;
    @media ${device.tablet} {
        min-width: 250px;
    }
`;
