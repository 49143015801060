import React from 'react';
import { SpotOverlaySetting } from '../../lib/api/models/umbraco/content-spot';
import { StyledOverlay } from './overlay.styled';
import { useOverlay } from './use-overlay';

type SpotOverlayProps = {
    overlay: SpotOverlaySetting;
};

export const SpotOverlay: React.FC<SpotOverlayProps> = ({ overlay }) => {
    const { overlayStyles } = useOverlay(overlay);
    return <StyledOverlay style={overlayStyles} />;
};
