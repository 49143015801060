import { VFC } from 'react';
import { hireCheckoutTypes } from '../../../../../../lib/api/models/umbraco';
import { LeadDurationDeliveryStore } from '../../../../../../lib/state/hessel-site/lead-checkout/duration-delivery';
import { ProductDetailsStore } from '../../../../../../lib/state/hessel-site/product-details';
import { DateStyle, formatDate } from '../../../../../../utils/helpers';
import { SubmittedInfo } from '../../../../../shared/submitted-info';
import { StyledDurationDeliverySummary } from './duration-delivery-summary.styled';

type Props = {
    content: hireCheckoutTypes.HireStepDelivery;
};

export const DurationDeliverySummary: VFC<Props> = ({ content }) => {
    const { deliveryMode, showEmployees, employees, selectedDepartment, selectedEmployeeId } = LeadDurationDeliveryStore.useStoreState(
        (state) => state
    );

    const { selectedStartupDate } = ProductDetailsStore.useStoreState((state) => state);

    const { selectedLeasingPeriod } = ProductDetailsStore.useStoreState((state) => state);

    const selectedEmployee = employees.find((x) => x.employeeId === selectedEmployeeId);

    return (
        <StyledDurationDeliverySummary>
            <SubmittedInfo label={content.durationDropdownLabel} text={selectedLeasingPeriod?.displayValue ?? ''} />
            <SubmittedInfo
                label={'Leveringsmetod'}
                text={deliveryMode === 'dealershipPickup' ? content.pickupAtDealershipText : content.deliveryToAddressText}
            />
            {selectedDepartment ? <SubmittedInfo label={content.dealershipDropdownLabel} text={selectedDepartment.dealershipLabel} /> : null}
            {selectedEmployee && showEmployees ? <SubmittedInfo label={content.employeesDropdownLabel} text={selectedEmployee?.name} /> : null}
            {selectedStartupDate ? (
                <SubmittedInfo label={content.deliveryDateDropdownLabel} text={formatDate(selectedStartupDate, DateStyle.simple_danish)} />
            ) : null}
        </StyledDurationDeliverySummary>
    );
};
