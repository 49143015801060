import React, { FC, useState } from 'react';
import { CardAgreements } from '../../your-car/agreements';
import { useUI } from '../../../../ui';
import { Button } from '../../../../shared';

type Props = {
    hasAgreements: boolean;
    preLinkText: string;
    linkText: string;
};

const CustomerAgreementText: FC<Props> = ({ hasAgreements, preLinkText, linkText }) => {
    const ui = useUI();
    const [showAgreements, setShowAgreements] = useState(false);

    return hasAgreements ? (
        <>
            <small>
                {preLinkText}{' '}
                <Button
                    variant="link"
                    onClick={(e) => {
                        e.stopPropagation();
                        setShowAgreements(true);
                        ui.removeScroll();
                    }}
                >
                    <small>{linkText}</small>
                </Button>
            </small>
            <CardAgreements
                cancelAction={() => {
                    setShowAgreements(false);
                    ui.applyScroll();
                }}
                isVisible={showAgreements}
            />
        </>
    ) : null;
};

export default CustomerAgreementText;
