import { action, persist, thunk } from 'easy-peasy';
import { SearchActions, SearchState, SearchStore, SearchThunks } from './search.types';
import { createContextStoreWithRuntimeModel } from '../helpers/context-store.helpers';
import { doRelewiseSearch, getNextPageOfVehicles, getPopularSearchTerms } from '../../api/search/search.api';

const searchDefaultState = (runtimeModel: Partial<SearchState> | undefined): SearchState => ({
    searchTerm: runtimeModel?.searchTerm ?? '',
    searchVehicleResults: runtimeModel?.searchVehicleResults ?? [],
    searchReturnedNoResults: runtimeModel?.searchReturnedNoResults ?? false,
    searchPredictionResults: runtimeModel?.searchPredictionResults ?? [],
    popularSearchTerms: runtimeModel?.popularSearchTerms ?? [],
    searchContentResults: runtimeModel?.searchContentResults ?? [],
    productSearchTotalCount: runtimeModel?.productSearchTotalCount ?? 0,
    currentPage: runtimeModel?.currentPage ?? 1,
});

const searchActions = (): SearchActions => ({
    setSearchTerm: action((state, payload) => {
        state.searchTerm = payload;
    }),
    setSearchVehicleResults: action((state, payload) => {
        state.searchVehicleResults = payload;
    }),
    setSearchReturnedNoResults: action((state, payload) => {
        state.searchReturnedNoResults = payload;
    }),
    setSearchPredictionResults: action((state, payload) => {
        state.searchPredictionResults = payload;
    }),
    resetSearch: action((state) => {
        state.searchTerm = '';
        state.searchVehicleResults = [];
        state.searchReturnedNoResults = false;
        state.searchPredictionResults = [];
        state.popularSearchTerms = [];
        state.searchContentResults = [];
        state.currentPage = 1;
    }),
    setPopularSearchTerms: action((state, payload) => {
        state.popularSearchTerms = payload;
    }),
    setContentResults: action((state, payload) => {
        state.searchContentResults = payload;
    }),
    setProductSearchTotalCount: action((state, payload) => {
        state.productSearchTotalCount = payload;
    }),
    addToSearchVehicleResults: action((state, payload) => {
        state.searchVehicleResults = [...state.searchVehicleResults, ...payload];
    }),
    setCurrentPage: action((state, payload) => {
        state.currentPage = payload;
    }),
});

const searchThunks = (): SearchThunks => ({
    doSearch: thunk(async (actions, payload) => {
        const result = await doRelewiseSearch(payload.searchTerm, payload.segment);
        if (result) {
            actions.setSearchReturnedNoResults(
                result.productSearchResults.results?.length === 0 && result.contentSearchResults.results?.length === 0
            );
            if (result.productSearchResults) {
                actions.setSearchVehicleResults(result.productSearchResults.results ?? []);
                actions.setProductSearchTotalCount(result.productSearchResults.hits ?? 0);
            }
            if (result.searchTermsResults) {
                actions.setSearchPredictionResults(result.searchTermsResults.predictions ?? []);
            }
            if (result.contentSearchResults) {
                actions.setContentResults(result.contentSearchResults.results ?? []);
            }
        }
    }),
    getPopularSearchTerms: thunk(async (actions, payload) => {
        const result = await getPopularSearchTerms(payload.segment);
        if (result) {
            actions.setPopularSearchTerms(result.recommendations ?? []);
        }
    }),
    getNextPageVehicleResults: thunk(async (actions, payload) => {
        const result = await getNextPageOfVehicles(payload.searchTerm, payload.page, payload.segment);
        if (result) {
            actions.addToSearchVehicleResults(result.results ?? []);
            actions.setCurrentPage(payload.page);
        }
    }),
});

export const SearchStoreContext = createContextStoreWithRuntimeModel<SearchStore, Partial<SearchState>>(
    (runtimeModel) =>
        persist(
            {
                ...searchDefaultState(runtimeModel),
                ...searchActions(),
                ...searchThunks(),
            },
            { storage: 'localStorage' }
        ),
    { name: 'SearchStore' }
);
