import { FC } from 'react';
import { StyledAgreementSubheader, StyledContainerBody, StyledContainerHeader, StyledContainer, StyledPrice } from '../service-agreement-step.styled';
import { CheckBox } from '../../../../shared';

type Props = {
    onChecked: () => void;
    onUnchecked: () => void;
    checked: boolean;
    title: string;
    titleDescription?: string;
    priceText?: string;
    children: React.ReactNode;
    id: string;
};

const ServiceAgreementContainer: FC<Props> = ({ onChecked, onUnchecked, checked, title, titleDescription, priceText, children, id }) => {
    return (
        <StyledContainer>
            <StyledContainerHeader>
                <CheckBox checked={checked} id={id} onChange={(value) => (value ? onChecked() : onUnchecked())} value="">
                    <p>
                        <strong>{title}</strong>
                    </p>
                </CheckBox>
                <StyledPrice checked={checked}>
                    {checked ? '' : 'Fra'} {`${priceText}`}
                </StyledPrice>
            </StyledContainerHeader>
            {checked && (
                <StyledContainerBody>
                    <StyledAgreementSubheader>{titleDescription}</StyledAgreementSubheader>
                    {children}
                </StyledContainerBody>
            )}
        </StyledContainer>
    );
};

export default ServiceAgreementContainer;
