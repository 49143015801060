import { FC, useEffect, useState } from 'react';
import { FilterUpdateNotification } from '../../../../../../lib/api/models/hessel-api/hire';
import { FacetOption } from '../../../../../../lib/view-models/vehicle';
import { CheckBox } from '../../../../../shared/checkbox';
import { OptionWrapper, StyledOptionContentWrapper } from '../checkox-filter.styled';

type IProps = {
    option: FacetOption;
    onCheckNotifications?: FilterUpdateNotification[];
    onUncheckNotification?: FilterUpdateNotification;
    onUpdate: (updates: FilterUpdateNotification[]) => void;
    disableInteraction?: boolean;
};

export const CheckboxRenderer: FC<IProps> = ({
    option,
    onCheckNotifications,
    onUncheckNotification,
    onUpdate,
    children,
    disableInteraction = false,
}) => {
    const [checkboxState, setCheckboxState] = useState(option.selected);
    const getFacetsToUncheck = (option: FacetOption, accumulator: FilterUpdateNotification[]) => {
        accumulator.push({ query: option.filterQueryValue, action: 'Remove' });
        option.subFacets?.forEach((x) => {
            if (x.selected) {
                accumulator.push({ query: x.filterQueryValue, action: 'Remove' });
                if (x.subFacets && x.subFacets.length > 0) {
                    getFacetsToUncheck(x, accumulator);
                }
            }
        });
        return accumulator;
    };

    useEffect(() => {
        if (option.selected !== checkboxState) setCheckboxState(option.selected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [option]);

    return (
        <OptionWrapper disabled={(option.count === 0 && !option.selected) || disableInteraction}>
            <CheckBox
                id={option.filterQueryValue}
                value={option.key}
                onChange={(checked) => {
                    setCheckboxState(checked);
                    if (!checked && option.subFacets && option.subFacets.length > 0) {
                        //When unchecking a facet we uncheck every subfacet of this facet aswell
                        //Also, if the facet is a subfacet, we need to check it's parent
                        onUpdate([...getFacetsToUncheck(option, []), onUncheckNotification ?? ({} as FilterUpdateNotification)]);
                    } else if (checked) {
                        //When checking a facet that is a subfacet we must unselect the parent facet, since we only send the "deepest" facet to the BE
                        onUpdate([...(onCheckNotifications ?? []), { query: option.filterQueryValue, action: 'Add' }]);
                    } else {
                        //When we uncheck a facet, if it is a subfacet, we have to reselect it's parent facet
                        onUpdate([{ query: option.filterQueryValue, action: 'Remove' }, onUncheckNotification ?? ({} as FilterUpdateNotification)]);
                    }
                }}
                checked={checkboxState}
                textAlign="center"
                checkboxSize={15}
            >
                <StyledOptionContentWrapper>{children}</StyledOptionContentWrapper>
            </CheckBox>
        </OptionWrapper>
    );
};
