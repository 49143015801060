import { DayPicker } from 'react-day-picker';
import styled from 'styled-components';

export const CustomCalendar = styled(DayPicker)`
    --rdp-cell-size: 35px;
    --rdp-cell-padding: 10px;
    --rdp-accent-color: ${({ theme }) => theme.palette.primary.main};
    --rdp-background-color: #fff;

    /* Switch to dark colors for dark themes */
    --rdp-accent-color-dark: #0a2841;
    --rdp-background-color-dark: #180270;

    /* Outline border for focused elements */
    --rdp-outline: 2px solid var(--rdp-accent-color);

    display: block;
    font-size: 1rem;
    padding: 0.1rem 0.5rem;
    max-width: 350px;
    max-height: 450px;
    align-self: center;

    @media (min-width: 360px) {
        --rdp-cell-size: 40px;
    }

    /* Hide elements for devices that are not screen readers */
    .rdp-vhidden {
        border: 0;
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        top: 0;
        width: 1px;
    }

    /* Buttons */
    .rdp-button_reset {
        appearance: none;
        position: relative;
        margin: 0;
        padding: 0;
        cursor: default;
        color: inherit;
        outline: none;
        background: none;
        font: inherit;

        -moz-appearance: none;
        -webkit-appearance: none;
    }

    .rdp-button {
        border: 2px solid transparent;
    }

    .rdp-button[disabled] {
        opacity: 0.25;
        cursor: not-allowed;
        width: var(--rdp-cell-size);
        height: var(--rdp-cell-size);
    }

    .rdp-button:not([disabled]) {
        cursor: pointer;
    }

    .rdp-button:focus:not([disabled]),
    .rdp-button:active:not([disabled]) {
        color: inherit;
        border: var(--rdp-outline);
        background-color: var(--rdp-background-color);
    }

    .rdp-button:hover:not([disabled]) {
        background-color: var(--rdp-background-color);
    }

    .rdp-months {
        display: flex;
    }

    .rdp-month {
        margin: 0 1em;
    }

    .rdp-month:first-child {
        margin-left: 0;
    }

    .rdp-month:last-child {
        margin-right: 0;
    }

    .rdp-table {
        margin: 0;
        max-width: calc(var(--rdp-cell-size) * 7);
        border-collapse: collapse;
    }

    .rdp-with_weeknumber .rdp-table {
        max-width: calc(var(--rdp-cell-size) * 8);
        border-collapse: collapse;
    }

    .rdp-caption {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        padding-top: 10px;
        margin-bottom: 15px;
        text-align: left;
    }

    .rdp-multiple_months .rdp-caption {
        position: relative;
        display: block;
        text-align: center;
    }

    .rdp-caption_dropdowns {
        position: relative;
        display: inline-flex;
    }

    .rdp-caption_label {
        position: relative;
        z-index: 1;
        display: inline-flex;
        align-items: center;
        margin: 0;
        padding: 0 0.25em;
        white-space: nowrap;
        color: currentColor;
        border: 0;
        border: 2px solid transparent;
        font-family: inherit;
        font-size: 17px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 27px;
    }

    .rdp-nav {
        white-space: nowrap;
    }

    .rdp-multiple_months .rdp-caption_first .rdp-nav {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }

    .rdp-multiple_months .rdp-caption_last .rdp-nav {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }

    .rdp-nav_button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: var(--rdp-cell-size);
        height: var(--rdp-cell-size);
        padding: 0.25em;
        border-radius: 100%;
    }

    /* ---------- */
    /* Dropdowns  */
    /* ---------- */

    .rdp-dropdown_year,
    .rdp-dropdown_month {
        position: relative;
        display: inline-flex;
        align-items: center;
    }

    .rdp-dropdown {
        appearance: none;
        position: absolute;
        z-index: 2;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        margin: 0;
        padding: 0;
        cursor: inherit;
        opacity: 0;
        border: none;
        background-color: transparent;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
    }

    .rdp-dropdown[disabled] {
        opacity: unset;
        color: unset;
    }

    .rdp-dropdown:focus:not([disabled]) + .rdp-caption_label,
    .rdp-dropdown:active:not([disabled]) + .rdp-caption_label {
        border: var(--rdp-outline);
        border-radius: 6px;
        background-color: var(--rdp-background-color);
    }

    .rdp-dropdown_icon {
        margin: 0 0 0 5px;
    }

    .rdp-head {
        border: 0;
    }

    .rdp-head_row,
    .rdp-row {
        height: 100%;
    }

    .rdp-head_cell {
        vertical-align: middle;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0;
        line-height: 19px;
        text-align: center;
        height: 100%;
        height: var(--rdp-cell-size);
        padding: 0;
    }

    .rdp-tbody {
        border: 0;
    }

    .rdp-foot {
        margin: 0.5em;
    }

    .rdp-cell {
        width: var(--rdp-cell-size);
        height: 100%;
        height: var(--rdp-cell-size);
        padding: 0;
        padding-bottom: var(--rdp-cell-padding);
        text-align: center;
    }

    .rdp-weeknumber {
        font-size: 0.75em;
    }

    .rdp-weeknumber,
    .rdp-day:not(.rdp-day_disabled) {
        display: flex;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: var(--rdp-cell-size);
        max-width: var(--rdp-cell-size);
        height: var(--rdp-cell-size);
        margin: 0;
        border: 2px solid transparent;
        border-radius: 100%;
        color: ${({ theme }) => theme.palette.primary.main};
    }

    .rdp-day_today:not(.rdp-day_outside) {
        font-weight: bold;
    }
    .rdp-day_selected:not([disabled]),
    .rdp-day_selected:focus:not([disabled]),
    .rdp-day_selected:active:not([disabled]),
    .rdp-day_selected:hover:not([disabled]) {
        color: white;
        background-color: var(--rdp-accent-color);
    }

    .rdp-day_range_start {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .rdp-day_range_end {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .rdp-day_range_end.rdp-day_range_start {
        border-radius: 100%;
    }
    .rdp-day_range_middle {
        border-radius: 0;
    }

    .rdp-nav_button_previous {
        transform: rotate(90deg);
    }

    .rdp-nav_button_next {
        transform: rotate(90deg);
    }
`;
