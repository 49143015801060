import { SavingsForm } from '.';
import { umbraco } from '../../../api';

export const convertUmbracoModelToSavings = (saving: umbraco.CustomerSavings, index: number): SavingsForm => {
    switch (saving.alias) {
        case 'multipleChoicesSaving': {
            return {
                id: `${saving.alias}-${index}`,
                value: saving.choices ? saving.choices[0].value ?? -1 : -1, // Set the selected value to the first choice
                memberPrice: 0,
                nonMemberPrice: 0,
                memberCompletePrice: 0,
                ...saving,
            };
        }
        case 'rangedChoiceSaving':
        case 'transportAndGasSaving':
        case 'carwashSaving': {
            return {
                id: `${saving.alias}-${index}`,
                value: saving.default ?? 0,
                memberPrice: 0,
                nonMemberPrice: 0,
                memberCompletePrice: 0,
                ...saving,
            };
        }
        default:
            throw new Error('Invalid customer saving type.');
    }
};
