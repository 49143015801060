import styled from 'styled-components';

export const StyledSellingPoint = styled.div`
    align-items: center;
    display: flex;
    gap: 0.4em;
    line-height: 1.66;
`;

export const StyledSellingPointIcon = styled.img`
    height: 2em;
    width: 2em;
`;

export const StyledSellingPointLabel = styled.span<{ isEmphasized: boolean }>`
    font-weight: ${(props) => (props.isEmphasized ? 600 : 400)};
`;

export const StyledLabelAndTooltip = styled.div`
    display: flex;
    gap: 0.4em;
`;
