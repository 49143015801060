import React, { FC } from 'react';
import { hesselViewModels } from '../../../lib/view-models';
import { defaultTheme } from '../../../themes';
import { StyledVehicleAvailability } from './vehicle-availability.styled';

export type VehicleAvailabilityProps = {
    availability: hesselViewModels.VehicleAvailability;
    vehicleType: hesselViewModels.VehicleType;
    className?: string;
};

const {
    common: { black, lightCyan: grey, offWhite },
    error: { main: red },
} = defaultTheme.palette;

type CarColorKeys = Partial<{
    [key in hesselViewModels.VehicleAvailability]: {
        label: string;
        color: string;
        backgroundColor: string;
    };
}>;

const CarColorConfig: CarColorKeys = {
    Order: {
        label: 'Ny bil til bestilling',
        color: offWhite,
        backgroundColor: black,
    },
    InStock: {
        label: 'Ny bil på lager',
        color: black,
        backgroundColor: grey,
    },
    Used: {
        label: 'Brugt',
        color: black,
        backgroundColor: offWhite,
    },
    Unknown: {
        label: 'Ukendt',
        color: offWhite,
        backgroundColor: red,
    },
};

const VanColorConfig: CarColorKeys = {
    Order: {
        label: 'Bestillingsbil',
        color: offWhite,
        backgroundColor: black,
    },
    Used: {
        label: 'Brugt',
        color: black,
        backgroundColor: offWhite,
    },
    Engros: {
        label: 'Engros',
        color: black,
        backgroundColor: offWhite,
    },
    Unknown: {
        label: 'Ukendt',
        color: offWhite,
        backgroundColor: red,
    },
};

function getColor(availability: hesselViewModels.VehicleAvailability, vehicleType: hesselViewModels.VehicleType) {
    switch (vehicleType) {
        case 'Car':
            return CarColorConfig[availability];
        case 'Van':
            return VanColorConfig[availability];
        default:
            return {
                label: 'Ukendt',
                color: red,
                backgroundColor: offWhite,
            };
    }
}

const VehicleAvailabilityMemo: FC<VehicleAvailabilityProps> = ({ availability, className, vehicleType }) => {
    const colorConfig = getColor(availability, vehicleType);

    return (
        <StyledVehicleAvailability
            backgroundColor={colorConfig?.backgroundColor ?? ''}
            className={className}
            foregroundColor={colorConfig?.color ?? ''}
        >
            {colorConfig?.label}
        </StyledVehicleAvailability>
    );
};

export const VehicleAvailability = React.memo(VehicleAvailabilityMemo);
