import { useCallback, useEffect, useRef, useState, VFC } from 'react';
import { config, useSpring } from 'react-spring';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import { useScrollLock } from '../../../../hooks/use-scroll-lock';
import { orgTypes } from '../../../../lib/api/models/umbraco';
import { FindDealershipsStore } from '../../../../lib/state/find-dealership';
import { DealershipInfoCard } from '../../../shared/dealership-info-card/dealership-info-card.component';
import { BookWorkshopModal } from '../../book-workshop-modal/book-workshop-modal.component';
import { AnimateStyledInfoBox, StyledInfoBox, StyledScrollIndicator } from './dealership-info-box.styled';

type DealershipInfoBoxProps = {
    className?: string;
    showAnimatedInfo?: boolean;
};

const DISTANCE_FROM_TOP = 200;

export const DealershipInfoBox: VFC<DealershipInfoBoxProps> = ({ className, showAnimatedInfo = true }) => {
    const { filteredLocations, dealershipId: selectedDealershipId } = FindDealershipsStore.useStoreState((state) => state);
    const [bookWorkshopModalData, setBookWorkshopModalData] = useState<{
        dealership: orgTypes.DealershipWithGeoInfo | undefined;
        show: boolean;
    }>({
        dealership: undefined,
        show: false,
    });
    const { applyScrollBars, hideScrollBars } = useScrollLock();

    useEffect(() => {
        if (selectedDealershipId) {
            const element = document.getElementById(selectedDealershipId);
            element?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'nearest',
            });
        }
    }, [selectedDealershipId]);

    const isMobile = useMediaQuery({ target: 'tablet' });

    const { infoBoxPosition } = useSpring({
        infoBoxPosition: showAnimatedInfo ? 0 : 200,
        config: { ...config.default },
    });

    const infoBoxRef = useRef<HTMLElement>(null);
    const infoBoxRefMobile = useRef<HTMLElement>(null);
    const [showScrollCue, setShowScrollCue] = useState(false);

    const getMapUi = useCallback(
        (renderForMobile: boolean) => {
            return filteredLocations.map((x, index) => {
                return (
                    <DealershipInfoCard
                        key={`${x.dealershipId}-${index}`}
                        dealershipId={x.dealershipId}
                        header={x.header}
                        selected={x.dealershipId === selectedDealershipId}
                        letter={x.letter}
                        address={x.address}
                        zipcode={x.zipcode}
                        city={x.city}
                        phone={x.phone}
                        url={x.url}
                        renderedForMobile={renderForMobile}
                        departments={x.departments}
                        supportedBrands={x.supportedBrands}
                        ctaAction={() => {
                            setBookWorkshopModalData({ dealership: x, show: true });
                            hideScrollBars();
                        }}
                    />
                );
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filteredLocations, selectedDealershipId]
    );

    const MapUi = !isMobile ? (
        <StyledInfoBox
            ref={infoBoxRef}
            className={className}
            onScroll={() => {
                const scrollTop = infoBoxRef?.current?.scrollTop;
                if (scrollTop) {
                    setShowScrollCue(scrollTop > DISTANCE_FROM_TOP);
                }
            }}
        >
            {showScrollCue ? <StyledScrollIndicator /> : null}
            {getMapUi(false)}
        </StyledInfoBox>
    ) : (
        <AnimateStyledInfoBox
            className={className}
            style={{
                transform: infoBoxPosition.to((x) => `translate3d(${x * -1}%, 0, 0)`),
            }}
            ref={infoBoxRefMobile}
            onScroll={() => {
                const scrollTop = infoBoxRefMobile?.current?.scrollTop;
                if (scrollTop) {
                    setShowScrollCue(scrollTop > DISTANCE_FROM_TOP);
                }
            }}
        >
            {showScrollCue ? <StyledScrollIndicator /> : null}
            {getMapUi(true)}
        </AnimateStyledInfoBox>
    );
    return (
        <>
            {MapUi}
            <BookWorkshopModal
                visible={bookWorkshopModalData.show}
                header={bookWorkshopModalData.dealership?.bookWorkshopModalTexts?.bookWorkshopHeader ?? ''}
                description={bookWorkshopModalData.dealership?.bookWorkshopModalTexts?.bookWorkshopDescription ?? ''}
                onClose={() => {
                    setBookWorkshopModalData({ dealership: undefined, show: false });
                    applyScrollBars();
                }}
                dealership={bookWorkshopModalData.dealership}
                bookWorkshopModalButton={bookWorkshopModalData.dealership?.bookWorkshopModalTexts?.bookWorkshopModalButtonText ?? ''}
            />
        </>
    );
};
