import { FC, useMemo } from 'react';
import { CartStoreContext } from '../../../../lib/state/shop/cart/cart.store';
import { getShopPrice } from '../../../../utils/helpers';
import { Orderline } from '../../cart/orderline/orderline.component';
import {
    StyledSummaryLine,
    StyledSummaryLineKey,
    StyledSummaryLineValue,
    StyledSummaryPriceTotalWrapper,
    StyledSummaryWrapper,
} from '../../cart/summary/cart-summary.styled';
import { StyledSummaryHeader } from './checkout-summary.styled';

type IProps = {
    header?: string;
};

export const CheckoutSummaryTotalAndOrderlines: FC<IProps> = ({ header }) => {
    const { cart, cartReceipt, shopCheckoutComplete } = CartStoreContext.useStoreState((state) => state);
    const cartToShow = shopCheckoutComplete ? cartReceipt : cart;
    const itemsTotal = useMemo(() => {
        return cartToShow ? cartToShow.lineItems.reduce((prev, curr) => prev + curr.quantity, 0) : 0;
    }, [cartToShow]);
    if (!cartToShow) return null;
    return (
        <>
            <StyledSummaryHeader>{header && header.length > 0 ? `${header}` : `Kurv (${itemsTotal})`}</StyledSummaryHeader>
            {cartToShow.lineItems.map((x) => {
                return <Orderline orderline={x} key={x.id} compressedView={true} />;
            })}
            <StyledSummaryWrapper>
                <StyledSummaryLine>
                    <StyledSummaryLineKey>Subtotal</StyledSummaryLineKey>
                    <StyledSummaryLineValue>{getShopPrice(cartToShow.subTotal)}</StyledSummaryLineValue>
                </StyledSummaryLine>
                {cartToShow.discounts
                    .filter((x) => x.couponCode.length > 0)
                    .map((x) => {
                        return (
                            <StyledSummaryLine key={x.couponCode}>
                                <StyledSummaryLineKey>
                                    {x.couponCode.length > 0 ? `Gavekode, "${x.discountName}".` : `${x.discountName}`}
                                </StyledSummaryLineKey>
                                <StyledSummaryLineValue>{getShopPrice(x.discountAmount * -1)}</StyledSummaryLineValue>
                            </StyledSummaryLine>
                        );
                    })}
                <StyledSummaryLine>
                    <StyledSummaryLineKey>{cartToShow.delivery ? cartToShow.delivery.label : 'Levering / afhentning fra'}</StyledSummaryLineKey>
                    <StyledSummaryLineValue>{getShopPrice(cartToShow.delivery ? cartToShow.delivery.price : 0)}</StyledSummaryLineValue>
                </StyledSummaryLine>
            </StyledSummaryWrapper>
            <StyledSummaryPriceTotalWrapper>
                <span>
                    Pris i alt <br />
                    inkl. moms
                </span>
                <span>{getShopPrice(cartToShow.totalPrice)}</span>
            </StyledSummaryPriceTotalWrapper>
        </>
    );
};
