import styled from 'styled-components';
import { device } from '../../../../../lib/media-query';
import { mixins } from '../../../../../themes';

export const BorderBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    ${mixins.gap(1.5)}
    margin-bottom: 2rem;

    @media ${device.tablet} {
        padding: 35px 0px;
        margin-bottom: revert;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 340px;

    @media ${device.tablet} {
        display: grid;
        grid-template-columns: 340px;
        grid-row-gap: 1rem;
    }
`;

export const Section = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
    position: relative;
`;

export const SectionNote = styled.p`
    color: #807f80;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 23px;

    position: relative;
    top: -8px;
`;

export const DeliveryModeWrapper = styled.div`
    display: flex;
    gap: 1.2rem;
    flex-wrap: wrap;
    justify-items: flex-start;
    /* margin-bottom: 1rem; */
`;

export const TermsAndConditions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
`;

export const EditSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media ${device.tablet} {
        display: grid;
        grid-template-columns: 340px;
        grid-gap: 1rem;
    }

    & button {
        justify-self: start;
        align-self: flex-start;

        margin-top: 15px;

        @media ${device.tablet} {
            margin-top: revert;
        }
    }
`;

export const StyledHeader = styled.h4`
    color: #0b0b0b;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
`;

export const DurationTermsModalContent = styled.div`
    padding: 0;
    margin-top: 20px;
    width: auto;

    @media ${device.mobileL} {
        padding: 0 30px;
    }

    & img,
    & p img {
        width: auto;
        max-width: 320px;
        object-fit: cover;

        @media ${device.tablet} {
            max-width: 500px;
        }
    }

    & ul,
    & ol {
        font-size: clamp(14px, 3.3vw - 0.75rem, 1rem);
        font-weight: 500;
        list-style: revert;
        padding: revert;
        margin: revert;
    }
`;
