import { VFC } from 'react';
import { sharedTypes } from '../../../lib/api/models/umbraco';
import { BorderBox, StyledText, StyledImageWrapper, StyledImage } from './selectable-card.styled';

export type SelectableCardProps = {
    icon?: sharedTypes.Image;
    text?: string;
    isSelected: boolean;
    onClick: () => void;
};

const MEDIA_URL = process.env.NEXT_PUBLIC_MEDIA_URL;

export const SelectableCard: VFC<SelectableCardProps> = ({ icon, text, isSelected, onClick }) => {
    return (
        <BorderBox isSelected={isSelected} onClick={onClick}>
            {icon && icon.src ? (
                <StyledImageWrapper>
                    <StyledImage src={`${MEDIA_URL}${icon.src}`} alt={icon.name} width={'32px'} height={'32px'} />
                </StyledImageWrapper>
            ) : null}
            {text && text?.length > 0 ? <StyledText>{text}</StyledText> : null}
        </BorderBox>
    );
};
