import styled from 'styled-components';
import { StyledCard } from '../../product-details-page/shared/product-detail-page-shared.styled';
import { SvgIcon } from '../../../shared/svg-icon';

export const StyledEngrosExplanationCard = styled(StyledCard)`
    padding: 20px;
    margin-top: 20px;
`;

export const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;

    p {
        font-size: 20px;
        font-weight: bold;
        font-family: 'Corporate E';
    }
`;

export const StyledDescription = styled.div`
    margin: 10px 0 10px;
`;

export const StyledSvgIcon = styled(SvgIcon)`
    cursor: pointer;
    svg {
        path {
            fill: #aeaeaf;
            stroke: #aeaeaf;
        }
    }
`;
