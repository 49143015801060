import React, { FC } from 'react';
import { SimpleModal } from '../../shared';
import YoutubeVideoIframe from '../../shared/youtube-video/youtube-video-iframe.component';
import { Props } from './youtube-video-modal.props';
import { YoutubeModalWrapper } from './youtube-video-modal.styled';

const YoutubeVideoModal: FC<Props> = ({ isVisible, onClose, watchId, title, subTitle }) => {
    return isVisible ? (
        <SimpleModal closeAction={onClose} isVisible={isVisible} headerText={title} subHeaderText={subTitle} subHeaderFontWeight={'400'}>
            <YoutubeModalWrapper>
                <YoutubeVideoIframe width="100%" watchId={watchId} />
            </YoutubeModalWrapper>
        </SimpleModal>
    ) : null;
};

export default YoutubeVideoModal;
