import styled from 'styled-components';

export const Wrapper = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 23;
    isolation: isolate;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
`;
