import { FC } from 'react';
import { IconButtonProps } from '../icon-button/icon-button.component';
import { StyledIconButtonWithLabel, StyledIconButtonWithLabelIcon, StyledIconButtonWithLabelText } from './icon-button-with-label.styled';

export const IconButtonWithLabel: FC<IconButtonProps> = (props) => {
    return (
        <StyledIconButtonWithLabel className={props.className} onClick={props.onClick}>
            <StyledIconButtonWithLabelText>{props.label}</StyledIconButtonWithLabelText>
            <StyledIconButtonWithLabelIcon aria-hidden="true">{props.children}</StyledIconButtonWithLabelIcon>
        </StyledIconButtonWithLabel>
    );
};
