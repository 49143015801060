import styled from 'styled-components';

export const StyledBlinker = styled.div<{ allowAnimation: boolean }>`
    width: 7px;
    height: 7px;
    border-radius: 50%;

    background-color: ${(props) => (props.allowAnimation ? 'unset' : props.theme.palette.primary.main)};

    animation: ${(props) => (props.allowAnimation ? 'spin 3s ease-in-out infinite alternate' : 'none')};

    @keyframes spin {
        0% {
            opacity: 0;
            background-color: transparent;
        }
        100% {
            opacity: 1;
            background-color: ${({ theme }) => theme.palette.primary.main};
        }
    }
`;
