import { useEffect, useState, VFC } from 'react';
import { FilterUpdateNotification, ParentColor } from '../../../../../../lib/api/models/hessel-api/hire';
import { FacetOption } from '../../../../../../lib/view-models/vehicle';
import { getImageCropUrl } from '../../../../../../utils/helpers';
import { StyledColorImage, StyledColorOption, StyledColorOptionName } from '../color-filter.styled';

type IProps = {
    option: FacetOption;
    onChange: (updates: FilterUpdateNotification[]) => void;
    colorAsset?: ParentColor;
};

export const ColorOption: VFC<IProps> = ({ option, onChange, colorAsset }) => {
    const [selectedState, setSelectedState] = useState(option.selected);

    useEffect(() => {
        if (option.selected !== selectedState) setSelectedState(option.selected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [option]);

    const toggle = () => {
        setSelectedState(!selectedState);
        onChange([{ query: option.filterQueryValue, action: option.selected ? 'Remove' : 'Add' }]);
    };

    return (
        <StyledColorOption onClick={() => toggle()} disabled={option.count === 0 && !option.selected}>
            {colorAsset ? (
                <StyledColorImage
                    style={{ backgroundImage: `url('${getImageCropUrl(colorAsset.resource.data, 'icon')}')` }}
                    selected={selectedState}
                />
            ) : null}
            <StyledColorOptionName>{option.key}</StyledColorOptionName>
        </StyledColorOption>
    );
};
