import { VFC } from 'react';
import { PreviewLinkWrapper, StyledText } from './preview-link.styled';
import Image from 'next/image';
import { MEDIA_URL } from '../../../utils/environment-constants';

type Props = {
    text: string;
    icon: string;
    onClick?: () => void;
};

export const PreviewLink: VFC<Props> = ({ text, onClick, icon }) => {
    return (
        <PreviewLinkWrapper onClick={() => onClick?.()}>
            <Image alt="Preview icon" src={`${MEDIA_URL}/${icon}`} width={25} height={25} layout="fixed" />
            <StyledText>{text}</StyledText>
        </PreviewLinkWrapper>
    );
};
