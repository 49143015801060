import { FC } from 'react';
import { FacetGroup } from '../../../../../lib/view-models/vehicle';
import { StyledColorOptionWrapper } from '../color-filter/color-filter.styled';
import { ColorOption } from '../color-filter/color-option/color-option.component';
import { FilterUpdateNotification } from '../../../../../lib/api/models/hessel-api/hire';

type IProps = {
    group: FacetGroup;
    onChange: (filterUpdate: FilterUpdateNotification[]) => void;
};

export const MainColorFilter: FC<IProps> = ({ group, onChange }) => {
    return (
        <StyledColorOptionWrapper>
            {group.facetOptions.map((x) => {
                const colorAsset =
                    typeof x.icon === 'string'
                        ? { id: '', name: '', resource: { id: '', name: '', data: x.icon, type: '', filename: '' } }
                        : undefined;
                return <ColorOption key={x.key} option={x} colorAsset={colorAsset} onChange={(updates) => onChange(updates)} />;
            })}
        </StyledColorOptionWrapper>
    );
};
