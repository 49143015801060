import { useEffect, VFC } from 'react';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import { umbraco } from '../../../../lib/api';
import { HireContentDisplayStore } from '../../../../lib/state/hessel-site/display-manager';
import { ProductDetailsStore } from '../../../../lib/state/hessel-site/product-details';
import { CenteredBlock } from '../../../structural-blocks/centered-block.component';
import { useUI } from '../../../ui';
import { OrderSummaryMobile } from '../../hire-order-summary/mobile';
import { HireLeadCheckoutStepList } from '../step-list';
import { LeadCheckoutLayout, PreviewLinks, RightPanel, StepsAndSummary, StyledHireNavigator } from './lead-site.styled';
import { PreviewLink } from '../../../shared';
import { HelpCenter } from '../../../help-center';
import { HireCheckoutMode, LeadCheckoutFormsStore } from '../../../../lib/state/hessel-site/lead-checkout/forms';
import { OrderSummaryDesktop } from '../../hire-order-summary/desktop-version';
import { sharedTypes } from '../../../../lib/api/models/umbraco';
import { getFormattedValue, handleUrlClick } from '../../../../utils/helpers';
import { useGtmTracking } from '../../../../gtm-tracking/hooks/use-gtm-tracking';
import { MiniBasketStore } from '../../../../lib/state/hessel-site/mini-basket';
import { Abbreviations } from '../../../../constants/units-and-abbreviations';

export type LeadSiteProps = {
    helpSettings: Array<umbraco.CustomerHelpCenter>;
    headerSettings: umbraco.HireHeaderSettings;
    noCarData?: umbraco.HireLeadCheckoutNoCar;
    leasingAgreementLink: Array<umbraco.HirePageLinkAndIcon>;
    seePaymentPlanLinkText: string;
    seePaymentPlanLinkIcon?: sharedTypes.Image;
};

export const LeadSite: VFC<LeadSiteProps> = ({
    helpSettings,
    headerSettings,
    noCarData,
    leasingAgreementLink,
    seePaymentPlanLinkText,
    seePaymentPlanLinkIcon,
}) => {
    const { setShowPaymentPlanSidebar } = HireContentDisplayStore.useStoreActions((actions) => actions);

    const isSmallScreen = useMediaQuery({ target: 'laptop_tablet_landscape' });

    const uiHandler = useUI();

    // Lead Checkout Forms Store
    const { checkoutMode, isCheckoutComplete } = LeadCheckoutFormsStore.useStoreState((state) => state);

    // Product Details Store
    const {
        selectedProduct,
        selectedColor,
        extraEquipmentPriceSum,
        optionalEquipmentPriceSum,
        extraEquipmentPackagePriceSum,
        selectedLeasingPeriod,
        selectedStartupDate,
    } = ProductDetailsStore.useStoreState((state) => state);

    // GTM Tracking
    const { trackHireLeadCheckout } = useGtmTracking();
    const tracker = trackHireLeadCheckout();

    const imageUrl =
        selectedProduct?.resources.find((x) => x.type === 'image' && x.colorCode === selectedColor?.code) ??
        selectedProduct?.resources.find((x) => x.type === 'image');

    const totalPriceWithMoms =
        (selectedProduct?.purchaseTypes.hire?.baseMonthlyPrice ?? 0) +
        extraEquipmentPriceSum +
        extraEquipmentPackagePriceSum +
        optionalEquipmentPriceSum +
        (selectedColor?.monthlyHirePrice ?? 0);

    // Product Details Store
    const { fillStoreFromMiniBasket } = ProductDetailsStore.useStoreActions((actions) => actions);
    // Mini Basket Store
    const { getState: getMiniBasketStoreState } = MiniBasketStore.useStore();
    const miniBasketStoreHydrated = MiniBasketStore.useStoreRehydrated();

    useEffect(() => {
        if (miniBasketStoreHydrated) {
            const miniBasketState = getMiniBasketStoreState().productData;

            if (miniBasketState) {
                fillStoreFromMiniBasket(miniBasketState);
            }
        }
    }, [fillStoreFromMiniBasket, getMiniBasketStoreState, miniBasketStoreHydrated]);

    return (
        <div style={{ backgroundColor: '#f7f7f8', padding: '50px 0 40px 0' }}>
            <CenteredBlock>
                {headerSettings.returnLink ? (
                    <StyledHireNavigator text={headerSettings.returnLink?.name} link={headerSettings.returnLink.url} />
                ) : null}

                <LeadCheckoutLayout>
                    <StepsAndSummary>
                        {!isCheckoutComplete && selectedProduct ? (
                            <OrderSummaryMobile
                                imageUrl={imageUrl && imageUrl.type === 'image' ? imageUrl?.url : '/'}
                                colorName={selectedColor?.name ?? ''}
                                colorImageUrl={selectedColor?.resource.data ?? ''}
                                model={selectedProduct.variantTitle}
                                leasingPeriod={selectedLeasingPeriod?.displayValue ?? ''}
                                carPrice={`${getFormattedValue(
                                    selectedProduct.purchaseTypes.hire.baseMonthlyPrice + (selectedColor?.monthlyHirePrice ?? 0)
                                )} ${Abbreviations.KR_SLASH_MD}`}
                                extraEquipmentPrice={`${getFormattedValue(extraEquipmentPriceSum + extraEquipmentPackagePriceSum)} ${
                                    Abbreviations.KR_SLASH_MD
                                }`}
                                optionalEquipmentPrice={`${getFormattedValue(optionalEquipmentPriceSum)} ${Abbreviations.KR_SLASH_MD}`}
                                totalPriceIncludingMoms={`${getFormattedValue(totalPriceWithMoms)} ${Abbreviations.KR_SLASH_MD}`}
                                totalPriceExcludingMoms={`${getFormattedValue(totalPriceWithMoms * 0.8)} ${Abbreviations.KR_SLASH_MD}`}
                                startupDate={selectedStartupDate}
                                availability={selectedProduct.availability}
                                brandTitle={selectedProduct.brandTitle}
                                variantTitle={selectedProduct.variantTitle}
                                showPaymentPlanSidebar={() => {
                                    setShowPaymentPlanSidebar({ showSidebar: true, checkoutMode });
                                    tracker.sidebarOpened('Betalingsplan');
                                }}
                                checkoutMode={checkoutMode}
                                leasingAgreementLink={leasingAgreementLink}
                                seePaymentPlanLinkText={seePaymentPlanLinkText}
                                seePaymentPlanLinkIcon={seePaymentPlanLinkIcon}
                            />
                        ) : null}
                        <HireLeadCheckoutStepList noCardData={noCarData} />
                    </StepsAndSummary>
                    <RightPanel>
                        {!isSmallScreen && !!selectedProduct && !isCheckoutComplete ? (
                            <OrderSummaryDesktop
                                imageUrl={imageUrl && imageUrl.type === 'image' ? imageUrl?.url : '/'}
                                colorName={selectedColor?.name ?? ''}
                                colorImageUrl={selectedColor?.resource.data ?? ''}
                                model={selectedProduct.variantTitle}
                                leasingPeriod={selectedLeasingPeriod?.displayValue ?? ''}
                                carPrice={`${getFormattedValue(
                                    selectedProduct.purchaseTypes.hire.baseMonthlyPrice + (selectedColor?.monthlyHirePrice ?? 0)
                                )} ${Abbreviations.KR_SLASH_MD}`}
                                extraEquipmentPrice={`${getFormattedValue(extraEquipmentPriceSum + extraEquipmentPackagePriceSum)} ${
                                    Abbreviations.KR_SLASH_MD
                                }`}
                                optionalEquipmentPrice={`${getFormattedValue(optionalEquipmentPriceSum)} ${Abbreviations.KR_SLASH_MD}`}
                                totalPriceIncludingMoms={`${getFormattedValue(totalPriceWithMoms)} ${Abbreviations.KR_SLASH_MD}`}
                                totalPriceExcludingMoms={`${getFormattedValue(totalPriceWithMoms * 0.8)} ${Abbreviations.KR_SLASH_MD}`}
                                startupDate={selectedStartupDate}
                                availability={selectedProduct.availability}
                                brandTitle={selectedProduct.brandTitle}
                                variantTitle={selectedProduct.variantTitle}
                                showBusinessSection={checkoutMode === HireCheckoutMode.Business}
                            />
                        ) : null}
                        <PreviewLinks>
                            <PreviewLink
                                text={seePaymentPlanLinkText}
                                onClick={() => {
                                    setShowPaymentPlanSidebar({ showSidebar: true, checkoutMode });
                                    uiHandler.removeScroll();
                                    tracker.sidebarOpened('Betalingsplan');
                                }}
                                icon={seePaymentPlanLinkIcon?.src ?? ''}
                            />
                            {leasingAgreementLink.length > 0 ? (
                                <PreviewLink
                                    text={leasingAgreementLink[0].link.name}
                                    icon={leasingAgreementLink[0].icon.src}
                                    onClick={() => {
                                        const url = leasingAgreementLink[0].link;

                                        if (url) {
                                            handleUrlClick(url);
                                        }
                                    }}
                                />
                            ) : null}
                        </PreviewLinks>
                        <HelpCenter helpSettings={helpSettings} />
                    </RightPanel>
                </LeadCheckoutLayout>
            </CenteredBlock>
        </div>
    );
};
