import { FC, useMemo } from 'react';
import { ShopPromotionSetup } from '../../../lib/api/models/umbraco';
import { GlobalEcommerceSettingsContext } from '../../../lib/state/global-ecommerce-settings/global-ecommerce-settings.store';
import { StyledPromotion, StyledPromotionsWrapper } from './promotion-badge.styled';

type IProps = {
    promotions: string[];
    className?: string;
};

export const PromotionBadges: FC<IProps> = ({ promotions, className }) => {
    const { settings } = GlobalEcommerceSettingsContext.useStoreState((state) => state);
    const mappedPromotions: ShopPromotionSetup[] = useMemo(() => {
        if (promotions.length === 0 || !settings) return [];
        return promotions.reduce<ShopPromotionSetup[]>((prev, curr) => {
            const setup = settings.promotionSetup.find((s) => s.promotionId === curr);
            if (setup) return [...prev, setup];
            return prev;
        }, []);
    }, [promotions, settings]);

    if (mappedPromotions.length === 0) return null;

    return (
        <StyledPromotionsWrapper className={className}>
            {mappedPromotions.map((x) => {
                return (
                    <StyledPromotion bgColor={x.backgroundColor} textColor={x.textColor} key={x.promotionId}>
                        {x.promotionLabel}
                    </StyledPromotion>
                );
            })}
        </StyledPromotionsWrapper>
    );
};
