import { FC } from 'react';
import { Wrapper } from './loading-wrapper.styled';

type Props = {
    isLoading?: boolean;
    grayscale?: string;
    blur?: string;
    children: React.ReactNode;
};
export const LoadingWrapper: FC<Props> = ({ isLoading, grayscale = '0.5', blur = '5px', children }) => {
    return (
        <Wrapper isLoading={isLoading} grayscale={grayscale} blur={blur}>
            {children}
        </Wrapper>
    );
};
