import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const BrandBarWrapper = styled.footer`
    background-color: black;
    color: white;
    width: 100%;
    padding: 50px 0;

    & * {
        color: white;
    }
`;

export const BrandBarDisplayContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 2rem;

    @media ${device.tablet} {
        display: grid;
        grid-template-columns: 2fr repeat(3, 1fr);
    }
`;

export const BrandBarItem = styled.div``;

export const CarClassHeader = styled.p`
    font-size: 17px;
    font-weight: 600;
    letter-spacing: normal;
    line-height: 26px;
    margin-bottom: 0.5rem;
`;

export const ModelsWrapper = styled.div<{ columns: number }>`
    columns: ${(props) => props.columns};
`;

export const LinkWrapper = styled.div``;
