import React, { FC } from 'react';
import { SubmittedFormMessage, StyledHeader, Value } from './order-car-receipts.styled';
import { useCmsPage } from '../../../hooks/use-cms-page';
import Link from 'next/dist/client/link';
import { isNullOrEmpty } from '../../../utils/helpers';
import reactStringReplace from 'react-string-replace';

type OrderCarReceiptsProps = {
    email: string;
    header?: string;
    description?: string;
    actionForUserText?: string;
    linkText?: string;
};

const OrderCarReceiptsMemo: FC<OrderCarReceiptsProps> = ({
    email,
    header = 'Tak for din forespørgsel',
    description = '',
    actionForUserText = 'Har du et spørgsmål kan du kontakt os via nedenstående formular eller',
    linkText = 'find din nærmeste HesselHiRE afdeling her',
}) => {
    const { url: contactPageUrl } = useCmsPage('findDealershipPage');

    const getDescriptionText = () => {
        const processedText = reactStringReplace(description, '{{email}}', () => <strong>{email}</strong>);
        return processedText;
    };

    const descriptionText = !isNullOrEmpty(description) ? (
        <Value>{getDescriptionText()}</Value>
    ) : (
        <Value>
            Vi har sendt en mail til dig på <strong>{email}</strong> med din forespørgsel. Vi vil kontakte dig hurtigst muligt ofte vil der gå 48
            timer.
        </Value>
    );

    return (
        <SubmittedFormMessage>
            <StyledHeader>{header}</StyledHeader>
            {descriptionText}
            <Value>
                {actionForUserText}{' '}
                <Link href={`/${contactPageUrl}`}>
                    <a>{linkText}</a>
                </Link>
            </Value>
        </SubmittedFormMessage>
    );
};

export const OrderCarReceipts = React.memo(OrderCarReceiptsMemo);
