export function findMinMax(array: number[]): [number, number] {
    if (!array || !array.length) throw new RangeError(`[findMinMax] Array is undefined or empty.`);

    return array.reduce(([min, max], v) => {
        if (!min || !max) return [v, v];
        if (v > max) {
            return [min, v];
        } else if (v < min) {
            return [v, max];
        } else {
            return [min, max];
        }
    }, new Array<number>()) as [number, number];
}

export function generateRange(start: number, stop: number, step: number): Array<number> {
    return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
}

export function getLetter(index: number): string {
    // If index is greater than 26, then append another letter
    if (index > 25) {
        return getLetter(Math.floor(index / 26) - 1) + getLetter(index % 26);
    }

    return String.fromCharCode(65 + index);
}
