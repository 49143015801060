import styled from 'styled-components';

export const SubmittedFormMessage = styled.div``;

export const StyledHeader = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    margin: 0 0 18px 0;
`;

export const Value = styled.p`
    font-weight: normal;
    font-size: 14px;
    margin: 0 0 25px 0;
`;
