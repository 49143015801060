import styled from 'styled-components';
import { Button } from '..';
import { device } from '../../../lib/media-query';

export const BorderBox = styled.section`
    display: grid;
    gap: 25px;
    grid-template-columns: revert;
    grid-template-rows: 1fr auto;
    padding: 45px 0;

    @media ${device.tablet} {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-template-rows: revert;
        gap: 100px;
    }
`;

export const InfoSection = styled.div`
    justify-self: center;
    align-self: center;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;

    order: 2;
    @media ${device.tablet} {
        order: revert;
    }
`;

export const StyledDealershipInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media ${device.tablet} {
        gap: 10px;
    }
`;

export const Header = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: clamp(20px, 2vw, 30px);
    font-weight: bold;
    letter-spacing: 0;
`;

export const Description = styled.div``;

export const InfoList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-self: flex-start;
`;

export const InfoListItem = styled.div<{ iconAvailable: boolean }>`
    ${({ iconAvailable }) => (iconAvailable ? 'display: grid;' : null)};
    grid-template-columns: 25px 1fr;
    align-items: center;
    gap: 10px;
`;

export const InfoListItemIcon = styled.img`
    display: block;
`;

export const InfoListItemText = styled.div``;

export const StyledIcon = styled.img`
    width: 25px;
`;

export const StyledText = styled.div``;

export const MapSection = styled.div`
    order: 1;
    @media ${device.tablet} {
        order: revert;
    }
`;

export const StyledMapImage = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
`;

export const StyledButton = styled(Button)`
    margin-top: 10px;
`;
