import { FC, useMemo, useState } from 'react';
import { BrandFacetKey } from '../../../../../constants/site-consts';
import { FilterType, FilterUpdateNotification } from '../../../../../lib/api/models/hessel-api/hire';
import { FacetGroup, FacetOption } from '../../../../../lib/view-models/vehicle';
import { Accordion } from '../../../../shared/accordion';
import { ToggleUi } from '../../../../shared/toggle-ui';
import { CheckboxFilter } from '../checkbox-filter/checkbox-filter.component';
import { ColorFilter } from '../color-filter/color-filter.component';
import { DepartmentFilter } from '../department-filter/department-filter.compontent';
import { DropdownFilterSingleSelect } from '../dropdown-filter/dropdown-filter-single-select/dropdown-filter-single-select.compontent';
import { DropdownFilterWrapper } from '../dropdown-filter/dropdown-filter-wrapper.component';
import { FilterHeader, FilterWrapper, InputWrapper } from './filter-group.styled';
import { DepartmentFilterResponse } from '../../../../../lib/api/organization/hessel-dealership-api';
import { CheckboxFilterReadMore } from '../checkbox-filter/checkbox-filter-read-more.component';
import { useMediaQuery } from '../../../../../hooks/use-media-query';
import { MainColorFilter } from '../main-color-filter/main-color-filter.component';
import { YourCarFilter } from '../your-car-filter/your-car-filter.component';
import { ShopFacetGroup } from '../../../../../lib/api/models/shop/product-list';

type IProps = {
    header: string;
    filterRenderType: FilterType;
    facet: FacetOption[];
    onChange: (filterUpdate: FilterUpdateNotification[]) => void;
    group: FacetGroup | ShopFacetGroup;
    maximumSubfacetDepth?: number;
    departments?: DepartmentFilterResponse[];
    shopContext?: boolean;
};

export const FilterGroup: FC<IProps> = ({
    header,
    filterRenderType,
    facet,
    onChange,
    group,
    maximumSubfacetDepth,
    departments,
    shopContext = false,
}) => {
    const [open, setOpen] = useState(group.key === BrandFacetKey);
    const isSingleUnselectableOption = (): boolean => {
        if (group.key === BrandFacetKey && group.facetOptions.length === 1 && group.facetOptions[0].selected) {
            return true;
        }
        return false;
    };

    const isDevice = useMediaQuery({ target: 'smallDesktop' });

    const renderAsReadMoreCheckbox = useMemo(() => {
        if (isDevice) return false;
        if (filterRenderType === 'Checkbox with read more' && facet.length > 12) return true;
        return false;
    }, [facet.length, filterRenderType, isDevice]);

    const renderAsNormalCheckbox = useMemo(() => {
        if (filterRenderType === 'Checkbox') return true;
        if (filterRenderType === 'Checkbox with read more' && facet.length <= 12) return true;
        if (isDevice && filterRenderType === 'Checkbox with read more') return true;
        return false;
    }, [facet.length, filterRenderType, isDevice]);

    const renderCheckboxFilterUI = (facets: FacetOption[]) => {
        return (
            <CheckboxFilter
                facetOptions={facets}
                onChange={(filterUpdate) => onChange(filterUpdate)}
                maximumSubfacetDepth={maximumSubfacetDepth}
                singleUnselectableOption={isSingleUnselectableOption()}
            />
        );
    };

    return (
        <FilterWrapper>
            {filterRenderType === 'Toggle' ? (
                facet && facet.length > 1 ? (
                    <ToggleUi
                        options={[
                            ...facet.map((x) => {
                                return {
                                    label: x.key,
                                    value: x.filterQueryValue,
                                };
                            }),
                        ]}
                        selectedOption={facet.find((x) => x.selected)?.filterQueryValue ?? facet[0].filterQueryValue}
                        onChange={(selection) => onChange([{ action: 'Replace', query: selection }])}
                    />
                ) : null
            ) : (
                <Accordion
                    title=""
                    variant="primary"
                    border="none"
                    headerSize="sm"
                    isOpen={open}
                    index={0}
                    onClick={() => setOpen(!open)}
                    backgroundColor="fff"
                    customTitle={<FilterHeader>{header}</FilterHeader>}
                    toggleIndicator="plus"
                >
                    <InputWrapper>
                        {renderAsNormalCheckbox && <div>{renderCheckboxFilterUI(facet)}</div>}
                        {renderAsReadMoreCheckbox && (
                            <CheckboxFilterReadMore
                                initialContent={renderCheckboxFilterUI(facet.filter((_, idx) => idx < 8))}
                                readMoreContent={renderCheckboxFilterUI(facet.filter((_, idx) => idx >= 8))}
                            ></CheckboxFilterReadMore>
                        )}
                        {filterRenderType === 'Dropdown interval' ? (
                            <DropdownFilterWrapper
                                facets={facet}
                                onChange={(update) => onChange(update)}
                                groupKey={group.key}
                                defaultMaxValue={shopContext ? 'max' : undefined}
                                separator={shopContext ? '--' : undefined}
                            />
                        ) : null}
                        {filterRenderType === 'Color' && <ColorFilter facetOptions={facet} onChange={(filterUpdate) => onChange(filterUpdate)} />}
                        {filterRenderType === 'Main Colors' && (
                            <MainColorFilter group={group as FacetGroup} onChange={(filterUpdate) => onChange(filterUpdate)} />
                        )}
                        {filterRenderType === 'Minimum value' && (
                            <DropdownFilterSingleSelect
                                options={facet}
                                onChange={(filterUpdate) => onChange(filterUpdate)}
                                groupKey={group.key}
                                minOrMax="Minimum"
                                label="Minimum"
                                unit="kr."
                            />
                        )}
                        {filterRenderType === 'Maximum value' && (
                            <DropdownFilterSingleSelect
                                options={facet}
                                onChange={(filterUpdate) => onChange(filterUpdate)}
                                groupKey={group.key}
                                label="Max."
                                minOrMax="Maximum"
                                unit="kr."
                            />
                        )}
                        {filterRenderType === 'Department' && departments && (
                            <DepartmentFilter departments={departments} facets={facet} onChange={(filterUpdate) => onChange(filterUpdate)} />
                        )}
                        {filterRenderType === 'Your car' && (
                            <YourCarFilter group={group as ShopFacetGroup} onChange={(filterUpdate) => onChange(filterUpdate)} />
                        )}
                    </InputWrapper>
                </Accordion>
            )}
        </FilterWrapper>
    );
};

export default FilterGroup;
