import { useSelect } from 'downshift';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BaseInput, BaseInputChildrenProps } from '../../forms/inputs/base';
import { SvgIcon } from '../svg-icon';
import { CustomCalendar } from './custom-calendar/custom-calendar.styled';
import { StyledPlaceholder, StyledValue, CalendarWrapper, DisplayWrapper, BorderBox, DropdownContainer } from './date-picker-dropdown.styled';
import da from 'date-fns/locale/da';
import { Matcher } from 'react-day-picker';
import { addDays } from 'date-fns';
import { useSpring, animated } from 'react-spring';
import { useClickOutsideObserver } from '../../../hooks/use-click-outside-observer';

const valueIsDefined = (value?: DropdownOption<string>): value is DropdownOption<string> => (value?.value && value?.displayValue ? true : false);

export type DropdownOption<T> = {
    value: T;
    displayValue: string;
};

export type Props = Omit<BaseInputChildrenProps<DropdownOption<string>>, 'valueIsDefined'> & {
    onChange: (value?: DropdownOption<string> | null) => void;
    isValid: boolean;
    canValidateInputField: boolean;
    validationMessage?: string;
    onInputBlur?: () => void;
    selectedDay?: Date;
    onDayChange: (day: Date) => void;
    waitingDays: number;
    includeWeekends?: boolean;
    disableDays?: number[];
    specialDays?: Date[];
};

export const DatePickerDropdown: FC<Props> = ({
    value,
    onChange,
    isValid,
    validationMessage,
    canValidateInputField,
    disabled,
    onInputBlur,
    selectedDay,
    onDayChange,
    waitingDays,
    includeWeekends = true,
    disableDays = [],
    specialDays = [],
    ...props
}) => {
    const { getLabelProps, getToggleButtonProps, toggleMenu, getMenuProps } = useSelect({
        items: [],
        itemToString: (item) => item?.displayValue ?? '',
        onSelectedItemChange: ({ selectedItem }) => {
            if (disabled) return;
            onChange(selectedItem);
        },
        initialSelectedItem: value,
    });

    const disabledDates: Matcher[] = useMemo(
        () => [
            (day) => {
                const now = new Date();
                return day < addDays(now, waitingDays);
            },
            { dayOfWeek: includeWeekends ? [...disableDays] : [0, 6, ...disableDays] },
            { before: new Date() },
            ...specialDays,
        ],
        [disableDays, includeWeekends, waitingDays, specialDays]
    );

    const [isOpen, setIsOpen] = useState(false);

    const spin = useSpring({
        transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    });

    const dropdownRef = useRef(null);
    useClickOutsideObserver(dropdownRef, () => setIsOpen(false), isOpen);

    const handleDocumentKeyDown = useCallback((e) => {
        if (e.key === 'Escape') {
            setIsOpen(false);
        }
    }, []);

    useEffect(() => {
        if (!disabled) {
            document.addEventListener('keydown', handleDocumentKeyDown, { passive: true });
        } else {
            document.removeEventListener('keydown', handleDocumentKeyDown);
        }

        return () => document.removeEventListener('keydown', handleDocumentKeyDown);
    }, [disabled, handleDocumentKeyDown]);

    return (
        <BorderBox isOpen={isOpen} onBlur={onInputBlur} ref={dropdownRef}>
            <BaseInput
                {...props}
                isOpen={isOpen}
                value={value}
                isValid={isValid}
                disabled={disabled}
                adornment={
                    <animated.div
                        style={spin}
                        onClick={(e) => {
                            e.preventDefault();
                            if (!disabled) {
                                setIsOpen(!isOpen);
                                toggleMenu();
                            }
                        }}
                    >
                        <SvgIcon iconName={'chevron/down'} />
                    </animated.div>
                }
                labelProps={getLabelProps({ disabled })}
                valueIsDefined={valueIsDefined}
                canValidateInputField={canValidateInputField}
                validationMessage={validationMessage}
                onLabelClick={() => {
                    if (!disabled) {
                        toggleMenu();
                    }
                }}
            >
                {({ placeholder, value }) => (
                    <>
                        <DisplayWrapper
                            {...getToggleButtonProps({ disabled })}
                            onClick={(e) => {
                                e.preventDefault();
                                if (!disabled) {
                                    setIsOpen(!isOpen);
                                    toggleMenu();
                                }
                            }}
                        >
                            {valueIsDefined(value) ? (
                                <StyledValue>{value.displayValue}</StyledValue>
                            ) : (
                                <StyledPlaceholder>{placeholder}</StyledPlaceholder>
                            )}
                        </DisplayWrapper>
                    </>
                )}
            </BaseInput>
            <DropdownContainer isOpen={isOpen}>
                <CalendarWrapper {...getMenuProps({ disabled })}>
                    {isOpen && (
                        <CustomCalendar
                            locale={da}
                            selected={selectedDay ?? addDays(new Date(), waitingDays)}
                            disabled={disabledDates}
                            mode="default"
                            fromMonth={addDays(new Date(), waitingDays)}
                            defaultMonth={selectedDay ?? addDays(new Date(), waitingDays)}
                            onDayClick={(date: Date | undefined) => {
                                if (date) {
                                    onDayChange(date);
                                    setIsOpen(false);
                                }
                            }}
                        />
                    )}
                </CalendarWrapper>
            </DropdownContainer>
        </BorderBox>
    );
};
