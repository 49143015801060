import styled from 'styled-components';

export const Wrapper = styled.section`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.palette.background.default};
    border: 1px solid ${({ theme }) => theme.palette.grey.medium};
    padding: 15px;
`;

export const Header = styled.h4`
    margin-bottom: 10px;
    font-weight: 500;
`;

export const TimeSlotPickupHeader = styled.p`
    margin-bottom: 15px;
    text-decoration: underline;
`;

export const TimeSlot = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 0.4rem;

    & > div {
        margin-bottom: 10px;
    }
`;

export const CheckboxText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
