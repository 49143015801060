import styled, { keyframes } from 'styled-components';

const verticalAnimation = keyframes`
    0% {
        opacity: 0;
        transform: translateY(-1rem);
    }

    100% {
        opacity: 1;
        transform: none;
    }
`;

const horizontalAnimation = keyframes`
    0% {
        opacity: 0;
        transform: translateY(-1rem);
    }

    100% {
        opacity: 1;
        transform: none;
    }
`;

const pulseAnimation = keyframes`
    0% {
        opacity: 1;
        transform: none;
    }

    50% {
        opacity: 0.8;
        transform: scale(0.6);
    }

    100% {
        opacity: 1;
        transform: none;
    }
`;

export const ScrollCue = styled.div<{ top: string; right: string; bottom: string; left: string; fullWidth: boolean }>`
    position: absolute;
    top: ${(props) => props.top};
    right: ${(props) => props.right};
    bottom: ${(props) => props.bottom};
    left: ${(props) => props.left};
    width: ${(props) => (props.fullWidth ? '100%' : 'unset')};
    text-align: center;
    animation: ${pulseAnimation} 2s 2s ease-out infinite;
    z-index: 300;

    @media screen and (min-width: 527px) {
        display: none;
    }
`;

export const CueArrow = styled.img<{ vertical: boolean }>`
    animation: ${(props) => (props.vertical ? verticalAnimation : horizontalAnimation)} 1s 0s ease-out forwards;
    opacity: 0;
`;
