import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const BorderBox = styled.section`
    /* position: sticky;
    top: 10px; */

    background-color: #ffffff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);

    isolation: isolate;
    z-index: 100;

    padding: 16px 10px;

    @media ${device.tablet} {
        padding: 16px 25px;
    }
`;

export const HeaderWrapper = styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
`;

export const Header = styled.h2`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
`;

export const Badge = styled.figure`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1rem;
    background-color: black;
    padding: 5px;
`;

export const BadgeContent = styled.p`
    color: #fcfcfc;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 10px;
`;

export const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px 0;
`;

export const TextGroup = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Label = styled.p`
    color: #807f80;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
`;
export const StyledValue = styled.p`
    color: #0b0b0b;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
`;
