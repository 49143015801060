import styled from 'styled-components';
import { device } from '../../../../lib/media-query';
import { ToggleUi } from '../../../shared/toggle-ui';

export const MobileFilterToggle = styled.div<{ visible: boolean }>`
    position: fixed;
    bottom: ${(props) => (props.visible ? '25px' : '-150px')};
    opacity: ${(props) => (props.visible ? '1' : '0')};
    border-radius: 25px;
    background-color: ${({ theme: { palette } }) => palette.primary.main};
    color: white;
    padding: 12px 15px;
    display: inline-flex;
    align-items: center;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    transition: all 0.5s ease-in-out;
    min-width: 300px;
    justify-content: center;

    @media ${device.laptopS} {
        display: none;
    }

    @media ${device.mobile_tablet} {
        min-width: 0;
    }
`;

export const MobileFilterToggleButton = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-weight: 500;
    padding: 0 10px 0 0;

    &:not(:first-of-type) {
        border-left: 1px solid white;
        padding: 0 0 0 10px;
    }
`;

export const MobileFilterIndicator = styled.span`
    opacity: 0.5;
    flex-shrink: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 80px;
`;

export const FilterWrapper = styled.aside`
    display: none;
    flex-direction: column;
    @media ${device.laptopS} {
        display: flex;
    }
`;

export const FilterContainer = styled.div`
    background-color: white;
    padding: 10px;
    box-shadow: ${({ theme: { shadows } }) => shadows.large};
`;

export const FilterHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
`;

export const ResetFilters = styled.button`
    padding: 0;
    background-color: white;
    border: 0;
    color: ${({ theme: { palette } }) => palette.primary.main};
    text-decoration: underline;
    font-size: 12px;
    cursor: pointer;
    font-weight: 500;
`;

export const FilterHeaderText = styled.p`
    font-size: 14px;
`;

export const StyledPurchaseTypeToggle = styled(ToggleUi)`
    @media ${device.tablet} {
        width: 75%;
        margin-bottom: 10px;
    }
`;
