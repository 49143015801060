import { FC } from 'react';
import { BaseInput } from '../base';
import type { Props } from './text-input.props';
import { StyledTextInput } from './text-input.styled';

const TextInput: FC<Props> = ({ type, onChange, onFocus, readonly = false, max, min, pattern, onInputBlur, ...props }) => {
    return (
        <BaseInput {...props} onInputBlur={onInputBlur}>
            {({ ...childProps }) => (
                <StyledTextInput
                    {...childProps}
                    type={type}
                    min={min}
                    max={max}
                    onFocus={onFocus}
                    onChange={onChange}
                    readOnly={readonly}
                    pattern={pattern}
                />
            )}
        </BaseInput>
    );
};

export default TextInput;
