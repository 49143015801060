import { useState } from 'react';
import { DispatchMethod } from '../gtm-tracking.types';
import { COMMON_GTM } from '../gtm-tracking-utils';

export type MembershipEvents = {
    completeCancellation: (action: string) => void;
    openCancellation: () => void;
    openSignUp: () => void;
};

const useMembershipEvents = (dispatch: DispatchMethod): MembershipEvents => {
    return useState({
        completeCancellation: (action: string) =>
            dispatch({
                event: 'Opsigelse',
                type: COMMON_GTM.COMPLETED,
                action,
            }),

        openCancellation: () =>
            dispatch({
                event: 'Opsigelse',
                type: COMMON_GTM.STARTED,
            }),

        openSignUp: () =>
            dispatch({
                event: 'Bliv medlem',
                type: COMMON_GTM.CLICK,
            }),
    })[0];
};

export const trackMembership = (dispatch: DispatchMethod): (() => MembershipEvents) => {
    return () => useMembershipEvents(dispatch);
};
