import { animated } from 'react-spring';
import styled from 'styled-components';

export const StyledReadMoreFlexContainer = styled.div<{ reversed: boolean }>`
    display: flex;
    flex-direction: ${(props) => (props.reversed ? 'column-reverse' : 'column')};
`;

export const StyledReadMoreContentWrapper = styled(animated.div)``;

export const StyledReadMoreContent = styled(animated.div)``;
