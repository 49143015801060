import styled from 'styled-components';

export const HeaderSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 0.9rem;
`;

export const Header = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
`;

export const Price = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    letter-spacing: 0;
    text-align: right;
`;

export const Description = styled.p`
    margin-bottom: 15px;
`;
