import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const ColumnWrapper = styled.div<{ columnCount: number }>`
    @media ${device.tablet} {
        display: grid;
        grid-template-columns: repeat(${(props) => props.columnCount}, 1fr);
        gap: 2rem;
    }
`;

export const BorderBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
`;

export const MultiInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const StyledError = styled.span`
    display: flex;
    flex-direction: column;
    color: ${({ theme: { palette } }) => palette.error.main};
    font-size: ${({ theme: { typography } }) => typography.small.fontSize}px;
`;

export const FormGroupCaption = styled.p`
    font-size: ${({ theme: { forms } }) => forms.groupCaptionFontSize}px;
    font-weight: bold;
    line-height: ${({ theme: { typography } }) => typography.bodyDefault.lineHeight};
    margin-bottom: ${({ theme: { forms } }) => forms.groupCaptionSpacing}px;
`;

export const CheckboxesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px 0 5px 0;

    @media ${device.tablet} {
        padding: 10px 0 10px 0;
        gap: 30px;
        flex-direction: revert;
    }
`;

export const StyledRedLabel = styled.span`
    /* color: #ff0000; */
    color: ${({ theme: { palette } }) => palette.error.main};
`;
