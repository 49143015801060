import { useMemo, VFC } from 'react';
import { orgTypes } from '../../../../lib/api/models/umbraco';
import { vehicleIsSupported, getBrandLogo, getSupportedVehicleIcon } from '../../../../utils/helpers/supported-brands.helpers';
import { LogoWrapper, ServiceIconWrapper, ServicesWrapper, StyleImage, StyledBrandDetailsBar, StyledImage } from './brand-and-service.styled';

type BrandAndServiceProps = {
    brandInfo: orgTypes.SupportedBrand;
};

export const BrandAndService: VFC<BrandAndServiceProps> = ({ brandInfo }) => {
    const hasBus = useMemo(() => vehicleIsSupported(brandInfo, 'Bus', 'Sales') || vehicleIsSupported(brandInfo, 'Bus', 'Workshop'), [brandInfo])

    return (
        <StyledBrandDetailsBar>
            <LogoWrapper>
                <StyleImage src={getBrandLogo(brandInfo)} alt="Brand Logo" />
            </LogoWrapper>

            <ServicesWrapper>
                <ServiceIconWrapper>
                    <StyledImage src={getSupportedVehicleIcon(brandInfo, 'Cars', 'Sales')} alt="Car Logo" />
                </ServiceIconWrapper>
                <ServiceIconWrapper>
                    <StyledImage src={getSupportedVehicleIcon(brandInfo, 'Vans', 'Sales')} alt="Car Logo" />
                </ServiceIconWrapper>
                <ServiceIconWrapper>
                    <StyledImage src={getSupportedVehicleIcon(brandInfo, 'Trucks', 'Sales')} alt="Car Logo" />
                </ServiceIconWrapper>
                {hasBus && (
                    <ServiceIconWrapper>
                        <StyledImage src={getSupportedVehicleIcon(brandInfo, 'Bus', 'Sales')} alt="Car Logo" />
                    </ServiceIconWrapper>
                )}
            </ServicesWrapper>

            <ServicesWrapper>
                <ServiceIconWrapper>
                    <StyledImage src={getSupportedVehicleIcon(brandInfo, 'Cars', 'Workshop')} alt="Car Logo" />
                </ServiceIconWrapper>
                <ServiceIconWrapper>
                    <StyledImage src={getSupportedVehicleIcon(brandInfo, 'Vans', 'Workshop')} alt="Car Logo" />
                </ServiceIconWrapper>
                <ServiceIconWrapper>
                    <StyledImage src={getSupportedVehicleIcon(brandInfo, 'Trucks', 'Workshop')} alt="Car Logo" />
                </ServiceIconWrapper>
                {hasBus && (
                    <ServiceIconWrapper>
                        <StyledImage src={getSupportedVehicleIcon(brandInfo, 'Bus', 'Workshop')} alt="Car Logo" />
                    </ServiceIconWrapper>
                )}
            </ServicesWrapper>
        </StyledBrandDetailsBar>
    );
};
