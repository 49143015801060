import { FC } from 'react';
import { ISubmittedInfo } from './submitted-info.props';
import { Wrapper, StyledLabel, StyledText } from './submitted-info.styled';

export const SubmittedInfo: FC<ISubmittedInfo> = ({ label, text, overrideMaxWidth }) => {
    return (
        <Wrapper overridePadding={!!overrideMaxWidth}>
            <StyledLabel>{label}</StyledLabel>
            <StyledText overrideMaxWidth={!!overrideMaxWidth}>{text}</StyledText>
        </Wrapper>
    );
};
