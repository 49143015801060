import React from 'react';
import { sharedTypes } from '../../../../lib/api/models/umbraco';
import { UrlType } from '../../../../lib/api/models/umbraco/shared-types';
import { hesselViewModels } from '../../../../lib/view-models';
import { MEDIA_URL } from '../../../../utils/environment-constants';
import { StyledPaymentPlanButton, StyledPriceLinks, StyledPriceListLink } from './vehicle-price-links.styled';

type VehiclePriceLinksProps = {
    hirePriceListLink?: sharedTypes.CtaUrl;
    downloadPriceListIcon: sharedTypes.Image;
    paymentPlanIcon: sharedTypes.Image;
    brochureIcon: sharedTypes.Image;
    paymentPlanLabel: string;
    onPaymentPlanClick: () => void;
    ownershipMode: hesselViewModels.OwnershipMode;
    selectedTab: hesselViewModels.OwnershipTab;
    product?: hesselViewModels.ProductDetails;
};

const VehiclePriceLinksMemo = ({
    hirePriceListLink,
    downloadPriceListIcon,
    paymentPlanIcon,
    brochureIcon,
    paymentPlanLabel,
    onPaymentPlanClick,
    ownershipMode,
    selectedTab,
    product,
}: VehiclePriceLinksProps) => {
    if (!product) {
        return null;
    }

    const priceListLink = getPriceListLink(selectedTab, product, hirePriceListLink);

    if (
        !priceListLink &&
        !(ownershipMode === 'Leasing' && product.vehicleType === 'Car' && selectedTab === 'Car HiRE') &&
        !(product.vehicleType === 'Van' && product.availability === 'Order' && !!product.brochureUrl)
    ) {
        return null;
    }

    return (
        <StyledPriceLinks>
            {priceListLink ? (
                <StyledPriceListLink
                    href={priceListLink.type === UrlType.Media ? MEDIA_URL + priceListLink.url : priceListLink.url}
                    iconUrl={MEDIA_URL + downloadPriceListIcon?.src}
                    openInNewTab={priceListLink.target === '_blank'}
                    linkText={priceListLink.name}
                />
            ) : null}
            {ownershipMode === 'Leasing' && product.vehicleType === 'Car' && selectedTab === 'Car HiRE' ? (
                <StyledPaymentPlanButton label={paymentPlanLabel} onClick={() => onPaymentPlanClick()} iconUrl={MEDIA_URL + paymentPlanIcon?.src} />
            ) : null}

            {product.vehicleType === 'Van' && product.availability === 'Order' && !!product.brochureUrl ? (
                <StyledPaymentPlanButton
                    label={'Hent brochure'}
                    onClick={() => window.open(product.brochureUrl, '_blank')}
                    iconUrl={MEDIA_URL + brochureIcon.src}
                />
            ) : null}
        </StyledPriceLinks>
    );
};

function getPriceListLink(
    selectedTab: hesselViewModels.OwnershipTab,
    product: hesselViewModels.ProductDetails,
    hirePriceListLink?: sharedTypes.CtaUrl
): sharedTypes.CtaUrl | undefined {
    const productPriceLink = {
        name: `Se komplet prisliste her`,
        target: '_blank',
        type: UrlType.External,
    };

    switch (selectedTab) {
        case 'Car Kontant': {
            const url = product.purchaseTypes.cash.url;
            return url ? { ...productPriceLink, url: url } : undefined;
        }

        case 'Car Finansiering': {
            return undefined;
        }

        case 'Car HiRE':
            return hirePriceListLink;

        case 'Car Privat Leasing': {
            const url = product.purchaseTypes.privateLeasing.url;
            return url ? { ...productPriceLink, url: url } : undefined;
        }

        case 'Van Kontant': {
            const url = product.purchaseTypes.cash.url;
            return url ? { ...productPriceLink, url: url } : undefined;
        }

        case 'Van Finansiering':
            return undefined;

        case 'Van Operationel Leasing': {
            const url = product.purchaseTypes.operationalLeasing.url;
            return url ? { ...productPriceLink, url: url } : undefined;
        }

        case 'Van Finansiel Leasing': {
            const url = product.purchaseTypes.financialLeasing.url;
            return url ? { ...productPriceLink, url: url } : undefined;
        }

        case 'No Value':
            throw new Error('Unknown vehicle type');

        default: {
            const exhaustiveCheck: never = selectedTab;
            return exhaustiveCheck;
        }
    }
}

export const VehiclePriceLinks = React.memo(VehiclePriceLinksMemo);
