import { FC } from 'react';
import { Accordion } from '../../shared/accordion';
import { IFaqList } from './faq-list.props';
import { AccordionWrapper, AccordionText } from './faq-list.styled';
import { StyledHeader, StyledWrapper } from '../_shared';
import { useAccordionController } from '../../../hooks/use-accordion-controller';

export const FaqList: FC<IFaqList> = ({ title, questions = [] }) => {
    const { registerAccordion } = useAccordionController();

    return (
        <StyledWrapper>
            <StyledHeader>{title}</StyledHeader>

            {questions.map((x, index) => {
                return (
                    <AccordionWrapper key={`question:${index}`}>
                        <Accordion
                            title={x.question}
                            variant="primary"
                            border={index === 0 ? 'top and bottom' : 'bottom'}
                            headerSize="sm"
                            {...registerAccordion(index)}
                        >
                            <AccordionText>{x.answer}</AccordionText>
                        </Accordion>
                    </AccordionWrapper>
                );
            })}
        </StyledWrapper>
    );
};
