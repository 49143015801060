import styled from 'styled-components';

export const StyledPriceContainer = styled.div`
    padding: 0 10px 10px;
    margin-top: auto;
    color: #0b0b0b;
`;

export const StyledPriceFlexContainer = styled.div`
    display: flex;
    justify-content: space-between;

    &:not(:first-of-type) {
        margin-top: 10px;
    }
`;

export const StyledPriceLabel = styled.p`
    font-size: 10px;
    line-height: 14px;
    font-weight: 400;
    padding-top: 3px;
`;

export const StyledPriceValue = styled.p`
    font-size: 17px;
    line-height: 18px;
    font-weight: 600;
`;

export const StyledPriceAndDisclaimerInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    cursor: help;
`;

export const StyledDisclaimerLabel = styled.p`
    display: flex;
    align-items: center;
    color: #aeaeaf;
    font-size: 10px;
    line-height: 12px;
    margin-top: 5px;
    font-weight: 400;

    img {
        opacity: 0.4;
    }
`;

export const StyledSavingsIndicator = styled.span`
    opacity: 0.5;
    font-size: 10px;
    text-decoration: line-through;
    line-height: 10px;
    text-align: right;
    margin-right: 5px;
`;
