import { VFC } from 'react';
import { Button, CheckBox } from '..';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { CheckboxBarWrapper, TitleAndDescription, StyledValue, StyledLabel, StyledButtonText } from './checkbox-bar.styled';

type CheckboxBarProps = {
    className?: string;
    id: string;
    title: string;
    isSelected: boolean;
    disabled?: boolean;
    onChange?: (id: string, isSelected: boolean) => void;
    onReadMoreClick?: () => void;
    priceText: string;
};
const max_character_limit = 24;

export const CheckboxBar: VFC<CheckboxBarProps> = ({ className, id, title, isSelected, disabled, onChange, onReadMoreClick, priceText }) => {
    const isMobile = useMediaQuery({ target: 'mobileL' });

    return (
        <CheckboxBarWrapper className={className}>
            <TitleAndDescription>
                <CheckBox id={id} checked={isSelected} disabled={!!disabled} onChange={(e) => onChange?.(id, e)} value={id}>
                    <StyledLabel selected={isSelected}>
                        {onReadMoreClick && isMobile && title.length > max_character_limit
                            ? title.substring(0, max_character_limit).trim() + '...'
                            : title}
                    </StyledLabel>
                </CheckBox>
                {onReadMoreClick ? (
                    <Button onClick={() => onReadMoreClick()} variant="link">
                        <StyledButtonText>Læs mere</StyledButtonText>
                    </Button>
                ) : null}
            </TitleAndDescription>
            <StyledValue>{priceText}</StyledValue>
        </CheckboxBarWrapper>
    );
};
