import { FC } from 'react';
import { ServiceAgreementCalculatorStepThree } from '../../../../../lib/api/models/umbraco';
import { StyledCustomerInfoContainer } from '../../service-agreements-step/service-agreement-step.styled';
import { CustomerInfo } from '../../../../../lib/api/models/hessel-api';
import { getFormattedValue } from '../../../../../utils/helpers';

type Props = {
    stepSettings: ServiceAgreementCalculatorStepThree;
    customerInfo: CustomerInfo;
};

export const CustomerInfoSummary: FC<Props> = ({ stepSettings, customerInfo }) => {
    return (
        <StyledCustomerInfoContainer>
            <div>
                <label>
                    <b>{stepSettings.nameInputLabel}</b>
                </label>
                <p>{customerInfo.name}</p>
            </div>
            <div>
                <label>
                    <b>{stepSettings.emailInputLabel}</b>
                </label>
                <p>{customerInfo.email}</p>
            </div>
            <div>
                <label>
                    <b>{stepSettings.mileageInputLabel}</b>
                </label>
                <p>{getFormattedValue(customerInfo.mileage)} km.</p>
            </div>
        </StyledCustomerInfoContainer>
    );
};
