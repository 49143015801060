import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const ProductListMain = styled.main<{ showFacets: boolean }>`
    position: relative;
    margin-bottom: 30px;

    ${(props) =>
        props.showFacets
            ? `
    @media ${device.laptopS} {
        display: grid;
        grid-template-columns: minmax(0, 1fr) 3.5fr;
        column-gap: 60px;
    }`
            : ''}
`;

export const PageHeader = styled.h1`
    font-family: 'Corporate E';
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;

    @media ${device.laptopS} {
        font-size: 40px;
    }
`;

export const StyledDescription = styled.p`
    color: #0b0b0b;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 10px;
`;

export const ResultsText = styled.p`
    color: #807f80;
    font-size: 17px;
    margin: 25px 0;

    @media ${device.laptopS} {
        margin-top: 30px;
    }

    strong {
        color: ${({ theme: { palette } }) => palette.common.black};
    }
`;

export const LoadingBlocker = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
`;

export const StyledFilterTilesWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin: -5px 0 20px;
`;

export const StyledFilterTile = styled.div<{ valid: boolean }>`
    background-color: ${(props) => (props.valid ? `white` : `#fff5f6`)};
    border: 1px solid ${(props) => (props.valid ? `white` : `red`)};
    padding: 0 0 0 7px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    color: ${(props) => (props.valid ? `black` : `red`)};
`;

export const StyledSvgWrapper = styled.span`
    transform: scale(0.5);
`;

export const HeaderAndDropdownWrapper = styled.div`
    display: flex;
    flex-direction: column;
    column-gap: 10px;

    @media ${device.tablet} {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`;
