import React, { useState, VFC } from 'react';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { sharedTypes } from '../../../lib/api/models/umbraco';
import { MEDIA_URL } from '../../../utils/environment-constants';
import { SidePanel } from '../../shared/modals/side-panel';
import { SidePanelLayout } from '../../shared/modals/side-panel-layout/side-panel-layout.component';
import { SimpleTextAndImageSpot } from '../../shared/simple-text-and-image/simple-text-and-image.component';
import { useUI } from '../../ui';
import { IframeWrapper, ImageAndIframe, ImageWrapper, StyledImage, StyledHeader, StyledIFrame } from './vacant-jobs-modal.styled';

type VacantJobsModalProps = {
    header: string;
    description: string;
    ctaText: string;
    image: sharedTypes.Image;
    modalImage: sharedTypes.Image;
};

export const VacantJobsModalMemo: VFC<VacantJobsModalProps> = ({ header, description, ctaText, image, modalImage }) => {
    const [showVacantJobsModal, setShowVacantJobsModal] = useState(false);
    const isMobile = useMediaQuery({ target: 'tablet' });
    const uiHandler = useUI();

    const hideScrollBars = () => {
        if (isMobile) {
            uiHandler.removeScrollAndLock();
        } else {
            uiHandler.removeScroll();
        }
    };

    const applyScrollBars = () => {
        uiHandler.applyScroll();
        setShowVacantJobsModal(false);
    };

    return (
        <>
            <SimpleTextAndImageSpot
                header={header}
                description={description}
                ctaText={ctaText}
                ctaAction={() => {
                    hideScrollBars();
                    setShowVacantJobsModal(true);
                }}
                image={image}
            />

            <SidePanel cancelAction={() => applyScrollBars()} isVisible={showVacantJobsModal} variant="70%">
                <SidePanelLayout closeSidePanel={() => applyScrollBars()}>
                    <StyledHeader>{header}</StyledHeader>
                    <ImageAndIframe>
                        <ImageWrapper>
                            <StyledImage src={MEDIA_URL + modalImage?.src} alt={modalImage?.name} />
                        </ImageWrapper>
                        <IframeWrapper>
                            <StyledIFrame
                                title="Ejner Hessel Ledige stillinger"
                                src="https://candidate.hr-manager.net/vacancies/list.aspx?customer=hessel"
                            ></StyledIFrame>
                        </IframeWrapper>
                    </ImageAndIframe>
                </SidePanelLayout>
            </SidePanel>
        </>
    );
};

export const VacantJobsModal = React.memo(VacantJobsModalMemo);
