import { FC } from 'react';
import { BorderBox, Header, Description } from './savings-item.styled';

type Props = {
    header: string;
    description: string;
    hideBottomBorder?: boolean;
};

export const SavingsItem: FC<Props> = ({ header, description, hideBottomBorder, children }) => {
    return (
        <BorderBox hideBottomBorder={hideBottomBorder ?? true}>
            <Header>{header}</Header>
            {description && description.length > 1 ? <Description>{description}</Description> : null}
            {children}
        </BorderBox>
    );
};
