import { FC } from 'react';
import { FormsTrackingStrategy, UmbracoForm } from '../../../lib/api/models/umbraco';
import { sanitizeMarkup } from '../../../utils/helpers';
import { SidePanel } from '../../shared/modals/side-panel';
import { SidePanelLayout } from '../../shared/modals/side-panel-layout/side-panel-layout.component';
import {
    StyledContactHesselFormDialogBody,
    StyledContactHesselFormDialogHeader,
    StyledContactHesselFormDialogSelectionSummary,
    StyledContactHesselFormDialogTitle,
} from './contact-hessel-form.styled';

export type ContactHesselFormDialogProps = {
    form: UmbracoForm;
    formHeader: string;
    formSubText: string;
    trackingStrategy?: FormsTrackingStrategy;
    isVisible: boolean;
    locationId?: string;
    onClose: () => void;
    pageId: string;
    selectedModel?: string;
    carId?: string;
};

export const ContactHesselFormDialog: FC<ContactHesselFormDialogProps> = (props) => {
    return (
        <SidePanel cancelAction={props.onClose} isVisible={props.isVisible}>
            <SidePanelLayout closeSidePanel={props.onClose}>
                <StyledContactHesselFormDialogHeader>
                    <StyledContactHesselFormDialogTitle>{props.formHeader}</StyledContactHesselFormDialogTitle>

                    <StyledContactHesselFormDialogSelectionSummary {...sanitizeMarkup(props.formSubText)} />
                </StyledContactHesselFormDialogHeader>
                <StyledContactHesselFormDialogBody
                    form={props.form}
                    pageId={props.pageId}
                    trackingStrategy={props.trackingStrategy}
                    submitExtraPayload={props.carId && props.selectedModel ? { car_id: props.carId, car_name: props.selectedModel } : undefined}
                    specialProps={props.locationId ? { dealershipId: props.locationId } : undefined}
                    componentIdSuffix={props.carId && props.selectedModel ? `${props.carId}-${props.selectedModel}` : undefined}
                />
            </SidePanelLayout>
        </SidePanel>
    );
};
