import styled from 'styled-components';

export const MobileFilterWrapper = styled.div`
    position: relative;
    min-height: 100%;
    width: 100%;
    background-color: white;
    padding: 0 20px;
`;

export const MobileFiltersHeader = styled.header`
    cursor: pointer;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    padding: 20px 0;
    background-color: white;
    z-index: 1;
    border-bottom: 1px solid ${({ theme: { palette } }) => palette.grey.medium};
    font-size: 13px;
    font-weight: 500;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 15px;
`;

export const MobileFiltersFooter = styled.footer`
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px 0;
    background-color: white;
    z-index: 1;
    border-top: 1px solid ${({ theme: { palette } }) => palette.grey.medium};
    display: flex;
    column-gap: 20px;
    align-items: center;

    button {
        font-size: 13px;
        &:last-of-type {
            flex-grow: 1;
        }
    }
`;
