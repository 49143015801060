import styled from 'styled-components';
import { device } from '../../../lib/media-query';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';

export const StyledCenteredBlock = styled(CenteredBlock)`
    padding: 20px 10px 50px;
    background-color: #f7f7f8;
`;

export const StyledHesselCenteredBlock = styled(CenteredBlock)`
    padding: 50px 0px;

    @media ${device.mobileL} {
        padding: 50px 10px;
    }

    background-color: #f7f7f8;
`;

export const StyledMain = styled.main`
    @media ${device.laptopS} {
        display: grid;
        grid-template-columns: auto 350px;
        grid-column-gap: 2rem;
    }

    @media ${device.desktop} {
        grid-column-gap: 4rem;
    }
`;
