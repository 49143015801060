import styled from 'styled-components';

export const StyledIconButtonWithLabel = styled.button`
    align-items: center;
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    gap: 1em;
    padding: 0;

    &:focus,
    &:focus-visible {
        outline: none;
    }

    &:hover span {
        color: ${(props) => props.theme.palette.text.label};
    }

    &:active span {
        color: ${(props) => props.theme.palette.primary.main};
    }

    &:focus-visible div {
        outline: 2px dotted ${(props) => props.theme.palette.primary.main};
        outline-offset: -2px;
    }

    &:hover div {
        transform: scale(1.1);
    }

    &:active div {
        background: ${(props) => props.theme.palette.common.offWhite};
        fill: ${(props) => props.theme.palette.primary.main};
        stroke: ${(props) => props.theme.palette.primary.main};
        transform: scale(0.95);
    }
`;

export const StyledIconButtonWithLabelText = styled.span``;

export const StyledIconButtonWithLabelIcon = styled.div`
    align-items: center;
    background: ${(props) => props.theme.palette.common.white};
    border-radius: 100%;
    box-shadow: ${(props) => props.theme.shadows.small};
    display: inline-flex;
    fill: ${(props) => props.theme.palette.common.black};
    justify-content: center;
    padding: 0.8em;
    stroke: ${(props) => props.theme.palette.common.black};
    transition: transform 150ms;

    > svg {
        height: 2em;
        width: 2em;
    }
`;
