import { FC, useMemo, useState } from 'react';
import {
    StyledCtaWrapper,
    StyledDesktopSummary,
    StyledInfoWrapper,
    StyledMobileSummary,
    StyledMobileSummaryCta,
    StyledMobileSummaryHeader,
    StyledMobileTotal,
    StyledOpenMobileSummaryButton,
    StyledOrderSummary,
    StyledSidePanel,
    StyledSidePanelClose,
} from './checkout-summary.styled';
import { CartStoreContext } from '../../../../lib/state/shop/cart/cart.store';
import { getShopPrice } from '../../../../utils/helpers';
import { SvgIcon } from '../../../shared/svg-icon';
import { SidePanel } from '../../../shared/modals/side-panel';
import { CheckoutSummaryTotalAndOrderlines } from './checkout-summary-total-orderlines.component';
import router from 'next/router';

export const CheckoutSummary: FC = () => {
    const { cart, cartReceipt, shopCheckoutComplete } = CartStoreContext.useStoreState((state) => state);
    const cartToShow = shopCheckoutComplete ? cartReceipt : cart;
    const [mobileSummaryOpen, setMobileSummaryOpen] = useState(false);
    const itemsTotal = useMemo(() => {
        return cartToShow ? cartToShow.lineItems.reduce((prev, curr) => prev + curr.quantity, 0) : 0;
    }, [cartToShow]);
    if (!cartToShow) return null;
    return (
        <>
            <StyledOrderSummary>
                <StyledMobileSummary>
                    <div>
                        <StyledMobileSummaryHeader>Kurv ({itemsTotal})</StyledMobileSummaryHeader>
                        <StyledMobileTotal>{getShopPrice(cartToShow.totalPrice)}</StyledMobileTotal>
                    </div>
                    <StyledOpenMobileSummaryButton onClick={() => setMobileSummaryOpen(true)}>
                        <span>Vis kurv</span>
                        <SvgIcon iconName="chevron/right" color="primary" />
                    </StyledOpenMobileSummaryButton>
                </StyledMobileSummary>
                <StyledDesktopSummary>
                    <CheckoutSummaryTotalAndOrderlines />
                </StyledDesktopSummary>
            </StyledOrderSummary>
            <SidePanel cancelAction={() => setMobileSummaryOpen(false)} isVisible={mobileSummaryOpen}>
                <StyledSidePanel>
                    <StyledSidePanelClose>
                        <SvgIcon iconName="close" onClick={() => setMobileSummaryOpen(false)} />
                    </StyledSidePanelClose>
                    <StyledInfoWrapper>
                        <CheckoutSummaryTotalAndOrderlines />
                        <StyledCtaWrapper>
                            <StyledMobileSummaryCta onClick={() => setMobileSummaryOpen(false)} variant="primary">
                                TILBAGE TIL CHECKOUT
                            </StyledMobileSummaryCta>
                            <StyledMobileSummaryCta onClick={() => router.back()}>REDIGER KURV</StyledMobileSummaryCta>
                        </StyledCtaWrapper>
                    </StyledInfoWrapper>
                </StyledSidePanel>
            </SidePanel>
        </>
    );
};
