import { memo } from 'react';
import { useScrollLock } from '../../../../hooks/use-scroll-lock';
import { EquipmentDialogManagerStore } from '../../../../lib/state/hessel-site/equipment-dialog-manager';
import { ProductDetailsStore } from '../../../../lib/state/hessel-site/product-details';
import { EquipmentSection } from '../../equipment-and-addons/equipment-card/equipment-card.component';
import { StandardEquipment } from '../../standard-equipment';

const ProductDetailsPageInStockCarPanelComponent = memo(() => {
    const { setShowAllEquipment } = EquipmentDialogManagerStore.useStoreActions((actions) => actions);
    const { selectedProduct, selectedTab } = ProductDetailsStore.useStoreState((state) => state);
    const { hideScrollBars } = useScrollLock();
    return selectedProduct ? (
        <>
            <EquipmentSection
                equipmentIsIncluded={false}
                onlyShowIncludedEquipment={true}
                header="Ekstraudstyr i denne bil"
                description="Dette er ekstraudstyr som bilen står med og som er inkluderet i prisen"
            />
            <StandardEquipment
                availability={selectedProduct.availability}
                standardEquipmentList={selectedProduct.standardEquipment[selectedTab]}
                standardEquipmentPackages={selectedProduct.standardEquipmentPackages[selectedTab]}
                showAllEquipment={() => {
                    setShowAllEquipment(true);
                    hideScrollBars();
                }}
                equipmentNames={selectedProduct.equipmentNames}
                header="Standardudstyr"
                description="Bemærk at bilen godt kan stå med mere udstyr end nedenstående"
            />
        </>
    ) : null;
});

export default ProductDetailsPageInStockCarPanelComponent;
