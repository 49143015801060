import { animated } from 'react-spring';
import styled from 'styled-components';

export const StyledMenuItem = styled.a<{ megaMenuOpen: boolean }>`
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    color: black;
    transition: all 0.2s ease-in-out;
    padding: 10px 0;
    display: block;
    &:hover {
        text-decoration: none;
    }
    border-bottom: 2px solid ${(props) => (props.megaMenuOpen ? '#0089D1' : 'transparent')};
    ${(props) => (props.megaMenuOpen ? 'color: #0089D1;' : '')}
`;

export const StyledItemWrapper = styled.div``;

export const StyledInspirationalLinkWrapper = styled.div<{ gridItems: number }>`
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(${(props) => props.gridItems}, 1fr);
`;

export const StyledPrimaryLinkHeader = styled.p`
    font-family: 'Corporate E';
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 10px;
`;

export const StyledPrimaryLinkWrapper = styled.div`
    margin-top: 30px;
    display: grid;
    column-gap: 40px;
    grid-template-columns: 1fr 1fr;
`;

export const StyledMegaMenuContentWrapper = styled(animated.div)`
    background-color: white;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
`;

export const StyledFadeWrapper = styled(animated.div)``;

export const StyledMegaMenuContentGrid = styled.div`
    display: grid;
    grid-template-columns: 2.5fr 1fr;
    column-gap: 100px;
`;

export const StyledSecondaryMenuWrapper = styled.div`
    background-color: #f7f7f8;
    padding: 30px 40px;
`;

export const StyledPrimaryMenuArea = styled.div`
    margin-top: 30px;
`;
