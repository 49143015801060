import styled, { DefaultTheme } from 'styled-components';

const getBackgroundColor = (isSelected: boolean, theme: DefaultTheme) => {
    return isSelected ? theme.palette.primary.main : theme.palette.common.white;
};

const getTextColor = (isAvailable: boolean, isSelected: boolean, theme: DefaultTheme) => {
    if (isSelected) {
        return theme.palette.common.white;
    }

    if (isAvailable) {
        return theme.palette.primary.main;
    }

    return theme.palette.text.disabled;
};

const getFontSize = (isSelected: boolean, theme: DefaultTheme) => {
    return isSelected ? theme.typography.fontWeight.bold : theme.typography.fontWeight.medium;
};

const getCursor = (isAvailable: boolean, isSelected: boolean) => {
    if (isSelected) {
        return 'cursor';
    }

    if (!isAvailable) {
        return 'not-allowed';
    }

    return 'pointer';
};

export const Rectangle = styled.div<{ isSelected: boolean; isAvailable: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    background-color: ${(props) => getBackgroundColor(props.isSelected, props.theme)};
    width: 85px;
    height: 31px;
    border-radius: 4px;
    cursor: ${(props) => getCursor(props.isAvailable, props.isSelected)};
`;

export const Diameter = styled.div`
    position: absolute;
    height: 0.5px;
    width: 100px;
    background-color: ${({ theme }) => theme.palette.text.disabled};
    transform: rotate(-19deg) scale(2);
`;

export const Text = styled.p<{ isAvailable: boolean; isSelected: boolean }>`
    color: ${(props) => getTextColor(props.isAvailable, props.isSelected, props.theme)};
    font-weight: ${(props) => getFontSize(props.isSelected, props.theme)};
`;
