import Image from 'next/image';
import { VFC, memo, useCallback, useMemo } from 'react';
import { useScrollLock } from '../../../../hooks/use-scroll-lock';
import { EquipmentDialogManagerStore } from '../../../../lib/state/hessel-site/equipment-dialog-manager';
import { ProductDetailsStore } from '../../../../lib/state/hessel-site/product-details';
import { hesselViewModels } from '../../../../lib/view-models';
import { formatPrice } from '../../../../utils/helpers';
import { Button, CheckBox } from '../../../shared';
import Collapsible from '../../../shared/collapsible/collapsible';
import { useEquipmentData } from '../../hooks/use-equipment-data';
import ProductDetailsCardComponent from '../../product-details-page/shared/product-details-card.component';
import {
    AddOnInfoWrapper,
    CheckboxAndReadMore,
    StyledButtonText,
    StyledCheckboxWrapper,
    StyledEqCheckboxGridArea,
    StyledEqPriceGridArea,
    StyledEqReadMoreGridArea,
    StyledEquipmentSection,
    StyledEquipmentSum,
    StyledEquipmentText,
    StyledIncludedEquipmentItem,
    StyledPrice,
} from './equipment-card.styled';

export type EquipmentSectionProps = {
    className?: string;
    header?: string;
    description?: string;
    equipmentIsIncluded: boolean;
    onlyShowIncludedEquipment?: boolean;
};

export const EquipmentSection: VFC<EquipmentSectionProps> = memo(
    ({ className, header, equipmentIsIncluded, onlyShowIncludedEquipment, description }) => {
        const { getEquipmentPrice } = useEquipmentData();
        const { updateExtraEquipment, updateExtraEquipmentPackage } = ProductDetailsStore.useStoreActions((actions) => actions);
        const { selectedProduct, includedEquipmentText, selectedTab } = ProductDetailsStore.useStoreState((state) => state);
        const { setSelectedEquipment, setSelectedEquipmentPackage } = EquipmentDialogManagerStore.useStoreActions((actions) => actions);
        const { hideScrollBars } = useScrollLock();

        const extraEquipment = useMemo(() => {
            const equipmentPerTab = selectedProduct?.extraEquipment?.[selectedTab];
            const equipment = onlyShowIncludedEquipment
                ? equipmentPerTab?.filter((equipment) => equipment.ownershipStatus === 'ExtraIncluded')
                : equipmentPerTab?.filter((equipment) => equipment.ownershipStatus === 'Extra' || equipment.ownershipStatus === 'ContactForPrice');
            return equipment ?? [];
        }, [selectedProduct?.extraEquipment, selectedTab, onlyShowIncludedEquipment]);

        const extraEquipmentPackage = useMemo(() => {
            const equipmentPackagePerTab = selectedProduct?.extraEquipmentPackages?.[selectedTab];
            const equipment = onlyShowIncludedEquipment
                ? equipmentPackagePerTab?.filter((equipment) => equipment.ownershipStatus === 'ExtraIncluded')
                : equipmentPackagePerTab?.filter(
                      (equipment) => equipment.ownershipStatus === 'Extra' || equipment.ownershipStatus === 'ContactForPrice'
                  );
            return equipment ?? [];
        }, [selectedProduct?.extraEquipmentPackages, selectedTab, onlyShowIncludedEquipment]);

        const equipmentSelect = useCallback(
            (equipment: hesselViewModels.Equipment, isSelected: boolean) => {
                updateExtraEquipment({
                    id: equipment.id,
                    isSelected: isSelected,
                });
            },
            [updateExtraEquipment]
        );

        const equipmentSum = useMemo(() => {
            if (!onlyShowIncludedEquipment) return `0`;
            const extraEquipmentSum = extraEquipment
                .map((val) => val.cashPrice ?? 0)
                .reduce((accumulator, currentVal) => accumulator + currentVal, 0);
            const extraEquipmentPackageSum = extraEquipmentPackage
                .map((val) => val.cashPrice ?? 0)
                .reduce((accumulator, currentVal) => accumulator + currentVal, 0);

            return `${formatPrice(extraEquipmentSum + extraEquipmentPackageSum)} kr.`;
        }, [extraEquipmentPackage, extraEquipment, onlyShowIncludedEquipment]);

        const equipmentPackageSelect = useCallback(
            (eqPackage: hesselViewModels.EquipmentPackage, isSelected: boolean) => {
                updateExtraEquipmentPackage({
                    id: eqPackage.id,
                    isSelected,
                });
            },
            [updateExtraEquipmentPackage]
        );

        const showMoreInfoAboutEquipment = useCallback(
            (args: hesselViewModels.Equipment | hesselViewModels.EquipmentPackage, isPackage: boolean) => {
                if (isPackage) {
                    setSelectedEquipmentPackage(args as hesselViewModels.EquipmentPackage);
                } else {
                    setSelectedEquipment(args as hesselViewModels.Equipment);
                }
                hideScrollBars();
            },
            [hideScrollBars, setSelectedEquipment, setSelectedEquipmentPackage]
        );
        const stockCarSortedEquipment = useMemo(() => {
            const allEquipment = [...extraEquipment, ...extraEquipmentPackage].sort((a, b) => {
                const price1 = a.cashPrice ?? 0;
                const price2 = b.cashPrice ?? 0;
                if (price1 > price2) return -1;
                if (price1 < price2) return 1;
                return 0;
            });
            return allEquipment;
        }, [extraEquipment, extraEquipmentPackage]);
        return extraEquipment.length > 0 || extraEquipmentPackage.length > 0 ? (
            <>
                <ProductDetailsCardComponent
                    padding="large"
                    header={header ?? 'Ekstraudstyr & tilbehør'}
                    description={description ?? 'Dette er ekstraudstyr som det er muligt at tilkøbe'}
                >
                    <Collapsible collapsedHeight={435} readMoreBtnText="Indlæs mere udstyr" readLessBtnText="Vis færre">
                        <>
                            <StyledEquipmentSection className={className}>
                                {onlyShowIncludedEquipment ? (
                                    <>
                                        {stockCarSortedEquipment.map((equipment) => {
                                            const equipmentPrice = getEquipmentPrice(equipment, 'Car Kontant');

                                            return (
                                                <AddOnInfoWrapper key={`checkbox-and-action-${equipment.id}`}>
                                                    <CheckboxAndReadMore>
                                                        <StyledEqCheckboxGridArea>
                                                            <StyledIncludedEquipmentItem>
                                                                <Image src={'/icons/checkmark.svg'} alt="checkmark ikon" width={25} height={25} />
                                                                <StyledEquipmentText>{equipment.name}</StyledEquipmentText>
                                                            </StyledIncludedEquipmentItem>
                                                        </StyledEqCheckboxGridArea>
                                                        {equipment.description && equipment.description.length > 0 ? (
                                                            <StyledEqReadMoreGridArea>
                                                                <Button variant="link" onClick={() => showMoreInfoAboutEquipment(equipment, false)}>
                                                                    <StyledButtonText>Læs mere</StyledButtonText>
                                                                </Button>
                                                            </StyledEqReadMoreGridArea>
                                                        ) : null}
                                                        <StyledEqPriceGridArea>
                                                            <StyledPrice>
                                                                {equipmentIsIncluded ? includedEquipmentText : `${equipmentPrice.formatted}`}
                                                            </StyledPrice>
                                                        </StyledEqPriceGridArea>
                                                    </CheckboxAndReadMore>
                                                </AddOnInfoWrapper>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <>
                                        {extraEquipment.map((equipment) => {
                                            const equipmentPrice = getEquipmentPrice(equipment);

                                            return (
                                                <AddOnInfoWrapper key={`checkbox-and-action-${equipment.id}`}>
                                                    <CheckboxAndReadMore>
                                                        <StyledEqCheckboxGridArea>
                                                            <StyledCheckboxWrapper>
                                                                <CheckBox
                                                                    id={equipment.id}
                                                                    onChange={(e) => equipmentSelect(equipment, e)}
                                                                    value={equipment.id}
                                                                    checked={!!equipment.isSelected || equipmentIsIncluded}
                                                                    disabled={equipmentIsIncluded}
                                                                    textAlign="top"
                                                                >
                                                                    <StyledEquipmentText>{equipment.name}</StyledEquipmentText>
                                                                </CheckBox>
                                                            </StyledCheckboxWrapper>
                                                        </StyledEqCheckboxGridArea>
                                                        {equipment.description && equipment.description.length > 0 ? (
                                                            <StyledEqReadMoreGridArea>
                                                                <Button variant="link" onClick={() => showMoreInfoAboutEquipment(equipment, false)}>
                                                                    <StyledButtonText>Læs mere</StyledButtonText>
                                                                </Button>
                                                            </StyledEqReadMoreGridArea>
                                                        ) : null}
                                                        <StyledEqPriceGridArea>
                                                            <StyledPrice>
                                                                {equipmentIsIncluded ? includedEquipmentText : `${equipmentPrice.formatted}`}
                                                            </StyledPrice>
                                                        </StyledEqPriceGridArea>
                                                    </CheckboxAndReadMore>
                                                </AddOnInfoWrapper>
                                            );
                                        })}

                                        {extraEquipmentPackage?.map((eqPackage) => {
                                            const equipmentPackagePrice = getEquipmentPrice(eqPackage);
                                            return (
                                                <AddOnInfoWrapper key={`equipment-package-checkbox-and-action-${eqPackage.id}`}>
                                                    <CheckboxAndReadMore>
                                                        <StyledEqCheckboxGridArea>
                                                            <CheckBox
                                                                id={`${eqPackage.id}-${eqPackage.packageIdValue}`}
                                                                onChange={(e) => equipmentPackageSelect(eqPackage, e)}
                                                                value={eqPackage.id}
                                                                checked={!!eqPackage.isSelected || equipmentIsIncluded}
                                                                disabled={equipmentIsIncluded}
                                                            >
                                                                <StyledEquipmentText>{eqPackage.name}</StyledEquipmentText>
                                                            </CheckBox>
                                                        </StyledEqCheckboxGridArea>
                                                        {(eqPackage.description && eqPackage.description.length > 0) ||
                                                        eqPackage.equipment?.length > 0 ? (
                                                            <StyledEqReadMoreGridArea>
                                                                <Button variant="link" onClick={() => showMoreInfoAboutEquipment(eqPackage, true)}>
                                                                    <StyledButtonText>Læs mere</StyledButtonText>
                                                                </Button>
                                                            </StyledEqReadMoreGridArea>
                                                        ) : null}
                                                        <StyledEqPriceGridArea>
                                                            <StyledPrice>
                                                                {equipmentIsIncluded ? includedEquipmentText : equipmentPackagePrice.formatted}
                                                            </StyledPrice>
                                                        </StyledEqPriceGridArea>
                                                    </CheckboxAndReadMore>
                                                </AddOnInfoWrapper>
                                            );
                                        })}
                                    </>
                                )}
                            </StyledEquipmentSection>
                            {onlyShowIncludedEquipment && (
                                <StyledEquipmentSum>
                                    <span>Bilen står med ekstraudstyr til en værdi á</span>
                                    <span>{equipmentSum}</span>
                                </StyledEquipmentSum>
                            )}
                        </>
                    </Collapsible>
                </ProductDetailsCardComponent>
            </>
        ) : null;
    }
);
