import { FC, useEffect, useMemo, useState } from 'react';
import ServiceAgreementContainer from '../service-agreement-container/service-agreement-container.component';
import { StyledLabel, StyledSliderContainer } from '../service-agreement-step.styled';
import { InputSlider } from '../../../../shared/input-slider';
import { RadioButton } from '../../../../shared/radio-button';
import { ServiceAgreementCalculatorModalStore } from '../../../../../lib/state/service-agreement-calculator/service-agreement-calculator.store';
import { StyledHr } from '../../service-agreement-calculator-modal.styled';
import { CompleteCareAgreement } from '../../../../../lib/api/models/hessel-api';
import { formatPrice } from '../../../../../utils/helpers';

type Props = {
    agreements: CompleteCareAgreement[];
    description?: string;
};

export const CompleteCareAgreementOption: FC<Props> = ({ agreements, description }) => {
    const { selectedCompleteCare } = ServiceAgreementCalculatorModalStore.useStoreState((state) => state);
    const { setSelectedCompleteCare, clearAllAgreements } = ServiceAgreementCalculatorModalStore.useStoreActions((action) => action);
    const [mileageInput, setMileageInput] = useState(Math.min(...agreements.map((agreement) => agreement.mileage)));

    const filterAgreements = useMemo(() => {
        const mileage = agreements
            .filter((agreement) => agreement.mileage >= mileageInput)
            .sort((a, b) => (a.mileage > b.mileage ? 1 : -1))[0].mileage;
        return agreements.filter((agreement) => agreement.mileage === mileage);
    }, [agreements, mileageInput]);

    const agreementsByMonths = useMemo(() => {
        return [...filterAgreements].sort((a, b) => (a.months > b.months ? 1 : -1));
    }, [filterAgreements]);

    const cheapestAgreement = useMemo(() => {
        return [...filterAgreements].sort((a, b) => (a.price > b.price ? 1 : -1))[0];
    }, [filterAgreements]);

    useEffect(() => {
        if (selectedCompleteCare) {
            const selectedAgreement = [...agreementsByMonths].reverse().find((a) => a.months <= selectedCompleteCare.months);

            setSelectedCompleteCare(selectedAgreement ?? cheapestAgreement);
        }
    }, [agreementsByMonths, cheapestAgreement, selectedCompleteCare, setSelectedCompleteCare]);

    const setDefaultCompleteCare = () => {
        setSelectedCompleteCare(cheapestAgreement);
    };

    const formattedPrice = useMemo(() => {
        const price = selectedCompleteCare?.price ?? Math.min(...agreements.map((agreement) => agreement.price));
        return `${formatPrice(price)} kr./mdr.`;
    }, [agreements, selectedCompleteCare?.price]);

    return (
        <ServiceAgreementContainer
            id="completeCare"
            onChecked={() => {
                clearAllAgreements();
                setMileageInput(Math.min(...agreements.map((agreement) => agreement.mileage)));
                setDefaultCompleteCare();
            }}
            onUnchecked={() => {
                setSelectedCompleteCare(undefined);
            }}
            checked={!!selectedCompleteCare}
            title="Mercedes-Benz CompleteCare"
            titleDescription={description}
            priceText={formattedPrice}
        >
            <StyledHr />

            <StyledSliderContainer>
                <StyledLabel>Hvor mange kilometer kører du om året?</StyledLabel>
                <InputSlider
                    min={5000}
                    max={Math.max(...agreements.map((x) => x.mileage))}
                    defaultValue={Math.min(...agreements.map((x) => x.mileage))}
                    interval={5000}
                    value={mileageInput}
                    onValueChange={setMileageInput}
                    unit="km."
                />
            </StyledSliderContainer>
            <StyledLabel>Hvor lang tid ønsker du din serviceaftale?</StyledLabel>

            {agreementsByMonths.map((agreement, index) => {
                return (
                    <RadioButton
                        key={index}
                        value={''}
                        id={`completeCare${index}`}
                        groupName="completeCare"
                        checked={selectedCompleteCare === agreement}
                        action={() => {
                            setSelectedCompleteCare(agreement);
                        }}
                    >
                        <label>{agreement.months} måneder</label>
                    </RadioButton>
                );
            })}
        </ServiceAgreementContainer>
    );
};
