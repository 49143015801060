import styled from 'styled-components';

export const StyledControlsContainer = styled.div`
    display: flex;
    gap: 22px;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
`;

export const StyledControl = styled.button`
    display: flex;
    background-color: transparent;
    border: none;
    cursor: pointer;
    gap: 4px;
    padding: 0;
    border-radius: 20px;
    &:disabled {
        cursor: not-allowed;
        & * {
            filter: grayscale(0.5);
            opacity: 0.4;
            cursor: not-allowed;
            transform: none;
        }
    }
`;

export const StyledHorizontalSliderWrapper = styled.div`
    display: flex;
    user-select: none;
    touch-action: pan-y;
    width: 100%;
`;

export const StyledIconText = styled.p`
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    text-transform: uppercase;
`;
