import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const Wrapper = styled.div`
    background-color: ${({ theme }) => theme.palette.common.white};
    width: 100%;

    padding: 35px 25px;
    padding-bottom: 1rem;

    @media ${device.desktop} {
        min-width: 430px;
    }
`;

export const Header = styled.h2`
    text-align: center;
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey.medium};
    padding-bottom: 20px;
    font-size: 30px;
    letter-spacing: 0;
    line-height: 35px;
    font-family: 'Corporate E Demi';
`;

export const BenefitList = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 20px;
`;

export const BenefitItem = styled.div`
    display: flex;
    gap: 1rem;
`;

export const BenefitText = styled.p``;
