import styled from 'styled-components';
import { device } from '../../../lib/media-query';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';

export const StyledCenteredBlock = styled(CenteredBlock)`
    background-color: #ffffff;
`;

export const StyledOpeningHoursModule = styled.section`
    padding: 85px 0;

    display: flex;
    flex-direction: column;
    gap: 19px;

    @media ${device.tablet} {
        flex-direction: row;
        justify-content: space-between;
        gap: 112px;
    }
`;

export const HeaderAndDescription = styled.div`
    align-self: flex-start;
`;

export const StyledHeader = styled.h2`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: 10px;

    @media ${device.tablet} {
        font-size: 30px;
    }
`;

export const StyledDescription = styled.p`
    color: #0b0b0b;
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    font-size: 13px;
    letter-spacing: 0;
    font-weight: normal;

    @media ${device.tablet} {
        font-size: 14px;
    }
`;

export const OpeningHoursColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 95%;
`;

export const Footnote = styled.p`
    color: #0b0b0b;
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    font-size: 12px;
    letter-spacing: 0;
    font-weight: normal;
`;

export const StyledDisclaimerLink = styled.span`
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    font-size: 12px;
    letter-spacing: 0;
    font-weight: normal;
`;
