import styled from 'styled-components';

export const BorderBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 15px 0;
`;

export const StyledLabel = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
`;

export const ValuesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
`;
export const PriceLabel = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
`;
export const DownPayment = styled.p`
    color: #807f80;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
`;
