import { animated } from 'react-spring';
import styled from 'styled-components';
import { device } from '../../../lib/media-query';
import { mixins } from '../../../themes';
import { GeoSearch } from '../../icons';

export const StyledGeoInputWrapper = styled.div`
    width: revert;

    @media ${device.tablet} {
        width: 380px;
    }
`;

export const StyledGeoFilterInput = styled(animated.input)`
    border: none;
    background-color: rgba(0, 0, 0, 0);
    &:focus {
        outline: none;
    }
    ${mixins.typography('inputValue')}
    ::placeholder {
        ${mixins.typography('inputPlaceholder')}
        font-size: 12px;
    }
    width: 100%;

    @media ${device.tablet} {
        width: 248px;
    }
`;

export const StyledGeoSearch = styled(GeoSearch)`
    background-color: #f7f7f8;
    border-radius: 50%;
`;
