import { FC } from 'react';
import { StyledCenteredBlock, StyledCenteredWrapper } from './centered-block.styled';

export type CenteredBlockProps = {
    className?: string;
    maxWidth?: number;
};

export const CenteredBlock: FC<CenteredBlockProps> = (props) => {
    const maxWidth = props.maxWidth || 1340;
    return (
        <StyledCenteredWrapper className={props.className} role="none">
            <StyledCenteredBlock maxWidth={maxWidth} role="none">
                {props.children}
            </StyledCenteredBlock>
        </StyledCenteredWrapper>
    );
};
