import styled, { keyframes } from 'styled-components';
import { device } from '../../../lib/media-query';

const grow = keyframes`
 from {
    transform: scaleX(0);
 }

 to {
    transform: scaleX(1);
 }
`;

const shrink = keyframes`
 from {

    transform: scaleX(1);
 }

 to {
    transform: scaleX(0);
 }
`;

export const BorderBox = styled.div<{ isSelected: boolean }>`
    display: grid;
    grid-template-rows: repeat(1fr, 2);
    place-items: center;
    padding: 15px 20px;

    height: 90px;
    width: 100%;
    background-color: ${(props) => (props.isSelected ? '#ffffff' : '#F1F1F2')};
    box-shadow: ${(props) => (props.isSelected ? props.theme.shadows.large : 'none')};

    cursor: ${(props) => (!props.isSelected ? 'pointer' : 'revert')};
    pointer-events: ${(props) => (props.isSelected ? 'none' : 'revert')};

    position: relative;
    transition: background-color ease-in-out 0.4s;

    @media ${device.tablet} {
        width: 230px;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 4px;
        width: 100%;
        background-color: ${({ theme }) => theme.palette.primary.main};
        transform-origin: ${(props) => (props.isSelected ? 'left' : 'center')};
        animation-name: ${(props) => (props.isSelected ? grow : shrink)};
        animation-duration: 0.4s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
    }
`;

export const StyledText = styled.p`
    color: #0b0b0b;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const StyledImageWrapper = styled.div``;

export const StyledImage = styled.img`
    display: block;
`;
