import { useUI } from '../components/ui';
import { useMediaQuery } from './use-media-query';

type ScrollLockType = {
    hideScrollBars: () => void;
    applyScrollBars: () => void;
};

export const useScrollLock = (): ScrollLockType => {
    const uiHandler = useUI();
    const isMobile = useMediaQuery({ target: 'tablet' });

    function hideScrollBars() {
        if (isMobile) {
            uiHandler.removeScrollAndLock();
        } else {
            uiHandler.removeScroll();
        }
    }

    function applyScrollBars() {
        uiHandler.applyScroll();
    }

    return { hideScrollBars, applyScrollBars };
};
