import styled from 'styled-components';

export const StyledStarContainer = styled.div<{ width: number; spaceBetween: number }>`
    width: ${(props) => props.width}px;
    margin: 0px ${(props) => props.spaceBetween}px;

    svg {
        border-radius: unset;
    }
`;
