import { useEffect, useState, VFC } from 'react';
import { ReepayReceiptContent } from '../../../../lib/api/models/umbraco';
import { ReepayStore } from '../../../../lib/state/reepay-form/reepay-form.store';
import { ReceiptContainer, Header, TextWrapper, Text, FormSummary, FormSummaryHeader, FormSummarySection, CtaWrapper } from './reepay-receipt.styled';
import router from 'next/router';
import { CtaHandler } from '../../content-handlers';

type Props = {
    pageContent: ReepayReceiptContent;
};

const MEDIA_URL = process.env.NEXT_PUBLIC_MEDIA_URL;

const ReepayReceipt: VFC<Props> = ({ pageContent }) => {
    const { receiptInfo } = ReepayStore.useStoreState((state) => state);
    // const { resetStore } = ReepayStore.useStoreActions((actions) => actions);
    // const isStoreHydrated = ReepayStore.useStoreRehydrated();
    const [fromExternalOrigin, setFromExternalOrigin] = useState(false);

    // useEffect(() => {
    //     if (isStoreHydrated && !receiptInfo) {
    //         resetStore({ generateReceipt: true });
    //     }
    // }, [isStoreHydrated, receiptInfo, resetStore]);

    /*
        This is used for when a customer signs up using the promotion banner in the receipt step on online booking.
        The customer will be redirected to the relevant site for their benefit agreement receipt.
        Since the customer is redirected, and the SPA doesn't have the customers information, it is passed to it through query parameters
    */
    useEffect(() => {
        if (!receiptInfo) return;
        const { email, phone, fullName, address, city, postalCode } = router.query;

        if (!email || !phone || !fullName || !address || !city || !postalCode) return;
        receiptInfo.customerInfo = {
            fullName: fullName as string,
            email: email as string,
            address: address as string,
            city: city as string,
            company: '',
            phone: phone as string,
            postalCode: postalCode as string,
        };
        setFromExternalOrigin(true);
    }, [fromExternalOrigin, receiptInfo]);

    if (!receiptInfo) {
        return null;
    }

    const { customerInfo, selectedAgreement, reepaySubscription } = receiptInfo;

    const getReceiptHeader = (): string => {
        return pageContent.header.replace('{{selected_plan}}', selectedAgreement?.agreementName ?? '');
    };

    if (!reepaySubscription && !fromExternalOrigin) return null;

    return (
        <ReceiptContainer>
            <TextWrapper>
                <Header>{getReceiptHeader()}</Header>
                <Text>
                    <div dangerouslySetInnerHTML={{ __html: pageContent.receiptText.replace('/media/', `${MEDIA_URL}/media/`) }}></div>
                </Text>

                <FormSummary>
                    <FormSummaryHeader>{pageContent.formSummaryHeader}</FormSummaryHeader>
                    <FormSummarySection>
                        <p>
                            {pageContent.emailLabel}
                            {customerInfo.email}
                        </p>
                        <p>
                            {pageContent.phoneLabel}
                            {customerInfo.phone}
                        </p>
                    </FormSummarySection>
                    <FormSummarySection>
                        <p>{customerInfo.fullName}</p>
                        <p>{customerInfo.address}</p>
                        <p>
                            {customerInfo.postalCode}, {customerInfo.city}
                        </p>
                    </FormSummarySection>
                </FormSummary>
                <CtaWrapper>{pageContent.ctas && <CtaHandler ctas={pageContent.ctas}></CtaHandler>}</CtaWrapper>
            </TextWrapper>
        </ReceiptContainer>
    );
};

export default ReepayReceipt;
