import styled, { css } from 'styled-components';
import { device } from '../../../../lib/media-query';
import { SidePanelProps } from './side-panel.component';
import { isSiteName, SITE_NAME } from '../../../../utils/helpers/site-id.helper';

type VariantSizeMap = {
    [key in NonNullable<SidePanelProps['variant']>]: string;
};

const defaultVariantSizeMap = {
    sm: '625px',
    md: '40vw',
    lg: '55vw',
    full: '100vw',
    '70%': '70vw',
};

export const StyledSidePanelWrapper = styled.div<{ isVisible: boolean; variant: keyof VariantSizeMap }>`
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.palette.background.default};
    ${isSiteName(SITE_NAME.CARSAVER) &&
    css`
        background-color: #f7f7f8;
    `}
    transition: transform 0.2s ease-in;
    transform: ${(props) => (!props.isVisible ? 'translateX(100%)' : '')};
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-gutter: stable;

    @media ${device.tablet} {
        position: fixed;
        top: 0;
        right: 0;
        width: ${(props) => defaultVariantSizeMap[props.variant]};
        min-width: 600px;
        height: 100%;
        box-shadow: 19px 19px 38px rgba(0, 0, 0, 0.3), 19 15px 12px rgba(0, 0, 0, 0.22);
    }
    z-index: 301;
    isolation: isolate;
`;
