import { VFC } from 'react';

import { NavItemWrapper, NavText, StyledChevronRight } from './navigation-item.styled';
import { umbraco } from '../../../../lib/api';
import { cmsUrlWithoutSiteId, handleUrlClick } from '../../../../utils/helpers';
import { useUI } from '../../../ui';

type NavigationItemProps = {
    menuItem: umbraco.HireMenuItem;
};

export const NavigationItem: VFC<NavigationItemProps> = ({ menuItem }) => {
    const cta = menuItem.action[0];
    const uiHandler = useUI();

    if (cta.alias === 'cTAWithLink') {
        return (
            <NavItemWrapper
                onClick={() => {
                    uiHandler.toggleMobileMenu(false);
                    handleUrlClick({ ...cta.linkUrl, url: cmsUrlWithoutSiteId(cta.linkUrl.url) });
                }}
            >
                <NavText>{cta.text}</NavText>
                <StyledChevronRight width={20} height={20} />
            </NavItemWrapper>
        );
    }
    return null;
};
