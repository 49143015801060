import styled from 'styled-components';
import { Button, KeyValueTable } from '../../shared';

export const StyledFinancialCalculationsModal = styled.div``;

export const HeaderSection = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
`;

export const Header = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
`;

export const Price = styled(Header)`
    font-weight: 400;
`;

export const Description = styled.p`
    color: #0b0b0b;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 30px;
`;

export const CalculatorSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 23px;
    margin-bottom: 40px;
`;

export const SliderContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 9px;
`;

export const LabelAndValue = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Label = styled.label`
    color: #0b0b0b;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    margin-bottom: 9px;
`;

export const SummarySection = styled.div``;

export const SummaryHeader = styled.h3`
    color: #0b0b0b;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
`;

export const CalculationTable = styled(KeyValueTable)`
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 20px;
`;

export const GetOfferButton = styled(Button)`
    margin-bottom: 25px;
`;

export const Disclaimer = styled.p`
    opacity: 0.5;
    color: #0b0b0b;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: normal;
`;

export const ErrorText = styled.p`
    color: #0b0b0b;
    letter-spacing: 0;
    font-weight: normal;
    margin-bottom: 20px;
`;

export const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
`;
