import styled from 'styled-components';
import { device } from '../../../../../lib/media-query';

export const BorderBox = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: start;
    grid-column-gap: 3rem;
    padding: 10px 0;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const StyledLabel = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    white-space: nowrap;

    @media ${device.tablet} {
        font-size: 15px;
    }
`;

export const Description = styled.p`
    color: #807f80;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
`;

export const PriceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
`;

export const DownPayment = styled.p`
    color: #807f80;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
`;
