import { useState } from 'react';
import { GTMHirePdpData } from '../../hooks/lead-checkout/use-hire-gtm-tracking-data';
import { DispatchMethod } from '../gtm-tracking.types';

export type BaseCheckoutPayload = {
    event: HireLeadCheckoutTrackingEvents;
    step?: string;
};

type StepChange = BaseCheckoutPayload;

type UserInfoStepEvent = BaseCheckoutPayload & {
    choice: string;
};

type DurationAndDelivery = BaseCheckoutPayload & {
    leasingperiode: string;
    levering: string;
    /**
     * Sendes kun hvis der er valgt 'Afhent i afdeling'
     */
    afdeling?: string;
};

type EquipmentEvent = BaseCheckoutPayload & {
    /**
     * Array af tilvalg
     */
    tilvalg: Array<string>;
    /**
     * Array af tilvalg
     */
    ekstraudstyr: Array<string>;
};

type StepLoadEvent = BaseCheckoutPayload & GTMHirePdpData;

type StepPurchaseEvent = StepLoadEvent & {
    transaction_id: string;
    value: number;
    tax: number;
    shipping: number;
    affiliation?: string;
};

export type CheckoutStepPayload = StepChange | UserInfoStepEvent | EquipmentEvent | DurationAndDelivery | StepLoadEvent | StepPurchaseEvent;

export const Hire_Lead_Checkout_Event_Types = {
    leadCheckoutEvents: 'Hire Site',
    beginCheckout: 'begin_checkout',
    checkout: 'checkout',
    checkoutOption: 'checkout_option',
    purchase: 'purchase',
    sidebarOpen: 'sidebar_open',
} as const;

export type HireLeadCheckoutTrackingEvents = typeof Hire_Lead_Checkout_Event_Types[keyof typeof Hire_Lead_Checkout_Event_Types];

const SidebarTypes = {
    PaymentPlan: 'Betalingsplan',
    FindDealership: 'Find afdeling',
    BookDriveTest: 'Book Prøvetur',
    ContactHessel: 'Kontakt Hessel',
    HireBenefits: 'Hvorfor HesselHiRE',
    ShowMiniBasket: 'Fortsæt din bestilling (mini-basket)',
} as const;

export type SidebarNames = typeof SidebarTypes[keyof typeof SidebarTypes];

export type HireLeadCheckoutEvents = {
    stepLoaded: (payload: CheckoutStepPayload) => void;
    stepChanged: (payload: CheckoutStepPayload) => void;
    checkoutFinished: (item: GTMHirePdpData) => void;
    sidebarOpened: (sidebarName: SidebarNames) => void;
};

const useHireLeadCheckoutEvents = (dispatch: DispatchMethod): HireLeadCheckoutEvents => {
    const eventType = Hire_Lead_Checkout_Event_Types.leadCheckoutEvents;

    return useState({
        stepLoaded: (payload: CheckoutStepPayload) =>
            dispatch({
                type: eventType,
                ...payload,
            }),
        stepChanged: (payload: CheckoutStepPayload) =>
            dispatch({
                type: eventType,
                ...payload,
            }),
        checkoutFinished: (item: GTMHirePdpData) =>
            dispatch({
                type: eventType,
                event: Hire_Lead_Checkout_Event_Types.purchase,
                ...item,
            }),
        sidebarOpened: (sidebarName: SidebarNames) =>
            dispatch({
                type: eventType,
                event: Hire_Lead_Checkout_Event_Types.sidebarOpen,
                sidebarName,
            }),
    })[0];
};

export const trackHireLeadCheckout = (dispatch: DispatchMethod): (() => HireLeadCheckoutEvents) => {
    return () => useHireLeadCheckoutEvents(dispatch);
};
