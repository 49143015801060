import { useCallback, VFC } from 'react';
import { CustomerSavingsStore, SavingsForm } from '../../../lib/state/plus-sites/savings';
import { RadioButton } from '../../shared';
import { InputSlider } from '../../shared/input-slider';
import { SavingsItem } from '../savings-item';
import { BorderBox, ChildWrapper, StyledRadioButtonText } from './savings-inputs.styled';

type Props = {
    inputs: SavingsForm[];
};

export const SavingsInputs: VFC<Props> = ({ inputs }) => {
    const { onChange } = CustomerSavingsStore.useStoreActions((actions) => actions);

    const renderSavings = useCallback(
        (saving: SavingsForm, index: number, total: number) => {
            switch (saving.alias) {
                case 'rangedChoiceSaving':
                case 'transportAndGasSaving':
                case 'carwashSaving': {
                    return (
                        <SavingsItem
                            key={`${saving.alias}-${saving.id}-${index}`}
                            header={`${index + 1}. ${saving.header}`}
                            description={saving.description ?? ''}
                            hideBottomBorder={index !== total - 1}
                        >
                            <ChildWrapper extraMargin={true}>
                                <InputSlider
                                    min={saving.min ?? 0}
                                    max={saving.max ?? 0}
                                    defaultValue={saving.default ?? 0}
                                    interval={saving.interval ?? 0}
                                    value={saving.value}
                                    unit={saving.unit}
                                    onValueChange={(value) => onChange({ id: saving.id, value })}
                                />
                            </ChildWrapper>
                        </SavingsItem>
                    );
                }
                case 'multipleChoicesSaving': {
                    return (
                        <SavingsItem
                            key={`${saving.alias}-${saving.id}-${index}`}
                            header={`${index + 1}. ${saving.header}`}
                            description={saving.description ?? ''}
                            hideBottomBorder={index !== total - 1}
                        >
                            <ChildWrapper>
                                {saving.choices?.map((choice, choiceIdx) => {
                                    return (
                                        <RadioButton
                                            id={`${saving.id}-${choice.alias}-${choice.value}-${choiceIdx}`}
                                            key={`${choice.alias}-${choice.value}-${choiceIdx}`}
                                            value={`${choice.value}`}
                                            checked={saving.value === choice.value}
                                            action={() => {
                                                onChange({ id: saving.id, value: choice.value ?? -1 });
                                            }}
                                        >
                                            <StyledRadioButtonText>{choice.label}</StyledRadioButtonText>
                                        </RadioButton>
                                    );
                                })}
                            </ChildWrapper>
                        </SavingsItem>
                    );
                }

                default:
                    return null;
            }
        },
        [onChange]
    );

    return <BorderBox>{inputs.map((x, idx) => renderSavings(x, idx, inputs.length))}</BorderBox>;
};
