import { VFC } from 'react';
import { contentSpotTypes } from '../../../lib/api';
import { NumberedListContentItem } from '../../../lib/api/models/umbraco/content-spot';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';
import {
    StyledItemHeader,
    StyledItemNumber,
    StyledItemText,
    StyledNumberedList,
    StyledNumberedListItem,
    StyledSpotHeader,
    StyledText,
} from './numbered-list-content-spot.styled';
import { StyledSpotSpacingContainer } from '../helpers';

export type NumberedListContentSpotProps = {
    content: contentSpotTypes.NumberedListContentSpot;
};

export const NumberedListContentSpot: VFC<NumberedListContentSpotProps> = ({ content }) => {
    return (
        <CenteredBlock>
            <StyledSpotSpacingContainer>
                {content.headerText && (
                    <StyledSpotHeader headerType={content.headerType} headerSize={content.headerSize}>
                        {content.headerText}
                    </StyledSpotHeader>
                )}
                {content.introText && <StyledText>{content.introText}</StyledText>}

                {content && content.spotList && (
                    <StyledNumberedList>
                        {content.spotList.map((item: NumberedListContentItem, index) => {
                            return (
                                <StyledNumberedListItem key={index}>
                                    <StyledItemNumber>{index + 1}</StyledItemNumber>
                                    {item.contentHeader && <StyledItemHeader>{item.contentHeader}</StyledItemHeader>}
                                    {item.contentText && <StyledItemText>{item.contentText}</StyledItemText>}
                                </StyledNumberedListItem>
                            );
                        })}
                    </StyledNumberedList>
                )}
            </StyledSpotSpacingContainer>
        </CenteredBlock>
    );
};
