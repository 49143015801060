import { FC, useMemo } from 'react';
import { Slider } from '../../shared';
import { IFullScreenCampaign } from './full-screen-campaign.props';
import { FullScreenCampaignWrapper } from './full-screen-campaign.styled';
import { FullScreenCampaignItem } from './campaign-item';
import { getMediaKey } from '../helpers';

const MEDIA_URL = process.env.NEXT_PUBLIC_MEDIA_URL;

export const FullScreenCampaign: FC<IFullScreenCampaign> = ({ heroSettings }) => {
    const imagesOnly: boolean = useMemo(() => {
        if (heroSettings && heroSettings.alias === 'fullScreenCampaign' && heroSettings.slideList) {
            return !heroSettings.slideList.find((x) => x.elementList.length > 0);
        }
        return false;
    }, [heroSettings]);
    return (
        <FullScreenCampaignWrapper id={heroSettings.scrollAnchorId} isOnlyImages={imagesOnly}>
            {heroSettings.alias === 'fullScreenCampaign' && (
                <Slider
                    key={`slider:${heroSettings.alias}`}
                    showPagination={heroSettings.slideList.length > 1}
                    slideDelayInSeconds={heroSettings.slideDelayInSeconds}
                >
                    {heroSettings?.slideList &&
                        heroSettings.slideList.map((slide, index) => {
                            return (
                                <FullScreenCampaignItem
                                    key={getMediaKey(slide.image, slide.video)}
                                    imageUrl={slide.image ? `${MEDIA_URL}/${slide.image?.src}` : undefined}
                                    imageMobileUrl={slide.imageMobile ? `${MEDIA_URL}/${slide.imageMobile?.src}` : undefined}
                                    videoUrl={slide.video ? `${MEDIA_URL}/${slide.video?.src}` : undefined}
                                    videoMobileUrl={slide.videoMobile ? `${MEDIA_URL}/${slide.videoMobile?.src}` : undefined}
                                    backgroundColor={slide.backgroundColor}
                                    elements={slide.elementList}
                                    slideCta={slide.ctaForEntireSlide}
                                    slideFootnote={slide.slideFootnote}
                                    imagesOnly={imagesOnly}
                                    isAboveFold={heroSettings.isAboveFold}
                                    carouselIndex={index}
                                    overlay={slide.overlay && slide.overlay.length > 0 ? slide.overlay[0] : undefined}
                                />
                            );
                        })}
                </Slider>
            )}
        </FullScreenCampaignWrapper>
    );
};
