import styled from 'styled-components';

export const PinWithLetterWrapper = styled.figure`
    position: relative;
`;

export const StyledDealershipPin = styled.img`
    height: 35px;
`;

export const StyledLetter = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 6px;
    left: 5.5px;
    width: 12px;
    height: auto;
    font-weight: 500;
    color: #fff;
    font-size: 10px;
`;
