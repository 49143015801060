import { FC, memo } from 'react';
import { useScrollLock } from '../../../../hooks/use-scroll-lock';
import { umbraco } from '../../../../lib/api';
import { EquipmentDialogManagerStore } from '../../../../lib/state/hessel-site/equipment-dialog-manager';
import { ProductDetailsStore } from '../../../../lib/state/hessel-site/product-details';
import { EquipmentSection } from '../../equipment-and-addons/equipment-card/equipment-card.component';
import { StandardEquipment } from '../../standard-equipment';
import ProductDetailsPageSpecs from '../product-details-page-specs/product-details-page-specs.component';
import ProductDetailsCardComponent from '../shared/product-details-card.component';

type ProductDetailsPageOrderCarPanelComponentProps = {
    sizeGuide?: umbraco.SizeGuideForModel;
};
const ProductDetailsPageOrderCarPanelComponent: FC<ProductDetailsPageOrderCarPanelComponentProps> = memo(({ sizeGuide }) => {
    const { setShowAllEquipment } = EquipmentDialogManagerStore.useStoreActions((actions) => actions);
    const { selectedProduct, selectedTab } = ProductDetailsStore.useStoreState((state) => state);
    const { hideScrollBars } = useScrollLock();

    return selectedProduct ? (
        <>
            <EquipmentSection equipmentIsIncluded={false} />
            <ProductDetailsCardComponent padding="large">
                <ProductDetailsPageSpecs sizeGuide={sizeGuide} />
            </ProductDetailsCardComponent>

            <StandardEquipment
                availability={selectedProduct.availability}
                standardEquipmentList={selectedProduct.standardEquipment[selectedTab]}
                standardEquipmentPackages={selectedProduct.standardEquipmentPackages[selectedTab]}
                showAllEquipment={() => {
                    setShowAllEquipment(true);
                    hideScrollBars();
                }}
                equipmentNames={selectedProduct.equipmentNames}
            />
        </>
    ) : null;
});

export default ProductDetailsPageOrderCarPanelComponent;
