import { FC } from 'react';
import { StyledShape } from './generic-shape.styled';

type GenericShapeProps = {
    className?: string;
    onClick?: () => void;
    textColor?: string;
    backgroundColor?: string;
};

export const GenericShape: FC<GenericShapeProps> = ({ className, children, onClick, textColor, backgroundColor }) => {
    return (
        <StyledShape
            className={className}
            onClick={() => onClick?.()}
            textColor={textColor ? `#${textColor}` : '#fff'}
            backgroundColor={backgroundColor ? `#${backgroundColor}` : '#000'}
        >
            {children}
        </StyledShape>
    );
};
