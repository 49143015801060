import React, { VFC, useRef } from 'react';
import { config, useSpring } from 'react-spring';
import { Button } from '../../../shared';
import { StickySummarySubComponentProps } from '../sticky-summary.component';
import { CarInfoWrapper, BrandTitle, VariantTitle, StyledStickySummary, TextWrapper, Title, TopSection, Value } from './mobile.styled';
import { formatPrice } from '../../../../utils/helpers';
import { Abbreviations } from '../../../../constants/units-and-abbreviations';

export const StickySummaryMobileMemo: VFC<StickySummarySubComponentProps> = ({ visible, variantTitle, brandTitle, primaryCta, priceInfo }) => {
    const slideIn = useSpring({
        config: { ...config.slow, friction: 45 },
        top: visible ? '0' : '-300px',
        delay: 500,
    });

    const { price, monthly, overrideText } = priceInfo;

    const textWrapper = useRef<HTMLDivElement>(null);
    const topSection = useRef<HTMLDivElement>(null);

    const variantTitleWidth = () => {
        if (!textWrapper.current || !topSection.current) return '100%';
        return `${topSection.current.clientWidth - textWrapper.current.clientWidth - 5}px`;
    };

    return (
        <StyledStickySummary style={slideIn}>
            <TopSection ref={topSection}>
                <CarInfoWrapper>
                    <BrandTitle>{brandTitle}</BrandTitle>
                    <VariantTitle parentWidth={variantTitleWidth()}>{variantTitle}</VariantTitle>
                </CarInfoWrapper>

                <TextWrapper ref={textWrapper}>
                    {overrideText ? (
                        <Value>{overrideText}</Value>
                    ) : (
                        <>
                            <Title>
                                {'Fra '}
                                {formatPrice(price)} {monthly ? Abbreviations.KR_SLASH_MD : Abbreviations.KR}
                            </Title>
                            <Button variant="link" onClick={() => primaryCta.action?.()}>
                                <span style={{ fontWeight: '400' }}>Se alle priser</span>
                            </Button>
                        </>
                    )}
                </TextWrapper>
            </TopSection>
        </StyledStickySummary>
    );
};

export const StickySummaryMobile = React.memo(StickySummaryMobileMemo);
