import styled from 'styled-components';
import { Button } from '../..';

export const BorderBox = styled.section`
    background-color: ${({ theme }) => theme.palette.common.white};
    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

export const Header = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: 8px;
`;

export const Description = styled.p`
    overflow-wrap: break-word;
    margin-bottom: 20px;
`;

export const StyledButton = styled(Button)`
    align-self: center;
`;
