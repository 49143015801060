import { useRouter } from 'next/router';
import { useMemo } from 'react';

type UseSearchOverlayReturn = {
    closeSearch: () => void;
    openSearch: () => void;
    isSearchOpen: boolean;
};

export const useSearchOverlay = (): UseSearchOverlayReturn => {
    const router = useRouter();

    const closeSearch = () => {
        if (window.location.hash === '#search') {
            const path = router.asPath.split('#')[0];
            router.push(path, undefined, { shallow: true });
        }
    };

    const openSearch = () => {
        router.push(`${router.asPath}#search`, undefined, { shallow: true });
    };

    const isSearchOpen = useMemo(() => {
        return router.asPath.includes('#search');
    }, [router.asPath]);

    return {
        closeSearch,
        openSearch,
        isSearchOpen,
    };
};
