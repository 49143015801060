import { FC } from 'react';
import { CardPadding, StyledCard, StyledProductDetailDescription, StyledProductDetailHeader } from './product-detail-page-shared.styled';

type ProductDetailsCardComponentProps = {
    header?: string;
    description?: string;
    padding?: CardPadding;
    children: JSX.Element;
};

const ProductDetailsCardComponent: FC<ProductDetailsCardComponentProps> = ({ header, description, padding, children }) => {
    return (
        <>
            <StyledCard paddingStyle={padding}>
                {header && <StyledProductDetailHeader>{header}</StyledProductDetailHeader>}
                {description && <StyledProductDetailDescription>{description}</StyledProductDetailDescription>}
                <div style={{ marginTop: header ? 20 : 0 }}>{children}</div>
            </StyledCard>
        </>
    );
};

export default ProductDetailsCardComponent;
