import React, { VFC } from 'react';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { Button, Responsive } from '../../shared';
import { GeoFilterInput } from '../geo-filter-input/geo-filter-input.component';
import { FindDealershipsDesktop } from './desktop/find-dealerships-desktop';
import { FindDealershipsMobile } from './mobile/find-dealerships-mobile';
import {
    ContentSection,
    GeoInputFilterWrapper,
    StyledHeader,
    StyledText,
    DropdownsWrapper,
    BrandDropdown,
    DestinationDropdown,
    StyledInfoBoxToggle,
    StyledCenteredBlock,
    StyledSpotWrapper,
    StyledButtonText,
    StyledFilterError,
    StyledResponsiveFrame,
    StyledSpotButtonWrapper,
    StyledSpotHeader,
} from './find-dealership.styled';
import { FindDealershipsStore } from '../../../lib/state/find-dealership';
import { orgTypes, sharedTypes, SortingLabel } from '../../../lib/api/models/umbraco';
import { EmergencyPhonesModal } from '../emergency-phones-modal/emergency-phones-modal.component';
import { useUI } from '../../ui';
import { uiTypes } from '../../../lib';
import { CtaHandler } from '../../plus-sites-shared/content-handlers';
import { isNullOrEmpty } from '../../../utils/helpers';

function sortOptions(options: Array<SortingLabel>) {
    return options.sort((a, b) => {
        if (a.labelText < b.labelText) {
            return -1;
        }
        if (a.labelText < b.labelText) {
            return 1;
        }
        return 0;
    });
}

type FindDealershipProps = {
    showFilteringOptions?: boolean;
    headerType?: uiTypes.HeaderType;
    headerSize?: uiTypes.HeaderSize;
    spotCta?: sharedTypes.MixedCta;
};

export const FindDealership: VFC<FindDealershipProps> = ({ showFilteringOptions, headerType, headerSize, spotCta }) => {
    const isMobile = useMediaQuery({ target: 'tablet' });

    const {
        showMobileInfoBox,
        freeTextFilter,
        config,
        selectedBrand,
        selectedDepartment,
        emergencyPhoneNumbers,
        dealershipAndTruckNumbers,
        dealershipId,
        locations,
        showEmergencyNumbersSidePanel,
        userCurrentLocation,
        filterStatus,
        renderGoogleMaps,
    } = FindDealershipsStore.useStoreState((state) => state);

    const {
        toggleMobileInfoBoxVisibility,
        setFreeTextFilter,
        applyFilters,
        setSelectedBrand,
        setSelectedDepartment,
        setShowEmergencyNumbersSidePanel,
        setUserCurrentLocation,
        setShowMobileInfoBox,
    } = FindDealershipsStore.useStoreActions((actions) => actions);

    const brandsOptions = React.useMemo(
        () => config.brands.map((x) => ({ sortingKey: x.brand, labelText: x.brand, disabled: x.disabled })),
        [config.brands]
    );

    const departmentOptions = React.useMemo(
        () =>
            config.departments
                .filter((x) => x.departmentType !== 'None')
                .map((x) => ({ sortingKey: x.departmentType, labelText: x.departmentType, disabled: x.disabled })),
        [config.departments]
    );

    const uiHandler = useUI();

    const Header_Text = 'Find din lokale afdeling';

    return (
        <>
            <StyledCenteredBlock>
                <StyledSpotWrapper>
                    <ContentSection>
                        {isNullOrEmpty(headerSize) ? (
                            <StyledHeader as={headerType}>{Header_Text}</StyledHeader>
                        ) : (
                            <StyledSpotHeader headerType={headerType} headerSize={headerSize}>
                                {Header_Text}
                            </StyledSpotHeader>
                        )}

                        <StyledText>Vi har {locations.length} afdelinger fordelt i Danmark - find din lokale afdeling.</StyledText>

                        {showFilteringOptions ? (
                            <>
                                <GeoInputFilterWrapper
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        applyFilters();
                                        if (isMobile) {
                                            setShowMobileInfoBox(true);
                                        }
                                    }}
                                >
                                    <GeoFilterInput
                                        id="dealership-geo-filter-input"
                                        label="Søg"
                                        onChange={(e) => {
                                            setFreeTextFilter(e.target.value);
                                        }}
                                        type="text"
                                        placeholder="Post nr. eller by"
                                        value={freeTextFilter}
                                        disabled={false}
                                        isValid={false}
                                        canValidateInputField={false}
                                        requestForLocation={() => {
                                            if (navigator.geolocation && !userCurrentLocation) {
                                                navigator.geolocation.getCurrentPosition(
                                                    (position) => {
                                                        setUserCurrentLocation({
                                                            coords: { lat: position.coords.latitude, long: position.coords.longitude },
                                                        });
                                                    },
                                                    (_error) => {
                                                        setUserCurrentLocation({ coords: undefined, mobileAccess: isMobile });
                                                    },
                                                    {
                                                        enableHighAccuracy: true,
                                                        timeout: 60000,
                                                    }
                                                );
                                            }

                                            if (userCurrentLocation) {
                                                setUserCurrentLocation({ coords: { lat: userCurrentLocation.lat, long: userCurrentLocation.long } });
                                            }
                                        }}
                                    />
                                    <Button type="submit" variant="primary" onClick={() => null} unsetMinWidth={true}>
                                        <StyledButtonText>Søg</StyledButtonText>
                                    </Button>
                                </GeoInputFilterWrapper>
                                {filterStatus === 'no match for zipcode' ? (
                                    <StyledFilterError>
                                        Vi fandt ingen resultater, der matcher postnummeret. Prøv at tjekke om din indtastning er korrekt, og søg
                                        igen.
                                    </StyledFilterError>
                                ) : null}
                                {filterStatus === 'no match for city name' ? (
                                    <StyledFilterError>
                                        Vi fandt ingen resultater, der matcher din søgning. Prøv at tjekke om din indtastning er korrekt, og søg igen.
                                    </StyledFilterError>
                                ) : null}
                            </>
                        ) : null}

                        {!showFilteringOptions && spotCta ? (
                            <StyledSpotButtonWrapper>
                                <CtaHandler ctas={[spotCta]} />
                            </StyledSpotButtonWrapper>
                        ) : null}
                    </ContentSection>

                    {showFilteringOptions ? (
                        <DropdownsWrapper>
                            <BrandDropdown
                                options={[{ sortingKey: 'Alle', labelText: 'Alle' }, ...sortOptions(brandsOptions)]}
                                onChangeSorting={(selection: string) => setSelectedBrand(selection as orgTypes.BrandType)}
                                selectedValue={(selectedBrand as string) ?? 'Alle'}
                                label={'Mærke'}
                            />

                            <DestinationDropdown
                                options={[{ sortingKey: 'Alle', labelText: 'Alle' }, ...sortOptions(departmentOptions)]}
                                onChangeSorting={(selection: string) => setSelectedDepartment(selection as orgTypes.DepartmentType)}
                                selectedValue={(selectedDepartment as string) ?? 'Alle'}
                                label={'Formål'}
                            />
                        </DropdownsWrapper>
                    ) : null}

                    {renderGoogleMaps ? (
                        <StyledInfoBoxToggle onClick={() => toggleMobileInfoBoxVisibility()}>
                            {showMobileInfoBox ? 'Se på kort' : 'Se på liste'}
                        </StyledInfoBoxToggle>
                    ) : null}

                    {isMobile ? (
                        <Responsive max="frame">
                            <FindDealershipsMobile />
                        </Responsive>
                    ) : (
                        <StyledResponsiveFrame min="frame">
                            <FindDealershipsDesktop />
                        </StyledResponsiveFrame>
                    )}
                </StyledSpotWrapper>
            </StyledCenteredBlock>
            <EmergencyPhonesModal
                visible={!!dealershipId && showEmergencyNumbersSidePanel}
                emergencyPhoneNumbers={emergencyPhoneNumbers ?? []}
                supportedBrands={[...(locations.find((x) => x.dealershipId === dealershipId)?.supportedBrands ?? [])]}
                dealershipAndTruckNumbers={dealershipAndTruckNumbers ?? []}
                closeModal={() => {
                    uiHandler.applyScroll();
                    setShowEmergencyNumbersSidePanel({ visible: false, dealershipId });
                }}
                currentDealershipId={dealershipId ?? ''}
            />
        </>
    );
};
