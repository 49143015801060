import styled from 'styled-components';

export const StyledTestimonialRibbon = styled.div`
    background-color: #f7f7f8;
    padding: 60px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

export const SidePanelTestimonialContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 20px;
`;

export const SidePanelText = styled.p`
    margin: 10px 0;
    white-space: pre-line;
`;

export const SidePanelQuote = styled.p`
    margin: 10px 0;
    white-space: pre-line;
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
    ::before,
    ::after {
        content: '"';
    }
`;

export const SidePanelHeader = styled.h1`
    margin-bottom: 10px;
`;

export const CtaHandlerWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 20px;
`;
