import { useMemo, VFC } from 'react';
import sanitizeHtml from 'sanitize-html';
import Collapsible from '../collapsible/collapsible';
import { StyledDescription, StyledHeader } from './short-info-card.styled';

export type ShortInfoCardProps = {
    header: string;
    description: string;
};

export const ShortInfoCard: VFC<ShortInfoCardProps> = ({ header, description }) => {
    const sanitizedDescription = useMemo(() => (description ? sanitizeHtml(description, { allowedTags: [] }) : undefined), [description]);

    return (
        <>
            <Collapsible>
                <StyledHeader>{header}</StyledHeader>
                <StyledDescription dangerouslySetInnerHTML={{ __html: sanitizedDescription ?? '' }}></StyledDescription>
            </Collapsible>
        </>
    );
};
