import styled from 'styled-components';
import { device } from '../../../../../lib/media-query';

export const InfoWrapper = styled.div<{ numberOfColumns: number }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media ${device.tablet} {
        display: grid;
        grid-template-columns: repeat(${(props) => props.numberOfColumns}, 1fr);
        width: 100%;
    }
`;
