import { FC } from 'react';
import { IContactInfo } from './contact-info.props';
import { Container, Label, StyledLink } from './contact-info.styled';
import Link from 'next/link';
import { StyledHeader, StyledWrapper } from '../_shared';

export const ContactInfo: FC<IContactInfo> = ({ title, telephone, email }) => {
    return (
        <StyledWrapper>
            <StyledHeader>{title}</StyledHeader>
            <Container>
                <Label>Tlf:</Label>
                <Link href={`tel:${telephone.split(' ').join('')}`} passHref={true}>
                    <StyledLink>{telephone}</StyledLink>
                </Link>
            </Container>
            <Container>
                <Label>E-mail:</Label>
                <Link href={`mailto:${email}`} passHref={true}>
                    <StyledLink>{email}</StyledLink>
                </Link>
            </Container>
        </StyledWrapper>
    );
};
