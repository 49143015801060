import Link from 'next/link';
import { FC, useState } from 'react';
import { TopLevelLink } from '../../../../lib/api/models/umbraco/mega-menu.types';
import { SidePanel } from '../../../shared/modals/side-panel';
import { SvgIcon } from '../../../shared/svg-icon';
import { StyledBurgerLink, StyledCloseIcon, StyledCloseText } from '../../burger-menu-desktop/burger-menu-desktop.styled';
import { InspirationalLink } from '../../inspirational-link/inspirational-link.compontent';
import { PrimaryLink } from '../../primary-link/primary-link.component';
import {
    StyledBackButton,
    StyledInspirationalLinkWrapper,
    StyledPageLink,
    StyledPageName,
    StyledPrimaryLinkWrapper,
    StyledSubMenuHeaderActions,
    StyledSubMenuNameAndLinkWrapper,
    StyledSubMenuWrapper,
} from './burger-menu-mobile-link.styled';

type IProps = {
    link: TopLevelLink;
    closeAction: () => void;
};

export const BurgerMenuMobileLink: FC<IProps> = ({ link, closeAction }) => {
    const [linkOpen, setLinkOpen] = useState(false);
    const close = () => {
        closeAction();
        setLinkOpen(false);
    };
    return (
        <>
            <Link href={link.linkDestination.url} passHref>
                <StyledBurgerLink
                    onClick={(e) => {
                        if (link.megaMenu && link.megaMenu.length > 0) {
                            e.preventDefault();
                            setLinkOpen(true);
                        } else {
                            close();
                        }
                    }}
                >
                    {link.linkText}
                </StyledBurgerLink>
            </Link>
            {link.megaMenu && link.megaMenu.length > 0 ? (
                <SidePanel isVisible={linkOpen} cancelAction={() => setLinkOpen(false)}>
                    <StyledSubMenuWrapper>
                        <StyledSubMenuHeaderActions>
                            <StyledBackButton onClick={() => setLinkOpen(false)}>
                                <SvgIcon iconName="chevron/left" /> Tilbage
                            </StyledBackButton>
                            <StyledCloseIcon onClick={() => close()}>
                                <StyledCloseText>Luk</StyledCloseText>
                                <SvgIcon iconName="close" />
                            </StyledCloseIcon>
                        </StyledSubMenuHeaderActions>
                        <StyledSubMenuNameAndLinkWrapper>
                            <StyledPageName>{link.linkText}</StyledPageName>
                            <Link href={link.linkDestination.url} passHref>
                                <StyledPageLink onClick={() => close()}>Gå til siden</StyledPageLink>
                            </Link>
                        </StyledSubMenuNameAndLinkWrapper>
                        <StyledInspirationalLinkWrapper>
                            {link.megaMenu[0].inspirationalLinks.map((x, idx) => {
                                return <InspirationalLink key={idx} link={x} onLinkClick={() => close()} />;
                            })}
                        </StyledInspirationalLinkWrapper>
                        <StyledPrimaryLinkWrapper>
                            {link.megaMenu[0].primaryLinks.map((x, idx) => {
                                return <PrimaryLink link={x} onLinkClick={() => close()} key={idx} />;
                            })}
                        </StyledPrimaryLinkWrapper>
                    </StyledSubMenuWrapper>
                </SidePanel>
            ) : null}
        </>
    );
};
