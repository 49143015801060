import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const StyledFormWrapper = styled.div`
    max-width: 340px;
    padding: 25px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const StyledCityZipWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media ${device.tablet} {
        display: grid;
        grid-template-columns: 134fr 191fr;
        gap: 20px;
    }
`;
