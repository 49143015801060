import { FC } from 'react';
import { ContentSpots } from '../spots';
import { ContentSpotSettings } from '../../lib/api/models/umbraco/content-spot';
import { GlobalProductListSettings } from '../../lib/api/models/umbraco';
import { postHireEmailCustomerAction } from '../../lib/api/hire/hessel-hire-email-customer-action-api';
import { useRouter } from 'next/router';
import { useEffectOnce } from 'react-use';

type Props = {
    context: {
        pageId: string;
    };
    contentSpots: ContentSpotSettings[];
    globalPlpSettings: GlobalProductListSettings;
};

const HireEmailCustomerActionPage: FC<Props> = ({ context, contentSpots, globalPlpSettings }) => {
    const router = useRouter();

    useEffectOnce(() => {
        const request = {
            action: router.query.action as string,
            orderGuid: router.query.orderGuid as string,
        };
        if (request.action === undefined || request.orderGuid === undefined) return;
        postHireEmailCustomerAction(request);
    });

    return <ContentSpots context={context} contentSpotSettings={contentSpots} globalPlpSettings={globalPlpSettings}></ContentSpots>;
};

export default HireEmailCustomerActionPage;
