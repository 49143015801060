import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const ZipCodeAndCity = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media ${device.mobileL} {
        display: grid;
        grid-template-columns: 40% calc(60% - 1rem);
        column-gap: 1rem;
        width: 100%;
    }
`;
