import { FC } from 'react';
import { filterTile } from '../../../../hooks/productlist/use-vehicle-productlist';
import { StyledFilterTile, StyledFilterTilesWrapper, StyledSvgWrapper } from '../vehicle-product-list.styled';
import { SvgIcon } from '../../../shared/svg-icon';
import { FilterUpdateNotification } from '../../../../lib/api/models/hessel-api/hire';

type IProps = {
    filterTiles: filterTile[];
    resetFilters: () => void;
    filterUpdate: (filterUpdate: FilterUpdateNotification[]) => void;
};

export const ProductListFilterTiles: FC<IProps> = ({ filterTiles, resetFilters, filterUpdate }) => {
    return filterTiles.length > 0 ? (
        <StyledFilterTilesWrapper>
            {filterTiles.length > 1 ? (
                <StyledFilterTile valid={true} onClick={() => resetFilters()}>
                    <span>Ryd alle filtre</span>
                    <StyledSvgWrapper>
                        <SvgIcon iconName="close" />
                    </StyledSvgWrapper>
                </StyledFilterTile>
            ) : null}
            {filterTiles.map((x, idx) => {
                if (x) {
                    return (
                        <StyledFilterTile valid={x.valid} key={idx} onClick={() => filterUpdate(x.filterUpdateNotifications)}>
                            <span>{`${x.label}: ${x.values.join(', ')}`}</span>
                            <StyledSvgWrapper>
                                <SvgIcon iconName="close" color={x.valid ? 'default' : 'danger'} />
                            </StyledSvgWrapper>
                        </StyledFilterTile>
                    );
                }
                return null;
            })}
        </StyledFilterTilesWrapper>
    ) : null;
};
