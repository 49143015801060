import styled from 'styled-components';

export const StyledInstallationCheckboxWrapper = styled.div`
    padding: 10px 15px;
    background-color: #f7f7f8;
`;

export const StyledInstallationCheckboxLabel = styled.span`
    font-weight: 600;
`;

export const StyledInstallationDisclaimer = styled.p`
    font-weight: 500;
    font-size: 12px;
    color: #aeaeaf;
`;

export const StyledCheckboxAndPriceWrapper = styled.div`
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
`;

export const StyledInstallationPrice = styled.p`
    font-weight: 600;
`;
