import { FC } from 'react';
import { SvgIcon } from '../svg-icon';
import { StyledUsp, StyledText } from './usp.styled';

export type Props = {
    text?: string;
};

const Usp: FC<Props> = ({ text }) => {
    return (
        <StyledUsp>
            <SvgIcon iconName="plus" color="defaultCircular" />
            <StyledText>{text}</StyledText>
        </StyledUsp>
    );
};

export default Usp;
