import React, { useState, VFC } from 'react';
import {
    CardWrapper,
    ImageWrapper,
    StyledDescription,
    StyledHeading,
    StyledImage,
    StyledSubmit,
} from './vehicle-product-list-send-request-card.styled';
import { sharedTypes } from '../../../../lib/api/models/umbraco';
import { MEDIA_URL } from '../../../../utils/environment-constants';
import { FormsSpot } from '../../../../lib/api/models/umbraco/content-spot';
import { ContactHesselFormDialog } from '../../../product-details/contact-hessel-form/contact-hessel-form.component';
import { useUI } from '../../../ui';

type VehicleProductListSendRequestCardProps = {
    icon: sharedTypes.Image;
    heading: string;
    text: string;
    ctaText: string;
    contactForm: FormsSpot;
    pageId: string;
    isAboveFold?: boolean;
};

const VehicleProductListSendRequestCardMemo: VFC<VehicleProductListSendRequestCardProps> = ({
    icon,
    heading,
    text,
    ctaText,
    contactForm,
    pageId,
    isAboveFold = false,
}) => {
    const [showContactForm, setShowContactForm] = useState(false);
    const uiHandler = useUI();

    return (
        <>
            <CardWrapper>
                <ImageWrapper>
                    <StyledImage src={MEDIA_URL + icon.src} alt={icon.name} loading={isAboveFold ? 'eager' : 'lazy'} />
                </ImageWrapper>

                <StyledHeading>{heading}</StyledHeading>

                <StyledDescription>{text}</StyledDescription>

                {contactForm && contactForm.form ? (
                    <StyledSubmit variant={'primary'} onClick={() => setShowContactForm(true)}>
                        <p>{ctaText}</p>
                    </StyledSubmit>
                ) : null}
            </CardWrapper>

            {contactForm && contactForm.form ? (
                <ContactHesselFormDialog
                    form={contactForm.form}
                    formHeader={contactForm.formHeader}
                    formSubText={contactForm.formSubtext}
                    trackingStrategy={contactForm.trackingStrategy?.[0]}
                    isVisible={showContactForm}
                    onClose={() => {
                        setShowContactForm(false);
                        uiHandler.applyScroll();
                    }}
                    pageId={pageId}
                />
            ) : null}
        </>
    );
};

export const VehicleProductListSendRequestCard = React.memo(VehicleProductListSendRequestCardMemo);
