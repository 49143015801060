import styled from 'styled-components';
import { device } from '../../../../lib/media-query';
import { Button } from '../../../shared';

export const CardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 450px;
    background-color: ${({ theme }) => theme.palette.common.white};
    padding: 70px 50px;
    @media ${device.tablet} {
        height: 100%;
    }
`;

export const ImageWrapper = styled.div`
    width: 82px;
    height: 82px;
    overflow: hidden;
`;

export const StyledImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const StyledHeading = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 12px;
`;

export const StyledDescription = styled.p`
    margin-bottom: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const StyledSubmit = styled(Button)``;
