import React, { useEffect, useState, VFC } from 'react';
import { contentSpotTypes, getPageByDataTypeAliasGeneric } from '../../../lib/api';
import { orgTypes } from '../../../lib/api/models/umbraco';
import { HesselDealership, OrgDealershipResponse, UmbracoStarmarkDealership } from '../../../lib/api/organization/hessel-dealership-api';
import { FindDealershipsStore } from '../../../lib/state/find-dealership';
import { getLetter } from '../../../utils/helpers/array.helpers';
import { sortGeoLocations } from '../../../utils/helpers/geo-location.helpers';
import { FindDealership } from '../../organization/find-dealerships/find-dealership.component';

type DealershipsUmbracoData = {
    allDealerships: Array<orgTypes.DealershipWithGeoInfo>;
    config: orgTypes.DealershipConfig;
    emergencyPhoneNumbers: Array<orgTypes.DepartmentEmergencyNumbers>;
    dealershipAndTruckNumbers: Array<orgTypes.DealerShipAndTruck>;
    contentSpotSettings: contentSpotTypes.ContentSpotSettings[];
    pageId: string;
};

export type FindDealershipViaMapProps = {
    spot: contentSpotTypes.FindDealershipViaMapSpot;
};

type StoreData = {
    locations: Array<orgTypes.DealershipWithGeoInfo>;
    config: orgTypes.DealershipConfig;
    emergencyPhoneNumbers: Array<orgTypes.DepartmentEmergencyNumbers>;
    dealershipAndTruckNumbers: Array<orgTypes.DealerShipAndTruck>;
    dealershipId: string;
    renderGoogleMaps: boolean;
    staticImageUrl?: string;
};

const FindDealershipViaMapMemo: VFC<FindDealershipViaMapProps> = ({ spot }) => {
    const [storeInitialData, setStoreInitialData] = useState<StoreData | undefined>();

    useEffect(() => {
        const fetchLocations = async (spot: contentSpotTypes.FindDealershipViaMapSpot) => {
            const result = await getDealerships(spot);

            const sortedDealerships = sortGeoLocations(result.allDealerships);
            const storeData: StoreData = {
                locations: sortedDealerships,
                config: result.config,
                emergencyPhoneNumbers: result.emergencyPhoneNumbers,
                dealershipAndTruckNumbers: result.dealershipAndTruckNumbers,
                dealershipId: sortedDealerships?.[0]?.dealershipId,
                renderGoogleMaps: spot.renderGoogleMaps,
                staticImageUrl: spot.staticImage?.src,
            };

            setStoreInitialData(storeData);
        };

        fetchLocations(spot);
    }, [spot]);

    return (
        <div>
            {storeInitialData ? (
                <FindDealershipsStore.Provider runtimeModel={storeInitialData}>
                    <FindDealership
                        headerType={spot.headerType}
                        headerSize={spot.headerSize}
                        spotCta={spot.cta && spot.cta.length > 0 ? spot.cta[0] : undefined}
                    />
                </FindDealershipsStore.Provider>
            ) : null}
        </div>
    );
};

async function getDealerships(spot: contentSpotTypes.FindDealershipViaMapSpot): Promise<DealershipsUmbracoData> {
    // Get Contact Root Page (findDealershipPage)
    const [findDealershipPage, findDealershipPageError] = await getPageByDataTypeAliasGeneric<Array<OrgDealershipResponse>>('findDealershipPage');
    if (!findDealershipPage || findDealershipPage.length === 0 || findDealershipPageError) {
        throw new Error('No content found for findDealershipPage data type alias.');
    }

    // Get List of Dealerships (dealershipInformation)
    const [listOfDealerships, listOfDealershipsError] = await getPageByDataTypeAliasGeneric<Array<HesselDealership | UmbracoStarmarkDealership>>(
        'dealershipInformation'
    );
    if (!listOfDealerships || listOfDealerships.length === 0 || listOfDealershipsError) {
        throw new Error('No dealerships for dealershipInformation data type alias.');
    }

    const departmentList: Array<orgTypes.DealershipDepartment> = [];
    const brandList: Array<orgTypes.SupportedBrand> = [];
    let dealershipAndTruckNumbers: Array<orgTypes.DealerShipAndTruck> = [];
    const allDealershipsFromUmbraco = listOfDealerships.filter((x) => x.contentTypeAlias === 'dealershipInformation');

    // Filter based on spot configuration
    let filteredDealerships = allDealershipsFromUmbraco.filter(
        (x) =>
            (x as HesselDealership).supportedBrands.some((sb) => spot.brands.includes(sb.brand)) &&
            (x as HesselDealership).departments.some((dep) => spot.departments.includes(dep.departmentType))
    );
    const salesSetup = spot.departments.some((x) => x === 'Salg');
    const workshopSetup = spot.departments.some((x) => x === 'Værksted');
    if (spot.supportedVehicles.length > 0 && (salesSetup || workshopSetup)) {
        filteredDealerships = filteredDealerships.filter((x) =>
            (x as HesselDealership).supportedBrands.some((sb) => {
                return (
                    spot.brands.includes(sb.brand) &&
                    ((salesSetup && sb.vehiclesInSales.some((vis) => spot.supportedVehicles.includes(vis))) ||
                        (workshopSetup && sb.vehiclesInWorkshop.some((viw) => spot.supportedVehicles.includes(viw))))
                );
            })
        );
    }

    if (!filteredDealerships) {
        throw new Error('Filtering dealerships yielded no results');
    }

    for (let index = 0; index < filteredDealerships.length; index++) {
        const dealerShip = filteredDealerships[index];

        if (dealerShip.contentTypeAlias !== 'dealershipInformation') {
            continue;
        }

        dealerShip.departments.forEach((dep) => {
            if (!departmentList.some((x) => x.departmentType === dep.departmentType)) {
                departmentList.push(dep);
            }
        });

        dealerShip.supportedBrands.forEach((sb) => {
            if (!brandList.some((x) => x.brand === sb.brand)) {
                brandList.push(sb);
            }
        });
    }

    const mercedesDealerships = filteredDealerships.filter((x) => (x as HesselDealership).supportedBrands.some((sb) => sb.brand === 'Mercedes-Benz'));

    dealershipAndTruckNumbers = mercedesDealerships.map((x) => {
        return {
            emergencyNumber: (x as HesselDealership).truckEmergencyNumber,
            autolineId: x.autolineId,
            displayName: x.displayName,
        };
    });

    return {
        pageId: findDealershipPage[0].id,
        allDealerships: filteredDealerships.map((x, index) => {
            const typedValue = x as HesselDealership;
            return {
                dealershipId: x.autolineId,
                hovedafdelingId: typedValue.hovedafdelingId,
                header: x.name,
                lat: typedValue.latitude,
                lng: typedValue.longitude,
                city: typedValue.city,
                zipcode: typedValue.zipcode,
                phone: typedValue.phone,
                address: typedValue.address,
                email: typedValue.eMail,
                url: x.url,
                departments: typedValue.departments,
                supportedBrands: typedValue.supportedBrands,
                writeUsForm: typedValue.writeUsForm?.[0] ?? null,
                certificates: [],
                letter: getLetter(index),
                displayName: typedValue.displayName,
                testDriveOpeningHours: {
                    openingHours: typedValue.testDriveOpeningHours?.[0]?.openingHours ?? [],
                    specialDays: typedValue.testDriveOpeningHours?.[0]?.specialDays ?? [],
                },
                bookWorkshopModalTexts: {
                    bookWorkshopHeader: typedValue.bookWorkshopHeader,
                    bookWorkshopDescription: typedValue.bookWorkshopDescription,
                    bookWorkshopModalButtonText: typedValue.bookWorkshopModalButtonText,
                },
                dealershipImage: typedValue.dealershipImage,
            };
        }),
        config: {
            brands: brandList,
            departments: departmentList,
        },
        emergencyPhoneNumbers: findDealershipPage[0].emergencyPhoneNumbers,
        dealershipAndTruckNumbers,
        contentSpotSettings: findDealershipPage[0].contentSpotSettings ?? null,
    };
}

export const FindDealershipViaMap = React.memo(FindDealershipViaMapMemo);
