import { useEffect, useState } from 'react';
import { QueryTarget, queryTarget } from '../lib/media-query';

type MediaQuerySelector = {
    target: QueryTarget;
    ssrDefault?: boolean;
};

const useMediaQuery = ({ ssrDefault = false, target }: MediaQuerySelector): boolean => {
    const [matchQuery, setMatchQuery] = useState(ssrDefault);

    useEffect(() => {
        const handleChange = ({ matches }: MediaQueryListEvent): void => {
            setMatchQuery(matches);
        };

        const matchMedia = window.matchMedia(`(max-width: ${queryTarget[target]}px)`);

        setMatchQuery(matchMedia.matches);

        matchMedia.addEventListener('change', handleChange);

        return () => {
            matchMedia.removeEventListener('change', handleChange);
        };
    }, [target]);

    return matchQuery;
};

export { useMediaQuery };
