import styled from 'styled-components';

export const DealershipInfoWrapper = styled.div<{ hideBorderBottom: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-top: 1px solid #d8d8d8;
    border-bottom: ${(props) => (props.hideBorderBottom ? 'none' : '1px solid #d8d8d8')};
    padding: 20px 0;
`;

export const StyledHeading = styled.p`
    color: #0b0b0b;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 26px;
`;
export const StyledText = styled.p`
    color: #000000;
    font-size: 14px;
    letter-spacing: 0;
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
`;

export const Label = styled.p`
    margin-right: 5px;
`;

export const StyledLink = styled.a`
    text-decoration: underline;
`;
