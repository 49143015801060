import styled from 'styled-components';

export const PoliciesWrapper = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const ContentWrapper = styled.div``;

export const StyledText = styled.p`
    margin-right: 5px;
    font-size: ${({ theme }) => theme.typography.fontSize}px;
    line-height: 20px;
`;
