import { FC, useCallback } from 'react';
import { BookingFormStore, FormInput } from '../../../lib/state/booking/form';
import { TextInput } from '../../forms/inputs';
import { AddressInput } from '../../forms/inputs/address';
import { BookingWorkshopsStore } from '../../../lib/state/booking/workshops';
import type { Props } from './inputs.props';
import { InputList } from './inputs.styled';
import { SelectWorkshop } from './select-workshop';

const Inputs: FC<Props> = ({ inputs, canValidate, optional = false }) => {
    const { onChange, onChangeMultiple, setCanValidate, setCanValidateAddress, setIsFocused } = BookingFormStore.useStoreActions(
        (actions) => actions
    );
    const { setCalendarShouldUpdate, setShopToGetNearbyFrom } = BookingWorkshopsStore.useStoreActions((actions) => actions);

    const renderFormInput = useCallback(
        (input: FormInput<string>, idx: number) => {
            switch (input.type) {
                case 'email':
                case 'tel':
                case 'text':
                case 'car':
                case 'name':
                    return (
                        <TextInput
                            key={`${idx}:${input.id}:input`}
                            id={input.id}
                            label={input.label}
                            onChange={(e) => {
                                onChange({ id: input.id, value: e.target.value });
                            }}
                            type={input.type}
                            placeholder={input.placeholder}
                            value={input.value}
                            disabled={input.disabled}
                            isValid={input.isValid}
                            validationMessage={input.validationMessage}
                            canValidateInputField={!optional && (input.canValidate || canValidate)}
                            onFocus={() => {
                                setIsFocused({ id: input.id, value: true });
                            }}
                            onInputBlur={() => {
                                setIsFocused({ id: input.id, value: false });
                                setCanValidate({ id: input.id, value: true });
                            }}
                            required={input.mandatory}
                        />
                    );
                case 'number':
                    return (
                        <TextInput
                            key={`${idx}:${input.id}:input`}
                            id={input.id}
                            label={input.label}
                            onChange={(e) => {
                                onChange({ id: input.id, value: e.target.value });
                            }}
                            type={input.type}
                            placeholder={input.placeholder}
                            value={input.value}
                            min={0}
                            isValid={input.isValid}
                            canValidateInputField={!optional && (input.canValidate || canValidate)}
                            validationMessage={input.validationMessage}
                            onFocus={() => {
                                setIsFocused({ id: input.id, value: true });
                            }}
                            onInputBlur={() => {
                                setIsFocused({ id: input.id, value: false });
                                setCanValidate({ id: input.id, value: true });
                            }}
                            pattern="[0-9]*"
                            required={input.mandatory}
                        />
                    );

                case 'workshop':
                    return (
                        <SelectWorkshop
                            key={`${idx}:${input.id}:input`}
                            id={input.id}
                            label={input.label}
                            placeholder={input.placeholder}
                            onChange={(value) => {
                                onChange({ id: input.id, value });
                                setCalendarShouldUpdate(true);
                                setShopToGetNearbyFrom(value);
                            }}
                            value={input.value}
                            canValidateInputField={!optional && (input.canValidate || canValidate)}
                            validationMessage={input.validationMessage}
                            infoMessage={input.infoMessage}
                            onInputBlur={() => setCanValidate({ id: input.id, value: true })}
                            required={input.mandatory}
                        />
                    );

                case 'address':
                    return (
                        <AddressInput
                            key={`${idx}:${input.id}:input`}
                            id={input.id}
                            onChange={({ id, value }) => {
                                onChangeMultiple({ rootId: input.id, id, value });
                            }}
                            inputs={input.inputs}
                            canValidateInputField={canValidate}
                            setCanValidate={({ rootId, id, value }) => setCanValidateAddress({ rootId, id, value })}
                            required={input.mandatory}
                        />
                    );

                default:
                    <p>unknown</p>;
            }
        },
        [
            canValidate,
            onChange,
            onChangeMultiple,
            optional,
            setCalendarShouldUpdate,
            setCanValidate,
            setCanValidateAddress,
            setIsFocused,
            setShopToGetNearbyFrom,
        ]
    );

    return <InputList>{inputs.map(renderFormInput)}</InputList>;
};

export default Inputs;
