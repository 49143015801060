import { utcToZonedTime } from 'date-fns-tz';
import { FC } from 'react';
import { DateStyle, formatDate } from '../../../utils/helpers';
import { ISlot } from './slot.props';
import { Diameter, Rectangle, Text } from './slot.styled';

export const Slot: FC<ISlot> = ({ time, isAvailable, isSelected, onClick }) => {
    const danishTime = utcToZonedTime(time, 'Europe/Copenhagen');

    return (
        <Rectangle
            isAvailable={isAvailable}
            isSelected={isSelected}
            onClick={() => {
                if (isSelected || !isAvailable) {
                    return;
                }
                onClick(time);
            }}
        >
            {!isAvailable && <Diameter />}
            <Text isAvailable={isAvailable} isSelected={isSelected}>
                kl. {formatDate(danishTime, DateStyle.HH_mm)}
            </Text>
        </Rectangle>
    );
};
