import { FC, useMemo } from 'react';
import { SidePanel } from '../side-panel';
import { SidePanelLayout } from '../side-panel-layout/side-panel-layout.component';
import { sharedTypes } from '../../../../lib/api/models/umbraco';
import { MEDIA_URL } from '../../../../utils/environment-constants';
import { PdpResource } from '../../../../lib/view-models/vehicle';
import { StyledCloseCta, StyledDescription, StyledDesktopOnly, StyledHeader, StyledMobileOnly } from './modal-with-editor-content.styled';
import { CompactCarousel } from '../../carousels/compact-carousel/compact-carousel.component';
import { SimpleCarousel } from '../../carousels/simple-carousel/simple-carousel.component';

export type GenericModalContent = {
    header: string;
    description: string;
    mediaList: Array<{ caption: string; media: sharedTypes.Image | sharedTypes.Video }>;
};

type IProps = {
    onClose: () => void;
    visible: boolean;
    content: GenericModalContent;
    closeCta?: boolean;
};

export const ModalWithEditorContent: FC<IProps> = ({ onClose, visible, content, closeCta = false }) => {
    const carouselItems = useMemo(() => {
        return (
            content.mediaList.map((x) => {
                if (x.media.type === 'image' || x.media.extension?.toLowerCase() === 'webp') {
                    return {
                        type: 'image',
                        url: MEDIA_URL + x.media.src,
                        caption: x.caption,
                    } as PdpResource;
                }

                if (x.media.type === 'file') {
                    return {
                        type: 'video',
                        url: MEDIA_URL + x.media.src,
                        caption: x.caption,
                    } as PdpResource;
                }

                throw new Error('Unknown media type');
            }) || []
        );
    }, [content]);

    return (
        <SidePanel cancelAction={onClose} isVisible={visible}>
            <SidePanelLayout closeSidePanel={onClose}>
                <StyledHeader>{content.header}</StyledHeader>
                <StyledDescription dangerouslySetInnerHTML={{ __html: content.description }}></StyledDescription>
                {carouselItems.length > 0 ? (
                    <>
                        <StyledMobileOnly>
                            <CompactCarousel slides={carouselItems} isAboveFold={false} />
                        </StyledMobileOnly>
                        <StyledDesktopOnly>
                            <SimpleCarousel slides={carouselItems} isAboveFold={false} />
                        </StyledDesktopOnly>
                    </>
                ) : null}
                {closeCta ? (
                    <StyledCloseCta variant="primary" onClick={onClose}>
                        Luk
                    </StyledCloseCta>
                ) : null}
            </SidePanelLayout>
        </SidePanel>
    );
};
