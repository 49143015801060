import React, { useEffect, useMemo, useState, VFC } from 'react';
import { hesselViewModels } from '../../../lib/view-models';

import {
    formatPrice,
    isNullOrEmpty,
    sanitizeMarkup,
    validateEmail,
    validateName,
    validateNotEmpty,
    validateRegistrationNumber,
    validateTel,
} from '../../../utils/helpers';
import { DropdownInput, TextareaInput, TextInput } from '../../forms/inputs';
import { CheckBox, Separator } from '../../shared';
import { SidePanel } from '../../shared/modals/side-panel';
import { SidePanelLayout } from '../../shared/modals/side-panel-layout/side-panel-layout.component';
import { OrderCarReceipts } from '../order-car-receipts/order-car-receipts.component';
import {
    CheckboxLabel,
    Disclaimer,
    HeaderAndPrice,
    InputsWrapper,
    SectionHeader,
    SelectionSummary,
    StyledHeader,
    StyledPrice,
    StyledSubmitButton,
    StyledText,
    TermsAndConditions,
    Title,
    TitleAndValue,
    TradeOfferCheckbox,
    Value,
} from './order-car-modal.styled';
import { submitLeadData } from '../../../lib/api/lead/lead-api';
import { OrderCarModalForm } from './order-car-modal.types';
import { usePdpLeadSubmitData } from '../hooks/use-pdp-lead-submit-data';
import { OrderCarModalSubmitData } from '../hooks/financial-types';
import { usePriceByOwnership } from '../hooks/use-price-by-ownership';
import { DealershipInformationPage, ProductDetailsPage } from '../../../lib/api/models/umbraco';
import { DropdownOption } from '../../shared/date-picker-dropdown/date-picker-dropdown.component';
import { usePdpDealerships } from '../product-details-page/hooks/use-pdp-dealerships';
import { useRelewiseTracking } from '../../../hooks/relewise/use-relewise-tracking';

type OrderCarModalProps = {
    visible: boolean;
    onClose: () => void;
    allDealerships: Array<DealershipInformationPage>;
    product: hesselViewModels.ProductDetails;
    color?: hesselViewModels.ProductColor;
    page: ProductDetailsPage;
    selectedTab: hesselViewModels.OwnershipTab;
    vehicleLocationId?: string;
    isUsedCar?: boolean;
};

const OrderCarModalMemo: VFC<OrderCarModalProps> = ({
    visible,
    onClose,
    allDealerships,
    product,
    color,
    page,
    selectedTab,
    vehicleLocationId,
    isUsedCar,
}) => {
    const initialDealership = useMemo(() => {
        if (isUsedCar) {
            return allDealerships.find((x) => Number(x.hovedafdelingId) === Number(vehicleLocationId));
        }

        if (vehicleLocationId) {
            return allDealerships.find((x) => Number(x.hovedafdelingId) === Number(vehicleLocationId));
        }

        return allDealerships?.[0];
    }, [allDealerships, isUsedCar, vehicleLocationId]);

    const modalSettings = page.orderCarModalSettings[0];

    const [formValues, setFormValues] = useState<OrderCarModalForm>({
        customer: {},
        consentCode: modalSettings.marketingLabel,
        url: product.url,
        dealershipName: initialDealership?.hovedafdelingId,
        dealershipId: initialDealership?.displayName,
    });

    const [formState, setFormState] = useState<'Initial' | 'Submitted'>('Initial');

    const selectedExtraEquipment = [...product.extraEquipment[selectedTab], ...product.extraEquipmentPackages[selectedTab]].filter(
        (x) => x.isSelected
    );
    const selectedOptionalEquipment = product.optionalEquipment[selectedTab].filter((x) => !!x.isSelected);

    const { getOrderCarModalSubmitData } = usePdpLeadSubmitData();

    const { priceInformation, santanderFinancing } = usePriceByOwnership(page);

    const resetFormData = () => {
        if (formState === 'Submitted') {
            setFormState('Initial');
            setFormValues({
                customer: {},
                consentCode: modalSettings.marketingLabel,
                dealershipId: initialDealership ? initialDealership.hovedafdelingId : undefined,
                dealershipName: initialDealership ? initialDealership.displayName : '',
                url: product.url,
            });
        }
    };

    useEffect(() => {
        if (isUsedCar && initialDealership) {
            setFormValues((prev) => ({
                ...prev,
                dealershipId: initialDealership.hovedafdelingId,
                dealershipName: initialDealership.displayName,
            }));
        }
    }, [isUsedCar, initialDealership]);

    const { dealerships } = usePdpDealerships();

    const dealershipOptions: DropdownOption<string>[] = useMemo(() => {
        if (dealerships) {
            const vehicleType = product.vehicleType === 'Car' ? 'Cars' : 'Vans';
            const filteredDealerships = dealerships.filter((x) =>
                x.supportedBrands.some((y) => y.brand === product.brand && y.vehiclesInSales.some((s) => s === vehicleType))
            );
            return filteredDealerships.map((x) => {
                return {
                    value: x.hovedafdelingId,
                    displayValue: x.displayName,
                };
            });
        }
        return [];
    }, [dealerships, product.brand, product.vehicleType]);

    const selectedDealership = useMemo(() => {
        if (dealershipOptions && formValues.dealershipId) return dealershipOptions.find((x) => x.value === formValues.dealershipId);
        return undefined;
    }, [dealershipOptions, formValues.dealershipId]);

    const { trackVehicleConversion } = useRelewiseTracking();

    if (!modalSettings) {
        return null;
    }

    return (
        <SidePanel
            isVisible={visible}
            cancelAction={() => {
                onClose();
                resetFormData();
            }}
            variant="sm"
        >
            <SidePanelLayout
                closeSidePanel={() => {
                    onClose();
                    resetFormData();
                }}
            >
                {formState === 'Initial' ? (
                    <>
                        <HeaderAndPrice>
                            <StyledHeader>{product.availability === 'Order' ? 'Anmod om tilbud' : 'Forespørg på bilen'}</StyledHeader>
                            <StyledPrice>
                                {formatPrice(priceInformation.price)} kr.{priceInformation.monthly ? '/md.' : null}
                            </StyledPrice>
                        </HeaderAndPrice>

                        <SelectionSummary>
                            <StyledText spacing="0 0 8px 0">
                                Du har valgt <strong>{`${product.brand} ${product.name}`}</strong>.
                            </StyledText>

                            <TitleAndValue>
                                <Title>Farve:</Title>
                                <Value>{color?.name}</Value>
                            </TitleAndValue>

                            {selectedExtraEquipment.length > 0 ? (
                                <TitleAndValue>
                                    <Title>Udstyr:</Title>
                                    <Value>{selectedExtraEquipment.map((x) => x.name).join(', ')}</Value>
                                </TitleAndValue>
                            ) : null}

                            {selectedOptionalEquipment.length > 0 ? (
                                <TitleAndValue>
                                    <Title>Tilvalg:</Title>
                                    <Value>{selectedOptionalEquipment.map((x) => x.name).join(', ')}</Value>
                                </TitleAndValue>
                            ) : null}
                        </SelectionSummary>

                        <Separator marginTop="30px" marginBottom="30px" />

                        {isUsedCar && initialDealership && initialDealership.displayName.length > 0 ? (
                            <SectionHeader>Bilen står i {initialDealership?.displayName}</SectionHeader>
                        ) : null}

                        {!isUsedCar ? (
                            <>
                                <SectionHeader spacing="0 0 18px 0">
                                    Ønsker du at modtage dit tilbud fra en bestemt forhandler, vælg den da herunder
                                </SectionHeader>

                                <DropdownInput
                                    options={dealershipOptions}
                                    onChange={(d) => {
                                        if (d)
                                            setFormValues({
                                                ...formValues,
                                                dealershipId: d.value,
                                                dealershipName: d.displayValue,
                                            });
                                    }}
                                    label="Vælg afdeling"
                                    id="SelectDepartment"
                                    value={selectedDealership}
                                    isValid={!!formValues?.dealershipId}
                                    canValidateInputField={!!formValues?.canValidate || !!formValues?.dealershipId}
                                    validationMessage={'Vælg en forhandler'}
                                />
                            </>
                        ) : null}

                        <StyledText spacing="10px 0 32px 0"></StyledText>

                        <SectionHeader spacing="0 0 18px 0">Hvem er du?</SectionHeader>
                        <InputsWrapper>
                            <TextInput
                                id="email-input"
                                label={modalSettings.emailLabel}
                                onChange={(e) => {
                                    setFormValues({
                                        ...formValues,
                                        email: e.target.value,
                                    });
                                }}
                                type="email"
                                placeholder={modalSettings.emailPlaceholder}
                                value={formValues?.email}
                                disabled={false}
                                isValid={validateEmail(formValues?.email ?? '')}
                                canValidateInputField={!!formValues?.canValidate}
                                validationMessage={modalSettings.emailValidationMessage}
                            />

                            <TextInput
                                id="phone-input"
                                label={modalSettings.telLabel}
                                onChange={(e) => {
                                    setFormValues({
                                        ...formValues,
                                        phone: e.target.value,
                                    });
                                }}
                                type="tel"
                                placeholder={modalSettings.telPlaceholder}
                                value={formValues?.phone}
                                disabled={false}
                                isValid={validateTel(formValues?.phone ?? '')}
                                canValidateInputField={!!formValues?.canValidate}
                                validationMessage={modalSettings.telValidationMessage}
                            />

                            <TextInput
                                id="full-name-input"
                                label={modalSettings.fullNameLabel}
                                onChange={(e) => {
                                    setFormValues({
                                        ...formValues,
                                        fullName: e.target.value,
                                    });
                                }}
                                type="text"
                                placeholder={modalSettings.fullNamePlaceholder}
                                value={formValues?.fullName}
                                disabled={false}
                                isValid={validateName(formValues?.fullName ?? '')}
                                canValidateInputField={!!formValues?.canValidate}
                                validationMessage={modalSettings.fullNameValidationMessage}
                            />
                        </InputsWrapper>

                        <Separator marginTop="35px" marginBottom="25px" />

                        <SectionHeader>
                            {!isNullOrEmpty(modalSettings.existingCarCheckboxText)
                                ? modalSettings.existingCarHeader
                                : 'Har du en byttebil som du ønsker at indgå i handlen, og som du ønsker vi skal give en vurdering på?'}
                        </SectionHeader>
                        <InputsWrapper>
                            <TradeOfferCheckbox
                                id={`offer-to-trade-car-${product.id}`}
                                value="0"
                                checked={!!formValues?.customer?.wantsToTrade}
                                onChange={(checked) =>
                                    setFormValues({
                                        ...formValues,
                                        customer: {
                                            ...formValues.customer,
                                            wantsToTrade: checked,
                                        },
                                    })
                                }
                                textAlign="top"
                                isValid={true}
                                required={false}
                                validationMessage={'---'}
                                canValidate={!!formValues?.canValidate}
                                addSpacing={!!formValues?.customer?.wantsToTrade}
                            >
                                <Value>{modalSettings.existingCarCheckboxText}</Value>
                            </TradeOfferCheckbox>

                            {formValues.customer?.wantsToTrade ? (
                                <>
                                    <TextInput
                                        id="registration-number-input"
                                        label={modalSettings.registrationNumberLabel}
                                        onChange={(e) => {
                                            setFormValues({
                                                ...formValues,
                                                customer: {
                                                    ...formValues.customer,
                                                    regNumber: e.target.value,
                                                },
                                            });
                                        }}
                                        type="text"
                                        placeholder={modalSettings.registrationNumberPlaceholder}
                                        value={formValues?.customer?.regNumber}
                                        disabled={false}
                                        isValid={validateRegistrationNumber(formValues?.customer.regNumber ?? '')}
                                        canValidateInputField={!!formValues?.canValidate && formValues.customer.wantsToTrade}
                                        validationMessage={modalSettings.registrationNumberValidationMessage}
                                    />

                                    <TextInput
                                        id="odometer-input"
                                        label={modalSettings.odometerLabel}
                                        onChange={(e) => {
                                            setFormValues({
                                                ...formValues,
                                                customer: {
                                                    ...formValues.customer,
                                                    odometer: e.target.value,
                                                },
                                            });
                                        }}
                                        type="text"
                                        placeholder={modalSettings.odometerPlaceholder}
                                        value={formValues?.customer?.odometer}
                                        disabled={false}
                                        isValid={validateNotEmpty(formValues?.customer.odometer ?? '')}
                                        canValidateInputField={!!formValues?.canValidate && formValues.customer.wantsToTrade}
                                        validationMessage={modalSettings.odometerValidationMessage}
                                    />

                                    <TextareaInput
                                        hideValidation={true}
                                        id="user-message"
                                        isValid={true}
                                        label={modalSettings.userMessageLabel}
                                        placeholder={modalSettings.userMessagePlaceholder}
                                        value={formValues?.customer?.message}
                                        onChange={(e) => {
                                            setFormValues({
                                                ...formValues,
                                                customer: {
                                                    ...formValues.customer,
                                                    message: e.target.value,
                                                },
                                            });
                                        }}
                                    />
                                </>
                            ) : null}
                        </InputsWrapper>

                        <Separator marginTop="30px" marginBottom="30px" />

                        <TermsAndConditions>
                            <CheckBox
                                id={`order-car-personal-data-policy-${product.id}`}
                                value="1"
                                checked={!!formValues?.termsAccepted}
                                onChange={(checked) =>
                                    setFormValues({
                                        ...formValues,
                                        termsAccepted: checked,
                                    })
                                }
                                textAlign="top"
                                isValid={formValues?.termsAccepted}
                                required={true}
                                validationMessage={modalSettings.personalDataPolicyValidationMessage}
                                canValidate={!!formValues?.canValidate}
                            >
                                <CheckboxLabel {...sanitizeMarkup(modalSettings.personalDataPolicyLabel)} />
                            </CheckBox>

                            <CheckBox
                                id={`order-car-marketing-${product.id}`}
                                value="2"
                                checked={!!formValues?.marketingAccepted}
                                onChange={(checked) =>
                                    setFormValues({
                                        ...formValues,
                                        marketingAccepted: checked,
                                    })
                                }
                                textAlign="top"
                                isValid={true}
                                required={false}
                                canValidate={false}
                            >
                                <CheckboxLabel {...sanitizeMarkup(modalSettings.marketingLabel)} />
                            </CheckBox>
                        </TermsAndConditions>

                        <StyledSubmitButton
                            variant="primary"
                            onClick={async () => {
                                if (!formValues?.canValidate) {
                                    setFormValues({
                                        ...formValues,
                                        canValidate: true,
                                    });
                                }

                                const regIsValid = formValues.customer?.wantsToTrade
                                    ? validateRegistrationNumber(formValues.customer?.regNumber ?? '')
                                    : true;

                                const odometerIsValid = formValues.customer?.wantsToTrade
                                    ? validateNotEmpty(formValues.customer?.odometer ?? '')
                                    : true;

                                if (
                                    validateEmail(formValues?.email ?? '') &&
                                    validateTel(formValues?.phone ?? '') &&
                                    validateName(formValues?.fullName ?? '') &&
                                    formValues.termsAccepted &&
                                    regIsValid &&
                                    odometerIsValid &&
                                    formValues?.dealershipName
                                ) {
                                    const submitData = getOrderCarModalSubmitData(
                                        formValues,
                                        selectedTab === 'Car Finansiering' || selectedTab === 'Van Finansiering' ? santanderFinancing : undefined
                                    );
                                    await submitLeadData<OrderCarModalSubmitData>('order-vehicle', submitData);
                                    trackVehicleConversion(product.entityId.toString(), product.purchaseTypes.cash.basePrice);
                                    setFormState('Submitted');
                                }
                            }}
                        >
                            <Value>{!isNullOrEmpty(modalSettings.submitButtonText) ? modalSettings.submitButtonText : 'Forespørg på bilen'}</Value>
                        </StyledSubmitButton>

                        <Disclaimer>{modalSettings.disclaimer}</Disclaimer>
                    </>
                ) : null}

                {formState === 'Submitted' ? (
                    <OrderCarReceipts
                        email={formValues.email ?? ''}
                        header={modalSettings.receiptSettings?.[0]?.header}
                        description={modalSettings.receiptSettings?.[0]?.description}
                        actionForUserText={modalSettings.receiptSettings?.[0]?.actionForUserText}
                        linkText={modalSettings.receiptSettings?.[0]?.linkText}
                    />
                ) : null}
            </SidePanelLayout>
        </SidePanel>
    );
};

export const OrderCarModal = React.memo(OrderCarModalMemo);
