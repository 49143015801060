import { VFC } from 'react';
import { GoogleMap } from '../../google/_map-wrapper/map-wrapper.component';
import { DealershipInfoBox } from '../dealership-info-box/dealership-info-box.component';
import styled from 'styled-components';
import { FindDealershipsStore } from '../../../../lib/state/find-dealership';
import { DEFAULT_MAP_POSITION_Mobile } from '../../../../constants/organization-consts';

export const FindDealershipsMobile: VFC = () => {
    const { showMobileInfoBox, locations, userCurrentLocation, renderGoogleMaps } = FindDealershipsStore.useStoreState((state) => state);

    return (
        <>
            <StyledFindStores>
                <DealershipInfoBox showAnimatedInfo={showMobileInfoBox} />

                {renderGoogleMaps ? (
                    <GoogleMap
                        center={{
                            lat: userCurrentLocation ? locations[0]?.lat ?? DEFAULT_MAP_POSITION_Mobile.lat : DEFAULT_MAP_POSITION_Mobile.lat,
                            lng: userCurrentLocation ? locations[0]?.lng ?? DEFAULT_MAP_POSITION_Mobile.lng : DEFAULT_MAP_POSITION_Mobile.lng,
                        }}
                    />
                ) : (
                    <StyledDealerShipInfoBox />
                )}
            </StyledFindStores>
        </>
    );
};

export const StyledFindStores = styled.section`
    position: relative;
`;

export const StyledDealerShipInfoBox = styled.div`
    width: 100%;
    aspect-ratio: 9 / 16;
    max-height: 500px;
`;
