import React, { useEffect, useState, VFC } from 'react';
import { hesselApiTypes } from '../../../lib/api/models/hessel-api';
import { FinancialCalculationsModalSettings } from '../../../lib/api/models/umbraco/product-details';
import { hesselViewModels } from '../../../lib/view-models';
import { formatPrice } from '../../../utils/helpers';
import { SidePanel } from '../../shared/modals/side-panel';
import { SidePanelLayout } from '../../shared/modals/side-panel-layout/side-panel-layout.component';
import { Spinner } from '../../shared/spinner';
import { SteppedInputSlider } from '../../shared/stepped-input-slider/stepped-input-slider.component';
import { KeyValueTableRow } from '../../shared/tables/key-value-table/row';
import {
    Description,
    HeaderSection,
    Header,
    Price,
    CalculatorSection,
    SliderContent,
    Label,
    LabelAndValue,
    SummarySection,
    SummaryHeader,
    CalculationTable,
    GetOfferButton,
    Disclaimer,
    ErrorText,
    SpinnerWrapper,
} from './financial-calculations-modal.styled';
import { ProductDetailsStore } from '../../../lib/state/hessel-site/product-details';
import { usePriceByOwnership } from '../hooks/use-price-by-ownership';
import { ProductDetailsPage as UmbracoProductDetailsPage } from '../../../lib/api/models/umbraco';
import { OpenDialog, usePdpModals } from '../product-details-page/product-details-page.context';
import sanitizeHtml from 'sanitize-html';
import { Abbreviations } from '../../../constants/units-and-abbreviations';

type FinancialCalculationsModalProps = {
    visible: boolean;
    onClose: () => void;
    product: hesselViewModels.ProductDetails;
    cashPrice: number;
    modalSettings: FinancialCalculationsModalSettings;
    page: UmbracoProductDetailsPage;
};

const down_payment_values = {
    default: 20,
    min: 20,
    max: 40,
    interval: 10,
};

const duration_values = {
    default: 84,
    min: 24,
    max: 96,
    interval: 12,
};

export const FinancialCalculationsModal: VFC<FinancialCalculationsModalProps> = ({ visible, onClose, product, modalSettings, cashPrice, page }) => {
    const { financialPurchaseDownPaymentPercentage } = ProductDetailsStore.useStoreState((state) => state);
    const { setFinancialPurchaseTypeDurationMonths, setFinancialPurchaseTypeDownPaymentPercentage } = ProductDetailsStore.useStoreActions(
        (actions) => actions
    );

    const duration = product.purchaseTypes.financing.durationMonths ?? duration_values.default;
    const downPaymentPercentage = financialPurchaseDownPaymentPercentage ?? down_payment_values.default;
    const [santanderFinancingToShow, setSantanderFinancingToShow] = useState<hesselApiTypes.CarFinancing | undefined>(undefined);

    const {
        santanderFinancing,
        santanderError: error,
        santanderIsLoading: isLoading,
        santanderIsValidating: isValidating,
    } = usePriceByOwnership(page);

    const [showSpinner, setShowSpinner] = useState(false);
    const { openDialog } = usePdpModals();

    useEffect(() => {
        if (!santanderFinancing) {
            return;
        }
        if (santanderFinancing && !error) {
            setSantanderFinancingToShow(santanderFinancing);
            return;
        }
        setSantanderFinancingToShow(undefined);
    }, [santanderFinancing, error]);

    useEffect(() => {
        setShowSpinner(false);
        if (isLoading || isValidating) {
            const timeout = setTimeout(() => setShowSpinner(true), 1000);
            return () => clearTimeout(timeout);
        }
    }, [cashPrice, duration, downPaymentPercentage, santanderFinancingToShow, isLoading, isValidating]);

    return (
        <>
            <SidePanel cancelAction={onClose} isVisible={visible}>
                <SidePanelLayout closeSidePanel={onClose}>
                    <HeaderSection>
                        <Header>Finansiering</Header>
                        <Price>
                            {santanderFinancingToShow?.paymentPerMonth
                                ? `${formatPrice(santanderFinancingToShow?.paymentPerMonth)} ${Abbreviations.KR_SLASH_MD}`
                                : '-'}
                        </Price>
                    </HeaderSection>

                    <Description>
                        Bilens pris inkl. evt. udstyr: <strong>{formatPrice(cashPrice)} kr.</strong>
                    </Description>

                    {error === 'Data Fetch Failed' ? (
                        <ErrorText>Vi kan desværre ikke beregne finansieringspriser i øjeblikket. Prøv venligst igen senere.</ErrorText>
                    ) : null}

                    <CalculatorSection>
                        <SliderContent>
                            <LabelAndValue>
                                <Label>Løbetid</Label>
                                <Label>{duration} mdr.</Label>
                            </LabelAndValue>
                            <SteppedInputSlider
                                min={duration_values.min}
                                max={duration_values.max}
                                defaultValue={duration_values.default}
                                interval={duration_values.interval}
                                value={duration}
                                onValueChange={(e) => {
                                    setFinancialPurchaseTypeDurationMonths({
                                        durationMonths: e,
                                    });
                                }}
                            />
                        </SliderContent>

                        <SliderContent>
                            <LabelAndValue>
                                <Label>Udbetaling</Label>
                                <Label>{formatPrice(Math.ceil(cashPrice * (downPaymentPercentage / 100)))} kr.</Label>
                            </LabelAndValue>
                            <SteppedInputSlider
                                min={down_payment_values.min}
                                max={down_payment_values.max}
                                defaultValue={down_payment_values.default}
                                interval={down_payment_values.interval}
                                value={downPaymentPercentage}
                                onValueChange={(e) => {
                                    setFinancialPurchaseTypeDownPaymentPercentage({
                                        downPaymentPercentage: e,
                                    });
                                }}
                                unit="%"
                            />
                        </SliderContent>
                    </CalculatorSection>

                    {!!santanderFinancingToShow && !showSpinner && !error ? (
                        <>
                            <SummarySection>
                                <SummaryHeader>Oversigt</SummaryHeader>
                                <Description>{modalSettings.description}</Description>
                                <CalculationTable>
                                    <KeyValueTableRow
                                        label="Månedlig ydelse"
                                        value={`${formatPrice(santanderFinancingToShow?.paymentPerMonth ?? 0)} ${Abbreviations.KR_SLASH_MD}`}
                                    />
                                    <KeyValueTableRow
                                        label={modalSettings.downPaymentLabel}
                                        value={`${formatPrice(santanderFinancingToShow?.downPayment ?? 0)} ${Abbreviations.KR}`}
                                    />
                                    <KeyValueTableRow label={modalSettings.durationLabel} value={`${duration} ${Abbreviations.MDR}`} />
                                    <KeyValueTableRow
                                        label={modalSettings.variableDebtorInterestLabel}
                                        value={`${formatPrice(santanderFinancingToShow?.nominalInterest ?? 0, 2)}%`}
                                    />
                                    <KeyValueTableRow label={modalSettings.aopLabel} value={`${santanderFinancingToShow?.aopBeforeTax}%`} />
                                    <KeyValueTableRow
                                        label={modalSettings.totalLoanCostsLabel}
                                        value={`${formatPrice(santanderFinancingToShow?.totalLoanCosts ?? 0)} ${Abbreviations.KR}`}
                                    />
                                    <KeyValueTableRow
                                        label={modalSettings.totalRepayLabel}
                                        value={`${formatPrice(santanderFinancingToShow?.totalRepay ?? 0)} ${Abbreviations.KR}`}
                                    />
                                </CalculationTable>
                            </SummarySection>

                            <GetOfferButton
                                variant="primary"
                                onClick={() => {
                                    onClose();
                                    openDialog({ dialog: OpenDialog.OrderCarFormModal });
                                }}
                            >
                                <p>{modalSettings.submitButtonText}</p>
                            </GetOfferButton>

                            <Disclaimer>
                                {sanitizeHtml(santanderFinancingToShow?.defaultDutyText, {
                                    allowedTags: [],
                                })}
                            </Disclaimer>
                        </>
                    ) : null}

                    {showSpinner && !error ? (
                        <SpinnerWrapper>
                            <Spinner size="medium" variant="light" />
                        </SpinnerWrapper>
                    ) : null}
                </SidePanelLayout>
            </SidePanel>
        </>
    );
};
