import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { mediaQuery } from '../../../utils/helpers';
import { BreakpointKeys } from '../../../themes';

type Props = {
    min?: number | BreakpointKeys;
    max?: number | BreakpointKeys;
    className?: string;
};

export const Responsive: FC<PropsWithChildren<Props>> = ({ min, max, className, children }) => {
    return (
        <ResponsiveContainer mediaQuery={mediaQuery(min, max)} className={className}>
            {children}
        </ResponsiveContainer>
    );
};

const ResponsiveContainer = styled.div<{ mediaQuery: string }>(({ mediaQuery }) => ({
    '&&': {
        // A little trick to increase specificity to allow for customization.
        display: 'none',

        [mediaQuery]: {
            display: 'inherit',
        },
    },
}));
