import { VFC } from 'react';
import {
    BorderBox,
    ImageWrapper,
    StyledImage,
    TextsWrapper,
    CarName,
    CarModel,
    CarColor,
    ColorTextAndImageMobile,
    StyledSummaryColorCircleMobile,
} from './car-summary.styled';

type Props = {
    imageUrl: string;
    brand: string;
    model: string;
    colorName: string;
    colorUrl: string;
};

export const CarSummary: VFC<Props> = ({ imageUrl, brand, model, colorName, colorUrl }) => {
    return (
        <BorderBox>
            <ImageWrapper>
                <StyledImage src={imageUrl} />
            </ImageWrapper>
            <TextsWrapper>
                <CarName>{brand}</CarName>
                <CarModel>{model}</CarModel>
                <ColorTextAndImageMobile>
                    <StyledSummaryColorCircleMobile imageUrl={colorUrl} />
                    <CarColor>{colorName}</CarColor>
                </ColorTextAndImageMobile>
            </TextsWrapper>
        </BorderBox>
    );
};
