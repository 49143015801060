import styled from 'styled-components';
import { StyledProductDetailGridItem } from '../shared/product-detail-page-shared.styled';

export const StyledEquipmentHeader = styled.p`
    color: rgba(11, 11, 11, 1);
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: left;
    line-height: 30px;
    margin-bottom: 4px;
`;

export const StyledEquipmentDescription = styled.span`
    opacity: 1;
    color: rgba(99, 99, 99, 1);
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 20px;
    display: block;
`;

export const StyledEquipmentGridItem = styled(StyledProductDetailGridItem)`
    display: grid;
    align-items: center;
    grid-template-columns: 25px auto;
    gap: 0px 2px;
    padding-bottom: 12px;
`;
