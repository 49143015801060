import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const StepWrapper = styled.section<{ isCurrentStep: boolean; isCompleted: boolean }>`
    background-color: ${(props) => (props.isCompleted ? props.theme.palette.background.default : props.theme.palette.common.white)};
    padding: 0px 15px;
    padding-bottom: ${(props) => (props.isCurrentStep || props.isCompleted ? '10px' : '0')};
    box-shadow: ${(props) => (props.isCompleted ? 'none' : props.theme.shadows.small)};

    border-top: 1px solid ${(props) => props.theme.palette.grey.medium};
    border-bottom: 1px solid ${(props) => props.theme.palette.grey.medium};

    @media ${device.tablet} {
        background-color: ${(props) => props.theme.palette.common.white};
        box-shadow: ${({ theme }) => theme.shadows.small};
        border-top: none;
        border-bottom: none;
    }

    pointer-events: ${(props) => (props.isCompleted ? 'none' : 'revert')};
`;

export const StepHeader = styled.div<{ isCurrentStep: boolean; isCompleted: boolean }>`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 20px 0;
    color: ${(props) => (props.isCompleted ? '#E2E2E4' : 'inherit')};

    @media ${device.tablet} {
        border-bottom: ${(props) => (props.isCurrentStep || props.isCompleted ? '1px' : '0')} solid ${({ theme }) => theme.palette.grey.medium};
    }
`;

export const Header = styled.h2`
    margin-left: 15px;
    color: inherit;
    font-weight: bold;
    font-family: 'Corporate E';
`;

export const IconAndText = styled.div`
    display: flex;
    align-items: center;
`;

export const StepContent = styled.div`
    position: relative;
    padding-left: 5px;
    padding-right: 5px;

    @media ${device.tablet} {
        padding-left: 50px;
        padding-right: 50px;
    }
`;

export const StepAction = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    @media ${device.tablet} {
        margin-top: revert;
    }
`;

export const StyledFootNote = styled.p`
    color: #807f80;
    font-size: 11px;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    width: revert;

    padding: 1rem 0;
    @media ${device.tablet} {
        width: 75%;
    }
`;

export const ChildrenWrapper = styled.div`
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
`;

export const PointerEvents = styled.span`
    pointer-events: all;
`;
