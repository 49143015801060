import styled from 'styled-components';

export const TogglerWrapper = styled.div`
    background-color: #ffffff;
    display: grid;
    grid-template-columns: minmax(80px, 100px) minmax(100px, 1fr) 1fr;
    padding: 10px 0;
    padding-right: 20px;
    grid-column-gap: 10px;
`;

export const ImageWrapper = styled.div``;

export const Image = styled.img`
    display: block;
    max-inline-size: 100%;
    block-size: auto;
    object-fit: cover;
    object-position: center;
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ColumnHeader = styled.p`
    color: #0b0b0b;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ColumnContent = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const DetailsColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

export const StyledToggler = styled.p`
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;

    display: flex;
    align-items: center;
    align-self: flex-end;
    white-space: nowrap;
`;
