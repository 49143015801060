import { VFC } from 'react';
import { ContentSpots } from '../../spots';
import { contentSpotTypes, umbraco } from '../../../lib/api';
import { FindDealershipsStore } from '../../../lib/state/find-dealership';
import { sortGeoLocations } from '../../../utils/helpers/geo-location.helpers';
import { DealershipUiLoader } from './dealership-ui-loader';
import { orgTypes } from '../../../lib/api/models/umbraco';

type ContactPageProps = {
    allDealerships: Array<orgTypes.DealershipWithGeoInfo>;
    config: orgTypes.DealershipConfig;
    emergencyPhoneNumbers: Array<orgTypes.DepartmentEmergencyNumbers>;
    dealershipAndTruckNumbers: Array<orgTypes.DealerShipAndTruck>;
    contentSpotSettings: contentSpotTypes.ContentSpotSettings[];
    pageId: string;
    metaMenu?: umbraco.PageMetaMenu;
};

export const ContactPage: VFC<ContactPageProps> = (props) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <FindDealershipsStore.Provider
                runtimeModel={{
                    locations: sortGeoLocations(props.allDealerships),
                    config: props.config,
                    emergencyPhoneNumbers: props.emergencyPhoneNumbers,
                    dealershipAndTruckNumbers: props.dealershipAndTruckNumbers,
                    renderGoogleMaps: true,
                }}
            >
                <DealershipUiLoader metaMenu={props.metaMenu} />
            </FindDealershipsStore.Provider>
            <ContentSpots contentSpotSettings={props.contentSpotSettings} context={{ pageId: props.pageId }} />
        </div>
    );
};
