import { FC, useMemo, useState } from 'react';
import { CheckBox } from '../../../shared';
import { DataPolicyLink, TermsAndConditions } from '../../book-test-drive-modal/book-test-drive-modal.styled';
import { validateEmail, validateName } from '../../../../utils/helpers';
import { ServiceAgreementCalculatorModalStore } from '../../../../lib/state/service-agreement-calculator/service-agreement-calculator.store';
import { StyledCheckboxLabel, StyledInputContainer } from './customer-info.styled';
import { TextInput } from '../../../forms/inputs';
import { StyledButton } from '../step/step.styled';
import { submitServiceAgreement } from '../../../../lib/api/service-agreement-calculator/hessel-service-agreement-calculator-api';
import { ServiceAgreementCalculatorStepThree } from '../../../../lib/api/models/umbraco';
import { CustomerInfoSummary } from './customer-info-summary/customer-info-summary.component';
import { CustomerInfo, ServiceAgreementType, Vehicle } from '../../../../lib/api/models/hessel-api';

type Props = {
    onNextStepClick: () => void;
    onHideEdit: React.Dispatch<React.SetStateAction<boolean>>;
    isOpen: boolean;
    isCompleted: boolean;
    stepSettings: ServiceAgreementCalculatorStepThree;
};

export const CustomerInfoStep: FC<Props> = ({ onNextStepClick, onHideEdit, isOpen, isCompleted, stepSettings }) => {
    const { customerInfo, vehicleInformation, selectedCompleteCare, selectedServiceCare, selectedWarrantyExtension } =
        ServiceAgreementCalculatorModalStore.useStoreState((state) => state);
    const { setCustomerInfo } = ServiceAgreementCalculatorModalStore.useStoreActions((actions) => actions);

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [canValidateFullName, setCanValidateFullName] = useState(false);
    const [canValidateEmail, setCanValidateEmail] = useState(false);
    const [canValidateMileage, setCanValidateMileage] = useState(false);
    const [canValidateDataPolicy, setCanValidateDataPolicy] = useState(false);

    const fullNameIsValid = useMemo(() => validateName(customerInfo.name), [customerInfo.name]);
    const emailIsValid = useMemo(() => validateEmail(customerInfo.email), [customerInfo.email]);
    const mileageIsValid = useMemo(() => customerInfo.mileage != null, [customerInfo.mileage]);

    const agreementType: ServiceAgreementType | undefined = useMemo(() => {
        if (selectedWarrantyExtension !== undefined) return 'WarrantyExtension';
        if (selectedServiceCare !== undefined) return 'ServiceCare';
        if (selectedCompleteCare !== undefined) return 'CompleteCare';
    }, [selectedCompleteCare, selectedServiceCare, selectedWarrantyExtension]);

    const onFormSubmitAsync = async () => {
        setCanValidateDataPolicy(true);
        setCanValidateEmail(true);
        setCanValidateFullName(true);
        setCanValidateMileage(true);
        setIsSubmitted(true);
        const selectedAgreement = selectedCompleteCare ?? selectedServiceCare ?? selectedWarrantyExtension;
        if (fullNameIsValid && emailIsValid && mileageIsValid && customerInfo.isPolicyAccepted && selectedAgreement && agreementType) {
            onHideEdit(true);

            await submitServiceAgreement({
                ...(vehicleInformation as Required<Vehicle>),
                ...(customerInfo as Required<CustomerInfo>),
                url: window.location.href,
                firstRegistrationDate: vehicleInformation.firstRegistrationDate,
                serviceAgreement: {
                    ...selectedAgreement,
                    agreementType,
                },
            });

            onNextStepClick();
        }
        setIsSubmitted(false);
    };

    return (
        <>
            {isCompleted && <CustomerInfoSummary customerInfo={customerInfo} stepSettings={stepSettings} />}
            {isOpen && (
                <>
                    <StyledInputContainer>
                        <TextInput
                            id="fullname"
                            label={stepSettings.nameInputLabel}
                            placeholder={stepSettings.nameInputPlaceholder}
                            type="name"
                            value={customerInfo.name}
                            canValidateInputField={canValidateFullName}
                            onInputBlur={() => setCanValidateFullName(true)}
                            validationMessage="Venligst indtast dit fulde navn"
                            isValid={fullNameIsValid}
                            onChange={(event) =>
                                setCustomerInfo({
                                    ...customerInfo,
                                    name: event.target.value,
                                })
                            }
                        />
                    </StyledInputContainer>
                    <StyledInputContainer>
                        <TextInput
                            id="email"
                            label={stepSettings.emailInputLabel}
                            placeholder={stepSettings.emailInputPlaceholder}
                            type="email"
                            value={customerInfo.email}
                            canValidateInputField={canValidateEmail}
                            validationMessage="Venligst indtast en gyldig e-mailadresse"
                            onInputBlur={() => setCanValidateEmail(true)}
                            isValid={emailIsValid}
                            onChange={(event) =>
                                setCustomerInfo({
                                    ...customerInfo,
                                    email: event.target.value,
                                })
                            }
                        />
                    </StyledInputContainer>
                    <StyledInputContainer>
                        <TextInput
                            id="mileage"
                            label={stepSettings.mileageInputLabel}
                            placeholder={stepSettings.mileageInputPlaceholder}
                            type="number"
                            value={customerInfo.mileage?.toString()}
                            canValidateInputField={canValidateMileage}
                            validationMessage="Venligst indtast din bils kilometerstand"
                            onInputBlur={() => setCanValidateMileage(true)}
                            isValid={mileageIsValid}
                            onChange={(event) =>
                                setCustomerInfo({
                                    ...customerInfo,
                                    mileage: event.target.valueAsNumber,
                                })
                            }
                        />
                    </StyledInputContainer>
                    <TermsAndConditions>
                        <StyledInputContainer>
                            <CheckBox
                                id="personal-data-policy"
                                value=""
                                checked={customerInfo.isPolicyAccepted}
                                onChange={(checked) =>
                                    setCustomerInfo({
                                        ...customerInfo,
                                        isPolicyAccepted: checked,
                                    })
                                }
                                textAlign="top"
                                isValid={customerInfo.isPolicyAccepted}
                                required={true}
                                validationMessage="Du skal acceptere vores persondatapolitik for at fortsætte"
                                canValidate={canValidateDataPolicy}
                            >
                                <StyledCheckboxLabel>
                                    {"Jeg godkender Ejner Hessel's "}
                                    <DataPolicyLink href={'/kontakt/kundeservice/persondatapolitik'} target="_blank" rel="noreferrer">
                                        persondatapolitik
                                    </DataPolicyLink>
                                </StyledCheckboxLabel>
                            </CheckBox>
                        </StyledInputContainer>
                        <StyledInputContainer>
                            <CheckBox
                                id="terms-policy"
                                value=""
                                checked={customerInfo.consent}
                                onChange={(checked) =>
                                    setCustomerInfo({
                                        ...customerInfo,
                                        consent: checked,
                                    })
                                }
                                textAlign="top"
                                isValid={true}
                            >
                                <StyledCheckboxLabel>{customerInfo.consentText}</StyledCheckboxLabel>
                            </CheckBox>
                        </StyledInputContainer>
                    </TermsAndConditions>
                    <StyledButton onClick={onFormSubmitAsync} variant="primary" loading={isSubmitted}>
                        {stepSettings.ctaText}
                    </StyledButton>
                </>
            )}
        </>
    );
};
