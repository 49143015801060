import { useMemo } from 'react';
import { ProductDetailsStore } from '../../../lib/state/hessel-site/product-details';
import { hesselViewModels } from '../../../lib/view-models';
import { formatPrice } from '../../../utils/helpers';
import { contactForPriceLabelText } from '../../../constants/site-consts';

type UseEquipmentDataReturnType = {
    getEquipmentPrice: (
        equipment: hesselViewModels.Equipment | hesselViewModels.EquipmentPackage,
        overridePriceTab?: hesselViewModels.OwnershipTab
    ) => {
        raw: number | undefined;
        formatted: string | undefined;
    };
};

/**
 *
 * @returns Gets the price for the selected equipment based on the selected ownership tab
 */
export const useEquipmentData = (storeSelectedTabOverride?: hesselViewModels.OwnershipTab): UseEquipmentDataReturnType => {
    const { selectedTab: storeSelectedTab } = ProductDetailsStore.useStoreState((state) => state);

    const selectedTab = useMemo(() => {
        return storeSelectedTabOverride ?? storeSelectedTab;
    }, [storeSelectedTab, storeSelectedTabOverride]);

    function getEquipmentPrice(
        equipment: hesselViewModels.Equipment | hesselViewModels.EquipmentPackage,
        overridePriceTab?: hesselViewModels.OwnershipTab
    ) {
        const tab = overridePriceTab ? overridePriceTab : selectedTab;
        const isMonthly = tab.includes('Leasing') || tab === 'Car HiRE';
        switch (tab) {
            case 'Car HiRE': {
                const hirePrice = equipment.monthlyHirePrice ?? 0;
                return {
                    raw: hirePrice,
                    formatted: `${formatPrice(hirePrice)} kr.${isMonthly ? '/md.' : ''}`,
                };
            }
            case 'Car Kontant': {
                const cashPrice = equipment.cashPrice ?? 0;
                return {
                    raw: cashPrice,
                    formatted: `${formatPrice(cashPrice)} kr.${isMonthly ? '/md.' : ''}`,
                };
            }
            case 'Car Privat Leasing': {
                if (equipment.monthlyPrivateLeasingPrice === -1) {
                    return {
                        raw: 0,
                        formatted: contactForPriceLabelText,
                    };
                }
                const monthlyPrivateLeasingPrice = equipment.monthlyPrivateLeasingPrice ?? 0;
                return {
                    raw: monthlyPrivateLeasingPrice,
                    formatted: `${formatPrice(monthlyPrivateLeasingPrice)} kr.${isMonthly ? '/md.' : ''}`,
                };
            }
            case 'Car Finansiering': {
                const carFinancing = equipment.cashPrice ?? 0;
                return {
                    raw: carFinancing,
                    formatted: `${formatPrice(carFinancing)} kr.${isMonthly ? '/md.' : ''}`,
                };
            }

            case 'Van Operationel Leasing': {
                if (equipment.monthlyOperationalLeasingPrice === -1) {
                    return {
                        raw: 0,
                        formatted: contactForPriceLabelText,
                    };
                }
                const vanOperationalLeasingPrice = equipment.monthlyOperationalLeasingPrice ?? 0;
                return {
                    raw: vanOperationalLeasingPrice,
                    formatted: `${formatPrice(vanOperationalLeasingPrice)} kr.${isMonthly ? '/md.' : ''}`,
                };
            }
            case 'Van Finansiel Leasing': {
                if (equipment.monthlyFinancialLeasingPrice === -1) {
                    return {
                        raw: 0,
                        formatted: contactForPriceLabelText,
                    };
                }
                const vanFinancialLeasingPrice = equipment.monthlyFinancialLeasingPrice ?? 0;
                return {
                    raw: vanFinancialLeasingPrice,
                    formatted: `${formatPrice(vanFinancialLeasingPrice)} kr.${isMonthly ? '/md.' : ''}`,
                };
            }
            case 'Van Kontant': {
                const vanCashPrice = equipment.cashPrice ?? 0;
                return {
                    raw: vanCashPrice,
                    formatted: `${formatPrice(vanCashPrice)} kr.${isMonthly ? '/md.' : ''}`,
                };
            }
            case 'Van Finansiering': {
                const vanFinancingPrice = equipment.cashPrice ?? 0;
                return {
                    raw: vanFinancingPrice,
                    formatted: `${formatPrice(vanFinancingPrice)} kr.${isMonthly ? '/md.' : ''}`,
                };
            }
            case 'No Value':
                throw new Error('No value for ownership tab');
            default: {
                return {
                    raw: 0,
                    formatted: '0',
                };
            }
        }
    }

    return {
        getEquipmentPrice,
    };
};
