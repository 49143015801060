import styled from 'styled-components';
import { Button } from '../../shared';

export const StyledHeader = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: 15px;
`;

export const SectionHeader = styled.p<{ spacing?: string }>`
    color: #0b0b0b;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    margin-bottom: 10px;
    margin: ${(props) => props.spacing};
    line-height: 26px;
`;

export const StyledText = styled.p<{ spacing?: string }>`
    color: #0b0b0b;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0;
    margin: ${(props) => props.spacing};
`;

export const TermsAndConditions = styled.div``;

export const InputsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;
`;

export const StyledSubmitButton = styled(Button)`
    margin-top: 20px;
`;

export const DataPolicyLink = styled.a`
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
`;
