import { FC, useEffect, useState } from 'react';
import { useSpring, config, animated } from 'react-spring';
import { useMeasure } from 'react-use';
import styled from 'styled-components';
import { useMediaQuery } from '../../../../../hooks/use-media-query';

type ComplianceTextProps = {
    className?: string;
    text: string;
};

export const ComplianceText: FC<ComplianceTextProps> = ({ className, text }) => {
    const isMobile = useMediaQuery({ target: 'tablet' });

    const defaultHeight: string | undefined = '40px';

    // Gets the height of the element (ref)
    const [heightRef, { height }] = useMeasure();

    const [contentHeight, setContentHeight] = useState(defaultHeight || height);

    useEffect(() => {
        //Sets initial height
        setContentHeight(height);
    }, [height]);

    const [showEntireText, setShowEntireText] = useState(false);

    // Animations
    const expand = useSpring({
        config: { ...config.slow, friction: 50 },
        height: showEntireText ? `${+contentHeight + 20}px` : defaultHeight,
        overflow: !showEntireText ? 'hidden' : '',
    });

    return isMobile ? (
        <animated.div style={expand}>
            <StyledComplianceText className={className} ref={heightRef as any}>
                {showEntireText ? text : `${text.slice(0, 75)}...`}{' '}
                <StyledReamMore onClick={() => setShowEntireText(!showEntireText)}>{showEntireText ? 'Læs mindre' : 'Læs mere'}</StyledReamMore>
            </StyledComplianceText>
        </animated.div>
    ) : (
        <StyledComplianceText className={className}>{text}</StyledComplianceText>
    );
};

const sharedStyles = {
    color: '#807f80',
    'font-size': '11px',
    'letter-spacing': 0,
    'line-height': '18px',
    'font-weight': 'normal',
};

export const StyledComplianceText = styled.p`
    ${sharedStyles};
`;

export const StyledReamMore = styled.span`
    ${sharedStyles};
    text-decoration: underline;
    cursor: pointer;
`;
