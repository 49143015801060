import React, { FC, useCallback, useMemo, useState } from 'react';
import { umbraco } from '../../lib/api';
import { StyledButtonContainer, StyledStarRatingContainer } from './sales-video.styled';
import { ContentSpots } from '../spots';
import { GlobalProductListSettings } from '../../lib/api/models/umbraco';
import StarRating from '../shared/star-rating/star-rating.component';
import { Button } from '../shared';
import EmployeeContactInfoCard from '../shared/employee-contact-info-card/employee-contact-info-card.component';
import { DynamicContentBoxes } from '../../lib/api/models/umbraco/content-spot';
import { submitActivity, submitRating } from '../../lib/api/sales-video/hessel-sales-video-api';
import { SalesVideoData } from '../../lib/api/models/hessel-api/sales-video';

type Props = {
    page: umbraco.SalesVideoPage;
    context: {
        pageId: string;
    };
    globalPlpSettings?: GlobalProductListSettings;
    salesVideo: SalesVideoData;
};

const SalesVideoPage: FC<Props> = ({ page, context, globalPlpSettings, salesVideo }) => {
    const [selectedRating, setSelectedRating] = useState(salesVideo?.rating ?? 0);
    const [submittedRating, setSubmittedRating] = useState(salesVideo?.rating ?? 0);
    const [ratingSubmittedThisSession, setRatingSubmittedThisSession] = useState(false);

    const greetingContentSpotsSettings = useMemo(() => {
        const spots = page.greetingContentSpotsSettings;
        const dynamicContentBoxes = spots.filter((s) => s.alias === 'dynamicContentBoxes') as DynamicContentBoxes[];
        const firstDynamicContentBox = dynamicContentBoxes[0];
        const secondDynamicContentBox = dynamicContentBoxes.length > 1 ? dynamicContentBoxes[1] : undefined;
        if (!firstDynamicContentBox || !secondDynamicContentBox || !salesVideo?.employee) return spots;

        const videoContentBox = firstDynamicContentBox.contentBoxes.find(() => true);
        if (!videoContentBox) return spots;
        videoContentBox.mediaFile = {
            src: salesVideo.videoLink,
            type: 'file',
            extension: '',
            name: '',
            id: 0,
        };
        const greetingContentBox = secondDynamicContentBox.contentBoxes.find(() => true);
        if (!greetingContentBox || !salesVideo.department) return spots;
        greetingContentBox.subtitle = `${salesVideo.employee?.name}, Rådgiver, ${salesVideo.department?.displayName}`;

        return spots;
    }, [page.greetingContentSpotsSettings, salesVideo.department, salesVideo.employee, salesVideo.videoLink]);

    const onRatingSubmit = useCallback(async () => {
        setSubmittedRating(selectedRating);
        setRatingSubmittedThisSession(true);
        if (!salesVideo?.id) return;

        await submitActivity({ videoDetailId: salesVideo.id, activityType: 'RatedByCustomer' });
        await submitRating({ videoDetailId: salesVideo.id, rating: selectedRating });
    }, [salesVideo?.id, selectedRating]);

    return (
        <>
            <ContentSpots contentSpotSettings={greetingContentSpotsSettings} globalPlpSettings={globalPlpSettings} context={context}></ContentSpots>
            <StyledStarRatingContainer>
                <StarRating
                    spaceBetweenStars={3}
                    onChangeValue={setSelectedRating}
                    starWidth={30}
                    numberOfStars={5}
                    currentRating={selectedRating}
                    submittedRating={submittedRating}
                />
                {submittedRating === 0 ? (
                    <StyledButtonContainer>
                        <Button variant="dark" disabled={selectedRating === 0} onClick={onRatingSubmit}>
                            {page.ctaButtonText}
                        </Button>
                    </StyledButtonContainer>
                ) : null}

                {ratingSubmittedThisSession ? <div>Tak for din bedømmelse af vores video</div> : null}
            </StyledStarRatingContainer>
            <ContentSpots contentSpotSettings={page.contentSpotsSettings} globalPlpSettings={globalPlpSettings} context={context}></ContentSpots>
            <EmployeeContactInfoCard
                employee={salesVideo?.employee}
                department={salesVideo.department}
                title={'Kontakt din rådgiver'}
                subTitle={'Herunder kan du finde navn og kontaktoplysninger på din personlige rådgiver.'}
            ></EmployeeContactInfoCard>
        </>
    );
};

export default SalesVideoPage;
