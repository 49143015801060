import styled from 'styled-components';

export const StyledCheckboxLabel = styled.span`
    line-height: 1.2rem;
`;

export const StyledInputContainer = styled.div`
    margin: 15px 0;
`;

export const StyledSummaryCard = styled.div`
    background-color: white;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.palette.grey.medium};
    padding: 10px;
    font-weight: 800;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
`;
