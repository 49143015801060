import { useEffect, useState, VFC } from 'react';
import { animated, config, useSpring } from 'react-spring';
import { useMeasure } from 'react-use';
import { hesselViewModels } from '../../../../lib/view-models';
import { Button } from '../../../shared';
import { StyledText, ButtonText } from './collapsible-item.styled';
import { ImageWrapper, StyledImage } from '../equipment-dialog.styled';
import { SimpleCarousel } from '../../../shared/carousels/simple-carousel/simple-carousel.component';

type CollapsibleItemProps = {
    name: string;
    description?: string;
    resources: Array<hesselViewModels.EquipmentResource>;
};

export const CollapsibleItem: VFC<CollapsibleItemProps> = ({ name, description, resources }) => {
    const defaultHeight: string | undefined = '0px';

    // Gets the height of the element (ref)
    const [heightRef, { height }] = useMeasure();

    const [contentHeight, setContentHeight] = useState(defaultHeight || height);

    useEffect(() => {
        //Sets initial height
        setContentHeight(height);
    }, [height]);

    const [isOpen, setIsOpen] = useState(false);

    // Animations
    const expand = useSpring({
        config: { ...config.slow, friction: 50 },
        height: isOpen ? `${contentHeight}px` : defaultHeight,
        overflow: !isOpen ? 'hidden' : '',
    });

    return (
        <>
            <StyledText>
                {name}{' '}
                <Button variant="link" onClick={() => setIsOpen(!isOpen)}>
                    <ButtonText>{isOpen ? 'Vis mindre' : 'Vis mere'}</ButtonText>
                </Button>
            </StyledText>
            <animated.div style={expand}>
                <div ref={heightRef as any}>
                    <StyledText>{description}</StyledText>

                    {resources.length === 1 ? (
                        <ImageWrapper>
                            <StyledImage alt={resources[0].name} src={resources[0].data} />
                        </ImageWrapper>
                    ) : null}

                    {resources.length > 1 ? (
                        <SimpleCarousel
                            slides={resources.map((r) => {
                                return {
                                    id: r.id,
                                    type: 'image',
                                    url: r.id,
                                    caption: r.name,
                                };
                            })}
                            isAboveFold={false}
                        />
                    ) : null}
                </div>
            </animated.div>
        </>
    );
};
