import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const StyledDealershipMap = styled.div`
    width: 100%;
    height: 508px;

    @media ${device.tablet} {
        height: 735px;
    }

    & div[role='dialog'].gm-style-iw {
        padding: 26px 22px !important;
        padding-bottom: 7px !important;
        border-radius: 0;
        max-width: revert !important;

        & button {
            top: 4px !important;
            right: 6px !important;

            & > span {
                width: 17px !important;
                height: 17px !important;
            }
        }
    }
`;
