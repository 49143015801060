import styled from 'styled-components';
export const NavigatorWrapper = styled.div`
    display: flex;
    align-self: baseline;
    gap: 1rem;
    margin: 2rem 0;
    cursor: pointer;
    width: max-content;
`;

export const NavigatorText = styled.p`
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    font-size: 17px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
    letter-spacing: 0;
    line-height: 27px;
`;
