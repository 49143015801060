import { VFC } from 'react';
import { sharedTypes } from '../../../lib/api/models/umbraco';
import { cmsUrlWithoutSiteId, isExternalLink } from '../../../utils/helpers';
import { StyledLink } from './custom-link.styled';
import Link from 'next/link';

type Props = {
    url: sharedTypes.CtaUrl;
    urlText: string;
};

export const CustomLink: VFC<Props> = ({ url, urlText }) => {
    const urlWithoutSiteId = cmsUrlWithoutSiteId(url.url);

    return isExternalLink(url) ? (
        <StyledLink href={urlWithoutSiteId} target="_blank">
            {urlText}
        </StyledLink>
    ) : (
        <Link href={urlWithoutSiteId} passHref={true}>
            <StyledLink>{urlText}</StyledLink>
        </Link>
    );
};
