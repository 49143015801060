import { FC, useEffect, useState } from 'react';
import { getParentColorAssets } from '../../../../../lib/api/vehicle/hessel-vehicle-plp-api';
import { FilterUpdateNotification, ParentColor } from '../../../../../lib/api/models/hessel-api/hire';
import { FacetOption } from '../../../../../lib/view-models/vehicle';
import { StyledColorOptionWrapper } from './color-filter.styled';
import { ColorOption } from './color-option/color-option.component';

type IProps = {
    facetOptions: FacetOption[];
    onChange: (filterUpdate: FilterUpdateNotification[]) => void;
};

export const ColorFilter: FC<IProps> = ({ facetOptions, onChange }) => {
    const [parentColorAssets, setParentColorAssets] = useState<ParentColor[]>([]);
    useEffect(() => {
        const getParentColors = async () => {
            const [result, error] = await getParentColorAssets();
            if (result && !error) {
                setParentColorAssets(result);
            }
        };
        getParentColors();
    }, []);

    return (
        <StyledColorOptionWrapper>
            {facetOptions.map((x) => {
                const colorAsset = parentColorAssets.find((y) => y.name === x.key);
                return <ColorOption key={x.key} option={x} colorAsset={colorAsset} onChange={(updates) => onChange(updates)} />;
            })}
        </StyledColorOptionWrapper>
    );
};
