import router from 'next/router';
import { FC, useEffect, useRef } from 'react';
import { CSSProperties } from 'styled-components';
import { cmsUrlWithoutSiteId, sanitizeMarkup } from '../../../utils/helpers';
import { StyledRichTextContent } from './content-spot-rich-text.styled';

type ContentSpotRichTextProps = {
    className?: string;
    text: string;
    style?: CSSProperties;
    width?: string;
};

/**
 *
 * @param param0 Important note:
 * By binding an optional className prop to the component, we can use it to style the component using styled-components.
 * Example: const StyledContentSpotRichText = styled(ContentSpotRichText)`font-size: 1.5rem; color: red;`;
 * @returns
 */
export const ContentSpotRichText: FC<ContentSpotRichTextProps> = ({ className, style, text, width }) => {
    const container = useRef<HTMLDivElement>(null);
    const isLinkInternal = (link: string): boolean => {
        const base = new URL(`${window.location.protocol}//${window.location.host}`);
        return new URL(link, base).hostname === base.hostname;
    };
    useEffect(() => {
        if (container.current) {
            container.current.querySelectorAll('a').forEach((link) => {
                const linkHref = link.attributes.getNamedItem('href')?.textContent ?? null;
                const linkTarget = link.attributes.getNamedItem('target')?.textContent ?? null;
                if (linkHref && isLinkInternal(linkHref)) {
                    link.href = cmsUrlWithoutSiteId(linkHref);
                    link.onclick = (e) => {
                        if (linkTarget === '_blank') {
                            window.open(link.href, '_blank');
                        } else {
                            e.stopPropagation();
                            e.preventDefault();
                            router.push(cmsUrlWithoutSiteId(linkHref));
                        }
                    };
                }
            });
        }
    }, [container]);

    return <StyledRichTextContent ref={container} style={style} className={className} width={width} {...sanitizeMarkup(text)} />;
};
