import styled from 'styled-components';
import { device } from '../../../../lib/media-query';
import { Button } from '../../../shared';

export const StyledOrderSummary = styled.aside`
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 10px 0px;
    padding: 15px 20px;
    position: sticky;
    top: 0;
    order: 0;
    @media ${device.laptopS} {
        padding: 30px;
        top: 40px;
        order: 1;
    }
`;

export const StyledMobileSummaryHeader = styled.p`
    font-size: 17px;
    font-weight: 700;
`;

export const StyledSummaryHeader = styled.p`
    font-weight: 700;
    font-size: 20px;
    border-bottom: 1px solid #e2e2e4;
    margin-bottom: 25px;
    padding-bottom: 25px;
`;

export const StyledMobileSummary = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media ${device.laptopS} {
        display: none;
    }
`;

export const StyledMobileTotal = styled.p`
    font-size: 12px;
`;

export const StyledDesktopSummary = styled.div`
    display: none;
    @media ${device.laptopS} {
        display: block;
    }
`;

export const StyledOpenMobileSummaryButton = styled.p`
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 15px;
    color: #0089d1;
    font-size: 12px;
`;

export const StyledSidePanel = styled.div`
    padding: 30px 20px;
`;

export const StyledSidePanelClose = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
`;

export const StyledInfoWrapper = styled.div`
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 10px 0px;
    padding: 25px 20px;
`;

export const StyledCtaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;
`;
export const StyledMobileSummaryCta = styled(Button)`
    width: 100%;
`;
