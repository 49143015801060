import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const Header = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: 14px;
`;

export const StyledDescription = styled.div`
    & p {
        color: #0b0b0b;
        font-size: 14px;
        letter-spacing: 0;
        font-weight: normal;
    }
`;

export const HighlightedText = styled.p`
    color: #0b0b0b;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
`;

export const StyledText = styled.p`
    color: #0a2841;
    font-size: 14px;
    letter-spacing: 0;
    text-align: center;
    font-weight: normal;
`;

export const DescriptionAndMedia = styled.div``;

export const MediaWrapper = styled.div`
    margin-top: 15px;
    padding-bottom: 15px;
`;

export const StyledImage = styled.img`
    display: block;
    max-inline-size: 100%;
    block-size: auto;
    object-fit: contain;
    object-position: center;

    @media ${device.tablet} {
        max-inline-size: 530px;
    }
`;

export const StyledVideo = styled.video`
    object-fit: contain;
    width: 100%;
    aspect-ratio: 16 / 9;

    @media ${device.tablet} {
        width: 550px;
    }
`;

export const DisclaimersWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 49px;
`;

export const StyledDisclaimer = styled.p`
    color: #807f80;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 12px;
`;

export const ReadMoreContent = styled.div`
    margin-bottom: 41px;
`;
