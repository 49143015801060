import styled from 'styled-components';
import { device } from '../../../../../lib/media-query';

export const ReceiptWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media ${device.tablet} {
        padding: 20px 0px;
    }
`;

export const StyledHeader = styled.h2`
    color: #0b0b0b;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0;
`;

export const StyledText = styled.p`
    margin-bottom: 2rem;
`;

export const NotesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 2rem 0;
`;
