import { action } from 'easy-peasy';
import { createContextStoreWithRuntimeModel } from '../../helpers/context-store.helpers';
import { FormInfoWithId, FormManagerActions, FormManagerState, FormManagerStore } from './forms-display-manager.types';

const formsManagerDefaultState = (runtimeModel: Partial<FormManagerState> | undefined): FormManagerState => ({
    formList: runtimeModel?.formList ?? ({} as Array<FormInfoWithId>),
});

const formsManagerActions = (): FormManagerActions => ({
    setFormVisibility: action((state, payload) => {
        state.formList = state.formList?.map((x) => {
            if (x.form.id === payload.id) {
                return {
                    ...x,
                    isVisible: payload.value,
                };
            }
            return x;
        });
    }),
});

export const FormsManagerStore = createContextStoreWithRuntimeModel<FormManagerStore, Partial<FormManagerState>>(
    (runtimeModel) => ({
        ...formsManagerDefaultState(runtimeModel),
        ...formsManagerActions(),
    }),
    { name: 'formsManagerStore' }
);
