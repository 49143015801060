import { GtmItem, GtmTransaction } from '../../../gtm-tracking/gtm-tracking.types';
import { ReepayAgreement, ReepayPlan } from '../../../lib/api/models/reepay';
import { ReepayGtmAreas } from '../../../lib/api/models/umbraco';

const CATEGORY = {
    PLUS: 'Hessel Plus',
    STARPLUS: 'Hessel Star Plus',
};

const TAX_MODIFIER = 0.2;

function gtmCategoryFromArea(area: ReepayGtmAreas): string {
    return area === ReepayGtmAreas.Plus ? CATEGORY.PLUS : CATEGORY.STARPLUS;
}

export function createAffiliation(area: ReepayGtmAreas, id: string, revenue?: number): GtmTransaction {
    return {
        id,
        affiliation: gtmCategoryFromArea(area),
        revenue,
        tax: revenue && revenue * TAX_MODIFIER,
    };
}

export function createGtmProduct(area: ReepayGtmAreas, agreement: ReepayAgreement, plan: ReepayPlan): GtmItem {
    return {
        id: agreement.reepayAccountId,
        name: agreement.agreementName,
        category: gtmCategoryFromArea(area),
        price: plan.amount,
        quantity: 1,
        variant: plan.gtmTrackAs,
    };
}
