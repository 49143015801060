import { FC, useMemo } from 'react';
import { hesselApi } from '../../../../../lib/api';
import { BookingFormStore } from '../../../../../lib/state/booking/form';
import { generateFormId } from '../../../../../lib/state/booking/form/booking-form.helpers';
import { storeInputTypes } from '../../../../../lib/state/_shared';
import { formInputValue, removeDuplicateProducts } from '../../../../../utils/helpers';
import { SubmittedInfo } from '../../../../shared/submitted-info';
import { InfoWrapper } from './service-summary.styled';

type Props = {
    selectedProducts: hesselApi.ServiceProduct[];
    selectedProductOptions?: hesselApi.ServiceProduct[];
    freeServiceLabel: string;
    comment?: string;
};

const ServiceSummary: FC<Props> = ({ selectedProducts, selectedProductOptions, freeServiceLabel, comment }) => {
    const { drivenKilometers, drivenKilometersLabel } = BookingFormStore.useStoreState(({ inputs }) => {
        const input = inputs.find((input) => input.id === generateFormId('Services', 'Driven Kilometers')) as
            | storeInputTypes.SingleFormInput<string>
            | undefined;
        const drivenKilometers = input ? formInputValue(input) : null;

        return {
            drivenKilometers,
            drivenKilometersLabel: input?.label,
        };
    });

    const getPrice = (price?: number): string | undefined => {
        if (price === 0) return freeServiceLabel;
        if (price == null) return undefined;

        return `${price} kr`;
    };

    const productsToDisplay = useMemo(() => {
        const distinctSelectedProducts = removeDuplicateProducts(selectedProducts); // This is necessary because "Dækopbevaring" is a duplicated product
        let allSelected = (selectedProductOptions ?? []).concat(distinctSelectedProducts);
        const productsWithOptions = allSelected.filter((product) => (product.productOptions?.length ?? 0) > 0);

        for (const product of productsWithOptions) {
            const defaultSelected = selectedProductOptions?.some((option) => product.defaultOption?.id === option.id);
            if (defaultSelected) allSelected = allSelected.filter((selectedProduct) => selectedProduct.id !== product.defaultOption?.id);

            const anyOptionIsSelected = selectedProductOptions?.some((selectedOption) =>
                product.productOptions?.some((option) => option.id === selectedOption.id)
            );
            if (anyOptionIsSelected) allSelected = allSelected.filter((selectedProduct) => selectedProduct.id !== product.id);
        }

        return allSelected;
    }, [selectedProductOptions, selectedProducts]);

    return (
        <InfoWrapper>
            {productsToDisplay.map((serviceProduct, idx) => (
                <SubmittedInfo
                    key={`${idx}:${serviceProduct.id}`}
                    label={serviceProduct.name}
                    text={getPrice(serviceProduct.price?.value) ?? serviceProduct?.description}
                />
            ))}
            {drivenKilometers && drivenKilometersLabel ? <SubmittedInfo label={drivenKilometersLabel} text={drivenKilometers} /> : null}
            {comment && <SubmittedInfo label="Kommentar" text={comment} />}
        </InfoWrapper>
    );
};

export default ServiceSummary;
