import React, { forwardRef, ReactNode } from 'react';
import { uiTypes } from '../../../../lib';
import { StyledSpotHeader } from './spot-header.styled';
import { useSpotHeader } from './use-spot-header';

type SpotHeaderProps = {
    className?: string;
    headerType?: uiTypes.HeaderType;
    headerSize?: uiTypes.HeaderSize;
    children?: ReactNode;
    style?: React.CSSProperties;
};

export const SpotHeader = forwardRef<HTMLHeadingElement, SpotHeaderProps>(({ className, headerType, headerSize, children, style }, ref) => {
    const { headerSizes } = useSpotHeader(headerSize);

    return (
        <StyledSpotHeader ref={ref} className={className} as={headerType} headerSize={headerSizes} style={style}>
            {children}
        </StyledSpotHeader>
    );
});

SpotHeader.displayName = 'SpotHeader';
