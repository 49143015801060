import { action } from 'easy-peasy';
import { HireStepState, HireStepStore } from '.';
import { createContextStoreWithRuntimeModel } from '../../../helpers/context-store.helpers';

export const LeadCheckoutStepsStore = createContextStoreWithRuntimeModel<HireStepStore, HireStepState>(
    (runtimeModel) => ({
        steps: runtimeModel?.steps ?? [],
        currentStep: runtimeModel?.currentStep ?? 0,
        completeStep: action((state, payload) => {
            state.steps[payload].isComplete = true;
            state.currentStep++;
        }),
        editStep: action((state, payload) => {
            state.currentStep = payload;
            state.steps[payload].isComplete = false;
            state.steps.forEach((x, index) => {
                if (index > payload) {
                    x.isComplete = false;
                    x.isValid = false;
                }
            });
        }),
        setStepValidity: action((state, payload) => {
            state.steps[payload.stepNumber].isValid = payload.isValid;
        }),
        setCurrentStepLoading: action((state, payload) => {
            state.steps[payload.stepNumber].isStepLoading = payload.isLoading;
        }),
        setCanValidateHireStep: action((state, payload) => {
            state.steps[payload.stepNumber].canValidateHireStep = payload.validateStep;
        }),
    }),
    { name: 'hireCheckoutSteps' }
);
