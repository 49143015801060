import { FC, useState } from 'react';
import { hesselViewModels } from '../../../lib/view-models';
import { next } from '../../../utils/helpers/incremental-integer.helper';

import {
    StyledAutoDesktopCampaignLabel,
    StyledLabelsWrapper,
    StyledProductDetailsBrandHeading,
    StyledProductDetailsVehicleAvailability,
    StyledProductDetailsEnergyRating,
    StyledProductDetailsHeader,
    StyledProductDetailsLocation,
    StyledProductDetailsVariantHeading,
    StyledDimensionLabel,
} from './product-details-header.styled';
import { CampaignList } from '../../shared/campaign-list/campaign-list.component';

export type ProductDetailsHeaderProps = {
    availability?: hesselViewModels.VehicleAvailability;
    vehicleType?: hesselViewModels.VehicleType;
    brandTitle: string;
    className?: string;
    energyRating?: hesselViewModels.EnergyRating;
    variantTitle: string;
    vanAutoDesktopCampaignLabels: Array<hesselViewModels.AutoDesktopLabel>;
    campaigns?: Array<hesselViewModels.ProductCampaign>;
    dimensions: {
        height: string;
        length: string;
    };
    location?: string;
    onLocationClick?: () => void;
    modelTitle?: string;
};

export const ProductDetailsHeader: FC<ProductDetailsHeaderProps> = ({
    className,
    brandTitle,
    dimensions,
    vanAutoDesktopCampaignLabels,
    variantTitle,
    availability,
    energyRating,
    campaigns,
    vehicleType,
    location,
    onLocationClick,
    modelTitle,
}) => {
    const [brandDomId] = useState(`product-details-header-brand-${next()}`);
    const [variantDomId] = useState(`product-details-header-variant-${next()}`);

    return (
        <StyledProductDetailsHeader aria-describedby={brandDomId} aria-labelledby={variantDomId} className={className}>
            <StyledProductDetailsVariantHeading id={variantDomId}>
                <StyledProductDetailsBrandHeading id={brandDomId}>
                    {brandTitle}
                    {modelTitle && modelTitle.length ? ` ${modelTitle}` : ''}
                </StyledProductDetailsBrandHeading>{' '}
                {variantTitle}
            </StyledProductDetailsVariantHeading>
            <StyledLabelsWrapper>
                {energyRating ? <StyledProductDetailsEnergyRating rating={energyRating} /> : null}
                {availability && vehicleType ? (
                    <StyledProductDetailsVehicleAvailability availability={availability} vehicleType={vehicleType} />
                ) : null}

                {(availability === 'Used' || availability === 'Engros') && location ? (
                    <StyledProductDetailsLocation onClick={() => onLocationClick?.()}>{location}</StyledProductDetailsLocation>
                ) : null}

                {vehicleType === 'Van' && (availability === 'Used' || availability === 'Engros')
                    ? vanAutoDesktopCampaignLabels.map((x, index) => (
                          <StyledAutoDesktopCampaignLabel
                              key={`van-auto-desktop-campaign-${index}`}
                              backgroundColor={'#' + x.backgroundColor}
                              color={'#' + x.textColor}
                          >
                              {x.text}
                          </StyledAutoDesktopCampaignLabel>
                      ))
                    : null}

                {vehicleType === 'Van' && availability === 'Order' ? (
                    <>
                        {dimensions.length ? <StyledDimensionLabel color="Dark">{dimensions.length}</StyledDimensionLabel> : null}
                        {dimensions.height ? <StyledDimensionLabel color="Gray">{dimensions.height}</StyledDimensionLabel> : null}
                    </>
                ) : null}

                <CampaignList campaigns={campaigns ?? []} />
            </StyledLabelsWrapper>
        </StyledProductDetailsHeader>
    );
};
