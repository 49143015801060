import { useMemo, VFC } from 'react';
import { hesselViewModels } from '../../../lib/view-models';
import EquipmentCard from '../product-details-page/equipment-card/equipment-card.components';
import ProductDetailsCardComponent from '../product-details-page/shared/product-details-card.component';

export type StandardEquipmentProps = {
    availability: hesselViewModels.VehicleAvailability;
    standardEquipmentList?: Array<hesselViewModels.Equipment>;
    standardEquipmentPackages?: Array<hesselViewModels.EquipmentPackage>;
    visibleAmount?: number;
    showAllEquipment: () => void;
    equipmentNames: Array<string>;
    header?: string;
    description?: string;
};

export const StandardEquipment: VFC<StandardEquipmentProps> = ({
    availability,
    standardEquipmentList,
    standardEquipmentPackages,
    visibleAmount = 6,
    showAllEquipment,
    equipmentNames,
    header,
    description,
}) => {
    const numberOfEquipmentToDisplay = useMemo(() => {
        if (!standardEquipmentPackages) {
            return standardEquipmentList?.length ?? 0;
        }
        return standardEquipmentPackages.length > visibleAmount ? 0 : Math.abs(visibleAmount - standardEquipmentPackages.length);
    }, [standardEquipmentList?.length, standardEquipmentPackages, visibleAmount]);

    const numberOfEquipmentPackagesToDisplay = useMemo(() => {
        if (!standardEquipmentPackages) {
            return 0;
        }
        if (standardEquipmentPackages.length > visibleAmount) {
            return visibleAmount;
        }
        return standardEquipmentPackages.length;
    }, [standardEquipmentPackages, visibleAmount]);

    const numberOfEquipmentNamesToDisplay = useMemo(() => {
        if (!equipmentNames) {
            return 0;
        }
        if (equipmentNames.length > visibleAmount) {
            return visibleAmount;
        }
        return equipmentNames.length;
    }, [equipmentNames, visibleAmount]);

    const orderSpecsList = useMemo(() => {
        const standardEquipment =
            standardEquipmentList
                ?.filter(Boolean)
                .slice(0, numberOfEquipmentToDisplay)
                .map((eq) => eq.name) ?? [];
        const standardEquipmentPackagesList =
            standardEquipmentPackages
                ?.filter(Boolean)
                .slice(0, numberOfEquipmentPackagesToDisplay)
                .map((eq) => eq.name ?? '') ?? [];

        return standardEquipment.concat(standardEquipmentPackagesList);
    }, [standardEquipmentList, standardEquipmentPackages, numberOfEquipmentToDisplay, numberOfEquipmentPackagesToDisplay]);

    const usedSpecsList = useMemo(() => {
        return equipmentNames.slice(0, numberOfEquipmentNamesToDisplay);
    }, [equipmentNames, numberOfEquipmentNamesToDisplay]);

    const showMoreRelevant = useMemo(() => {
        if (availability === 'Used' || availability === 'Engros') return equipmentNames.length > usedSpecsList.length;
        return (standardEquipmentList?.length ?? 0) + (standardEquipmentPackages?.length ?? 0) > orderSpecsList.length;
    }, [
        availability,
        equipmentNames.length,
        orderSpecsList.length,
        standardEquipmentList?.length,
        standardEquipmentPackages?.length,
        usedSpecsList.length,
    ]);

    const itemsList = useMemo(
        () => (availability === 'Used' || availability === 'Engros' ? usedSpecsList : orderSpecsList),
        [availability, orderSpecsList, usedSpecsList]
    );

    if (itemsList.length === 0) return null;

    return (
        <ProductDetailsCardComponent>
            <EquipmentCard
                items={itemsList}
                onShowSpecsClick={() => {
                    showAllEquipment();
                }}
                header={header}
                description={description}
                showMoreRelevant={showMoreRelevant}
            />
        </ProductDetailsCardComponent>
    );
};
