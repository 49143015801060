import { FC, useState } from 'react';
import { SvgIcon } from '../../../shared/svg-icon';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import { ShopFacetGroup } from '../../../../lib/api/models/shop/product-list';
import { FilterUpdateNotification } from '../../../../lib/api/models/hessel-api/hire';
import { ShopProductListMobileFilters } from './shop-product-list-mobile-filters.component';
import { SortingLabel } from '../../../../lib/api/models/umbraco';
import {
    MobileFilterIndicator,
    MobileFilterToggle,
    MobileFilterToggleButton,
} from '../../../vehicle/vehicle-product-list/vehicle-product-list-filter/vehicle-product-list-filter.styled';

type IProps = {
    facets: ShopFacetGroup[];
    isProductlistInViewport: boolean;
    filterUpdate: (updates: FilterUpdateNotification[]) => void;
    resetFilters: () => void;
    sortOptions: SortingLabel[];
    selectedSorting: string;
    updateSorting: (sorting: string) => void;
    activeFiltersCount: number;
};

export const ProductListFilterMobile: FC<IProps> = ({
    facets,
    isProductlistInViewport,
    filterUpdate,
    resetFilters,
    sortOptions,
    selectedSorting,
    updateSorting,
    activeFiltersCount,
}) => {
    const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
    const [sortingOpen, setSortingOpen] = useState(false);
    const isDevice = useMediaQuery({ target: 'smallDesktop' });
    return (
        <>
            <MobileFilterToggle visible={isProductlistInViewport}>
                <MobileFilterToggleButton onClick={() => setMobileFiltersOpen(true)}>
                    <SvgIcon iconName="filter" color="light" />
                    <span>Filter</span>
                    <MobileFilterIndicator>{activeFiltersCount}</MobileFilterIndicator>
                </MobileFilterToggleButton>
                <MobileFilterToggleButton
                    onClick={() => {
                        setMobileFiltersOpen(true);
                        setSortingOpen(true);
                    }}
                >
                    <span>Sorter</span>
                    <MobileFilterIndicator>Vælg sortering</MobileFilterIndicator>
                </MobileFilterToggleButton>
            </MobileFilterToggle>
            {isDevice ? (
                <ShopProductListMobileFilters
                    setOpenState={(state: boolean) => setMobileFiltersOpen(state)}
                    openState={mobileFiltersOpen}
                    applyMobileFiltersText={'ANVEND'}
                    closeMobileFilterOverlay={'LUK'}
                    clearFiltersText={'RYD FILTRE'}
                    facets={facets ?? []}
                    resetFilters={() => resetFilters()}
                    updateFilters={(updates) => filterUpdate(updates)}
                    selectedSortOption={selectedSorting && selectedSorting.length > 0 ? selectedSorting : 'Vælg sortering'}
                    sortingOptions={sortOptions}
                    sortingOpenExternal={sortingOpen}
                    updateSorting={(sorting) => updateSorting(sorting)}
                />
            ) : null}
        </>
    );
};
