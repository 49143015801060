import styled from 'styled-components';

export const StyledBulletPointImageContainer = styled.div`
    width: 25px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    margin-right: 20px;
`;

export const StyledBulletPoint = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledBulletPointList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
`;

export const StyledBulletPointValue = styled.p`
    font-weight: bold;
`;

export const StyledBulletPointLabel = styled.label`
    font-size: 15px;
`;
