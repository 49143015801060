import styled from 'styled-components';
import { device } from '../../../../lib/media-query';
import { EnergyRating } from '../../../product-details/energy-rating/energy-rating.component';
import { Button, KeyValueTable } from '../../../shared';
import { BundleCard } from '../../_shared';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;

    padding: 50px;
    padding-right: 30px;
`;

export const CardsList = styled.div`
    & > article:first-of-type {
        border-top: 1px solid #d1d2d4;
    }

    & > div,
    & > article {
        border-bottom: 1px solid #d1d2d4;
    }
`;

export const HeaderSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 0.9rem;
`;

export const Header = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-weight: bold;
    white-space: nowrap;

    font-size: 16px;
    @media ${device.tablet} {
        font-size: 25px;
    }
`;

export const Price = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    text-align: right;
    white-space: nowrap;

    font-size: 16px;
    @media ${device.tablet} {
        font-size: 25px;
    }
`;

export const Description = styled.p`
    margin-bottom: 25px;
    font-weight: normal;
`;

export const StyledAccordionHeader = styled.p`
    color: #0b0b0b;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
`;

export const StyledAccordionDescription = styled.p`
    margin-top: 15px;
`;

export const Tables = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 25px;
`;

export const StyledTableWrapper = styled.div``;

export const StyledPaymentPlanTable = styled(KeyValueTable)`
    font-size: 12px;
    font-weight: 500;
`;

// Bundle card
export const StyledBundleCard = styled(BundleCard)`
    padding: 30px 0;
`;

export const StyledButton = styled(Button)`
    margin-top: 30px;
    margin-bottom: 15px;
`;

export const EnergyConsumptionWrapper = styled.div``;

export const StyledEnergyText = styled.p`
    opacity: 0.5;
    color: #0b0b0b;
    font-size: 11px;
    letter-spacing: 0;
`;

export const StyledEnergyRating = styled(EnergyRating)`
    font-size: 10px;
`;

export const Close = styled.div`
    display: flex;
    align-content: center;
    width: min-content;
    cursor: pointer;
    margin-left: auto;
    transform: translate(-25px, 25px);
`;

export const StyledCompletedForm = styled.section`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & > button {
        align-self: flex-start;
    }
`;

export const StyledHeader = styled.p`
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
`;

export const StyledDescription = styled.p`
    color: #0b0b0b;
    font-size: 14px;
    letter-spacing: 0;
`;

export const ClickableText = styled.span`
    color: ${({ theme }) => theme.palette.primary.main};
    text-decoration: underline;
    cursor: pointer;
`;
