import { useEffect, useMemo, useState, VFC } from 'react';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { ProductDetailsBenefitsDialog } from '../../../lib/api/models/umbraco/product-details';
import { hesselViewModels } from '../../../lib/view-models';
import { MEDIA_URL } from '../../../utils/environment-constants';
import { getCmsImageUrl } from '../../../utils/helpers';
import { ContentSpotRichText } from '../../shared/content-spot-rich-text/content-spot-rich-text.component';
import { SidePanel } from '../../shared/modals/side-panel';
import { SidePanelLayout } from '../../shared/modals/side-panel-layout/side-panel-layout.component';
import { KeyValueTableRow } from '../../shared/tables/key-value-table/row';
import {
    StyledBenefitsDialogCarousel,
    StyledBenefitsDialogHeaderWrapper,
    StyledBenefitsDialogSubtitle,
    StyledBenefitsDialogTable,
    StyledBenefitsDialogTitle,
    StyledCompactCarousel,
} from './benefits-dialog.styled';

export type BenefitsDialogProps = {
    onClose: () => void;
    isVisible: boolean;
    content: ProductDetailsBenefitsDialog;
};

export const BenefitsDialog: VFC<BenefitsDialogProps> = (props) => {
    const [timesOpened, setTimesOpened] = useState(0);
    useEffect(() => {
        if (props.isVisible) {
            setTimesOpened(timesOpened + 1);
        }
        // Intentionally leaving out timesOpened - for obvious reasons :)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isVisible]);

    const slides = useMemo(
        () =>
            (props.content.images || []).map<hesselViewModels.PdpResource>((slide) => ({
                caption: slide.caption,
                id: `${slide.image.id}`,
                type: 'image',
                url: getCmsImageUrl(slide.image.src),
            })),
        [props.content.images]
    );

    const benefits = useMemo(
        () =>
            (props.content.benefits || []).map(({ icon, label, value }, i) => ({
                iconUrl: icon && MEDIA_URL + icon.src,
                key: i,
                label,
                value,
            })),
        [props.content.benefits]
    );

    const isMobile = useMediaQuery({ target: 'tablet' });

    return (
        <SidePanel cancelAction={props.onClose} isVisible={props.isVisible}>
            <SidePanelLayout closeSidePanel={props.onClose}>
                <StyledBenefitsDialogHeaderWrapper>
                    {props.content.title && <StyledBenefitsDialogTitle>{props.content.title}</StyledBenefitsDialogTitle>}
                    {props.content.subtitle && <StyledBenefitsDialogSubtitle>{props.content.subtitle}</StyledBenefitsDialogSubtitle>}
                </StyledBenefitsDialogHeaderWrapper>
                {props.content.body && <ContentSpotRichText text={props.content.body}></ContentSpotRichText>}
                {benefits.length && (
                    <StyledBenefitsDialogTable>
                        {benefits.map((benefit) => (
                            // eslint-disable-next-line react/jsx-key
                            <KeyValueTableRow {...benefit} />
                        ))}
                    </StyledBenefitsDialogTable>
                )}
                {isMobile ? (
                    <StyledCompactCarousel key={'timesOpened'} slides={slides} isAboveFold={false} />
                ) : (
                    <StyledBenefitsDialogCarousel
                        key={timesOpened}
                        slides={slides}
                        showImageAsCover={true}
                        isAboveFold={false}
                        useSquaredCorners={props.content.useSquaredCorners}
                    />
                )}
            </SidePanelLayout>
        </SidePanel>
    );
};
