import { useEffect, useState } from 'react';

type EquipmentAccordionController = {
    registerAccordion: (index: number) => {
        index: number;
        onClick: () => void;
        isOpen: boolean;
    };
};

export const useEquipmentAccordionController = (defaultOpenIndex?: number): EquipmentAccordionController => {
    const [openAccordionIndex, setOpenAccordionIndex] = useState(-1);

    useEffect(() => {
        if (defaultOpenIndex === undefined) {
            setOpenAccordionIndex(-1);
            return;
        }

        setOpenAccordionIndex(defaultOpenIndex);
    }, [defaultOpenIndex]);

    const handleEquipmentAccordionClick = (newIndex: number) => {
        if (newIndex === openAccordionIndex) {
            setOpenAccordionIndex(-1);
        } else {
            setOpenAccordionIndex(newIndex);
        }
    };

    const registerAccordion = (index: number) => ({
        index,
        onClick: () => handleEquipmentAccordionClick(index),
        isOpen: index === openAccordionIndex,
    });

    return {
        registerAccordion,
    };
};
