import { addDays } from 'date-fns';
import { FormsInput } from '../../../../lib/api/models/umbraco';
import { generateDates } from '../../../../utils/helpers';
import { getFieldTypeAlias } from '../../../../utils/helpers/umbraco-forms.helper';

export function resolveAvailableDates(input: FormsInput): Date[] {
    const inputType = getFieldTypeAlias(input.fieldTypeId);

    if (inputType !== 'date' && inputType !== 'advancedDatepicker') {
        return [];
    }
    const fromDate = inputType === 'date' ? new Date() : addDays(new Date(), parseInt(input.settings.maxDateOffsetSetting));

    return generateDates(fromDate, 365);
}
