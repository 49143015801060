import { VFC } from 'react';
import { StyledPriceListLink, StyledPriceListLinkIcon, StyledPriceListLinkLabel } from './price-list-link.styled';

export type PriceListLinkProps = {
    className?: string;
    href: string;
    iconUrl: string;
    openInNewTab?: boolean;
    linkText: string;
};

export const PriceListLink: VFC<PriceListLinkProps> = (props) => {
    return (
        <StyledPriceListLink download href={props.href} target={props.openInNewTab === true ? '_blank' : '_self'}>
            <StyledPriceListLinkIcon alt="" src={props.iconUrl} />
            <StyledPriceListLinkLabel>{props.linkText}</StyledPriceListLinkLabel>
        </StyledPriceListLink>
    );
};
