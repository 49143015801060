import { IConfirmModal } from './confirm.props';
import { ModalWrapper } from '../wrapper';
import { Wrapper, Footer, ChildWrapper } from './confirm.styled';
import { FC } from 'react';
import { Button } from '../../../../components/shared/button';

export const ConfirmModal: FC<IConfirmModal> = ({
    isVisible = false,
    headerText = 'Er du sikker?',
    confirmText = 'OK',
    subHeaderText = '',
    cancelText = 'Anullere',
    confirmAction,
    cancelAction,
    disableConfirm = false,
    children,
    removeOverflow = false,
}) => {
    return isVisible ? (
        <ModalWrapper
            cancelAction={cancelAction}
            isVisible={isVisible}
            headerText={headerText}
            subHeaderText={subHeaderText}
            removeOverflow={removeOverflow}
        >
            <Wrapper>
                <ChildWrapper>{children}</ChildWrapper>
                <Footer>
                    <Button onClick={cancelAction} variant="light">
                        {cancelText}
                    </Button>
                    <Button onClick={confirmAction} variant="primary" disabled={disableConfirm}>
                        {confirmText}
                    </Button>
                </Footer>
            </Wrapper>
        </ModalWrapper>
    ) : null;
};
