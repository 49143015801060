import { FC } from 'react';
import { SearchStoreContext } from '../../../lib/state/search/search.store';
import styled from 'styled-components';

type IProps = {
    noResultsText: string;
};

export const SearchNoResults: FC<IProps> = ({ noResultsText }) => {
    const { searchTerm } = SearchStoreContext.useStoreState((state) => state);
    const text =
        noResultsText.length > 0
            ? noResultsText.replace('{{searchTerm}}', searchTerm)
            : `<p>Din søgning på <strong>${searchTerm}</strong> gav desværre ingen resultater</p>`;
    return <StyledNoResultsText dangerouslySetInnerHTML={{ __html: text }} />;
};

const StyledNoResultsText = styled.div`
    ul,
    ol {
        list-style-type: unset;
        padding-left: 30px;
        margin-top: 20px;
    }
`;
