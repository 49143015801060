import styled from 'styled-components';
import { device } from '../../../lib/media-query';
import { UmbracoForms } from '../../spots/umbraco-forms';

export const HeaderSection = styled.header``;

export const Header = styled.p`
    font: 700 25px/30px 'Corporate E', Serif;
    margin: 0 0 14px;
`;

export const SubHeader = styled.div`
    font-weight: 400;
`;

export const WriteUsFormDialogBody = styled(UmbracoForms)`
    border-top: 1px solid #d1d2d4;
    margin: 0 85px 0 0;
    padding: 27px 0 0;

    @media ${device.tablet} {
        margin: 0 85px 0 50px;
    }
`;
