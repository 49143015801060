import React, { useEffect, useMemo, useState, VFC } from 'react';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { PdpBookAdvisorModal } from '../../../lib/api/models/umbraco/product-details';
import { ProductDetailsStore } from '../../../lib/state/hessel-site/product-details';
import { PdpResource } from '../../../lib/view-models/vehicle';
import { MEDIA_URL } from '../../../utils/environment-constants';
import { sanitizeMarkup } from '../../../utils/helpers';
import { getFormConfigurations } from '../../../utils/helpers/umbraco-forms.helper';
import { CtaHandler } from '../../plus-sites-shared/content-handlers';
import { CompactCarousel } from '../../shared/carousels/compact-carousel/compact-carousel.component';
import { SimpleCarousel } from '../../shared/carousels/simple-carousel/simple-carousel.component';
import { SidePanel } from '../../shared/modals/side-panel';
import { SidePanelLayout } from '../../shared/modals/side-panel-layout/side-panel-layout.component';
import { BookAdvisorModalBody, HeaderSection, SubHeader, Header, StyledDescription, CtaWrapper } from './book-advisor-modal.styled';
import { useRelewiseTracking } from '../../../hooks/relewise/use-relewise-tracking';

export type BookAdvisorModalProps = {
    modalProps: PdpBookAdvisorModal;
    visible: boolean;
    onClose: () => void;
    pageId: string;
};

const BookAdvisorModalMemo: VFC<BookAdvisorModalProps> = ({ modalProps, visible, onClose, pageId }) => {
    const [formIsCompleted, setFormIsCompleted] = useState(false);
    const { selectedProduct, selectedColor, selectedTab } = ProductDetailsStore.useStoreState((state) => state);
    const isMobile = useMediaQuery({ target: 'tablet' });

    useEffect(() => {
        if (!visible) {
            setFormIsCompleted(false);
        }
    }, [visible]);

    const carouselItems = useMemo(() => {
        return (
            modalProps?.receiptMediaList.map((x) => {
                if (x.media.type === 'image' || x.media.extension?.toLowerCase() === 'webp') {
                    return {
                        type: 'image',
                        url: MEDIA_URL + x.media.src,
                        caption: x.caption,
                    } as PdpResource;
                }

                if (x.media.type === 'file') {
                    return {
                        type: 'video',
                        url: MEDIA_URL + x.media.src,
                        caption: x.caption,
                    } as PdpResource;
                }

                throw new Error('Unknown media type');
            }) || []
        );
    }, [modalProps]);

    const configurationString = useMemo(
        () => getFormConfigurations(selectedTab, selectedProduct, selectedColor),
        [selectedColor, selectedProduct, selectedTab]
    );

    const { trackVehicleConversion } = useRelewiseTracking();

    useEffect(() => {
        if (formIsCompleted && selectedProduct) {
            trackVehicleConversion(selectedProduct.entityId.toString(), selectedProduct.purchaseTypes.cash.basePrice);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formIsCompleted]);

    return (
        <SidePanel cancelAction={onClose} isVisible={visible}>
            <SidePanelLayout closeSidePanel={onClose}>
                {!formIsCompleted ? (
                    <>
                        <HeaderSection>
                            <Header>{modalProps?.bookAdvisorForm?.[0]?.formHeader}</Header>
                            <SubHeader {...sanitizeMarkup(modalProps?.bookAdvisorForm?.[0]?.formSubtext)} />
                        </HeaderSection>
                        <BookAdvisorModalBody
                            form={modalProps?.bookAdvisorForm?.[0]?.form}
                            pageId={pageId}
                            specialProps={{
                                enquiryCarId: selectedProduct?.id,
                                configurations: configurationString,
                            }}
                            trackingStrategy={modalProps?.bookAdvisorForm?.[0]?.trackingStrategy?.[0]}
                            onFormComplete={() => setFormIsCompleted(true)}
                        />
                    </>
                ) : (
                    <HeaderSection>
                        <Header>{modalProps?.receiptHeader}</Header>

                        <StyledDescription {...sanitizeMarkup(modalProps?.receiptDescription)} />

                        {isMobile ? (
                            <CompactCarousel slides={carouselItems} isAboveFold={false} />
                        ) : (
                            <SimpleCarousel slides={carouselItems} isAboveFold={false} />
                        )}

                        {modalProps?.receiptCTA ? (
                            <CtaWrapper>
                                <CtaHandler ctas={modalProps?.receiptCTA} />
                            </CtaWrapper>
                        ) : null}
                    </HeaderSection>
                )}
            </SidePanelLayout>
        </SidePanel>
    );
};

export const BookAdvisorModal = React.memo(BookAdvisorModalMemo);
