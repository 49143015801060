import { VFC } from 'react';
import { CueArrow, ScrollCue } from './scroll-cue.styled';

type Unit = `${string}px` | `${string}%`;

type Props = {
    icon: string;
    width?: number;
    top?: Unit;
    right?: Unit;
    bottom?: Unit;
    left?: Unit;
    animationDirection?: 'Vertical' | 'Horizontal';
    fullWidth?: boolean;
};

export const ScrollIndicator: VFC<Props> = ({
    icon,
    width = 30,
    top = 'unset',
    right = 'unset',
    bottom = 'unset',
    left = 'unset',
    animationDirection = 'Vertical',
    fullWidth = true,
}) => {
    return (
        <ScrollCue top={top} right={right} bottom={bottom} left={left} fullWidth={fullWidth}>
            <CueArrow vertical={animationDirection === 'Vertical'} src={icon} alt="scroll cue" width={width} />
        </ScrollCue>
    );
};
