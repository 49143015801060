import styled from 'styled-components';
import { device } from '../../../../lib/media-query';
import { mixins } from '../../../../themes';

export const YourCarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    ${mixins.gap(1.5)}
`;

export const YourCarStep = styled.div`
    display: flex;

    flex-direction: column;

    gap: 1.5rem;

    @media ${device.tablet} {
        width: ${({ theme }) => theme.typography.fontSize};
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 2rem;
    }
`;

export const InputList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
