import { contentSpotTypes } from '../../lib/api';

/**
 * Rules for marking content spots as above the fold:
 * 1. If the first content spot is a product list, mark the first content spot as above the fold
 * 1.1. Inside the product list, mark the first 6 products as above the fold. On Mobile, mark the first 1 products as above the fold
 * 2. If the first content spot is not a product list, mark the first two content spots as above the fold
 * @param contentSpots
 * @returns A list of content spots with the isAboveFold property set to true or false
 */
export function markContentSpotAsAboveFold(contentSpots: Array<contentSpotTypes.ContentSpotSettings>): Array<contentSpotTypes.ContentSpotSettings> {
    const productListFilterIndex = contentSpots.findIndex(
        (contentSpot) => contentSpot.alias === 'productListWithFilter' || contentSpot.alias === 'productListWithoutFilter'
    );

    if (productListFilterIndex === 0) {
        contentSpots.forEach((contentSpot, index) => {
            contentSpot.isAboveFold =
                (contentSpot.alias === 'productListWithFilter' || contentSpot.alias === 'productListWithoutFilter') && index === 0;
        });

        return contentSpots;
    }

    contentSpots.forEach((contentSpot, index) => {
        const isNotProductList = contentSpot.alias !== 'productListWithFilter' && contentSpot.alias !== 'productListWithoutFilter';
        contentSpot.isAboveFold = index < 2 && isNotProductList;
    });

    return contentSpots;
}
