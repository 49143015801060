import { FC, useMemo, useState } from 'react';
import { CSSProperties } from 'styled-components';
import { useCarouselControls } from '../../../../hooks/use-carousel-controls';
import { hesselViewModels } from '../../../../lib/view-models';
import { next } from '../../../../utils/helpers/incremental-integer.helper';
import { ArrowLeft, ArrowRight } from '../../../icons';
import { IconButton } from '../../icon-button/icon-button.component';
import { mapSlides } from '../carousel-utils';
import {
    StyledCarouselControlsBottomRight,
    StyledCarouselControlsBottomRightIndexIndicator,
    StyledCompactCarousel,
    StyledCompactCarouselMarker,
    StyledCompactCarouselMarkerList,
    StyledCompactInnerCarousel,
} from './compact-carousel.styled';

type CarouselControls = 'bottom right';

export type CompactCarouselProps = {
    className?: string;
    slides: Array<hesselViewModels.PdpResource>;
    hideCaption?: boolean;
    hideMarkerList?: boolean;
    carouselControls?: CarouselControls;
    customStyles?: CSSProperties;
    isAboveFold?: boolean;
    useSquaredCorners?: boolean;
    indicatorPosition?: 'Absolute' | 'Static';
    blendMode?: {
        mixBlendMode: string;
        backgroundColor: string;
    };
    showAsCover?: boolean;
};

export const CompactCarousel: FC<CompactCarouselProps> = ({ showAsCover = true, ...props }) => {
    const { activeIndex, dragOffset, bindGestures, nextSlide, previousSlide } = useCarouselControls(props.slides.length);
    const [carouselDomId] = useState(`compact-carousel-${next()}`);
    const carouselItems = useMemo(() => mapSlides(props.slides, carouselDomId), [carouselDomId, props.slides]);

    return props.slides ? (
        <StyledCompactCarousel
            aria-roledescription="carousel"
            role="region"
            {...bindGestures()}
            className={props.className}
            style={props.customStyles}
        >
            <StyledCompactInnerCarousel
                activeIndex={activeIndex}
                dragOffset={dragOffset}
                id={carouselDomId}
                items={carouselItems}
                renderCaption={(slide) => {
                    if (slide.type === 'youTube' || props.hideCaption) {
                        return null;
                    }

                    return slide.caption && <p>{slide.caption}</p>;
                }}
                showImageAsCover={showAsCover}
                isAboveFold={!!props.isAboveFold}
                useSquaredCorners={props.useSquaredCorners}
                blendMode={props.blendMode}
            />
            {props.carouselControls === 'bottom right' ? (
                <StyledCarouselControlsBottomRight>
                    {carouselItems.length > 1 ? (
                        <IconButton label="Forrige" size={45} onClick={() => previousSlide()}>
                            <ArrowLeft></ArrowLeft>
                        </IconButton>
                    ) : null}
                    <StyledCarouselControlsBottomRightIndexIndicator>
                        {activeIndex + 1}
                        <span style={{ opacity: '0.5' }}>/{carouselItems.length.toString()}</span>
                    </StyledCarouselControlsBottomRightIndexIndicator>
                    {carouselItems.length > 1 ? (
                        <IconButton label="Næste" size={45} onClick={() => nextSlide()}>
                            <ArrowRight></ArrowRight>
                        </IconButton>
                    ) : null}
                </StyledCarouselControlsBottomRight>
            ) : null}
            {carouselItems.length > 1 && !props.hideMarkerList ? (
                <StyledCompactCarouselMarkerList
                    absolute={props.indicatorPosition === 'Absolute' || props.indicatorPosition === undefined}
                    role="none"
                >
                    {carouselItems.map((_item, i) => {
                        const isActive = i === activeIndex;
                        return <StyledCompactCarouselMarker key={i} isActive={isActive} role="none" />;
                    })}
                </StyledCompactCarouselMarkerList>
            ) : null}
        </StyledCompactCarousel>
    ) : null;
};
