import { FC } from 'react';
import { INavigator } from './navigator.props';
import { useRouter } from 'next/router';
import { SvgIcon } from '../svg-icon';
import { NavigatorWrapper, NavigatorText } from './navigator.styled';

export const Navigator: FC<INavigator> = ({ text, link, className }) => {
    const router = useRouter();

    return (
        <NavigatorWrapper className={className} onClick={() => router.push(link)}>
            <SvgIcon iconName="chevron/left" />
            <NavigatorText>{text}</NavigatorText>
        </NavigatorWrapper>
    );
};
