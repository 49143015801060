import { FC, useMemo } from 'react';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import { FilterUpdateNotification } from '../../../../lib/api/models/hessel-api/hire';
import { AvailabilityLabel } from '../../../../lib/api/models/umbraco';
import { BaseFilterSetup } from '../../../../lib/api/models/umbraco/content-spot';
import { FacetGroup } from '../../../../lib/view-models/vehicle';
import { mapAvailabilityFacetToToggleOptions } from '../../../../utils/helpers';
import { ToggleUi } from '../../../shared/toggle-ui';
import { ToggleOptionType } from '../../../shared/toggle-ui/toggle-ui.component';
import { SortingHeader, StyledFilterDropDown } from './vehicle-product-list-sorting.styled';

type IProps = {
    onChangeCarAvailabilityType: (filterUpdate: FilterUpdateNotification) => void;
    carAvailabilityTypes?: FacetGroup;
    availabilityLabels: AvailabilityLabel[];
    baseFilterSetup: BaseFilterSetup;
};

export const VehicleProductListSorting: FC<IProps> = ({ carAvailabilityTypes, onChangeCarAvailabilityType, availabilityLabels, baseFilterSetup }) => {
    const isMobile = useMediaQuery({ target: 'tablet' });

    const toggleOptions: ToggleOptionType[] = useMemo(() => {
        if (carAvailabilityTypes) {
            return mapAvailabilityFacetToToggleOptions(carAvailabilityTypes, availabilityLabels);
        }

        return [];
    }, [availabilityLabels, carAvailabilityTypes]);

    const dropdownOptions = useMemo(() => {
        const options = toggleOptions.map((x) => {
            return { sortingKey: x.value, labelText: x.label };
        });
        return options;
    }, [toggleOptions]);

    const selectedToggleOption = useMemo(() => {
        const selectedAvailability = carAvailabilityTypes?.facetOptions.find((x) => x.selected);
        if (selectedAvailability) return selectedAvailability.filterQueryValue;
        const defaultAvailability = carAvailabilityTypes?.facetOptions.find((x) => x.key === baseFilterSetup.defaultAvailability);
        if (defaultAvailability) return defaultAvailability.filterQueryValue;
        return '';
    }, [baseFilterSetup.defaultAvailability, carAvailabilityTypes?.facetOptions]);

    return (
        <SortingHeader>
            {carAvailabilityTypes && toggleOptions.length > 1 ? (
                <>
                    {!isMobile ? (
                        <ToggleUi
                            options={toggleOptions}
                            onChange={(selection: string) => onChangeCarAvailabilityType({ query: selection, action: 'Replace' })}
                            selectedOption={selectedToggleOption}
                        />
                    ) : (
                        <StyledFilterDropDown
                            label="Vis"
                            options={dropdownOptions}
                            onChangeSorting={(selection: string) => onChangeCarAvailabilityType({ query: selection, action: 'Replace' })}
                            selectedValue={dropdownOptions.find((x) => x.sortingKey === selectedToggleOption)?.labelText ?? ''}
                        />
                    )}
                </>
            ) : (
                <div />
            )}
        </SortingHeader>
    );
};

export default VehicleProductListSorting;
