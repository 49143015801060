import { useSelect } from 'downshift';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { SvgIcon } from '../../../shared/svg-icon';
import { BaseInput } from '../base';
import type { DropdownOption, Props } from './dropdown-input.props';
import {
    StyledPlaceholder,
    StyledValue,
    StyledList,
    StyledListItem,
    DisplayWrapper,
    DropdownContainer,
    ListContainer,
} from './dropdown-input.styled';
import { animated, useSpring } from 'react-spring';
import { useClickOutsideObserver } from '../../../../hooks/use-click-outside-observer';
import { useDeviceDetect } from '../../../../hooks/use-device-detect';

const valueIsDefined = (value?: DropdownOption<string>): value is DropdownOption<string> => (value?.value && value?.displayValue ? true : false);

const DropdownInput: FC<Props> = ({
    className,
    value,
    onChange,
    isValid,
    validationMessage,
    infoMessage,
    canValidateInputField,
    options,
    disabled,
    onInputBlur,
    ...props
}) => {
    const { getItemProps, getLabelProps, getMenuProps, getToggleButtonProps, highlightedIndex, toggleMenu } = useSelect({
        items: options,
        itemToString: (item) => item?.displayValue ?? '',
        onSelectedItemChange: ({ selectedItem }) => {
            if (disabled) return;
            onChange(selectedItem);
        },
        initialSelectedItem: value,
    });

    const [isOpen, setIsOpen] = useState(false);

    const spin = useSpring({
        transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    });

    const dropdownRef = useRef(null);
    useClickOutsideObserver(dropdownRef, () => setIsOpen(false), isOpen);

    const handleDocumentKeyDown = useCallback((e) => {
        if (e.key === 'Escape') {
            setIsOpen(false);
        }
    }, []);

    useEffect(() => {
        if (!disabled) {
            document.addEventListener('keydown', handleDocumentKeyDown, { passive: true });
        } else {
            document.removeEventListener('keydown', handleDocumentKeyDown);
        }

        return () => document.removeEventListener('keydown', handleDocumentKeyDown);
    }, [disabled, handleDocumentKeyDown]);

    const device = useDeviceDetect();

    return (
        <DropdownContainer
            className={className}
            isOpen={isOpen}
            onBlur={onInputBlur}
            onClick={() => {
                if (!disabled) {
                    setIsOpen(!isOpen);
                    toggleMenu();
                }
            }}
            ref={dropdownRef}
        >
            <BaseInput
                {...props}
                isOpen={isOpen}
                value={value}
                isValid={isValid}
                disabled={disabled}
                adornment={
                    <animated.div style={spin}>
                        <SvgIcon iconName={'chevron/down'} />
                    </animated.div>
                }
                labelProps={getLabelProps({ disabled })}
                valueIsDefined={valueIsDefined}
                canValidateInputField={canValidateInputField}
                validationMessage={validationMessage}
                infoMessage={infoMessage}
                onLabelClick={() => {
                    if (!disabled) {
                        toggleMenu();
                    }
                }}
                hideValidation={!canValidateInputField}
            >
                {({ placeholder, value }) => (
                    <>
                        <DisplayWrapper {...getToggleButtonProps({ disabled })} onClick={disabled ? undefined : toggleMenu}>
                            {valueIsDefined(value) ? (
                                <StyledValue isdesktopdevice={`${device.isDesktop()}`}>{value.displayValue}</StyledValue>
                            ) : (
                                <StyledPlaceholder>{placeholder}</StyledPlaceholder>
                            )}
                        </DisplayWrapper>
                    </>
                )}
            </BaseInput>
            <ListContainer isOpen={isOpen}>
                <StyledList {...getMenuProps({ disabled })}>
                    {isOpen &&
                        options.map((item, idx) => (
                            <StyledListItem
                                style={highlightedIndex === idx ? { backgroundColor: '#fafafa' } : { opacity: disabled || item.disabled ? '0.4' : 1 }}
                                key={`${idx}:${item.displayValue}`}
                                {...getItemProps({ item, index: idx, disabled: disabled || item.disabled })}
                            >
                                <span style={{ display: 'flex', flexDirection: 'column', gap: '0' }}>
                                    <span>{item.displayValue}</span>
                                    {item.subLabel && item.subLabel.length > 0 ? (
                                        <span style={{ color: '#aeaeaf', fontSize: '12px' }}>{item.subLabel}</span>
                                    ) : null}
                                </span>
                            </StyledListItem>
                        ))}
                </StyledList>
            </ListContainer>
        </DropdownContainer>
    );
};

export default DropdownInput;
