import { VFC } from 'react';
import { BorderBox, StyledLabel, ValuesWrapper, PriceLabel, DownPayment } from './total-price.styled';

type TotalPriceProps = {
    totalPriceIncludingMoms: string;
};

export const TotalPrice: VFC<TotalPriceProps> = ({ totalPriceIncludingMoms }) => {
    return (
        <BorderBox>
            <StyledLabel>Pris i alt inkl. moms</StyledLabel>

            <ValuesWrapper>
                <PriceLabel>{totalPriceIncludingMoms}</PriceLabel>
                <DownPayment>Udbetaling 0 kr.</DownPayment>
            </ValuesWrapper>
        </BorderBox>
    );
};
