import styled from 'styled-components';

export const Header = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: 14px;
`;

export const StyledDescription = styled.div`
    & > p {
        color: #0b0b0b;
        font-size: 14px;
        letter-spacing: 0;
        font-weight: normal;
        margin-bottom: 30px;
    }

    & ul,
    & ol {
        font-weight: normal;
        list-style: revert;
        padding: revert;
        margin: revert;
    }

    & ul li,
    & ol li {
        font-weight: normal;
    }
`;

export const CtaWrapper = styled.div`
    margin-top: 30px;
`;
