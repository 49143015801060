import styled from 'styled-components';
import { device } from '../../../../lib/media-query';
import { Button } from '../../../shared';

export const StyledSummaryHeader = styled.p`
    font-size: 20px;
    font-weight: 700;
    font-family: 'Corporate E';
    line-height: 30px;
    border-bottom: 1px solid #e2e2e4;
    padding-bottom: 15px;
    margin-bottom: 25px;
`;

export const StyledSummaryLine = styled.div`
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
`;

export const StyledSummaryLineKey = styled.span`
    font-size: 12px;
    font-weight: 500;
    color: #aeaeaf;
    line-height: 15px;
`;

export const StyledSummaryLineValue = styled.span`
    font-size: 12px;
    font-weight: 500;
    color: #0b0b0b;
    line-height: 15px;
`;

export const StyledDiscountRemoveLink = styled.span`
    margin-left: 5px;
    color: #0089d1;
    cursor: pointer;
    text-decoration: underline;
`;

export const StyledSummaryWrapper = styled.div`
    border-bottom: 1px solid #e2e2e4;
    padding-bottom: 5px;
`;

export const StyledSummaryPriceTotalWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Corporate E';
    line-height: 22px;
    margin-top: 20px;

    @media ${device.mobileL} {
        font-size: 20px;
    }
`;

export const StyledCartCta = styled(Button)`
    width: 100%;
    margin: 20px 0 25px;
`;

export const StyledSummaryFooter = styled.footer`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
`;

export const StyledSummaryFooterItemContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    line-height: 24px;
    font-size: 12px;
`;

export const StyledSafePaymentContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 500;
    line-height: 24px;
    font-size: 12px;
`;
