import { FC } from 'react';
import { ICheckbox } from './checkbox.props';
import { BorderBox, Wrapper, StyledCheckBox, StyledLabel, StyledError } from './checkbox.styled';
import { StyledRedLabel } from '../../forms/inputs/base/base-input.styled';

export const CheckBox: FC<ICheckbox> = ({
    id,
    checked,
    onChange,
    children,
    disabled,
    value,
    isValid = false,
    required = false,
    textAlign = 'center',
    validationMessage = '',
    expandLabel = false,
    canValidate = true,
    name = id,
    checkboxSize = 17,
    className,
}) => {
    return (
        <BorderBox className={className} onClick={(e) => e.stopPropagation()}>
            <Wrapper alignItems={textAlign}>
                <StyledCheckBox
                    id={id}
                    onChange={(e) => onChange(e.target.checked)}
                    checked={checked}
                    disabled={disabled}
                    value={value}
                    error={canValidate && !isValid && required}
                    name={name}
                    size={checkboxSize}
                />
                <StyledLabel htmlFor={id} expandLabel={expandLabel}>
                    <span>{children}</span>
                    {required && !disabled && <StyledRedLabel>*</StyledRedLabel>}
                </StyledLabel>
            </Wrapper>
            {required && !isValid && canValidate && <StyledError>{validationMessage}</StyledError>}
        </BorderBox>
    );
};
