import React, { useMemo, useState, VFC } from 'react';
import { OrganizationConsts } from '../../../constants';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { orgTypes } from '../../../lib/api/models/umbraco';
import { MEDIA_URL } from '../../../utils/environment-constants';
import { LoadMore } from '../../shared/load-more';
import { ToggleUi } from '../../shared/toggle-ui';
import { EmployeeCard } from '../employee-card/employee-card.component';
import {
    ContentWrapper,
    StyledEmployeesInDepartment,
    Header,
    StyledCenteredBlock,
    ToggleUiWrapper,
    DepartmentsDropdown,
} from './employees-in-department.styled';

type EmployeesInDepartmentProps = {
    dealership: orgTypes.DealershipWithGeoInfo;
};

const page_size = 20;

export const EmployeesInDepartmentMemo: VFC<EmployeesInDepartmentProps> = ({ dealership }) => {
    const [selectedDepartment, setSelectedDepartment] = useState<orgTypes.DepartmentType>('Alle');
    const [currentTake, setCurrentTake] = useState(page_size);
    const isMobile = useMediaQuery({ target: 'tablet' });

    const filteredEmployees = useMemo(() => {
        if (selectedDepartment === 'Alle') {
            const employees = [...(dealership.employees ?? [])];

            return employees.sort((a, b) => {
                const aWebSorting = a.webSortings?.find((x) => x.departmentId === +dealership.hovedafdelingId);
                const bWebSorting = b.webSortings?.find((x) => x.departmentId === +dealership.hovedafdelingId);
                if ((aWebSorting?.departmentSort ?? 9999) === (bWebSorting?.departmentSort ?? 9999)) return 0;
                if ((aWebSorting?.departmentSort ?? 9999) > (bWebSorting?.departmentSort ?? 9999)) return 1;
                return -1;
            });
        }

        const employeesFilteredInternally = dealership.employees?.filter((x) => x.workDepartment === selectedDepartment) ?? [];

        return employeesFilteredInternally.sort((a, b) => {
            const aWebSorting = a.webSortings?.find((x) => x.departmentId === +dealership.hovedafdelingId);
            const bWebSorting = b.webSortings?.find((x) => x.departmentId === +dealership.hovedafdelingId);
            if ((aWebSorting?.internalDepartmentSort ?? 9999) === (bWebSorting?.internalDepartmentSort ?? 9999)) return 0;
            if ((aWebSorting?.internalDepartmentSort ?? 9999) > (bWebSorting?.internalDepartmentSort ?? 9999)) return 1;
            return -1;
        });
    }, [dealership.employees, dealership.hovedafdelingId, selectedDepartment]);

    const relevantDepartments = useMemo(
        () =>
            dealership.departments.filter(
                (dep) => dep.departmentType !== 'None' && dealership.employees?.some((emp) => emp.workDepartment === dep.departmentType)
            ),
        [dealership.departments, dealership.employees]
    );

    const toggleOptions = useMemo(() => {
        const ops = relevantDepartments.map((x) => {
            return {
                label: x.departmentName,
                value: x.departmentType,
            };
        });

        return [
            {
                label: 'Alle',
                value: 'Alle',
            },
            ...ops,
        ];
    }, [relevantDepartments]);

    const dropdownOptions = React.useMemo(() => {
        const ops = relevantDepartments.map((x) => ({ sortingKey: x.departmentType, labelText: x.departmentType, disabled: x.disabled }));
        return [{ sortingKey: 'Alle', labelText: 'Alle' }, ...ops];
    }, [relevantDepartments]);

    if (!dealership.employees) {
        return null;
    }

    function applyFilters(selection: string) {
        setCurrentTake(page_size);
        setSelectedDepartment(selection as orgTypes.DepartmentType);
    }

    return (
        <StyledCenteredBlock>
            <StyledEmployeesInDepartment id={OrganizationConsts.FindEmployeesSpot}>
                <Header>Medarbejdere i afdelingen</Header>

                <ToggleUiWrapper>
                    {!isMobile ? (
                        <ToggleUi
                            options={toggleOptions}
                            selectedOption={selectedDepartment}
                            onChange={(selection) => {
                                applyFilters(selection);
                            }}
                        />
                    ) : (
                        <DepartmentsDropdown
                            options={dropdownOptions}
                            onChangeSorting={(selection: string) => applyFilters(selection)}
                            selectedValue={(selectedDepartment as string) ?? 'Alle'}
                            label={'Afdeling'}
                        />
                    )}
                </ToggleUiWrapper>

                <ContentWrapper>
                    {filteredEmployees
                        .filter((_, index) => index < currentTake)
                        .map((emp, index) => {
                            return (
                                <EmployeeCard
                                    key={`employees-${emp.employeeId}:${index}`}
                                    name={emp.name}
                                    imageUrl={emp.webPicture ?? `${MEDIA_URL}${dealership.employeesPlaceholderImage?.src}`}
                                    brands={emp.brands}
                                    email={emp.email}
                                    phone1={emp.phone1}
                                    phone2={emp.phone2}
                                    title={emp.title}
                                    showMobileNumber={emp.showMobileNumber}
                                />
                            );
                        })}
                </ContentWrapper>

                <LoadMore
                    onClick={() => setCurrentTake(currentTake + page_size)}
                    total={filteredEmployees.length}
                    take={page_size}
                    current={currentTake}
                    loadMoreText={'Vis flere medarbejdere'}
                ></LoadMore>
            </StyledEmployeesInDepartment>
        </StyledCenteredBlock>
    );
};

export const EmployeesInDepartment = React.memo(EmployeesInDepartmentMemo);
