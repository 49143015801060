import React, { VFC, useEffect, useRef } from 'react';
import { formatPrice } from '../../../utils/helpers';
import { OwnershipInfoCardPrices, StyledPriceWrapper, StyledDownPayment, StyledVatMessage } from './ownership-card.styled';
import { useIntersection } from 'react-use';
import { Abbreviations } from '../../../constants/units-and-abbreviations';

export type OwnershipCardPriceProps = {
    price: number;
    downPayment?: number;
    monthly?: boolean;
    vatIncluded?: boolean;
    overrideText?: string; // If provided, will be only text displayed
    isCompanyCar?: boolean;
    priceTextOverride?: string;
    isAFromPrice?: boolean;
    priceVisibilityChanged: (visible: boolean) => void;
};

/**
 * Helper component to display the price in the ownership card.
 */
export const OwnershipCardPrice: VFC<OwnershipCardPriceProps> = ({
    price,
    monthly,
    downPayment,
    vatIncluded = false,
    overrideText,
    isCompanyCar = false,
    priceVisibilityChanged,
    priceTextOverride,
    isAFromPrice = false,
}) => {
    // Leasing card visibility checker
    const ownershipCardVisibilityRef = useRef<HTMLDivElement>(null);

    const intersection = useIntersection(ownershipCardVisibilityRef, {
        root: null,
        rootMargin: '0px',
        threshold: 1,
    });

    useEffect(() => {
        if (intersection?.intersectionRatio === 1) {
            priceVisibilityChanged?.(false);
        } else if (intersection?.intersectionRatio !== 0) {
            priceVisibilityChanged?.(true);
        }
    }, [intersection?.intersectionRatio, priceVisibilityChanged]);

    return (
        <OwnershipInfoCardPrices key={`prices-${price}`} ref={ownershipCardVisibilityRef}>
            {overrideText ? (
                <StyledPriceWrapper>{overrideText}</StyledPriceWrapper>
            ) : (
                <>
                    <StyledPriceWrapper>
                        {isAFromPrice ? 'Fra ' : null}
                        {formatPrice(price)} {monthly ? Abbreviations.KR_SLASH_MD : Abbreviations.KR}
                    </StyledPriceWrapper>
                    {downPayment !== undefined && downPayment !== null ? (
                        <StyledDownPayment>
                            Udbetaling&nbsp;
                            {formatPrice(downPayment)} kr.
                        </StyledDownPayment>
                    ) : null}

                    {priceTextOverride ? (
                        <StyledVatMessage>{priceTextOverride}</StyledVatMessage>
                    ) : (
                        <StyledVatMessage>
                            {!isCompanyCar ? `Priser er ${vatIncluded ? 'ekskl.' : 'inkl.'} moms.` : `Pris inkl. moms og afgifter`}
                        </StyledVatMessage>
                    )}
                </>
            )}
        </OwnershipInfoCardPrices>
    );
};
