import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const StyledSeeAllLink = styled.a`
    order: 3;

    @media ${device.mobile_tablet} {
        order: revert;
    }
`;
