import { ChangeEventHandler, FC } from 'react';
import styled from 'styled-components';
import { mixins } from '../../../../themes';
import { BaseInput, BaseInputChildrenProps } from '../base';

const Textarea = styled.textarea`
    border: none;
    background-color: rgba(0, 0, 0, 0);
    &:focus {
        outline: none;
    }
    ${mixins.typography('inputValue')}
    ::placeholder {
        ${mixins.typography('inputPlaceholder')}
    }
    width: 100%;
    resize: none;
    padding: 0;
    margin-left: -3px;
`;

type Props = BaseInputChildrenProps<string> & {
    onChange: ChangeEventHandler<HTMLTextAreaElement>;
    readonly?: boolean;
    isValid: boolean;
    validationMessage?: string;
    canValidateInputField?: boolean;
    rows?: number;
    onInputBlur?: () => void;
    required?: boolean;
};

const TextareaInput: FC<Props> = ({ onChange, readonly = false, rows = 4, canValidateInputField = false, validationMessage, ...props }) => {
    return (
        <BaseInput
            multiline={true}
            {...props}
            canValidateInputField={canValidateInputField}
            validationMessage={validationMessage}
            textAreaWrapper={true}
        >
            {(childProps) => <Textarea {...childProps} onChange={onChange} readOnly={readonly} rows={rows} />}
        </BaseInput>
    );
};

export default TextareaInput;
