import { VFC } from 'react';
import { contentSpotTypes } from '../../../lib/api';
import styled from 'styled-components';

type HorizontalDividerSpotProps = {
    spot: contentSpotTypes.HorizontalDividerSpot;
    isFirstSpotInList: boolean;
};

const convertSpacing = (spot: contentSpotTypes.HorizontalDividerSpot) => {
    if (!spot.spacing) return 10;
    switch (spot.spacing) {
        case 'Small':
            return 10;
        case 'Medium':
            return 20;
        case 'Large':
            return 30;
        case 'X-Large':
            return 40;
        default:
            return 10;
    }
};

export const HorizontalDividerSpot: VFC<HorizontalDividerSpotProps> = ({ spot, isFirstSpotInList }) => {
    return (
        <HorizontalDividerWrapper id={spot.scrollAnchorId} color={spot.color} height={convertSpacing(spot)} isFirstSpotInList={isFirstSpotInList} />
    );
};

const HorizontalDividerWrapper = styled.div<{ color: string; height: number; isFirstSpotInList: boolean }>`
    width: 100%;
    height: ${(props) => `${props.height}px`};
    background-color: ${(props) => '#' + props.color};
    margin: ${(props) => (props.isFirstSpotInList ? `0 0 -10px` : '-10px 0')};
`;
