export const getYoutubeIframeUrl = (youtubeUrl: string): string => {
    const videoId = getYoutubeVideoId(youtubeUrl);
    if (videoId !== '') {
        return `https://www.youtube.com/embed/${videoId}`;
    }
    return '';
};

export const getYoutubeVideoId = (youtubeUrl: string): string => {
    if (youtubeUrl.split('?').length > 1) {
        const urlSearchParams = new URLSearchParams(youtubeUrl.split('?')[1]);
        const videoId = urlSearchParams.get('v');
        if (videoId) return videoId;
    } else {
        return youtubeUrl.substring(youtubeUrl.lastIndexOf('/'));
    }
    return '';
};
