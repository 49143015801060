import styled from 'styled-components';
import { queryTarget } from '../../../../lib/media-query';

export type CardPadding = 'large' | 'small' | 'none';
const getCardPadding = (padding: CardPadding | undefined) => {
    if (!padding || padding === 'none') return 0;

    return padding === 'small' ? '12px 14px' : '45px 32px';
};
export const StyledCard = styled.div<{ paddingStyle?: CardPadding }>`
    background: ${(props) => props.theme.palette.common.white};
    box-shadow: ${(props) => props.theme.shadows.large};
    padding: ${(props) => getCardPadding(props.paddingStyle)};
`;

export const StyledProductDetailGridItem = styled.div`
    border-bottom: 1px solid #d1d2d4;
    font-size: 14px;
`;
export const StyledProductDetailGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 19px 35px;
    margin-top: 23px;

    @media (max-width: ${queryTarget.laptop_tablet_landscape}px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

export const StyledProductDetailHeader = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
`;

export const StyledProductDetailDescription = styled.p`
    color: rgba(99, 99, 99, 1);
    font-size: 13px;
    font-weight: 400;
    text-align: left;
    margin-top: 8px;
    line-height: 16px;
`;
