import { VFC } from 'react';
import { BrandAndServiceModuleProps } from '../../brand-and-service-module/brand-and-service-module.component';
import { BrandAndServiceMobile } from '../../brand-and-service/mobile/brand-and-service-mobile.component';
import { StyledBrandAndServiceTable } from './mobile.styled';

type BrandAndServiceMobileProps = Pick<BrandAndServiceModuleProps, 'supportedBrands'> & { onCtaClick?: () => void };

export const BrandAndServiceTableMobile: VFC<BrandAndServiceMobileProps> = ({ supportedBrands, onCtaClick }) => {
    return (
        <StyledBrandAndServiceTable>
            {supportedBrands.map((x, index) => {
                return <BrandAndServiceMobile key={`${x.brand}:${index}`} brandInfo={x} onCtaClick={onCtaClick} />;
            })}
        </StyledBrandAndServiceTable>
    );
};
