import styled from 'styled-components';

import { ComplianceText } from './components/compliance-text.component';

export const IncludingDeliveryAndComplianceTextWrapper = styled.div`
    padding: 0 20px;
`;

export const PositionedComplianceText = styled(ComplianceText)``;

export const StyledIncludingDeliveryText = styled.p`
    color: #807f80;
    font-size: 11px;
    font-style: italic;
    margin-bottom: 5px;
`;
