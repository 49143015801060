import styled from 'styled-components';

export const StyledStockIndicator = styled.span<{ color: string }>`
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
`;

export const StyledStockInfo = styled.p`
    display: flex;
    gap: 12px;
    font-weight: 500;
    font-size: 10px;
    align-items: center;
`;
