import { FC } from 'react';
import styled, { CSSProperties } from 'styled-components';

type CoverImageProps = {
    src?: string;
    alt?: string;
    loading?: 'eager' | 'lazy';
    styles?: CSSProperties;
};

export const CoverImage: FC<CoverImageProps> = ({ src, alt = '', loading = 'eager', styles }) => {
    return <StyledCoverImage src={src} loading={loading} alt={alt} style={{ ...styles }} />;
};

export const StyledCoverImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    z-index: 0;
`;
