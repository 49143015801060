import { FC } from 'react';
import { VideoWrapper } from './hero-video.styled';

type Props = {
    autoPlay?: boolean;
    muted?: boolean;
    loop?: boolean;
    videoUrl?: string;
};

export const HeroVideo: FC<Props> = ({ autoPlay = true, muted = true, loop = true, videoUrl }) => {
    if (!videoUrl) {
        return null;
    }

    return (
        <VideoWrapper playsInline={true} autoPlay={autoPlay} muted={muted} loop={loop}>
            <source src={videoUrl} type="video/mp4"></source>
            Your browser does not support HTML5 video.
        </VideoWrapper>
    );
};
