import styled from 'styled-components';
import { device } from '../../../../../lib/media-query';

export const WorkshopBookingData = styled.div<{ isLoading: boolean }>`
    display: flex;
    flex-direction: column;

    @media ${device.tablet} {
        flex-direction: row;
        gap: 3rem;
    }
    pointer-events: ${(props) => (props.isLoading ? 'none' : 'revert')};
    cursor: ${(props) => (props.isLoading ? 'not-allowed' : 'revert')};
    filter: ${(props) => (props.isLoading ? 'grayscale(0.5) blur(2px)' : 'revert')};
`;

export const DeliveryWrapper = styled.div`
    width: 100%;
`;

export const DeliveryMethods = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1rem;

    & > div {
        margin-bottom: 0.75rem;
    }
`;

export const StyledHeader = styled.h4`
    margin-bottom: 1rem;
`;

export const LabelText = styled.p`
    margin-top: -2px;
`;

export const SubText = styled.p`
    font-size: 12px;
    line-height: 12px;
`;
