import { FC, useMemo } from 'react';
import { StyledStockIndicator, StyledStockInfo } from './stock-indicator.styled';
import { StockStatus } from '../../../lib/api/models/shop';
import { GlobalEcommerceSettingsContext } from '../../../lib/state/global-ecommerce-settings/global-ecommerce-settings.store';

type IProps = {
    stockStatus: StockStatus;
    inventory: number;
};

type StockInformation = {
    expectedDeliveryTime: string;
    color: string;
};

export const StockIndicator: FC<IProps> = ({ stockStatus }) => {
    const { settings } = GlobalEcommerceSettingsContext.useStoreState((state) => state);

    const stockIndicator: StockInformation = useMemo(() => {
        const stockStatusTranslations = settings?.stockStatusTranslations ?? [];
        const stockStatusTranslation = stockStatusTranslations.find((x) => x.stockStatusKey === stockStatus);

        return {
            expectedDeliveryTime: stockStatusTranslation ? stockStatusTranslation.stockStatusLabel : stockStatus.toString(),
            color:
                stockStatusTranslation && stockStatusTranslation.stockIndicatorColor.length > 0
                    ? `#${stockStatusTranslation.stockIndicatorColor}`
                    : stockStatus === 'OutOfStock'
                    ? 'red'
                    : '#28ce79',
        };
    }, [settings?.stockStatusTranslations, stockStatus]);

    return (
        <StyledStockInfo>
            <StyledStockIndicator color={stockIndicator.color} /> <span style={{ color: 'black' }}>{stockIndicator.expectedDeliveryTime}</span>
        </StyledStockInfo>
    );
};
