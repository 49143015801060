import { VFC } from 'react';
import { BorderBox, StyledLabel, ContentWrapper, DownPayment, Description, PriceWrapper } from './total-no-moms.styled';

type Props = {
    totalPriceNoMoms: string;
};

export const TotalNoMoms: VFC<Props> = ({ totalPriceNoMoms }) => {
    return (
        <BorderBox>
            <ContentWrapper>
                <StyledLabel>Pris i alt ekskl. moms</StyledLabel>
                <Description>Beskatningsgrundlag og momsfradrag fremgår af endelig kontrakt som fremsendes ved bestilling.</Description>
            </ContentWrapper>

            <PriceWrapper>
                <StyledLabel>{totalPriceNoMoms}</StyledLabel>
                <DownPayment>Udbetaling 0 kr.</DownPayment>
            </PriceWrapper>
        </BorderBox>
    );
};
