import styled from 'styled-components';
import { device } from '../../../lib/media-query';
import { mixins } from '../../../themes';

export const StyledBgWrapper = styled.div`
    background-color: #f7f7f8;
    padding: 40px 0;
`;

export const StyledHeader = styled.h2`
    font-family: 'Corporate E';
    font-size: 30px;
    line-height: 35px;
    // To preserver the same styling when users do not provide headerType and p is used as fallback
    font-weight: bold;
`;

export const StyledSubHeader = styled.p`
    margin-top: 10px;
`;

export const StyledCardWrapper = styled.div<{ cardsPerRow: string }>`
    margin-top: 50px;
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    @media ${device.tablet} {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media ${device.laptopS} {
        grid-template-columns: repeat(${(props) => props.cardsPerRow}, minmax(0, 1fr));
    }
`;

export const StyledCard = styled.div`
    img {
        max-width: 100%;
    }
`;

export const StyledImage = styled.img<{ useSquaredCorners?: boolean }>`
    ${({ useSquaredCorners }) => mixins.useSquaredCorners(useSquaredCorners)}
`;

export const StyledLinkWrapper = styled.a`
    text-decoration: none;
    color: #0b0b0b;

    &:hover {
        text-decoration: none;
    }
`;

export const StyledCardHeader = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 20px;
`;

export const StyledName = styled.p`
    font-family: 'Corporate E';
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
`;

export const StyledPrice = styled.p`
    line-height: 18px;
`;

export const StyledManchet = styled.p`
    margin-top: 3px;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.5;
`;

export const StyledDescription = styled.p`
    margin-top: 10px;
`;

export const StyledLink = styled.p`
    font-weight: 500;
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: 13px;
    margin-top: 8px;
    text-decoration: underline;
`;
