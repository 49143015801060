import styled from 'styled-components';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';

export const StyledPdpUspBar = styled.section`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    justify-items: center;
    column-gap: 10px;
    row-gap: 10px;
    padding: 50px 0;
`;

export const StyledCenteredBlock = styled(CenteredBlock)`
    background-color: #fff;
`;
