import styled from 'styled-components';
import { uiTypes } from '../../../../../lib';
import { device } from '../../../../../lib/media-query';

export const ContentWrapper = styled.div<{ hasIcon: boolean }>`
    display: grid;
    grid-template-columns: 1fr;
    @media ${device.tablet} {
        grid-template-columns: ${(props) => (props.hasIcon ? '30px 1fr' : '1fr')};
        grid-column-gap: 1.5rem;
    }
`;

export const StyledIcon = styled.img`
    display: none;
    width: 35px;

    @media ${device.tablet} {
        display: revert;
    }
`;

export const StyledDescription = styled.p`
    padding-left: 0;
    font-weight: normal;
    font-size: 12px;
    @media ${device.tablet} {
        font-size: 13px;
    }
`;

export const TitleAndDescription = styled.div`
    display: flex;
    flex-direction: column;
`;

export const TitleAndReadMore = styled.div`
    display: flex;
    gap: 5px;
`;

export const StyledTitle = styled.p<{ size: uiTypes.Size }>`
    color: #0b0b0b;
    font-size: ${(props) => (props.size === 'small' ? '12px' : '14px')};
    font-weight: 500;
    letter-spacing: 0;
`;

export const ReadMore = styled.span<{ size: uiTypes.Size }>`
    color: #0089d1;
    font-size: ${(props) => (props.size === 'small' ? '12px' : '14px')};
    font-weight: 500;
    letter-spacing: 0;
    cursor: pointer;
    text-decoration: underline;
`;
