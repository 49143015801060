import React, { useMemo, VFC } from 'react';
import {
    IconAndTextWrapper,
    StyledClickableText,
    StyledClock,
    StyledDealershipInfoCard,
    Header,
    StyledMapPin,
    StyledHoursWrapper,
    StyledText,
    StyledPhone,
    StyledAlert,
    SeeMoreBtn,
    ContentWrapper,
    CtaWrapper,
    BookTimeBtn,
    ButtonText,
    StyledDepartmentName,
} from './dealership-card.styled';
import { PinWithLetter } from '../../organization/find-dealerships/pin-with-letter/pin-with-letter.component';
import { FindDealershipsStore } from '../../../lib/state/find-dealership';
import { cmsUrlWithoutSiteId, getOpeningText, mapOpeningHours } from '../../../utils/helpers';
import { useUI } from '../../ui';
import { orgTypes } from '../../../lib/api/models/umbraco';
import { getGoogleMapsUrl } from '../../../utils/helpers/geo-location.helpers';
import { useRouter } from 'next/router';
import { ExternalLinkIcon } from '../../icons';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { GlobalDealershipSettingsContext } from '../../../lib/state/global-dealership-settings/global-dealership-settings.store';

type DealershipInfoCardProps = {
    className?: string;
    dealershipId: string;
    header: string;
    selected: boolean;
    letter: string;
    address: string;
    city: string;
    zipcode: string;
    phone: string;
    url: string;
    renderedForMobile?: boolean;
    departments?: Array<orgTypes.DealershipDepartment>;
    supportedBrands?: Array<orgTypes.SupportedBrand>;
    ctaAction?: () => void;
};

export const DealershipInfoCard: VFC<DealershipInfoCardProps> = ({
    className,
    dealershipId,
    header,
    selected,
    letter,
    address,
    city,
    zipcode,
    phone,
    url,
    renderedForMobile,
    departments = [],
    supportedBrands = [],
    ctaAction,
}): JSX.Element => {
    const { selectedBrand, renderGoogleMaps } = FindDealershipsStore.useStoreState((state) => state);
    const { setShowEmergencyNumbersSidePanel } = FindDealershipsStore.useStoreActions((actions) => actions);
    const dealershipSettings = GlobalDealershipSettingsContext.useStoreState((state) => state.settings);

    const uiHandler = useUI();
    const router = useRouter();

    const canBookWorkshop = useMemo(() => {
        if (!departments.some((x) => x.departmentType === 'Værksted')) {
            return false;
        }

        if (!selectedBrand || selectedBrand === 'Alle') {
            return supportedBrands.some((x) => x.vehiclesInWorkshop.length > 0);
        }

        return supportedBrands.some((x) => x.vehiclesInWorkshop.length > 0 && x.brand === selectedBrand);
    }, [departments, selectedBrand, supportedBrands]);

    const mappedDepartments = React.useMemo(() => {
        return mapOpeningHours(
            departments.filter((x) => x.openingHours?.length > 0),
            dealershipSettings?.defaultSpecialDays
        );
    }, [departments, dealershipSettings?.defaultSpecialDays]);

    const atLeastOneWorkshopDepartment = useMemo(() => departments.some((x) => x.departmentType === 'Værksted'), [departments]);

    const isMobile = useMediaQuery({ target: 'mobile_tablet' });

    function goToDealershipPage() {
        router.push(cmsUrlWithoutSiteId(url.replace('/hire-forside', '')));
    }

    return (
        <ContentWrapper
            id={dealershipId}
            className={className}
            selected={selected}
            onClick={(e) => {
                e.stopPropagation();
                goToDealershipPage();
            }}
        >
            {!isMobile && renderGoogleMaps ? <PinWithLetter selected={selected} letter={letter} /> : <div />}
            <StyledDealershipInfoCard>
                <Header>{header}</Header>

                <IconAndTextWrapper>
                    <StyledMapPin />
                    <StyledText>
                        {address}, {zipcode} {city}
                    </StyledText>
                    <a onClick={(e) => e.stopPropagation()} href={getGoogleMapsUrl(address, zipcode).toString()} target={'_blank'} rel="noreferrer">
                        <ExternalLinkIcon height={20} width={20} viewBox="0 0 25 25" />
                    </a>
                </IconAndTextWrapper>

                <IconAndTextWrapper>
                    <StyledClock />
                    <StyledHoursWrapper>
                        {mappedDepartments.map((dep, index) => {
                            return (
                                <StyledText key={`dep-${dep.departmentName}:${index}`}>
                                    <StyledDepartmentName>{dep.departmentName}</StyledDepartmentName> - {getOpeningText(dep.openingHours)}
                                </StyledText>
                            );
                        })}
                    </StyledHoursWrapper>
                </IconAndTextWrapper>

                <IconAndTextWrapper>
                    <StyledPhone />
                    <StyledClickableText href={`tel:${phone}`}>{phone}</StyledClickableText>
                </IconAndTextWrapper>

                <IconAndTextWrapper>
                    <StyledAlert />
                    <StyledClickableText
                        onClick={(e) => {
                            e.stopPropagation();
                            if (renderedForMobile) {
                                uiHandler.removeScrollAndLock();
                            } else {
                                uiHandler.removeScroll();
                            }
                            setShowEmergencyNumbersSidePanel({ visible: true, dealershipId });
                        }}
                    >
                        Se nødservice telefon
                    </StyledClickableText>
                </IconAndTextWrapper>

                <CtaWrapper>
                    <SeeMoreBtn
                        onClick={(e) => {
                            e.stopPropagation();
                            goToDealershipPage();
                        }}
                        variant="primary"
                    >
                        <ButtonText>Se mere</ButtonText>
                    </SeeMoreBtn>

                    {atLeastOneWorkshopDepartment ? (
                        <BookTimeBtn
                            onClick={(e) => {
                                e.stopPropagation();
                                ctaAction?.();
                            }}
                            disabled={!canBookWorkshop}
                        >
                            <ButtonText>Book tid på værksted</ButtonText>
                        </BookTimeBtn>
                    ) : null}
                </CtaWrapper>
            </StyledDealershipInfoCard>
        </ContentWrapper>
    );
};
