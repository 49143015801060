import { useState } from 'react';

type AccordionController = {
    registerAccordion: (index: number) => {
        index: number;
        onClick: () => void;
        isOpen: boolean;
    };
};

export const useAccordionController = (initialOpenIndex: number | number[] = -1): AccordionController => {
    const [openAccordionIndexs, setOpenAccordionIndexs] = useState(initialOpenIndex instanceof Array ? initialOpenIndex : [initialOpenIndex]);

    const handleAccordionClick = (newIndex: number) => {
        if (openAccordionIndexs.includes(newIndex)) {
            if (openAccordionIndexs.length === 1) {
                setOpenAccordionIndexs([]);
            } else {
                const arr = [...openAccordionIndexs]
                    .map((i) => {
                        return i !== newIndex ? i : -1;
                    })
                    .filter((number) => number !== -1);
                setOpenAccordionIndexs(arr);
            }
        } else {
            setOpenAccordionIndexs([...openAccordionIndexs, newIndex]);
        }
    };

    const registerAccordion = (index: number) => ({
        index,
        onClick: () => handleAccordionClick(index),
        isOpen: openAccordionIndexs.includes(index),
    });

    return {
        registerAccordion,
    };
};
