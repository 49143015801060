import { deleteAPI, postAPI } from '../helpers';
import { CartObject } from '../models/shop/cart';
import { APIResponse } from '../types';

export const addVoucher = async (basketId: string, voucherCode: string): APIResponse<CartObject> => {
    return postAPI<CartObject>(`/api/v1/commerce/basket/${basketId}/coupon/${voucherCode}`);
};

export const removeVoucher = async (basketId: string, voucherCode: string): APIResponse<CartObject> => {
    return deleteAPI<CartObject>(`/api/v1/commerce/basket/${basketId}/coupon/${voucherCode}`);
};
