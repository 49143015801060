/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { DispatchMethod, GtmItem } from '../gtm-tracking.types';
import { defaultCheckoutDataLayer, defaultCheckoutOptionDataLayer, defaultPurchaseDataLayer } from '../gtm-tracking-utils';

export type BookingEvents = {
    checkoutOption: (step: number, option?: string, assignSource?: any) => void;
    checkoutStep: (step: number, products: GtmItem[], assignSource?: any) => void;
    purchase: (affiliation: string, revenue: number, tax: number, id: string, products: GtmItem[], customerHasBenefitAgreement: boolean) => void;
    customerAndBenefitAgreement: (hasAgreement: boolean) => void;
};

const useBookingEvents = (dispatch: DispatchMethod): BookingEvents => {
    const type = 'servicebooking';

    return useState({
        checkoutOption: (step: number, option?: string, assignSource?: any) =>
            dispatch({
                ...defaultCheckoutOptionDataLayer(type, step, option),
                ...assignSource,
            }),

        checkoutStep: (step: number, products: GtmItem[], assignSource?: any) =>
            dispatch({
                ...defaultCheckoutDataLayer(type, step, products),
                ...assignSource,
            }),

        purchase: (affiliation: string, revenue: number, tax: number, id: string, products: GtmItem[], customerHasBenefitAgreement: boolean) =>
            dispatch({
                ...defaultPurchaseDataLayer(
                    type,
                    {
                        affiliation,
                        id,
                        revenue,
                        shipping: 0,
                        tax,
                    },
                    products
                ),
                vaerksted: affiliation,
                discount: 0,
                fordelskunde: customerHasBenefitAgreement,
            }),
        customerAndBenefitAgreement: (hasAgreement: boolean) =>
            dispatch({
                type: type,
                event: 'fordelskunde',
                fordelskunde: hasAgreement,
            }),
    })[0];
};

export const trackBooking = (dispatch: DispatchMethod): (() => BookingEvents) => {
    return () => useBookingEvents(dispatch);
};
