import React, { useRef, VFC } from 'react';

import { StyledDealershipMap } from './simple-maps.styled';
import { SimpleMarker } from '../simple-marker/simple-marker.component';

export type MapProps = {
    center: google.maps.LatLngLiteral;
    zoom: number;
};

export const SimpleMap: VFC<MapProps> = ({ center, zoom }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = React.useState<google.maps.Map>();

    React.useEffect(() => {
        if (ref.current && !map) {
            setMap(
                new window.google.maps.Map(ref.current, {
                    center,
                    zoom,
                    mapTypeControl: false,
                    streetViewControl: false,
                    fullscreenControl: false,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.TOP_RIGHT,
                    },
                    gestureHandling: 'cooperates',
                })
            );
        }
    }, [ref, map, center, zoom]);

    return (
        <>
            <StyledDealershipMap ref={ref} id="hire-dealership-map" />
            <SimpleMarker map={map} position={center} />
        </>
    );
};
