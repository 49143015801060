import styled, { css } from 'styled-components';
import { device } from '../../../lib/media-query';
import { MINIMAL_SITE_HEADER_HEIGHT } from '../../../lib/enums';

export const StyledMinimalHeader = styled.header<{ scrolled?: boolean }>`
    background-color: transparent;
    padding: 10px 0;
    height: ${MINIMAL_SITE_HEADER_HEIGHT.MOBILE}px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    width: 100%;
    transition: background-color 0.15s;
    will-change: background-color;

    @media ${device.tablet} {
        height: ${MINIMAL_SITE_HEADER_HEIGHT.DESKTOP}px;
    }

    ${(props) =>
        props.scrolled &&
        css`
            background-color: ${({ theme }) => theme.palette.primary.main};
        `}
`;

export const StyledHeaderContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    margin: 0 auto;
    max-width: calc(1340px + 40px);
    padding: 0 20px;
    width: 100%;

    @media ${device.tablet} {
        max-width: calc(1340px + 60px);
        padding-left: 30px;
        padding-right: 30px;
    }
`;

export const StyledHeaderLogoLink = styled.a`
    display: block;
    text-decoration: none;
`;

export const StyledImageLogo = styled.img`
    display: block;
    height: 100%;
    width: 100%;
    max-width: 120px;
    object-fit: contain;

    @media ${device.tablet} {
        max-width: 140px;
    }
`;
