import { ShiftBy } from '../../shift-by';
import { FC } from 'react';
import { ICircle } from './circle.props';
import { StyledCircle } from './circle.styled';

export const Circle: FC<ICircle> = ({ xOffset, yOffset, children }) => {
    return (
        <StyledCircle>
            <ShiftBy x={xOffset} y={yOffset}>
                {children}
            </ShiftBy>
        </StyledCircle>
    );
};
