import styled, { DefaultTheme } from 'styled-components';
import { animated } from 'react-spring';
import { device } from '../../../lib/media-query';
import { Borders } from '../../../lib/ui-types';

const getTextColor = (theme: DefaultTheme, textColor: string) => {
    if (textColor && textColor !== '') {
        return `#${textColor}`;
    }

    return theme.palette.common.black;
};

export const StyledAccordion = styled.div<{ border: Borders; borderColor: string; background: string; contentPadding: string }>`
    background: ${(props) => `#${props.background}`};
    border-top: ${(props) => (props.border === 'top' || props.border === 'top and bottom' ? `1px solid #${props.borderColor}` : 'none')};
    border-bottom: ${(props) => (props.border === 'bottom' || props.border === 'top and bottom' ? `1px solid #${props.borderColor}` : 'none')};
    padding: ${(props) => props.contentPadding};
`;

export const AnimatedWrapper = styled(animated.div)``;

export const StyledContent = styled.div<{ textColor: string }>`
    color: ${(props) => getTextColor(props.theme, props.textColor)};
`;

export const StyledHeader = styled.div<{ isDisabled: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    pointer-events: ${(props) => (props.isDisabled ? 'none' : 'revert')};
`;

export const Header = styled.h3<{ size: string; textColor: string }>`
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    font-size: 15px;
    font-weight: ${(props) => (props.size === 'sm' ? '500' : '600')};
    letter-spacing: 0;
    line-height: ${(props) => (props.size === 'sm' ? '1.5rem' : '1.6875rem')};
    color: ${(props) => getTextColor(props.theme, props.textColor)};
    width: 95%;

    @media ${device.tablet} {
        font-size: ${(props) => props.size};
    }
`;

export const StyledWrapper = styled(animated.div)``;

export const StyledIcon = styled(animated.div)`
    align-self: center;

    @media ${device.mobileL} {
        padding-right: revert;
    }
`;

export const PlusWrapper = styled.div`
    position: relative;
    width: 20px;
`;

export const PlusIndicator = styled(animated.div)`
    position: absolute;
    height: 2px;
    width: 14px;
    background-color: ${({ theme: { palette } }) => palette.common.black};
    transition: all 0.2s ease-in-out;
`;
