import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const StyledRecommendedSearchWordsHeader = styled.p`
    font-family: 'Corporate E';
    font-size: 18px;
    line-height: 19px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #767676;
`;

export const StyledRecommendedSearchWord = styled.p`
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;

    @media ${device.laptop} {
        font-size: 16px;
    }
`;

export const StyledNotPartOfCurrentSearchTerm = styled.span`
    color: #0b0b0b4d;
`;

export const StyledSearchWordWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
