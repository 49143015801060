import { FC, useMemo } from 'react';
import ServiceAgreementContainer from '../service-agreement-container/service-agreement-container.component';
import { StyledLabel } from '../service-agreement-step.styled';
import { RadioButton } from '../../../../shared/radio-button';
import { ServiceAgreementCalculatorModalStore } from '../../../../../lib/state/service-agreement-calculator/service-agreement-calculator.store';
import { StyledHr } from '../../service-agreement-calculator-modal.styled';
import { ServiceCareAgreement } from '../../../../../lib/api/models/hessel-api';
import { formatPrice } from '../../../../../utils/helpers';

type Props = {
    agreements: ServiceCareAgreement[];
    description?: string;
};

export const ServiceCareAgreementOption: FC<Props> = ({ agreements, description }) => {
    const { selectedServiceCare } = ServiceAgreementCalculatorModalStore.useStoreState((states) => states);
    const { setSelectedServiceCare, clearAllAgreements } = ServiceAgreementCalculatorModalStore.useStoreActions((actions) => actions);

    const distinctMonths = useMemo(() => {
        return [...agreements]
            .sort((a, b) => (a.months > b.months ? 1 : -1))
            .filter((agreement, index, self) => index === self.findIndex(({ months }) => months === agreement.months));
    }, [agreements]);

    const formattedPrice = useMemo(() => {
        const price = selectedServiceCare?.price ?? Math.min(...agreements.map((agreement) => agreement.price));
        return `${formatPrice(price)} kr./mdr.`;
    }, [selectedServiceCare, agreements]);

    return (
        <ServiceAgreementContainer
            id="serviceCare"
            onChecked={() => {
                clearAllAgreements();
                const months = distinctMonths[0].months;
                setSelectedServiceCare(agreements.find((agreement) => agreement.months === months));
            }}
            onUnchecked={() => {
                setSelectedServiceCare(undefined);
            }}
            checked={!!selectedServiceCare}
            title="Mercedes-Benz ServiceCare"
            titleDescription={description}
            priceText={formattedPrice}
        >
            <StyledHr />
            <StyledLabel>Hvor lang tid ønsker du din serviceaftale?</StyledLabel>

            {distinctMonths.map((agreement, index) => {
                return (
                    <RadioButton
                        key={agreement.months}
                        value={''}
                        id={`serviceCareMonths${index}`}
                        groupName="serviceCareMonths"
                        checked={agreement.months === selectedServiceCare?.months}
                        action={() => {
                            setSelectedServiceCare(agreement);
                        }}
                    >
                        <label>{agreement.months} måneder</label>
                    </RadioButton>
                );
            })}

            <StyledLabel>Hvor mange services?</StyledLabel>
            {agreements
                .filter((agreement) => agreement.months === selectedServiceCare?.months)
                .map((agreement, index) => {
                    return (
                        <RadioButton
                            key={index}
                            value={''}
                            id={`serviceCareServices${index}`}
                            groupName="serviceCareServices"
                            checked={agreement === selectedServiceCare}
                            action={() => {
                                setSelectedServiceCare(agreement);
                            }}
                        >
                            <label>{agreement.totalServiceInspections} services</label>
                        </RadioButton>
                    );
                })}
        </ServiceAgreementContainer>
    );
};
