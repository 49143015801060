import { VFC } from 'react';
import {
    BorderBox,
    ImageWrapper,
    StyledImage,
    TextsWrapper,
    CarName,
    CarModel,
    CarColor,
    StyledRemove,
    ColorTextAndImage,
    StyledSummaryColorCircle,
} from './car-summary.styled';

export type CarSummaryProps = {
    imageUrl: string;
    colorUrl: string;
    brand: string;
    model: string;
    colorName: string;
    showRemove?: boolean;
    onRemoveButtonClick?: () => void;
};

export const CarSummary: VFC<CarSummaryProps> = ({ imageUrl, brand, model, colorName, colorUrl, showRemove, onRemoveButtonClick }) => {
    return (
        <BorderBox>
            <ImageWrapper>
                <StyledImage src={imageUrl} />
            </ImageWrapper>
            <TextsWrapper>
                <CarName>{brand}</CarName>
                <CarModel>{model}</CarModel>
                <ColorTextAndImage>
                    <StyledSummaryColorCircle imageUrl={colorUrl}></StyledSummaryColorCircle>
                    <CarColor title={colorName}>{colorName}</CarColor>
                </ColorTextAndImage>
            </TextsWrapper>
            {showRemove ? <StyledRemove onClick={() => onRemoveButtonClick?.()}>Fjern bil</StyledRemove> : null}
        </BorderBox>
    );
};
