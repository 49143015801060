import React, { FC, useMemo } from 'react';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import { PdpVanMeasurementModal } from '../../../../lib/api/models/umbraco/product-details';
import { PdpResource } from '../../../../lib/view-models/vehicle';
import { MEDIA_URL } from '../../../../utils/environment-constants';
import { sanitizeMarkup } from '../../../../utils/helpers';
import { CtaHandler } from '../../../plus-sites-shared/content-handlers';
import { CompactCarousel } from '../../../shared/carousels/compact-carousel/compact-carousel.component';
import { SimpleCarousel } from '../../../shared/carousels/simple-carousel/simple-carousel.component';
import { SidePanel } from '../../../shared/modals/side-panel';
import { SidePanelLayout } from '../../../shared/modals/side-panel-layout/side-panel-layout.component';
import { CtaWrapper, Header, StyledDescription } from './van-measure-guide-modal.styled';

type VanMeasureGuideModalProps = {
    visible: boolean;
    onClose: () => void;
    modalSettings: PdpVanMeasurementModal;
};

export const VanMeasureGuideModalMemo: FC<VanMeasureGuideModalProps> = ({ visible, onClose, modalSettings }) => {
    const isMobile = useMediaQuery({ target: 'tablet' });

    const carouselItems = useMemo(() => {
        return (
            modalSettings.mediaList.map((x) => {
                if (x.media.type === 'image' || x.media.extension?.toLowerCase() === 'webp') {
                    return {
                        type: 'image',
                        url: MEDIA_URL + x.media.src,
                        caption: x.caption,
                    } as PdpResource;
                }

                if (x.media.type === 'file') {
                    return {
                        type: 'video',
                        url: MEDIA_URL + x.media.src,
                        caption: x.caption,
                    } as PdpResource;
                }

                throw new Error('Unknown media type');
            }) || []
        );
    }, [modalSettings]);

    return (
        <SidePanel cancelAction={onClose} isVisible={visible}>
            <SidePanelLayout closeSidePanel={onClose}>
                <Header>{modalSettings.header}</Header>

                <StyledDescription {...sanitizeMarkup(modalSettings.description)} />

                {isMobile ? (
                    <CompactCarousel slides={carouselItems} isAboveFold={false} />
                ) : (
                    <SimpleCarousel slides={carouselItems} isAboveFold={false} />
                )}

                {modalSettings.cta ? (
                    <CtaWrapper>
                        <CtaHandler ctas={modalSettings.cta} />
                    </CtaWrapper>
                ) : null}
            </SidePanelLayout>
        </SidePanel>
    );
};

export const VanMeasureGuideModal = React.memo(VanMeasureGuideModalMemo);
