import { hireCheckoutTypes } from '../../../../api/models/umbraco';
import { HireStepType } from '../../../../api/models/umbraco/hire-site/lead-checkout';
import { HireStep, PickHireStepType } from './steps.types';

export const convertHireCheckoutStepToStepState = (step: hireCheckoutTypes.HireCheckoutStep): HireStep => {
    switch (step.alias) {
        case 'checkoutStepUserInformation':
            return {
                type: 'checkoutStepUserInformation',
                content: step,
                isComplete: false,
                isValid: false,
                canValidateHireStep: false,
                isStepLoading: false,
            };

        case 'checkoutStepAddonsAndEquipment':
            return {
                type: 'checkoutStepAddonsAndEquipment',
                content: step,
                isComplete: false,
                isValid: true,
                canValidateHireStep: false,
                isStepLoading: false,
            };

        case 'checkoutStepDurationAndDelivery':
            return {
                type: 'checkoutStepDurationAndDelivery',
                content: step,
                isComplete: false,
                isValid: true,
                canValidateHireStep: false,
                isStepLoading: false,
            };
        case 'checkoutStepReceipt':
            return {
                type: 'checkoutStepReceipt',
                content: step,
                isComplete: false,
                isValid: true,
                canValidateHireStep: false,
                isStepLoading: false,
            };

        default:
            return {
                type: 'Unknown',
                content: {
                    alias: 'Unknown',
                    submitButton: '',
                    title: '',
                    hideBlock: false,
                    fromDate: new Date(),
                    toDate: new Date(),
                    scrollAnchorId: '',
                },
                isComplete: false,
                isValid: false,
                canValidateHireStep: false,
                isStepLoading: false,
            };
    }
};

export const filterStepType =
    <T extends HireStepType>(type: T) =>
    (step: HireStep): step is PickHireStepType<T> =>
        step.type === type;
