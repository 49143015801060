import React, { FC } from 'react';
import { Props } from './youtube-video-iframe.props';
import { VideoContainer, YoutubeIframe } from './youtube-video-iframe.styled';

const YoutubeVideoIframe: FC<Props> = ({ watchId, width, height }) => {
    return (
        <VideoContainer width={width} height={height}>
            <YoutubeIframe
                src={`https://www.youtube.com/embed/${watchId}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
        </VideoContainer>
    );
};

export default YoutubeVideoIframe;
