import { memo } from 'react';
import { useScrollLock } from '../../../../hooks/use-scroll-lock';
import { EquipmentDialogManagerStore } from '../../../../lib/state/hessel-site/equipment-dialog-manager';
import { ProductDetailsStore } from '../../../../lib/state/hessel-site/product-details';
import EquipmentCard from '../equipment-card/equipment-card.components';

const ProductDetailsPageUsedCarPanelComponent = memo(() => {
    const { selectedProduct } = ProductDetailsStore.useStoreState((state) => state);
    const { setShowAllEquipment } = EquipmentDialogManagerStore.useStoreActions((actions) => actions);
    const { hideScrollBars } = useScrollLock();
    return selectedProduct ? (
        <>
            {selectedProduct.equipmentNames.length > 0 && (
                <EquipmentCard
                    items={selectedProduct.equipmentNames}
                    onShowSpecsClick={() => {
                        setShowAllEquipment(true);
                        hideScrollBars();
                    }}
                    showMoreRelevant={selectedProduct.equipmentNames.length > 10}
                />
            )}
        </>
    ) : null;
});

export default ProductDetailsPageUsedCarPanelComponent;
