import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { usePushError } from '../../../../error-handling';
import { GtmTrackingProvider, propsFromSettings } from '../../../../gtm-tracking/components/gtm-tracking-provider.component';
import { ErrorType, umbraco } from '../../../../lib/api';
import { DisplayManagerStore } from '../../../../lib/state/display-manager';
import { FormInfoWithId, FormsManagerStore } from '../../../../lib/state/plus-sites/forms-display-manager';
import { CustomerSavingsStore } from '../../../../lib/state/plus-sites/savings';
import { convertUmbracoModelToSavings } from '../../../../lib/state/plus-sites/savings/savings.helpers';
import { ReepayStore } from '../../../../lib/state/reepay-form/reepay-form.store';
import { formInfoFromSpot } from '../../../../utils/helpers';
import { PlusSubscriptionPageReceipt } from '../../../plus/plus-site/plus-subscription-page-receipt.component';
import { UiProvider } from '../../../ui';
import ReepayForm from '../reepay-form/reepay-form.component';
import { PlusSiteProviderProps } from './plus-site-provider.types';

export const PlusSiteProvider: FC<PlusSiteProviderProps> = ({
    children,
    siteSettings,
    page,
    initialValidationState,
    customerSavings,
    isFromBooking,
}) => {
    const { pushError } = usePushError();

    const displayErrors = useCallback(
        (error: ErrorType) => {
            pushError(error);
        },
        [pushError]
    );

    const reepayFormContent = useMemo(() => {
        return siteSettings?.marketSettings.map(({ reepaySettings }) => {
            return reepaySettings?.reepayFormContent[0];
        })[0];
    }, [siteSettings]);

    const gtmProps = useMemo(() => propsFromSettings(siteSettings), [siteSettings]);

    const reepayComparisonModule = useMemo(() => {
        return siteSettings?.marketSettings.map(({ reepaySettings }) => {
            return reepaySettings?.serviceComparison[0];
        })[0];
    }, [siteSettings]);

    const getFormsList = (page: umbraco.Page): FormInfoWithId[] => {
        if (
            page.contentTypeAlias === 'plusRootPage' ||
            page.contentTypeAlias === 'starPlusRootPage' ||
            page.contentTypeAlias === 'fordPlusRootPage' ||
            page.contentTypeAlias === 'plusSubscriptionPage' ||
            page.contentTypeAlias === 'carSaverRootPage'
        ) {
            return page.sidebarContent.map(formInfoFromSpot);
        }

        return [];
    };

    const [reepayStoreId, setReepayStoreId] = useState('');
    useEffect(() => {
        if ((page.contentTypeAlias === 'plusSubscriptionPage' || page.contentTypeAlias === 'carSaverRootPage') && page.id !== reepayStoreId) {
            setReepayStoreId(page.id);
        }
    }, [page, reepayStoreId]);

    if (page.contentTypeAlias === 'receiptPage') {
        return (
            <GtmTrackingProvider {...gtmProps}>
                <ReepayStore.Provider>
                    <FormsManagerStore.Provider runtimeModel={{ formList: getFormsList(page) }}>
                        <DisplayManagerStore.Provider>
                            <PlusSubscriptionPageReceipt page={page} />
                        </DisplayManagerStore.Provider>
                    </FormsManagerStore.Provider>
                </ReepayStore.Provider>
            </GtmTrackingProvider>
        );
    }

    return (
        <GtmTrackingProvider {...gtmProps}>
            <DisplayManagerStore.Provider>
                <CustomerSavingsStore.Provider
                    runtimeModel={{
                        savings: customerSavings?.savingItems?.map(convertUmbracoModelToSavings),
                        yearlyMembership: customerSavings?.yearlyMembershipPrice,
                        yearlyCompleteMembership: customerSavings?.yearlyCompleteMembershipPrice
                            ? customerSavings.yearlyCompleteMembershipPrice === 0
                                ? undefined
                                : customerSavings.yearlyCompleteMembershipPrice
                            : undefined,
                    }}
                    injections={{ pushError: displayErrors }}
                >
                    <ReepayStore.Provider
                        runtimeModel={{
                            agreements: reepayFormContent.benefitAgreements,
                        }}
                        key={reepayStoreId === '' ? page.id : reepayStoreId}
                    >
                        <FormsManagerStore.Provider runtimeModel={{ formList: getFormsList(page) }}>
                            <UiProvider>
                                {children}
                                <ReepayForm
                                    reepayFormContent={reepayFormContent}
                                    serviceComparison={reepayComparisonModule}
                                    initialValidationState={initialValidationState}
                                    isFromBooking={isFromBooking}
                                />
                            </UiProvider>
                        </FormsManagerStore.Provider>
                    </ReepayStore.Provider>
                </CustomerSavingsStore.Provider>
            </DisplayManagerStore.Provider>
        </GtmTrackingProvider>
    );
};
