import { VFC, useMemo } from 'react';
import { useAccordionController } from '../../../hooks/use-accordion-controller';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { BrandBarContent } from '../../../lib/api/models/umbraco';
import { Accordion } from '../accordion';
import { CustomLink } from '../custom-link';
import { MobileWrapper, MobileContent, AccordionContent, AccordionLink } from './brand-bar-mobile.styled';
import { BrandBarItem, BrandBarWrapper, CarClassHeader, ModelsWrapper, LinkWrapper, BrandBarDisplayContainer } from './brand-bar.styled';
import Link from 'next/link';
import { isExternalLink } from '../../../utils/helpers';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';
import { MenuContext } from '../../mega-menu/mega-menu.component';

type Props = {
    brandBarSettings?: BrandBarContent;
    menuContext?: MenuContext;
};

export const BrandBar: VFC<Props> = ({ brandBarSettings, menuContext }) => {
    const isMobile = useMediaQuery({ target: 'tablet' });

    const { registerAccordion } = useAccordionController();

    const brandBarList = useMemo(() => {
        if (brandBarSettings) {
            if (menuContext === 'erhverv') return brandBarSettings.erhvervBrandBarList;
            return brandBarSettings.brandBarList;
        }
        return [];
    }, [brandBarSettings, menuContext]);

    return !isMobile ? (
        <BrandBarWrapper>
            <CenteredBlock>
                <BrandBarDisplayContainer>
                    {brandBarList.map((x, index) => {
                        return (
                            <BrandBarItem key={`brand-bar-class-${index}`}>
                                <CarClassHeader>
                                    <CustomLink url={x.classUrl} urlText={x.className} />
                                </CarClassHeader>
                                <ModelsWrapper columns={index === 0 ? 2 : 1}>
                                    {x.classModels.map((m, modelIndex) => {
                                        return (
                                            <LinkWrapper key={`${x.className}-${m.modelName}-${modelIndex}`}>
                                                <CustomLink url={m.modelUrl} urlText={m.modelName} />
                                            </LinkWrapper>
                                        );
                                    })}
                                </ModelsWrapper>
                            </BrandBarItem>
                        );
                    })}
                </BrandBarDisplayContainer>
            </CenteredBlock>
        </BrandBarWrapper>
    ) : (
        <MobileWrapper>
            {brandBarList.map((carClass, index) => {
                return (
                    <MobileContent key={`brand-bar-mobile-col-${carClass.className}-${index}`}>
                        <Accordion title={carClass.className} headerSize="md" variant="dark" border="bottom" {...registerAccordion(index)}>
                            <AccordionContent>
                                {carClass.classModels.map((model, mIndex) => {
                                    return (
                                        <li key={`link-${carClass.className}-${model.modelName}-${mIndex}`}>
                                            <Link href={model.modelUrl.url} passHref={true}>
                                                <AccordionLink target={isExternalLink(model.modelUrl) ? '_blank' : '_self'}>
                                                    {model.modelName}
                                                </AccordionLink>
                                            </Link>
                                        </li>
                                    );
                                })}
                            </AccordionContent>
                        </Accordion>
                    </MobileContent>
                );
            })}
        </MobileWrapper>
    );
};
