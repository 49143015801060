import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const Wrapper = styled.div`
    position: relative;
    border: 1px solid ${({ theme }) => theme.palette.grey.medium};
    background-color: ${({ theme }) => theme.palette.grey.main};
    box-shadow: ${({ theme }) => theme.shadows.large};
    width: 100%;
    padding: 20px 20px;

    @media ${device.tablet} {
        display: grid;
        grid-template-columns: 50px 50px auto;
        align-items: center;
        /* height: 100%; */
        padding-top: 15px;
    }
`;

export const StyledCheckmark = styled.div`
    position: absolute;
    right: 5px;
    top: 5px;
    display: inline-block;

    @media ${device.tablet} {
        position: unset;
        right: unset;
        top: unset;
    }
`;

export const InfoWrapper = styled.div`
    padding-left: 10px;
`;

export const StyledLabel = styled.p`
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
`;

export const StyledText = styled.p`
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;
`;
