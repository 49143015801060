import { getAPI } from '../helpers';
import { DealershipEmployee } from '../models/umbraco/organization.types';
import { APIResponse } from '../types';

export type EmployeesApiQueryRequest = {
    brandNames?: string[];
    departmentIds?: number[];
    vehicleTypes?: string[];
    workDepartments?: string[];
    orderByDescending?: boolean;
};

export const getEmployeesByQuery = async (params: EmployeesApiQueryRequest): APIResponse<Array<DealershipEmployee>> => {
    return getAPI<Array<DealershipEmployee>>(`api/v2/employee`, params);
};

export const getEmployeesByIds = (userIds: string[]): APIResponse<Array<DealershipEmployee>> => {
    return getAPI<Array<DealershipEmployee>>(`api/v1/employee/by-id`, {
        userIds,
    });
};
