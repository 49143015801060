import styled from 'styled-components';

export const StyledPaymentPlanButton = styled.button`
    appearance: none;
    background: none;
    border: none;
    color: ${(props) => props.theme.palette.common.black};
    cursor: pointer;
    display: inline-grid;
    font: 500 12px/24px ${({ theme }) => theme.fontFamily.secondary}, Sans-serif;
    gap: 5px;
    grid: auto / 25px 1fr;
    padding: 0;

    &:focus-visible {
        outline: 1px dotted currentColor;
        outline-offset: 2px;
    }
`;

export const StyledPaymentPlanButtonIcon = styled.img`
    height: 25px;
    object-fit: contain;
    width: 25px;
`;

export const StyledPaymentPlanButtonLabel = styled.span`
    text-decoration: underline;
`;
