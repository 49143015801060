import { VFC } from 'react';
import Image from 'next/image';
import { NotesWithIconWrapper, Content, StyledHeader, StyledText, ImageWrapper } from './note-with-icon.styled';
import { sharedTypes } from '../../../lib/api/models/umbraco';

type Props = {
    header?: string;
    text?: string;
    icon?: sharedTypes.Image;
    index: number;
};

const MEDIA_URL = process.env.NEXT_PUBLIC_MEDIA_URL;

export const NoteWithIcon: VFC<Props> = ({ header, text, icon, index }) => {
    return (
        <NotesWithIconWrapper>
            {icon && icon.src ? (
                <ImageWrapper>
                    <Image alt="receipt note icon" src={`${MEDIA_URL}${icon?.src}`} width={50} height={50} layout="fixed" />
                </ImageWrapper>
            ) : (
                <div></div>
            )}

            <Content>
                {header && header.length > 0 ? (
                    <StyledHeader>
                        {index + 1}. {header}
                    </StyledHeader>
                ) : null}

                {text && text.length > 0 ? <StyledText>{text}</StyledText> : null}
            </Content>
        </NotesWithIconWrapper>
    );
};
