import { action, persist } from 'easy-peasy';
import { DisplayManagerActions, DisplayManagerInjections, DisplayManagerState, HireDisplayManagerStore } from '.';
import { createContextStoreWithRuntimeModel } from '../../helpers/context-store.helpers';

const displayManagerDefaultState = (): DisplayManagerState =>
    persist(
        {
            showDealershipListDialog: false,
            showPaymentPlanSidebar: false,
            userActivityStatus: 'Checkout Not Started',
            userClosedMetaMenu: false,
        },
        {
            storage: 'sessionStorage',
            deny: ['showDealershipListDialog', 'showPaymentPlanSidebar', 'userActivityStatus'],
        }
    );

const displayActions = (): DisplayManagerActions => ({
    setShowDealershipListDialog: action((state, payload) => {
        state.showDealershipListDialog = payload;
    }),
    setShowPaymentPlanSidebar: action((state, payload) => {
        state.showPaymentPlanSidebar = payload.showSidebar;
        state.checkoutMode = payload.checkoutMode;
    }),
    setUserClosedMetaMenu: action((state, payload) => {
        state.userClosedMetaMenu = payload;
    }),
});

export const HireContentDisplayStore = createContextStoreWithRuntimeModel<
    HireDisplayManagerStore,
    Partial<DisplayManagerState>,
    DisplayManagerInjections
>(
    () => ({
        ...displayManagerDefaultState(),
        ...displayActions(),
    }),
    { name: 'hireContentDisplayStore' }
);
