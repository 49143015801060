import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const Header = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: 0.9rem;
`;

export const Description = styled.p`
    margin-bottom: 1.5rem;
`;

export const CtaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 30px;

    & button {
        align-self: center;
        width: 300px;
    }

    @media ${device.tablet} {
        flex-direction: revert;
        max-width: revert;

        & button {
            align-self: revert;
            width: revert;
        }
    }
`;

export const Disclaimer = styled.p`
    opacity: 0.5;
    color: #0b0b0b;
    font-size: 11px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 20px;
`;
