import { FC } from 'react';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { contentSpotTypes, umbraco } from '../../../lib/api';
import { MEDIA_URL } from '../../../utils/environment-constants';
import { CoverImage } from '../../shared/cover-image/cover-image.component';
import { CarFinderFilters } from '../car-finder-filters';

import { FindCarWrapper, FindCarHeader, Content, StyledSpotHeader } from './car-finder.styled';
import { isNullOrEmpty } from '../../../utils/helpers';

type Props = {
    hero: contentSpotTypes.HeroCarFinder;
    columnSpan: number;
    index: number;
    globalPlpSettings: umbraco.GlobalProductListSettings;
    isAboveFold: boolean;
};

export const CarFinder: FC<Props> = ({ hero, columnSpan, index, globalPlpSettings, isAboveFold }) => {
    const isMobile = useMediaQuery({ target: 'tablet' });

    if (hero.alias === 'heroCarFinder') {
        return (
            <FindCarWrapper columnSpan={columnSpan} backgroundColor={hero.backgroundColor} index={index}>
                {hero.backgroundImage || hero.backgroundImageMobile ? (
                    <CoverImage
                        src={
                            isMobile && !!hero.backgroundImageMobile
                                ? `${MEDIA_URL}/${hero.backgroundImageMobile?.src}`
                                : `${MEDIA_URL}/${hero.backgroundImage?.src}`
                        }
                        loading={isAboveFold ? 'eager' : 'lazy'}
                    />
                ) : null}
                <Content>
                    {isNullOrEmpty(hero.headerSize) ? (
                        <FindCarHeader as={hero.headerType}>{hero.headlineText}</FindCarHeader>
                    ) : (
                        <StyledSpotHeader headerType={hero.headerType} headerSize={hero.headerSize}>
                            {hero.headlineText}
                        </StyledSpotHeader>
                    )}

                    <CarFinderFilters hero={hero} columnSpan={columnSpan} globalPlpSettings={globalPlpSettings} />
                </Content>
            </FindCarWrapper>
        );
    }

    return null;
};
