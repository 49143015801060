import { FC } from 'react';
import { Slider } from '../../shared';
import { Campaign } from '../campaign';
import { HeroWrapper } from './split-hero-manager.styled';
import { contentSpotTypes, umbraco } from '../../../lib/api';
import { CarFinder } from '../car-finder';
import { getMediaKey } from '../helpers';
import { ColumnFormat } from '../../../lib/api/models/umbraco/content-spot';
import { CtaHandler } from '../../plus-sites-shared/content-handlers';
import { MEDIA_URL } from '../../../utils/environment-constants';

export type SplitHeroManagerProps = {
    splitHero: contentSpotTypes.SplitHero;
    globalPlpSettings: umbraco.GlobalProductListSettings;
};

const getColumnSpan = (format: ColumnFormat, elementIndex: number) => {
    switch (format) {
        case '1 by 2': {
            if (elementIndex === 0) {
                return 1;
            }
            return 2;
        }

        case '2 by 1': {
            if (elementIndex === 0) {
                return 2;
            }
            return 1;
        }

        default: {
            if (elementIndex === 0) {
                return 1;
            }
            return 2;
        }
    }
};

export const SplitHeroManager: FC<SplitHeroManagerProps> = ({ splitHero, globalPlpSettings }) => {
    if (!splitHero) {
        return null;
    }

    return splitHero.alias === 'splitHero' ? (
        <HeroWrapper columns={3} id={splitHero.scrollAnchorId}>
            {splitHero.splitHeroElements.map((setting, index) => {
                if (setting.alias === 'heroCarFinder') {
                    return (
                        <CarFinder
                            key={`find-car:${setting.alias}`}
                            hero={setting}
                            columnSpan={getColumnSpan(splitHero.columnFormat, index)}
                            index={index}
                            globalPlpSettings={globalPlpSettings}
                            isAboveFold={!!splitHero.isAboveFold}
                        />
                    );
                }

                return (
                    setting.alias === 'heroCampaign' && (
                        <Slider
                            key={`slider:${setting.alias}`}
                            columnSpan={getColumnSpan(splitHero.columnFormat, index)}
                            showPagination={setting.slideList?.length > 1}
                            slideDelayInSeconds={setting.slideDelayInSeconds}
                        >
                            {setting?.slideList &&
                                setting.slideList.map((slide, index) => {
                                    return (
                                        <Campaign
                                            key={getMediaKey(slide.image, slide.video)}
                                            imageUrl={slide.image ? `${MEDIA_URL}/${slide.image?.src}` : undefined}
                                            imageMobileUrl={slide.imageMobile ? `${MEDIA_URL}/${slide.imageMobile?.src}` : undefined}
                                            videoUrl={slide.video ? `${MEDIA_URL}/${slide.video?.src}` : undefined}
                                            videoMobileUrl={slide.videoMobile ? `${MEDIA_URL}/${slide.videoMobile?.src}` : undefined}
                                            headlineText={slide.headlineText}
                                            headerType={slide.headerType}
                                            headerSize={slide.headerSize}
                                            contentPosition={slide.contentPosition}
                                            verticalAlign={slide.verticalAlignment}
                                            columnSpan={getColumnSpan(splitHero.columnFormat, index)}
                                            textColor={slide.textColor}
                                            backgroundColor={slide.backgroundColor}
                                            slideCta={slide.ctaForEntireSlide}
                                            isAboveFold={!!splitHero.isAboveFold}
                                            carouselIndex={index}
                                            ctaBoxBackground={{
                                                color: slide.boxBackgroundColor,
                                                opacity: slide.boxBackgroundOpacity,
                                            }}
                                            overlay={slide.overlay && slide.overlay.length > 0 ? slide.overlay[0] : undefined}
                                        >
                                            {slide.ctas ? <CtaHandler ctas={slide.ctas} /> : undefined}
                                        </Campaign>
                                    );
                                })}
                        </Slider>
                    )
                );
            })}
        </HeroWrapper>
    ) : null;
};
