import { useMemo, VFC } from 'react';
import { PdpVanFinanceSetup } from '../../../../../lib/api/models/umbraco/product-details';
import { VanPurchaseOwnershipCashTab, VanPurchaseOwnershipFinanceTab } from '../../../../../lib/api/models/umbraco/product-types/vans.types';
import { hesselViewModels } from '../../../../../lib/view-models';
import { TabbedCard } from '../../../../shared/tabbed-card/tabbed-card.component';
import { OwnershipCard } from '../../../ownership-card/ownership-card.component';
import { OpenDialog, usePdpModals } from '../../../product-details-page/product-details-page.context';
import { IncludingDeliveryAndComplianceTextWrapper, PositionedComplianceText, StyledIncludingDeliveryText } from './van-purchase-tabs.styled';
import sanitizeHtml from 'sanitize-html';
import { ProductDetailsPage } from '../../../../../lib/api/models/umbraco';
import { useVanCtaConfig } from '../../use-van-cta-config';
import { PriceByOwnership } from '../../../hooks/price-by-ownership.types';
import { CarFinancing } from '../../../../../lib/api/models/hessel-api/hire';

export type VanPurchaseTabsProps = {
    page: ProductDetailsPage;
    product: hesselViewModels.ProductDetails;
    selectedOwnershipTab: hesselViewModels.OwnershipTab;
    setSelectedOwnershipTab: (tab: hesselViewModels.OwnershipTab) => void;
    vanFinancialSetup: Array<PdpVanFinanceSetup>;
    priceVisibilityChanged: (visible: boolean) => void;
    priceInformation: PriceByOwnership;
    santanderFinancing?: CarFinancing;
};

export const VanPurchaseTabs: VFC<VanPurchaseTabsProps> = ({
    page,
    product,
    selectedOwnershipTab,
    setSelectedOwnershipTab,
    vanFinancialSetup,
    priceVisibilityChanged,
    priceInformation,
    santanderFinancing,
}) => {
    const { openDialog } = usePdpModals();
    const CtaConfig = useVanCtaConfig(page);

    const cashTab = useMemo(() => {
        const cashTabCms = vanFinancialSetup.find((x) => x.alias === 'vanPurchaseOwnershipCashTab') as VanPurchaseOwnershipCashTab;
        return cashTabCms && product.purchaseTypes.cash.show
            ? {
                  id: 'Van Kontant',
                  label: cashTabCms?.tabName ?? '',
                  description: cashTabCms.subHeader ?? '',
                  onClick: () => setSelectedOwnershipTab('Van Kontant'),
                  content: (
                      <OwnershipCard
                          primaryCta={{
                              label: CtaConfig[product.availability]?.labels.primary,
                              action: CtaConfig[product.availability]?.actions.primary,
                          }}
                          secondaryCta={{
                              label: CtaConfig[product.availability]?.labels.secondary,
                              action: CtaConfig[product.availability]?.actions.secondary,
                          }}
                          footerCta={{
                              label: CtaConfig[product.availability]?.labels.footerLink,
                              action: CtaConfig[product.availability]?.actions.footerLink,
                          }}
                          usps={cashTabCms.usps}
                          price={priceInformation}
                          priceVisibilityChanged={priceVisibilityChanged}
                          cardFootNote={
                              product.availability === 'Used' || product.availability === 'Engros' ? `Ref. ${product.variantId}` : undefined
                          }
                          disclaimer={
                              <IncludingDeliveryAndComplianceTextWrapper>
                                  {product.availability !== 'Engros' && page.vanIncludingDeliveryText?.length > 0 ? (
                                      <StyledIncludingDeliveryText>{page.vanIncludingDeliveryText}</StyledIncludingDeliveryText>
                                  ) : null}

                                  {product.availability === 'Engros' && page.vanIncludingDeliveryTextEngros?.length > 0 ? (
                                      <StyledIncludingDeliveryText>{page.vanIncludingDeliveryTextEngros}</StyledIncludingDeliveryText>
                                  ) : null}
                              </IncludingDeliveryAndComplianceTextWrapper>
                          }
                      />
                  ),
              }
            : undefined;
    }, [
        vanFinancialSetup,
        product.purchaseTypes.cash.show,
        product.availability,
        product.variantId,
        CtaConfig,
        priceInformation,
        priceVisibilityChanged,
        page.vanIncludingDeliveryText,
        page.vanIncludingDeliveryTextEngros,
        setSelectedOwnershipTab,
    ]);

    const financeTab = useMemo(() => {
        const financeTabCms = vanFinancialSetup.find((x) => x.alias === 'vanPurchaseOwnershipFinanceTab') as VanPurchaseOwnershipFinanceTab;
        return financeTabCms && product.purchaseTypes.financing.show
            ? {
                  id: 'Van Finansiering',
                  label: financeTabCms?.tabName ?? '',
                  description: `${product.purchaseTypes.financing.durationMonths} mdr.`,
                  onClick: () => setSelectedOwnershipTab('Van Finansiering'),
                  content: (
                      <OwnershipCard
                          primaryCta={{
                              label: CtaConfig[product.availability]?.labels.primary,
                              action: CtaConfig[product.availability]?.actions.primary,
                          }}
                          secondaryCta={{
                              label: CtaConfig[product.availability]?.labels.secondary,
                              action: CtaConfig[product.availability]?.actions.secondary,
                          }}
                          footerCta={{
                              label: CtaConfig[product.availability]?.labels.footerLink,
                              action: CtaConfig[product.availability]?.actions.footerLink,
                          }}
                          usps={[
                              ...(financeTabCms?.usps ?? []),
                              {
                                  label: `${financeTabCms?.durationLabel}: ${product.purchaseTypes.financing.durationMonths} mdr.` ?? '',
                                  icon: financeTabCms?.durationIcon,
                                  isEmphasized: true,
                              },
                              {
                                  label: `${financeTabCms?.fixedDebtorLabel ?? ''} ${
                                      santanderFinancing?.interestRate ?? product.purchaseTypes.financing.nominalInterest
                                  } %`,
                                  icon: financeTabCms?.fixedDebtorIcon,
                              },
                              {
                                  label: `${financeTabCms?.aopLabel ?? ''} ${
                                      santanderFinancing?.aopBeforeTax ?? product.purchaseTypes.financing.aopBeforeTax
                                  } %`,
                                  icon: financeTabCms?.aopIcon,
                              },
                          ]}
                          linkCta={{
                              label: financeTabCms?.ctaText,
                              action: () => openDialog({ dialog: OpenDialog.FinancialCalculations }),
                          }}
                          price={priceInformation}
                          disclaimer={
                              <IncludingDeliveryAndComplianceTextWrapper>
                                  {product.availability !== 'Engros' && page.vanIncludingDeliveryText?.length > 0 ? (
                                      <StyledIncludingDeliveryText>{page.vanIncludingDeliveryText}</StyledIncludingDeliveryText>
                                  ) : null}

                                  {product.availability === 'Engros' && page.vanIncludingDeliveryTextEngros?.length > 0 ? (
                                      <StyledIncludingDeliveryText>{page.vanIncludingDeliveryTextEngros}</StyledIncludingDeliveryText>
                                  ) : null}

                                  <PositionedComplianceText
                                      text={`Forbrug ved blandet kørsel: ${product.specifications.consumption}, CO2 udledning: ${
                                          product.specifications.co2Emission
                                      }, energimærke: ${product.energyRating}. ${sanitizeHtml(
                                          `${santanderFinancing?.defaultDutyText ?? product.purchaseTypes.financing.santanderComplianceText}`,
                                          {
                                              allowedTags: [],
                                          }
                                      )}`}
                                  />
                              </IncludingDeliveryAndComplianceTextWrapper>
                          }
                          priceVisibilityChanged={priceVisibilityChanged}
                      />
                  ),
              }
            : null;
    }, [
        CtaConfig,
        openDialog,
        page.vanIncludingDeliveryText,
        page.vanIncludingDeliveryTextEngros,
        priceInformation,
        priceVisibilityChanged,
        product.availability,
        product.energyRating,
        product.purchaseTypes.financing.aopBeforeTax,
        product.purchaseTypes.financing.durationMonths,
        product.purchaseTypes.financing.nominalInterest,
        product.purchaseTypes.financing.santanderComplianceText,
        product.purchaseTypes.financing.show,
        product.specifications.co2Emission,
        product.specifications.consumption,
        santanderFinancing?.aopBeforeTax,
        santanderFinancing?.defaultDutyText,
        santanderFinancing?.interestRate,
        setSelectedOwnershipTab,
        vanFinancialSetup,
    ]);

    const purchaseOwnershipTabs = [];
    if (cashTab) {
        purchaseOwnershipTabs.push(cashTab);
    }
    const isOrderFord = product.brand === 'Ford' && product.availability === 'Order';
    if (financeTab && product.availability !== 'Used' && product.availability !== 'Engros' && !isOrderFord) {
        purchaseOwnershipTabs.push(financeTab);
    }

    const tabs = purchaseOwnershipTabs;

    if (product.vehicleType !== 'Van') {
        return null;
    }

    return (
        <TabbedCard
            controlled
            onAccesibilityTabSelected={(id) => setSelectedOwnershipTab(id as hesselViewModels.OwnershipTab)}
            activeTabId={selectedOwnershipTab}
            tabs={tabs}
        />
    );
};
