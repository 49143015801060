import styled, { css } from 'styled-components';

type StyledTabbedCardTabPanelProps = {
    isActive: boolean;
};

export const StyledTabbedCard = styled.section`
    background: ${(props) => props.theme.palette.common.white};
    box-shadow: ${(props) => props.theme.shadows.large};
`;

export const StyledTabbedCardTabList = styled.div`
    display: flex;
    font-size: 14px;
    min-height: 60px;
    &:has(> :nth-child(1):last-child) {
        padding: 0em 22px 0px;
        & button {
            border-bottom: 2px solid black;
        }
    }
`;

export const StyledTabbedCardTab = styled.button<StyledTabbedCardTabPanelProps>`
    appearance: none;
    background-color: ${(props) => (props.isActive ? props.theme.palette.common.white : 'rgba(217, 217, 217, 0.3)')};
    border: none;
    cursor: pointer;
    flex: 0 1 100%;
    overflow: hidden;
    padding: 14px 10px 0.25em;
    text-align: center;
    transition: transform 150ms;
    color: ${(props) => props.theme.palette.text.primary};

    &:only-child {
        background-color: white;
        text-align: left;
        padding: 14px 0 0.25em;
    }

    &:focus-visible {
        outline: 2px dotted ${(props) => props.theme.palette.primary.main};
    }

    &:active {
        color: ${(props) => props.theme.palette.primary.main};
        border-color: ${(props) => props.theme.palette.primary.main};
    }
`;

export const LabelAndBadgeWrapper = styled.div<{ onlyOption: boolean }>`
    display: flex;
    align-items: center;
    gap: 5px;
    ${(props) =>
        !props.onlyOption
            ? css`
                  justify-content: center;
              `
            : null}
`;

export const StyledTabbedCardLabel = styled.div<StyledTabbedCardTabPanelProps>`
    line-height: 1.71;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px; ;
`;

export const StyledTabbedCardDescription = styled.div`
    font-size: 0.78em;
    line-height: 2.18;
    margin: -0.55em 0 0;
    opacity: 0.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
`;

export const StyledTabbedCardTabPanel = styled.div.attrs<StyledTabbedCardTabPanelProps>((props) => ({
    style: {
        display: props.isActive ? undefined : 'none',
    },
}))<StyledTabbedCardTabPanelProps>`
    &:focus-visible {
        outline: 2px dotted ${(props) => props.theme.palette.primary.main};
    }
`;
