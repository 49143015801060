import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const FullscreenMediaWrapper = styled.section`
    min-height: 400px;
    height: 400px;

    @media ${device.tablet} {
        height: 55vh;
        min-height: 580px;
    }
`;
