import React, { FC, useEffect, useState } from 'react';
import { useSpring, config } from 'react-spring';
import { useMeasure } from 'react-use';
import { StyledReadMoreContentWrapper, StyledReadMoreContent, StyledReadMoreFlexContainer } from './read-more-wrapper.styled';

type IProps = {
    labelContent: React.ReactNode;
    echoNewState?: (newState: boolean) => void;
    contentBelowToggle?: boolean;
    hideReadMoreAfterOpen?: boolean;
};

export const ReadMoreWrapper: FC<IProps> = ({ children, labelContent, echoNewState, contentBelowToggle = false, hideReadMoreAfterOpen = false }) => {
    const [isOpen, setIsOpen] = useState(false);
    const defaultHeight: string | undefined = '0px';

    // Gets the height of the element (ref)
    const [heightRef, { height }] = useMeasure();

    const [contentHeight, setContentHeight] = useState(defaultHeight || height);

    useEffect(() => {
        //Sets initial height
        setContentHeight(height);
    }, [height]);

    // Animations
    const expand = useSpring({
        config: { ...config.slow, friction: 50 },
        height: isOpen ? `${contentHeight}px` : defaultHeight,
        overflow: !isOpen ? 'hidden' : '',
    });

    const fadeContent = useSpring({
        config: { ...config.slow, friction: 50 },
        opacity: isOpen ? 1 : 0,
        delay: 300,
    });

    const onClickEvent = () => {
        setIsOpen(!isOpen);
        echoNewState?.(!isOpen);
    };

    return (
        <StyledReadMoreFlexContainer reversed={contentBelowToggle}>
            <StyledReadMoreContentWrapper style={expand}>
                <StyledReadMoreContent ref={heightRef as any} style={fadeContent}>
                    {children}
                </StyledReadMoreContent>
            </StyledReadMoreContentWrapper>
            {!isOpen || (isOpen && !hideReadMoreAfterOpen) ? <div onClick={() => onClickEvent()}>{labelContent}</div> : null}
        </StyledReadMoreFlexContainer>
    );
};
