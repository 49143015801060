import { useKeenSlider } from 'keen-slider/react';
import { FC, useEffect, useState } from 'react';
import { StyledControl, StyledControlsContainer, StyledHorizontalSliderWrapper, StyledIconText } from './horizontal-drag-slider.styled';
import { SvgIcon } from '../svg-icon';
import { SliderPosition } from '../../vehicle/vehicle-product-card-carousel/vehicle-product-card-carousel.component';
import React from 'react';

type IProps = {
    initialIndex?: number;
    spacing?: number;
    onLoad?: () => void;
    perView?: number | 'auto';
    controls?: boolean;
};

export const HorizontalDragSlider: FC<IProps> = ({ initialIndex, spacing = 20, children, onLoad, perView = 'auto', controls = false }) => {
    const [sliderPosition, setSliderPosition] = useState<SliderPosition>();
    const [sliderRef, instance] = useKeenSlider({
        initial: initialIndex ?? 0,
        loop: false,
        mode: 'free',
        slides: {
            perView,
            spacing,
        },
        created: onLoad,
        detailsChanged(instance) {
            if (!instance.track || !instance.track.details) {
                return;
            }
            if (isNaN(instance.track.details.progress)) {
                setSliderPosition('No Slides');
            } else if (instance.track.details.position === instance.track.details.max) {
                setSliderPosition('end');
            } else if (instance.track.details.position === instance.track.details.min) {
                setSliderPosition('beginning');
            } else {
                setSliderPosition('In Between');
            }
        },
    });

    useEffect(() => {
        instance.current?.update();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children]);

    const previousButtonIsDisabled = sliderPosition === 'beginning' || sliderPosition === 'No Slides';
    const nextButtonIsDisabled = sliderPosition === 'end' || sliderPosition === 'No Slides';

    return (
        <>
            {controls && (
                <StyledControlsContainer>
                    <StyledControl
                        onClick={(e) => {
                            e.stopPropagation();
                            instance.current?.prev();
                        }}
                        disabled={previousButtonIsDisabled}
                    >
                        <SvgIcon iconName="chevron/left" />
                        <StyledIconText>Forrige</StyledIconText>
                    </StyledControl>
                    <StyledControl
                        onClick={(e) => {
                            e.stopPropagation();
                            instance.current?.next();
                        }}
                        disabled={nextButtonIsDisabled}
                    >
                        <StyledIconText>Næste</StyledIconText>
                        <SvgIcon iconName="chevron/right" />
                    </StyledControl>
                </StyledControlsContainer>
            )}
            <StyledHorizontalSliderWrapper ref={sliderRef} className="keen-slider">
                {children}
            </StyledHorizontalSliderWrapper>
        </>
    );
};
