import styled from 'styled-components';
import { device } from '../../../lib/media-query';
import { hesselViewModels } from '../../../lib/view-models';
import { MagnifyingGlass } from '../../icons';
import { ShortInfoCard } from '../../shared';
import { IconButton } from '../../shared/icon-button/icon-button.component';
import { Spinner } from '../../shared/spinner';
import { ToggleUi } from '../../shared/toggle-ui';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';
import { PdpUspTemplateBar } from '../pdp-usp-template-bar/pdp-usp-template-bar.component';
import { ProductDetailsHeader } from '../product-details-header/product-details-header.component';

export const StyledProductDetailsPageMain = styled.main`
    --pdp-margin: 12px;
    --spot-bg: #f7f7f8;
    background-color: #f7f7f8;

    @media ${device.laptopS} {
        padding: 20px 10px;
        padding-top: 0;
        background-color: white;
    }
`;

export const StyledProductDetailsPage = styled.div`
    @media ${device.laptop} {
        display: grid;
        grid-template-columns: 3fr 2fr;
        grid-template-areas: 'header header' 'leftPanel rightPanel';
        gap: 0 90px;
    }
`;

export const StyledProductDetailsHeader = styled(ProductDetailsHeader)`
    margin: 0 0 10px;
`;

export const StyledCarouselContainer = styled.div`
    padding: 12px 14px;
`;

export const StyledFlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;

    align-self: flex-start;
    grid-area: 1 / 2 / -1 / 3;
    margin: 6em 0 0;
`;

export const StyledZoomButton = styled(IconButton).attrs({
    children: MagnifyingGlass({}),
})``;

export const StyledSidePanel = styled.div<{ availability: hesselViewModels.VehicleAvailability }>`
    align-self: flex-start;
    grid-area: rightPanel;
    margin-top: 20px;

    @media ${device.tablet} {
        position: sticky;
        top: 20px;
    }

    @media ${device.laptop} {
        margin-top: 0;
    }
`;

export const StyledShortInfoCard = styled(ShortInfoCard)``;

export const StyledSpinner = styled(Spinner)`
    position: relative;
    top: 80px;
    left: 38%;
    transform: translate(50%, 50%);
    margin-bottom: 200px;

    @media ${device.tablet} {
        top: 50px;
        left: 50%;
    }
`;

export const StyledBundleWrapper = styled.div`
    margin: var(--pdp-margin, 12px) 0;
    padding: 20px 0;
    background-color: var(--spot-bg, #f7f7f8);
`;

export const StyledPdpUspTemplateBar = styled(PdpUspTemplateBar)`
    margin-top: var(--pdp-margin, 12px);
`;

export const OwnershipWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`;

export const StyledOwnershipToggle = styled(ToggleUi)`
    width: 50%;

    @media ${device.tablet} {
        width: 60%;
    }
`;

export const FullEquipmentListLinkAndIcon = styled.div`
    display: flex;
    gap: 5px;
    margin-top: 13px;
`;

export const FullEquipmentLink = styled.a`
    color: #0b0b0b;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0;
`;

export const FullEquipmentListIcon = styled.img`
    height: 25px;
    object-fit: contain;
    width: 25px;
`;

export const StyledCenteredBlock = styled(CenteredBlock)`
    background-color: var(--spot-bg, #f7f7f8);
`;

export const StyledPaddingWrapper = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
`;

export const StyledPromotionsWrapper = styled.div`
    margin: var(--pdp-margin, 12px) 0;
`;

export const StyledLeftSidePanel = styled.div`
    display: grid;
    gap: 20px 0px;
    grid-area: leftPanel;
`;

export const StyledColorDisclaimer = styled.p`
    text-align: center;
    background-color: #ebebeb;
    padding: 8px 12px;
    margin: 20px 18px -20px;
    font-size: 10px;
    line-height: 14px;
    @media ${device.mobile_tablet} {
        font-size: 14px;
        line-height: 24px;
    }
`;

export const StyledConfigurationWrapper = styled.div`
    padding: 35px 32px 30px;

    @media ${device.mobile_tablet} {
        padding-top: 30px;
    }
`;
