import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const StyledStep = styled.section`
    display: flex;
    flex-direction: column;
`;

export const StepHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 0.5rem;
`;

export const WorkshopListWrapper = styled.div`
    display: flex;
    gap: 1rem;
    overflow-x: auto;
    scroll-behavior: smooth;
    scrollbar-width: thin;
    padding: 1rem 0;
    isolation: isolate;
    @media ${device.tablet} {
        overflow-x: visible;
    }
`;

export const CommentAccordionWrapper = styled.div<{ isLoading: boolean }>`
    margin-top: 20px;

    pointer-events: ${(props) => (props.isLoading ? 'none' : 'revert')};
    cursor: ${(props) => (props.isLoading ? 'not-allowed' : 'revert')};
    filter: ${(props) => (props.isLoading ? 'grayscale(0.5) blur(2px)' : 'revert')};
`;

export const CommentWrapper = styled.div`
    margin-top: 20px;
`;

export const ModalContent = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;

    padding: 30px 35px;

    @media ${device.tablet} {
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
    }
`;

export const AccordionHeader = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    font-size: 0.74rem;
    font-weight: 500;
    line-height: 1.5rem;
    width: max-content;

    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;

    @media ${device.mobileM} {
        font-size: 0.78rem;
    }

    @media ${device.mobileL} {
        font-size: 0.9rem;
        padding-right: 10px;
    }
`;
