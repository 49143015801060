import styled from 'styled-components';
import { device } from '../../../../../lib/media-query';
import { mixins } from '../../../../../themes';
import { ToggleUi } from '../../../../shared/toggle-ui';

export const BorderBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    ${mixins.gap(1.5)}

    @media ${device.tablet} {
        padding: 35px 0px;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media ${device.tablet} {
        display: grid;
        grid-template-columns: 0.5fr;
        grid-row-gap: 1rem;
    }
`;

export const StyledToggleUi = styled(ToggleUi)`
    width: 60%;
`;
