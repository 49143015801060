import { FC, useMemo } from 'react';
import { FormInput } from '../../../../../lib/state/booking/form';
import { formInputLabel, formInputValue } from '../../../../../utils/helpers';
import { SubmittedInfo } from '../../../../shared/submitted-info';
import { InfoWrapper } from './contact-info-summary.styled';

type Props = {
    inputs: FormInput<string>[];
};

const ContactInfoSummary: FC<Props> = ({ inputs }) => {
    const submittedInfo = useMemo(() => {
        const addressInputs = inputs.find((x) => x.type === 'address');
        let list: FormInput<string>[] = [];

        if (addressInputs?.type === 'address') {
            const address = addressInputs.inputs.find(({ multiformInputName }) => multiformInputName === 'address');
            const zipcode = addressInputs.inputs.find(({ multiformInputName }) => multiformInputName === 'zipcode');
            const city = addressInputs.inputs.find(({ multiformInputName }) => multiformInputName === 'city');

            if (address && zipcode && city) {
                const zipcodeAndCity = { ...city, label: 'Post nr. og by', value: `${zipcode.value} ${city.value}` };

                list = [address, zipcodeAndCity];
            }
        }
        return [...inputs.filter((x) => x.type !== 'address'), ...list].map((input) => ({
            id: input.id,
            value: formInputValue(input),
            label: formInputLabel(input),
        }));
    }, [inputs]);

    return (
        <InfoWrapper numberOfColumns={submittedInfo.length}>
            {submittedInfo.map((val, idx) => (
                <SubmittedInfo key={`${idx}:${val.id}`} label={val.label} text={val.value} />
            ))}
        </InfoWrapper>
    );
};

export default ContactInfoSummary;
