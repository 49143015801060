import styled from 'styled-components';
import { device } from '../../../lib/media-query';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';

export const StyledCenteredBlock = styled(CenteredBlock)`
    background-color: #fff;
`;

export const StyledSimpleTextAndImage = styled.section`
    display: flex;
    flex-direction: column;
    gap: 45px;

    padding: 40px 0;
    @media ${device.tablet} {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: space-between;
        padding: 85px 0;
    }
`;

export const ContentColumn = styled.div`
    max-width: 420px;
    order: 2;

    @media ${device.tablet} {
        padding-top: 39px;
        order: revert;
    }
`;

export const ImageColumn = styled.div``;
export const StyledImage = styled.img`
    display: block;
    width: 100%;
    height: 326px;
    object-fit: cover;
    object-position: center;
`;

export const Header = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: 15px;
`;

export const Description = styled.div`
    & > p {
        color: #0b0b0b;
        font-size: 14px;
        letter-spacing: 0;
        font-weight: normal;
    }
    margin-bottom: 15px;
`;
