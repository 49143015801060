import { FC, useEffect, useState } from 'react';
import { SidePanel } from '../../../shared/modals/side-panel';
import { Button, CheckBox } from '../../../shared';
import { SvgIcon } from '../../../shared/svg-icon';
import { FilterUpdateNotification } from '../../../../lib/api/models/hessel-api/hire';
import { SortingLabel } from '../../../../lib/api/models/umbraco';
import { Accordion } from '../../../shared/accordion';
import { ShopFacetGroup } from '../../../../lib/api/models/shop/product-list';
import { getFilterRenderType } from '../../../vehicle/vehicle-product-list/vehicle-product-list-filter/filter.helpers';
import { MobileFiltersFooter } from './shop-product-list-mobile-filter.styled';
import { FilterGroup } from '../../../vehicle/vehicle-product-list/vehicle-product-list-filter/filter-group';
import { OptionText } from '../../../vehicle/vehicle-product-list/vehicle-product-list-filter/checkbox-filter/checkox-filter.styled';
import {
    MobileFilterWrapper,
    MobileFiltersHeader,
} from '../../../vehicle/vehicle-product-list/vehicle-product-list-filter/vehicle-product-list-mobile-filter.styled';
import {
    FilterHeader,
    FilterWrapper,
    InputWrapper,
} from '../../../vehicle/vehicle-product-list//vehicle-product-list-filter/filter-group/filter-group.styled';
type IProps = {
    openState: boolean;
    setOpenState: (state: boolean) => void;
    applyMobileFiltersText: string;
    closeMobileFilterOverlay: string;
    clearFiltersText: string;
    facets: ShopFacetGroup[];
    resetFilters: () => void;
    updateFilters: (updates: FilterUpdateNotification[]) => void;
    updateSorting: (sorting: string) => void;
    sortingOptions: SortingLabel[];
    selectedSortOption: string;
    sortingOpenExternal: boolean;
};

export const ShopProductListMobileFilters: FC<IProps> = ({
    openState,
    setOpenState,
    applyMobileFiltersText,
    closeMobileFilterOverlay,
    clearFiltersText,
    facets,
    resetFilters,
    updateFilters,
    sortingOpenExternal,
    sortingOptions,
    selectedSortOption,
    updateSorting,
}) => {
    const [sortingOpen, setSortingOpen] = useState(sortingOpenExternal);
    const [selectedSorting, setSelectedSorting] = useState([selectedSortOption, '']);
    const resetFiltersAndClose = () => {
        setOpenState(false);
        resetFilters();
    };

    useEffect(() => {
        if (sortingOpenExternal !== sortingOpen) setSortingOpen(sortingOpenExternal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortingOpenExternal]);

    useEffect(() => {
        if (selectedSortOption !== selectedSorting[0]) setSelectedSorting([selectedSortOption, '']);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSortOption]);

    return (
        <SidePanel
            isVisible={openState}
            cancelAction={() => {
                setOpenState(false);
            }}
            variant="md"
        >
            <MobileFilterWrapper>
                <MobileFiltersHeader onClick={() => setOpenState(false)}>
                    {closeMobileFilterOverlay} <SvgIcon iconName="close" />
                </MobileFiltersHeader>
                {sortingOptions.length > 0 ? (
                    <FilterWrapper>
                        <Accordion
                            title=""
                            variant="primary"
                            border="none"
                            headerSize="sm"
                            isOpen={sortingOpen}
                            index={0}
                            onClick={() => setSortingOpen(!sortingOpen)}
                            backgroundColor="fff"
                            customTitle={<FilterHeader>Sortering</FilterHeader>}
                            toggleIndicator="plus"
                        >
                            {sortingOptions.map((x) => {
                                return (
                                    <InputWrapper key={x.sortingKey}>
                                        <CheckBox
                                            checked={x.labelText === selectedSorting[0]}
                                            id={x.sortingKey}
                                            value={x.sortingKey}
                                            onChange={() => updateSorting(x.sortingKey)}
                                        >
                                            <OptionText>{x.labelText}</OptionText>
                                        </CheckBox>
                                    </InputWrapper>
                                );
                            })}
                        </Accordion>
                    </FilterWrapper>
                ) : null}
                {facets.map((x, idx) => {
                    return (
                        <FilterGroup
                            key={`${x.key}-${idx}`}
                            header={x.label}
                            filterRenderType={getFilterRenderType(x)}
                            facet={x.facetOptions.map((f) => {
                                return {
                                    ...f,
                                    subFacets: undefined,
                                };
                            })}
                            onChange={(filterUpdate) => updateFilters(filterUpdate)}
                            group={x}
                            shopContext={true}
                        />
                    );
                })}
                <MobileFiltersFooter>
                    <Button onClick={() => resetFiltersAndClose()}>{clearFiltersText}</Button>
                    <Button variant="primary" onClick={() => setOpenState(false)}>
                        {applyMobileFiltersText}
                    </Button>
                </MobileFiltersFooter>
            </MobileFilterWrapper>
        </SidePanel>
    );
};
