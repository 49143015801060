import styled from 'styled-components';
import { Button, CheckBox } from '../../shared';

export const HeaderAndPrice = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
`;

export const StyledHeader = styled.p<{ spacing?: string }>`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    margin: ${(props) => props.spacing};
`;

export const StyledPrice = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: normal;
    letter-spacing: 0;
`;

export const SectionHeader = styled.p<{ spacing?: string }>`
    color: #0b0b0b;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    margin-bottom: 10px;
    margin: ${(props) => props.spacing};
    line-height: 26px;
`;

export const StyledText = styled.p<{ spacing?: string }>`
    color: #0b0b0b;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0;
    margin: ${(props) => props.spacing};
`;

export const TermsAndConditions = styled.div``;

export const InputsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;
`;

export const StyledSubmitButton = styled(Button)`
    margin-top: 20px;
`;

export const SelectionSummary = styled.div``;

export const TitleAndValue = styled.div`
    display: flex;
    gap: 5px;
`;

export const Title = styled.p`
    font-weight: bold;
    font-size: 14px;
`;

export const Value = styled.p<{ spacing?: string }>`
    font-weight: normal;
    font-size: 14px;
    margin: ${(props) => props.spacing};
`;

export const Disclaimer = styled.p`
    color: #807f80;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
    font-weight: normal;
    margin-top: 30px;
`;

export const TradeOfferCheckbox = styled(CheckBox)<{ addSpacing: boolean }>`
    margin-bottom: ${(props) => (props.addSpacing ? '11px' : 'unset')};
`;

export const CheckboxLabel = styled.div`
    & p {
        font-weight: normal;
    }
`;
