import { FC } from 'react';
import { StyledDescription, StyledEngrosExplanationCard, StyledHeader, StyledSvgIcon } from './engros-explanation.styled';
import { Button } from '../../../shared';

import { EngrosExplanationSpot } from '../../../../lib/api/models/umbraco';
import { OpenDialog, usePdpModals } from '../../product-details-page/product-details-page.context';

type IProps = {
    content: EngrosExplanationSpot;
};

export const EngrosExplanation: FC<IProps> = ({ content }) => {
    const { openDialog } = usePdpModals();
    return (
        <StyledEngrosExplanationCard>
            <StyledHeader>
                <p>{content.header}</p>
                <StyledSvgIcon
                    onClick={() =>
                        openDialog({
                            dialog: OpenDialog.EngrosExplainer,
                        })
                    }
                    iconName="info"
                />
            </StyledHeader>
            <StyledDescription dangerouslySetInnerHTML={{ __html: content.bodyText }}></StyledDescription>
            <Button
                variant="link"
                onClick={() =>
                    openDialog({
                        dialog: OpenDialog.EngrosExplainer,
                    })
                }
            >
                {content.openModalLinkText}
            </Button>
        </StyledEngrosExplanationCard>
    );
};
