import { useMemo, VFC } from 'react';
import { HireContentDisplayStore } from '../../../../lib/state/hessel-site/display-manager';
import { ProductDetailsStore } from '../../../../lib/state/hessel-site/product-details';
import { getFormattedValue } from '../../../../utils/helpers';
import { generateRange } from '../../../../utils/helpers/array.helpers';
import { DropdownInput } from '../../../forms/inputs';
import { DropdownOption } from '../../../shared/date-picker-dropdown/date-picker-dropdown.component';
import { SidePanel } from '../../../shared/modals/side-panel';
import { SidePanelLayout } from '../../../shared/modals/side-panel-layout/side-panel-layout.component';
import { useUI } from '../../../ui';
import { PaymentPlanDetails } from '../payment-plan-details';
import { HeaderSection, Header, Price, Description } from './payment-plan-sidebar.styled';
import { Abbreviations } from '../../../../constants/units-and-abbreviations';

export type PaymentPlanSidebarProps = {
    numberOfNoCostMonths: number;
};

export const PaymentPlanSidebar: VFC<PaymentPlanSidebarProps> = ({ numberOfNoCostMonths }) => {
    const { showPaymentPlanSidebar, checkoutMode } = HireContentDisplayStore.useStoreState((state) => state);
    const { setShowPaymentPlanSidebar } = HireContentDisplayStore.useStoreActions((actions) => actions);

    // Product details page
    const {
        selectedProduct,
        selectedLeasingPeriod,
        selectedColor,
        extraEquipmentPriceSum,
        optionalEquipmentPriceSum,
        extraEquipmentPackagePriceSum,
    } = ProductDetailsStore.useStoreState((state) => state);
    const { setSelectedLeasingPeriod } = ProductDetailsStore.useStoreActions((actions) => actions);

    const hireBasePrice = useMemo(() => {
        return (
            (selectedProduct?.purchaseTypes.hire.baseMonthlyPrice ?? 0) +
            (selectedColor?.monthlyHirePrice ?? 0) +
            extraEquipmentPriceSum +
            optionalEquipmentPriceSum +
            extraEquipmentPackagePriceSum
        );
    }, [
        extraEquipmentPackagePriceSum,
        extraEquipmentPriceSum,
        optionalEquipmentPriceSum,
        selectedColor?.monthlyHirePrice,
        selectedProduct?.purchaseTypes.hire.baseMonthlyPrice,
    ]);

    const leasingMonths = useMemo(() => {
        return [selectedProduct?.purchaseTypes.hire?.durationFrom ?? 0, selectedProduct?.purchaseTypes.hire?.durationTo ?? 0];
    }, [selectedProduct]);

    const leasingPeriodOptions = useMemo(() => {
        const [start, stop] = leasingMonths;

        const fullList = generateRange(start, stop, 1);

        return fullList
            .sort((a, b) => a - b)
            .map<DropdownOption<string>>((p) => ({
                displayValue: `${p} mdr.`,
                value: `${p}`,
            }));
    }, [leasingMonths]);

    const uiHandler = useUI();

    return (
        <SidePanel
            isVisible={showPaymentPlanSidebar}
            cancelAction={() => {
                setShowPaymentPlanSidebar({ showSidebar: false });
                uiHandler.applyScroll();
            }}
        >
            <SidePanelLayout
                closeSidePanel={() => {
                    setShowPaymentPlanSidebar({ showSidebar: false });
                    uiHandler.applyScroll();
                }}
            >
                <HeaderSection>
                    <Header>Betalingsplan</Header>
                    <Price>
                        {getFormattedValue(hireBasePrice)} {Abbreviations.KR_SLASH_MD}
                    </Price>
                </HeaderSection>

                <Description>Herunder finder du betalingsplanen for bilen</Description>

                <DropdownInput
                    id="payment-plan-dropdown"
                    options={leasingPeriodOptions}
                    value={selectedLeasingPeriod ?? { value: '', displayValue: '' }}
                    label={'Periode'}
                    placeholder={'Vælg en periode'}
                    disabled={leasingPeriodOptions.length === 0}
                    onChange={(option) => {
                        if (option) {
                            setSelectedLeasingPeriod(option);
                        }
                    }}
                    isValid={false}
                    canValidateInputField={false}
                    validationMessage={'Vælg venligst en betalingsperiode'}
                    onInputBlur={() => null}
                />

                <PaymentPlanDetails
                    numberOfMonths={selectedLeasingPeriod?.value ? +selectedLeasingPeriod?.value : 0}
                    numberOfNoCostMonths={numberOfNoCostMonths}
                    oneMonthLeasingCost={hireBasePrice}
                    handlingFee={selectedProduct?.purchaseTypes.hire.establishmentFee ?? 0}
                    checkoutMode={checkoutMode}
                />
            </SidePanelLayout>
        </SidePanel>
    );
};
