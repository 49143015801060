import { action, computed, thunk } from 'easy-peasy';
import { getServiceProducts } from '../../../api';
import { createContextStoreWithRuntimeModel } from '../../helpers/context-store.helpers';
import {
    ServiceProductsActions,
    ServiceProductsInjections,
    ServiceProductsState,
    ServiceProductsStore,
    ServiceProductsThunks,
} from './booking-service-products.types';

const bookingServiceProductsDefaultState = (): ServiceProductsState => ({
    availableServiceProducts: [],
    loading: false,
    selectedServiceProductsIds: [],
    mutuallyExclusiveProducts: [],
    flattenedProducts: computed((state) => {
        return state.flattenedProductsAndSubProducts.concat(state.flattenedProductsOptions);
    }),
    flattenedProductsAndSubProducts: computed((state) => {
        return state.availableServiceProducts.concat(state.flattenedSubProducts);
    }),
    flattenedSubProducts: computed((state) => {
        const allSubProducts = state.availableServiceProducts.map(({ subProducts }) => subProducts ?? []);

        if (allSubProducts.length === 0) return [];

        return allSubProducts.reduce((a, b) => a.concat(b));
    }),
    flattenedProductsOptions: computed((state) => {
        const allOptions = state.availableServiceProducts.map(({ productOptions, defaultOption }) =>
            (productOptions ?? []).concat(defaultOption ?? [])
        );

        if (allOptions.length === 0) return [];

        return allOptions.reduce((a, b) => a.concat(b));
    }),
    totalServiceTime: computed((state) => {
        const selectedProducts = state.selectedServiceProductsIds.map((id) => state.flattenedProducts.find((product) => product.id === id));
        if (selectedProducts.length === 0) return 0;

        const totalTime = selectedProducts.map((product) => product?.requiredTimeInMinutes ?? 0).reduce((a, b) => a + b);
        return totalTime;
    }),
    serviceTooLong: computed((state) => {
        return state.totalServiceTime >= 60 * 3;
    }),
});

const bookingServiceProductsActions = (): ServiceProductsActions => ({
    //NH_TODO: Move booking stores into shared booking folder instead of multiple booking folders
    setAvailableServiceProducts: action((state, payload) => {
        state.availableServiceProducts = payload;
    }),
    setLoading: action((state, payload) => {
        state.loading = payload;
    }),
    setServiceProducts: action((state, payload) => {
        const mutuallyExclusiveProductPair = state.mutuallyExclusiveProducts.find((keyValue) => keyValue.includes(payload));
        if (mutuallyExclusiveProductPair !== undefined) {
            const excludingProduct = mutuallyExclusiveProductPair.find((id) => id !== payload);
            if (excludingProduct === undefined) return;

            const excludingProductIsSelected = state.selectedServiceProductsIds.includes(excludingProduct);
            if (excludingProductIsSelected) {
                return;
            }
        }

        state.selectedServiceProductsIds = state.selectedServiceProductsIds.some((id) => id === payload)
            ? state.selectedServiceProductsIds.filter((id) => id !== payload)
            : [...state.selectedServiceProductsIds, payload];
    }),
    setProductOption: action((state, payload) => {
        if (state.selectedServiceProductsIds.includes(payload.optionId)) return;

        // unselect other product options for parent
        const productOptionParent = state.availableServiceProducts.find(({ id }) => id === payload.parentId);
        if (!productOptionParent?.productOptions || !productOptionParent.defaultOption) return;

        const productOptionIds = [...productOptionParent.productOptions, productOptionParent.defaultOption].map((option) => option.id);
        state.selectedServiceProductsIds = state.selectedServiceProductsIds.filter((id) => !productOptionIds.includes(id));

        // Add new option id
        state.selectedServiceProductsIds = [payload.optionId, ...state.selectedServiceProductsIds];
    }),
    clearProductOptions: action((state, payload) => {
        const product = state.availableServiceProducts.find((product) => product.id === payload);
        if (!product?.productOptions || !product?.defaultOption) return;

        const allOptions = [...(product.productOptions ?? []), product.defaultOption];
        state.selectedServiceProductsIds = state.selectedServiceProductsIds.filter((id) => !allOptions.some((option) => option.id === id));
    }),
    setComment: action((state, payload) => {
        state.comment = payload;
    }),
    setServiceProductSelected: action((state, payload) => {
        const productAlreadySelected = state.selectedServiceProductsIds.some((productId) => payload.productId === productId);
        if (payload.newState === productAlreadySelected) return;

        state.selectedServiceProductsIds = payload.newState
            ? [...state.selectedServiceProductsIds, payload.productId]
            : state.selectedServiceProductsIds.filter((id) => id !== payload.productId);
    }),
    setMutuallyExclusiveProducts: action((state, payload) => {
        state.mutuallyExclusiveProducts = payload;
    }),
});

const bookingServiceProductsThunks = (): ServiceProductsThunks => ({
    getAvailableServiceProducts: thunk(async (actions, payload, { injections: { pushError } }) => {
        actions.setLoading(true);
        const [result, error] = await getServiceProducts(payload);
        if (result && !error) {
            actions.setAvailableServiceProducts(result.products);
            actions.setMutuallyExclusiveProducts(result.mutuallyExclusiveProducts); //NH_TODO: Start using when no more build errors
        } else if (error) {
            pushError(error.errorType);
        }
        actions.setLoading(false);
    }),
});

export const BookingServiceProductsStore = createContextStoreWithRuntimeModel<ServiceProductsStore, ServiceProductsState, ServiceProductsInjections>(
    () => ({
        ...bookingServiceProductsDefaultState(),
        ...bookingServiceProductsActions(),
        ...bookingServiceProductsThunks(),
    }),
    { name: 'ServiceProducts' }
);
