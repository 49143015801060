import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const PreviewLinkWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    cursor: pointer;
`;

export const StyledText = styled.p`
    text-decoration: underline;
    color: #0b0b0b;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;

    @media ${device.tablet} {
        font-size: 14px;
    }
`;
