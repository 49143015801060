import { RefObject, useEffect } from 'react';

export const useClickOutsideObserver = (ref: RefObject<HTMLDivElement> | undefined, toggle: () => void, openState: boolean): void => {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref && ref.current && openState && event.target && !ref.current.contains(event.target as Node)) {
                toggle();
            }
        };

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [openState, ref, toggle]);
};
