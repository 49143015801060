import { animated } from 'react-spring';
import styled from 'styled-components';
import { device } from '../../../../lib/media-query';
import { mixins } from '../../../../themes';
import { isNullOrEmpty } from '../../../../utils/helpers';

export const DisabledContent = styled.div<{ disabled?: boolean }>`
    opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;

export const BorderBox = styled.div<{
    multiline?: boolean;
    isOpen?: boolean;
    isValid: boolean;
    canValidate: boolean;
    textAreaBorder?: boolean;
}>`
    min-height: 50px;
    border-width: 1px;
    border-style: solid;
    border-radius: ${({ theme: { shape } }) => `${shape.rounded.borderRadius}px`};
    ${(props) => (props.textAreaBorder === true ? 'border-radius: 14px;' : '')};
    border-color: ${(props) => (props.canValidate ? (props.isValid ? props.theme.palette.grey.main : '#D64469') : props.theme.palette.grey.medium)};
    align-items: center;
    background-color: ${({ theme: { palette } }) => palette.common.white};

    & > * {
        padding: 0px 2.5px;
    }
    & > :first-child {
        padding-left: 0px;
    }
    & > :last-child {
        padding-right: 0px;
    }

    ${({ multiline }) => (multiline ? { display: 'grid', gridTemplateColumns: 'auto', padding: '20px 30px' } : { display: 'flex' })};

    ${({ isOpen }) => (isOpen ? { borderBottomWidth: '0px', borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' } : {})}
`;

export const Label = styled(animated.label)<{ multiline?: string }>`
    ${mixins.typography('inputLabel')}
    color: ${({ theme: { palette } }) => palette.text.primary};
    margin-left: ${({ multiline }) => (!isNullOrEmpty(multiline) && multiline === 'true' ? '' : '30px')};
    flex-shrink: 0;

    @media ${device.tablet} {
        min-width: max-content;
    }
`;

export const InputBox = styled(animated.div)`
    flex-grow: 1;
    display: flex;

    // To remove outline on mobile
    :focus-visible {
        outline: none;
    }
    // To remove outline on mobile
    :focus {
        outline: none;
    }
`;

export const Adornment = styled.div`
    margin: 0px 20px 0px 2.5px;
    display: flex;
    & > * {
        margin: 0px 2.5px;
    }
    & > :first-child {
        margin-left: 0px;
    }
    & > :last-child {
        margin-right: 0px;
    }
`;

export const ValidationMessage = styled.p`
    color: #d64469;
    font-size: ${({ theme: { typography } }) => typography.small.fontSize}px;
    margin-left: 10px;
`;

export const InfoMessage = styled.p`
    font-size: ${({ theme: { typography } }) => typography.small.fontSize}px;
    margin-left: 10px;
`;

export const StyledRedLabel = styled.span`
    color: ${({ theme: { palette } }) => palette.error.main};
    font-weight: normal;
    margin-left: 2px;
`;
