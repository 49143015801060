import { FC } from 'react';
import { SizeGuideReferenceSpot } from '../../../lib/api/models/umbraco/content-spot';
import { SizeGuide } from './size-guide.component';

export type SizeGuideReferenceProps = {
    spot: SizeGuideReferenceSpot;
};

export const SizeGuideReference: FC<SizeGuideReferenceProps> = ({ spot }) => {
    return (
        <SizeGuide
            spot={{
                scrollAnchorId: spot.scrollAnchorId,
                alias: 'sizeGuideSpot',
                description: spot.sizeGuide.description,
                dropdownLabel: spot.sizeGuide.dropdownLabel,
                header: spot.sizeGuide.header,
                headerType: spot.sizeGuide.headerType,
                headerSize: spot.sizeGuide.headerSize,
                tabs: spot.sizeGuide.tabs,
                hideBlock: false,
                fromDate: new Date(),
                toDate: new Date(),
                isAboveFold: spot.isAboveFold,
            }}
        />
    );
};
