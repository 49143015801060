import { useEffect, useState, VFC } from 'react';

import {
    ContentWrapper,
    StyledIcon,
    StyledDescription,
    StyledTitle,
    DescriptionButton,
    TitleAndDescription,
    ButtonText,
} from './collapsible-cell.styled';

import { useSpring, config, animated } from 'react-spring';
import { useMeasure } from 'react-use';
import { Button } from '../../../shared';
import { ComparisonTableRow } from '../../../../lib/api/models/umbraco/content-spot';
import { replaceLineBreaks } from '../../../../utils/helpers';

const MEDIA_URL = process.env.NEXT_PUBLIC_MEDIA_URL;

type Props = {
    row: ComparisonTableRow;
    isAboveFold: boolean;
};

export const CollapsibleCell: VFC<Props> = ({ row, isAboveFold = false }) => {
    const defaultHeight: string | undefined = '0px';

    // Gets the height of the element (ref)
    const [heightRef, { height }] = useMeasure();

    const [contentHeight, setContentHeight] = useState(defaultHeight || height);

    useEffect(() => {
        //Sets initial height
        setContentHeight(height);
    }, [height]);

    const [isOpen, setIsOpen] = useState(false);

    // Animations
    const expand = useSpring({
        config: { ...config.slow, friction: 50 },
        height: isOpen ? `${contentHeight}px` : defaultHeight,
        overflow: !isOpen ? 'hidden' : '',
    });

    return (
        <ContentWrapper>
            {row.icon ? (
                <StyledIcon src={`${MEDIA_URL}${row.icon.src}`} alt={row.icon?.name} loading={isAboveFold ? 'eager' : 'lazy'} />
            ) : (
                <div></div>
            )}

            <TitleAndDescription>
                <StyledTitle>
                    <strong>{row.title}</strong>{' '}
                </StyledTitle>
                <DescriptionButton>
                    {row.description ? (
                        <Button variant="link" onClick={() => setIsOpen(!isOpen)}>
                            <ButtonText>{isOpen ? 'Læs mindre' : 'Læs mere'}</ButtonText>
                        </Button>
                    ) : null}
                    <animated.div style={expand}>
                        <StyledDescription ref={heightRef as any} dangerouslySetInnerHTML={{ __html: replaceLineBreaks(row.description) }} />
                    </animated.div>
                </DescriptionButton>
            </TitleAndDescription>
        </ContentWrapper>
    );
};
