import styled from 'styled-components';

export const MobileWrapper = styled.div`
    background-color: black;
    color: white;
    width: 100%;
    padding: 2.875rem 1.25rem;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;

    & * {
        color: white;
    }
    padding-bottom: 150px;
`;

export const MobileContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const AccordionContent = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const AccordionLink = styled.a`
    color: #fcfcfc;
    display: block;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 6px;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;
