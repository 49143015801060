import styled from 'styled-components';
import { animated } from 'react-spring';

export const CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const AnimatedOWnRisk = styled(animated.div)`
    margin-left: 15px;
`;
