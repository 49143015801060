import styled from 'styled-components';

export const ToggleWrapper = styled.div`
    display: inline-block;
`;

export const ToggleGrid = styled.div<{ toggleState: number; totalToggles: number }>`
    background-color: white;
    display: grid;
    grid-template-columns: repeat(${(props) => `${props.totalToggles}, 1fr`});
    align-items: center;
    box-shadow: ${({ theme: { shadows } }) => shadows.large};
    border-radius: 20px;
    overflow: hidden;
    position: relative;

    &:after {
        content: '';
        height: calc(100% - 6px);
        width: calc(${(props) => `${100 / props.totalToggles}%`} - 6px);
        position: absolute;
        left: ${(props) => `calc(${props.toggleState ? (props.toggleState / props.totalToggles) * 100 : 0}% + 3px)`};
        top: 3px;
        background-color: black;
        border-radius: 20px;
        transition: all 0.4s ease-in-out;
    }
`;

export const ToggleOption = styled.div<{ selected: boolean }>`
    padding: 8px 15px;
    text-align: center;
    white-space: nowrap;
    color: ${(props) => (props.selected ? 'white' : ({ theme: { palette } }) => palette.common.black)};
    z-index: 1;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
`;
