import styled from 'styled-components';

export const NotesWithIconWrapper = styled.div`
    display: grid;
    grid-template-columns: 55px 1fr;
    grid-column-gap: 2rem;
    align-items: start;
`;

export const ImageWrapper = styled.div`
    background-color: #f7f7f8;
    width: fit-content;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    min-width: 55px;
`;

export const Content = styled.div``;

export const StyledHeader = styled.p`
    color: #0b0b0b;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
`;

export const StyledText = styled.p`
    opacity: 0.5;
    color: #0b0b0b;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
`;
