import styled from 'styled-components';

import { device } from '../../../../../lib/media-query';

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media ${device.tablet} {
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
`;
