import { VFC } from 'react';
import {
    StyledContentWrapper,
    StyledCtas,
    StyledHeroSplitSpot,
    StyledImageWrapper,
    StyledLargerDevicesOnly,
    StyledSmallDevicesOnly,
    StyledSpacingPostSpot,
    StyledSpotHeader,
    StyledText,
    StyledTextContent,
    StyledTextWrapper,
} from './hero-split-spot.styled';
import { contentSpotTypes } from '../../../lib/api';
import { HeroSplitSpotShape } from './hero-split-spot-shape.component';
import { CtaHandler } from '../../shared/cta-handler';

export type HeroSplitSpotProps = {
    content: contentSpotTypes.HeroSplitSpot;
};

export const HeroSplitSpot: VFC<HeroSplitSpotProps> = ({ content }) => {
    return (
        <>
            <StyledHeroSplitSpot bgColor={content?.backgroundColor} imageUrl={content?.image?.src}>
                <StyledContentWrapper bgColor={content?.backgroundColor} hasImage={content && content.image !== undefined && content.image !== null}>
                    <StyledTextWrapper>
                        <StyledTextContent>
                            {content.headerText && (
                                <StyledSpotHeader
                                    headerType={content.headerType}
                                    headerSize={content.headerSize}
                                    textColorSmall={content?.textColorSmallDevice}
                                    textColorLarge={content?.textColorLargeDevice}
                                >
                                    {content.headerText}
                                </StyledSpotHeader>
                            )}

                            {content?.contentText && (
                                <StyledText textColorSmall={content?.textColorSmallDevice} textColorLarge={content?.textColorLargeDevice}>
                                    {content.contentText}
                                </StyledText>
                            )}

                            {content?.ctas && (
                                <StyledLargerDevicesOnly>
                                    <StyledCtas>
                                        <CtaHandler ctas={content.ctas} />
                                    </StyledCtas>
                                </StyledLargerDevicesOnly>
                            )}

                            {content?.cTASmallDevices && (
                                <StyledSmallDevicesOnly>
                                    <StyledCtas>
                                        <CtaHandler ctas={content.cTASmallDevices} />
                                    </StyledCtas>
                                </StyledSmallDevicesOnly>
                            )}
                        </StyledTextContent>
                        <HeroSplitSpotShape shapeColor={content.backgroundColor ? '#' + content.backgroundColor : undefined}></HeroSplitSpotShape>
                    </StyledTextWrapper>

                    <StyledImageWrapper imageUrl={content?.image?.src} bgColor={content?.backgroundColor}></StyledImageWrapper>
                </StyledContentWrapper>
            </StyledHeroSplitSpot>
            <StyledSpacingPostSpot />
        </>
    );
};
