import styled from 'styled-components';

export const StyledKeyValueTableRow = styled.tr`
    background: #f1f1f2;
    display: grid;
    grid: auto / auto 1fr auto;
    padding: 0 10px 0 0;

    &:nth-child(even) {
        background: #f7f7f8;
    }
`;

export const StyledKeyValueTableCell = styled.td`
    display: block;
    padding: 10px;
    justify-self: flex-end;
    text-align: right;
    white-space: nowrap;

    &:first-child {
        text-align: left;
        white-space: normal;
    }

    &:empty {
        padding: 0;
    }
`;

export const StyledKeyValueTableIcon = styled.img`
    display: block;
    max-height: calc(1em + 8px);
    margin: -4px;
    max-width: calc(1em + 8px);
`;
