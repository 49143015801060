import useSWR from 'swr';
import { getProductList } from '../../../lib/api/vehicle/hessel-vehicle-plp-api';
import { hesselViewModels } from '../../../lib/view-models';
import { mapCarDetailsToProductCard } from '../../../lib/mappers/vehicle/product-card.mapper';

type UseProductRibbonReturnType = {
    vehicles: Array<hesselViewModels.ProductCard>;
};

const fetchVehicles = async (configurationId: string, from: number, take: number, filters: Array<string>, vehicleType: string) => {
    const [fetchedVehicles, fetchedVehiclesFetchError] = await getProductList(
        {
            from: from,
            take: take,
            configurationId: configurationId,
            filters: [...filters],
        },
        vehicleType
    );
    if (fetchedVehicles && !fetchedVehiclesFetchError) {
        return fetchedVehicles.vehicles.map(mapCarDetailsToProductCard);
    } else {
        return [];
    }
};

export function useProductRibbon(
    filterConfigurationId: string | undefined,
    filterConfig: { from: number; take: number; filters: Array<string> },
    vehicleType: string
): UseProductRibbonReturnType {
    const { data: vehicles } = useSWR(
        filterConfigurationId ? [`productRibbon-${filterConfigurationId}`, filterConfig.from, filterConfig.take, ...filterConfig.filters] : null,
        () => fetchVehicles(filterConfigurationId ?? '', filterConfig.from, filterConfig.take, filterConfig.filters, vehicleType),
        { revalidateOnFocus: false, revalidateIfStale: false }
    );

    return { vehicles: vehicles || [] };
}
