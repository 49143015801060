import styled from 'styled-components';
import { ChevronRight } from '../../../icons';

export const NavItemWrapper = styled.section`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 19px 0;
`;

export const NavText = styled.p`
    opacity: 0.9;
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0;
`;

export const StyledChevronRight = styled(ChevronRight)`
    & path {
        stroke: ${(props) => props.theme.palette.primary.main};
        stroke-width: 1px;
    }
`;
