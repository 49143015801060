import { useMemo, VFC } from 'react';
import { FilterUpdateNotification } from '../../../../../lib/api/models/hessel-api/hire';
import { DepartmentFilterResponse } from '../../../../../lib/api/organization/hessel-dealership-api';
import { FacetOption } from '../../../../../lib/view-models/vehicle';
import { CheckboxFilter } from '../checkbox-filter/checkbox-filter.component';

type IProps = {
    onChange: (update: FilterUpdateNotification[]) => void;
    facets: FacetOption[];
    departments: DepartmentFilterResponse[];
};

export const DepartmentFilter: VFC<IProps> = ({ onChange, facets, departments }) => {
    const filteredFacets = useMemo(() => {
        return facets
            .filter((x) => departments.find((y) => y.hovedafdelingId === x.key))
            .map((x) => {
                const department = departments.find((y) => y.hovedafdelingId === x.key);
                const label =
                    department && department.filterLabel && department.filterLabel.length > 0 ? department.filterLabel : department?.dealershipLabel;
                return {
                    ...x,
                    key: label ?? x.key,
                };
            })
            .sort((a, b) => a.key.localeCompare(b.key));
    }, [departments, facets]);
    return <CheckboxFilter facetOptions={filteredFacets} onChange={(filterUpdate) => onChange(filterUpdate)}></CheckboxFilter>;
};
