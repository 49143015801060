import React, { VFC } from 'react';
import { orgTypes } from '../../../../../lib/api/models/umbraco';
import { getOpeningText, mapOpeningHours } from '../../../../../utils/helpers';
import { getGoogleMapsUrl } from '../../../../../utils/helpers/geo-location.helpers';
import {
    IconAndTextWrapper,
    StyledClickableText,
    StyledClock,
    StyledInfoWindow,
    StyledInfoWindowHeader,
    StyledMapPin,
    StyledHoursWrapper,
    StyledText,
    StyledPhone,
    StyledAlert,
    StyleInfoWindowButton,
    StyledAnchor,
} from './marker-info.styled';

type MarkerInfoProps = {
    header: string;
    address: string;
    zipcode: string;
    phone: string;
    ctaAction: () => void;
    openEmergencyPhoneNumberSidePanel: () => void;
    departments?: Array<orgTypes.DealershipDepartment>;
};

export const MarkerInfo: VFC<MarkerInfoProps> = ({
    header,
    address,
    zipcode,
    phone,
    ctaAction,
    openEmergencyPhoneNumberSidePanel,
    departments = [],
}): JSX.Element => {
    const mappedDepartments = React.useMemo(() => mapOpeningHours(departments.filter((x) => x.openingHours?.length > 0)), [departments]);

    return (
        <StyledInfoWindow>
            <StyledInfoWindowHeader>{header}</StyledInfoWindowHeader>
            <IconAndTextWrapper>
                <StyledMapPin />
                <StyledAnchor onClick={(e) => e.stopPropagation()} href={getGoogleMapsUrl(address, zipcode).toString()} target={'_blank'}>
                    {address}
                </StyledAnchor>
            </IconAndTextWrapper>

            <IconAndTextWrapper>
                <StyledClock />
                <StyledHoursWrapper>
                    {mappedDepartments.map((dep, index) => {
                        return (
                            <StyledText key={`dep-${dep.departmentName}:${index}`}>
                                {dep.departmentName} - {getOpeningText(dep.openingHours)}
                            </StyledText>
                        );
                    })}
                </StyledHoursWrapper>
            </IconAndTextWrapper>

            <IconAndTextWrapper>
                <StyledPhone />
                <StyledAnchor href={`tel:${phone}`}>{phone}</StyledAnchor>
            </IconAndTextWrapper>

            <IconAndTextWrapper>
                <StyledAlert />
                <StyledClickableText
                    onClick={(e) => {
                        e.stopPropagation();
                        openEmergencyPhoneNumberSidePanel();
                    }}
                >
                    Se nødservice telefon
                </StyledClickableText>
            </IconAndTextWrapper>

            <StyleInfoWindowButton onClick={() => ctaAction()}>
                <p>Se mere</p>
            </StyleInfoWindowButton>
        </StyledInfoWindow>
    );
};
