import styled from 'styled-components';

export const StyledHeader = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: 15px;
`;

export const StyledDescription = styled.div`
    & > p {
        color: #0b0b0b;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 23px;
    }
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 29px;
`;

export const CarTypeAndBrandList = styled.div``;

export const CarType = styled.p`
    color: #0b0b0b;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
`;

export const Brands = styled.p`
    color: #0b0b0b;
    font-size: 14px;
    letter-spacing: 0;
    font-weight: normal;
`;
