import styled from 'styled-components';
import { Button } from '../button';

export const StyledLoadMoreContainer = styled.div`
    padding: 50px 80px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`;

export const StyledSummaryText = styled.p`
    color: #807f80;
    font-size: 17px;
    font-weight: 500;
`;

export const StyledLoadMoreButton = styled(Button)`
    width: 255px;
`;
