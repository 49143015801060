import { FC } from 'react';
import reactStringReplace from 'react-string-replace';
import { getUniqueId } from '../../../utils/helpers';
import { LabelText } from './label-with-link.styled';

type IProps = {
    labelText: string;
    termsLinkText: string;
    onTermsLinkClick: () => void;
};

export const LabelWithLinkComponent: FC<IProps> = ({ labelText, termsLinkText, onTermsLinkClick }) => {
    const getLabelText = () => {
        const split = reactStringReplace(labelText, '{{terms_link}}', () => (
            <LabelText
                key={getUniqueId()}
                onClick={(e) => {
                    e.preventDefault();
                    onTermsLinkClick();
                }}
            >
                {termsLinkText}
            </LabelText>
        ));
        return split;
    };
    return <p>{getLabelText()}</p>;
};
