import { VFC } from 'react';
import { BrandAndServiceModuleProps } from '../brand-and-service-module/brand-and-service-module.component';
import { BrandAndServiceTableDesktop } from './desktop/desktop.component';
import { BrandAndServiceTableMobile } from './mobile/mobile.component';

export const BrandAndServiceTable: VFC<Pick<BrandAndServiceModuleProps, 'supportedBrands'> & { onCtaClick?: () => void }> = ({
    supportedBrands,
    onCtaClick,
}) => {
    return (
        <>
            <BrandAndServiceTableDesktop supportedBrands={supportedBrands} />
            <BrandAndServiceTableMobile supportedBrands={supportedBrands} onCtaClick={onCtaClick} />
        </>
    );
};
