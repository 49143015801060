import { FC, useCallback, useEffect } from 'react';
import { MangerWrapper } from './step-list.styled';
import { Step } from '../../../shared/step';
import { YourCar, BookingServices, BookingWorkshop, BookingContactInfo, BookingReceipt } from '../../../booking/booking-steps';
import { BookingStepsStore, Step as StepType } from '../../../../lib/state/booking/steps';
import { BookingFormStore } from '../../../../lib/state/booking/form';
import { useBookAppointmentData } from '../../../../hooks/booking/booking/use-book-appointment-data';
import { useGTMBookingData } from '../../../../hooks/booking/booking/use-gtm-booking-data';
import { CompletedWorkshop } from '../../../booking/booking-steps/workshop/completed';
import { useGtmTracking } from '../../../../gtm-tracking/hooks/use-gtm-tracking';
import { umbraco } from '../../../../lib/api';
import { useRouter } from 'next/router';

type Props = {
    siteSettings: umbraco.SiteSettings;
};

export const StepList: FC<Props> = ({ siteSettings }) => {
    const { steps, currentStep } = BookingStepsStore.useStoreState((state) => state);
    const { completeStep, editStep, setCurrentStepLoading, setCanValidateStep } = BookingStepsStore.useStoreActions((actions) => actions);
    const { confirmBooking, onVehicleChange } = BookingFormStore.useStoreActions((actions) => actions);
    const vehicle = BookingFormStore.useStoreState(({ vehicle }) => vehicle);

    const router = useRouter();
    const bookAppointmentData = useBookAppointmentData();
    const gtmData = useGTMBookingData();
    const { trackBooking } = useGtmTracking();
    const tracker = trackBooking();

    const loadStepContent = (step: StepType, stepNumber: number, current: number) => {
        switch (step.type) {
            case 'YourCar':
                return (
                    <YourCar isCompleted={step.isComplete} content={step.content} stepNumber={stepNumber} canValidateStep={step.canValidateStep} />
                );
            case 'Services':
                return <BookingServices isComplete={step.isComplete} content={step.content} stepNumber={stepNumber} />;
            case 'Workshop':
                if (stepNumber !== current && !step.isComplete) {
                    return null;
                }

                return step.isComplete ? (
                    <CompletedWorkshop workshopContent={step.content} />
                ) : (
                    <BookingWorkshop isCompleted={step.isComplete} workshopContent={step.content} stepNumber={stepNumber} />
                );
            case 'ContactInfo':
                return (
                    <BookingContactInfo
                        isCompleted={step.isComplete}
                        content={step.content}
                        stepNumber={stepNumber}
                        canValidateStep={step.canValidateStep}
                    />
                );
            case 'Receipt':
                return current === stepNumber ? <BookingReceipt content={step.content} stepNumber={stepNumber} siteSettings={siteSettings} /> : null;

            default:
                <p>Unknown step</p>;
        }
    };

    const nextStepGTMDataPush = useCallback(
        (step: StepType) => {
            switch (step.type) {
                case 'YourCar':
                    tracker.checkoutOption(1, gtmData?.serviceAgreement, {
                        brand: gtmData?.brand,
                        serviceaftale: gtmData?.serviceAgreement,
                    });
                    return;
                case 'Services':
                    tracker.checkoutOption(2);
                    return;
                case 'Workshop':
                    tracker.checkoutOption(3, gtmData?.deliveryOption);
                    return;
                case 'ContactInfo':
                    tracker.checkoutOption(4, gtmData?.newsletterOption);
                    return;
            }
        },
        [gtmData, tracker]
    );

    const nextStepStatePush = useCallback(
        (stepIndex: number) => {
            const options = {
                pathname: router.asPath.split('?')[0],
                query: { ...router.query, step: stepIndex + 1, slug: undefined },
            };

            delete options.query.slug;

            router.push(options, undefined, { shallow: true, scroll: false });
        },
        [router]
    );

    const goNextHandler = useCallback(
        async (step: StepType, index: number) => {
            nextStepGTMDataPush(step);
            nextStepStatePush(index + 1);
            if (step.type === 'ContactInfo' && bookAppointmentData) {
                setCurrentStepLoading({ isLoading: true, stepNumber: index });
                const error = await confirmBooking(bookAppointmentData);
                setCurrentStepLoading({ isLoading: false, stepNumber: index });

                if (error) return;
            } else if (step.type === 'Receipt' && vehicle) {
                onVehicleChange(vehicle);
            }
            completeStep(index);
        },
        [bookAppointmentData, completeStep, confirmBooking, nextStepGTMDataPush, nextStepStatePush, onVehicleChange, setCurrentStepLoading, vehicle]
    );

    useEffect(() => {
        const step = Number.parseInt(router.query.step as string);
        const stepIndex = step - 1;
        if (Number.isNaN(step)) return;
        if (currentStep === 4 && stepIndex !== 4) {
            window.history.go(-step);
        } else if (stepIndex < currentStep) {
            editStep(stepIndex);
            const stepElement = document.getElementById(`step-wrapper-${step}`);
            if (stepElement) {
                setTimeout(() => {
                    stepElement.scrollIntoView({ behavior: 'smooth' });
                }, 0);
            }
        } else if (stepIndex > currentStep) {
            router.back();
        }
    }, [router.query.step]);

    useEffect(() => {
        const options = {
            pathname: router.asPath.split('?')[0],
            query: { ...router.query, step: 1, slug: undefined },
        };

        delete options.query.slug;

        router.replace(options, undefined, { shallow: true, scroll: false });
    }, []);

    return (
        <MangerWrapper>
            {steps?.map((step, idx) => (
                <Step
                    key={`${idx}:${step.type}`}
                    goNext={() => goNextHandler(step, idx)}
                    edit={() => {
                        editStep(idx);
                        nextStepStatePush(idx);
                    }}
                    stepNumber={idx + 1}
                    totalSteps={steps.length}
                    title={step.content.titleText}
                    nextStep={step.content.submitButtonText}
                    isCurrentStep={idx === currentStep}
                    isCompleted={step.isComplete}
                    footNote={step.content.footnote ?? ''}
                    isStepValid={step.isValid}
                    isStepLoading={step.isStepLoading}
                    setCanValidateStep={() => setCanValidateStep({ validateStep: true, stepNumber: idx })}
                    stepType={step.type}
                >
                    {loadStepContent(step, idx, currentStep)}
                </Step>
            ))}
        </MangerWrapper>
    );
};
