import { action } from 'easy-peasy';
import { DisplayManagerActions, DisplayManagerInjections, DisplayManagerState, DisplayManagerStoreModel } from '.';
import { createContextStoreWithRuntimeModel } from '../helpers/context-store.helpers';

const displayManagerDefaultState = (): DisplayManagerState => ({
    showSavingsModal: false,
    showMyHesselStarPlus: false,
    userClosedMetaMenu: false,
});

const displayActions = (): DisplayManagerActions => ({
    setShowSavingsModal: action((state, payload) => {
        state.showSavingsModal = payload;
    }),
    setShowMyHesselStarPlus: action((state, payload) => {
        state.showMyHesselStarPlus = payload;
    }),
    setUserClosedMetaMenu: action((state, payload) => {
        state.userClosedMetaMenu = payload;
    }),
});

export const DisplayManagerStore = createContextStoreWithRuntimeModel<
    DisplayManagerStoreModel,
    Partial<DisplayManagerState>,
    DisplayManagerInjections
>(
    () => ({
        ...displayManagerDefaultState(),
        ...displayActions(),
    }),
    { name: 'contentDisplayStore' }
);
