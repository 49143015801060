import styled from 'styled-components';
import { device } from '../../lib/media-query';

type StyledCenteredBlockProps = {
    maxWidth: number;
};

export const StyledCenteredWrapper = styled.div`
    padding: 0 20px;
    width: 100%;

    @media ${device.tablet} {
        padding: 0 30px;
    }
`;

export const StyledCenteredBlock = styled.div<StyledCenteredBlockProps>`
    max-width: ${(props) => props.maxWidth}px;
    margin: 0 auto;
`;
