import { useEffect, useMemo, useState, VFC } from 'react';
import { FilterUpdateNotification } from '../../../../../lib/api/models/hessel-api/hire';
import { FacetOption } from '../../../../../lib/view-models/vehicle';
import { getMinAndMaxIntervalValues, getUnitForFacetGroup } from '../../../../../utils/helpers/productlist.helpers';
import DropdownFilter from './dropdown-filter.component';
import { StyledInteractionBlocker, StyledWrapper } from './dropdown-filter.styled';
import { FirstYearRegFilterKey } from '../../../../../constants/site-consts';

type IProps = {
    facets: FacetOption[];
    onChange: (update: FilterUpdateNotification[]) => void;
    groupKey: string;
    separator?: string;
    defaultMaxValue?: string;
};

export const DropdownFilterWrapper: VFC<IProps> = ({ facets, onChange, groupKey, separator = '-', defaultMaxValue = '9999999' }) => {
    const [minimum, setMinimum] = useState('');
    const [maximum, setMaximum] = useState('');

    const setNewFilter = (newMin: string, newMax: string) => {
        setMinimum(newMin);
        setMaximum(newMax);
        const newFilterValue = `${groupKey}__${newMin}${separator}${newMax === '0' ? defaultMaxValue : newMax}`;
        if (newFilterValue === `${groupKey}__0${separator}${defaultMaxValue}`) {
            onChange([
                {
                    action: 'Remove all in key',
                    query: newFilterValue,
                },
            ]);
        } else {
            onChange([
                {
                    action: 'Replace',
                    query: newFilterValue,
                },
            ]);
        }
    };

    const validateMinValues = (value: string) => {
        const numerical = Number(value.replace('.', ''));
        const maxNumerical = Number(maximum);
        return numerical < maxNumerical || maxNumerical === 0 || Number.isNaN(maxNumerical);
    };

    const validateMaxValues = (value: string) => {
        const numerical = Number(value.replace('.', ''));
        const minNumerical = Number(minimum);
        return numerical > minNumerical || numerical === 0;
    };

    useEffect(() => {
        const [min, max] = getMinAndMaxIntervalValues(facets, groupKey, separator);
        setMinimum(min);
        setMaximum(max);
    }, [facets, groupKey, separator]);

    const defaultSelection: FacetOption = {
        count: 0,
        filterQueryValue: '0',
        key: '0',
        selected: !facets.find((x) => x.selected),
    };

    const labelUnit = useMemo(() => {
        return getUnitForFacetGroup(groupKey);
    }, [groupKey]);

    return (
        <StyledWrapper>
            {facets.length === 0 ? <StyledInteractionBlocker /> : null}
            <DropdownFilter
                options={[defaultSelection, ...facets.filter((x) => x.key !== '0')]}
                onChange={(option) => setNewFilter(option?.filterQueryValue ?? '', maximum)}
                selectedValue={minimum}
                valueValidator={(value) => validateMinValues(value)}
                label="Fra"
                labelUnit={labelUnit}
                formatNumericalValues={groupKey !== FirstYearRegFilterKey}
            />
            <DropdownFilter
                options={[...facets.filter((_, idx) => idx !== 0), defaultSelection]}
                onChange={(option) => setNewFilter(minimum, option?.filterQueryValue ?? '')}
                selectedValue={maximum === defaultMaxValue ? '0' : maximum}
                valueValidator={(value) => validateMaxValues(value)}
                label="Til"
                labelUnit={labelUnit}
                formatNumericalValues={groupKey !== FirstYearRegFilterKey}
            />
        </StyledWrapper>
    );
};
