import styled from 'styled-components';

export const StyledCard = styled.div`
    background-color: white;
    width: 100%;
    display: grid;
    gap: 5px;
    padding: 4px;
    grid-template-rows: repeat(3, max-content);

    :hover {
        cursor: pointer;
    }
`;

export const StyledImage = styled.img`
    width: 100%;
    height: 200px;
    object-fit: cover;
`;

export const Header = styled.p`
    font-size: 14px;
    font-weight: 800;
    margin-bottom: 10px;
`;

export const Quote = styled.p`
    font-size: 16px;
    font-weight: 500;
    font-style: italic;
    margin-bottom: 5px;
`;

export const Text = styled.p`
    line-height: normal;
`;

export const CustomerInfoSection = styled.div`
    padding: 12px;
`;

export const RatingSection = styled.div`
    margin-bottom: 20px;
    align-self: flex-end;
`;
