import styled from 'styled-components';

export const AddonsAndEquipmentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    padding: 1rem 0;
    margin-bottom: 1rem;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
`;

export const StyledHeader = styled.h3`
    color: #0b0b0b;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
`;

export const StyledList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
