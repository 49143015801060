import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const StyledContentWrapperMobile = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 29px 44.5px;
    background-color: #ffffff;
    @media ${device.tablet} {
        display: none;
    }
`;

export const LogoWrapper = styled.div`
    margin-bottom: 20px;
`;

export const HeaderAndServices = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-bottom: 34px;
`;

export const StyleImage = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    max-width: 150px;
`;

export const ServicesWrapper = styled.div`
    display: flex;
    gap: 23.65px;
    align-items: baseline;
    justify-self: center;
`;

export const Header = styled.p`
    color: #0b0b0b;
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
`;

export const ServiceIconWrapper = styled.div``;

export const StyledImage = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
`;

export const StyledButtonText = styled.span`
    color: #fafafa;
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.3px;
    line-height: 18px;
    text-align: center;
`;
