import { useState, VFC } from 'react';
import { umbraco } from '../../../lib/api';
import { formatPrice } from '../../../utils/helpers';
import { ScrollIndicator } from '../scroll-cue';
import { CollapsibleCell } from './collapsible-cell';
import { BorderBox, CellWrapper, StickyTh, StyledIcon, StyledTitle, Table, Tbody, Td, Th, Thead, Tr } from './savings-table.styled';

type Props = {
    savings: Array<umbraco.SavingsWithId>;
    yearlyMembership?: number;
    yearlyCompleteMembership?: number;
    membershipSum: number;
    nonMembershipSum: number;
    completeMembershipSum: number;
    content?: umbraco.CustomerSavingsContent;
};

const MEDIA_URL = process.env.NEXT_PUBLIC_MEDIA_URL;

export const SavingsTable: VFC<Props> = ({
    savings,
    yearlyMembership,
    yearlyCompleteMembership,
    membershipSum,
    nonMembershipSum,
    completeMembershipSum,
    content,
}) => {
    const [showScrollCue, setShowScrollCue] = useState(true);

    return (
        <BorderBox
            onScroll={() => {
                if (showScrollCue) {
                    setShowScrollCue(false);
                }
            }}
        >
            <Table>
                <Thead>
                    <Tr>
                        <StickyTh></StickyTh>
                        <Th>{content?.notMemberColumnText}</Th>
                        <Th>{content?.memberColumnText}</Th>
                        {yearlyCompleteMembership && <Th>{content?.completeMemberColumnText}</Th>}
                    </Tr>
                </Thead>
                <Tbody>
                    {savings.map((saving, index) => {
                        return (
                            <Tr key={index} isHighlighted={index % 2 !== 0}>
                                <StickyTh isHighlighted={index % 2 !== 0}>
                                    <CollapsibleCell saving={saving} />
                                </StickyTh>
                                <Td>
                                    <p>{formatPrice(saving.nonMemberPrice)} kr.</p>
                                </Td>
                                <Td>
                                    <p>{formatPrice(saving.memberPrice)} kr.</p>
                                </Td>
                                {yearlyCompleteMembership && (
                                    <Td>
                                        <p>{formatPrice(saving.memberCompletePrice)} kr.</p>
                                    </Td>
                                )}
                            </Tr>
                        );
                    })}

                    <Tr isHighlighted={(savings.length - 1) % 2 === 0}>
                        <StickyTh isHighlighted={savings.length % 2 !== 0}>
                            <CellWrapper>
                                {content?.yearlySubscriptionIcon ? (
                                    <StyledIcon
                                        src={`${MEDIA_URL}${content?.yearlySubscriptionIcon.src}`}
                                        alt={content?.yearlySubscriptionIcon.name}
                                    />
                                ) : (
                                    <div></div>
                                )}
                                <StyledTitle>
                                    <strong>{content?.yearlySubscriptionText}</strong>
                                </StyledTitle>
                            </CellWrapper>
                        </StickyTh>
                        <Td>
                            <p>0 kr.</p>
                        </Td>
                        <Td>
                            <p>{yearlyMembership} kr.</p>
                        </Td>
                        {yearlyCompleteMembership && (
                            <Td>
                                <p>{yearlyCompleteMembership} kr.</p>
                            </Td>
                        )}
                    </Tr>

                    <Tr isHighlighted={(savings.length - 1) % 2 !== 0}>
                        <StickyTh isHighlighted={true}>
                            <CellWrapper>
                                {content?.yearlyTotalExpensesIcon ? (
                                    <StyledIcon
                                        src={`${MEDIA_URL}${content?.yearlyTotalExpensesIcon?.src}`}
                                        alt={content?.yearlyTotalExpensesIcon?.name}
                                    />
                                ) : (
                                    <div></div>
                                )}
                                <StyledTitle>
                                    <strong>{content?.yearlyTotalExpensesText}</strong>
                                </StyledTitle>
                            </CellWrapper>
                        </StickyTh>
                        <Td>
                            <p>
                                <strong>{formatPrice(nonMembershipSum)} kr.</strong>
                            </p>
                        </Td>
                        {yearlyMembership && (
                            <Td>
                                <p>
                                    <strong>{formatPrice(membershipSum + yearlyMembership)} kr.</strong>
                                </p>
                            </Td>
                        )}
                        {yearlyCompleteMembership && (
                            <Td>
                                <p>
                                    <strong>{formatPrice(completeMembershipSum + yearlyCompleteMembership)} kr.</strong>
                                </p>
                            </Td>
                        )}
                    </Tr>
                </Tbody>
            </Table>
            {showScrollCue && (
                <ScrollIndicator icon="/icons/chevron/right.svg" width={50} animationDirection="Horizontal" top="26%" right="-4%" fullWidth={false} />
            )}
        </BorderBox>
    );
};
