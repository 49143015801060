import styled from 'styled-components';
import { device } from '../../../../lib/media-query';
import { Navigator } from '../../../shared';

export const LeadCheckoutLayout = styled.div`
    @media ${device.laptop} {
        display: grid;
        grid-template-columns: auto 420px;
        grid-column-gap: 2rem;
        align-items: start;
    }

    @media ${device.desktop} {
        grid-column-gap: 2.2rem;
    }
`;

export const StepsAndSummary = styled.main`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

export const RightPanel = styled.aside`
    @media ${device.laptop} {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }
`;

export const PreviewLinks = styled.div`
    display: none;

    @media ${device.laptop} {
        display: flex;
        gap: 1.5rem;
        margin-bottom: 1rem;
    }
`;

export const StyledHireNavigator = styled(Navigator)`
    margin: 10px 0;
    margin-bottom: 15px;
`;
