import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const StyleDealershipsMapImage = styled.img`
    display: block;
    object-fit: cover;
    width: 100%;
    height: 508px;

    @media ${device.tablet} {
        height: 735px;
    }
`;
