import { FC } from 'react';
import { SearchStoreContext } from '../../../lib/state/search/search.store';
import { StyledResultTypeHeader } from './search-results.styled';
import { VehicleSearchResults } from './vehicle-search-results.component';
import { ContentSearchResults } from './content-search-results.component';

export const SearchResults: FC = () => {
    const { searchVehicleResults, searchContentResults } = SearchStoreContext.useStoreState((state) => state);

    return (
        <>
            <div style={{ display: searchContentResults.length > 0 ? 'block' : 'none', marginBottom: '20px' }}>
                <StyledResultTypeHeader style={{ marginBottom: '-25px' }}>Indholdssider</StyledResultTypeHeader>
                <ContentSearchResults />
            </div>
            <div style={{ display: searchVehicleResults.length > 0 ? 'block' : 'none' }}>
                <StyledResultTypeHeader>Køretøjer</StyledResultTypeHeader>
                <VehicleSearchResults />
            </div>
        </>
    );
};
