import { useSelect } from 'downshift';
import { FC, useMemo } from 'react';
import { FilterUpdateNotification } from '../../../../../../lib/api/models/hessel-api/hire';
import { FacetOption } from '../../../../../../lib/view-models/vehicle';
import { DropdownLabel, DropdownWrapper, OptionsWrapper, ValueAndArrowWrapper, Option, OptionsBackground } from '../dropdown-filter.styled';
import Image from 'next/image';

type IProps = {
    options: FacetOption[];
    onChange: (update: FilterUpdateNotification[]) => void;
    groupKey: string;
    label?: string;
    unit?: string;
    minOrMax?: 'Minimum' | 'Maximum';
};

export const DropdownFilterSingleSelect: FC<IProps> = ({ options, onChange, groupKey, label = 'Minumum', unit = 'kg', minOrMax = 'Minimum' }) => {
    const defaultSelection: FacetOption = useMemo(() => {
        return {
            count: 0,
            filterQueryValue: `${groupKey}__0`,
            key: '0',
            selected: !options.find((x) => x.selected),
        };
    }, [groupKey, options]);
    const { isOpen, getItemProps, getLabelProps, getMenuProps, getToggleButtonProps, toggleMenu } = useSelect({
        items: [defaultSelection, ...options],
        itemToString: (item) => item?.key ?? '',
        onSelectedItemChange: ({ selectedItem }) => {
            toggleMenu();
            if (selectedItem) {
                onChange([
                    {
                        action: selectedItem.filterQueryValue === `${groupKey}__0` ? 'Remove all in key' : 'Replace',
                        query:
                            minOrMax === 'Minimum'
                                ? `${selectedItem.filterQueryValue}-999999`
                                : `${selectedItem.filterQueryValue.split('__').join('__0-')}`,
                    },
                ]);
            }
        },
        initialSelectedItem: options.find((x) => x.selected),
    });

    const selectedOptionLabel = useMemo(() => {
        const option = options.find((x) => x.selected);
        if (option) return `${option.key} ${unit}`;
        return 'Vælg';
    }, [options, unit]);

    const optionsToRender = useMemo(() => {
        return [defaultSelection, ...options];
    }, [defaultSelection, options]);

    return (
        <DropdownWrapper {...getToggleButtonProps()}>
            <DropdownLabel {...getLabelProps()}>{label}</DropdownLabel>
            <ValueAndArrowWrapper open={isOpen}>
                <span style={{ position: 'static', zIndex: 3 }}>{selectedOptionLabel}</span>
                <Image src="/icons/chevron/down.svg" height="20px" width="20px" />
            </ValueAndArrowWrapper>
            <OptionsWrapper open={isOpen} {...getMenuProps()}>
                <OptionsBackground>
                    {isOpen &&
                        optionsToRender.map((item, index) => {
                            return (
                                <Option key={index} {...getItemProps({ item, index })} disabled={false}>
                                    {item.key === '0' ? 'Alle' : `${item.key} ${unit}`}
                                </Option>
                            );
                        })}
                </OptionsBackground>
            </OptionsWrapper>
        </DropdownWrapper>
    );
};
