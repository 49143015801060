import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const StyledShopProductDetailsPageWrapper = styled.main`
    background-color: rgb(247, 247, 248);
    padding: 80px 0;

    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const StyledShopProductDetailsGrid = styled.div`
    display: grid;
    gap: 66px;
    @media ${device.laptop} {
        grid-template-columns: 2fr 1.1fr;
    }
`;
