import { VFC } from 'react';
import { formatPrice } from '../../../utils/helpers';
import { generateRange } from '../../../utils/helpers/array.helpers';
import {
    BorderBox,
    InputSliderWrapper,
    MinMaxWrapper,
    StyledMinMaxText,
    StyledRange,
    StyledSlider,
    StyledThumb,
    StyledTrack,
} from './stepped-input-slider.styled';

type Props = {
    min: number;
    max: number;
    defaultValue: number;
    interval: number;
    value: number;
    unit?: string;
    onValueChange: (value: number) => void;
};

export const SteppedInputSlider: VFC<Props> = ({ min, max, defaultValue, interval, value, unit, onValueChange }) => {
    return (
        <BorderBox>
            <InputSliderWrapper>
                <StyledSlider
                    defaultValue={[defaultValue]}
                    min={+min}
                    max={+max}
                    step={+interval}
                    aria-label="Price"
                    value={[value]}
                    onValueChange={(e) => {
                        onValueChange(e[0]);
                    }}
                >
                    <StyledTrack>
                        <StyledRange />
                    </StyledTrack>
                    <StyledThumb />
                </StyledSlider>
            </InputSliderWrapper>
            <MinMaxWrapper>
                {generateRange(min, max, interval).map((x, index) => {
                    return (
                        <StyledMinMaxText key={`${x}-${index}`}>
                            {formatPrice(x)}
                            {unit ? `${unit}` : ''}
                        </StyledMinMaxText>
                    );
                })}
            </MinMaxWrapper>
        </BorderBox>
    );
};
