import styled from 'styled-components';

export const SortingDropdownOptions = styled.div<{ open: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    box-shadow: ${({ theme: { shadows } }) => shadows.large};
    border-radius: 20px;
    display: ${(props) => (props.open ? 'flex' : 'none')};
    flex-direction: column;
    background-color: white;
    padding: 35px 0 15px;

    &:focus,
    &:active {
        outline: none;
    }
`;

export const SortingDropdownWrapper = styled.div`
    display: flex;
    padding: 0 15px;
    font-size: 12px;
    background-color: white;
    box-shadow: ${({ theme: { shadows } }) => shadows.large};
    border-radius: 20px;
    column-gap: 10px;
    align-items: center;
    position: relative;
    z-index: 11;
    cursor: pointer;
    min-width: 200px;
    justify-content: space-between;
`;

export const SortingHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const StyledSortingLabel = styled.span`
    font-weight: 500;
    z-index: 11;
`;

export const SortingValue = styled.span`
    color: #807f80;
    z-index: 11;
`;

export const SortingOption = styled.div<{ disabled: boolean }>`
    padding: 5px 15px;
    color: ${(props) => (props.disabled ? 'grey' : 'currentColor')};
    pointer-events: ${(props) => (props.disabled ? 'none' : 'revert')};

    &:hover {
        background-color: ${({ theme: { palette } }) => palette.common.offWhite};
    }
`;

export const ArrowContainer = styled.div<{ open: boolean }>`
    z-index: 11;
    margin-top: 2px;

    img {
        transition: all 0.2s ease-in-out;
        ${(props) => (props.open ? 'transform: rotate(180deg);' : '')}
    }
`;
