import styled from 'styled-components';
import { device } from '../../../../../../lib/media-query';

export const EquipmentSummaryWrapper = styled.section`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));

    @media ${device.tablet} {
        grid-template-columns: repeat(5, 1fr);
        width: 100%;
    }
`;
