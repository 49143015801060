import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const Wrapper = styled.div<{ alignItems: 'center' | 'baseline' | 'top' }>`
    display: flex;
    align-items: ${(props) => props.alignItems};
    ${(props) => (props.alignItems === 'top' ? 'input { margin-top: 2px; }' : '')}
`;

export const StyledCheckBox = styled.input.attrs({ type: 'checkbox' })<{ error: boolean; size: number }>`
    aspect-ratio: 1 / 1;
    background-color: #fff;
    border-radius: ${({ theme }) => `${theme.typography.inputCheckbox.borderRadius}px`};
    border: 1px solid ${(props) => (props.error ? props.theme.palette.error.main : props.theme.forms.borderColorDefault)};
    cursor: pointer;
    display: inline-block;
    height: ${({ theme }) => `${theme.typography.inputCheckbox.size}px`};
    outline: none;
    position: relative;
    transition: background-color 0.3s, border-color 0.3s, box-shadow 0.2s;
    -moz-appearance: none;
    -webkit-appearance: none;

    &::after {
        border-color: ${({ theme }) => theme.palette.common.white};
        border-style: solid;
        border-width: 0 0 2px 2px;
        content: '';
        display: block;
        height: calc(${({ theme }) => `${theme.typography.inputCheckbox.size}px / 3`});
        width: calc(${({ theme }) => `${theme.typography.inputCheckbox.size}px / 2`});
        opacity: 0;
        position: absolute;
        left: calc(${({ theme }) => `${theme.typography.inputCheckbox.size}px / 5`});
        top: calc(${({ theme }) => `${theme.typography.inputCheckbox.size}px / 5`});
        transform: rotate(-50deg);
        transition: opacity 0.3s;
    }

    &:checked {
        background-color: ${({ theme }) => theme.palette.primary.main};

        &::after {
            opacity: 1;
        }
    }

    &:hover:not(:checked):not(:disabled) {
        border-color: ${({ theme }) => theme.forms.borderColorHover};
    }

    &:focus {
        box-shadow: 0 0 0 2px rgba(39, 94, 254, 0.3);
    }

    &:disabled {
        background-color: #efefef;
        cursor: not-allowed;

        &:checked {
            background-color: ${({ theme }) => theme.palette.primary.main};
            border-color: ${(props) => props.theme.forms.borderColorDefault};
        }

        & + label {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }
`;

export const StyledLabel = styled.label<{ expandLabel: boolean }>`
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: ${({ theme }) => `${theme.typography.small.fontSize}px`};
    font-weight: ${({ theme: { forms } }) => forms.fontWeight};
    vertical-align: top;
    cursor: pointer;
    padding-left: 10px;
    line-height: ${({ theme }) => `${theme.typography.inputCheckbox.size}px`};
    width: ${(props) => (props.expandLabel ? '100%' : 'auto')};

    @media ${device.tablet} {
        /* font-size: ${({ theme }) => `${theme.typography.inputLabel.fontSize}px`}; */
        font-size: ${({ theme: { forms } }) => forms.fontSize}px;
        font-weight: ${({ theme: { forms } }) => forms.fontWeight};
    }
`;

export const BorderBox = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledError = styled.span`
    display: flex;
    flex-direction: column;
    color: ${({ theme: { palette } }) => palette.error.main};
    font-size: ${({ theme: { typography } }) => typography.small.fontSize}px;
    margin-left: 10px;
`;
