import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const FormWrapper = styled.div<{ formLoading: boolean }>`
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 2rem;
    transition: filter 0.4s ease-in-out;
    filter: ${(props) => (props.formLoading ? 'grayscale(0.5) blur(2px)' : 'grayscale(0) blur(0)')};
    ${(props) => (props.formLoading ? 'pointer-events: none;' : '')};
`;

export const FormContainer = styled.section`
    width: 90%;
    margin: 0 auto;

    @media ${device.tablet} {
        width: 720px;
        padding: 2rem;
    }
`;

export const FormHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
    padding-bottom: 1rem;

    @media ${device.tablet} {
        border-bottom: 1px solid ${({ theme }) => theme.palette.grey.medium};
    }
`;

export const StepIndicatorWrapper = styled.div<{ active: boolean; complete: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${({ theme }) => theme.palette.grey.medium};
    ${(props) => (props.active ? 'color:revert;' : '')}

    &:not(:last-of-type) {
        padding-right: 3rem;
    }
`;

export const StepIndicatorContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
`;

export const StepIndicator = styled.div<{ active: boolean; complete: boolean }>`
    height: 70px;
    width: 70px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.palette.grey.medium};
    color: ${({ theme }) => theme.palette.grey.medium};
    ${(props) => (props.active ? 'border-color: revert;color:revert;' : '')}
    font-family: "Corporate E";
    font-weight: bold;
    font-size: 2rem;
    background-color: white;
    margin-bottom: 0.5rem;
`;

export const ButtonRow = styled.div`
    display: flex;
    gap: 1rem;
`;

export const UmbracoFormHeader = styled.p`
    color: #0b0b0b;
    font-family: ${({ theme }) => theme.fontFamily.primary};
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
`;
