import Link from 'next/link';
import React, { FC } from 'react';
import { hesselViewModels } from '../../../lib/view-models';
import { generatePdpUrl } from '../../../utils/helpers';
import { VehicleProductCardCarousel } from '../../vehicle/vehicle-product-card-carousel/vehicle-product-card-carousel.component';
import { StyledCenteredBlock } from './related-vehicles-ribbon.styled';
import { VehicleProductListCard } from '../../vehicle/vehicle-product-list/vehicle-product-list-card';

type RelatedVehiclesRibbonMemoProps = {
    className?: string;
    header?: string;
    description?: string;
    vehicles: Array<hesselViewModels.ProductCard>;
    onVehicleClick?: () => void;
    isAboveFold?: boolean;
    showAsCompanyCars?: boolean;
};

const RelatedVehiclesRibbonMemo: FC<RelatedVehiclesRibbonMemoProps> = ({
    className,
    header,
    description,
    vehicles,
    onVehicleClick,
    isAboveFold = false,
    showAsCompanyCars = false,
}) => {
    return (
        <StyledCenteredBlock className={className}>
            <VehicleProductCardCarousel header={header ?? ''} text={description ?? ''}>
                {vehicles.map((product, index) => {
                    const pdpUrl = generatePdpUrl(product, showAsCompanyCars);
                    return (
                        <Link key={`${product.id}-${index}`} href={pdpUrl}>
                            <a href={pdpUrl} style={{ textDecoration: 'none' }}>
                                <VehicleProductListCard
                                    nonScrollableId={`related-vehicles-${product.id}-${index}`}
                                    productCard={product}
                                    className="keen-slider__slide"
                                    onCardClick={() => onVehicleClick?.()}
                                    allowCarousel={false}
                                    isAboveFold={isAboveFold}
                                    priceType={showAsCompanyCars ? 'Taxation' : 'Purchase'}
                                />
                            </a>
                        </Link>
                    );
                })}
            </VehicleProductCardCarousel>
        </StyledCenteredBlock>
    );
};

export const RelatedVehiclesRibbon = React.memo(RelatedVehiclesRibbonMemo);
