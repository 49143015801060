import styled from 'styled-components';
import * as SliderPrimitive from '@radix-ui/react-slider';

export const BorderBox = styled.div``;

export const InputSliderWrapper = styled.div`
    position: relative;
`;

// Input slides
export const StyledSlider = styled(SliderPrimitive.Root)`
    position: relative;
    display: flex;
    align-items: center;
    user-select: none;
    touch-action: none;
    width: 100%;
`;

export const StyledTrack = styled(SliderPrimitive.Track)`
    background-color: white;
    position: relative;
    flex-grow: 1;
    border-radius: 9999px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);

    &[data-orientation='horizontal'] {
        height: 5px;
    }
    &[data-orientation='vertical'] {
        width: 5px;
    }
`;

export const StyledRange = styled(SliderPrimitive.Range)`
    position: absolute;
    background-color: black;
    border-radius: 9999px;
    height: 100%;
`;

export const StyledThumb = styled(SliderPrimitive.Thumb)`
    all: unset;
    display: block;
    width: 20px;
    height: 20px;
    background-color: black;

    border-radius: 10px;
    &:hover {
        background-color: #000;
        cursor: grab;
    }
    &:focus {
        box-shadow: 0 2px 10px #000;
    }
`;

export const MinMaxWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
`;

export const StyledMinMaxText = styled.div`
    color: #0b0b0b;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 23px;
`;
