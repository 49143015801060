import styled from 'styled-components';

import { contentSpotTypes } from '../../../../lib/api';

import { device } from '../../../../lib/media-query';
import { SpotHeader } from '../../../shared/typography/spot-header/spot-header.component';

const getElementsPosition = (count: number, position: contentSpotTypes.Position): string => {
    if (count > 1) {
        return 'space-between';
    }

    switch (position) {
        case 'Left':
            return 'flex-start';
        case 'Center':
            return 'center';
        case 'Right':
            return 'flex-end';
        case '':
            return 'unset';
        default:
            throw new Error('ejh.spa > getElementsPosition: Invalid position values');
    }
};

const getVerticalAlignment = (count: number, position: contentSpotTypes.VerticalAlignment): string => {
    if (count > 1) {
        return 'center';
    }

    switch (position) {
        case 'Top':
            return 'flex-start';
        case 'Center':
            return 'center';
        case 'Bottom':
            return 'flex-end';
        case '':
        default:
            return 'center';
    }
};

export const FullWrapper = styled.div<{
    backgroundColor?: string;
    isClickable: boolean;
    imagesOnly: boolean;
    verticalAlignment: contentSpotTypes.VerticalAlignment | undefined;
    horizontalAlignment: contentSpotTypes.Position | undefined;
}>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: ${(props) => (props.verticalAlignment === 'Center' && props.horizontalAlignment === 'Center' ? 'center' : 'flex-start')};

    width: 100%;
    padding: 40px 0;

    background-color: ${(props) => (props.backgroundColor ? '#' + props.backgroundColor : 'rgba(0, 0, 0, 0.7)')};

    cursor: ${(props) => (props.isClickable ? 'pointer' : 'default')};

    & * {
        z-index: 20;
    }

    min-height: revert;
    aspect-ratio: ${(props) => (props.imagesOnly ? '1.3 / 1' : '4 / 3')};

    @media ${device.tablet} {
        aspect-ratio: 16 / 4.4;
        padding: revert;
        height: 100%;
        ${(props) => (props.imagesOnly ? '' : 'min-height: 400px;')}
        justify-content: center;
    }

    @media (max-width: 590px) {
        aspect-ratio: ${(props) => (props.imagesOnly ? '1.3 / 1' : ' 1 / 1.5')};
    }
`;

export const Content = styled.div<{
    elementsCount: number;
    availablePosition: contentSpotTypes.Position;
    verticalAlignment: contentSpotTypes.VerticalAlignment;
}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4rem;
    align-items: center;
    color: ${({ theme }) => theme.palette.common.white};
    width: 100%;
    position: relative;
    padding: 0 0 40px;

    @media ${device.tablet} {
        flex-direction: row;
        justify-content: ${(props) => getElementsPosition(props.elementsCount, props.availablePosition)};
        align-items: ${(props) => getVerticalAlignment(props.elementsCount, props.verticalAlignment)};
        height: 360px;
        padding: 0;
    }
`;

export const CtaWrapper = styled.div<{ textColor: string }>`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    color: ${(props) => '#' + props.textColor};
    justify-content: center;
    max-width: 420px;
    position: relative;

    @media ${device.tablet} {
        padding: 0;
        text-align: left;

        & > button {
            align-self: flex-start;
        }
    }
`;

export const StyledSpotHeader = styled(SpotHeader)<{ textColor: string }>`
    color: ${(props) => '#' + props.textColor};
`;

export const CtaText = styled.p`
    font-size: ${({ theme }) => theme.typography.fontSize};
`;

export const SlideDisclaimer = styled.p<{ textColor: string }>`
    color: ${(props) => '#' + props.textColor};

    opacity: 0.7;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 12px;

    @media ${device.tablet} {
        position: absolute;
        left: 0px;
        bottom: -3rem;
    }
`;

export const CtaListWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;

    @media ${device.tablet} {
        justify-content: flex-start;
    }
`;
