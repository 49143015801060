import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const Wrapper = styled.section`
    display: grid;
    grid-template-columns: auto;
    row-gap: 20px;
    width: 100%;
`;

export const ChildWrapper = styled.section``;

export const Footer = styled.div`
    display: flex;
    flex-direction: column;

    & > button {
        margin-bottom: 1rem;
        margin-right: revert;
    }

    @media ${device.mobileL} {
        flex-direction: row;
        justify-content: center;
        margin-top: auto;

        & > button {
            margin-bottom: revert;
            margin-right: 1rem;
        }
    }
`;
