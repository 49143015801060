import { VFC } from 'react';
import { hesselViewModels } from '../../../../../../lib/view-models';
import { SubmittedInfo } from '../../../../../shared/submitted-info';
import { EquipmentSummaryWrapper } from './equipment-summary.styled';
import { Abbreviations } from '../../../../../../constants/units-and-abbreviations';

type EquipmentSummaryProps = {
    selectedProduct?: hesselViewModels.ProductDetails;
};

export const EquipmentSummary: VFC<EquipmentSummaryProps> = ({ selectedProduct }) => {
    if (!selectedProduct) {
        return null;
    }

    return (
        <EquipmentSummaryWrapper>
            {selectedProduct.availability === 'Order'
                ? selectedProduct.extraEquipment['Car HiRE'].map((eq, idx) =>
                      eq.isSelected ? (
                          <SubmittedInfo key={`${idx}:${eq.id}`} label={eq.name} text={`${eq.monthlyHirePrice} ${Abbreviations.KR_SLASH_MD}`} />
                      ) : null
                  )
                : null}

            {selectedProduct.availability === 'Order'
                ? selectedProduct.extraEquipmentPackages['Car HiRE'].map((eq, idx) =>
                      eq.isSelected ? (
                          <SubmittedInfo key={`${idx}:${eq.id}`} label={eq.name ?? ''} text={`${eq.monthlyHirePrice} ${Abbreviations.KR_SLASH_MD}`} />
                      ) : null
                  )
                : null}

            {selectedProduct.optionalEquipment['Car HiRE'].map((eq, idx) =>
                eq.isSelected ? (
                    <SubmittedInfo key={`${idx}:${eq.id}`} label={eq.name} text={`${eq.monthlyHirePrice} ${Abbreviations.KR_SLASH_MD}`} />
                ) : null
            )}
        </EquipmentSummaryWrapper>
    );
};
