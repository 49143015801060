import { FC, useMemo } from 'react';
import { hesselViewModels } from '../../../lib/view-models';
import { energyRatingLabelAndColor } from '../../../lib/view-models/vehicle';
import { StyledEnergyRating, StyledEnergyRatingLabel } from './energy-rating.styled';

export type EnergyRatingProps = {
    className?: string;
    rating: hesselViewModels.EnergyRating;
};

export const EnergyRating: FC<EnergyRatingProps> = ({ className, rating }) => {
    const { energyRating: label, color } = useMemo(() => energyRatingLabelAndColor(rating), [rating]);
    const descriptiveLabel = useMemo(() => `Energimærke: ${label}`, [label]);

    return (
        <StyledEnergyRating aria-label={descriptiveLabel} backgroundColor={color} className={className}>
            <StyledEnergyRatingLabel>{label}</StyledEnergyRatingLabel>
        </StyledEnergyRating>
    );
};
