import styled from 'styled-components';

export const DemoVehiclesProductRibbonWrapper = styled.div`
    background-color: #f7f7f8;
    padding: 60px 0;
`;

export const NoResultWrapper = styled.div`
    padding: 40px;

    background-color: ${({ theme }) => theme.palette.common.white};
`;

export const TextsAndCta = styled.div`
    margin-top: 37px;
`;

export const Header = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: 10px;
    line-height: 23px;
`;

export const Description = styled.p`
    color: #0b0b0b;
    font-size: 14px;
    letter-spacing: 0;
    font-weight: normal;
    margin-bottom: 10px;
`;

export const NoResultHeader = styled.h2`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: 15px;
`;

export const NoResultDescription = styled.div`
    color: #0b0b0b;
    font-size: 14px;
    letter-spacing: 0;
    font-weight: normal;
    margin-bottom: 45px;

    p:last-of-type {
        margin-bottom: 0 !important;
    }
`;
