import { getAPI } from '../helpers';
import { contentSpotTypes, umbraco } from '../models';
import { orgTypes, sharedTypes } from '../models/umbraco';
import { FormsSpot } from '../models/umbraco/content-spot';
import { APIResponse } from '../types';

type DealershipSiteId = 'dealershipInformation' | 'starmarkDealershipInformation';

type SiteIdentifier<T extends DealershipSiteId> = { contentTypeAlias: T };

export type BaseDealership<T extends DealershipSiteId> = SiteIdentifier<T> & {
    id: string;
    parentId: string;
    name: string;
    url: string;
    updateDate: string;
    sortOrder: number;
    hasChildren: boolean;
    breadcrumbs: Array<sharedTypes.Breadcrumb>;
};

export type UmbracoStarmarkDealership = BaseDealership<'starmarkDealershipInformation'> & orgTypes.StarmarkDealership;

export type HesselDealership = BaseDealership<'dealershipInformation'> & {
    autolineId: string;
    hovedafdelingId: string;
    displayName: string;
    phone: string;
    eMail: string;
    address: string;
    latitude: number;
    longitude: number;
    city: string;
    zipcode: string;
    supportedBrands: Array<orgTypes.SupportedBrand>;
    departments: Array<orgTypes.DealershipDepartment>;
    truckEmergencyNumber: string;
    writeUsForm: Array<FormsSpot>;
    testDriveOpeningHours: Array<{
        openingHours: Array<orgTypes.OpeningHour>;
        specialDays: Array<orgTypes.SpecialDay>;
    }>;
    certifications: Array<orgTypes.OrgCertificate>;
    bookWorkshopDescription: string;
    bookWorkshopHeader: string;
    bookWorkshopModalButtonText: string;
    dealershipImage?: sharedTypes.Image;
};

export type OrgDealershipResponse = {
    id: string;
    parentId: string;
    contentTypeAlias: string;
    name: string;
    url: string;
    updateDate: string;
    sortOrder: number;
    hasChildren: boolean;
    breadcrumbs: Array<sharedTypes.Breadcrumb>;
    emergencyPhoneNumbers: Array<orgTypes.DepartmentEmergencyNumbers>;
    contentSpotSettings: contentSpotTypes.ContentSpotSettings[];
    pageMetaMenu?: Array<umbraco.PageMetaMenu>;
    demoCarNotAvailableModel: Array<orgTypes.DemoCarNotAvailableModal>;
    children: Array<HesselDealership | UmbracoStarmarkDealership>;
};

export type DepartmentFilterResponse = {
    name: string;
    hovedafdelingId: string;
    dealershipLabel: string;
    filterLabel: string;
};

export const getDepartmentsForFilter = async (url: string): APIResponse<DepartmentFilterResponse[]> => {
    return getAPI<DepartmentFilterResponse[]>(`${process.env.NEXT_PUBLIC_API_URL}/${url}`);
};
