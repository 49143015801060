import { FC, useState } from 'react';
import {
    StyledCheckboxAndPriceWrapper,
    StyledInstallationCheckboxLabel,
    StyledInstallationCheckboxWrapper,
    StyledInstallationDisclaimer,
    StyledInstallationPrice,
} from './product-installation-checkbox.styled';
import { CheckBox } from '../../shared';
import { getShopPrice } from '../../../utils/helpers';
import { InstallationRequirement } from '../../../lib/api/models/shop';

type IProps = {
    installationRequirement: InstallationRequirement;
    productId: string;
};

export const ProductInstallationCheckbox: FC<IProps> = ({ installationRequirement, productId }) => {
    const [selected, setSelected] = useState(installationRequirement === 'Obligatorisk montering hos Hessel');
    return (
        <>
            <StyledInstallationCheckboxWrapper>
                <StyledCheckboxAndPriceWrapper>
                    <CheckBox
                        id={productId}
                        checked={selected}
                        disabled={installationRequirement === 'Obligatorisk montering hos Hessel'}
                        onChange={(e) => setSelected(e)}
                        value="installation"
                    >
                        <StyledInstallationCheckboxLabel>Tilføj montering af vare</StyledInstallationCheckboxLabel>
                    </CheckBox>
                    {/* <StyledInstallationPrice>+{getShopPrice(265)}</StyledInstallationPrice> */}
                    <StyledInstallationPrice>
                        {installationRequirement === 'Obligatorisk montering hos Hessel' ? 'Inkl. i prisen' : `+${getShopPrice(265)}`}
                    </StyledInstallationPrice>
                </StyledCheckboxAndPriceWrapper>
                <div style={{ marginTop: '5px', marginLeft: '27px' }}>
                    <StyledInstallationDisclaimer>Dato og tidspunkt vælges i checkout</StyledInstallationDisclaimer>
                </div>
            </StyledInstallationCheckboxWrapper>
        </>
    );
};
