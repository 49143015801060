import styled from 'styled-components';
import { device } from '../../lib/media-query';
import { mixins } from '../../themes';

export const ContentWrapper = styled.div`
    display: none;
    @media ${device.laptopS} {
        display: revert;
    }
`;

export const StyledHelp = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3rem;
    ${mixins.gap(3)}
    position: revert;
    height: auto;
    top: revert;
    top: 100px;
    padding: 20px;

    @media ${device.laptopS} {
        padding: revert;
        position: sticky;
        top: 100px;
    }
`;

export const ButtonWrapper = styled.div`
    position: fixed;
    bottom: 1rem;
    right: 1rem;

    @media ${device.laptopS} {
        display: none;
    }
`;
