import { FC, useMemo } from 'react';
import { useSpring, config } from 'react-spring';
import { useBenefitAgreementInfo } from '../../../../../hooks/booking/booking/use-benefit-agreement-info';
import { useAgreements } from '../../../../../hooks/use-agreements';
import { BookingFormStore } from '../../../../../lib/state/booking/form';
import { BookingStepsStore } from '../../../../../lib/state/booking/steps';
import { filterStepType } from '../../../../../lib/state/booking/steps/booking-steps.helpers';
import { vehicleName, customerAgreement } from '../../../../../utils/helpers';
import { Button, LoadingWrapper } from '../../../../shared';
import { Spinner } from '../../../../shared/spinner';
import { SvgIcon } from '../../../../shared/svg-icon';
import {
    BrandInfo,
    BrandInfoDescription,
    BrandInfoItem,
    BrandInfoItemDescription,
    BrandInfoItemHeader,
    BrandInfoItemText,
    ButtonWrapper,
} from './brand.styled';

type Props = {
    show: boolean;
    isLoading?: boolean;
    showAgreements: () => void;
};

export const Brand: FC<Props> = ({ show, showAgreements, isLoading }) => {
    const { content } = BookingStepsStore.useStoreState((state) => ({
        content: state.steps
            .filter(filterStepType('YourCar'))
            .map(({ content }) => content)
            .reduce((acc, content) => ({ ...acc, ...content })),
    }));

    const { agreements, vehicle } = BookingFormStore.useStoreState(({ vehicle, customer }) => ({
        vehicle: vehicle,
        agreements: { benefitAgreement: customer?.benefitAgreement, serviceAgreement: vehicle?.serviceAgreement },
    }));

    const { registeredCarInfoLabel, registeredAgreementLabel, registeredSectionDescription, registeredSectionActionText } = content;

    const hasAgreements = useAgreements();

    const fadeContent = useSpring({
        config: { ...config.slow, friction: 50 },
        opacity: show ? 1 : 0,
        delay: 100,
    });
    const benefitAgreementInfo = useBenefitAgreementInfo();

    const knownCar = useMemo(() => {
        return isLoading || vehicle?.knownCar;
    }, [isLoading, vehicle]);

    if (!show) return null;

    return (
        <BrandInfo style={fadeContent}>
            {knownCar && (
                <BrandInfoItem hasAgreements={hasAgreements}>
                    {isLoading ? <Spinner variant="blue" size="small"></Spinner> : <SvgIcon iconName="checkmark" color="success" />}

                    <LoadingWrapper isLoading={isLoading}>
                        <SvgIcon iconName="car/car-side-view" color="defaultCircular" />
                    </LoadingWrapper>
                    <LoadingWrapper isLoading={isLoading}>
                        <BrandInfoItemText>
                            <BrandInfoItemHeader>{registeredCarInfoLabel}</BrandInfoItemHeader>
                            <BrandInfoItemDescription>
                                {!isLoading && vehicle?.makeId && vehicle?.model && vehicle?.variant
                                    ? vehicleName(vehicle.makeId, vehicle.model, vehicle.variant)
                                    : 'Lorem ipsum dolor sit amet consectetur adipisicing'}
                            </BrandInfoItemDescription>
                        </BrandInfoItemText>
                    </LoadingWrapper>
                </BrandInfoItem>
            )}
            {!knownCar && (
                <BrandInfoItem hasAgreements={false} disableGrid={true}>
                    <BrandInfoItemText>
                        <BrandInfoItemHeader>Kontakt kundeservice</BrandInfoItemHeader>
                        <BrandInfoItemDescription>
                            Vi oplever, at vi ikke kan slå din bil op i vores systemer. Da der givetvis er tale om en fejl, vil vi sætte stor pris på,
                            om du vil kontakte vores kundeservice på <a href="mailto:service@hessel.dk">service@hessel.dk</a> eller{' '}
                            <a href="tel:72115005">72115005</a>, så vi kan hjælpe dig med at komme på værksted.
                        </BrandInfoItemDescription>
                    </BrandInfoItemText>
                </BrandInfoItem>
            )}

            {hasAgreements && (
                <>
                    <BrandInfoItem hasAgreements={hasAgreements}>
                        <SvgIcon iconName="checkmark" color="success" />
                        <SvgIcon iconName="car/star" color="defaultCircular" />
                        <BrandInfoItemText>
                            <BrandInfoItemHeader>{registeredAgreementLabel} </BrandInfoItemHeader>
                            <BrandInfoItemDescription>
                                {customerAgreement(content, agreements, benefitAgreementInfo?.agreementName)}
                            </BrandInfoItemDescription>
                        </BrandInfoItemText>
                    </BrandInfoItem>

                    <BrandInfoDescription>{registeredSectionDescription} </BrandInfoDescription>

                    <ButtonWrapper>
                        <Button variant="link" onClick={showAgreements}>
                            {registeredSectionActionText}
                        </Button>
                    </ButtonWrapper>
                </>
            )}
        </BrandInfo>
    );
};
