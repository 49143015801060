import { action, persist, thunk } from 'easy-peasy';
import { CartActions, CartState, CartStore, CartThunks } from './cart.types';
import { createContextStoreWithRuntimeModel } from '../../helpers/context-store.helpers';
import {
    addCustomerToCart,
    addProductToCart,
    deleteOrderline,
    setDealershipAsDelivery,
    setHomeDeliveryAsDelivery,
    setParcelShopPickupAsDelivery,
    startCheckoutPayment,
    updateOrderlineQuantity,
} from '../../../api/shop/cart-api';
import { addVoucher, removeVoucher } from '../../../api/shop/voucher-api';

const cartDefaultState = (runtimeModel: Partial<CartState> | undefined): CartState => ({
    cart: runtimeModel?.cart ?? null,
    reepayCheckout: runtimeModel?.reepayCheckout ?? null,
    shopCheckoutComplete: false,
    cartReceipt: null,
    voucherMessage: null,
    cartUrl: runtimeModel?.cartUrl ?? '/shop/kurv',
    checkoutUrl: runtimeModel?.checkoutUrl ?? '/shop/betaling',
});

const cartActions = (): CartActions => ({
    setCart: action((state, payload) => {
        state.cart = payload;
        state.shopCheckoutComplete = false;
    }),
    setReepayCheckout: action((state, payload) => {
        state.reepayCheckout = payload;
    }),
    resetShopStore: action((state) => {
        state.cart = null;
        state.reepayCheckout = null;
        state.shopCheckoutComplete = false;
    }),
    setShopCheckoutComplete: action((state, payload) => {
        state.shopCheckoutComplete = payload;
    }),
    setCartToCartReceipt: action((state) => {
        state.cartReceipt = state.cart;
        state.cart = null;
    }),
    stopReepayCheckout: action((state) => {
        state.reepayCheckout = null;
    }),
    setVoucherMessage: action((state, payload) => {
        state.voucherMessage = payload;
    }),
});

const cartThunks = (): CartThunks => ({
    addProductToCart: thunk(async (actions, payload) => {
        const [response, error] = await addProductToCart(payload.productSku, payload.cart ? payload.cart.id : null);
        if (response && response.id && !error) {
            actions.setCart(response);
        } else {
            return 'Error';
        }
    }),
    updateOrderlineQuantity: thunk(async (actions, payload) => {
        const [response, error] = await updateOrderlineQuantity(payload.productSku, payload.quantity, payload.cart.id);
        if (response && response.id && !error) {
            actions.setCart(response);
        }
    }),
    deleteOrderline: thunk(async (actions, payload) => {
        const [response, error] = await deleteOrderline(payload.productSku, payload.cart.id);
        if (response && response.id && !error) {
            actions.setCart(response);
        }
    }),
    addCustomerToCart: thunk(async (actions, payload) => {
        const customerObject = payload.cart.customer
            ? { ...payload.customerInfo, customerId: payload.cart.customer.customerId }
            : payload.customerInfo;
        const [response, error] = await addCustomerToCart(customerObject, payload.cart.id);
        if (response && response.id && !error) {
            actions.setCart(response);
        }
    }),
    startReepayCheckout: thunk(async (actions, payload) => {
        const [response, error] = await startCheckoutPayment({ basketId: payload.cart.id, consentDetails: payload.consent });
        if (response && !error) {
            actions.setReepayCheckout(response);
        }
    }),
    addVoucherToCart: thunk(async (actions, payload) => {
        const [response, error] = await addVoucher(payload.cart.id, payload.voucherCode);
        if (response && !error) {
            if (response.id) {
                actions.setCart(response);
                actions.setVoucherMessage({
                    success: true,
                    message: `Rabatkoden '${payload.voucherCode}' blev tilføjet til ordren`,
                });
            } else {
                actions.setVoucherMessage({
                    success: false,
                    message: `Rabatkoden '${payload.voucherCode}' kunne ikke valideres`,
                });
            }
        } else {
            actions.setVoucherMessage({
                success: false,
                message: 'Der skete en fejl',
            });
        }
    }),
    removeVoucherFromCart: thunk(async (actions, payload) => {
        actions.setVoucherMessage(null);
        const [response, error] = await removeVoucher(payload.cart.id, payload.voucherCode);
        if (response && !error) {
            if (response.id) {
                actions.setCart(response);
            }
        }
    }),
    setDealershipAsDelivery: thunk(async (actions, payload) => {
        const [response, error] = await setDealershipAsDelivery(payload.cart.id, payload.dealership);
        if (response && !error) {
            if (response.id) {
                actions.setCart(response);
            }
        }
    }),
    setParcelShopPickupAsDelivery: thunk(async (actions, payload) => {
        const [response, error] = await setParcelShopPickupAsDelivery(payload.cart.id, payload.parcelShop);
        if (response && !error) {
            if (response.id) {
                actions.setCart(response);
            }
        }
    }),
    setHomeDeliveryAsDelivery: thunk(async (actions, payload) => {
        const [response, error] = await setHomeDeliveryAsDelivery(payload.cart.id, payload.alternativeDeliveryAddress);
        if (response && !error) {
            if (response.id) {
                actions.setCart(response);
            }
        }
    }),
});

export const CartStoreContext = createContextStoreWithRuntimeModel<CartStore, Partial<CartState>>(
    (runtimeModel) =>
        persist(
            {
                ...cartDefaultState(runtimeModel),
                ...cartActions(),
                ...cartThunks(),
            },
            {
                storage: 'localStorage',
            }
        ),
    { name: 'CartStore' }
);
