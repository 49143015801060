import { FC } from 'react';
import { SvgIcon } from '../svg-icon';
import { IAgreementCard } from './agreement-card.props';
import { Wrapper, Header, BenefitList, BenefitItem, BenefitText } from './agreement-card.styled';

export const AgreementCard: FC<IAgreementCard> = ({ title, benefits = [] }) => {
    return (
        <Wrapper>
            <Header>{title}</Header>
            <BenefitList>
                {benefits.map((x, index) => {
                    return (
                        <BenefitItem key={`benefit-item-${index}`}>
                            <SvgIcon iconName="checkmark" />
                            <BenefitText>{x}</BenefitText>
                        </BenefitItem>
                    );
                })}
            </BenefitList>
        </Wrapper>
    );
};
