import { VFC } from 'react';
import { StyledPaymentPlanButton, StyledPaymentPlanButtonIcon, StyledPaymentPlanButtonLabel } from './payment-plan-button.styled';

export type PaymentPlanButtonProps = {
    className?: string;
    iconUrl: string;
    label: string;
    onClick?: () => void;
};

export const PaymentPlanButton: VFC<PaymentPlanButtonProps> = (props) => {
    return (
        <StyledPaymentPlanButton className={props.className} onClick={props.onClick}>
            <StyledPaymentPlanButtonIcon alt="" src={props.iconUrl} />
            <StyledPaymentPlanButtonLabel>{props.label}</StyledPaymentPlanButtonLabel>
        </StyledPaymentPlanButton>
    );
};
