import { validateEmail, validateNotEmpty, validateTel, validateName } from '../../../../../utils/helpers';

import { hireCheckoutTypes, hireInputTypes } from '../../../../api/models/umbraco';
import { storeInputTypes } from '../../../_shared';

export const generateHireFormId = (
    step: hireCheckoutTypes.HireStepType,
    type: hireInputTypes.HireUmbracoInputType,
    addressType?: 'address' | 'zip' | 'city'
): string => `${step}:${type}${addressType ? `:${addressType}` : ''}`;

const convertInputToHireFormInput =
    (step: hireCheckoutTypes.HireStepType) =>
    (input: hireInputTypes.HireUmbracoInput): storeInputTypes.FormInput<string> => {
        switch (input.type) {
            case 'Name':
                return {
                    id: generateHireFormId(step, input.type),
                    step,
                    isValid: false,
                    canValidate: false,
                    type: 'name',
                    label: input.label,
                    placeholder: input.placeholder,
                    value: '',
                    validationMessage: input.validationMessage,
                };
            case 'Email':
                return {
                    id: generateHireFormId(step, input.type),
                    step,
                    isValid: false,
                    canValidate: false,
                    type: 'email',
                    label: input.label,
                    placeholder: input.placeholder,
                    value: '',
                    validationMessage: input.validationMessage,
                };
            case 'Telephone number':
                return {
                    id: generateHireFormId(step, input.type),
                    step,
                    isValid: false,
                    canValidate: false,
                    type: 'tel',
                    label: input.label,
                    placeholder: input.placeholder,
                    value: '',
                    validationMessage: input.validationMessage,
                };

            case 'CVR (Business)':
                return {
                    id: generateHireFormId(step, input.type),
                    step,
                    isValid: false,
                    canValidate: false,
                    type: 'cvr (business)',
                    label: input.label,
                    placeholder: input.placeholder,
                    value: '',
                    validationMessage: input.validationMessage,
                };

            case 'Company (Business)':
                return {
                    id: generateHireFormId(step, input.type),
                    step,
                    isValid: false,
                    canValidate: false,
                    type: 'company (business)',
                    label: input.label,
                    placeholder: input.placeholder,
                    value: '',
                    validationMessage: input.validationMessage,
                };

            case 'Address':
                return {
                    id: generateHireFormId(step, input.type),
                    isValid: false,
                    canValidate: false,
                    step,
                    type: 'address',
                    inputs: [
                        {
                            multiformInputName: 'address',
                            id: generateHireFormId(step, input.type, 'address'),
                            step,
                            isValid: false,
                            canValidate: false,
                            type: 'text',
                            label: input.addressLabel,
                            placeholder: input.addressPlaceholder,
                            value: '',
                            validationMessage: input.addressValidationMessage,
                        },
                        {
                            multiformInputName: 'zipcode',
                            id: generateHireFormId(step, input.type, 'zip'),
                            step,
                            isValid: false,
                            canValidate: false,
                            type: 'text',
                            label: input.zipCodeLabel,
                            placeholder: input.zipCodePlaceholder,
                            value: '',
                            validationMessage: input.zipCodeValidationMessage,
                        },
                        {
                            multiformInputName: 'city',
                            id: generateHireFormId(step, input.type, 'city'),
                            step,
                            isValid: false,
                            canValidate: false,
                            type: 'text',
                            label: input.cityLabel,
                            placeholder: input.cityPlaceholder,
                            value: '',
                            validationMessage: input.cityValidationMessage,
                        },
                    ],
                };
        }
    };

export const convertHireCheckoutStepsToInputs = (step: hireCheckoutTypes.HireCheckoutStep): storeInputTypes.FormInput<string>[] => {
    switch (step.alias) {
        case 'checkoutStepUserInformation': {
            return step.inputList.map(convertInputToHireFormInput('checkoutStepUserInformation'));
        }
        default:
            return [];
    }
};

export const validateHireInput = (input: storeInputTypes.FormInput<string>): boolean => {
    switch (input.type) {
        case 'text':
        case 'company (business)':
            return validateNotEmpty(input.value);

        case 'email':
            return validateNotEmpty(input.value) && validateEmail(input.value);

        case 'tel':
            return validateNotEmpty(input.value) && validateTel(input.value);

        case 'number':
        case 'cvr (business)':
            return validateNotEmpty(input.value) && Number(input.value) > 0;

        case 'address':
            return input.inputs.reduce<boolean>(
                (acc, input) => validateHireInput(input) && (input.multiformInputName === 'zipcode' ? input.value.length === 4 : true) && acc,
                true
            );

        case 'name':
            return validateNotEmpty(input.value) && validateName(input.value);

        default:
            return true;
    }
};
