import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const StyledSubHeader = styled.h3`
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 27px;
`;

export const StyledSubmittedInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media ${device.tablet} {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        width: 100%;
    }
`;

export const StepQuestion = styled.p`
    color: #0b0b0b;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 27px;
`;
