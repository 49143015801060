import styled from 'styled-components';
import { Button } from '../../../../shared';
import { device } from '../../../../../lib/media-query';

export const StyledModalHeader = styled.p`
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0px;
    margin-bottom: 20px;
`;

export const StyledProductDescription = styled.p`
    font-size: 14px;
    line-height: 20px;
    margin-top: 15px;

    @media ${device.tablet} {
        line-height: 24px;
    }
`;

export const StyledButton = styled(Button)`
    font-weight: 400;
`;
