import { KeyboardEvent, useState } from 'react';
import { ReactDOMAttributes, useDrag, UserDragConfig } from '@use-gesture/react';

type CarouselControls = {
    activeIndex: number;
    bindGestures: () => ReactDOMAttributes;
    dragOffset: number;
    nextSlide: () => void;
    onKeyDown: (event: KeyboardEvent) => void;
    previousSlide: () => void;
    setActiveIndex: (activeIndex: number) => void;
};

const gestureOptions: UserDragConfig = {
    axis: 'x',
    preventDefault: true,
};

export const useCarouselControls = (numberOfSlides: number, initialIndex = 0): CarouselControls => {
    const [activeIndex, setActiveIndex] = useState(initialIndex);
    const [dragOffset, setDragOffset] = useState(0);

    const onKeyDown = (event: KeyboardEvent) => {
        switch (event.key) {
            case 'ArrowLeft':
                previousSlide();
                event.preventDefault();
                return;
            case 'ArrowRight':
                nextSlide();
                event.preventDefault();
                return;
            default:
                return;
        }
    };

    const bindGestures = useDrag(({ direction: [direction], down, movement: [movement], velocity: [velocity] }) => {
        requestAnimationFrame(() => setDragOffset(down ? movement : 0));
        if (down || velocity < 0.1) return;
        direction < 0 ? nextSlide() : previousSlide();
    }, gestureOptions);

    const nextSlide = () => {
        const nextIndex = activeIndex + 1 >= numberOfSlides ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previousSlide = () => {
        const previousIndex = activeIndex <= 0 ? numberOfSlides - 1 : activeIndex - 1;
        setActiveIndex(previousIndex);
    };

    return {
        activeIndex,
        bindGestures,
        dragOffset,
        nextSlide,
        onKeyDown,
        previousSlide,
        setActiveIndex,
    };
};
