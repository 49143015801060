import styled from 'styled-components';
import { device } from '../../../lib/media-query';
import { SpotHeader } from '../../shared/typography/spot-header/spot-header.component';

export const BorderBox = styled.div`
    background-color: #f7f7f8;
    padding: 30px 0;

    @media ${device.tablet} {
        padding: 64px 0;
    }
`;

export const DisplayContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const Header = styled.h2`
    color: #0b0b0b;
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    font-family: 'Corporate E';
    line-height: 30px;
`;

export const CenteredHeader = styled.h2`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 35px;
    align-self: center;
    text-align: center;
`;

export const StyledCenteredHeaderSpot = styled(SpotHeader)`
    align-self: center;
    text-align: center;
`;

export const Footnotes = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const FootnoteText = styled.p`
    color: #807f80;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 12px;

    display: revert;
    @media ${device.tablet} {
        display: none;
    }
`;
