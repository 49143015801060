import { device } from '../../../lib/media-query';
import styled from 'styled-components';

export const StepWrapper = styled.section<{ isCurrentStep: boolean; isCompleted: boolean }>`
    min-width: 315px;
    background-color: ${(props) => (props.isCompleted ? props.theme.palette.background.default : props.theme.palette.common.white)};
    padding: 0px 15px;
    padding-bottom: ${(props) => (props.isCurrentStep || props.isCompleted ? '10px' : '0')};
    box-shadow: ${(props) => (props.isCompleted ? 'none' : props.theme.shadows.small)};

    border-top: 1px solid ${(props) => props.theme.palette.grey.medium};
    border-bottom: 1px solid ${(props) => props.theme.palette.grey.medium};

    @media ${device.tablet} {
        background-color: ${(props) => props.theme.palette.common.white};
        box-shadow: ${({ theme }) => theme.shadows.small};
        border-top: none;
        border-bottom: none;
    }

    pointer-events: ${(props) => (props.isCurrentStep ? 'revert' : 'none')};
`;

export const StepHeader = styled.div<{ isCurrentStep: boolean; isCompleted: boolean }>`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 20px 0;
    color: ${(props) => (props.isCurrentStep ? 'inherit' : '#b5b5b5')};

    @media ${device.tablet} {
        border-bottom: ${(props) => (props.isCurrentStep || props.isCompleted ? '1px' : '0')} solid ${({ theme }) => theme.palette.grey.medium};
    }
`;

export const Header = styled.h2`
    margin-left: 15px;
    color: inherit;
    font-weight: bold;
    font-family: 'Corporate E';
`;

export const IconAndText = styled.div`
    display: flex;
    align-items: center;
`;

export const CircleText = styled.span<{ isCurrentStep: boolean }>`
    opacity: ${(props) => (props.isCurrentStep ? '1' : '0.4')};
`;

export const StepContent = styled.div`
    position: relative;
    padding-left: 5px;
    padding-right: 5px;

    @media ${device.tablet} {
        padding-left: 50px;
        padding-right: 50px;
    }
`;

export const StepAction = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
`;

export const StyledFootNote = styled.p`
    text-align: center;
    padding: 1rem;
    font-size: 10px;
    @media ${device.tablet} {
        padding: 1rem 5rem;
    }
`;

export const ChildrenWrapper = styled.div`
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    @media ${device.tablet} {
        padding-top: 40px;
    }
`;

export const PointerEvents = styled.span`
    pointer-events: all;
`;

export const StyledCheckMarkWrapper = styled.div`
    width: max-content;
`;
