import useSWR from 'swr';
import { getPageByDataTypeAlias, umbraco } from '../lib/api';

type UseCmsPageReturnType = {
    url: string | undefined;
};

const fetcher = async (alias: string) => {
    const [fetchedPage, error] = await getPageByDataTypeAlias(alias);

    if (fetchedPage && fetchedPage.length > 0 && !error) {
        return fetchedPage[0];
    }

    return undefined;
};

export const useCmsPage = (pageAlias: string): UseCmsPageReturnType => {
    const { data: page } = useSWR<umbraco.Page | undefined>(pageAlias, fetcher, {
        revalidateOnFocus: false,
        revalidateIfStale: false,
    });

    return {
        url: page ? page.url : undefined,
    };
};
