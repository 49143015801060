import styled, { css } from 'styled-components';
import { device } from '../../../lib/media-query';
import { SpotHeader } from '../../shared/typography/spot-header/spot-header.component';
import { isSiteName, SITE_NAME } from '../../../utils/helpers/site-id.helper';

export const StyledWrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 0;

    @media ${device.tablet} {
        padding: 64px 0;
    }

    ${isSiteName(SITE_NAME.CARSAVER) &&
    css`
        padding: 32px 0;
        @media ${device.tablet} {
            padding: 70px 0;
        }
    `}
`;

export const Header = styled.h2`
    color: #0b0b0b;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 35px;
    margin-bottom: 40px;
    font-family: 'Corporate E';
`;

export const StyledRichFaqSpotHeader = styled(SpotHeader)`
    margin-bottom: 40px;
`;

export const QuestionsWrapper = styled.div`
    width: 100%;
`;

export const RichFaqWrapper = styled.div`
    width: 100%;
`;

export const StyledRichContent = styled.div`
    padding: 10px 0;
    overflow: hidden;
    & img {
        width: auto;
        max-width: 320px;
        object-fit: cover;

        @media ${device.tablet} {
            max-width: 500px;
        }
    }

    & ul,
    & ol {
        list-style: revert;
        padding: revert;
        margin: revert;
    }

    @media ${device.tablet} {
        overflow: revert;
    }
`;
