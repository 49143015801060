import styled from 'styled-components';

export const CampaignListItem = styled.div<{ bgColor: string; color: string }>`
    background-color: ${({ bgColor }) => bgColor};
    color: ${({ color }) => color};
    cursor: default;
    font-weight: normal;
    font-family: ${(props) => props.theme.fontFamily.secondary};
    line-height: 1.6em;
    font-size: 10px;
    padding: 0 0.4em;
    white-space: nowrap;
`;
