import styled from 'styled-components';

export const StyledDescription = styled.p`
    margin-top: 15px;
`;

export const StyledPaymentIconsWrapper = styled.div`
    margin: 20px 0;
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 20px;
    border: 1px solid #e2e2e4;
`;

export const StyledConsentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
`;
