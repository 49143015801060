import { FC, useMemo } from 'react';
import {
    StyledActions,
    StyledInstallationOptionWrapper,
    StyledOfferPrice,
    StyledOrderlineContainer,
    StyledOrderlineFlexContainer,
    StyledOrderlineImage,
    StyledOrderlineInfoAndActions,
    StyledOrderlineLink,
    StyledOrderlineName,
    StyledPrice,
    StyledPricesWrapper,
    StyledPromotionBadges,
    StyledQuantityPicker,
    StyledRemoveButton,
    StyledSize,
    StyledUnitPrice,
} from './orderline.styled';
import { getImageCropUrl, getShopPrice } from '../../../../utils/helpers';
import { CartOrderline } from '../../../../lib/api/models/shop/cart';
import { CartStoreContext } from '../../../../lib/state/shop/cart/cart.store';
import Link from 'next/link';
import { ProductInstallationCheckbox } from '../../product-installation-checkbox/product-installation-checkbox.component';
import { useGtmTracking } from '../../../../gtm-tracking/hooks/use-gtm-tracking';

type IProps = {
    orderline: CartOrderline;
    compressedView?: boolean;
};

export const Orderline: FC<IProps> = ({ orderline, compressedView = false }) => {
    const { deleteOrderline, updateOrderlineQuantity } = CartStoreContext.useStoreActions((actions) => actions);
    const { cart, cartReceipt, shopCheckoutComplete } = CartStoreContext.useStoreState((state) => state);
    const cartToShow = shopCheckoutComplete ? cartReceipt : cart;

    const { trackShopEvents } = useGtmTracking();
    const tracker = trackShopEvents();

    const quantityOptions = useMemo(() => {
        return [
            {
                displayValue: '1',
                value: '1',
            },
            {
                displayValue: '2',
                value: '2',
            },
            {
                displayValue: '3',
                value: '3',
            },
            {
                displayValue: '4',
                value: '4',
            },
            {
                displayValue: '5',
                value: '5',
            },
            {
                displayValue: '6',
                value: '6',
            },
            {
                displayValue: '7',
                value: '7',
            },
            {
                displayValue: '8',
                value: '8',
            },
            {
                displayValue: '9',
                value: '9',
            },
        ];
    }, []);

    const productOnOffer = useMemo(() => {
        return orderline.price < orderline.originalPrice;
    }, [orderline.originalPrice, orderline.price]);

    const hasInstallation = false; //TODO INSTALLATION

    if (!cartToShow) return null;

    return (
        <StyledOrderlineContainer>
            <StyledOrderlineFlexContainer>
                <StyledOrderlineImage compressed={compressedView}>
                    <Link href={orderline.productUrl ?? ''} passHref={true}>
                        <StyledOrderlineLink>
                            <img
                                src={getImageCropUrl(orderline.imageUrl, 'productlist')}
                                alt={orderline.productName}
                                style={{ mixBlendMode: 'multiply' }}
                            />
                        </StyledOrderlineLink>
                    </Link>
                </StyledOrderlineImage>
                <div style={{ flexGrow: '1' }}>
                    <StyledOrderlineInfoAndActions>
                        <div style={{ flexGrow: '1' }}>
                            {orderline.promotionIds && orderline.promotionIds.length > 0 ? (
                                <StyledPromotionBadges promotions={orderline.promotionIds} />
                            ) : null}
                            <Link href={orderline.productUrl ?? ''} passHref={true}>
                                <StyledOrderlineLink>
                                    <StyledOrderlineName compressed={compressedView}>{orderline.productName}</StyledOrderlineName>
                                </StyledOrderlineLink>
                            </Link>
                            {orderline.size && orderline.size.length > 0 ? <StyledSize>Størrelse: {orderline.size}</StyledSize> : null}
                            {orderline.color ? <StyledSize>Farve: {orderline.color}</StyledSize> : null}
                            {compressedView ? <StyledSize>Antal: {orderline.quantity}</StyledSize> : null}
                            <StyledPricesWrapper>
                                {productOnOffer ? (
                                    <>
                                        <StyledOfferPrice>{getShopPrice(orderline.price)}/stk.</StyledOfferPrice>
                                        <StyledUnitPrice beforePrice={true}>{getShopPrice(orderline.originalPrice)}/stk.</StyledUnitPrice>
                                    </>
                                ) : (
                                    <StyledUnitPrice beforePrice={false}>{getShopPrice(orderline.price)}/stk.</StyledUnitPrice>
                                )}
                            </StyledPricesWrapper>
                            {!compressedView ? (
                                <StyledActions>
                                    <StyledQuantityPicker
                                        canValidateInputField={false}
                                        id={orderline.id}
                                        isValid={true}
                                        label={'Antal'}
                                        onChange={(value) => {
                                            if (cart) {
                                                const newQuantity = Number(value?.value);
                                                const amountAdded = newQuantity - orderline.quantity;
                                                if (amountAdded > 0) {
                                                    tracker.addToCart(
                                                        {
                                                            brands: [],
                                                            categories: [],
                                                            id: orderline.productId,
                                                            name: orderline.productName,
                                                            price: orderline.price,
                                                            color: orderline.color,
                                                        },
                                                        amountAdded
                                                    );
                                                }
                                                updateOrderlineQuantity({
                                                    productSku: orderline.productSku,
                                                    cart,
                                                    quantity: newQuantity,
                                                });
                                            }
                                        }}
                                        options={quantityOptions}
                                        value={{
                                            value: orderline.quantity.toString(),
                                            displayValue: orderline.quantity.toString(),
                                        }}
                                    ></StyledQuantityPicker>
                                    <StyledRemoveButton
                                        onClick={() => {
                                            if (cart) deleteOrderline({ cart, productSku: orderline.productSku });
                                        }}
                                    >
                                        Fjern
                                    </StyledRemoveButton>
                                </StyledActions>
                            ) : null}
                        </div>
                        <div>
                            <StyledPrice compressed={compressedView}>{getShopPrice(orderline.totalPrice)}</StyledPrice>
                            {productOnOffer && (
                                <StyledUnitPrice beforePrice={true}>{getShopPrice(orderline.totalOriginalPrice ?? 0)}</StyledUnitPrice>
                            )}
                        </div>
                    </StyledOrderlineInfoAndActions>
                    {hasInstallation && !compressedView && (
                        <StyledInstallationOptionWrapper>
                            <ProductInstallationCheckbox installationRequirement={'Valgfri montering'} productId={orderline.id} />
                        </StyledInstallationOptionWrapper>
                    )}
                </div>
            </StyledOrderlineFlexContainer>
        </StyledOrderlineContainer>
    );
};
