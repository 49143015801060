import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const StyledSizeGuideBg = styled.div<{ isPdpPlugin: boolean }>`
    background-color: #f7f7f8;
    ${(props) => (props.isPdpPlugin ? 'margin: var(--pdp-margin, 12px) 0; padding: 40px 0' : 'padding: 40px 0')};
`;

export const StyledSizeGuideGrid = styled.div`
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    @media ${device.laptop} {
        display: grid;
        grid-template-columns: 3fr 2fr;
        align-items: flex-end;
    }
`;

export const StyledSizeGuideHeader = styled.h3`
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    line-height: 24px;
`;

export const StyledSizeGuideDescription = styled.p`
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    max-width: 575px;
`;

export const StyledSizeGuideContentWrapper = styled.div`
    background-color: white;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
    margin-top: 27px;
    padding: 5px;

    @media ${device.laptop} {
        display: grid;
        grid-template-columns: 3fr 2fr;
    }
`;

export const StyledMediaArea = styled.div`
    width: 100%;
    aspect-ratio: 1.69 / 1;
    background-color: rgb(247, 247, 248);

    figure {
        display: block !important;
    }
`;

export const StyledSliderControls = styled.div`
    position: absolute;
    height: 30px;
    width: 300px;
    background-color: green;
    bottom: 30px;
    right: 30px;
`;

export const StyledSpecificationsArea = styled.div`
    padding: 35px 25px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media ${device.laptop} {
        gap: 5px;
        padding: 10px 20px;
    }
    @media ${device.laptopS} {
        gap: 18px;
        padding: 25px 35px 15px;
    }
`;

export const StyledSpecificationsHeader = styled.h4`
    font-family: 'Corporate E';
    font-weight: bold;
    font-size: 25px;
    line-height: 35px;
    padding-bottom: 10px;
`;

export const StyledSpecificationWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 30px;
`;

export const StyledSpecificationLabel = styled.p`
    font-weight: 400;
`;
export const StyledSpecificationValue = styled.p`
    font-weight: 600;
`;

export const StyledSpecificationsDisclaimer = styled.p`
    order: -1;
    font-weight: 500;
    opacity: 0.5;
    font-size: 12px;
    margin-top: auto;
    @media ${device.laptop} {
        order: 0;
    }
`;
