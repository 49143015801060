import { reepayTypes } from '../models';
import { getAPI, postAPI } from '../helpers';
import { APIResponse } from '../types';
import { CreateSessionRequest, SessionRequestResponse } from '../models/reepay';
import { ReepayApiVersion } from '../../../constants/api-const';

const REEPAY_BASE_API_URL = `api/${ReepayApiVersion}`;

export const validateReepayVoucher = async (params: reepayTypes.ValidateVoucherParams): APIResponse<reepayTypes.ValidateVoucherResponse> => {
    return getAPI<reepayTypes.ValidateVoucherResponse>(`${REEPAY_BASE_API_URL}/payment/voucher/validate`, params);
};

export const getPlans = async (params: reepayTypes.ReepayPlanRequestParams): APIResponse<reepayTypes.ReepayPlanResponse> => {
    const queryObject: any = {}; //Cannot definitely type this object, because we do not know the properties beforehand
    params.plans.forEach((element, idx) => {
        queryObject[`plans[${idx}].key`] = element.key;
        queryObject[`plans[${idx}].value`] = element.value;
    });

    return getAPI('/api/v1/payment/plans', queryObject);
};

export const createSubscriptionSession = async (params: CreateSessionRequest): APIResponse<SessionRequestResponse> => {
    return postAPI(`${REEPAY_BASE_API_URL}/payment/session`, params);
};
