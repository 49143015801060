import { FC, KeyboardEvent, MouseEvent } from 'react';
import { StyledIconButton } from './icon-button.styled';

export type IconButtonProps = {
    className?: string;
    label: string;
    onClick?: (event: KeyboardEvent<HTMLButtonElement> | MouseEvent<HTMLButtonElement>) => void;
    size?: number;
};

export const IconButton: FC<IconButtonProps> = (props) => {
    return (
        <StyledIconButton aria-label={props.label} className={props.className} onClick={props.onClick} size={props.size}>
            <div aria-hidden="true">{props.children}</div>
        </StyledIconButton>
    );
};
