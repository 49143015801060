import { FC, useMemo, useState } from 'react';
import { FitsBrandWithModels } from '../../../../../lib/api/models/shop';
import { ReadMoreWrapper } from '../../../../shared/read-more/read-more-wrapper.component';
import { StyledFitsModelsWrapper, StyledFitsModelHeader, StyledFitsModelText, StyledSeeAllFitsToggle } from './fits-model.styled';

type IProps = {
    fits: FitsBrandWithModels[];
};

type FlattenedFit = {
    key: string;
    name: string;
};

export const FitsModel: FC<IProps> = ({ fits }) => {
    const [showAllFits, setShowAllFits] = useState(false);

    const allFitsFlattened = useMemo(() => {
        const allResults: FlattenedFit[] = [];
        fits.forEach((brand, brandIdx) => {
            brand.models.forEach((model, modelIdx) => {
                model.bodyTypes.forEach((bodyType, bodyIdx) => {
                    bodyType.years.forEach((year, yearIdx) => {
                        allResults.push({
                            key: `${brandIdx}-${modelIdx}-${bodyIdx}-${yearIdx}`,
                            name: `${model.modelName}, ${bodyType.bodyTypeName}, ${year}`,
                        });
                    });
                });
            });
        });
        return allResults;
    }, [fits]);

    return fits.length > 0 ? (
        <StyledFitsModelsWrapper>
            <StyledFitsModelHeader>Passer til:</StyledFitsModelHeader>
            {allFitsFlattened
                .filter((_, idx) => idx < 4)
                .map((fit) => {
                    return <StyledFitsModelText key={fit.key}>{fit.name}</StyledFitsModelText>;
                })}
            {allFitsFlattened.length > 4 ? (
                <ReadMoreWrapper
                    labelContent={<StyledSeeAllFitsToggle>{`Vis ${showAllFits ? 'færre' : 'flere'}`}</StyledSeeAllFitsToggle>}
                    echoNewState={(newState) => setShowAllFits(newState)}
                >
                    {allFitsFlattened
                        .filter((_, idx) => idx > 3)
                        .map((fit) => {
                            return <StyledFitsModelText key={fit.key}>{fit.name}</StyledFitsModelText>;
                        })}
                </ReadMoreWrapper>
            ) : null}
        </StyledFitsModelsWrapper>
    ) : null;
};
