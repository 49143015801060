import React, { VFC } from 'react';
import { FormsTrackingStrategy } from '../../../../lib/api/models/umbraco';
import { FormsSpot } from '../../../../lib/api/models/umbraco/content-spot';
import { sanitizeMarkup } from '../../../../utils/helpers';
import { SidePanel } from '../../../shared/modals/side-panel';
import { SidePanelLayout } from '../../../shared/modals/side-panel-layout/side-panel-layout.component';
import { EnquireAboutModalBody, HeaderSection, SubHeader, Header } from './enquire-about-car-modal.styled';

export type EnquireAboutCarModalProps = {
    form: FormsSpot;
    trackingStrategy?: FormsTrackingStrategy;
    visible: boolean;
    onClose: () => void;
    pageId: string;
    vehicleId: string;
};

const EnquireAboutModalMemo: VFC<EnquireAboutCarModalProps> = ({ form, visible, onClose, pageId, vehicleId }) => {
    return (
        <SidePanel cancelAction={onClose} isVisible={visible}>
            <SidePanelLayout closeSidePanel={onClose}>
                <HeaderSection>
                    <Header>{form.formHeader}</Header>
                    <SubHeader {...sanitizeMarkup(form.formSubtext)} />
                </HeaderSection>
                <EnquireAboutModalBody
                    form={form.form}
                    pageId={pageId}
                    trackingStrategy={form.trackingStrategy?.[0]}
                    specialProps={{
                        enquiryCarId: vehicleId,
                    }}
                />
            </SidePanelLayout>
        </SidePanel>
    );
};

export const EnquireAboutCarModal = React.memo(EnquireAboutModalMemo);
