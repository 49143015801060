import { FC, useState } from 'react';
import { VehicleProductCardCarousel } from '../../vehicle/vehicle-product-card-carousel/vehicle-product-card-carousel.component';
import { TestimonialCard } from './testimonial-card.component';
import {
    CtaHandlerWrapper,
    SidePanelHeader,
    SidePanelQuote,
    SidePanelTestimonialContainer,
    SidePanelText,
    StyledTestimonialRibbon,
} from './testimonial.styled';
import { TestimonialSpot } from '../../../lib/api/models/umbraco/content-spot';
import { SidePanel } from '../../shared/modals/side-panel';
import { TestimonialCustomer } from './testimonial.types';
import { SidePanelLayout } from '../../shared/modals/side-panel-layout/side-panel-layout.component';
import { CtaHandler } from '../../shared/cta-handler';
import { Button } from '../../shared';
import { useUI } from '../../ui';

type Props = {
    content: TestimonialSpot;
};

export const Testimonial: FC<Props> = ({
    content: { customers, header, bodyText, cta, scrollAnchorId, headerTextAlignment, bodyTextAlignment, headerSize, headerType },
}) => {
    const [openSidePanel, setOpenSidePanel] = useState(false);
    const [currentCustomer, setCurrentCustomer] = useState<TestimonialCustomer>();
    const ui = useUI();

    const closeSidePanel = () => {
        ui.applyScroll();
        setOpenSidePanel(false);
    };

    return (
        <>
            <StyledTestimonialRibbon id={scrollAnchorId}>
                <VehicleProductCardCarousel
                    header={header}
                    text={bodyText}
                    headerSize={headerSize}
                    headerType={headerType}
                    headerAlignment={headerTextAlignment}
                    descriptionAlignment={bodyTextAlignment}
                >
                    {customers.map((customer, index) => (
                        <TestimonialCard
                            key={index}
                            customer={customer}
                            setCustomer={setCurrentCustomer}
                            openSidePanel={() => {
                                setOpenSidePanel(true);
                                ui.removeScroll();
                            }}
                        />
                    ))}
                </VehicleProductCardCarousel>
                <CtaHandlerWrapper>
                    <CtaHandler ctas={cta} />
                </CtaHandlerWrapper>
            </StyledTestimonialRibbon>

            <SidePanel isVisible={openSidePanel} cancelAction={closeSidePanel}>
                <SidePanelLayout closeSidePanel={closeSidePanel}>
                    <SidePanelTestimonialContainer>
                        <SidePanelHeader>{currentCustomer?.customerName}</SidePanelHeader>
                        <SidePanelQuote>{currentCustomer?.quote}</SidePanelQuote>
                        <SidePanelText>{currentCustomer?.text}</SidePanelText>
                        <Button alignSelf="center" onClick={closeSidePanel} variant="primary">
                            Luk
                        </Button>
                    </SidePanelTestimonialContainer>
                </SidePanelLayout>
            </SidePanel>
        </>
    );
};
