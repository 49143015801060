import { CSSProperties, FC, useMemo } from 'react';
import { contentSpotTypes } from '../../../lib/api';
import { sharedTypes } from '../../../lib/api/models/umbraco';
import { TextAlignment } from '../../../lib/api/models/umbraco/content-spot/content-spot.types';
import { MEDIA_URL } from '../../../utils/environment-constants';
import { getYoutubeIframeUrl } from '../../../utils/helpers/youtube.helpers';
import { CtaHandler } from '../../plus-sites-shared/content-handlers';
import { useCtaHandler } from '../../plus-sites-shared/hooks';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';
import {
    StyledContentBoxCtaWrapper,
    StyledContentBoxImage,
    StyledContentBoxSubtitle,
    StyledContentBoxTitle,
    StyledDynamicContentBoxContainer,
    StyledDynamicContentBoxGrid,
    StyledDynamicContentBoxHeader,
    StyledDynamicSpotTitle,
    StyledIframeContainer,
    StyledSpotHeader,
    StyledVideoContainer,
} from './dynamic-content-boxes.styled';
import { ContentSpotRichText } from '../../shared/content-spot-rich-text/content-spot-rich-text.component';
import { DynamicContentBoxesScriptLoader } from './dynamic-content-boxes-script-loader';
import { isNullOrEmpty } from '../../../utils/helpers';

type IDynamicContentBoxes = {
    content: contentSpotTypes.DynamicContentBoxes;
};

export const DynamicContentBoxes: FC<IDynamicContentBoxes> = ({ content }) => {
    const templateColumns = useMemo(() => {
        if (content.contentBoxWidth === '25%') {
            return 4;
        } else if (content.contentBoxWidth === '33%') {
            return 3;
        } else if (content.contentBoxWidth === '50%') {
            return 2;
        } else {
            return 1;
        }
    }, [content.contentBoxWidth]);

    const { handleCtaClick } = useCtaHandler();

    const getTextAlignment = (alignment: TextAlignment): CSSProperties => {
        if (alignment === 'Right') {
            return { textAlign: 'right', justifyContent: 'flex-end' };
        } else if (alignment === 'Center') {
            return { textAlign: 'center', justifyContent: 'center' };
        } else {
            return { textAlign: 'left', justifyContent: 'flex-start' };
        }
    };

    const getMediaUrl = (url: string) => {
        return url.startsWith('https://') ? url : `${MEDIA_URL}/${url}`;
    };

    return (
        <StyledDynamicContentBoxContainer id={content.scrollAnchorId} backgroundColor={content.backgroundColor} fontColor={content.fontColor}>
            <CenteredBlock>
                {content.header.length > 0 ? (
                    isNullOrEmpty(content.headerSize) ? (
                        <StyledDynamicContentBoxHeader
                            as={content.headerType}
                            style={{
                                ...getTextAlignment(content.headerTextAlignment),
                                marginBottom: content.bodyText.length > 0 ? '10px' : '25px',
                                color: `#${content.fontColor}`,
                            }}
                        >
                            {content.header}
                        </StyledDynamicContentBoxHeader>
                    ) : (
                        <StyledSpotHeader
                            headerType={content.headerType}
                            headerSize={content.headerSize}
                            style={{
                                ...getTextAlignment(content.headerTextAlignment),
                                marginBottom: content.bodyText.length > 0 ? '10px' : '25px',
                                color: `#${content.fontColor}`,
                            }}
                        >
                            {content.header}
                        </StyledSpotHeader>
                    )
                ) : null}
                {content.bodyText.length > 0 ? (
                    <ContentSpotRichText style={getTextAlignment(content.bodyTextTextAlignment)} text={content.bodyText} />
                ) : null}
                <StyledDynamicContentBoxGrid
                    columns={templateColumns}
                    divisible={templateColumns % 2 === 0}
                    singleBoxJustifiedCenter={content.contentBoxWidth === '50% and centered'}
                >
                    {content.contentBoxes.map((x, idx) => {
                        const video = x.mediaFile as sharedTypes.Video;
                        return (
                            <div key={idx}>
                                {x.mediaFile && (x.mediaFile.type === 'image' || x.mediaFile.extension?.toLowerCase() === 'webp') ? (
                                    <StyledContentBoxImage
                                        useSquaredCorners={x.useSquaredCorners}
                                        src={getMediaUrl(x.mediaFile.src)}
                                        alt={x.title}
                                        onClick={x.cta.length > 0 ? () => handleCtaClick(x.cta[0]) : undefined}
                                        clickable={x.cta.length > 0}
                                        loading={content.isAboveFold ? 'eager' : 'lazy'}
                                    />
                                ) : null}
                                {x.mediaFile &&
                                x.mediaFile.type === 'file' &&
                                x.mediaFile.extension?.toLowerCase() !== 'webp' &&
                                video &&
                                video.src.length > 0 ? (
                                    <StyledVideoContainer useSquaredCorners={x.useSquaredCorners}>
                                        <video
                                            height="100%"
                                            width="100%"
                                            controls
                                            poster={x.videoThumbnail?.src ? getMediaUrl(x.videoThumbnail?.src) : undefined}
                                            src={getMediaUrl(video.src)}
                                        ></video>
                                    </StyledVideoContainer>
                                ) : null}
                                {x.youtubeVideo.length > 0 && getYoutubeIframeUrl(x.youtubeVideo) !== '' ? (
                                    <StyledIframeContainer useSquaredCorners={x.useSquaredCorners}>
                                        <iframe
                                            title={x.title}
                                            width="100%"
                                            height="100%"
                                            src={`${getYoutubeIframeUrl(x.youtubeVideo)}`}
                                            allowFullScreen
                                        />
                                    </StyledIframeContainer>
                                ) : null}
                                {x.title.length > 0 ? (
                                    isNullOrEmpty(x.titleSize) ? (
                                        <StyledContentBoxTitle style={getTextAlignment(x.titleTextAlignment)} as={x.titleType}>
                                            {x.title}
                                        </StyledContentBoxTitle>
                                    ) : (
                                        <StyledDynamicSpotTitle
                                            headerType={x.titleType}
                                            headerSize={x.titleSize}
                                            style={getTextAlignment(x.titleTextAlignment)}
                                        >
                                            {x.title}
                                        </StyledDynamicSpotTitle>
                                    )
                                ) : null}
                                {x.subtitle.length > 0 ? (
                                    <StyledContentBoxSubtitle style={getTextAlignment(x.subtitleTextAlignment)}>
                                        {x.subtitle}
                                    </StyledContentBoxSubtitle>
                                ) : null}
                                {x.bodyText.length > 0 ? (
                                    <ContentSpotRichText
                                        style={{ ...getTextAlignment(x.bodyTextTextAlignment), marginTop: '5px' }}
                                        text={x.bodyText}
                                    ></ContentSpotRichText>
                                ) : null}
                                {x.cta.length > 0 ? (
                                    <StyledContentBoxCtaWrapper
                                        style={getTextAlignment(x.cTAAlignment)}
                                        alignment={x.cTAAlignment === 'Right' ? 'flex-end' : x.cTAAlignment === 'Center' ? 'center' : 'flex-start'}
                                    >
                                        <CtaHandler ctas={x.cta} />
                                    </StyledContentBoxCtaWrapper>
                                ) : null}
                                {x.script.length > 0 ? <DynamicContentBoxesScriptLoader scriptString={x.script} /> : null}
                                {x.iframeUrl != null ? (
                                    <iframe
                                        width="100%"
                                        title={x.iframeUrl.name}
                                        height={x.iframeHeight}
                                        frameBorder="no"
                                        src={x.iframeUrl.url}
                                    ></iframe>
                                ) : null}
                            </div>
                        );
                    })}
                </StyledDynamicContentBoxGrid>
            </CenteredBlock>
        </StyledDynamicContentBoxContainer>
    );
};
