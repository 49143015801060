import { action } from 'easy-peasy';
import { cities_and_geo_location } from '../../../components/organization/cities';
import {
    DEFAULT_MAP_POSITION,
    DEFAULT_MAP_POSITION_Mobile,
    MAP_NO_LOCATION_ZOOM_LEVEL,
    MAP_ZOOM_LEVEL,
} from '../../../constants/organization-consts';
import { isNumber } from '../../../utils/helpers';
import { sortGeoLocations } from '../../../utils/helpers/geo-location.helpers';
import { orgTypes } from '../../api/models/umbraco';
import { createContextStoreWithRuntimeModel } from '../helpers/context-store.helpers';
import {
    FindDealershipsStoreActions,
    FindDealershipsStoreInjections,
    FindDealershipsStoreModel,
    FindDealershipsStoreState,
} from './find-dealership.types';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { MEDIA_URL } from '../../../utils/environment-constants';

function handleBrandChange(locations: Array<orgTypes.DealershipWithGeoInfo>, allConfig: orgTypes.DealershipConfig): orgTypes.DealershipConfig {
    const availableDepartments: Array<orgTypes.DealershipDepartment> = [];

    // Go through filtered locations and find available departments
    for (let index = 0; index < locations.length; index++) {
        const loc = locations[index];

        loc.departments.forEach((dep) => {
            if (!availableDepartments.some((x) => x.departmentType === dep.departmentType)) {
                availableDepartments.push(dep);
            }
        });
    }

    // Disable the ones that don't match
    allConfig.departments.forEach((x) => {
        if (!availableDepartments.some((dl) => dl.departmentType === x.departmentType)) {
            x.disabled = true;
        }
    });

    return allConfig;
}

function handleDepartmentChange(locations: Array<orgTypes.DealershipWithGeoInfo>, allConfig: orgTypes.DealershipConfig): orgTypes.DealershipConfig {
    const availableBrands: Array<orgTypes.SupportedBrand> = [];

    // Go through filtered locations and find available brands
    for (let index = 0; index < locations.length; index++) {
        const loc = locations[index];

        loc.supportedBrands.forEach((sb) => {
            if (!availableBrands.some((x) => x.brand === sb.brand)) {
                availableBrands.push(sb);
            }
        });
    }

    // Disable the ones that don't match
    allConfig.brands.forEach((x) => {
        if (!availableBrands.some((bl) => bl.brand === x.brand)) {
            x.disabled = true;
        }
    });

    return allConfig;
}

const findDealershipsDefaultState = (runtimeModel: Partial<FindDealershipsStoreState> | undefined): FindDealershipsStoreState => ({
    dealershipId: undefined,
    locations: runtimeModel?.locations ?? [],
    filteredLocations: runtimeModel?.locations ?? [],
    config: runtimeModel?.config ?? { brands: [], departments: [] },
    emergencyPhoneNumbers: runtimeModel?.emergencyPhoneNumbers ?? [],
    dealershipAndTruckNumbers: runtimeModel?.dealershipAndTruckNumbers ?? [],
    mapData: [],
    showMobileInfoBox: runtimeModel?.renderGoogleMaps ? false : true,
    freeTextFilter: '',
    showEmergencyNumbersSidePanel: false,
    filterStatus: 'default',
    markerClusterer: undefined,
    renderGoogleMaps: runtimeModel?.renderGoogleMaps ?? false,
    staticImageUrl:
        runtimeModel?.staticImageUrl && runtimeModel.staticImageUrl.length > 0
            ? MEDIA_URL + runtimeModel.staticImageUrl
            : '/fallback-images/dealership.jpg',
});

const findDealershipsActions = (): FindDealershipsStoreActions => ({
    setDealershipId: action((state, payload) => {
        state.dealershipId = payload;
    }),
    setMapData: action((state, payload) => {
        state.mapData = payload;
    }),
    toggleMobileInfoBoxVisibility: action((state) => {
        state.showMobileInfoBox = !state.showMobileInfoBox;
    }),
    setShowMobileInfoBox: action((state, payload) => {
        state.showMobileInfoBox = payload;
    }),
    setFreeTextFilter: action((state, payload) => {
        state.freeTextFilter = payload;
    }),
    applyFilters: action((state) => {
        state.filterStatus = 'default';
        if (state.freeTextFilter && state.freeTextFilter.length > 3) {
            if (isNumber(state.freeTextFilter)) {
                const location = cities_and_geo_location.find((x) => x.zipcode === state.freeTextFilter);

                if (location) {
                    const newCoordinates = {
                        lat: location.coordinates.latitude,
                        long: location.coordinates.longitude,
                    };

                    state.userCurrentLocation = newCoordinates;
                    state.locations = sortGeoLocations(state.locations, newCoordinates);
                    state.filteredLocations = state.locations;
                    state.dealershipId = state.locations[0].dealershipId;

                    state.mapData?.[0]?.map?.setCenter({
                        lat: state.locations[0].lat,
                        lng: state.locations[0].lng,
                    });
                    state.mapData?.[0]?.map?.setZoom(MAP_ZOOM_LEVEL);
                } else {
                    state.filterStatus = 'no match for zipcode';
                    state.mapData?.[0]?.map?.setZoom(MAP_NO_LOCATION_ZOOM_LEVEL);
                }
            } else {
                const spellingMethods = [
                    state.freeTextFilter.toLowerCase(),
                    state.freeTextFilter.toLowerCase().replace('aa', 'å'),
                    state.freeTextFilter.toLowerCase().replace('å', 'aa'),
                ];
                let matchedLocations = cities_and_geo_location.filter((x) => spellingMethods.some((y) => y === x.name.toLowerCase()));
                if (!matchedLocations || matchedLocations.length === 0) {
                    matchedLocations = cities_and_geo_location.filter((x) => spellingMethods.some((y) => x.name.toLowerCase().includes(y)));
                }
                if (!matchedLocations || matchedLocations.length === 0) {
                    const locationMatcher = state.locations.find((x) =>
                        spellingMethods.some(
                            (y) => x.displayName.toLowerCase().includes(y) || x.address.toLowerCase().includes(y) || x.city.toLowerCase().includes(y)
                        )
                    );
                    if (locationMatcher) {
                        matchedLocations = [
                            {
                                coordinates: {
                                    latitude: locationMatcher.lat,
                                    longitude: locationMatcher.lng,
                                },
                                name: '',
                                zipcode: '',
                            },
                        ];
                    }
                }

                if (matchedLocations && matchedLocations.length > 0) {
                    const newCoordinates = {
                        lat: matchedLocations[0].coordinates.latitude,
                        long: matchedLocations[0].coordinates.longitude,
                    };

                    state.userCurrentLocation = newCoordinates;
                    state.locations = sortGeoLocations(state.locations, newCoordinates);
                    state.filteredLocations = state.locations;
                    state.dealershipId = state.locations[0].dealershipId;

                    state.mapData?.[0]?.map?.setCenter({
                        lat: state.locations[0].lat,
                        lng: state.locations[0].lng,
                    });

                    // Set Zoom level
                    if (state.locations.length > 0) {
                        state.mapData?.[0]?.map?.setZoom(MAP_ZOOM_LEVEL);
                    } else {
                        state.mapData?.[0]?.map?.setZoom(MAP_NO_LOCATION_ZOOM_LEVEL);
                    }
                } else {
                    state.filterStatus = 'no match for city name';
                    state.mapData?.[0]?.map?.setZoom(MAP_NO_LOCATION_ZOOM_LEVEL);
                }
            }
        } else {
            state.filteredLocations = state.locations;
        }
        state.selectedBrand = undefined;
        state.selectedDepartment = undefined;
    }),
    setSelectedBrand: action((state, payload) => {
        state.selectedBrand = payload;
        state.config.departments.forEach((x) => (x.disabled = false));
        if (payload === 'Alle') {
            const filteredByAllBrands = state.locations.filter((x) =>
                state.selectedDepartment && state.selectedDepartment !== 'Alle'
                    ? x.departments.some((x) => x.departmentType === state.selectedDepartment)
                    : true
            );
            state.filteredLocations = sortGeoLocations(filteredByAllBrands, state.userCurrentLocation);
        } else {
            const filteredByBrand = state.locations.filter(
                (x) =>
                    x.supportedBrands.some((x) => x.brand === payload) &&
                    (state.selectedDepartment && state.selectedDepartment !== 'Alle'
                        ? x.departments.some((x) => x.departmentType === state.selectedDepartment)
                        : true)
            );
            state.filteredLocations = sortGeoLocations(filteredByBrand, state.userCurrentLocation);
            state.config = handleBrandChange(state.filteredLocations, state.config);
        }

        state.mapData?.[0]?.map?.setCenter({
            lat: state.filteredLocations[0].lat,
            lng: state.filteredLocations[0].lng,
        });

        state.freeTextFilter = '';
    }),
    setSelectedDepartment: action((state, payload) => {
        state.selectedDepartment = payload;
        state.config.brands.forEach((x) => (x.disabled = false));
        if (payload === 'Alle') {
            const filteredByAll = state.locations.filter((x) =>
                state.selectedBrand && state.selectedBrand !== 'Alle' ? x.supportedBrands.some((x) => x.brand === state.selectedBrand) : true
            );
            state.filteredLocations = sortGeoLocations(filteredByAll, state.userCurrentLocation);
        } else {
            const filteredByDepartment = state.locations.filter(
                (x) =>
                    x.departments.some((x) => x.departmentType === payload) &&
                    (state.selectedBrand && state.selectedBrand !== 'Alle' ? x.supportedBrands.some((x) => x.brand === state.selectedBrand) : true)
            );
            state.filteredLocations = sortGeoLocations(filteredByDepartment, state.userCurrentLocation);
            state.config = handleDepartmentChange(state.filteredLocations, state.config);
        }

        state.mapData?.[0]?.map?.setCenter({
            lat: state.filteredLocations[0].lat,
            lng: state.filteredLocations[0].lng,
        });

        state.freeTextFilter = '';
    }),
    setShowEmergencyNumbersSidePanel: action((state, payload) => {
        state.showEmergencyNumbersSidePanel = payload.visible;
        state.dealershipId = payload.dealershipId;
    }),

    setUserCurrentLocation: action((state, payload) => {
        state.userCurrentLocation = payload.coords;
        state.locations = sortGeoLocations(state.locations, payload.coords);
        state.filteredLocations = sortGeoLocations(state.filteredLocations, payload.coords);

        state.mapData?.[0]?.map?.setCenter({
            lat: state.filteredLocations[0].lat,
            lng: state.filteredLocations[0].lng,
        });
        state.mapData?.[0]?.map?.setZoom(MAP_ZOOM_LEVEL);

        if (!payload.coords) {
            const defaultStartingCoords = payload.mobileAccess ? DEFAULT_MAP_POSITION_Mobile : DEFAULT_MAP_POSITION;
            state.mapData?.[0]?.map?.setCenter(defaultStartingCoords);
            state.mapData?.[0]?.map?.setZoom(MAP_NO_LOCATION_ZOOM_LEVEL);
        }
    }),
    createMarkerClusterer: action((state, payload) => {
        state.markerClusterer?.clearMarkers();
        state.markerClusterer?.setMap(null);
        state.markerClusterer = new MarkerClusterer({ map: state.mapData?.[0]?.map, markers: payload.markers });
    }),
});

export const FindDealershipsStore = createContextStoreWithRuntimeModel<
    FindDealershipsStoreModel,
    Partial<FindDealershipsStoreState>,
    FindDealershipsStoreInjections
>(
    (runtimeModel) => ({
        ...findDealershipsDefaultState(runtimeModel),
        ...findDealershipsActions(),
    }),
    { name: 'findDealershipsStore' }
);
