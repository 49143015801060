import styled from 'styled-components';

export type StyledIconButtonProps = {
    size?: number;
};

const defaultButtonSize = 46;

export const StyledIconButton = styled.button.attrs<StyledIconButtonProps>((props) => ({
    style: {
        fontSize: `${(props.size || defaultButtonSize) - 24}px`,
    },
}))<StyledIconButtonProps>`
    appearance: none;
    background: ${(props) => props.theme.palette.common.white};
    border: none;
    border-radius: 100%;
    box-shadow: ${(props) => props.theme.shadows.small};
    cursor: pointer;
    fill: ${(props) => props.theme.palette.common.black};
    padding: 12px;
    stroke: ${(props) => props.theme.palette.common.black};
    transition: transform 150ms;

    &:focus-visible {
        outline: 2px dotted ${(props) => props.theme.palette.primary.main};
        outline-offset: -2px;
    }

    &:hover {
        transform: scale(1.1);
    }

    &:active {
        background: ${(props) => props.theme.palette.common.offWhite};
        fill: ${(props) => props.theme.palette.primary.main};
        stroke: ${(props) => props.theme.palette.primary.main};
        transform: scale(0.95);
    }

    svg {
        display: block;
        height: 1em;
        width: 1em;
    }
`;
