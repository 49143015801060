import styled from 'styled-components';

export const StyledInputHeader = styled.p`
    font-weight: 600;
    margin-bottom: 10px;
`;

export const StyledInputGroup = styled.div`
    margin-bottom: 20px;
`;

export const StyledInputsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;
