import styled from 'styled-components';

import { device } from '../../../lib/media-query';

export const HeroWrapper = styled.section<{ columns: number }>`
    display: flex;
    flex-direction: column;
    height: 900px;

    gap: 10px;

    @media ${device.laptop} {
        display: grid;
        grid-template-columns: repeat(${(props) => props.columns}, 1fr);
        height: clamp(450px, 50vh, 610px);
    }

    & > img {
        display: block;
    }
`;
