import styled from 'styled-components';

export const StyledFitsModelsWrapper = styled.div`
    margin-top: 20px;
`;

export const StyledFitsModelHeader = styled.p`
    font-weight: 600;
`;

export const StyledFitsModelText = styled.p`
    font-weight: 400;
`;

export const StyledSeeAllFitsToggle = styled.p`
    text-decoration: underline;
    color: #0089d1;
    cursor: pointer;
`;
