import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const StarPlusModalContent = styled.div`
    display: flex;
    padding: 30px 0;
    flex-direction: column;
    width: 90%;
    gap: 1rem;

    @media ${device.tablet} {
        flex-direction: row;
        justify-content: space-between;
    }
`;
