import { useEffect, VFC } from 'react';
import { umbraco } from '../../../lib/api';
import { FindDealershipsStore } from '../../../lib/state/find-dealership';
import { FindDealership } from '../../organization/find-dealerships/find-dealership.component';

import { queryTarget } from '../../../lib/media-query';

type DealershipUiLoaderProps = {
    metaMenu?: umbraco.PageMetaMenu;
};

export const DealershipUiLoader: VFC<DealershipUiLoaderProps> = () => {
    const { renderGoogleMaps } = FindDealershipsStore.useStoreState((state) => state);
    const { setUserCurrentLocation } = FindDealershipsStore.useStoreActions((actions) => actions);

    useEffect(() => {
        if (!renderGoogleMaps) {
            return;
        }

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setUserCurrentLocation({ coords: { lat: position.coords.latitude, long: position.coords.longitude } });
                },
                (_error) => {
                    const matchMedia = window.matchMedia(`(max-width: ${queryTarget['tablet']}px)`);
                    setUserCurrentLocation({ coords: undefined, mobileAccess: matchMedia.matches });
                }
            );
        }
    }, [renderGoogleMaps, setUserCurrentLocation]);

    return (
        <>
            <FindDealership showFilteringOptions={true} />
        </>
    );
};
