import { useEffect, useState, VFC } from 'react';
import { LeadCheckoutFormsStore, HireCheckoutMode } from '../../../../../../lib/state/hessel-site/lead-checkout/forms';
import { storeInputTypes } from '../../../../../../lib/state/_shared';
import { formInputLabel, formInputValue } from '../../../../../../utils/helpers';
import { SubmittedInfo } from '../../../../../shared/submitted-info';
import { StyledSubmittedInfo } from '../../../typography';
import { SummaryBorderBox } from './user-info-summary.styled';

type UserSubmittedInfo = Array<{
    id: string;
    value: string;
    label: string;
}>;

export const UserInfoSummary: VFC = () => {
    const [submittedInfo, setSubmittedInfo] = useState<UserSubmittedInfo>();

    const { inputs } = LeadCheckoutFormsStore.useStoreState((state) => ({
        inputs: state.inputs,
    }));

    const { checkoutMode } = LeadCheckoutFormsStore.useStoreState((state) => ({
        checkoutMode: state.checkoutMode,
    }));

    useEffect(() => {
        const addressInputs = inputs.find((x) => x.type === 'address');
        let list: storeInputTypes.FormInput<string>[] = [];

        if (addressInputs?.type === 'address') {
            const address = addressInputs.inputs.find(({ multiformInputName }) => multiformInputName === 'address');
            const zipcode = addressInputs.inputs.find(({ multiformInputName }) => multiformInputName === 'zipcode');
            const city = addressInputs.inputs.find(({ multiformInputName }) => multiformInputName === 'city');

            if (address && zipcode && city) {
                const zipcodeAndCity = { ...city, label: 'Post nr. og by', value: `${zipcode.value} ${city.value}` };

                list = [address, zipcodeAndCity];
            }
        }

        const filtered =
            checkoutMode === HireCheckoutMode.Business
                ? inputs.filter((x) => x.type !== 'address')
                : inputs.filter((x) => x.type !== 'company (business)' && x.type !== 'cvr (business)' && x.type !== 'address');

        const result = [...filtered, ...list].map((input) => ({
            id: input.id,
            value: formInputValue(input),
            label: formInputLabel(input),
        }));

        setSubmittedInfo(result);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SummaryBorderBox>
            <StyledSubmittedInfo>
                {submittedInfo?.map((val, idx) => (
                    <SubmittedInfo key={`${idx}:${val.id}`} label={val.label} text={val.value} overrideMaxWidth={true} />
                ))}
            </StyledSubmittedInfo>
        </SummaryBorderBox>
    );
};
