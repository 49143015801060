import { orgTypes } from '../../lib/api/models/umbraco';

export const vehicleIsSupported = (
    brandInfo: orgTypes.SupportedBrand,
    vehicleType: orgTypes.VehicleType,
    department: 'Sales' | 'Workshop'
): boolean => {
    return department === 'Sales'
        ? brandInfo.vehiclesInSales.some((x) => x === vehicleType)
        : brandInfo.vehiclesInWorkshop.some((x) => x === vehicleType);
};

export function getSupportedVehicleIcon(
    brandInfo: orgTypes.SupportedBrand,
    vehicleType: orgTypes.VehicleType,
    department: 'Sales' | 'Workshop'
): string {
    const vehicleTypeSupported = vehicleIsSupported(brandInfo, vehicleType, department);

    switch (vehicleType) {
        case 'Cars':
            return vehicleTypeSupported ? '/icons/brand-and-services/cars-active.svg' : '/icons/brand-and-services/cars-inactive.svg';
        case 'Vans':
            return vehicleTypeSupported ? '/icons/brand-and-services/vans-active.svg' : '/icons/brand-and-services/vans-inactive.svg';
        case 'Trucks':
            return vehicleTypeSupported ? '/icons/brand-and-services/trucks-active.svg' : '/icons/brand-and-services/trucks-inactive.svg';
        case 'Bus':
            return vehicleTypeSupported ? '/icons/brand-and-services/bus-active.svg' : '/icons/brand-and-services/bus-inactive.svg';
        default:
            return '';
    }
}

export function getBrandLogo(brandInfo: orgTypes.SupportedBrand): string {
    switch (brandInfo.brand) {
        case 'Mercedes-Benz':
            return '/icons/brand-and-services/mercedes-logo.svg';
        case 'Renault':
            return '/icons/brand-and-services/renault-logo.svg';
        case 'Dacia':
            return '/icons/brand-and-services/dacia-single-logo.svg';
        case 'Ford':
            return '/icons/brand-and-services/ford-logo.svg';
        case 'FUSO':
            return '/icons/brand-and-services/fuso-logo.svg';
        case 'Smart':
            return '/icons/brand-and-services/smart-logo.png';
        case 'Setra':
            return '/icons/brand-and-services/setra-logo.svg';
        case 'XPENG':
            return '/icons/brand-and-services/xpeng-logo.svg';
        default:
            return '';
    }
}
