import styled from 'styled-components';
import { device } from '../../../../lib/media-query';
import { TextareaInput } from '../../../forms/inputs';

export const ServiceCardWrapper = styled.div`
    margin: 0 -10px;
    @media ${device.tablet} {
        margin: 0;
        display: grid;
        grid-template-columns: 1fr 50px;
    }

    & > button {
        display: none;

        @media ${device.tablet} {
            display: revert;
            margin-left: 20px;
        }
    }
`;

export const BookingTextareaInput = styled(TextareaInput)`
    ::placeholder {
        font-size: 12px;
    }
`;

export const CommentWrapper = styled.div`
    margin: 0px -10px;

    @media ${device.tablet} {
        margin: 5px 50px 0 0;
    }
`;

export const StyledCustomerAgreementTextContainer = styled.div`
    margin-bottom: 15px;
`;
