import styled from 'styled-components';
import { device } from '../../../../../lib/media-query';

export const Container = styled.div`
    display: grid;
    grid-template-columns: auto;
    row-gap: 15px;
`;

export const RadioContainer = styled.div`
    display: grid;
    grid-template-columns: auto;
    row-gap: 10px;

    letter-spacing: 0px;
    line-height: 23px;
    font-weight: 400;
    font-size: 12px;

    margin-left: 10px;

    @media ${device.tablet} {
        margin-left: 0;
    }
`;

export const DropdownInputContainer = styled.div`
    max-width: 380px;
    padding-right: 10px;

    @media ${device.tablet} {
        padding-right: 0px;
    }
`;
