import { FC } from 'react';
import { SvgIcon } from '../svg-icon';
import { ICarInfo } from './car-info.props';
import { Wrapper, StyledCheckmark, InfoWrapper, StyledLabel, StyledText } from './car-info.styled';

export const CarInfo: FC<ICarInfo> = ({ icon, checkmarkColor: iconColor, descriptionIconColor, label, text, success = true }) => {
    return (
        <Wrapper>
            <StyledCheckmark>
                <SvgIcon iconName={success ? 'checkmark' : 'close'} color={success ? iconColor : 'danger'} />
            </StyledCheckmark>
            {success && <SvgIcon iconName={icon} color={descriptionIconColor} />}
            <InfoWrapper>
                <StyledLabel>{label}</StyledLabel>
                <StyledText>{text}</StyledText>
            </InfoWrapper>
        </Wrapper>
    );
};
