import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const StyledCartPageWrapper = styled.main`
    background-color: rgb(247, 247, 248);
    padding: 40px 0;

    @media ${device.laptopS} {
        padding: 80px 0;
    }
`;

export const StyledCartPageHeader = styled.h1`
    font-size: 30px;
    font-weight: 700;
    font-family: 'Corporate E';
    line-height: 35px;
    @media ${device.laptopS} {
        font-size: 40px;
        line-height: 43px;
    }
`;

export const StyledCartPageGrid = styled.div`
    margin-top: 20px;
    gap: 40px;
    display: flex;
    flex-direction: column;
    @media ${device.laptopS} {
        display: grid;
        grid-template-columns: 2fr 1fr;
        align-items: flex-start;
    }
`;

export const StyledBoxSection = styled.div`
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 10px 0px;
    padding: 30px;
`;
