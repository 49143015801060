import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const ContentWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    @media ${device.tablet} {
        grid-template-columns: 30px 1fr;
        grid-column-gap: 1.5rem;
    }
`;

export const StyledIcon = styled.img`
    display: none;
    width: 35px;

    @media ${device.tablet} {
        display: revert;
    }
`;

export const StyledDescription = styled.p`
    padding-left: 0;
    font-weight: normal;
    font-size: 12px;
    @media ${device.tablet} {
        font-size: 13px;
    }
`;

export const TitleAndDescription = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledTitle = styled.p`
    font-size: 12px;

    @media ${device.tablet} {
        font-size: 13px;
    }
`;

export const DescriptionButton = styled.div`
    font-size: 12px;

    button {
        position: unset;
    }

    @media ${device.tablet} {
        font-size: 13px;
    }
`;

export const ButtonText = styled.span`
    font-size: inherit;
`;
