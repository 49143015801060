import { FC, ReactNode, useEffect, useState } from 'react';
import { config, useSpring } from 'react-spring';
import { useMeasure } from 'react-use';
import { StyledAnimatedCheckboxWrapper, StyledContentWrapper, StyledReadMoreLink } from './checkox-filter.styled';

type IProps = {
    initialContent: ReactNode;
    readMoreContent: ReactNode;
};

export const CheckboxFilterReadMore: FC<IProps> = ({ initialContent, readMoreContent }) => {
    const [isOpen, setIsOpen] = useState(false);
    const defaultHeight: string | undefined = '0px';

    // Gets the height of the element (ref)
    const [heightRef, { height }] = useMeasure();

    const [contentHeight, setContentHeight] = useState(defaultHeight || height);

    useEffect(() => {
        //Sets initial height
        setContentHeight(height);
    }, [height]);

    // Animations
    const expand = useSpring({
        config: { ...config.slow, friction: 50 },
        height: isOpen ? `${contentHeight}px` : defaultHeight,
        overflow: !isOpen ? 'hidden' : '',
    });

    const fadeContent = useSpring({
        config: { ...config.slow, friction: 50 },
        opacity: isOpen ? 1 : 0,
        delay: 300,
    });

    return (
        <div>
            <div style={{ marginBottom: '20px' }}>{initialContent}</div>
            {!isOpen ? <StyledReadMoreLink onClick={() => setIsOpen(true)}>Vis alle m&aelig;rker</StyledReadMoreLink> : null}
            <StyledAnimatedCheckboxWrapper style={expand}>
                <StyledContentWrapper ref={heightRef as any} style={fadeContent}>
                    {readMoreContent}
                </StyledContentWrapper>
            </StyledAnimatedCheckboxWrapper>
        </div>
    );
};
