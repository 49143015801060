import styled from 'styled-components';
import { device } from '../../../lib/media-query';
import { SharedStylePreserver } from '../style-preserver/style-preserver.styled';
import { SpotHeader } from '../typography/spot-header/spot-header.component';

export const HeroCardWrapper = styled.div<{ bgColor: string; textColor: string }>`
    display: flex;
    flex-direction: column;
    background: ${({ theme, bgColor }) => (bgColor && bgColor.length > 0 ? bgColor : theme.palette.common.white)};
    color: ${({ theme, textColor }) => (textColor && textColor.length > 0 ? textColor : theme.palette.common.black)};
    padding: 25px 20px;
    isolation: isolate;
    z-index: 10;
    max-width: 400px;
    min-width: 300px;
    width: 95%;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);

    position: relative;

    @media ${device.tablet} {
        min-width: 390px;
    }
    text-align: center;
`;

export const Tag = styled.p<{ textColor: string }>`
    text-transform: uppercase;
    color: ${({ textColor }) => (textColor && textColor.length > 0 ? textColor : '#688488')};
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 12px;
    margin-bottom: 1rem;
`;

export const StylePreserver = styled(SharedStylePreserver)`
    & > * {
        font-size: 30px;
    }
`;

export const Header = styled.p`
    font-size: 30px;
    margin-bottom: 5px;
`;

export const StyledSpotHeader = styled(SpotHeader)`
    margin-bottom: 5px;
`;

export const Price = styled.p`
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
    line-height: 26px;
`;

export const ValueForCustomer = styled.p`
    margin-bottom: 5px;
`;

export const BenefitList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 2rem;
`;

export const BenefitItem = styled.div`
    display: flex;
    gap: 1rem;
`;

export const BenefitText = styled.p`
    font-weight: 500;
    text-align: left;
`;
