import styled from 'styled-components';
import { device } from '../../../../../lib/media-query';
import { GenericCircle } from '../../../../shared/shapes/generic-circle';

export const BorderBox = styled.div`
    position: relative;

    display: grid;
    grid-template-columns: 150px 1fr;
    grid-column-gap: 1rem;
    align-items: center;
    padding: 15px 0;

    padding-top: 35px;

    @media (min-width: 445px) {
        padding-top: revert;
    }
`;

export const ImageWrapper = styled.div``;

export const StyledImage = styled.img`
    display: block;
    max-inline-size: 100%;
    block-size: auto;
    object-fit: cover;
    object-position: center;
`;

export const TextsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 70px;
`;

export const CarName = styled.p`
    color: #0b0b0b;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
`;

export const CarModel = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
`;

export const CarColor = styled.p`
    color: #807f80;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 140px;

    @media ${device.tablet} {
        width: revert;
    }
`;

export const StyledRemove = styled.p`
    color: ${(props) => props.theme.palette.primary.main};
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    right: 0;
    top: 10px;
    cursor: pointer;
`;

export const ColorTextAndImage = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

export const StyledSummaryColorCircle = styled(GenericCircle)<{ imageUrl?: string; backgroundColor?: string }>`
    width: 12px;
    height: 12px;
    background-image: ${(props) => (props.imageUrl ? `url('${props.imageUrl}')` : 'none')};
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    flex-shrink: 0;
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'unset')};
`;
