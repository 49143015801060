import { VFC } from 'react';
import {
    Contact,
    ContactInfoList,
    CorrespondingBrands,
    EmployeeCardWrapper,
    EmployeeImage,
    EmployeeInfoWrapper,
    Header,
    ImageWrapper,
    Role,
    StyledAnchor,
    StyledBrandAndDealershipsWrapper,
} from './employee-card.styled';

type EmployeeCardProps = {
    name: string;
    imageUrl?: string;
    brands: Array<string>;
    email: string;
    phone1: string;
    phone2: string;
    title: string;
    showMobileNumber: boolean;
    className?: string;
    dealershipNames?: string[];
};

export const EmployeeCard: VFC<EmployeeCardProps> = ({
    name,
    imageUrl,
    brands,
    email,
    phone1,
    phone2,
    title,
    showMobileNumber,
    className,
    dealershipNames,
}) => {
    return (
        <EmployeeCardWrapper tabIndex={0} className={className}>
            <ImageWrapper>
                <EmployeeImage src={imageUrl} />
            </ImageWrapper>
            <EmployeeInfoWrapper>
                <Header>{name}</Header>
                <Role>{title}</Role>
                <ContactInfoList>
                    <Contact>
                        <StyledAnchor href={`tel:${phone1}`}>{phone1}</StyledAnchor>
                    </Contact>
                    {showMobileNumber ? (
                        <Contact>
                            <StyledAnchor href={`tel:${phone2}`}>{phone2}</StyledAnchor>
                        </Contact>
                    ) : null}
                    <Contact>
                        <StyledAnchor href={`mailto:${email}`}>{email}</StyledAnchor>
                    </Contact>
                </ContactInfoList>
            </EmployeeInfoWrapper>
            <StyledBrandAndDealershipsWrapper>
                {dealershipNames && dealershipNames.length > 0 ? <CorrespondingBrands>{dealershipNames.join(', ')}</CorrespondingBrands> : null}
                <CorrespondingBrands>{brands.join(', ')}</CorrespondingBrands>
            </StyledBrandAndDealershipsWrapper>
        </EmployeeCardWrapper>
    );
};
