import { VFC } from 'react';
import { FormsTrackingStrategy } from '../../../lib/api/models/umbraco';
import { FormsSpot } from '../../../lib/api/models/umbraco/content-spot';
import { sanitizeMarkup } from '../../../utils/helpers';
import { SidePanel } from '../../shared/modals/side-panel';
import { SidePanelLayout } from '../../shared/modals/side-panel-layout/side-panel-layout.component';
import { WriteUsFormDialogBody, HeaderSection, SubHeader, Header } from './write-us-modal.styled';

export type WriteUsFormDialogProps = {
    form: FormsSpot;
    formHeader: string;
    formSubText: string;
    trackingStrategy?: FormsTrackingStrategy;
    isVisible: boolean;
    onClose: () => void;
    pageId: string;
    dealershipId: string;
};

export const WriteUsFormDialog: VFC<WriteUsFormDialogProps> = ({
    form,
    formHeader,
    formSubText,
    trackingStrategy,
    isVisible,
    onClose,
    pageId,
    dealershipId,
}) => {
    return (
        <SidePanel cancelAction={onClose} isVisible={isVisible}>
            <SidePanelLayout closeSidePanel={onClose}>
                <HeaderSection>
                    <Header>{formHeader}</Header>
                    <SubHeader {...sanitizeMarkup(formSubText)} />
                </HeaderSection>
                <WriteUsFormDialogBody
                    form={form.form}
                    pageId={pageId}
                    trackingStrategy={trackingStrategy}
                    componentIdSuffix={`dealership-${dealershipId}`}
                    specialProps={{ dealershipId: dealershipId }}
                />
            </SidePanelLayout>
        </SidePanel>
    );
};
