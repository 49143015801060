import { hesselViewModels } from '../../lib/view-models';

export type GTMHirePdpData = {
    ecommerce: {
        items: Array<GTMProduct | GTMEquipment>;
    };
};

type Affiliation = 'Kontant' | 'HesselHire' | 'Privatleasing' | 'Finansiering';

type GTMProduct = {
    item_id: string; //bilens ID/sku
    item_name: string; //vi skal meget gerne have navnet der står i frontend: https://linkprotect.cudasvc.com/url?a=https%3a%2f%2fprnt.sc%2f3DtFegO-V4Ju&c=E,1,ftsgFZqwgUwbuJnXQdNXwM67YrdBdFgoIhnH8Z67a5QQRnKx_okh6bZBAiOdpsSmJFWb-rwJFPf6qPF-RunJAJcb9nyKK8RnBRT5inayW7iyxg,,&typo=1
    affiliation: Affiliation; //eller Kontant, HesselHire, Privatleasing
    coupon?: string; //Sendes kun på lagerbiler - n efterfulgt af id ved ny og u efterfulgt af id ved brugt
    currency: string;
    item_brand: string;
    item_category: string;
    item_category2: string; //Biltype
    item_category3: string; //Drivmiddel
    item_category4: string; //Trækhjul
    item_category5: string; //Geartype
    item_list_id: 'Bestillingsbil' | 'Lagerbil'; //Kategori fra PLP
    item_list_name: 'Bestillingsbil' | 'Lagerbil'; //Kategori fra PLP
    item_variant: string;
    price: number;
    quantity: number;
};

export type GTMEquipment = {
    item_id: string;
    item_name: string;
    currency: string;
    item_brand: string;
    item_category: string;
    item_variant: string; //valgte bils sku
    price: number;
    quantity: number;
};

type HireLeadCheckoutData =
    | {
          carData?: GTMHirePdpData;
          extraEquipmentData?: Array<GTMEquipment>;
          extraEquipmentPackageData?: Array<GTMEquipment>;
          addonsData?: Array<GTMEquipment>;
      }
    | undefined;

export const convertProductToGtmData = (
    selectedProduct?: hesselViewModels.ProductDetails,
    selectedColor?: hesselViewModels.ProductColor,
    affiliation?: Affiliation
): HireLeadCheckoutData => {
    if (!selectedProduct) {
        return;
    }

    const extraEquipment = selectedProduct?.extraEquipment['Car HiRE']
        .filter((x) => x.isSelected)
        .map((extraEq) => {
            return {
                item_id: extraEq.id,
                item_name: extraEq.name,
                currency: 'DKK',
                item_brand: selectedProduct.brand,
                item_category: 'Ekstraudstyr',
                item_variant: selectedProduct.id, //valgte bils sku
                price: extraEq.monthlyHirePrice ?? 0,
                quantity: 1,
                affiliation: affiliation,
            };
        });

    const extraEquipmentPackages = selectedProduct?.extraEquipmentPackages['Car HiRE']
        .filter((x) => x.isSelected)
        .map((extraEqPackage) => {
            return {
                item_id: extraEqPackage.packageIdKey,
                item_name: extraEqPackage.name ?? '',
                currency: 'DKK',
                item_brand: selectedProduct.brand,
                item_category: 'Ekstraudstyr',
                item_variant: selectedProduct.id, //valgte bils sku
                price: extraEqPackage.monthlyHirePrice ?? 0,
                quantity: 1,
            };
        });

    const addonsList = selectedProduct?.optionalEquipment['Car HiRE']
        .filter((x) => x.isSelected)
        .map((addon) => {
            return {
                item_id: addon.id,
                item_name: addon.name,
                currency: 'DKK',
                item_brand: selectedProduct.brand,
                item_category: 'Tilvalg',
                item_variant: selectedProduct.id, //valgte bils sku
                price: addon.monthlyHirePrice ?? 0,
                quantity: 1,
            };
        });

    const carInfo = {
        ecommerce: {
            items: [
                {
                    item_id: selectedProduct.itemNumber,
                    item_name: `${selectedProduct.variantTitle}`,
                    affiliation: affiliation ?? 'HesselHire',
                    coupon: undefined,
                    currency: 'DKK',
                    item_brand: selectedProduct.brand,
                    item_category: selectedProduct.modelTitle,
                    item_category2: selectedProduct.carType,
                    item_category3: selectedProduct.fuelType,
                    item_category4: selectedProduct.tractionWheels,
                    item_category5: selectedProduct.transmissionType,
                    item_list_id: 'Bestillingsbil',
                    item_list_name: 'Bestillingsbil',
                    item_variant: selectedColor?.name ?? '',
                    price: selectedProduct.purchaseTypes.hire.baseMonthlyPrice,
                    quantity: 1,
                },
                ...extraEquipment,
                ...extraEquipmentPackages,
                ...addonsList,
            ],
        },
    };

    if (!selectedProduct) {
        return undefined;
    }

    return { carData: carInfo, extraEquipmentData: extraEquipment, extraEquipmentPackageData: extraEquipmentPackages, addonsData: addonsList };
};

export function getGtmTotalPrice(product: HireLeadCheckoutData, colorPrice: number): number {
    let sum = product?.carData?.ecommerce.items[0].price ?? 0;
    sum += colorPrice;

    product?.addonsData?.forEach((x) => (sum += x.price));
    product?.extraEquipmentData?.forEach((x) => (sum += x.price));
    product?.extraEquipmentPackageData?.forEach((x) => (sum += x.price));

    return sum;
}
