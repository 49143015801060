import { useState } from 'react';
import { COMMON_GTM } from '../gtm-tracking-utils';
import { DispatchMethod } from '../gtm-tracking.types';

export type ContactSixPlusSixFormEvents = {
    completeContactSixPlusSixFormEvents: (prams: string) => void;
};

const useContactHesselFromEvents = (dispatch: DispatchMethod): ContactSixPlusSixFormEvents => {
    return useState({
        completeContactSixPlusSixFormEvents: (action: string) =>
            dispatch({
                event: '6+6_tilbud',
                type: COMMON_GTM.COMPLETED,
                id: action,
            }),
    })[0];
};

export const trackContactSixPlusSixFormEvents = (dispatch: DispatchMethod): (() => ContactSixPlusSixFormEvents) => {
    return () => useContactHesselFromEvents(dispatch);
};
