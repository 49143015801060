import { CSSProperties } from 'styled-components';
import { contentSpotTypes } from '../../lib/api';
import { useMediaQuery } from '../../hooks/use-media-query';

type UseOverlayReturnType = {
    overlayStyles: CSSProperties;
};

export const useOverlay = (overlay: contentSpotTypes.SpotOverlaySetting): UseOverlayReturnType => {
    const { color, opacity, variant } = overlay;
    const opacityValue = (opacity === 0 ? 85 : opacity) / 100;
    const isMobile = useMediaQuery({ target: 'tablet' });

    const topToBottomGradient = {
        background: `linear-gradient(to bottom, #${color}, transparent)`,
        opacity: opacityValue,
    };

    if (isMobile) {
        return {
            overlayStyles: topToBottomGradient,
        };
    }

    let overlayStyles: CSSProperties = {};
    switch (variant) {
        case 'Full width':
            overlayStyles = { backgroundColor: '#' + color, opacity: opacityValue };
            break;
        case 'Full width with gaussian blur':
            overlayStyles = { backgroundColor: '#' + color, opacity: opacityValue };
            break;
        case 'Left to right':
            overlayStyles = {
                background: `linear-gradient(to right, #${color}, transparent)`,
                opacity: opacityValue,
            };
            break;
        case 'Right to left':
            overlayStyles = {
                background: `linear-gradient(to left, #${color}, transparent)`,
                opacity: opacityValue,
            };
            break;
        case 'Top to bottom':
            overlayStyles = topToBottomGradient;
            break;
        case 'Bottom to top':
            overlayStyles = {
                background: `linear-gradient(to top, #${color}, transparent)`,
                opacity: opacityValue,
            };
            break;
    }

    return {
        overlayStyles,
    };
};
