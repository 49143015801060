import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const CardWrapper = styled.div<{ noFilters: boolean }>`
    display: grid;
    justify-items: center;
    gap: 20px;

    @media ${device.tablet} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media ${device.laptop} {
        grid-template-columns: repeat(3, 1fr);
    }

    @media ${device.laptopS} {
        grid-template-columns: repeat(${(props) => (props.noFilters ? '4' : '3')}, 1fr);
    }
`;
