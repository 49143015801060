import { VFC } from 'react';
import { HireCheckoutMode } from '../../../../lib/state/hessel-site/lead-checkout/forms';
import { Separator } from '../../../shared';
import { CarSummary } from './car-summary';
import { OrderSummaryCardWrapper, SectionWrapper, TextGroup, Label, StyledValue } from './order-summary-card.styled';
import { TotalNoMoms } from './total-no-moms';
import { TotalPrice } from './total-price';

type OrderSummaryCardProps = {
    colorName: string;
    colorImageUrl: string;
    selectedLeasingPeriod?: { value: string; displayValue: string };
    imageUrl: string;
    carPrice: string;
    extraEquipmentPrice: string;
    optionalEquipmentPrice: string;
    totalPriceIncludingMoms: string;
    totalPriceExcludingMoms: string;
    startupDate?: Date;
    brandTitle?: string;
    variantTitle?: string;
    checkoutMode?: HireCheckoutMode;
};

export const OrderSummaryCard: VFC<OrderSummaryCardProps> = ({
    colorName,
    selectedLeasingPeriod,
    imageUrl,
    totalPriceIncludingMoms,
    totalPriceExcludingMoms,
    startupDate,
    brandTitle,
    variantTitle,
    checkoutMode,
    colorImageUrl,
}) => {
    return (
        <OrderSummaryCardWrapper>
            <CarSummary
                imageUrl={imageUrl}
                brandTitle={brandTitle ?? ''}
                model={variantTitle ?? ''}
                colorName={colorName}
                colorImageUrl={colorImageUrl}
            />

            <Separator marginBottom="0px" marginTop="px" />

            <SectionWrapper>
                <TextGroup>
                    <Label>Leasingperiode {startupDate ? `(Opstart fra ${startupDate.toLocaleDateString('da-DK')})` : null}</Label>
                    <StyledValue>{selectedLeasingPeriod?.displayValue ?? ''}</StyledValue>
                </TextGroup>
            </SectionWrapper>

            <Separator marginBottom="0px" marginTop="px" />

            <TotalPrice totalPriceIncludingMoms={totalPriceIncludingMoms} />

            {checkoutMode === HireCheckoutMode.Business ? (
                <>
                    <Separator marginBottom="0px" marginTop="px" />
                    <TotalNoMoms totalPriceExcludingMoms={totalPriceExcludingMoms} />{' '}
                </>
            ) : null}
        </OrderSummaryCardWrapper>
    );
};
