import styled from 'styled-components';
import { device } from '../../../lib/media-query';
import { SpotHeader } from '../../shared/typography/spot-header/spot-header.component';

export const VehicleProductRibbonWrapper = styled.div`
    background-color: #f7f7f8;
    padding: 60px 0;
`;

export const Header = styled.h2`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: 15px;
`;

export const StyledSpotHeader = styled(SpotHeader)`
    margin-bottom: 15px;
`;

export const DescriptionAndCtas = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    gap: 1rem;

    @media ${device.mobile_tablet} {
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
    }
`;

export const Description = styled.div`
    color: #0b0b0b;
    font-size: 14px;
    letter-spacing: 0;
    font-weight: normal;

    p:last-of-type {
        margin-bottom: 0 !important;
    }
`;

export const Ctas = styled.div`
    display: flex;
    gap: 22px;
    align-items: center;

    @media ${device.mobile_tablet} {
        align-self: flex-end;
    }
`;

export const Cta = styled.button<{ customColor: string }>`
    display: flex;
    background-color: transparent;
    border: none;
    cursor: pointer;
    gap: 4px;
    padding: 0;
    border-radius: 20px;
    color: ${(props) => props.customColor};
    &:disabled {
        cursor: not-allowed;
        & * {
            filter: grayscale(0.5);
            opacity: 0.4;
            cursor: not-allowed;
            transform: none;
        }
    }
`;

export const StyledIconText = styled.p<{ customColor: string }>`
    color: ${(props) => props.customColor};
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    text-transform: uppercase;
`;
