import styled from 'styled-components';

export const StyledPromotionsWrapper = styled.div`
    display: flex;
    gap: 6px;
`;

export const StyledPromotion = styled.p<{ bgColor: string; textColor: string }>`
    background-color: ${(props) => `#${props.bgColor}`};
    color: ${(props) => `#${props.textColor}`};
    font-size: 10px;
    line-height: 10px;
    font-weight: 500;
    padding: 3px 5px;
`;
