import { FC } from 'react';
import { ContentWrapper, Content, Header, ImageAndContentWrapper, StyledSpotHeader } from './full-screen-car-finder.styled';
import { HeroVideo } from '../../shared/hero-video';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';
import { MEDIA_URL } from '../../../utils/environment-constants';
import { contentSpotTypes, umbraco } from '../../../lib/api';
import { FullScreenCarFinderFilters } from '../full-screen-car-finder-filters';
import { CoverImage } from '../../shared/cover-image/cover-image.component';
import { isNullOrEmpty } from '../../../utils/helpers';

export type FullScreenCarFinderProps = {
    heroSettings: contentSpotTypes.HeroSettings;
    globalPlpSettings: umbraco.GlobalProductListSettings;
};

export const FullScreenCarFinder: FC<FullScreenCarFinderProps> = ({ heroSettings, globalPlpSettings }) => {
    const isMobile = useMediaQuery({ target: 'tablet' });

    return heroSettings.alias === 'fullScreenCarFinder' ? (
        <ContentWrapper id={heroSettings.scrollAnchorId}>
            <ImageAndContentWrapper itemsPosition="center" backgroundColor={heroSettings.backgroundColor}>
                {!isMobile ? heroSettings.video ? <HeroVideo videoUrl={`${MEDIA_URL}/${heroSettings.video.src}`} /> : null : null}
                {isMobile ? heroSettings.videoMobile ? <HeroVideo videoUrl={`${MEDIA_URL}/${heroSettings.videoMobile.src}`} /> : null : null}
                {isMobile && heroSettings.videoMobile === undefined ? (
                    heroSettings.video ? (
                        <HeroVideo videoUrl={`${MEDIA_URL}/${heroSettings.video.src}`} />
                    ) : null
                ) : null}

                {!heroSettings.video && !heroSettings.videoMobile ? (
                    <CoverImage
                        src={
                            isMobile && !!heroSettings.imageMobile
                                ? `${MEDIA_URL}/${heroSettings.imageMobile?.src}`
                                : `${MEDIA_URL}/${heroSettings.image?.src}`
                        }
                        loading={heroSettings.isAboveFold ? 'eager' : 'lazy'}
                    />
                ) : null}

                <CenteredBlock maxWidth={880}>
                    <Content>
                        {isNullOrEmpty(heroSettings.headerSize) ? (
                            <Header as={!isNullOrEmpty(heroSettings.headerType) ? heroSettings.headerType : 'p'}>{heroSettings.headlineText}</Header>
                        ) : (
                            <StyledSpotHeader headerType={heroSettings.headerType} headerSize={heroSettings.headerSize}>
                                {heroSettings.headlineText}
                            </StyledSpotHeader>
                        )}

                        <FullScreenCarFinderFilters globalPlpSettings={globalPlpSettings} heroSettings={heroSettings} />
                    </Content>
                </CenteredBlock>
            </ImageAndContentWrapper>
        </ContentWrapper>
    ) : null;
};
