import { VFC } from 'react';
import { uiTypes } from '../../../../lib';
import { sharedTypes } from '../../../../lib/api/models/umbraco';
import { handleUrlClick } from '../../../../utils/helpers';
import { BorderBox, Header, Description, StyledButton } from './no-result-with-cta.styled';

export type NoResultWithCtaProps = {
    className?: string;
    header: string;
    description: string;
    ctaText: string;
    ctaUrl?: sharedTypes.CtaUrl;
    ctaColor?: uiTypes.Variant;
};

export const NoResultWithCta: VFC<NoResultWithCtaProps> = ({ className, header, description, ctaText, ctaUrl, ctaColor = 'primary' }) => {
    return (
        <BorderBox className={className}>
            <Header>{header}</Header>
            <Description>{description}</Description>
            <StyledButton variant={ctaColor} onClick={() => handleUrlClick(ctaUrl)}>
                <p>{ctaText}</p>
            </StyledButton>
        </BorderBox>
    );
};
