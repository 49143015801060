import { Props } from './base-input.props';
import React, { useState, useEffect, useContext } from 'react';
import { useSpring } from 'react-spring';
import { useMeasure } from 'react-use';
import { Adornment, BorderBox, InputBox, Label, DisabledContent, ValidationMessage, InfoMessage, StyledRedLabel } from './base-input.styled';
import { SvgIcon } from '../../../shared/svg-icon';
import { useDeviceDetect } from '../../../../hooks/use-device-detect';
import { ThemeContext } from 'styled-components';

const errorClass = 'hasError';

function BaseInput<T = unknown>({
    id,
    label,
    value,
    children,
    isValid,
    adornment,
    labelProps,
    comboBoxProps,
    inputProps,
    valueIsDefined,
    disabled,
    multiline,
    isOpen,
    hideValidation,
    canValidateInputField,
    validationMessage,
    infoMessage,
    onInputBlur,
    onLabelClick,
    textAreaWrapper = false,
    required,
    ...rest
}: Props<T>): JSX.Element {
    const [labelRef, { width: labelWidth }] = useMeasure<HTMLLabelElement>();
    const [shiftLabel, setShiftLabel] = useState(multiline ? false : valueIsDefined ? valueIsDefined(value) : value ? true : false);
    const [focus, setFocus] = useState(false);
    const device = useDeviceDetect();
    const theme = useContext(ThemeContext);

    const shiftLabelAnimation = useSpring({
        transform: shiftLabel ? 'translateY(-10px)' : 'translateY(0px)',
        // fontSize: shiftLabel ? '10px' : '13px',
        fontSize: shiftLabel ? theme.typography.inputSubLabel.fontSize : theme.typography.inputLabel.fontSize,
        paddingRight: shiftLabel ? '0' : '3px',
        // color: shiftLabel ? '#807F80' : 'black',
        color: shiftLabel ? '#807F80' : 'black',
    });

    const shiftInputAnimation = useSpring({
        transform: shiftLabel ? `translateX(-${labelWidth + (device.isDesktop() ? 2 : 3)}px)` : 'translate(0px)', //The additional 2px is due to some internal padding within the "option" span.
        marginRight: shiftLabel ? `-${labelWidth + 2}px` : '0px',
        paddingLeft: shiftLabel ? '0px' : '3px',
        paddingTop: shiftLabel ? '12px' : '0px',
    });

    useEffect(() => {
        if (!disabled && !multiline) {
            setShiftLabel((valueIsDefined ? valueIsDefined(value) : value) || focus ? true : false);
        }

        if (disabled && !multiline) {
            setShiftLabel((valueIsDefined ? valueIsDefined(value) : value) || value ? true : false);
        }
    }, [value, valueIsDefined, focus, disabled, multiline]);

    useEffect(() => {
        if (!isValid && canValidateInputField && process.browser) {
            const errorElement = document.querySelector(`.${errorClass}`);
            errorElement?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [canValidateInputField, isValid]);

    return (
        <DisabledContent disabled={disabled}>
            <BorderBox
                className={!isValid ? errorClass : ''}
                multiline={multiline}
                isOpen={isOpen}
                isValid={isValid}
                canValidate={canValidateInputField && !disabled}
                {...comboBoxProps}
                textAreaBorder={textAreaWrapper}
            >
                <Label
                    multiline={multiline ? 'true' : 'false'}
                    ref={labelRef}
                    htmlFor={id}
                    style={shiftLabelAnimation}
                    {...labelProps}
                    onClick={() => {
                        if (typeof onLabelClick === 'function') {
                            onLabelClick();
                        }
                    }}
                >
                    {label}
                    {required && !disabled && <StyledRedLabel>*</StyledRedLabel>}
                </Label>
                <InputBox style={shiftInputAnimation} {...inputProps}>
                    {children({
                        ...rest,
                        id,
                        value,
                        onFocus: () => setFocus(true),
                        onBlur: () => {
                            setFocus(false);

                            if (typeof onInputBlur === 'function') {
                                onInputBlur();
                            }
                        },
                        disabled,
                    })}
                </InputBox>
                <Adornment>
                    {isValid && !hideValidation && !textAreaWrapper && <SvgIcon iconName="checkmark" color="success" />}
                    {adornment}
                </Adornment>
            </BorderBox>
            {!isValid && !hideValidation && canValidateInputField && !disabled && <ValidationMessage>{validationMessage}</ValidationMessage>}
            {infoMessage && infoMessage.length > 0 ? <InfoMessage>{infoMessage}</InfoMessage> : null}
        </DisabledContent>
    );
}

export default BaseInput;
