import styled from 'styled-components';

export const DropdownWrapper = styled.div`
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
    border-radius: 20px;
    padding: 9px 20px;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    font-size: 12px;
    position: relative;
    cursor: pointer;
    background-color: white;

    &:not(:first-of-type) {
        margin-top: 5px;
    }
`;

export const DropdownLabel = styled.span`
    color: #636363;
    display: flex;
    align-items: center;
    z-index: 3;
    font-weight: 600;
`;

export const ValueAndArrowWrapper = styled.div<{ open: boolean }>`
    display: flex;
    align-items: center;
    column-gap: 12px;

    img {
        transition: all 0.2s ease-in-out;
        ${(props) => (props.open ? 'transform: rotate(180deg);' : '')}
    }
`;

export const OptionsWrapper = styled.div<{ open: boolean }>`
    position: absolute;
    top: calc(100% - 20px);
    left: -1px;
    right: -1px;
    border: 1px solid ${({ theme: { palette } }) => palette.grey.medium};
    border-top: 0;
    display: ${(props) => (props.open ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: flex-end;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    z-index: 2;

    &:focus,
    &:active {
        outline: none;
    }
`;

export const OptionsBackground = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-top: 5px;
    padding: 20px 0 10px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
`;

export const Option = styled.div<{ disabled: boolean }>`
    padding: 5px 20px;
    font-size: 12px;
    &:hover {
        background-color: ${({ theme: { palette } }) => palette.common.offWhite};
    }
    ${(props) => (props.disabled ? 'pointer-events:none;opacity:0.5;' : '')}
`;
