import styled from 'styled-components';
import { device } from '../../../../../lib/media-query';

export const CompletedWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media ${device.tablet} {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: revert;
    }

    @media ${device.desktop} {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: revert;
    }
`;
