import React, { FC, useCallback, useMemo } from 'react';
import { BookingFormStore } from '../../../../../../lib/state/booking/form';
import { BookingServiceProductsStore } from '../../../../../../lib/state/booking/service-products';
import { BookingStepsStore } from '../../../../../../lib/state/booking/steps';
import { filterStepType } from '../../../../../../lib/state/booking/steps/booking-steps.helpers';
import { formatPrice } from '../../../../../../utils/helpers';
import { Button, CheckBox } from '../../../../../shared';
import { StyledCheckboxContainer, TirehotelText, YoutubeLinkContent } from './tirehotel-checkbox.styled';

type Props = {
    onVideoLinkClicked: (watchId: string) => void;
};

export const TirehotelCheckbox: FC<Props> = ({ onVideoLinkClicked }) => {
    const content = BookingStepsStore.useStoreState((state) => state.steps.find(filterStepType('Services'))?.content);

    const { setServiceProductSelected } = BookingServiceProductsStore.useStoreActions((state) => ({
        setServiceProductSelected: state.setServiceProductSelected,
    }));

    const { allProducts, selectedServiceProductsIds } = BookingServiceProductsStore.useStoreState((state) => ({
        allProducts: state.flattenedProductsAndSubProducts,
        selectedServiceProductsIds: state.selectedServiceProductsIds,
    }));

    const hasStoredTires = BookingFormStore.useStoreState(({ vehicle }) => vehicle?.hasStoredTires);

    const customerWantsTirehotel = useMemo(() => {
        return selectedServiceProductsIds.some((id) => content?.tirehotelProductIds?.some(({ productId }) => productId === id));
    }, [content?.tirehotelProductIds, selectedServiceProductsIds]);

    const tirehotelText = useMemo(() => {
        const tirehotelPrice = allProducts.find(({ id }) => content?.tirehotelProductIds?.some((x) => x.productId === id))?.price;
        if (tirehotelPrice?.value == null) return content?.tirehotelNoPriceText;

        const text = customerWantsTirehotel && hasStoredTires ? content?.tirehotelSelectedText : content?.tirehotelNotSelectedText;
        if (text == null) return null;

        return text?.replace('{price}', formatPrice(tirehotelPrice.value, 2));
    }, [
        allProducts,
        content?.tirehotelNoPriceText,
        content?.tirehotelNotSelectedText,
        content?.tirehotelProductIds,
        content?.tirehotelSelectedText,
        customerWantsTirehotel,
        hasStoredTires,
    ]);

    const setCustomerWantsTirehotel = useCallback(
        (checked) => {
            const availableTirehotelProducts = allProducts.filter(({ id }) =>
                content?.tirehotelProductIds?.some(({ productId }) => productId === id)
            );

            availableTirehotelProducts.forEach(({ id }) => setServiceProductSelected({ productId: id, newState: checked }));
        },
        [allProducts, content?.tirehotelProductIds, setServiceProductSelected]
    );

    return (
        <StyledCheckboxContainer>
            <CheckBox
                id="cbCustomerWantsTirehotel"
                value="1"
                checked={customerWantsTirehotel}
                onChange={(checked) => setCustomerWantsTirehotel(checked)}
                textAlign="top"
            >
                {tirehotelText ? (
                    <TirehotelText
                        dangerouslySetInnerHTML={{
                            __html: tirehotelText,
                        }}
                    ></TirehotelText>
                ) : null}
                {content?.tirehotelNotSelectedYoutubeWatchId ? (
                    <Button
                        variant="link"
                        onClick={() =>
                            customerWantsTirehotel && hasStoredTires
                                ? setCustomerWantsTirehotel(false)
                                : onVideoLinkClicked(content?.tirehotelNotSelectedYoutubeWatchId ?? '')
                        }
                    >
                        <YoutubeLinkContent>
                            {customerWantsTirehotel && hasStoredTires
                                ? content?.tirehotelSelectedLinkText
                                : content?.tirehotelNotSelectedVideoLinkText}
                        </YoutubeLinkContent>
                    </Button>
                ) : null}
            </CheckBox>
        </StyledCheckboxContainer>
    );
};
