import styled from 'styled-components';
import { device } from '../../../../../lib/media-query';
import { mixins } from '../../../../../themes';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    ${mixins.gap(0.8)}
    align-items: center;
    padding: 10px;

    @media ${device.tablet} {
        padding: 20px;
        gap: 1.5rem;
        ${mixins.gap(1.5)}
    }

    @media ${device.desktop} {
        padding: 40px;
        padding-left: 70px;
        max-width: 75vw;
    }
`;

export const Close = styled.div`
    display: flex;
    align-content: center;
    width: auto;
    align-self: flex-end;
    cursor: pointer;
`;

export const CloseText = styled.p`
    margin-right: 10px;
`;

export const AgreementHeader = styled.h2`
    align-self: flex-start;
    font-family: 'Corporate E Demi';
`;

export const AgreementList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;

    /* Safari */
    @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
            & > * + * {
                margin-top: 1.5rem;
            }
        }
    }

    & > button {
        align-self: center;
        margin-top: 2rem;
    }
`;
