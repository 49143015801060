import { animated } from 'react-spring';
import styled from 'styled-components';

export const OptionWrapper = styled.div<{ disabled: boolean }>`
    &:not(:last-of-type) {
        margin-bottom: 20px;
    }

    label {
        flex-grow: 1;
    }

    ${(props) => (props.disabled ? 'pointer-events: none;opacity:.5;' : '')}
`;

export const OptionText = styled.p`
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
`;

export const StyledOptionContentWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    align-items: center;
    position: relative;
`;

export const StyledOptionIcon = styled.img`
    max-height: 24px;
    margin-left: auto;
    margin-right: 18px;
    position: absolute;
    right: 0;
`;

export const StyledAnimatedCheckboxWrapper = styled(animated.div)``;

export const StyledContentWrapper = styled(animated.div)``;

export const StyledReadMoreLink = styled.p`
    font-size: 12px;
    line-height: 12px;
    color: #636363;
    text-decoration: underline;
    cursor: pointer;
`;
