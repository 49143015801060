import styled from 'styled-components';

export const StyledBrandDetailsBar = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: #ffffff;
    padding: 23px 47px;
    height: 117px;
    column-gap: 117px;
    align-items: center;
`;

export const LogoWrapper = styled.div``;
export const StyleImage = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    max-width: 150px;
    max-height: 70px;
`;

export const ServicesWrapper = styled.div`
    display: flex;
    gap: 23.65px;
    align-items: baseline;
    justify-self: center;
`;

export const ServiceIconWrapper = styled.div``;

export const StyledImage = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
`;
