import { FC } from 'react';
import { StyledSubmittedInfo } from '../../../hire/hire-lead-checkout/typography';
import { SubmittedInfo } from '../../../shared/submitted-info';
import { CartStoreContext } from '../../../../lib/state/shop/cart/cart.store';

export const ShopCustomerInfoSummary: FC = () => {
    const { cart, cartReceipt, shopCheckoutComplete } = CartStoreContext.useStoreState((state) => state);
    const cartToShow = shopCheckoutComplete ? cartReceipt : cart;
    if (!cartToShow || !cartToShow.customer) return null;
    return (
        <div style={{ opacity: '0.4' }}>
            <StyledSubmittedInfo>
                <SubmittedInfo label={'NAVN'} text={cartToShow.customer.firstName} overrideMaxWidth={true} />
                <SubmittedInfo label={'E-MAIL'} text={cartToShow.customer.email} overrideMaxWidth={true} />
                <SubmittedInfo label={'TLF.'} text={cartToShow.customer.phone} overrideMaxWidth={true} />
                <SubmittedInfo label={'ADRESSE'} text={cartToShow.customer.customerAddress.addressLine1} overrideMaxWidth={true} />
                <SubmittedInfo
                    label={'POSTNR. OG BY'}
                    text={`${cartToShow.customer.customerAddress.zipCode} ${cartToShow.customer.customerAddress.city}`}
                    overrideMaxWidth={true}
                />
            </StyledSubmittedInfo>
        </div>
    );
};
