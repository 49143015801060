import styled from 'styled-components';

export const StyledFormTitle = styled.div`
    font: 700 25px/30px 'Corporate E', Serif;
    margin: 0 0 14px;
`;

export const StyledVehicleTitle = styled.p`
    color: #0b0b0b;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0;
    border-bottom: 1px solid rgb(226, 226, 228);
    padding-bottom: 20px;
    margin-bottom: 20px;
`;

export const StyledVehicleLocation = styled.p`
    color: #0b0b0b;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0;
    margin-bottom: 20px;
`;

export const StyledFormSubtitle = styled.span`
    font-weight: 400;
`;

export const StyledFormWrapper = styled.div``;

export const StyledInputGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    row-gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
`;

export const StyledConsentsWrapper = styled.div`
    border-top: 1px solid rgb(226, 226, 228);
    margin-top: 40px;
    margin-bottom: 30px;
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export const StyledCheckboxLabel = styled.span`
    line-height: 24px;
`;
