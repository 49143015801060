import { FC } from 'react';
import { StyledFilterContainer, StyledFilterWrapper } from './product-list-filter-section.styled';
import { FilterUpdateNotification } from '../../../../lib/api/models/hessel-api/hire';
import { ShopFacetGroup } from '../../../../lib/api/models/shop/product-list';
import { FilterGroup } from '../../../vehicle/vehicle-product-list/vehicle-product-list-filter/filter-group';
import { getFilterRenderType } from '../../../vehicle/vehicle-product-list/vehicle-product-list-filter/filter.helpers';

type IProps = {
    facets: ShopFacetGroup[];
    updateFilterNotifications: (filterUpdates: FilterUpdateNotification[]) => void;
};

export const ProductListFilterSection: FC<IProps> = ({ facets, updateFilterNotifications }) => {
    return (
        <StyledFilterWrapper>
            <StyledFilterContainer>
                {facets.map((x) => {
                    return (
                        <FilterGroup
                            key={x.label}
                            facet={x.facetOptions.map((f) => {
                                return {
                                    ...f,
                                    subFacets: undefined,
                                };
                            })}
                            filterRenderType={getFilterRenderType(x)}
                            group={x}
                            header={x.label}
                            onChange={(update) => updateFilterNotifications(update)}
                            shopContext={true}
                        ></FilterGroup>
                    );
                })}
            </StyledFilterContainer>
        </StyledFilterWrapper>
    );
};
