import { FC, useMemo } from 'react';
import {
    StyledCheckboxLabel,
    StyledConsentsWrapper,
    StyledFormSubtitle,
    StyledFormTitle,
    StyledFormWrapper,
    StyledInputGrid,
    StyledVehicleLocation,
    StyledVehicleTitle,
} from './contact-us-pdp-form.styled';
import { SidePanel } from '../../shared/modals/side-panel';
import { SidePanelLayout } from '../../shared/modals/side-panel-layout/side-panel-layout.component';
import { DropdownInput, TextInput, TextareaInput } from '../../forms/inputs';
import { DealershipInformationPage, ProductDetailsPageContactForm, StarmarkDealershipPage } from '../../../lib/api/models/umbraco';
import { ProductDetailsStore } from '../../../lib/state/hessel-site/product-details';
import { Button, CheckBox } from '../../shared';
import { validateEmail, validateName, validateNotEmpty, validateTel } from '../../../utils/helpers';
import { ContactPdpFormProps, useContactPdpForm } from './use-contact-pdp-form';

type IProps = {
    onClose: () => void;
    isVisible: boolean;
    dealerships: DealershipInformationPage[];
    starmarkDealership: StarmarkDealershipPage[];
    formSetup: ProductDetailsPageContactForm;
    isBusinessContext: boolean;
};

export const ContactUsPdpForm: FC<IProps> = ({ onClose, isVisible, dealerships, starmarkDealership, formSetup, isBusinessContext }) => {
    const { selectedProduct } = ProductDetailsStore.useStoreState((state) => state);
    const mappedDealerships = useMemo(() => {
        if (!selectedProduct || !dealerships) return [];
        return dealerships.filter((x) => {
            const convertedVehicleType = selectedProduct.vehicleType === 'Car' ? 'Cars' : 'Vans';
            const brandMatches = x.supportedBrands.filter((y) => y.brand === selectedProduct.brand);
            const salesMatches = brandMatches.filter((bm) => bm.vehiclesInSales.find((vhs) => vhs === convertedVehicleType));
            if (salesMatches.length > 0) return true;
            return false;
        });
    }, [dealerships, selectedProduct]);
    const formProps: ContactPdpFormProps = useMemo(() => {
        if (
            selectedProduct &&
            selectedProduct.locationId &&
            (selectedProduct.availability === 'Engros' || selectedProduct.availability === 'InStock' || selectedProduct.availability === 'Used')
        ) {
            const exactDealership = dealerships.find((x) => x.hovedafdelingId === selectedProduct.locationId?.toString());
            if (exactDealership) {
                return {
                    departmentName: exactDealership.displayName,
                    departmentId: exactDealership.hovedafdelingId,
                    consentText: formSetup.marketingConsentText,
                    trackingPrice: selectedProduct?.purchaseTypes.cash.basePrice || 0,
                };
            } else {
                const exactStarmarkDealership = starmarkDealership.find((x) => x.hovedafdelingId === selectedProduct.locationId?.toString());
                if (exactStarmarkDealership) {
                    return {
                        departmentName: exactStarmarkDealership.displayName,
                        departmentId: exactStarmarkDealership.hovedafdelingId,
                        consentText: formSetup.marketingConsentText,
                        trackingPrice: selectedProduct?.purchaseTypes.cash.basePrice || 0,
                    };
                }
            }
        }
        return {
            departmentName: '',
            departmentId: '',
            consentText: formSetup.marketingConsentText,
            trackingPrice: selectedProduct?.purchaseTypes.cash.basePrice || 0,
        };
    }, [dealerships, formSetup.marketingConsentText, selectedProduct, starmarkDealership]);
    const { formValues, setFormValues, submitForm, formSubmitted, resetForm } = useContactPdpForm(formProps);

    return (
        <SidePanel
            cancelAction={() => {
                onClose();
                if (formSubmitted) {
                    resetForm();
                }
            }}
            isVisible={isVisible}
        >
            <SidePanelLayout
                closeSidePanel={() => {
                    onClose();
                    if (formSubmitted) {
                        resetForm();
                    }
                }}
            >
                <StyledFormWrapper>
                    <StyledFormTitle
                        dangerouslySetInnerHTML={{ __html: formSetup.header.length > 0 ? formSetup.header : 'Kontakt os' }}
                    ></StyledFormTitle>
                    <StyledVehicleTitle>
                        Du har valgt <strong>{`${selectedProduct?.brand}, ${selectedProduct?.name}`}</strong>
                    </StyledVehicleTitle>
                    {formProps.departmentName.length > 0 ? (
                        <StyledVehicleLocation>
                            Bilen står i <strong>{formProps.departmentName}</strong>
                        </StyledVehicleLocation>
                    ) : null}
                    {formSubmitted ? (
                        <StyledFormSubtitle
                            dangerouslySetInnerHTML={{
                                __html: formSetup.receiptText,
                            }}
                        ></StyledFormSubtitle>
                    ) : (
                        <>
                            <StyledFormSubtitle
                                dangerouslySetInnerHTML={{
                                    __html:
                                        formSetup.subtext.length > 0
                                            ? formSetup.subtext
                                            : 'Ingen spørgsmål er for store eller små. Send os en besked og vi vil svare dig indenfor 24 timer på hverdage.',
                                }}
                            ></StyledFormSubtitle>
                            <StyledInputGrid>
                                <TextInput
                                    id={'contact-pdp-form-name'}
                                    label={'Navn'}
                                    type={'name'}
                                    onChange={(e) =>
                                        setFormValues({
                                            ...formValues,
                                            name: {
                                                ...formValues.name,
                                                value: e.target.value,
                                                isValid: validateName(e.target.value),
                                            },
                                        })
                                    }
                                    value={formValues.name.value}
                                    isValid={formValues.name.isValid}
                                    canValidateInputField={formValues.name.canValidate}
                                    required={true}
                                    validationMessage={`Obligatorisk felt. Indtast dit fulde navn, f.eks. 'Hans Hansen'.`}
                                    onInputBlur={() =>
                                        setFormValues({
                                            ...formValues,
                                            name: {
                                                ...formValues.name,
                                                canValidate: true,
                                            },
                                        })
                                    }
                                />
                                <TextInput
                                    id={'contact-pdp-form-email'}
                                    label={'E-mail'}
                                    type={'email'}
                                    onChange={(e) =>
                                        setFormValues({
                                            ...formValues,
                                            email: {
                                                ...formValues.email,
                                                value: e.target.value,
                                                isValid: validateEmail(e.target.value),
                                            },
                                        })
                                    }
                                    value={formValues.email.value}
                                    isValid={formValues.email.isValid}
                                    canValidateInputField={formValues.email.canValidate}
                                    required={true}
                                    validationMessage={`Obligatorisk felt. Indtast din e-mail adresse.`}
                                    onInputBlur={() =>
                                        setFormValues({
                                            ...formValues,
                                            email: {
                                                ...formValues.email,
                                                canValidate: true,
                                            },
                                        })
                                    }
                                />
                                <TextInput
                                    id={'contact-pdp-form-phone'}
                                    label={'Tlf. nr.'}
                                    type={'text'}
                                    onChange={(e) =>
                                        setFormValues({
                                            ...formValues,
                                            phone: {
                                                ...formValues.phone,
                                                value: e.target.value,
                                                isValid: validateTel(e.target.value),
                                            },
                                        })
                                    }
                                    value={formValues.phone.value}
                                    isValid={formValues.phone.isValid}
                                    canValidateInputField={formValues.phone.canValidate}
                                    required={true}
                                    validationMessage={`Obligatorisk felt. Indtast dit telefonnummer.`}
                                    onInputBlur={() =>
                                        setFormValues({
                                            ...formValues,
                                            phone: {
                                                ...formValues.phone,
                                                canValidate: true,
                                            },
                                        })
                                    }
                                />
                                {formProps.departmentId.length === 0 ? (
                                    <DropdownInput
                                        id={'contact-pdp-form-department'}
                                        label={'Vælg afdeling'}
                                        onChange={(e) => {
                                            if (e && e.value) {
                                                setFormValues({
                                                    ...formValues,
                                                    departmentName: {
                                                        ...formValues.departmentName,
                                                        value: e.displayValue,
                                                        isValid: validateNotEmpty(e.displayValue),
                                                        canValidate: true,
                                                    },
                                                    departmentId: {
                                                        ...formValues.departmentId,
                                                        value: e.value,
                                                        isValid: validateNotEmpty(e.value),
                                                        canValidate: true,
                                                    },
                                                });
                                            }
                                        }}
                                        isValid={formValues.departmentId.isValid}
                                        canValidateInputField={formValues.departmentId.canValidate}
                                        value={{ displayValue: formValues.departmentName.value, value: formValues.departmentId.value }}
                                        options={mappedDealerships.map((x) => {
                                            return {
                                                displayValue: x.displayName,
                                                value: x.hovedafdelingId,
                                            };
                                        })}
                                        required={formProps.departmentId.length === 0}
                                    />
                                ) : null}
                                {isBusinessContext ? (
                                    <TextInput
                                        id={'contact-pdp-form-companyName'}
                                        label={'Firmanavn'}
                                        type={'text'}
                                        onChange={(e) =>
                                            setFormValues({
                                                ...formValues,
                                                companyName: {
                                                    ...formValues.companyName,
                                                    value: e.target.value,
                                                    isValid: true,
                                                },
                                            })
                                        }
                                        value={formValues.companyName.value}
                                        isValid={formValues.companyName.isValid && formValues.companyName.value.length > 0}
                                        canValidateInputField={formValues.companyName.canValidate}
                                        required={false}
                                        onInputBlur={() =>
                                            setFormValues({
                                                ...formValues,
                                                companyName: {
                                                    ...formValues.companyName,
                                                    canValidate: true,
                                                },
                                            })
                                        }
                                    />
                                ) : null}
                            </StyledInputGrid>
                            <TextareaInput
                                id={'contact-pdp-form-comment'}
                                label={'Kommentar'}
                                onChange={(e) =>
                                    setFormValues({
                                        ...formValues,
                                        comment: {
                                            ...formValues.comment,
                                            value: e.target.value,
                                            isValid: validateNotEmpty(e.target.value),
                                        },
                                    })
                                }
                                isValid={formValues.comment.isValid}
                                canValidateInputField={formValues.comment.canValidate}
                                value={formValues.comment.value}
                                placeholder="Stil dine spørgsmål her"
                                required={true}
                                validationMessage={`Obligatorisk felt. Stil dine spørgsmål her.`}
                                onInputBlur={() =>
                                    setFormValues({
                                        ...formValues,
                                        comment: {
                                            ...formValues.comment,
                                            canValidate: true,
                                        },
                                    })
                                }
                            />
                            <StyledConsentsWrapper>
                                <CheckBox
                                    checked={formValues.dataConsent.value}
                                    id="contact-pdp-form-data-consent"
                                    onChange={(value) =>
                                        setFormValues({
                                            ...formValues,
                                            dataConsent: {
                                                ...formValues.dataConsent,
                                                value,
                                            },
                                        })
                                    }
                                    value="data-policy-accept"
                                    textAlign="top"
                                    required={true}
                                    validationMessage={`Du skal acceptere vores persondatapolitik for at fortsætte.`}
                                    canValidate={formValues.dataConsent.canValidate}
                                >
                                    <StyledCheckboxLabel dangerouslySetInnerHTML={{ __html: formSetup.dataConsentText }}></StyledCheckboxLabel>
                                </CheckBox>
                                <CheckBox
                                    checked={formValues.marketingConsent.value}
                                    id="contact-pdp-form-marketing-consent"
                                    onChange={(value) =>
                                        setFormValues({
                                            ...formValues,
                                            marketingConsent: {
                                                ...formValues.marketingConsent,
                                                value,
                                            },
                                        })
                                    }
                                    value="marketing-policy-accept"
                                    textAlign="top"
                                >
                                    <StyledCheckboxLabel dangerouslySetInnerHTML={{ __html: formSetup.marketingConsentText }}></StyledCheckboxLabel>
                                </CheckBox>
                            </StyledConsentsWrapper>
                            <Button onClick={() => submitForm()} variant="primary">
                                {formSetup.submitButtonText.length > 0 ? formSetup.submitButtonText : 'SEND DINE SPØRGSMÅL'}
                            </Button>
                        </>
                    )}
                </StyledFormWrapper>
            </SidePanelLayout>
        </SidePanel>
    );
};
