import { FC, useMemo } from 'react';
import { CustomerInfoSection, Header, Quote, RatingSection, StyledCard, StyledImage, Text } from './testimonial-card.styled';
import { TestimonialCustomer } from './testimonial.types';
import StarRating from '../../shared/star-rating/star-rating.component';
import { getCmsImageUrl } from '../../../utils/helpers';
import { Button } from '../../shared';

type Props = {
    customer: TestimonialCustomer;
    setCustomer: (customer: TestimonialCustomer) => void;
    openSidePanel: () => void;
};

export const TestimonialCard: FC<Props> = ({ customer, setCustomer, openSidePanel }) => {
    const maxTextLength = 175;

    const customerText = useMemo(() => {
        const text = customer.text.length >= maxTextLength ? customer.text.slice(0, maxTextLength).trim().concat('... ') : customer.text.concat('"');
        return `"${text}`;
    }, [customer.text]);

    return (
        <StyledCard
            className="keen-slider__slide"
            onClick={() => {
                setCustomer(customer);
                openSidePanel();
            }}
        >
            <StyledImage src={getCmsImageUrl(customer.image.src)} alt={customer.customerName} />
            <CustomerInfoSection>
                <Header>{customer.customerName}</Header>
                <Quote>&quot;{customer.quote}&quot;</Quote>
                <Text>
                    {customerText}
                    {customer.text.length >= maxTextLength && (
                        <Button variant="link" onClick={() => {}}>
                            Læs mere
                        </Button>
                    )}
                </Text>
            </CustomerInfoSection>
            <RatingSection>
                <StarRating currentRating={customer.rating} numberOfStars={5} spaceBetweenStars={1} starWidth={30} />
            </RatingSection>
        </StyledCard>
    );
};
