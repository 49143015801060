import { useState } from "react";
import { DispatchMethod, GtmItem, GtmTransaction } from "../gtm-tracking.types";
import { defaultCheckoutDataLayer, defaultCheckoutOptionDataLayer, defaultPurchaseDataLayer } from "../gtm-tracking-utils";

export type ReepayFormEvents = {
    checkoutOption: (step: number, option: string) => void;
    checkoutStep: (step: number, product?: GtmItem) => void;
    purchase: (actionField: GtmTransaction, product: GtmItem) => void;
}

const useReepayFormEvents = (dispatch: DispatchMethod, type: string): ReepayFormEvents => {
    return useState({
        checkoutOption: (step: number, option: string) => dispatch(
            defaultCheckoutOptionDataLayer(type, step, option)),

        checkoutStep: (step: number, product?: GtmItem) => dispatch(
            defaultCheckoutDataLayer(type, step, product ? [product] : [])),

        purchase: (actionField: GtmTransaction, product: GtmItem) => dispatch(
            defaultPurchaseDataLayer(type, actionField, [product])),
    })[0];
}

export const trackReepayForm = (dispatch: DispatchMethod): (type: string) => ReepayFormEvents => {
    return (type: string) => useReepayFormEvents(dispatch, type);
}
