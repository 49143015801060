import { FC } from 'react';
import { BookingElements } from './booking-elements';
import { Props } from './page-elements.props';

/**
 * Note: The changes/decision to support multi-site was made after booking components were made.
 * Therefore, it is not possible to rename rootPage without breaking the entire umbraco setup.
 */
const bookingSiteId = 'rootPage';

const PageElements: FC<Props> = ({ page, siteSettings }) => {
    switch (page.contentTypeAlias) {
        case bookingSiteId:
        case 'bookingMainPage':
            return <BookingElements bookingSteps={page.bookingSteps} siteSettings={siteSettings} />;
        default:
            return <p>unknown page type</p>;
    }
};

export default PageElements;
