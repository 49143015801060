import { VFC } from 'react';
import { useGtmTracking } from '../../../gtm-tracking/hooks/use-gtm-tracking';
import { FindDealershipSpot } from '../../../lib/api/models/umbraco/content-spot';
import { HireContentDisplayStore } from '../../../lib/state/hessel-site/display-manager';
import { getUniqueId, isNullOrEmpty, sanitizeMarkup } from '../../../utils/helpers';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';
import { useUI } from '../../ui';
import {
    InfoSection,
    MapSection,
    BorderBox,
    Header,
    Description,
    StyledDealershipInfoWrapper,
    InfoList,
    InfoListItem,
    StyledIcon,
    StyledText,
    StyledMapImage,
    StyledButton,
} from './find-dealership-module.styled';
import { SpotHeader } from '../typography/spot-header/spot-header.component';

export type FindDealershipModuleProps = {
    content: FindDealershipSpot;
};

const MEDIA_URL = process.env.NEXT_PUBLIC_MEDIA_URL;

export const FindDealershipModule: VFC<FindDealershipModuleProps> = ({ content }) => {
    const { setShowDealershipListDialog } = HireContentDisplayStore.useStoreActions((actions) => actions);
    const uiHandler = useUI();

    // GTM
    const { trackHireLeadCheckout } = useGtmTracking();
    const tracker = trackHireLeadCheckout();

    return (
        <CenteredBlock maxWidth={1110}>
            <BorderBox id={content.scrollAnchorId}>
                <InfoSection>
                    <StyledDealershipInfoWrapper>
                        {isNullOrEmpty(content.headerSize) ? (
                            <Header as={content.headerType}>{content.header}</Header>
                        ) : (
                            <SpotHeader headerType={content.headerType} headerSize={content.headerSize}>
                                {content.header}
                            </SpotHeader>
                        )}
                        <Description {...sanitizeMarkup(content.description)} />
                    </StyledDealershipInfoWrapper>

                    <InfoList>
                        {content.infoList
                            ? content.infoList.map((x) => {
                                  return (
                                      <InfoListItem key={`dealership-info-${getUniqueId()}`} iconAvailable={!!x.icon}>
                                          {x.icon ? <StyledIcon src={`${MEDIA_URL}/${x.icon?.src}`} /> : <div></div>}
                                          <StyledText {...sanitizeMarkup(x.text)} />
                                      </InfoListItem>
                                  );
                              })
                            : null}
                    </InfoList>

                    {!isNullOrEmpty(content.buttonText) ? (
                        <StyledButton
                            onClick={() => {
                                uiHandler.removeScroll();
                                setShowDealershipListDialog(true);
                                tracker.sidebarOpened('Find afdeling');
                            }}
                            variant="primary"
                        >
                            <p>{content.buttonText}</p>
                        </StyledButton>
                    ) : null}
                </InfoSection>
                <MapSection>
                    <StyledMapImage src={`${MEDIA_URL}/${content.image?.src}`} loading={content.isAboveFold ? 'eager' : 'lazy'} />
                </MapSection>
            </BorderBox>
        </CenteredBlock>
    );
};
