import React, { VFC } from 'react';

import {
    StyledAccordionHeader,
    Label,
    SelectedConfigWrapper,
    StyledConfigText,
    StyledConfigPrice,
    StyledCircle,
} from './configuration-accordion-header.styled';

export type AccordionConfigHeaderProps = {
    className?: string;
    label: string;
    value: string;
    price: string;
    colorUrl?: string;
    icon?: React.ReactNode;
};

const AccordionConfigHeaderMemo: VFC<AccordionConfigHeaderProps> = ({ className, label, value, price, colorUrl, icon }) => {
    return (
        <StyledAccordionHeader className={className}>
            <Label>{label}</Label>
            <SelectedConfigWrapper>
                {colorUrl ? <StyledCircle imageUrl={colorUrl} /> : null}
                {icon ? icon : null}
                <StyledConfigText>{value}</StyledConfigText>
            </SelectedConfigWrapper>
            <StyledConfigPrice>{price}</StyledConfigPrice>
        </StyledAccordionHeader>
    );
};

export const AccordionConfigHeader = React.memo(AccordionConfigHeaderMemo);
