import styled from 'styled-components';

export const StyledYourCarFacetOption = styled.p<{ selected: boolean }>`
    font-size: 12px;
    font-weight: ${(props) => (props.selected ? '600' : '400')};
    ${(props) => (props.selected ? 'color: #0089D1;' : null)}
    margin-bottom: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
`;

export const StyledYourCarSubFacetsWrapper = styled.div`
    margin-left: 15px;
`;

export const StyledFacetOptionIcon = styled.img`
    max-height: 25px;
    max-width: 35px;
`;

export const StyledReadMoreToggle = styled.p`
    color: #636363;
    text-decoration: underline;
    cursor: pointer;
    font-size: 12px;
`;
