import styled from 'styled-components';
import { device } from '../../../lib/media-query';
import { SpotHeader } from '../../shared/typography/spot-header/spot-header.component';

export const ContentWrapper = styled.section``;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    width: 100%;
    padding: 50px 0 75px;
    position: relative;

    @media ${device.tablet} {
        padding: 150px 0 170px;
    }
`;

export const Header = styled.h1`
    color: ${({ theme }) => theme.palette.common.white};
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 1rem;
    font-family: 'Corporate E';

    padding: 0 10px;

    @media ${device.desktop} {
        padding: revert;
    }
`;

export const StyledSpotHeader = styled(SpotHeader)`
    color: ${({ theme }) => theme.palette.common.white};
    margin-bottom: 1rem;
    padding: 0 10px;
    text-align: center;

    @media ${device.desktop} {
        padding: revert;
    }
`;

export const ImageAndContentWrapper = styled.div<{
    backgroundColor?: string;
    itemsPosition: 'center' | 'top';
}>`
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 100%;
    width: 100%;

    background-color: ${(props) => (props.backgroundColor ? '#' + props.backgroundColor : 'rgba(0, 0, 0, 0.7)')};

    @media ${device.tablet} {
        justify-content: ${(props) => (props.itemsPosition === 'center' ? 'center' : 'flex-start')};
    }
`;
