import styled from 'styled-components';
import { device } from '../../../../../lib/media-query';
import { Alert, Clock, MapPin, Phone } from '../../../../icons';

export const StyledInfoWindow = styled.div`
    display: flex;
    flex-direction: column;

    background-color: #ffffff;
    width: 269px;
`;

export const StyledInfoWindowHeader = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: 10px;
`;

export const IconAndTextWrapper = styled.div`
    display: flex;
    gap: 10px;
    margin-bottom: 5px;
`;

export const StyledClickableText = styled.p`
    color: #00adef;
    font-family: 'Avenir Next W01';
    font-size: 14px;
    letter-spacing: 0;
    text-decoration: underline;
    cursor: pointer;
    font-weight: normal;
`;

export const StyledMapPin = styled(MapPin)`
    margin-left: -6px;
`;

export const StyledClock = styled(Clock)`
    margin-left: -6px;
`;

export const StyledPhone = styled(Phone)`
    margin-left: -6px;
`;

export const StyledAlert = styled(Alert)`
    margin-left: -4.5px;
`;

export const StyledHoursWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledText = styled.p`
    font-weight: normal;
    color: #0b0b0b;
    font-family: 'Avenir Next W01';
    font-size: 14px;
    letter-spacing: 0;
    line-height: 23px;
`;

export const StyledAnchor = styled.a`
    font-weight: normal;
    font-family: 'Avenir Next W01';
    font-size: 14px;
    letter-spacing: 0;
    line-height: 23px;
`;

export const StyleInfoWindowButton = styled.button`
    display: flex;
    background-color: transparent;
    border: none;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    padding: 0;
    background-color: #00adef;
    color: #fafafa;
    height: 45px;
    width: 100%;
    border-radius: 20px;
    text-transform: uppercase;

    margin-top: 10px;

    &:disabled {
        cursor: not-allowed;
        & * {
            filter: grayscale(0.5);
            opacity: 0.4;
            cursor: not-allowed;
            transform: none;
        }
    }

    margin-bottom: 20px;

    @media ${device.tablet} {
        width: 117px;
        margin-bottom: revert;
    }
`;
