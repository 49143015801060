import { VFC } from 'react';
import { umbraco } from '../../../lib/api';
import { sanitizeMarkup } from '../../../utils/helpers';
import { MetaMenuWrapper, StyledClose, StyledText } from './meta-menu.styled';

type MetaMenuProps = {
    className?: string;
    metaMenu?: umbraco.PageMetaMenu;
    onClose: () => void;
    isClosed: boolean;
};

export const MetaMenu: VFC<MetaMenuProps> = ({ className, metaMenu, onClose, isClosed }) => {
    return metaMenu && !isClosed ? (
        <MetaMenuWrapper className={className} tabIndex={0} backgroundColor={metaMenu.backgroundColor} textColor={metaMenu.textColor}>
            <div />
            <StyledText {...sanitizeMarkup(metaMenu.text)} />
            <StyledClose textcolor={metaMenu.textColor} role="button" tabIndex={0} onClick={onClose} viewBox="0 -4 35 34" />
        </MetaMenuWrapper>
    ) : null;
};
