import { useRouter } from 'next/router';
import { VFC } from 'react';
import { orgTypes } from '../../../lib/api/models/umbraco';
import { isNullOrEmpty, sanitizeMarkup } from '../../../utils/helpers';
import { Button, Separator } from '../../shared';
import { SidePanel } from '../../shared/modals/side-panel';
import { SidePanelLayout } from '../../shared/modals/side-panel-layout/side-panel-layout.component';
import { Brands, CarType, CarTypeAndBrandList, ContentWrapper, StyledDescription, StyledHeader } from './book-workshop-modal.styled';
import { useCmsPage } from '../../../hooks/use-cms-page';

type BookWorkshopModalProps = {
    visible: boolean;
    onClose: () => void;
    header: string;
    description: string;
    bookWorkshopModalButton: string;
    dealership?: orgTypes.DealershipWithGeoInfo;
};

function mapSupportedBrandsToCarType(supportedBrands: Array<orgTypes.SupportedBrand>) {
    const cartTypes: Array<{
        carType: string;
        brand: string;
    }> = [];

    for (let index = 0; index < supportedBrands.length; index++) {
        const sb = supportedBrands[index];
        sb.vehiclesInSales.forEach((x) => cartTypes.push({ brand: sb.brand, carType: x }));
        sb.vehiclesInWorkshop.forEach((x) => cartTypes.push({ brand: sb.brand, carType: x }));
    }

    let finalResult: Array<{ carType: string; brands: Array<string> }> = [];

    cartTypes.forEach((x) => {
        if (!finalResult.some((f) => f.carType === x.carType)) {
            const listOfBrands: Array<string> = [];

            for (let k = 0; k < cartTypes.length; k++) {
                const car = cartTypes[k];
                if (car.carType === x.carType && !listOfBrands.includes(car.brand)) {
                    listOfBrands.push(car.brand);
                }
            }

            const temp = { carType: x.carType, brands: listOfBrands };
            finalResult = [...finalResult, temp];
        }
    });

    return finalResult;
}

export const BookWorkshopModal: VFC<BookWorkshopModalProps> = ({ visible, onClose, header, description, dealership, bookWorkshopModalButton }) => {
    const router = useRouter();
    const carTypeAndBrands = mapSupportedBrandsToCarType(dealership?.supportedBrands ?? []);
    const { url: bookingPageUrl } = useCmsPage('bookingMainPage');

    const getCarTypeText = (vehicleType: string): string => {
        if (vehicleType === 'Vans') return 'Varebiler';
        if (vehicleType === 'Trucks') return 'Lastbiler';
        return 'Personbiler';
    };

    return (
        <SidePanel
            isVisible={visible}
            cancelAction={() => {
                onClose();
            }}
        >
            <SidePanelLayout
                closeSidePanel={() => {
                    onClose();
                }}
            >
                <StyledHeader>{header}</StyledHeader>

                <StyledDescription {...sanitizeMarkup(description)} />

                <Separator marginBottom="18px" marginTop="29px" />

                <ContentWrapper>
                    {carTypeAndBrands.map((x, index) => {
                        return (
                            <CarTypeAndBrandList key={`${x.carType}-${index}`}>
                                <CarType>{getCarTypeText(x.carType)}</CarType>
                                <Brands>{x.brands.join(', ')}</Brands>
                            </CarTypeAndBrandList>
                        );
                    })}
                </ContentWrapper>

                <Button variant="primary" onClick={() => router.push(bookingPageUrl ?? '/')}>
                    {!isNullOrEmpty(bookWorkshopModalButton) ? bookWorkshopModalButton : 'Book tid på værksted'}
                </Button>
            </SidePanelLayout>
        </SidePanel>
    );
};
