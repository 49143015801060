import { FC } from 'react';
import {
    StyledCard,
    StyledCardHeader,
    StyledDescription,
    StyledImage,
    StyledLink,
    StyledLinkWrapper,
    StyledManchet,
    StyledName,
    StyledPrice,
} from '../car-exploration.styled';
import Link from 'next/link';
import { CtaUrl } from '../../../../lib/api/models/umbraco/shared-types';

type IProps = {
    link?: CtaUrl;
    imageUrl: string;
    imageAlt: string;
    name: string;
    price: string;
    manchet: string;
    description: string;
    linkText?: string;
    useSquaredCornersForImages?: boolean;
};

export const CarExplorationCard: FC<IProps> = (props) => {
    const image = <StyledImage alt={props.imageAlt} src={props.imageUrl} useSquaredCorners={props.useSquaredCornersForImages} />;
    return (
        <StyledCard>
            {props.link && props.link.url && props.link.url.length > 0 ? (
                <Link href={props.link.url} passHref>
                    <StyledLinkWrapper target={props.link.target}>{image}</StyledLinkWrapper>
                </Link>
            ) : (
                image
            )}
            <StyledCardHeader>
                <StyledName>{props.name}</StyledName>
                {props.price && props.price.length > 0 ? <StyledPrice>{props.price}</StyledPrice> : null}
            </StyledCardHeader>
            <StyledManchet>{props.manchet}</StyledManchet>
            <StyledDescription>{props.description}</StyledDescription>
            {props.link && props.link.url && props.link.url.length > 0 ? (
                <Link href={props.link.url} passHref>
                    <StyledLinkWrapper target={props.link.target}>
                        <StyledLink>{props.linkText}</StyledLink>
                    </StyledLinkWrapper>
                </Link>
            ) : null}
        </StyledCard>
    );
};
