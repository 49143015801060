import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const CardWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 450px;
    @media ${device.tablet} {
        height: 100%;
    }
`;

export const BgImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const TextArea = styled.div`
    padding: 50px 20px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    a {
        text-decoration: none;
    }
`;

export const StyledMarketingCardHeader = styled.p`
    font-family: 'Corporate E';
    font-weight: 700;
    font-size: 20px;
    color: white;
`;

export const StyledMarketingCardDescription = styled.p`
    font-size: 13px;
    font-weight: normal;
    margin-top: 5px;
    max-height: 250px;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

export const LinkText = styled.a`
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 700;
    color: white;
    text-decoration: none;
    margin-top: 15px;

    &:hover {
        text-decoration: none;
    }
`;

export const LinkTextSpan = styled.span`
    margin-right: 10px;
    margin-top: 3px;
    color: white;
`;
