import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper/core';

// install Swiper modules
SwiperCore.use([Pagination, Autoplay]);

type Props = {
    showPagination?: boolean;
    columnSpan?: number;
    slideDelayInSeconds: number;
};

export const Slider: FC<Props> = ({ children, columnSpan, showPagination = true, slideDelayInSeconds }) => {
    const pagination = {
        clickable: true,

        renderBullet: function (_: number, className: string) {
            return showPagination ? `<span class='${className}'></span>` : '';
        },
    };

    return (
        <Swiper
            style={{ gridColumn: columnSpan ? `span ${columnSpan}` : '100%', userSelect: 'none' }}
            pagination={pagination}
            autoplay={{ delay: Math.max(5, slideDelayInSeconds) * 1000, pauseOnMouseEnter: true, disableOnInteraction: false }}
            loop={showPagination}
            allowSlideNext={showPagination}
            allowSlidePrev={showPagination}
        >
            {children && (
                <>
                    {React.Children.map(children, (child) => {
                        if (React.isValidElement(child)) {
                            return React.cloneElement(<SwiperSlide>{child}</SwiperSlide>, { style: { ...child?.props.style } });
                        }

                        return null;
                    })}
                </>
            )}
        </Swiper>
    );
};
