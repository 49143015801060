import { FC, useMemo, useRef, useState } from 'react';
import { useClickOutsideObserver } from '../../../../../hooks/use-click-outside-observer';
import { ProductCardPrice } from '../../../../../lib/api/models/hessel-api/hire';
import { ToolTipContent } from '../../../../shared/tool-tip-content/tool-tip-content.component';
import {
    StyledDisclaimerLabel,
    StyledPriceAndDisclaimerInfo,
    StyledPriceFlexContainer,
    StyledPriceLabel,
    StyledPriceValue,
    StyledSavingsIndicator,
} from './product-list-card-price.styled';

export const ProductListCardPriceType: FC<ProductCardPrice> = ({ label, value, disclaimer, type }) => {
    const [disclaimerOpen, setDisclaimerOpen] = useState(false);
    const container = useRef(null);
    const toggleDisclaimer = () => {
        setDisclaimerOpen(!disclaimerOpen);
    };
    useClickOutsideObserver(container, toggleDisclaimer, disclaimerOpen);

    const priceContent = useMemo(() => {
        if (!disclaimer)
            return (
                <>
                    <StyledPriceLabel>{label}</StyledPriceLabel>
                    <StyledPriceValue>{value}</StyledPriceValue>
                </>
            );
        return (
            <>
                <StyledPriceLabel>{label}</StyledPriceLabel>
                {disclaimer.description.length > 0 ? (
                    <StyledPriceAndDisclaimerInfo
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setDisclaimerOpen(!disclaimerOpen);
                        }}
                    >
                        <StyledPriceValue>{value}</StyledPriceValue>
                        <StyledDisclaimerLabel>
                            {type === 'Bundle' ? (
                                <StyledSavingsIndicator>{disclaimer.label}</StyledSavingsIndicator>
                            ) : (
                                <span style={{ marginRight: '5px' }}>{disclaimer.label}</span>
                            )}
                            <ToolTipContent isOpen={disclaimerOpen}>{disclaimer.description}</ToolTipContent>
                        </StyledDisclaimerLabel>
                    </StyledPriceAndDisclaimerInfo>
                ) : (
                    <StyledPriceAndDisclaimerInfo>
                        <StyledPriceValue>{value}</StyledPriceValue>
                        <StyledDisclaimerLabel>{disclaimer.label}</StyledDisclaimerLabel>
                    </StyledPriceAndDisclaimerInfo>
                )}
            </>
        );
    }, [disclaimer, disclaimerOpen, label, type, value]);

    return <StyledPriceFlexContainer ref={container}>{priceContent}</StyledPriceFlexContainer>;
};
