import styled from 'styled-components';
import { device, queryTarget } from '../../../../../lib/media-query';

export const PromotionWrapper = styled.div`
    display: flex;
    box-shadow: ${({ theme }) => theme.shadows.large};
    padding: 7px;
    margin-bottom: 30px;

    max-width: 400px;

    flex-direction: column;
    gap: 15px;

    @media ${device.tablet} {
        flex-direction: row;

        max-width: 790px;
        min-width: 690px;

        gap: 28px;
    }
`;
export const PromotionContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
export const BannerHeader = styled.h3`
    margin-bottom: 11px;
    max-width: 400px;
    font-size: 19px;
    line-height: 22px;

    @media ${device.tablet} {
        margin-top: 4px;
    }
`;
export const DesktopBannerImage = styled.img`
    width: 100%;
    display: none;

    @media ${device.tablet} {
        display: revert;
        height: 240px;
        width: auto;
    }
`;
export const MobileBannerImage = styled(DesktopBannerImage)`
    display: revert;

    @media ${device.tablet} {
        display: none;
    }
`;
export const SellingPointsList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 5px;

    margin-bottom: 11px;
`;
export const SellingPoint = styled.li`
    display: flex;
    gap: 10px;
`;
export const BannerCTAContainer = styled.div`
    display: flex;
    gap: 6px;
    justify-content: center;

    @media (max-width: ${queryTarget.mobileL}px) {
        flex-direction: column;
    }

    @media ${device.tablet} {
        justify-content: revert;
        gap: 12px;
    }
`;
