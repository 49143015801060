import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const StyledAccordionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 30px;

    @media ${device.tablet} {
        padding-right: 50px;
    }
`;

export const HeaderLabel = styled.p`
    color: #0b0b0b;
    font-size: clamp(12px, 2vw, 14px);
    font-weight: 500;
    letter-spacing: 0;
`;

export const OpeningInfoWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 17px;
`;

export const OpeningStatus = styled.div<{ isOpen: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 72px;
    padding: 3px 5px;
    height: 17px;
    background-color: ${(props) => (props.isOpen ? props.theme.palette.primary.main : '#E43A3A')};
`;

export const OpeningStatusText = styled.p`
    color: #fcfcfc;
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 10px;
`;

export const Hours = styled.p`
    color: #807f80;
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: right;
`;

export const StyledText = styled.p`
    color: #0b0b0b;
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    font-size: 14px;
    letter-spacing: 0;
    line-height: 30px;
    font-weight: normal;
`;
