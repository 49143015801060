import styled, { css } from 'styled-components';
import { device } from '../../../../../lib/media-query';
import { mixins } from '../../../../../themes';
import { Button } from '../../../../shared';

export const Wrapper = styled.section<{ isDisabled: boolean }>`
    background-color: ${({ theme }) => theme.palette.grey.main};
    margin: 5px 0;
    flex-grow: 1;
    ${(props) => props.isDisabled && 'color: #c0c0c0;'}
`;

export const HeaderSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;

    @media ${device.tablet} {
        display: grid;
        align-items: flex-start;
        grid-template-columns: 4fr 0.9fr;
    }
`;

export const ServiceHeader = styled.h3<{ isSelected: boolean; isDisabled: boolean }>`
    ${(props) => props.isDisabled && 'color: #c0c0c0;'}
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    font-weight: ${(props) => (props.isSelected ? 600 : 500)};
    letter-spacing: 0;
    line-height: 24px;

    font-size: 13px;
    @media ${device.tablet} {
        font-size: 14px;
    }
`;

export const ServiceAndNote = styled.div<{ isDisabled: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 10px;

    cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
    ${({ isDisabled }) => isDisabled && 'user-select: none;'}

    @media ${device.mobileL} {
        padding: 10px 20px;
    }

    @media ${device.tablet} {
        padding-right: 0;
        display: grid;
        gap: 5px;
        grid-template-rows: max-content auto;
    }
`;

export const SubTitle = styled.div`
    margin-left: 35px;

    @media ${device.tablet} {
        display: flex;
        align-items: baseline;
        justify-content: left;
    }
`;

export const SubTitleText = styled.span`
    ${mixins.typography('small')};
    font-size: 12px;
`;

export const SubTitleLink = styled.span`
    ${mixins.typography('small')};
    font-size: 12px;
    color: ${({ theme }) => theme.palette.primary.main};
    text-decoration: underline;
`;

export const PriceAndService = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const PricesWrapper = styled.div`
    display: none;

    @media ${device.tablet} {
        justify-self: end;
        align-self: start;

        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;

        padding-top: 10px;
    }
`;

export const CurrentPrice = styled.p`
    font-weight: 500;
    font-size: 14px;
    padding-right: 15px;
    white-space: nowrap;
    text-align: right;
`;

export const BeforePrice = styled(CurrentPrice)`
    text-decoration: line-through;
    opacity: 0.5;
    padding-right: 0;
    font-size: 14px;
`;

export const CurrentPriceMobile = styled.p`
    font-weight: 500;
    white-space: nowrap;
    justify-self: end;

    font-size: 0.9rem;
    @media ${device.tablet} {
        font-size: 1.1rem;
    }

    @media ${device.tablet} {
        display: none;
    }
`;

export const BeforePriceMobile = styled(CurrentPriceMobile)`
    opacity: 0.5;
    text-decoration: line-through;
    align-self: center;

    font-size: 12px;
    @media ${device.mobileL} {
        font-size: 14px;
    }

    @media ${device.tablet} {
        display: none;
    }
`;

export const Link = styled.a`
    padding: 10px;
    padding-top: 0px;
    margin-left: 35px;
    margin-top: 10px;

    @media ${device.mobileM} {
        justify-self: flex-end;
    }

    @media ${device.tablet} {
        margin-left: revert;
        padding-top: 10px;
        margin-top: revert;
    }
`;

export const SubSection = styled.div`
    display: flex;
    flex-direction: column;

    @media ${device.mobile_tablet} {
        display: grid;
        align-items: center;
        grid-template-columns: 2fr 1fr;
        padding-left: 15px;
    }
`;

export const SectionText = styled.p`
    font-weight: 600;
`;

export const StyledHeader = styled.h3`
    font-weight: 500;
`;
export const SubProductsStyledHeader = styled(StyledHeader)`
    font-size: 16px;
`;
export const StyledDescription = styled.p`
    letter-spacing: 0px;
    line-height: 23px;
    font-weight: 400;
    font-size: 12px;
`;

export const SubProductsHeader = styled.div`
    padding-left: 10px;

    @media ${device.mobileL} {
        padding: 0 20px;
    }
`;

export const Container = styled.div`
    @media ${device.mobileL} {
        margin-left: 10px;
    }

    @media ${device.tablet} {
        margin-left: 47px;
    }
`;

export const SubProductsSection = styled.div`
    @media ${device.tablet} {
        margin-left: 27px;
    }
`;

export const BeforePriceAndPrice = styled.div`
    display: flex;
    gap: 5px;
`;

export const HeaderAndBadge = styled.div<{ isDisabled: boolean }>`
    display: flex;
    gap: 5px;
    align-items: center;

    ${({ isDisabled }) => isDisabled && 'margin-left: 35px;'}
`;

export const PriceAndBadge = styled.div`
    display: grid;
    grid-template-columns: max-content auto;
    align-items: center;
    justify-content: space-between;
`;

export const DiscountBadge = styled.figure<{ isDisabled?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1rem;
    background-color: ${(props) => (props.isDisabled ? '#c0c0c0' : props.theme.palette.primary.main)};
    width: min-content;

    padding: 9px 8px;
    @media ${device.mobileL} {
        padding: 10px 15px;
    }
`;

export const DiscountBadgeContent = styled.p`
    color: #fcfcfc;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0;

    font-size: 12px;
    @media ${device.mobileL} {
        font-size: 13px;
    }
`;

export const YourPriceWrapper = styled.p`
    display: none;
    font-size: 12px;
    font-weight: 400;
    align-self: center;

    @media ${device.tablet} {
        display: revert;

        white-space: nowrap;
        margin-left: auto;
        text-align: right;
    }
`;
export const YourPriceWrapperMobile = styled(YourPriceWrapper)`
    display: revert;

    white-space: nowrap;
    margin-left: revert;
    text-align: left;

    @media ${device.tablet} {
        display: none;
    }
`;

export const StyledButtonContent = styled.span`
    font-size: 12px;
    font-weight: 400;
`;

export const YourPriceLink = styled(Button)<{ isDisabled?: boolean }>`
    ${(props) =>
        props.isDisabled &&
        css`
            color: #c0c0c0;
            border-bottom-color: #c0c0c0;
            cursor: default;
        `}
`;

export const DrivenKilometersContainer = styled.div`
    margin-top: 15px;
    max-width: 380px;
    padding-right: 10px;

    @media ${device.tablet} {
        padding-right: 0px;
    }
`;

export const OptionalTextContainer = styled.div`
    margin: 10px 0px 20px 10px;

    color: #838384;
    font-size: 0.8rem;
    line-height: 1.5rem;
    max-width: 400px;
`;

export const SeparatorContainer = styled.div`
    margin-right: 20px;
`;
export const SubProductsSeparatorContainer = styled.div`
    margin-right: 20px;
    margin-left: 20px;

    @media ${device.tablet} {
        margin-left: 47px;
    }
`;

export const DisabledText = styled.p`
    color: #848484;
    font-size: 12px;
    line-height: 1rem;
`;
