import { FC } from 'react';
import { IRadioButton } from './radio-button.props';
import { StyledRadioButton, StyledLabel, Wrapper, StyledSubLabel, StyledError } from './radio-button.styled';

export const RadioButton: FC<IRadioButton> = ({
    id,
    checked,
    disabled,
    action,
    groupName,
    value,
    children,
    subLabel,
    labelAlignment = 'center',
    isValid = true,
    required = false,
    validationMessage,
    className,
}) => {
    return (
        <>
            <Wrapper alignment={labelAlignment} className={className}>
                <StyledRadioButton id={id} onChange={action} checked={checked} name={groupName} disabled={disabled} value={value} isValid={isValid} />
                <StyledLabel
                    htmlFor={id}
                    onClick={() => {
                        if (typeof action === 'function') {
                            action();
                        }
                    }}
                >
                    {children}
                </StyledLabel>
                <StyledSubLabel>{subLabel}</StyledSubLabel>
            </Wrapper>

            {required && !isValid && <StyledError>{validationMessage}</StyledError>}
        </>
    );
};
