import { useEffect, useMemo, VFC } from 'react';
import { umbraco } from '../../../../lib/api';
import { DisplayManagerStore } from '../../../../lib/state/display-manager';
import { handleUrlClick } from '../../../../utils/helpers';
import { Button, SimpleModal } from '../../../shared';
import { useUI } from '../../../ui';
import { StarPlusModalContent } from './my-hessel-start-plus-handler.styled';

type Props = {
    siteSettings: umbraco.SiteSettings;
};

export const MyHesselStarPlusHandler: VFC<Props> = ({ siteSettings }) => {
    const { showMyHesselStarPlus } = DisplayManagerStore.useStoreState((state) => state);
    const { setShowMyHesselStarPlus } = DisplayManagerStore.useStoreActions((actions) => actions);
    const uiHandler = useUI();

    useEffect(() => {
        if (showMyHesselStarPlus) {
            uiHandler.removeScroll();
        }
    }, [showMyHesselStarPlus, uiHandler]);

    const result = useMemo(() => {
        return siteSettings?.marketSettings.map(({ mitHesselStarPlus }) => {
            return {
                mitHesselStarPlus,
            };
        })[0];
    }, [siteSettings]);

    return (
        <SimpleModal
            headerText={result?.mitHesselStarPlus?.header}
            isVisible={showMyHesselStarPlus}
            closeAction={() => {
                setShowMyHesselStarPlus(false);
                uiHandler.applyScroll();
            }}
            closeText={result?.mitHesselStarPlus?.closeButtonText}
            ctaVariant={
                result?.mitHesselStarPlus?.closeButtonColor && result?.mitHesselStarPlus?.closeButtonColor?.toString() !== ''
                    ? result?.mitHesselStarPlus?.closeButtonColor
                    : 'primary'
            }
        >
            <StarPlusModalContent>
                {result?.mitHesselStarPlus?.ctas.map((cta, index) => {
                    if (cta.alias === 'cTAWithLink') {
                        return (
                            <Button key={`my-hessel-star-plus-${index}`} variant={cta.color} onClick={() => handleUrlClick(cta.linkUrl)}>
                                {cta.text}
                            </Button>
                        );
                    }

                    return null;
                })}
            </StarPlusModalContent>
        </SimpleModal>
    );
};
