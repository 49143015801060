import React, { FC, useMemo, useState } from 'react';
import Star from './star/star.component';
import { StyledStarRatingContainer } from './star-rating.styled';

type Props = {
    numberOfStars: number;
    currentRating: number;
    starWidth: number;
    spaceBetweenStars: number;
    submittedRating?: number;
    onChangeValue?: (rating: number) => void;
};

const StarRating: FC<Props> = ({ onChangeValue, starWidth, numberOfStars, currentRating, spaceBetweenStars, submittedRating }) => {
    const stars = useMemo(() => {
        const result = [];
        for (let i = 0; i < numberOfStars; i++) {
            result.push(i);
        }
        return result;
    }, [numberOfStars]);
    const [mouseHover, setMouseHover] = useState(false);
    const [hoverRating, setHoverRating] = useState(0);

    return (
        <StyledStarRatingContainer
            showPointer={submittedRating === 0}
            onMouseEnter={() => setMouseHover(true)}
            onMouseLeave={() => setMouseHover(false)}
        >
            {stars.map((index) => (
                <Star
                    key={index}
                    width={starWidth}
                    isActive={(mouseHover && submittedRating === 0 ? hoverRating : currentRating) > index}
                    spaceBetween={spaceBetweenStars}
                    onClick={() => {
                        if (submittedRating === 0 && onChangeValue) {
                            onChangeValue(index + 1);
                        }
                    }}
                    onMouseOver={() => {
                        setHoverRating(index + 1);
                    }}
                />
            ))}
        </StyledStarRatingContainer>
    );
};

export default StarRating;
