import { animated } from '@react-spring/web';
import styled from 'styled-components';
import { device } from '../../../../../lib/media-query';

export const BrandInfo = styled(animated.div)`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.palette.grey.main};
    border: 1px solid ${({ theme }) => theme.palette.grey.medium};
    padding: 1.5rem;

    @media ${device.tablet} {
        // Container for this element has display set to grid
        align-self: flex-start;
    }
`;

export const BrandInfoItem = styled.div<{ hasAgreements: boolean; disableGrid?: boolean }>`
    display: grid;
    grid-template-columns: ${(props) => (props.disableGrid ? '1fr' : '1fr 1fr 4fr')};
    align-items: center;
    margin-bottom: ${(props) => (props.hasAgreements ? '1rem' : '0')};
    grid-column-gap: 20px;
`;

export const BrandInfoItemText = styled.div``;

export const BrandInfoItemHeader = styled.p`
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 14px;
    font-weight: 500;
`;

export const BrandInfoItemDescription = styled.p`
    letter-spacing: 0;
    line-height: 25px;
`;

export const BrandInfoDescription = styled.p`
    margin-bottom: 1rem;
`;

export const ButtonWrapper = styled.div`
    align-self: flex-start;
`;
