import styled from 'styled-components';
import { animated } from '@react-spring/web';

export const StyledInfoBox = styled.section`
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    height: calc(100% - 25px);
    width: 484px;
    background-color: #ffffff;
    isolation: isolate;
    z-index: 10;
    overflow-y: auto;
    scroll-behavior: smooth;
    scrollbar-width: thin;
    scrollbar-gutter: stable;

    & > article:not(:last-child) {
        border-bottom: 1px solid hsla(0, 0%, 85%, 0.3);
    }
`;

export const AnimateStyledInfoBox = styled(animated.section)`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #ffffff;
    isolation: isolate;
    z-index: 10;
    overflow-y: auto;
    scroll-behavior: smooth;
    scrollbar-width: thin;
    scrollbar-gutter: stable;

    & > article:not(:last-child) {
        border-bottom: 1px solid hsla(0, 0%, 85%, 0.3);
    }
`;

export const StyledScrollIndicator = styled.div`
    position: sticky;
    top: 0;
    backdrop-filter: saturate(180%) blur(20px);
    background-color: rgb(247, 247, 248, 0.72);
    padding: 30px;
    z-index: 20;
    isolation: isolate;
`;
