import styled from 'styled-components';
import { device } from '../../../lib/media-query';
import { SpotHeader } from '../../shared/typography/spot-header/spot-header.component';

const maxWidth = '650px';

const getSpacing = (small?: boolean): string => {
    if (small) {
        return '24px';
    } else {
        return '56px';
    }
};

export const StyledSpotSpacingContainer = styled.div`
    padding: 32px 0;
    @media ${device.tablet} {
        padding: 70px 0;
    }
`;

export const StyledSpotHeader = styled(SpotHeader)`
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    margin: 0 auto 12px;
    max-width: ${maxWidth};
    white-space: pre-line;

    @media ${device.laptop} {
        color: ${({ theme }) => theme.palette.text.primary};
        max-width: none;
    }
`;

export const StyledText = styled.div`
    white-space: pre-line;
    margin-top: 1em;
    margin: 0 auto;
    max-width: ${maxWidth};

    @media ${device.laptop} {
        font-size: 18px;
        max-width: none;
    }
`;

export const StyledNumberedList = styled.ol`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 100%;
    padding-left: 18px;
    max-width: ${maxWidth};
    margin: 32px auto 0;

    @media ${device.laptop} {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-auto-rows: 1fr;
        margin-top: 48px;
        max-width: none;
        padding-left: 28px;
    }

    @media ${device.laptopS} {
        gap: 48px;
    }
`;

export const StyledNumberedListItem = styled.li`
    background-color: #e6e6e6;
    box-sizing: border-box;
    align-items: flex-start;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: ${getSpacing(true)} 32px ${getSpacing(true)} 56px;
    position: relative;
    width: 100%;

    @media ${device.mobile_tablet} {
        padding-left: ${getSpacing()};
    }

    @media ${device.laptop} {
        padding-left: 60px;
    }
`;

export const StyledItemNumber = styled.div`
    background-color: ${({ theme }) => theme.palette.primary.main};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    position: absolute;
    left: 0;
    top: calc(${getSpacing(true)} - 4px);
    aspect-ratio: 1 / 1;
    transform: translateX(-40%);
    width: 46px;
    line-height: 1;

    @media ${device.mobile_tablet} {
        top: ${getSpacing(true)};
        width: 56px;
    }

    @media ${device.laptop} {
        transform: translateX(-30%);
    }

    @media ${device.laptopS} {
        transform: translateX(-45%);
    }
`;

export const StyledItemHeader = styled.p`
    font-size: 22px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    white-space: pre-line;

    @media ${device.laptop} {
        color: ${({ theme }) => theme.palette.text.primary};
    }
`;

export const StyledItemText = styled.p`
    line-height: 1.4;
    margin-top: 12px;
    min-height: 50px;
    font-size: 14px;
`;

export const StyledTileBottomContent = styled.div`
    margin-top: 10px;
`;

export const StyledTileText = styled.p`
    font-size: 18px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    line-height: 1.2;

    & + a {
        margin-top: 0.5em;
    }
`;

export const StyledTileButton = styled.button`
    background-color: transparent;
    border: none;
    min-height: 2.5em;
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.text.primary};
    padding: 0;
    text-align: left;
    cursor: pointer;
`;
