import { VFC } from 'react';
import { BrandAndServiceModuleProps } from '../../brand-and-service-module/brand-and-service-module.component';
import { BrandAndService } from '../../brand-and-service/desktop/brand-and-service.component';
import { HeaderText, StyledBrandAndServiceTable, StyledHeaderRow, StyledTableColumn } from './desktop.styled';

export const BrandAndServiceTableDesktop: VFC<Pick<BrandAndServiceModuleProps, 'supportedBrands'>> = ({ supportedBrands }) => {
    return (
        <StyledBrandAndServiceTable>
            <StyledHeaderRow>
                <StyledTableColumn />
                <StyledTableColumn>
                    <HeaderText>Salg</HeaderText>
                </StyledTableColumn>
                <StyledTableColumn>
                    <HeaderText>Værksted</HeaderText>
                </StyledTableColumn>
            </StyledHeaderRow>
            {supportedBrands.map((x, index) => {
                return <BrandAndService key={`${x.brand}:${index}`} brandInfo={x} />;
            })}
        </StyledBrandAndServiceTable>
    );
};
