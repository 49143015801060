import styled from 'styled-components';
import { device } from '../../../../lib/media-query';
import { SpotHeader } from '../../../shared/typography/spot-header/spot-header.component';

export const StyledBundleSpot = styled.section`
    background-color: #f7f7f8;
    padding: 46px 0;
`;

export const StyledHeader = styled.h2`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: 15px;
    line-height: 24px;
`;

export const StyleBundleSpotHeader = styled(SpotHeader)`
    margin-bottom: 15px;
`;

export const StyledDescription = styled.p`
    margin-bottom: 15px;
    font-size: 14px;
`;

export const FoundText = styled.p`
    color: #807f80;
    font-size: 17px;
    letter-spacing: 0;
    margin-bottom: 30px;
`;

export const StyledResult = styled.span`
    color: #0b0b0b;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0;
`;

export const BundleCardsWrapper = styled.div`
    display: grid;
    justify-items: center;
    gap: 20px;

    @media ${device.tablet} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media ${device.laptop} {
        grid-template-columns: repeat(3, 1fr);
    }

    @media ${device.laptopS} {
        grid-template-columns: repeat(4, 1fr);
    }
`;

export const StyledCarouselWrapper = styled.div`
    @media ${device.tablet} {
        margin-top: -50px;
    }
`;
