import { FC, useState } from 'react';
import { sharedTypes, UmbracoForm } from '../../../../lib/api/models/umbraco';
import { Button } from '../../../shared';
import { SidePanel } from '../../../shared/modals/side-panel';
import { SidePanelLayout } from '../../../shared/modals/side-panel-layout/side-panel-layout.component';
import { RichTextRenderer } from '../../../shared/rich-text-renderer/rich-text-renderer.component';
import { UmbracoForms } from '../../../spots/umbraco-forms';
import { StyledHeader, ButtonWrapper, NoResultsImage, NoResultsText, NoResultsWrapper } from './vehicle-product-list-no-results.styled';

type FormContent = {
    form: UmbracoForm | undefined;
    header: string;
    subText: string;
};

type IProps = {
    noResultsText: string;
    image?: sharedTypes.Image;
    clearFiltersText: string;
    contactForm?: FormContent;
    contactCTAText: string;
    pageId: string;
    resetFilters: () => void;
    noResultHeader: string;
    filterTiles?: React.ReactNode;
};

const MEDIA_URL = process.env.NEXT_PUBLIC_MEDIA_URL;

export const VehicleProductListNoResults: FC<IProps> = ({
    noResultsText,
    image,
    clearFiltersText,
    contactForm,
    contactCTAText,
    pageId,
    resetFilters,
    noResultHeader,
    filterTiles,
}) => {
    const [contactModalOpen, setContactModalOpen] = useState(false);

    return (
        <>
            {filterTiles ? filterTiles : null}
            <NoResultsWrapper>
                <StyledHeader>{noResultHeader}</StyledHeader>
                {image && <NoResultsImage src={`${MEDIA_URL}/${image.src}`} alt={image.name} style={{ maxWidth: '100%' }} />}
                <RichTextRenderer>
                    <NoResultsText dangerouslySetInnerHTML={{ __html: noResultsText }}></NoResultsText>
                </RichTextRenderer>
                <ButtonWrapper>
                    <Button variant="primary" onClick={() => setContactModalOpen(true)}>
                        {contactCTAText}
                    </Button>
                    <Button onClick={() => resetFilters()}>{clearFiltersText}</Button>
                </ButtonWrapper>
                {contactForm && contactForm.form ? (
                    <SidePanel isVisible={contactModalOpen} cancelAction={() => setContactModalOpen(false)}>
                        <SidePanelLayout closeSidePanel={() => setContactModalOpen(false)}>
                            <UmbracoForms form={contactForm.form} pageId={pageId} header={contactForm.header} subtext={contactForm.subText} />
                        </SidePanelLayout>
                    </SidePanel>
                ) : null}
            </NoResultsWrapper>
        </>
    );
};

export default VehicleProductListNoResults;
