import { FC } from 'react';
import { StyledKeyValueTable, StyledKeyValueTableBody } from './key-value-table.styled';

export type KeyValueTableProps = {
    className?: string;
};

export const KeyValueTable: FC<KeyValueTableProps> = (props) => {
    return (
        <StyledKeyValueTable className={props.className}>
            <StyledKeyValueTableBody>{props.children}</StyledKeyValueTableBody>
        </StyledKeyValueTable>
    );
};
