import { createContext, FC, useContext, useState } from 'react';
import { handleOverflow } from '../../utils/helpers';

export interface StateValue {
    mobileMenuOpen: boolean;
}

export interface StateModifiers {
    removeScroll: (lockBodyScroll?: boolean) => void;
    removeScrollAndLock: () => void;
    applyScroll: () => void;
    forceApplyScroll: () => void;
    toggleMobileMenu: (value: boolean) => void;
}

type State = StateModifiers & StateValue;

const initialState = {
    mobileMenuOpen: false,
};

const stateModifiers = {
    removeScroll: () => {
        return;
    },
    removeScrollAndLock: () => {
        return;
    },
    applyScroll: () => {
        return;
    },
    forceApplyScroll: () => {
        return;
    },
    toggleMobileMenu: () => {
        return;
    },
};

const UiContext = createContext<State>({
    ...initialState,
    ...stateModifiers,
});

export const UiProvider: FC = ({ children }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [scrollY, setScrollY] = useState<number | undefined>(undefined);

    const removeScroll = () => handleOverflow(true, menuOpen);

    const removeScrollAndLock = () => {
        setScrollY(-window.scrollY);
        handleOverflow(true, true);
    };

    const forceApplyScroll = () => {
        handleOverflow(false);

        if (scrollY) {
            window.scrollTo(0, (scrollY ?? 0) * -1);
            setScrollY(undefined);
        }
    };

    const applyScroll = () => {
        if (!menuOpen) {
            handleOverflow(false);
        }

        if (scrollY) {
            window.scrollTo(0, (scrollY ?? 0) * -1);
            setScrollY(undefined);
        }
    };
    const toggleMobileMenu = (value: boolean) => setMenuOpen(value);

    const value = {
        removeScroll,
        removeScrollAndLock,
        applyScroll,
        forceApplyScroll,
        mobileMenuOpen: menuOpen,
        toggleMobileMenu,
    };

    return <UiContext.Provider value={value}>{children}</UiContext.Provider>;
};

export const useUI = (): State => {
    const context = useContext(UiContext);

    if (context === undefined) {
        throw new Error('useUi must be used within the UiProvider');
    }

    return context;
};
