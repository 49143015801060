import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { submitLeadData } from '../../../lib/api/lead/lead-api';
import { PdpContactLead } from '../../../lib/api/lead/lead-api.types';
import { usePdpLeadSubmitData } from '../hooks/use-pdp-lead-submit-data';
import { ProductDetailsStore } from '../../../lib/state/hessel-site/product-details';
import { useRelewiseTracking } from '../../../hooks/relewise/use-relewise-tracking';

type FormInput = {
    value: string;
    canValidate: boolean;
    isValid: boolean;
};

type FormConsent = {
    value: boolean;
    canValidate: boolean;
};

type FormValues = {
    name: FormInput;
    phone: FormInput;
    email: FormInput;
    departmentName: FormInput;
    departmentId: FormInput;
    comment: FormInput;
    companyName: FormInput;
    dataConsent: FormConsent;
    marketingConsent: FormConsent;
};

type ContactPdpForm = {
    formValues: FormValues;
    setFormValues: Dispatch<SetStateAction<FormValues>>;
    submitForm: () => void;
    resetForm: () => void;
    formSubmitted: boolean;
};

export type ContactPdpFormProps = {
    departmentName: string;
    departmentId: string;
    consentText: string;
    trackingPrice: number;
};

export const useContactPdpForm = (props: ContactPdpFormProps): ContactPdpForm => {
    const { vehicleToSubmit } = usePdpLeadSubmitData();
    const { selectedProduct } = ProductDetailsStore.useStoreState((state) => state);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const defaultFormState = {
        name: {
            value: '',
            canValidate: false,
            isValid: false,
        },
        comment: {
            value: '',
            canValidate: false,
            isValid: false,
        },
        email: {
            value: '',
            canValidate: false,
            isValid: false,
        },
        departmentId: {
            value: props.departmentId,
            canValidate: false,
            isValid: props.departmentId.length > 0,
        },
        departmentName: {
            value: props.departmentName,
            canValidate: false,
            isValid: props.departmentName.length > 0,
        },
        phone: {
            value: '',
            canValidate: false,
            isValid: false,
        },
        companyName: {
            value: '',
            canValidate: false,
            isValid: true,
        },
        dataConsent: {
            value: false,
            canValidate: false,
        },
        marketingConsent: {
            value: false,
            canValidate: false,
        },
    };

    const [formValues, setFormValues] = useState<FormValues>(defaultFormState);

    const { trackVehicleConversion } = useRelewiseTracking();

    useEffect(() => {
        setFormValues({
            ...formValues,
            departmentId: {
                value: props.departmentId,
                canValidate: false,
                isValid: props.departmentId.length > 0,
            },
            departmentName: {
                value: props.departmentName,
                canValidate: false,
                isValid: props.departmentName.length > 0,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const submitForm = async () => {
        if (
            formValues.comment.isValid &&
            formValues.departmentId.isValid &&
            formValues.departmentName.isValid &&
            formValues.email.isValid &&
            formValues.name.isValid &&
            formValues.phone.isValid &&
            formValues.dataConsent.value &&
            vehicleToSubmit !== null
        ) {
            await submitLeadData<PdpContactLead>('contact-us', {
                name: formValues.name.value,
                email: formValues.email.value,
                phone: formValues.phone.value,
                vehicle: vehicleToSubmit,
                departmentId: Number(formValues.departmentId.value),
                departmentName: formValues.departmentName.value,
                url: selectedProduct?.url ?? '',
                consent: formValues.marketingConsent.value,
                consentText: props.consentText,
                comment: formValues.comment.value,
                companyName: formValues.companyName.value,
            });
            trackVehicleConversion(vehicleToSubmit.entityId, props.trackingPrice);
            setFormSubmitted(true);
        } else {
            setFormValues({
                name: {
                    ...formValues.name,
                    canValidate: true,
                },
                comment: {
                    ...formValues.comment,
                    canValidate: true,
                },
                departmentId: {
                    ...formValues.departmentId,
                    canValidate: true,
                },
                departmentName: {
                    ...formValues.departmentName,
                    canValidate: true,
                },
                email: {
                    ...formValues.email,
                    canValidate: true,
                },
                phone: {
                    ...formValues.phone,
                    canValidate: true,
                },
                dataConsent: {
                    ...formValues.dataConsent,
                    canValidate: true,
                },
                marketingConsent: {
                    ...formValues.marketingConsent,
                    canValidate: true,
                },
                companyName: {
                    ...formValues.companyName,
                    canValidate: true,
                },
            });
        }
    };

    const resetForm = () => {
        setFormValues(defaultFormState);
        setFormSubmitted(false);
    };

    return {
        formValues,
        setFormValues,
        submitForm,
        formSubmitted,
        resetForm,
    };
};
