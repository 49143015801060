import { FC } from 'react';
import {
    StyledNotPartOfCurrentSearchTerm,
    StyledRecommendedSearchWord,
    StyledRecommendedSearchWordsHeader,
    StyledSearchWordWrapper,
} from './recommended-search-words.styled';
import { SvgIcon } from '../../shared/svg-icon';
import { SearchStoreContext } from '../../../lib/state/search/search.store';

export const RecommendedSearchWords: FC = () => {
    const { searchPredictionResults, searchTerm, popularSearchTerms } = SearchStoreContext.useStoreState((state) => state);
    const { setSearchTerm } = SearchStoreContext.useStoreActions((actions) => actions);

    return (
        <div>
            <StyledRecommendedSearchWordsHeader>
                {searchPredictionResults.length > 0 ? 'Foreslåede søgeord' : 'Populære søgeord'}
            </StyledRecommendedSearchWordsHeader>
            <StyledSearchWordWrapper>
                {searchPredictionResults.length > 0
                    ? searchPredictionResults.map((prediction) => {
                          if (prediction.term !== undefined && prediction.term !== null && (prediction.term as string).length !== 0) {
                              const predictionString = prediction.term as string;
                              const predictionStartsWithSearchTerm = predictionString.startsWith(searchTerm);
                              const searchTermSplit = predictionString.split(searchTerm);
                              return (
                                  <StyledRecommendedSearchWord key={predictionString} onClick={() => setSearchTerm(predictionString)}>
                                      <SvgIcon iconName="search" />
                                      {predictionStartsWithSearchTerm && searchTermSplit.length > 1 ? (
                                          <span>
                                              {searchTerm}
                                              <StyledNotPartOfCurrentSearchTerm>{searchTermSplit[1]}</StyledNotPartOfCurrentSearchTerm>
                                          </span>
                                      ) : (
                                          <StyledNotPartOfCurrentSearchTerm>{predictionString}</StyledNotPartOfCurrentSearchTerm>
                                      )}
                                  </StyledRecommendedSearchWord>
                              );
                          }
                          return null;
                      })
                    : popularSearchTerms.map((popularSearchTerm) => {
                          if (popularSearchTerm.term !== undefined && popularSearchTerm.term !== null && popularSearchTerm.term.length !== 0) {
                              const termAsString = popularSearchTerm.term as string;
                              return (
                                  <StyledRecommendedSearchWord key={termAsString} onClick={() => setSearchTerm(termAsString)}>
                                      <span>{termAsString}</span>
                                  </StyledRecommendedSearchWord>
                              );
                          }
                          return null;
                      })}
            </StyledSearchWordWrapper>
        </div>
    );
};
