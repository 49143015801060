import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const StyledBurgerLink = styled.a`
    text-decoration: none;
    font-family: 'Corporate E';
    font-weight: bold;
    padding: 20px 0;
    border-bottom: 1px solid #e2e2e4;
    color: #0b0b0b;
    display: block;
    font-size: 17px;
    &:hover {
        text-decoration: none;
    }
    &:first-of-type {
        border-top: 1px solid #e2e2e4;
    }
`;

export const StyledLinkWrapper = styled.div`
    margin: 20px 0 30px;
`;

export const StyledWrapper = styled.div`
    background-color: white;
    padding: 20px;
    @media ${device.laptopS} {
        padding: 50px;
    }

    @media ${device.tablet} {
        height: 100%;
    }
`;

export const StyledActionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

export const StyledCloseIcon = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
`;

export const StyledCloseText = styled.p`
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
`;
