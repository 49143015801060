import styled from 'styled-components';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';

export const StyledCenteredBlock = styled(CenteredBlock)`
    padding-top: 60px;
    padding-bottom: 60px;
`;

export const StyledCustomerPortalBackground = styled.div`
    background-color: #eee;
`;
