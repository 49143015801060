import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const BorderBox = styled.div`
    color: #0b0b0b;
    font-size: 14px;
    letter-spacing: 0;
    position: relative;
    overflow: auto;
`;

export const Table = styled.table`
    width: 100%;
    border-spacing: 0;
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
    & > tr:nth-of-type(1) > td,
    & > tr:nth-of-type(1) > th {
        border-top: 1px solid #d1d2d4;
    }
`;

export const Th = styled.th`
    min-width: 150px;
`;

export const StickyTh = styled.th`
    position: sticky;
    left: 0px;
    min-width: 200px;
    text-align: left;
    background-color: inherit;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);

    @media ${device.tablet} {
        max-width: 370px;
    }

    @media ${device.laptopS} {
        box-shadow: none;
    }

    z-index: 2;
`;

export const StickyThForRow = styled(StickyTh)<{ rowPadding: string }>`
    padding: ${(props) => props.rowPadding};
`;

export const Tr = styled.tr`
    background-color: #f7f7f8;
    &:nth-child(even) {
        background: #f1f1f2;
    }

    & > td:not(:first-child):not(:last-child) {
        border-right: 1px solid #d1d2d4;
    }
    & > td:nth-child(2) {
        border-left: 1px solid #d1d2d4;
    }
`;

export const Td = styled.td`
    text-align: center;
    min-width: 150px;

    @media ${device.desktop} {
        min-width: revert;
    }
`;

export const StyledCellText = styled.p`
    color: #0a2841;
    font-size: 12px;
    letter-spacing: 0;
`;

export const ColumnTextWrapper = styled.div`
    padding-bottom: 20px;
`;

export const ColumnHeader = styled.p`
    color: #0b0b0b;
    font-weight: 600;
    letter-spacing: 0;
    font-size: 12px;
    @media ${device.tablet} {
        font-size: 13px;
    }
`;

export const ColumnSubHeader = styled.p`
    color: #0b0b0b;
    letter-spacing: 0;
    font-weight: normal;
    line-height: 23px;
    font-size: 12px;
    @media ${device.tablet} {
        font-size: 13px;
    }
`;

export const StyledIcon = styled.img`
    max-width: 17px;
`;
