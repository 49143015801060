import styled from 'styled-components';

export const StyledText = styled.p`
    color: #0b0b0b;
    font-size: 14px;
    letter-spacing: 0;
`;

export const ButtonText = styled.span`
    font-size: inherit;
`;
