import { useEffect, useState } from 'react';
import { BaseFilterSetup } from '../lib/api/models/umbraco/content-spot';
import { FacetGroup, FacetOption } from '../lib/view-models/vehicle';
import { getProductList } from '../lib/api/vehicle/hessel-vehicle-plp-api';

type ReturnType = {
    validAvailabilities: FacetGroup | undefined;
};

export const useAvailabilityValidator = (
    availabilities: FacetGroup | undefined,
    baseFilter: BaseFilterSetup,
    filterConfiguration: string
): ReturnType => {
    const [validAvailabilityOptions, setValidAvailabilityOptions] = useState<FacetOption[]>([]);
    const [requestProcessing, setRequestProcessing] = useState<boolean>(false);

    const category = baseFilter.alias === 'carBaseFilter' ? 'Car' : 'Van';

    useEffect(() => {
        const validateAvailabilities = async () => {
            const acc: FacetOption[] = [];
            if (availabilities && !requestProcessing) {
                setRequestProcessing(true);
                for (const x of (availabilities as FacetGroup).facetOptions) {
                    if (x.key === baseFilter.defaultAvailability.toString()) {
                        acc.push(x);
                    } else {
                        const [response, error] = await getProductList(
                            {
                                configurationId: filterConfiguration,
                                filters: [`${(availabilities as FacetGroup).key}__${x.key}`],
                                from: 0,
                                take: 0,
                            },
                            category
                        );
                        if (!error && response && response.total > 0) {
                            acc.push(x);
                        }
                    }
                }
                setRequestProcessing(false);
            }
            setValidAvailabilityOptions(acc);
        };
        validateAvailabilities();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availabilities]);

    if (availabilities) {
        return {
            validAvailabilities: { ...availabilities, facetOptions: validAvailabilityOptions },
        };
    }
    return {
        validAvailabilities: undefined,
    };
};
