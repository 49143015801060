import { FC, useEffect, useMemo, useState } from 'react';
import {
    FilterWrapper,
    FilterContainer,
    FilterHeader,
    ResetFilters,
    FilterHeaderText,
    MobileFilterToggle,
    MobileFilterToggleButton,
    MobileFilterIndicator,
} from './vehicle-product-list-filter.styled';
import { SvgIcon } from '../../../shared/svg-icon';
import { FilterGroup } from './filter-group';
import { useUI } from '../../../ui';
import { VehicleProductListMobileFilters } from '.';
import { FilterGroupLabel, SortingLabel } from '../../../../lib/api/models/umbraco';
import { FacetGroup } from '../../../../lib/view-models/vehicle';
import { FilterUpdateNotification } from '../../../../lib/api/models/hessel-api/hire';
import { getFilterRenderType } from './filter.helpers';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import { toggleChatBot } from '../../../../utils/helpers';
import { DepartmentFilterResponse } from '../../../../lib/api/organization/hessel-dealership-api';
import { PriceFilter } from './price-filter/price-filter.component';
import { PaymentType } from '../../../../lib/api/models/umbraco/content-spot';
import {
    CashPriceFilterKey,
    FinancingPriceFilterKey,
    HirePriceFilterKey,
    PrivateLeasingPriceFilterKey,
    VanLeaseingPriceFilterKey,
    VanLeasingDownpaymentFilterKey,
} from '../../../../constants/site-consts';

type IProps = {
    inViewport: boolean;
    clearFiltersText: string;
    applyMobileFiltersText: string;
    closeMobileFilterOverlay: string;
    filterSectionHeader: string;
    filters?: FacetGroup[];
    filterLabels: FilterGroupLabel[];
    updateFilter: (filterUpdate: FilterUpdateNotification[]) => void;
    resetFilters: () => void;
    activeFiltersCount: number;
    sortOptions: SortingLabel[];
    selectedSortOptionValue: string;
    updateSorting: (sorting: string) => void;
    maximumSubfacetDepth?: number;
    departments?: DepartmentFilterResponse[];
    paymentType: PaymentType;
};

export const VehicleProductListFilter: FC<IProps> = ({
    inViewport,
    clearFiltersText,
    applyMobileFiltersText,
    closeMobileFilterOverlay,
    filters,
    updateFilter,
    resetFilters,
    filterSectionHeader,
    activeFiltersCount,
    sortOptions,
    selectedSortOptionValue,
    updateSorting,
    maximumSubfacetDepth,
    departments,
    paymentType,
}) => {
    const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
    const [sortingOpen, setSortingOpen] = useState(false);

    const uiHandler = useUI();

    const isDevice = useMediaQuery({ target: 'smallDesktop' });

    useEffect(() => {
        if (mobileFiltersOpen) {
            uiHandler.removeScroll();
        } else {
            uiHandler.applyScroll();
            setSortingOpen(false);
            toggleChatBot(true);
        }
    }, [mobileFiltersOpen, uiHandler]);

    const openSorting = () => {
        setMobileFiltersOpen(true);
        setSortingOpen(true);
    };

    const selectedSortOption = useMemo(() => {
        return sortOptions.find((x) => x.sortingKey === selectedSortOptionValue);
    }, [selectedSortOptionValue, sortOptions]);

    const cashPriceFacet = useMemo(() => filters?.find((x) => x.key === CashPriceFilterKey), [filters]);
    const finanincingPriceFacet = useMemo(() => filters?.find((x) => x.key === FinancingPriceFilterKey), [filters]);
    const hirePriceFacet = useMemo(() => filters?.find((x) => x.key === HirePriceFilterKey), [filters]);
    const privateLeasingFacet = useMemo(() => filters?.find((x) => x.key === PrivateLeasingPriceFilterKey), [filters]);

    const filtersWithCorrectPriceFilters = useMemo(() => {
        if (paymentType === 'Buy') {
            return filters?.filter(
                (x) =>
                    x.key !== HirePriceFilterKey &&
                    x.key !== FinancingPriceFilterKey &&
                    x.key !== VanLeaseingPriceFilterKey &&
                    x.key !== VanLeasingDownpaymentFilterKey
            );
        }
        if (paymentType === 'Leasing') {
            return filters?.filter((x) => x.key !== CashPriceFilterKey && x.key !== FinancingPriceFilterKey);
        }
        return filters;
    }, [filters, paymentType]);

    return (
        <>
            <FilterWrapper>
                <FilterContainer>
                    <FilterHeader>
                        <FilterHeaderText>{filterSectionHeader}</FilterHeaderText>
                        <ResetFilters onClick={() => resetFilters()}>{clearFiltersText}</ResetFilters>
                    </FilterHeader>

                    {!isDevice
                        ? filtersWithCorrectPriceFilters?.map((x) => {
                              if (x === cashPriceFacet && finanincingPriceFacet) {
                                  return (
                                      <PriceFilter
                                          key={paymentType}
                                          onChange={(filterUpdate) => updateFilter(filterUpdate)}
                                          priceOptions={[cashPriceFacet, finanincingPriceFacet]}
                                      />
                                  );
                              }
                              if (x === finanincingPriceFacet && cashPriceFacet) return null;
                              if (x === hirePriceFacet && privateLeasingFacet) {
                                  return (
                                      <PriceFilter
                                          key={paymentType}
                                          onChange={(filterUpdate) => updateFilter(filterUpdate)}
                                          priceOptions={[privateLeasingFacet, hirePriceFacet]}
                                      />
                                  );
                              }
                              if (x === privateLeasingFacet && hirePriceFacet) return null;
                              return (
                                  <FilterGroup
                                      key={x.key}
                                      header={x.label}
                                      filterRenderType={getFilterRenderType(x)}
                                      facet={x.facetOptions}
                                      onChange={(filterUpdate) => updateFilter(filterUpdate)}
                                      group={x}
                                      maximumSubfacetDepth={maximumSubfacetDepth}
                                      departments={departments}
                                  />
                              );
                          })
                        : null}
                </FilterContainer>
            </FilterWrapper>
            <MobileFilterToggle visible={inViewport}>
                <MobileFilterToggleButton onClick={() => setMobileFiltersOpen(true)}>
                    <SvgIcon iconName="filter" color="light" />
                    <span>Filter</span>
                    <MobileFilterIndicator>{activeFiltersCount}</MobileFilterIndicator>
                </MobileFilterToggleButton>
                {sortOptions.length > 0 ? (
                    <MobileFilterToggleButton onClick={() => openSorting()}>
                        <span>Sorter</span>
                        <MobileFilterIndicator>{selectedSortOption?.labelText}</MobileFilterIndicator>
                    </MobileFilterToggleButton>
                ) : null}
            </MobileFilterToggle>
            {isDevice ? (
                <VehicleProductListMobileFilters
                    setOpenState={(state: boolean) => setMobileFiltersOpen(state)}
                    openState={mobileFiltersOpen}
                    applyMobileFiltersText={applyMobileFiltersText}
                    closeMobileFilterOverlay={closeMobileFilterOverlay}
                    clearFiltersText={clearFiltersText}
                    facets={filters ?? []}
                    resetFilters={() => resetFilters()}
                    updateFilters={(updates) => updateFilter(updates)}
                    selectedSortOption={selectedSortOption ? selectedSortOption.labelText : 'Vælg sortering'}
                    sortingOptions={sortOptions}
                    sortingOpenExternal={sortingOpen}
                    updateSorting={(sorting) => updateSorting(sorting)}
                    maximumSubfacetDepth={maximumSubfacetDepth}
                    departments={departments}
                    paymentType={paymentType}
                />
            ) : null}
        </>
    );
};

export default VehicleProductListFilter;
