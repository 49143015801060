import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const StyledHeader = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: 15px;
`;

export const NoResultsWrapper = styled.div`
    margin-top: 40px;
`;

export const NoResultsText = styled.div`
    margin-bottom: 30px;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    gap: 15px;
    flex-direction: column;
    @media ${device.mobileL} {
        flex-direction: row;
    }
`;

export const NoResultsImage = styled.img`
    margin-bottom: 30px;
`;

export const Close = styled.div`
    display: flex;
    align-content: center;
    width: min-content;
    cursor: pointer;
    margin-left: auto;
    transform: translate(-25px, 25px);
`;
