import { VFC } from 'react';
import { sanitizeMarkup } from '../../../../../../utils/helpers';
import { SidePanel } from '../../../../../shared/modals/side-panel';
import { SvgIcon } from '../../../../../shared/svg-icon';
import { AllContent, SidebarHeader, CloseButton, StyledHeaderAndDescription, StyledDescription } from './generic-benefits-dialog.styled';

type GenericBenefitsDialogProps = {
    visible: boolean;
    header: string;
    description: string;
    hideSidebar: () => void;
};

export const GenericBenefitsDialog: VFC<GenericBenefitsDialogProps> = ({ visible, header, description, hideSidebar }) => {
    return (
        <SidePanel
            isVisible={visible}
            cancelAction={() => {
                hideSidebar();
            }}
        >
            <AllContent>
                <CloseButton
                    onClick={() => {
                        hideSidebar();
                    }}
                >
                    <SvgIcon iconName={'close'} />
                </CloseButton>

                <StyledHeaderAndDescription>
                    <SidebarHeader>{header}</SidebarHeader>

                    <StyledDescription {...sanitizeMarkup(description)} />
                </StyledHeaderAndDescription>
            </AllContent>
        </SidePanel>
    );
};
