import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const StyledUspTemplate = styled.article<{ clickable: boolean }>`
    display: grid;
    grid-template-rows: repeat(4, min-content);
    justify-items: center;
    align-items: center;
    width: 100%;
    gap: 10px;
    cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};

    @media ${device.tablet} {
        aspect-ratio: 1 / 1;
        max-height: 200px;
        width: 300px;
    }
`;

export const ImageWrapper = styled.div``;

export const TopSideIcon = styled.img`
    display: block;
    max-inline-size: 100%;
    width: 70px;
    block-size: auto;
    object-fit: contain;
    object-position: center;
`;

export const BottomSideIcon = styled(TopSideIcon)`
    width: unset;
    max-width: 50px;
`;

export const Header = styled.p`
    color: #0b0b0b;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
    margin-top: 5px;
`;

export const Description = styled.p`
    font-size: 14px;
    letter-spacing: 0;
    text-align: center;
    font-weight: normal;
`;
