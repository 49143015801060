import styled from 'styled-components';

export const StyledHeader = styled.p`
    color: #0b0b0b;
    font-family: 'Corporate E';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 16px;
`;

export const StyledDescription = styled.p`
    color: #0b0b0b;
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    font-size: 14px;
    letter-spacing: 0;
    line-height: 23px;
    font-weight: normal;
    margin-bottom: 18px;
`;

export const StyledAccordionTitle = styled.p`
    color: #0b0b0b;
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
`;

export const AccordionContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5.5px;
    padding: 10px 0;
    width: 91%;
`;

export const StyledOrgPhone = styled.p`
    color: #aeaeaf;
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    font-size: 14px;
    letter-spacing: 0;
    font-weight: normal;
`;

export const StyledLabel = styled.span`
    color: #0b0b0b;
`;

export const PhoneAndDepartmentDropdown = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;
