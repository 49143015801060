import styled from 'styled-components';

export const StyledReceiptWrapper = styled.div`
    margin-top: 25px;
`;

export const StyledReceiptHeader = styled.p`
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 5px;
`;
