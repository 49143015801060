import { FC, useMemo, useState } from 'react';
import { BookingFormStore } from '../../../../../lib/state/booking/form';
import { calculateDiscount, getFormattedValue } from '../../../../../utils/helpers';
import { ServiceCard } from '../service-card';
import { CardAgreements } from '../../your-car/agreements';
import { BookingInputs } from '../../../inputs';
import { CheckBox } from '../../../../shared/checkbox';
import { Separator } from '../../../../shared/separator';
import {
    Wrapper,
    HeaderSection,
    ServiceHeader,
    SubTitle,
    SubTitleText,
    CurrentPrice,
    Link,
    CurrentPriceMobile,
    PriceAndService,
    ServiceAndNote,
    PriceAndBadge,
    DiscountBadge,
    DiscountBadgeContent,
    PricesWrapper,
    BeforePrice,
    BeforePriceMobile,
    SubTitleLink,
    Container,
    StyledDescription,
    SubProductsStyledHeader,
    YourPriceWrapper,
    StyledButtonContent,
    DrivenKilometersContainer,
    OptionalTextContainer,
    SubProductsHeader,
    SubProductsSection,
    SeparatorContainer,
    SubProductsSeparatorContainer,
    HeaderAndBadge,
    BeforePriceAndPrice,
    DisabledText,
    YourPriceLink,
} from './service-card-item.styled';
import { TirehotelCheckbox } from './tirehotel-checkbox/tirehotel-checkbox.component';
import ProductOptions from '../product-options/product-options.component';
import { ServiceProductOption } from '../../../../../lib/api/models/umbraco';
import { ServiceProduct, ServiceProductPrice } from '../../../../../lib/api/models/hessel-api';
import { umbraco } from '../../../../../lib/api';

type Props = {
    title: string;
    currentPriceLabel?: string;
    link?: { name: string; url: string };
    isSelected: boolean;
    onServiceSelected: (serviceId: string) => void;
    onYoutubeModalLinkClicked?: (watchId: string) => void;
    serviceProductOption?: ServiceProductOption;
    price?: ServiceProductPrice;
    content: umbraco.BookingStepServices;
    isServiceInspection?: boolean;
    serviceProduct: ServiceProduct;
    isDisabled?: boolean;
};

export const ServiceCardItem: FC<Props> = ({
    title,
    isSelected,
    currentPriceLabel,
    onServiceSelected,
    onYoutubeModalLinkClicked,
    serviceProductOption,
    link,
    content,
    price,
    isServiceInspection,
    serviceProduct,
    isDisabled = false,
}) => {
    const [showAgreements, setShowAgreements] = useState(false);

    const { drivenKilometersInputs } = BookingFormStore.useStoreState(({ inputs }) => ({
        drivenKilometersInputs: inputs.filter((input) => input.step === 'Services'),
    }));

    const getYourPriceText = useMemo(() => {
        if (price?.discountType === 'benefitAgreement') {
            const yourPriceText = content?.benefitAgreementYourPriceText;
            if (yourPriceText === undefined) return null;

            const [beforeLinkText, afterLinkText] = yourPriceText.split('{{link}}');
            const linkText = content?.benefitAgreementYourPriceLinkText;

            return (
                <>
                    {beforeLinkText}
                    <YourPriceLink
                        onClick={(e) => {
                            e.stopPropagation();
                            if (!isDisabled) setShowAgreements(true);
                        }}
                        variant="link"
                        isDisabled={isDisabled}
                    >
                        <StyledButtonContent>{linkText}</StyledButtonContent>
                    </YourPriceLink>
                    {afterLinkText}
                </>
            );
        }

        if (content?.promotionCodeYourPriceText === undefined) return null;
        return <>{content.promotionCodeYourPriceText}</>;
    }, [
        content?.benefitAgreementYourPriceLinkText,
        content?.benefitAgreementYourPriceText,
        content.promotionCodeYourPriceText,
        isDisabled,
        price?.discountType,
    ]);

    const shouldShowTirehotelCheckbox = useMemo(
        () => content?.showTirehotelOnProductIds?.some((x) => x.productId === serviceProduct.id),
        [content?.showTirehotelOnProductIds, serviceProduct.id]
    );

    const subProductsWithoutTirehotel = useMemo(() => {
        const products = serviceProduct.subProducts?.filter(({ id }) => content.tirehotelProductIds?.every(({ productId }) => id !== productId));

        return products ?? [];
    }, [content.tirehotelProductIds, serviceProduct.subProducts]);

    return (
        <Wrapper isDisabled={isDisabled}>
            <CardAgreements
                isVisible={showAgreements}
                cancelAction={() => {
                    setShowAgreements(false);
                }}
            />
            <PriceAndService>
                <HeaderSection>
                    <ServiceAndNote
                        isDisabled={isDisabled}
                        onClick={(e) => {
                            e.stopPropagation();
                            onServiceSelected(serviceProduct.id);
                        }}
                    >
                        <PriceAndBadge>
                            {isDisabled ? (
                                <HeaderAndBadge isDisabled={isDisabled}>
                                    <ServiceHeader isSelected={isSelected} isDisabled={isDisabled}>
                                        {title}
                                    </ServiceHeader>
                                    {price?.beforeValue != null && price?.value != null ? (
                                        <DiscountBadge isDisabled={isDisabled}>
                                            <DiscountBadgeContent>{-calculateDiscount(price.value, price.beforeValue)}%</DiscountBadgeContent>
                                        </DiscountBadge>
                                    ) : null}
                                </HeaderAndBadge>
                            ) : (
                                <CheckBox
                                    id={serviceProduct.id}
                                    checked={isSelected}
                                    value={serviceProduct.id}
                                    onChange={() => onServiceSelected(serviceProduct.id)}
                                    expandLabel={true}
                                    textAlign="top"
                                >
                                    <HeaderAndBadge isDisabled={isDisabled}>
                                        <ServiceHeader isSelected={isSelected} isDisabled={isDisabled}>
                                            {title}
                                        </ServiceHeader>
                                        {price?.beforeValue != null && price?.value != null ? (
                                            <DiscountBadge>
                                                <DiscountBadgeContent>{-calculateDiscount(price.value, price.beforeValue)}%</DiscountBadgeContent>
                                            </DiscountBadge>
                                        ) : null}
                                    </HeaderAndBadge>
                                </CheckBox>
                            )}
                            <BeforePriceAndPrice>
                                {price?.beforeValue != null ? (
                                    <>
                                        {price?.value != null ? <YourPriceWrapper>{getYourPriceText}</YourPriceWrapper> : null}
                                        <BeforePriceMobile>{getFormattedValue(price.beforeValue)} kr.</BeforePriceMobile>
                                    </>
                                ) : null}
                                <CurrentPriceMobile>{currentPriceLabel}</CurrentPriceMobile>
                            </BeforePriceAndPrice>
                        </PriceAndBadge>

                        <SubTitle>
                            <SubTitleText>
                                {isDisabled ? (
                                    <DisabledText>
                                        <strong>For at undgå forvirring</strong>, har vi deaktiveret denne ydelse, da du har valgt en anden ydelse,
                                        der er sammenlignelig med denne.
                                    </DisabledText>
                                ) : (
                                    serviceProduct.description
                                )}
                                {serviceProductOption?.youTubeId && (
                                    <>
                                        {' '}
                                        <SubTitleLink
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onYoutubeModalLinkClicked && onYoutubeModalLinkClicked(serviceProductOption.youTubeId);
                                            }}
                                        >
                                            {serviceProductOption.linkText}
                                        </SubTitleLink>
                                    </>
                                )}
                            </SubTitleText>
                        </SubTitle>
                    </ServiceAndNote>

                    {currentPriceLabel && (
                        <PricesWrapper>
                            {price?.beforeValue ? <BeforePrice>{getFormattedValue(price.beforeValue)} kr</BeforePrice> : <div></div>}
                            <CurrentPrice>{currentPriceLabel}</CurrentPrice>
                        </PricesWrapper>
                    )}

                    {link && (
                        <Link href={link.url} target="_blank">
                            {link.name}
                        </Link>
                    )}
                </HeaderSection>
            </PriceAndService>

            {isSelected ? (
                <>
                    <Container>
                        {shouldShowTirehotelCheckbox ? (
                            <TirehotelCheckbox onVideoLinkClicked={(watchId) => onYoutubeModalLinkClicked && onYoutubeModalLinkClicked(watchId)} />
                        ) : null}

                        {isServiceInspection ? (
                            <SeparatorContainer>
                                <Separator marginTop="0" marginBottom="15px" />
                            </SeparatorContainer>
                        ) : null}

                        {serviceProduct.productOptions?.some(() => true) ? <ProductOptions serviceProduct={serviceProduct} /> : null}

                        {isServiceInspection && drivenKilometersInputs?.length > 0 ? (
                            <>
                                <DrivenKilometersContainer>
                                    <BookingInputs inputs={drivenKilometersInputs} canValidate={false} optional={true} />
                                </DrivenKilometersContainer>
                                {content?.drivenKilometersIsOptionalText && (
                                    <OptionalTextContainer>{content.drivenKilometersIsOptionalText}</OptionalTextContainer>
                                )}
                            </>
                        ) : null}
                    </Container>
                    {content && subProductsWithoutTirehotel.length > 0 ? (
                        <>
                            <SubProductsSeparatorContainer>
                                <Separator marginTop="0px" marginBottom="15px" />
                            </SubProductsSeparatorContainer>
                            <SubProductsSection>
                                <SubProductsHeader>
                                    <SubProductsStyledHeader>Andre kunder vælger også</SubProductsStyledHeader>
                                    <StyledDescription>Populære ydelser som andre kunder vælger</StyledDescription>
                                </SubProductsHeader>
                                {subProductsWithoutTirehotel.map((subProduct) => {
                                    return (
                                        <ServiceCard
                                            key={subProduct.id}
                                            serviceProduct={subProduct}
                                            onYoutubeModalLinkClicked={onYoutubeModalLinkClicked}
                                            content={content}
                                        ></ServiceCard>
                                    );
                                })}
                            </SubProductsSection>
                        </>
                    ) : null}
                </>
            ) : null}
        </Wrapper>
    );
};
