import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const SortingDropdownWrapper = styled.div`
    display: none;
    padding: 0 15px;
    font-size: 12px;
    background-color: white;
    box-shadow: ${({ theme: { shadows } }) => shadows.large};
    border-radius: 20px;
    column-gap: 10px;
    align-items: center;
    position: relative;
    z-index: 1;
    cursor: pointer;
    min-width: 200px;
    justify-content: space-between;

    @media ${device.laptopS} {
        display: flex;
    }
`;

export const StyledSortingLabel = styled.span`
    font-weight: 500;
`;

export const SortingOption = styled.div<{ selected: boolean }>`
    padding: 5px 15px;

    &:hover {
        background-color: ${({ theme: { palette } }) => palette.common.offWhite};
    }
`;

export const SortingValue = styled.span`
    color: #807f80;
`;

export const SortingDropdownOptions = styled.div<{ open: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    box-shadow: ${({ theme: { shadows } }) => shadows.large};
    border-radius: 20px;
    display: ${(props) => (props.open ? 'flex' : 'none')};
    flex-direction: column;
    background-color: white;
    padding: 35px 0 15px;

    &:focus,
    &:active {
        outline: none;
    }
`;

export const ArrowContainer = styled.div<{ open: boolean }>`
    margin-top: 2px;

    img {
        transition: all 0.2s ease-in-out;
        ${(props) => (props.open ? 'transform: rotate(180deg);' : '')}
    }
`;
