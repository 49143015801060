import styled from 'styled-components';

export const StyledHeader = styled.p`
    color: #0b0b0b;
    font-size: 25px;
    font-weight: bold;
    font-family: 'Corporate E';
    margin-bottom: 14px;
`;

export const StyledDescription = styled.div`
    white-space: pre-line;
    font-size: 14px;
    line-height: 23px;
`;
