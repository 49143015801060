import styled from 'styled-components';
import { contentSpotTypes } from '../../../../lib/api';
import { device } from '../../../../lib/media-query';
import { SpotHeader } from '../../../shared/typography/spot-header/spot-header.component';

const getContent = (position: contentSpotTypes.Position): string => {
    switch (position) {
        case 'Left':
            return 'flex-start';
        case 'Center':
            return 'center';
        case 'Right':
            return 'flex-end';
        case '':
            return 'unset';
        default:
            throw new Error('ejh.spa > getContent: Invalid position values');
    }
};

export const MediaWrapper = styled.div<{
    backgroundColor?: string;
    showOverlay: boolean;
}>`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 100%;

    background-color: ${(props) => (props.backgroundColor ? '#' + props.backgroundColor : 'rgba(0, 0, 0, 0.7)')};

    isolation: isolate;
`;

export const MediaContent = styled.div<{ textPosition: contentSpotTypes.Position }>`
    display: flex;
    flex-direction: column;
    // width: 100%;
    align-items: center;

    @media ${device.tablet} {
        align-items: ${(props) => getContent(props.textPosition)};
    }
`;

export const MediaHeader = styled.h2<{ textColor: string }>`
    color: white;
    width: 90%;
    height: 70%;
    isolation: isolate;
    z-index: 20;
    color: #${(props) => props.textColor};
    text-align: left;
    font-family: 'Corporate E';
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
    @media ${device.tablet} {
        width: 30%;
    }
`;

export const StylesMediaHeader = styled(SpotHeader)<{ textColor: string }>`
    color: white;
    width: 90%;
    height: 70%;
    isolation: isolate;
    z-index: 20;
    color: #${(props) => props.textColor};
    text-align: left;
    margin-bottom: 10px;
    @media ${device.tablet} {
        width: 30%;
    }
`;

export const TextWrapper = styled.div<{ textColor: string }>`
    color: white;
    width: 90%;
    height: 70%;
    isolation: isolate;
    z-index: 20;
    color: #${(props) => props.textColor};
    text-align: left;

    & p {
        font-size: clamp(14px, 3.3vw - 0.75rem, 1rem);
    }

    & ul,
    & ol {
        font-size: clamp(14px, 3.3vw - 0.75rem, 1rem);
        font-weight: 500;
        list-style: revert;
        padding: revert;
        margin: revert;
    }

    @media ${device.tablet} {
        width: 30%;
    }
`;
